import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  highlighted: {
    background: theme.vars.palette.bigid?.blue100,
  },
  heading: {
    color: theme.vars.palette.bigid?.gray600,
    fontSize: '0.8125rem',
    fontWeight: 800,
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  body: {
    width: '100%',
    padding: '4px 18px',
    overflow: 'auto',
  },
  rotate90: {
    transform: 'rotate(-90deg)',
  },
}));
