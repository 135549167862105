import React, { FC, useState } from 'react';
import cx from 'classnames';
import { BigidSwitch } from '@bigid-ui/components';
import { BigidChevronRightIcon } from '@bigid-ui/icons';
import { Root, classes } from './WorkflowCustmizationPipelineStyles';
import { RequestWorkflowStageType } from '../../types';

export interface WorkflowCustmizationPipelinePropsType {
  stages: RequestWorkflowStageType[];
  onToggle?: (id: number, name: string, checked: boolean) => void;
  onClick: (id: number) => void;
}

export const WorkflowCustmizationPipeline: FC<WorkflowCustmizationPipelinePropsType> = ({
  stages,
  onToggle,
  onClick,
}) => {
  const [activeStateId, setActiveStateId] = useState(stages[0].id);
  const onStageClick = (id: number) => {
    setActiveStateId(id);
    onClick(id);
  };

  return (
    <Root className={classes.root}>
      {stages.map(({ id, name, enabled }, idx) => (
        <div key={id} className={classes.wrapper}>
          <div
            data-aid={`${name}-stage`}
            className={cx(classes.stage, { [classes.enabled]: enabled, [classes.active]: activeStateId === id })}
            onClick={() => onStageClick(id)}
          >
            <div>{name}</div>
            {idx !== 0 && stages.length - 1 !== idx && !!onToggle && (
              <div
                className={classes.toggle}
                onClick={event => {
                  event.stopPropagation();
                }}
              >
                <BigidSwitch
                  checked={enabled}
                  dataAid={`${name}-stage__switch`}
                  onChange={(event, checked) => {
                    event.stopPropagation();
                    onToggle(id, name, checked);
                  }}
                />
              </div>
            )}
          </div>
          {stages.length - 1 !== idx && <BigidChevronRightIcon />}
        </div>
      ))}
    </Root>
  );
};
