import React, { FC, useCallback } from 'react';
import { BigidSwitch } from '@bigid-ui/components';
import { debounce } from 'lodash';

import { ActionsWorkflowGetType } from '../../../types';
import { useFormatting } from '../../../hooks/useFormatting';
import { updateActionWorkflow } from '../../../services/settingsService';
import { SnackbarUtils } from '../../../utils';
import { WorkflowCustomizationContainer } from '../hooks/useWorkflowCustomization';
import { Root, classes } from './ActionsWorkflowTableStyles';

interface ActionsWorkflowRowPropsType {
  rows: ActionsWorkflowGetType[];
}

export const ActionsWorkflowRow: FC<ActionsWorkflowRowPropsType> = ({ rows }) => {
  const { getFormattedDate } = useFormatting();
  const { reloadWorkflowDefinitions } = WorkflowCustomizationContainer.useContainer();

  const onToggle = useCallback(
    async (id: number, name: string, enabled: boolean) => {
      await updateActionWorkflow(id, enabled);
      SnackbarUtils.toast(<div>{`${name} action ${enabled ? 'enabled' : 'disabled'}.`}</div>);
      await reloadWorkflowDefinitions();
    },
    [reloadWorkflowDefinitions],
  );

  return (
    <Root>
      {rows.map((actionsRow: ActionsWorkflowGetType) => (
        <tr key={actionsRow.id} className={classes.tr} data-aid={`actions-tr${actionsRow.id}`}>
          <td>{actionsRow.name}</td>
          <td className={classes.triggerTd}>{actionsRow.trigger}</td>
          <td>
            {actionsRow.modificationDate ? (
              getFormattedDate(actionsRow.modificationDate, {
                hour: 'numeric',
                minute: 'numeric',
              })
            ) : (
              <span className={classes.dashCentering}>-</span>
            )}
          </td>
          <td className={classes.activeTd} onClick={event => event.stopPropagation()}>
            <BigidSwitch
              checked={actionsRow.enabled}
              dataAid={`${actionsRow.name}-action__switch`}
              onChange={debounce((event, checked) => onToggle(actionsRow.id, actionsRow.name, checked), 300)}
            />
          </td>
          <td className={classes.activeTd}></td>
        </tr>
      ))}
    </Root>
  );
};
