import React from 'react';
import parse, { HTMLReactParserOptions, domToReact, attributesToProps } from 'html-react-parser';
import { sanitize } from 'dompurify';
import { normalizeLinkToHyperlink } from './helpers';

const options: HTMLReactParserOptions = {
  replace(domNode) {
    if (!('attribs' in domNode)) {
      return;
    }

    const { name, attribs, children } = domNode;
    const props = attributesToProps(domNode.attribs);

    if (name === 'a') {
      return (
        <a target="_blank" rel="noreferrer" {...props} href={normalizeLinkToHyperlink(attribs.href)}>
          {domToReact(children)}
        </a>
      );
    }
  },
};

export const parseHTML = (html: string, replaceLink?: boolean) =>
  parse(sanitize(html), replaceLink ? options : undefined);
