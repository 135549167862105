import { api } from './apiService';
import {
  RequestsByStatusType,
  StatisticsType,
  RequestsByTypeType,
  DataRequestsByTypeType,
  TrendsRequestsOptionsType,
  TrendRequestDataItemType,
  RegulationQuantitiesType,
  RequestsByUserProfileType,
  MapChartItemDataType,
  MapChartDataType,
} from '../../types';
import { processParams } from '../../utils';

export const getRequestsByStatus = (): Promise<RequestsByStatusType> =>
  api.get<StatisticsType<RequestsByStatusType>>(`/statistics/requests-by-status`).then(res => {
    return res.data.statistics;
  });

export const getRequestsByType = (): Promise<RequestsByTypeType[]> =>
  api.get<StatisticsType<RequestsByTypeType[]>>(`/statistics/requests-by-type`).then(res => {
    return res.data.statistics;
  });

export const getRequestsTimeByType = (): Promise<DataRequestsByTypeType[]> =>
  api
    .get<StatisticsType<DataRequestsByTypeType[]>>(`/statistics/response-time-by-type`)
    .then(res => res.data.statistics);

export const getTrendOpenRequests = (options: TrendsRequestsOptionsType): Promise<TrendRequestDataItemType[]> =>
  api
    .get<StatisticsType<TrendRequestDataItemType[]>>(processParams(`/statistics/trends/open-requests`, options))
    .then(res => res.data.statistics);

export const getTrendOpenRequestsByStatus = (options: TrendsRequestsOptionsType): Promise<TrendRequestDataItemType[]> =>
  api
    .get<StatisticsType<TrendRequestDataItemType[]>>(
      processParams(`/statistics/trends/open-requests-by-status`, options),
    )
    .then(res => {
      return res.data.statistics;
    });

export const getTrendOpenRequestsByType = (options: TrendsRequestsOptionsType): Promise<TrendRequestDataItemType[]> =>
  api
    .get<StatisticsType<TrendRequestDataItemType[]>>(processParams(`/statistics/trends/open-requests-by-type`, options))
    .then(res => {
      return res.data.statistics;
    });

export const getTrendClosedRequests = (options: TrendsRequestsOptionsType): Promise<TrendRequestDataItemType[]> =>
  api
    .get<StatisticsType<TrendRequestDataItemType[]>>(processParams(`/statistics/trends/closed-requests`, options))
    .then(res => res.data.statistics);

export const getTrendClosedRequestsByType = (options: TrendsRequestsOptionsType): Promise<TrendRequestDataItemType[]> =>
  api
    .get<StatisticsType<TrendRequestDataItemType[]>>(
      processParams(`/statistics/trends/closed-requests-by-type`, options),
    )
    .then(res => {
      return res.data.statistics;
    });

export const getTrendRejectedRequests = (options: TrendsRequestsOptionsType): Promise<TrendRequestDataItemType[]> =>
  api
    .get<StatisticsType<TrendRequestDataItemType[]>>(
      processParams(`/statistics/trends/rejected-requests-by-reason`, options),
    )
    .then(res => res.data.statistics);

export const getRequestsByRegulations = (options: TrendsRequestsOptionsType): Promise<RegulationQuantitiesType> =>
  api
    .get<StatisticsType<RegulationQuantitiesType>>(processParams(`/statistics/requests-by-regulation`, options))
    .then(res => res.data.statistics);

export const getRequestsByUserProfile = (options: TrendsRequestsOptionsType): Promise<RequestsByUserProfileType> =>
  api
    .get<StatisticsType<RequestsByUserProfileType>>(processParams(`/statistics/requests-by-user-profile`, options))
    .then(res => res.data.statistics);

export const getMapChardData = (options: TrendsRequestsOptionsType): Promise<MapChartItemDataType[]> =>
  api
    .get<MapChartDataType>(processParams(`/statistics/requests-by-user-location`, options))
    .then(res => res.data.statistics);
