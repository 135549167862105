import { useEffect, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useFormik } from 'formik';
import { noop } from 'lodash';

import { UpdateEmailTemplateType, UpdateWorkflowType } from '../../../types';
import { makeRequestWorkflowValidationSchema } from '../../../utils';
import { fetchEmailTemplate } from '../../../services/settingsService';
import { WorkflowCustomizationContainer } from './useWorkflowCustomization';
import { AvailableLanguagesContainer } from '../../../state/availableLanguagesContainer';
import { useFetch } from '../../../hooks';

const getDurationDays = (type: string) => {
  if (type === 'Y') {
    return 365;
  } else if (type === 'M') {
    return 30;
  } else {
    return 1;
  }
};

export const useRequestCustomization = () => {
  const [durationError, setDurationError] = useState<string | undefined>();

  const { requestWorkflowData } = WorkflowCustomizationContainer.useContainer();
  const { languagesDropdownOptions: availableLanguages } = AvailableLanguagesContainer.useContainer();

  const requestWorkflowValidationSchema = useMemo(
    () => makeRequestWorkflowValidationSchema(availableLanguages),
    [availableLanguages],
  );

  const { data: emailTemplateData, loadData: loadEmailTemplateData } = useFetch({
    fetchApi: () => fetchEmailTemplate(requestWorkflowData?.autoExtensionEmailTemplateId),
    permission: !!requestWorkflowData?.autoExtensionEmailTemplateId,
    dependencyParams: [requestWorkflowData?.autoExtensionEmailTemplateId],
  });

  const requestWorkflowFormik = useFormik<{ configuration: UpdateWorkflowType }>({
    initialValues: { configuration: requestWorkflowData || {} },
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: noop,
  });

  const emailTemplateFormik = useFormik<{ emailTemplate: UpdateEmailTemplateType }>({
    initialValues: { emailTemplate: emailTemplateData },
    enableReinitialize: true,
    validationSchema: requestWorkflowValidationSchema,
    validateOnChange: true,
    onSubmit: noop,
  });

  const validateDuration = (reportRetentionPeriod?: string, dataRetentionPeriod?: string) => {
    if (!reportRetentionPeriod || !dataRetentionPeriod) {
      return;
    }
    const [[, reportNumberValue, reportTypeValue]] = [...reportRetentionPeriod.matchAll(/P(\d+)([D,M,Y])/g)];
    const [[, dataNumberValue, dataTypeValue]] = [...dataRetentionPeriod.matchAll(/P(\d+)([D,M,Y])/g)];

    const reportDays = +reportNumberValue * getDurationDays(reportTypeValue);
    const dataDays = +dataNumberValue * getDurationDays(dataTypeValue);

    if (reportDays > dataDays) {
      return 'File download retention cannot exceed closed request retention.';
    }
  };

  useEffect(() => {
    // TODO: Think how to implement it by YUP
    setDurationError(
      validateDuration(
        requestWorkflowFormik.values.configuration.reportRetentionPeriod,
        requestWorkflowFormik.values.configuration.dataRetentionPeriod,
      ),
    );
  }, [
    requestWorkflowFormik.values.configuration.reportRetentionPeriod,
    requestWorkflowFormik.values.configuration.dataRetentionPeriod,
  ]);

  return {
    requestWorkflowFormik,
    requestWorkflowData,
    durationError,
    emailTemplateFormik,
    loadEmailTemplateData,
  };
};

export const RequestCustomizationContainer = createContainer(useRequestCustomization);
