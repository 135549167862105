import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    fontSize: '1rem',
    color: theme.vars.palette.bigid?.gray700,
  },
  bold: {
    fontWeight: 600,
  },
  text: {
    paddingRight: 10,
    fontWeight: 400,
  },
}));
