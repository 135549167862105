import React, { useCallback, useState, ChangeEvent } from 'react';
import {
  BigidDialog,
  BigidDropdown,
  BigidHeading4,
  BigidSidePanel,
  BigidSwitch,
  BigidTextField,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { useSnackbar } from 'notistack';
import { KeyboardBackspace } from '@mui/icons-material';
import { LanguageType } from '@consumer/state/languageContainer';
import { useNavigate } from 'react-router-dom';
import { MePaper, BigidMeTooltipWithIcon, Loader } from '../../../components';
import WorkflowEmailTemplate from '../WorkflowEmailTemplate';
import { Root, classes } from './WorkflowSettingsStyles';
import { RequestCustomizationContainer } from '../hooks/useRequestCustomization';
import { usePreventLeavePage } from '../../../hooks/usePreventLeavePage';
import { updateEmailTemplate, updateWorkflow } from '../../../services/settingsService';
import {
  businessUsersToBigidDropdownOptions,
  collaboratorsToBigidDropdownValue,
  encodeWorkflowEmail,
} from '../../../utils';
import { WorkflowCustomizationContainer } from '../hooks/useWorkflowCustomization';
import EmailTemplateControlsBar from '../EmailTemplateControlsBar';
import { useFormatting } from '../../../hooks/useFormatting';
import { FormLanguageGeneralError } from '../../../components/FormLanguageGeneralError';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { omit } from 'lodash';
import { AllowOutsideRequestors } from '../AllowOutsideRequestors/AllowOutsideRequestors';
import { AllowOutsideRequestorsContainer } from '../hooks/useAllowOutsideRequestors';
import { Box, Typography, useTheme } from '@mui/material';
import { Portal } from '@mui/base';
import { Stack } from '@mui/system';
import { TimeDurationField } from '../../../components/TimeDurationField';
import { MeAccordion } from '../../../components/MeAccordion';
import { BigidEditIcon, BigidWarningIcon } from '@bigid-ui/icons';
import { AvailableLanguagesContainer } from '../../../state/availableLanguagesContainer';
import { useFetch, useToggle } from '../../../hooks';
import { getBusinessUsersByTenant } from '../../../services/request';
import { DueDateNotifications } from '../DueDateNotifications/DueDateNotifications';
import { primaryBorder } from '../../../assets/styles';
import { DueDateNotificationsContainer } from '../hooks/useDueDateNotifications';
import { RequestWorkflowStageEmailCode } from '../../../types';

const NONE_LABEL = 'None';

export const WorkflowSettings = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { reloadWorkflowDefinitions } = WorkflowCustomizationContainer.useContainer();
  const { requestWorkflowFormik, emailTemplateFormik, requestWorkflowData, durationError, loadEmailTemplateData } =
    RequestCustomizationContainer.useContainer();
  const {
    handleDueDateNotificationsUpdate,
    settingsFormik: { isValid: DueDateNotificationsIsValid, dirty: dueDateNotificationsDirty },
  } = DueDateNotificationsContainer.useContainer();
  const {
    settingFormik: {
      handleSubmit: handleAllowOutsideRequestorsUpdate,
      isValid: AllowOutsideRequestorsIsValid,
      dirty: allowOutsideRequestorsDirty,
    },
  } = AllowOutsideRequestorsContainer.useContainer();
  const { data: usersToAssign } = useFetch({
    initValue: {
      users: [],
    },
    fetchApi: () => getBusinessUsersByTenant(),
  });

  const { value: isOpenSaveDialog, setFalse: closeSaveDialog, setTrue: openSaveDialog } = useToggle(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageType>(LanguageType.EN);
  const {
    value: isOpenEmailTemplateSettings,
    setFalse: closeEmailTemplateSettings,
    setTrue: openEmailTemplateSettings,
  } = useToggle(false);
  const configuration = requestWorkflowFormik.values.configuration;
  const { getFormattedDate } = useFormatting();
  const { languages } = AvailableLanguagesContainer.useContainer();

  usePageTitle(`Workflow settings-${requestWorkflowData?.type}`, { enable: !!requestWorkflowData?.type });

  usePreventLeavePage(requestWorkflowFormik.dirty);

  const onBack = useCallback(() => {
    requestWorkflowFormik.dirty || allowOutsideRequestorsDirty || dueDateNotificationsDirty
      ? openSaveDialog()
      : navigate(`/settings/workflow-customization/${requestWorkflowData!.id}`);
  }, [
    requestWorkflowFormik.dirty,
    allowOutsideRequestorsDirty,
    dueDateNotificationsDirty,
    openSaveDialog,
    navigate,
    requestWorkflowData,
  ]);

  const onSettingsSave = useCallback(async () => {
    const { configuration } = requestWorkflowFormik.values;

    await Promise.all([
      updateWorkflow(requestWorkflowData!.id, configuration),
      handleDueDateNotificationsUpdate(),
      handleAllowOutsideRequestorsUpdate(),
    ]);
    await reloadWorkflowDefinitions();
    enqueueSnackbar('Changes saved');
    closeSaveDialog();
  }, [
    requestWorkflowFormik.values,
    requestWorkflowData,
    handleDueDateNotificationsUpdate,
    handleAllowOutsideRequestorsUpdate,
    reloadWorkflowDefinitions,
    enqueueSnackbar,
    closeSaveDialog,
  ]);

  const handleSaveEmailTemplate = useCallback(async () => {
    const { emailTemplate } = emailTemplateFormik.values;

    await updateEmailTemplate(requestWorkflowData!.autoExtensionEmailTemplateId, {
      ...omit(emailTemplate, ['translations', 'canToggleAppeal']),
      ...encodeWorkflowEmail(emailTemplate),
    });
    loadEmailTemplateData();
    closeSaveDialog();
    closeEmailTemplateSettings();
    emailTemplateFormik.resetForm();
    enqueueSnackbar('Changes saved');
  }, [
    closeEmailTemplateSettings,
    closeSaveDialog,
    emailTemplateFormik,
    enqueueSnackbar,
    loadEmailTemplateData,
    requestWorkflowData,
  ]);

  const ownersToAssignOptions = [
    { displayValue: NONE_LABEL, value: '', id: '' },
    ...businessUsersToBigidDropdownOptions(usersToAssign.users),
  ];
  const handleCloseEmailTemplateSetting = () => {
    emailTemplateFormik.resetForm();
    closeEmailTemplateSettings();
  };

  const isDisabledSaveButton =
    (!!requestWorkflowData?.autoExtensionEmailTemplateId
      ? requestWorkflowFormik.dirty && !requestWorkflowFormik.isValid
      : false) ||
    !AllowOutsideRequestorsIsValid ||
    !DueDateNotificationsIsValid ||
    !!durationError;

  if (!requestWorkflowData) return <Loader />;

  return (
    <Root>
      <div className={classes.header}>
        <span className={classes.backButton} data-aid="workflow-settings-back__button" onClick={onBack}>
          <KeyboardBackspace fill={theme.vars.palette.bigid?.gray500} />
          <div className={classes.title}>Workflow Settings</div>
        </span>
        <PrimaryButton
          text="Save Changes"
          onClick={onSettingsSave}
          disabled={isDisabledSaveButton}
          dataAid="save-settings__button"
          size="medium"
        />
      </div>

      <div className={classes.layout}>
        <MePaper>
          <BigidHeading4 mb={2}>Information</BigidHeading4>
          <div className={classes.row}>
            <span className={classes.label}>Workflow Name</span>
            <span>{requestWorkflowData.workflowName}</span>
          </div>
          <div className={classes.row}>
            <span className={classes.label}>Workflow Owner</span>
            <div className={classes.selectOwnerRow}>
              <BigidDropdown
                displayLimit={10}
                dataAid="workflow-owner__select"
                noOptionsMessage="No options to select"
                value={[
                  {
                    id: String(configuration?.defaultOwnerUserId),
                    value: configuration.defaultOwnerUserFullName,
                    displayValue: configuration.defaultOwnerUserFullName!,
                  },
                ]}
                onSelect={value => {
                  requestWorkflowFormik.setFieldValue(`configuration.defaultOwnerUserId`, value[0]?.id);
                  requestWorkflowFormik.setFieldValue(
                    `configuration.defaultOwnerUserFullName`,
                    value[0]?.displayValue !== NONE_LABEL ? value[0]?.displayValue : '',
                  );
                }}
                options={ownersToAssignOptions}
              />
            </div>
          </div>
          <div className={classes.row}>
            <span className={classes.label}>Collaborators</span>
            <div className={classes.selectOwnerRow}>
              <div className={classes.collaboratorsDropdown}>
                <BigidDropdown
                  dataAid={`Collaborators__select`}
                  value={collaboratorsToBigidDropdownValue(configuration.defaultCollaborators || [])}
                  options={businessUsersToBigidDropdownOptions(usersToAssign.users)}
                  onSelect={values => {
                    requestWorkflowFormik.setFieldValue(
                      `configuration.defaultCollaborators`,
                      (values || []).map(v => ({
                        userId: v.id,
                        userFullName: v.displayValue,
                      })),
                    );
                  }}
                  isMulti
                  isErasable
                  applyOnChange
                  isExpandable
                />
              </div>
            </div>
          </div>
          <div className={classes.row}>
            <span className={classes.label}>Created</span>
            <span>
              {getFormattedDate(requestWorkflowData.creationDate!, {
                hour: 'numeric',
                minute: 'numeric',
              })}
            </span>
          </div>
          <div className={classes.row}>
            <span className={classes.label}>Last Update</span>
            <span>
              {requestWorkflowData.modificationDate
                ? getFormattedDate(requestWorkflowData.modificationDate, {
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                : '-'}
            </span>
          </div>
        </MePaper>
        <MePaper>
          <DueDateNotifications />
        </MePaper>
        <MePaper>
          <AllowOutsideRequestors />
        </MePaper>
        {!!requestWorkflowData.autoExtensionEmailTemplateId && (
          <MePaper className={classes.relative}>
            <div className={classes.flex}>
              <Box width="100%">
                <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Stack flexDirection="row" alignItems="center">
                    <div className={classes.subtitle}>Extend Request Time</div>
                    <BigidMeTooltipWithIcon
                      title="This email content will be sent when a request is
extended for both automated and manual extension"
                    />
                  </Stack>
                  <TertiaryButton
                    startIcon={<BigidEditIcon />}
                    text="Edit email"
                    onClick={openEmailTemplateSettings}
                    size="medium"
                    dataAid="extend-request-summary-edit-email-button"
                  />
                </Stack>
              </Box>
              <Portal>
                <BigidSidePanel
                  title="Extend Request Time Email"
                  open={isOpenEmailTemplateSettings}
                  onClose={emailTemplateFormik.dirty ? openSaveDialog : handleCloseEmailTemplateSetting}
                  maxWidth="medium"
                  isShowBackdrop
                  content={
                    <div>
                      <Stack flexDirection="row" alignItems="center" justifyContent="space-between" pr={2}>
                        <EmailTemplateControlsBar
                          selectedLanguage={selectedLanguage}
                          emailTemplateId={requestWorkflowData.autoExtensionEmailTemplateId}
                          emailTemplate={emailTemplateFormik.values.emailTemplate}
                          onSelectedLanguageChange={setSelectedLanguage}
                          emailCode={RequestWorkflowStageEmailCode.COMMON_REQUEST_EXTENDED}
                        />
                        <PrimaryButton
                          text="Save Changes"
                          onClick={handleSaveEmailTemplate}
                          size="medium"
                          disabled={!emailTemplateFormik.isValid}
                          dataAid="save-email-changes-button"
                        />
                      </Stack>
                      <Box pl={4} borderTop={primaryBorder}>
                        <MeAccordion
                          name=""
                          headerClasses={{ expanded: classes.accordionHeading }}
                          bodyClassName={classes.accordionBody}
                          isExpanded
                          heading={
                            <Stack direction="row" alignItems="center" spacing={0.5} data-aid="extend-request-summary">
                              <Typography fontSize={16}>Email message</Typography>
                              <BigidMeTooltipWithIcon title="Click here to see an example of the email template" />
                            </Stack>
                          }
                          body={
                            <Box ml={1} mr={1}>
                              <FormLanguageGeneralError
                                fieldName="subject"
                                fieldLabel="Subject"
                                selectedLanguage={selectedLanguage}
                                errors={
                                  (
                                    emailTemplateFormik.errors?.emailTemplate as unknown as {
                                      translations: Record<string, string>;
                                    }
                                  )?.translations
                                } //TODO: This is some TS issue
                                availableLanguages={languages}
                              />
                              {!!requestWorkflowData.autoExtensionEmailTemplateId && (
                                <WorkflowEmailTemplate
                                  testIdPrefix={'extend_request'}
                                  selectedLanguage={selectedLanguage}
                                  emailTemplate={emailTemplateFormik.values.emailTemplate}
                                  emailTemplateErrors={emailTemplateFormik.errors.emailTemplate}
                                  setFieldValue={emailTemplateFormik.setFieldValue}
                                />
                              )}
                            </Box>
                          }
                        />
                      </Box>
                    </div>
                  }
                />
              </Portal>
            </div>
            <div className={classes.row}>
              <BigidSwitch
                checked={configuration.autoExtensionEnabled}
                label="Extend all requests automatically after"
                onChange={(_, checked) =>
                  requestWorkflowFormik.setFieldValue(`configuration.autoExtensionEnabled`, checked)
                }
                size="small"
              />
              <div className={classes.selectRow}>
                <BigidTextField
                  value={configuration.extensionAfterDays?.toString()}
                  placeholder="Days"
                  type="number"
                  inputProps={{ min: '0' }}
                  size="medium"
                  onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                    requestWorkflowFormik.setFieldValue(`configuration.extensionAfterDays`, +event.target.value)
                  }
                  dataAid="extensionAfterDays__field"
                />
              </div>
              <span>&nbsp;&nbsp;days</span>
            </div>
            <Box className={classes.row} mb={1}>
              <BigidSwitch
                checked={configuration.extensionEmailEnabled}
                label="Send email when requests get extended"
                onChange={(_, checked) =>
                  requestWorkflowFormik.setFieldValue(`configuration.extensionEmailEnabled`, checked)
                }
                size="small"
              />
            </Box>
          </MePaper>
        )}
        <MePaper>
          <BigidHeading4 mb={1}>Data Expiration</BigidHeading4>
          <Box mb={2}>
            Personal data is retained for a limited time for follow-up and auditing, and then deleted for data security
            reasons.
          </Box>
          <Stack direction="row" spacing={2.5}>
            <TimeDurationField
              name="dataRetentionPeriod"
              label="Closed request data expires after:"
              tooltip="At the end of the retention period, which starts when
              the request is closed, the request data is deleted."
              value={requestWorkflowFormik.values.configuration.dataRetentionPeriod}
              onChange={value => requestWorkflowFormik.setFieldValue('configuration.dataRetentionPeriod', value)}
            />
            <TimeDurationField
              name="reportRetentionPeriod"
              label="File download link expires after:"
              tooltip="At the end of the retention period, which starts when the
              download link is sent to the requestor, the link is disabled."
              value={requestWorkflowFormik.values.configuration.reportRetentionPeriod}
              onChange={value => requestWorkflowFormik.setFieldValue('configuration.reportRetentionPeriod', value)}
            />
          </Stack>
          {durationError && (
            <Stack direction="row" alignItems="center" mt={2} data-aid="dataRetentionPeriod_error">
              <BigidWarningIcon size="small" />
              <Typography mr={1}>{durationError}</Typography>
            </Stack>
          )}
        </MePaper>
      </div>
      {isOpenSaveDialog && (
        <BigidDialog
          title="You have unsaved changes"
          isOpen={isOpenSaveDialog}
          buttons={[
            {
              component: TertiaryButton,
              text: 'Close without saving',
              onClick: () => {
                if (isOpenEmailTemplateSettings) {
                  handleCloseEmailTemplateSetting();
                  closeSaveDialog();
                } else {
                  navigate(`/settings/workflow-customization/${requestWorkflowData!.id}`);
                }
              },
              dataAid: 'workflow-settings-save-dialog__cancel',
            },
            {
              component: PrimaryButton,
              text: 'Save',
              onClick: isOpenEmailTemplateSettings ? handleSaveEmailTemplate : onSettingsSave,
              dataAid: 'workflow-settings-save-dialog__apply',
            },
          ]}
          onClose={closeSaveDialog}
        >
          Your changes will be lost. Are you sure you want to close?
        </BigidDialog>
      )}
    </Root>
  );
};
