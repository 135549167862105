import React, { FC } from 'react';
import cx from 'classnames';
import { Paper, PaperProps } from '@mui/material';
import { useStyles } from './MePaperStyles';
import { paperBoxShadow, BORDER_RADIUS } from '../../assets/styles';

export type MePaperProps = { padding?: boolean } & PaperProps;

export const MePaper: FC<MePaperProps> = ({ padding = true, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Paper
      sx={theme => ({
        borderRadius: BORDER_RADIUS,
        backgroundColor: theme.vars.palette.bigid?.white,
        boxShadow: paperBoxShadow,
        maxWidth: 850,
        margin: '24px auto',
        padding: '20px 32px',
      })}
      className={cx(className, { [classes.noPadding]: !padding })}
      {...rest}
    />
  );
};
