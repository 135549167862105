import React, { FC } from 'react';
import { get } from 'lodash';
import { FormikErrors } from 'formik';
import { BigidFormFieldLabelWrapper, BigidRadioGroup, BigidTextField } from '@bigid-ui/components';
import { LanguageType } from '@consumer/state/languageContainer';
import { Root, classes } from './EmailTemplateStyles';
import { BigidMeTooltipWithIcon } from '../BigidMeTooltipWithIcon';
import { AuthorizedAgentMessageMode, UpdateEmailTemplateType } from '../../types';
import { Loader } from '../Loader';
import { RichTextEditor } from '../RichTextEditor';

const DEFAULT_BODY_TOOLTIP_TEXT =
  'You may insert the following variables in the text: {{brandName}}, {{consumerName}}, {{privacyPolicyLink}}, {{userDevice}}, {{userLocation}}, {{reportExpirationDate}}, {{reportExpirationDays}}, {{supportLink}}';

const authorizedAgentMessageModeOptions = [
  {
    label: 'Authorized Agent',
    value: AuthorizedAgentMessageMode.AGENT,
  },
  {
    label: 'Data Subject',
    value: AuthorizedAgentMessageMode.CONSUMER,
  },
];

interface EmailTemplatePropsType {
  namespace?: string;
  selectedLanguage: LanguageType;
  emailTemplate?: UpdateEmailTemplateType;
  emailTemplateErrors?: FormikErrors<UpdateEmailTemplateType>;
  testIdPrefix?: string;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  allFieldsRequired?: boolean; // TODO: Remove after applying validation for all forms with EmailTemplate (UX changes needed)
  bodyTooltipText?: string;
}

export const EmailTemplate: FC<EmailTemplatePropsType> = ({
  namespace = '',
  selectedLanguage,
  emailTemplate,
  emailTemplateErrors,
  setFieldValue,
  testIdPrefix = '',
  allFieldsRequired = false,
  bodyTooltipText = DEFAULT_BODY_TOOLTIP_TEXT,
}) => {
  if (!emailTemplate) return <Loader />;

  return (
    <Root className={classes.root}>
      {!!emailTemplate.authorizedAgentMessageMode && (
        <div className={classes.row}>
          <BigidRadioGroup
            name="authorizedAgentMessageMode"
            mainLabel="Communicate with"
            horizontal
            value={
              emailTemplate.authorizedAgentMessageMode === AuthorizedAgentMessageMode.AGENT
                ? AuthorizedAgentMessageMode.AGENT
                : AuthorizedAgentMessageMode.CONSUMER
            }
            options={authorizedAgentMessageModeOptions}
            onChange={value =>
              setFieldValue(
                `${namespace}emailTemplate.authorizedAgentMessageMode`,
                value.target.value === AuthorizedAgentMessageMode.AGENT
                  ? AuthorizedAgentMessageMode.AGENT
                  : AuthorizedAgentMessageMode.CONSUMER,
              )
            }
          />
          <div className={classes.tooltip}>
            <BigidMeTooltipWithIcon title="Select who will receive this message when an Authorized Agent submits a request." />
          </div>
        </div>
      )}
      <div className={classes.row}>
        <BigidTextField
          required
          errorMessage={get(emailTemplateErrors, `translations[${selectedLanguage}].subject`)}
          value={emailTemplate.translations[selectedLanguage]?.subject}
          label="Subject"
          dataAid={`${testIdPrefix}_subject_field`}
          onChange={value =>
            setFieldValue(`${namespace}emailTemplate.translations[${selectedLanguage}].subject`, value.target.value)
          }
          size="medium"
          type="text"
        />
      </div>
      <div className={classes.row}>
        <BigidTextField
          required={allFieldsRequired}
          errorMessage={get(emailTemplateErrors, `translations[${selectedLanguage}].header`)}
          value={emailTemplate.translations[selectedLanguage]?.header}
          label="Header"
          dataAid={`${testIdPrefix}_header_field`}
          onChange={value =>
            setFieldValue(`${namespace}emailTemplate.translations[${selectedLanguage}].header`, value.target.value)
          }
          size="medium"
          type="text"
        />
      </div>
      <div className={classes.row}>
        <BigidTextField
          required={allFieldsRequired}
          errorMessage={get(emailTemplateErrors, `translations[${selectedLanguage}].title`)}
          value={emailTemplate.translations[selectedLanguage]?.title}
          label="Title"
          dataAid={`${testIdPrefix}_title_field`}
          onChange={value =>
            setFieldValue(`${namespace}emailTemplate.translations[${selectedLanguage}].title`, value.target.value)
          }
          size="medium"
          type="text"
        />
      </div>
      <div className={classes.editor}>
        <BigidFormFieldLabelWrapper
          id={`${testIdPrefix}_email-body`}
          label="Message"
          isRequired={allFieldsRequired}
          errorIsShown
          error={get(emailTemplateErrors, `translations[${selectedLanguage}].message`)}
          tooltipText={bodyTooltipText}
        >
          <RichTextEditor
            dataAid={`${testIdPrefix}_email-body_input`}
            value={emailTemplate.translations[selectedLanguage]?.message}
            onBlur={html => setFieldValue(`${namespace}emailTemplate.translations[${selectedLanguage}].message`, html)}
          />
        </BigidFormFieldLabelWrapper>
      </div>
    </Root>
  );
};
