import React, { FC } from 'react';
import { FileDrop } from 'react-file-drop';

import { ReactComponent as IconFileUpload } from '../../assets/icons/icon-upload-grey.svg';

import { useStyles } from './MeFileUploadStyles';

type MeFileUploadPropsType = {
  onUpload: (files: File[]) => void;
  format: string;
  maxFiles?: number;
  maxFileSize?: number;
  disabled?: boolean;
};

// NOTE: We have a similar component called FileUploader, but it has very different API
// and implemented for a different use cases than the current one.
// TODO: Let's make a UI audit of those components and came up with a single approach.

export const MeFileUpload: FC<MeFileUploadPropsType> = ({ onUpload, format, maxFiles, maxFileSize, disabled }) => {
  const classes = useStyles();

  const handleUpload = (files: FileList | null) => {
    const formatRegExp = new RegExp(`(${format.replace(/,\s?/g, '|')})$`, 'i');
    const filteredFiles = Array.from(files || []).filter(file => formatRegExp.test(file.name));

    filteredFiles && onUpload(filteredFiles);
  };

  const handleInputFileChange = (event: { target: { files: FileList | null; value: string } }) => {
    handleUpload(event.target.files);
    event.target.value = '';
  };

  const preventDragHandler = (_files: FileList | null, event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const preventHandler = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <label className={classes.meFileUploadRoot}>
      <div className={classes.dropdownArea}>
        <FileDrop className={classes.fileDrop} onDrop={disabled ? preventDragHandler : handleUpload}>
          <div className={classes.uploadWrapper}>
            <IconFileUpload />
            <div className={classes.uploadInputWrapper}>
              Drop your files here or&nbsp;
              <span className={classes.link}>browse</span>
              &nbsp;your computer
              {!disabled && (
                <input
                  type="file"
                  data-aid="file-upload"
                  accept={format}
                  multiple
                  className={classes.upload}
                  onChange={disabled ? preventHandler : handleInputFileChange}
                />
              )}
              <div className={classes.subTitle}>
                <div>File types allowed: {format}.</div>
                {maxFiles && (
                  <div>
                    Maximum allowed: {maxFiles} files{maxFileSize ? ` of no more than ${maxFileSize} MB each` : ''}.
                  </div>
                )}
              </div>
            </div>
          </div>
        </FileDrop>
      </div>
    </label>
  );
};
