import { useCallback, useEffect, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { ManualRequestResolutionsType, UpdateStageSettingsPostType } from '../../../types';
import { makeStageWorkflowValidationSchema, encodeWorkflowEmail } from '../../../utils';
import {
  fetchEmailTemplate,
  updateAutoCloseResolutions,
  updateEmailTemplate,
  updateManualCloseResolutions,
  updateStageEmail,
} from '../../../services/settingsService';
import {
  StageEmailConfigurationType,
  StageConfigType,
  RequestWorkflowStageEmailCode,
} from '../../../types/SettingsTypes';
import {
  setRequiredDefaultsForValidation,
  setRequiredDefaultsForValidationStageEmail,
} from '../WorkflowCustomization/utils';
import { AvailableLanguagesContainer } from '../../../state/availableLanguagesContainer';
import { transformVendorEngagementToOption } from '../StageEmailCustomization/utils';

export const requestWorkflowDefaultData = {
  general: {
    configuration: {
      firstSendAfter: 'P0D',
    },
    emailTemplate: {
      progressEnabled: true,
      appealEnabled: false,
      canToggleAppeal: false,
      translations: setRequiredDefaultsForValidationStageEmail(),
    },
  },
  manual: {
    configuration: {
      id: -1,
      code: '',
      name: 'required',
      type: ManualRequestResolutionsType.REQUEST_COMPLETION,
      defaultForStage: false,
      mandatory: false,
      emailEnabled: false,
      attachmentsEnabled: false,
      emailTemplateId: -1,
      modificationDate: '',
      translations: setRequiredDefaultsForValidation('name'),
    },
    emailTemplate: {
      progressEnabled: true,
      appealEnabled: false,
      canToggleAppeal: false,
      translations: setRequiredDefaultsForValidationStageEmail(),
    },
  },
  auto: {
    configuration: {
      id: -1,
      code: '',
      name: 'required',
      text: 'required',
      emailTemplateId: -1,
      emailEnabled: false,
      modificationDate: '',
      mandatory: false,
      translations: setRequiredDefaultsForValidation('auditLogMessage'),
    },
    emailTemplate: {
      progressEnabled: true,
      appealEnabled: false,
      canToggleAppeal: false,
      translations: setRequiredDefaultsForValidationStageEmail(),
    },
  },
  vendorDeletion: {
    responseRequired: false,
    vendorEngagements: [],
  },
};

export const useStageEmailCustomization = () => {
  const [requestWorkflowInitialData, setRequestWorkflowInitialData] =
    useState<UpdateStageSettingsPostType>(requestWorkflowDefaultData);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emailTemplateId, setEmailTemplateId] = useState<number>(0);
  const [stageEmail, setStageEmail] = useState<{ configType: StageConfigType; config: StageEmailConfigurationType }>();
  const { languagesDropdownOptions: availableLanguages } = AvailableLanguagesContainer.useContainer();

  const requestWorkflowValidationSchema = useMemo(
    () =>
      makeStageWorkflowValidationSchema(
        availableLanguages,
        stageEmail?.config.code === RequestWorkflowStageEmailCode.VENDOR_DELETION_REQUEST,
      ),
    [availableLanguages, stageEmail?.config.code],
  );

  const formik = useFormik<UpdateStageSettingsPostType>({
    initialValues: requestWorkflowInitialData,
    enableReinitialize: true,
    validationSchema: requestWorkflowValidationSchema,
    validateOnChange: true,
    onSubmit: async (values, { resetForm }) => {
      if (!stageEmail) return;

      await Promise.all([
        stageEmail.configType === 'general' &&
          updateStageEmail(stageEmail.config.id, {
            ...omit(values.general.configuration, ['vendorEngagements']),
            vendorEngagementIds: values.general.configuration.vendorEngagements?.map(({ value }) => value),
          }),
        stageEmail.configType === 'manual' &&
          updateManualCloseResolutions(stageEmail.config.id, values.manual.configuration),
        stageEmail.configType === 'auto' && updateAutoCloseResolutions(stageEmail.config.id, values.auto.configuration),
        values[stageEmail.configType].emailTemplate &&
          updateEmailTemplate(emailTemplateId, {
            ...values[stageEmail.configType].emailTemplate,
            ...encodeWorkflowEmail(values[stageEmail.configType].emailTemplate),
          }),
      ]);

      resetForm({ values: requestWorkflowDefaultData });
    },
  });

  const fetchStageEmailData = useCallback(async () => {
    if (!emailTemplateId) return;
    if (!stageEmail) return;

    setIsLoading(true);

    const emailData = await fetchEmailTemplate(emailTemplateId);

    setRequestWorkflowInitialData(prev => ({
      ...prev,
      [stageEmail.configType]: {
        configuration: {
          ...stageEmail.config, // @TODO: make it declarative, add to type
          enabled: stageEmail.config.enabled,
          firstSendAfter: stageEmail.config.firstSendAfter,
          responseRequired: stageEmail.config.responseRequired,
          vendorEngagements: stageEmail.config.vendorEngagements?.map(transformVendorEngagementToOption),
        },
        emailTemplate: {
          translations: emailData.translations,
          progressEnabled: emailData.progressEnabled,
          canToggleAppeal: emailData.canToggleAppeal,
          appealEnabled: emailData.appealEnabled,
          authorizedAgentMessageMode: emailData.authorizedAgentMessageMode,
        },
      },
    }));
    setIsLoading(false);
  }, [emailTemplateId, stageEmail]);

  useEffect(() => {
    fetchStageEmailData();
  }, [fetchStageEmailData]);

  return {
    formikEmail: formik,
    isLoading,
    emailTemplateId,
    stageEmail,
    setStageEmail,
    setEmailTemplateId,
  };
};

export const StageEmailCustomizationContainer = createContainer(useStageEmailCustomization);
