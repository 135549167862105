import React, { useState } from 'react';
import { FileDrop } from 'react-file-drop';
import { useStyles } from './FileUploaderLinkStyles';
import { BigidMeTooltipWithIcon } from '../BigidMeTooltipWithIcon';
import { BigidAttachmentIcon } from '@bigid-ui/icons';
import { TertiaryButton } from '@bigid-ui/components';
import { noop } from 'lodash';
import { Box, Stack } from '@mui/system';

const KILOBYTE = 1024;
export interface FileUploaderLinkPropsType {
  format: string;
  isDisabled?: boolean;
  tooltip?: string;
  maxSize?: number;
  maxFiles?: number;
  testId?: string;
  onSelect: (filename: string, file: File) => void;
}

export const FileUploaderLink = ({
  onSelect,
  format,
  isDisabled,
  maxSize = 10,
  maxFiles = 5,
  tooltip,
  testId,
}: FileUploaderLinkPropsType) => {
  const [uploadError, setUploadErrorStatus] = useState('');
  const classes = useStyles();

  const onSelectFile = (files: FileList | null) => {
    if (!files) return;

    if (files[0].size / (KILOBYTE * KILOBYTE) > maxSize) {
      const error = `Maximum file size: ${maxSize}MB per file.`;
      setUploadErrorStatus(error);
      return;
    }

    const formatRegExp = new RegExp(`(${format.replace(/,\s?/g, '|')})$`, 'i');
    if (!formatRegExp.test(files[0].name)) {
      const error = 'Wrong file format';
      setUploadErrorStatus(error);
      return;
    }
    onSelect(files[0].name, files[0]);
    setUploadErrorStatus('');
  };

  const onFileChange = (event: { target: { files: FileList | null; value: string } }) => {
    onSelectFile(event.target.files);
    event.target.value = '';
  };

  return (
    <label className={classes.root}>
      <FileDrop onDrop={files => onSelectFile(files)}>
        <Stack flexDirection="row" alignItems="center">
          <TertiaryButton size="medium" onClick={noop} startIcon={<BigidAttachmentIcon />} text="Upload File" />
          <input
            type="file"
            disabled={isDisabled}
            data-aid={testId || 'file-upload'}
            accept={format}
            multiple={false}
            className={classes.upload}
            onChange={onFileChange}
          />
          {tooltip && (
            <Box zIndex={1}>
              <BigidMeTooltipWithIcon title={tooltip} />
            </Box>
          )}
        </Stack>
        {uploadError && <div className={classes.error}>{uploadError}</div>}
        {isDisabled && <div className={classes.error}>Max. {maxFiles} files</div>}
      </FileDrop>
    </label>
  );
};
