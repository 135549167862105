import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  meFileUploadRoot: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  fileDrop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    padding: '20px 28px',
  },
  dropdownArea: {
    position: 'relative',
    width: '100%',
    border: `1px  dashed ${theme.vars.palette.bigid?.blue900}`,
    borderRadius: 12,
  },
  uploadWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadInputWrapper: {
    position: 'relative',
    padding: '0 20px',
  },
  title: {
    fontSize: '0.8125rem',
    fontWeight: 600,
  },
  subTitle: {
    fontSize: '0.6875rem',
    color: theme.vars.palette.bigid?.gray400,
  },
  link: {
    color: theme.vars.palette.bigid?.blue900,
  },
  upload: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '6px 0',
    display: 'inline-block',
    opacity: 0,
    cursor: 'pointer',
  },
}));
