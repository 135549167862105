import { getDataSourceIconByDsType } from '@bigid-ui/icons';

export const transformVendorEngagementToOption = ({
  id,
  name,
  vendorType,
}: {
  id: number;
  name: string;
  vendorType: string;
}) => ({
  id: `${id}`,
  value: id,
  displayValue: name,
  icon: getDataSourceIconByDsType(vendorType),
});
