export const blue = {
  50: '#E7F2F6',
  75: '#F1F5FF',
  100: '#CFE9F5',
  200: '#B8E6FB',
  300: '#94D9F8',
  400: '#5CCBFC',
  500: '#2DB9F6',
  600: '#06ABF4',
  750: '#4B7BFF',
  900: '#0890CC',
};

export const gray = {
  25: '#FCFCFC',
  50: '#F5F5F5',
  75: '#F7F7F7',
  100: '#EEEEEE',
  150: '#EAEAEA',
  175: '#EDEDED',
  200: '#DDDDDD',
  250: '#D4D4D4',
  275: '#D6D6D6',
  300: '#CCCCCC',
  400: '#AAAAAA',
  500: '#999999',
  600: '#777777',
  650: '#727171',
  700: '#555555',
  725: '#4F4F4F',
  730: '#4A4A4A',
  750: '#3E3E3E',
  775: '#292929',
  800: '#222222',
};

export const orangeScheme = {
  50: '#FF7021',
  100: '#FBF6F0',
};

export const white = '#FFFFFF';
export const black = '#000000';
export const linkText = '#2177CC';
export const disabledText = '#BDBDBD';

export const errorColor = '#e81c3d';
export const stepperActive = '#01BFA5';
export const stepperInactive = gray[650];
export const stepperCompletedInactive = '#B3B3B3';
export const messageBackgroundColor = '#949494';
export const backgroundColor = '#f6f6f6';

export const BigidMeColors = {
  white,
  black,
  gray,
  blue,
  orangeScheme,
  disabledText,
  backgroundColor,
};
