import { BigidDropdownOption } from '@bigid-ui/components';
import { CountryType } from '../../../types';

const countryValuePredicate = (
  countryDropdownOption: BigidDropdownOption,
  exceptionResidenciesArr: string[] | undefined,
) => !!exceptionResidenciesArr?.find(exceptionResidence => countryDropdownOption.id === exceptionResidence);

export const getCountryDropdownValueOptions = (
  exceptionResidenciesArr: string[] | undefined,
  bigidDropdownOptions: BigidDropdownOption[],
): BigidDropdownOption[] => {
  const flatBigidDropdownOptions: BigidDropdownOption[] = bigidDropdownOptions.flatMap(option =>
    option.children && option.children.length > 0 ? option.children : option,
  );

  return flatBigidDropdownOptions.filter(conutry => countryValuePredicate(conutry, exceptionResidenciesArr));
};

export const generateCountriesDropdownOptions = (countries: CountryType[]): BigidDropdownOption[] => {
  return countries.map(country => ({
    id: country.name,
    value: country.code,
    displayValue: country.displayName,
    children: (country.divisions || []).map(division => ({
      id: country.name + '-' + division.code,
      value: division.code,
      displayValue: division.displayName,
      parentId: country.name,
    })),
  }));
};
