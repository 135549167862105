import React, { ReactElement, ReactNode } from 'react';
import { useStyles } from './SimpleTableStyles';
import cx from 'classnames';

export interface SimpleTablePropsType {
  th: ReactNode[];
  tr?: ReactNode[];
  trElement?: ReactElement;
  children?: ReactNode;
  thClasses?: string[];
  testId?: string;
  bodyTestId?: string;
  thTestId?: string;
  className?: string;
}

export const SimpleTable = ({
  th,
  tr,
  trElement,
  children,
  thClasses,
  testId = '',
  bodyTestId = '',
  thTestId = '',
  className,
}: SimpleTablePropsType) => {
  const classes = useStyles();

  return (
    <table className={cx(classes.root, className)} cellSpacing="0" data-aid={testId}>
      <thead>
        <tr>
          {th.map((title: ReactNode, idx: number) => (
            <th key={idx} className={thClasses && thClasses[idx]} data-aid={`${thTestId}-${idx}`}>
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody data-aid={bodyTestId} className={classes.body}>
        {trElement && trElement}
        {tr && tr}
      </tbody>
      {children && children}
    </table>
  );
};
