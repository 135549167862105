import makeStyles from '@mui/styles/makeStyles';
import { chartColors, primaryBorder } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 202,
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  tooltipDate: {
    color: theme.vars.palette.bigid?.gray700,
    borderBottom: primaryBorder,
  },
  chipRoot: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  chipIcon: {
    width: 8,
    height: 8,
    borderRadius: 2,
  },
  chipText: {
    margin: '0 30px 0 12px',
  },
  chipValue: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    color: chartColors.chipValueColor,
    marginRight: 7,
  },
  chipLabel: {
    fontSize: '0.8125rem',
    color: theme.vars.palette.bigid?.gray700,
  },
}));
