import { styled } from '@mui/material/styles';
import { BigidColorsV2 } from '@bigid-ui/components';

const PREFIX = 'WorkflowCustomizationOnboardingStep';

export const classes = {
  image: `${PREFIX}-image`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
};

export const Step = styled('div')({
  [`& .${classes.image}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${classes.title}`]: {
    textAlign: 'center',
    color: BigidColorsV2.purple[400],
    marginTop: 8,
  },
  [`& .${classes.description}`]: {
    marginTop: 8,
    textAlign: 'center',
  },

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});
