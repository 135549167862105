import React, { FC, ReactNode } from 'react';
import { LayoutEmptyState } from '../LayoutEmptyState';
import { Stack } from '@mui/system';

export interface GridNoDataProps {
  testId?: string;
  illustration?: ReactNode;
  description: string;
  actions?: { label: string; onClick: () => void; testId?: string }[];
}

export const GridNoData: FC<GridNoDataProps> = ({ testId = 'GridNoData', illustration, description, actions }) => (
  <Stack position="absolute" height="100%" width="100%" alignItems="center" justifyContent="center" data-aid={testId}>
    <LayoutEmptyState description={description} illustration={illustration} actions={actions} />
  </Stack>
);
