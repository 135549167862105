import { isArray } from 'lodash';
import {
  AuthorizedAgentFieldNameType,
  AuthorizedAgentFieldType,
  FieldDefinition,
  FieldsType,
  FormFieldType,
  ManagePreferencesItemType,
  ManagePreferencesSectionsType,
} from '../services';

export const updateManagePreferencesDataSection = (
  prev: ManagePreferencesSectionsType[],
  sectionIndex: number,
  defineIsSelected: (index: number, item: ManagePreferencesItemType) => boolean,
) => {
  const newData = [...prev];
  const newSection = { ...newData[sectionIndex] };
  newSection.items = newSection.items.map((item: ManagePreferencesItemType, index: number) => ({
    ...item,
    selected: defineIsSelected(index, item),
  }));
  newData[sectionIndex] = newSection;
  return newData;
};

export const updateManagePreferencesDataOptOutSection = (
  prev: ManagePreferencesSectionsType[],
  sectionIndex: number,
  itemIndex: number,
  selected: boolean,
) => {
  const newData = [...prev];
  const newSection = { ...newData[sectionIndex] };
  newSection.items = newSection.items.map((item: ManagePreferencesItemType, index: number) => ({
    ...item,
    selected: itemIndex === index ? selected : item.selected,
  }));
  newData[sectionIndex] = newSection;
  return newData;
};

export const setRadioSectionSelectionForPreview = (items: ManagePreferencesItemType[], selected: boolean | null) => {
  return items.map((item: ManagePreferencesItemType) => ({
    ...item,
    selected,
  }));
};

export const setPageTitle = (text: string | string[]) => {
  if (isArray(text)) {
    document.title = text.join(' | ');
  } else {
    document.title = text;
  }
};

export const getFieldDefinitionType = (field: FieldDefinition, config: Record<string, unknown>) => {
  if (config[field.id]) {
    return field.id;
  }

  if (field.fieldType === FormFieldType.DATE) {
    return FieldsType.DATE;
  }

  if (field.fieldType === FormFieldType.SINGLE_SELECTION) {
    return FieldsType.SINGLE_SELECTION;
  }

  return FieldsType.DEFAULT;
};

export const groupFieldDefinitions = (
  fields: FieldDefinition[],
  config: Record<FieldsType, { group?: string }>,
): FieldDefinition[][] => {
  const result: FieldDefinition[][] = [];
  fields.forEach(field => {
    if (result.length === 0) {
      result.push([field]);
      return;
    }

    const lastGroup = result[result.length - 1];
    const lastGroupName = config[getFieldDefinitionType(lastGroup[0], config)].group;
    const filedGroupName = config[getFieldDefinitionType(field, config)].group;
    if (lastGroupName === filedGroupName) {
      lastGroup.push(field);
    } else {
      result.push([field]);
    }
  });
  return result;
};

export const getAuthorizedAgentFieldType = (field: AuthorizedAgentFieldType, config: Record<string, unknown>) => {
  if (config[field.name]) {
    return field.name;
  }

  return AuthorizedAgentFieldNameType.DEFAULT;
};

export const groupAuthorizedAgentFields = (
  fields: AuthorizedAgentFieldType[],
  config: Record<AuthorizedAgentFieldNameType, { group?: string }>,
): AuthorizedAgentFieldType[][] => {
  const result: AuthorizedAgentFieldType[][] = [];
  fields.forEach(field => {
    if (result.length === 0) {
      result.push([field]);
      return;
    }

    const lastGroup = result[result.length - 1];
    const lastGroupName = config[getAuthorizedAgentFieldType(lastGroup[0], config)].group;
    const filedGroupName = config[getAuthorizedAgentFieldType(field, config)].group;
    if (lastGroupName === filedGroupName) {
      lastGroup.push(field);
    } else {
      result.push([field]);
    }
  });
  return result;
};

export const normalizeLinkToHyperlink = (link = '', protocol = '') => {
  if (!link || link.startsWith('mailto:')) return link;
  if (/@/.test(link) && !/mailto:/.test(link)) return `mailto:${link}`;
  if (!/^(https?:)?\/\//.test(link)) return `${protocol || '//'}${link}`;
  return link;
};
