import React, { FC } from 'react';
import { TooltipRenderProps } from '@bigid-ui/guided-tour';
import lottieJson from '../../../../assets/lotties/AuthorizedAgentLottieFileAnimation.json';
import { StartTooltip } from '../../../GuidedTour/tooltips/StartTooltip';

export const AuthorizedAgentStartTooltip: FC<TooltipRenderProps> = ({ ...props }) => (
  <StartTooltip
    {...props}
    title="Accept requests from Authorized Agents"
    description="Authorized agents, such as legal guardians and holders of power or attorney, are empowered to submit data
        subject access requests (DSARs) on behalf of their representees. Click below to walk through a guided tour of
         how this flow works and how to add it to your privacy portal."
    lottie={lottieJson}
  />
);
