export const DataSourceAssets: Record<string, { filename: string }> = {
  Absorb: { filename: `absorb.svg` },
  '15Five': { filename: `15five.svg` },
  '6sense': { filename: `6sense.svg` },
  '8x8': { filename: `8x8.svg` },
  ActiveCampaign: { filename: `activecampaign.svg` },
  'Adaptive Insights': { filename: `adaptiveinsights.svg` },
  'Adobe Analytics': { filename: `adobeanalytics.svg` },
  Adform: { filename: `adform.svg` },
  'Adobe Creative Cloud': { filename: `adobecreativecloud.svg` },
  'Adobe Target': { filename: `adobetarget.svg` },
  AdvancedMD: { filename: `advancedmd.svg` },
  Aeries: { filename: `aeries.svg` },
  AgileCRM: { filename: `agilecrm.svg` },
  '123FormBuilder': { filename: `123formbuilder.svg` },
  '2Checkout': { filename: `2checkout.svg` },
  Accenture: { filename: `accenture.svg` },
  AccuWeather: { filename: `accuweather.svg` },
  'Act-On': { filename: `acton.svg` },
  'Acuity Scheduling': { filename: `acuityscheduling.svg` },
  Acxiom: { filename: `acxiom.svg` },
  Airtable: { filename: `airtable.svg` },
  'Alexa Top Sites': { filename: `alexatopsites.svg` },
  Alfresco: { filename: `alfresco.svg` },
  Algolia: { filename: `algolia.svg` },
  Acumatica: { filename: `acumatica.svg` },
  'Adobe Analytics Live Stream': { filename: `adobeanalyticslivestream.svg` },
  'Adobe Experience Manager': { filename: `adobeexperiencemanager.svg` },
  'Adobe Marketing Cloud': { filename: `adobemarketingcloud.svg` },
  AdRoll: { filename: `adroll.svg` },
  Affinity: { filename: `affinity.png` },
  Airbrake: { filename: `airbrake.svg` },
  'Agile CRM': { filename: `agilecrm2.svg` },
  Ahrefs: { filename: `ahrefs.png` },
  Aircall: { filename: `aircall.svg` },
  AlertEnterprise: { filename: `alertenterprise.svg` },
  'Alexa Web Information Service': { filename: `alexawebinformationservice.svg` },
  Alteryx: { filename: `alteryx.svg` },
  'ADP Workforce Now': { filename: `adpworkforcenow.png` },
  Altify: { filename: `altify.png` },
  Amazon: { filename: `amazon.png` },
  'Amazon Athena': { filename: `amazonathena.svg` },
  'Adapt.io': { filename: `adapt.io.png` },
  'Amazon CloudWatch': { filename: `amazoncloudwatch.svg` },
  'Amazon Cognito': { filename: `amazoncognito.svg` },
  Adstream: { filename: `adstream.png` },
  'Amazon EC2': { filename: `amazonec2.svg` },
  'Amazon Lamdba': { filename: `amazonlamdba.svg` },
  'Amazon Lex': { filename: `amazonlex.svg` },
  'Amazon Connect': { filename: `amazonconnect.svg` },
  'Amazon DynamoDB': { filename: `amazondynamodb.svg` },
  'Amazon Polly': { filename: `amazonpolly.svg` },
  'Amazon RDS': { filename: `amazonrds.svg` },
  'Amazon SES': { filename: `amazonses.svg` },
  Ambassador: { filename: `ambassador.svg` },
  'Amazon Marketplace': { filename: `amazonmarketplace.svg` },
  'Amazon CloudFront': { filename: `amazoncloudfront.svg` },
  Anaplan: { filename: `anaplan.svg` },
  AMcards: { filename: `amcards.svg` },
  Angoss: { filename: `angoss.svg` },
  AngelList: { filename: `angellist.svg` },
  AnyMeeting: { filename: `anymeeting.svg` },
  Amobee: { filename: `amobee.svg` },
  amoCRM: { filename: `amocrm.svg` },
  Amplitude: { filename: `amplitude.svg` },
  Anaconda: { filename: `anaconda.svg` },
  Apparound: { filename: `apparound.svg` },
  Appian: { filename: `appian.svg` },
  Ansible: { filename: `ansible.svg` },
  ArcGis: { filename: `arcgis.svg` },
  Appointlet: { filename: `appointlet.svg` },
  ApiOmat: { filename: `apiomat.svg` },
  'Apache Kafka': { filename: `apachekafka.svg` },
  'Appdirect ISV': { filename: `appdirectisv.svg` },
  Appcues: { filename: `appcues.svg` },
  AppNeta: { filename: `appneta.svg` },
  Apptus: { filename: `apptus.svg` },
  AppDynamics: { filename: `appdynamics.svg` },
  Asana: { filename: `asana.svg` },
  AskNicely: { filename: `asknicely.svg` },
  Apttus: { filename: `apttus.svg` },
  Atlassian: { filename: `atlassian.svg` },
  Aria: { filename: `aria.svg` },
  ARCON: { filename: `arcon.svg` },
  Ariba: { filename: `ariba.svg` },
  AscentERP: { filename: `ascenterp.svg` },
  Aspera: { filename: `aspera.svg` },
  'Asset Panda': { filename: `assetpanda.svg` },
  Attivio: { filename: `attivio.svg` },
  Auth0: { filename: `auth0.svg` },
  'ARTEC IT Solutions': { filename: `artecitsolutions.svg` },
  Avalara: { filename: `avalara.svg` },
  AWeber: { filename: `aweber.svg` },
  'Atlassian Confluence': { filename: `atlassianconfluence.svg` },
  'Attentive Mobile': { filename: `attentivemobile.svg` },
  'Authorize.Net': { filename: `authorize.net.svg` },
  athenahealth: { filename: `athenahealth.png` },
  'AWS Cloudfront': { filename: `awscloudfront.svg` },
  Autopilot: { filename: `autopilot.svg` },
  AutoCAD: { filename: `autocad.svg` },
  'AWS CloudWatch': { filename: `awscloudwatch.svg` },
  Autodesk: { filename: `autodesk.svg` },
  'Autotask Pro': { filename: `autotaskpro.svg` },
  'AWS Cognito': { filename: `awscognito.jpg` },
  'AWS Lambda': { filename: `awslambda.svg` },
  'AWS CodeDeploy': { filename: `awscodedeploy.svg` },
  'AWS SageMaker': { filename: `awssagemaker.png` },
  'AWS CodePipeline': { filename: `awscodepipeline.svg` },
  'AWS Comprehend': { filename: `awscomprehend.png` },
  'AWS Glue': { filename: `awsglue.svg` },
  'AWS Kinesis': { filename: `awskinesis.svg` },
  Axosoft: { filename: `axosoft.svg` },
  'AWS SQS': { filename: `awssqs.svg` },
  'AWS S3': { filename: `awss3.svg` },
  'Azure Dev Ops': { filename: `azuredevops.svg` },
  Azalead: { filename: `azalead.svg` },
  'Azure Active Directory': { filename: `azureactivedirectory.svg` },
  'Azure Virtual Machines': { filename: `azurevirtualmachines.svg` },
  BambooHR: { filename: `bamboohr.svg` },
  B2BSignals: { filename: `b2bsignals.svg` },
  'AWS SNS': { filename: `awssns.svg` },
  'Basecamp 2': { filename: `basecamp2.svg` },
  Beamery: { filename: `beamery.svg` },
  Beeline: { filename: `beeline.svg` },
  Beekeeper: { filename: `beekeeper.svg` },
  Bandwidth: { filename: `bandwidth.svg` },
  Barracuda: { filename: `barracuda.svg` },
  benefitfocus: { filename: `benefitfocus.svg` },
  BetterWorks: { filename: `betterworks.svg` },
  Base: { filename: `base.svg` },
  'Betty Blocks': { filename: `bettyblocks.svg` },
  Belarc: { filename: `belarc.svg` },
  Benchmark: { filename: `benchmark.svg` },
  BeyondTrust: { filename: `beyondtrust.svg` },
  Birst: { filename: `birst.svg` },
  bigid: { filename: `bigid.png` },
  Bigtincan: { filename: `bigtincan.svg` },
  Bitglass: { filename: `bitglass.svg` },
  BigCommerce: { filename: `bigcommerce.svg` },
  'Bill.com': { filename: `bill.com.svg` },
  'Bing Ads': { filename: `bingads.svg` },
  'Bime Analytics': { filename: `bimeanalytics.svg` },
  BlackLine: { filename: `blackline.svg` },
  BirdEye: { filename: `birdeye.svg` },
  BlackBerry: { filename: `blackberry.svg` },
  Bitbucket: { filename: `bitbucket.svg` },
  Bizzabo: { filename: `bizzabo.png` },
  'BMC Remedy': { filename: `bmcremedy.svg` },
  Bitly: { filename: `bitly.svg` },
  Box: { filename: `box.svg` },
  Braintree: { filename: `braintree.svg` },
  Blink: { filename: `blink.svg` },
  BlueJeans: { filename: `bluejeans.svg` },
  Bloomfire: { filename: `bloomfire.svg` },
  Bloomberg: { filename: `bloomberg.svg` },
  'BOARD International': { filename: `boardinternational.svg` },
  Boxever: { filename: `boxever.svg` },
  BrightFunnel: { filename: `brightfunnel.png` },
  Brainshark: { filename: `brainshark.svg` },
  Buffer: { filename: `buffer.svg` },
  Brandfolder: { filename: `brandfolder.png` },
  Braze: { filename: `braze.svg` },
  BuiltWith: { filename: `builtwith.svg` },
  Brightcove: { filename: `brightcove.svg` },
  BrickFTP: { filename: `brickftp.svg` },
  BusinessObjects: { filename: `businessobjects.svg` },
  Brightree: { filename: `brightree.svg` },
  Bugsnag: { filename: `bugsnag.svg` },
  Buildium: { filename: `buildium.svg` },
  CallidusCloud: { filename: `calliduscloud.svg` },
  bullhorn: { filename: `bullhorn.png` },
  'Campaign Monitor Commerce': { filename: `campaignmonitorcommerce.png` },
  Bunchball: { filename: `bunchball.svg` },
  Bynder: { filename: `bynder.svg` },
  Carta: { filename: `carta.svg` },
  'Capsule CRM': { filename: `capsulecrm.svg` },
  Calendly: { filename: `calendly.svg` },
  'Campaign Monitor': { filename: `campaignmonitor.svg` },
  'CA Technologies': { filename: `catechnologies.svg` },
  'Capax Discovery': { filename: `capaxdiscovery.svg` },
  Catchpoint: { filename: `catchpoint.svg` },
  Centrify: { filename: `centrify.svg` },
  Ceridian: { filename: `ceridian.svg` },
  Cassandra: { filename: `cassandra.svg` },
  Cerri: { filename: `cerri.svg` },
  'CCH Tagetik': { filename: `cchtagetik.svg` },
  Chartbeat: { filename: `chartbeat.svg` },
  CensorNet: { filename: `censornet.svg` },
  'Chili Piper': { filename: `chilipiper.svg` },
  CipherCloud: { filename: `ciphercloud.svg` },
  Changepoint: { filename: `changepoint.svg` },
  Chargify: { filename: `chargify.svg` },
  ChartMogul: { filename: `chartmogul.png` },
  Chatwork: { filename: `chatwork.svg` },
  'Cisco Jabber': { filename: `ciscojabber.svg` },
  'Cisco Kinetic': { filename: `ciscokinetic.svg` },
  ClickUp: { filename: `clickup.png` },
  'Cisco Webex Teams': { filename: `ciscowebexteams.svg` },
  Citrix: { filename: `citrix.svg` },
  Clarizen: { filename: `clarizen.svg` },
  Claritysoft: { filename: `claritysoft.svg` },
  'Close.io': { filename: `close.io.svg` },
  CloudSense: { filename: `cloudsense.svg` },
  'Cloud Watch': { filename: `cloudwatch.svg` },
  Clearbit: { filename: `clearbit.svg` },
  'Clear Company': { filename: `clearcompany.svg` },
  'Clubhouse.io': { filename: `clubhouse.io.svg` },
  Coda: { filename: `coda.svg` },
  Cloudflare: { filename: `cloudflare.svg` },
  Clubhouse: { filename: `clubhouse.svg` },
  Concur: { filename: `concur.svg` },
  comScore: { filename: `comscore.svg` },
  'Conga Contracts': { filename: `congacontracts.svg` },
  'Constant Contact': { filename: `constantcontact.svg` },
  ConnectWise: { filename: `connectwise.svg` },
  commercetools: { filename: `commercetools.svg` },
  CodeLathe: { filename: `codelathe.svg` },
  Codeship: { filename: `codeship.svg` },
  Close: { filename: `close.png` },
  Contently: { filename: `contently.svg` },
  Confluent: { filename: `confluent.svg` },
  Conversocial: { filename: `conversocial.svg` },
  Convertkit: { filename: `convertkit.svg` },
  'Core Security': { filename: `coresecurity.svg` },
  Contify: { filename: `contify.svg` },
  CoSchedule: { filename: `coschedule.svg` },
  Copper: { filename: `copper.svg` },
  Coveo: { filename: `coveo.svg` },
  CrunchBase: { filename: `crunchbase.svg` },
  'Cornerstone OnDemand': { filename: `cornerstoneondemand.svg` },
  Cosential: { filename: `cosential.svg` },
  Coupa: { filename: `coupa.svg` },
  CTERA: { filename: `ctera.svg` },
  'Customer.io': { filename: `customer.io.svg` },
  Criteo: { filename: `criteo.png` },
  CRMNEXT: { filename: `crmnext.svg` },
  Crownpeak: { filename: `crownpeak.svg` },
  Datadog: { filename: `datadog.svg` },
  CSV: { filename: `csv.svg` },
  Cue: { filename: `cue.svg` },
  DataFox: { filename: `datafox.svg` },
  Darktrace: { filename: `darktrace.png` },
  Datahug: { filename: `datahug.svg` },
  CyberArk: { filename: `cyberark.svg` },
  Datanyze: { filename: `datanyze.svg` },
  Cvent: { filename: `cvent.svg` },
  DataRobot: { filename: `datarobot.svg` },
  DataXu: { filename: `dataxu.svg` },
  Databricks: { filename: `databricks.svg` },
  'Dassault Systemes': { filename: `dassaultsystemes.svg` },
  Dataiku: { filename: `dataiku.svg` },
  Demandware: { filename: `demandware.svg` },
  Deputy: { filename: `deputy.svg` },
  'Digital River': { filename: `digitalriver.svg` },
  'D&B Hoovers': { filename: `dbhoovers.svg` },
  'Dell Technologies - RSA Security': { filename: `delltechnologiesrsasecurity.svg` },
  Demandbase: { filename: `demandbase.svg` },
  Disqus: { filename: `disqus.svg` },
  Docebo: { filename: `docebo.svg` },
  'Desk.com': { filename: `desk.com.svg` },
  DiscoverOrg: { filename: `discoverorg.svg` },
  DocRaptor: { filename: `docraptor.svg` },
  DocuWare: { filename: `docuware.svg` },
  Dialpad: { filename: `dialpad.svg` },
  Domo: { filename: `domo.svg` },
  Doodle: { filename: `doodle.svg` },
  Drift: { filename: `drift.svg` },
  DocuSign: { filename: `docusign.svg` },
  'Dynamic Yield': { filename: `dynamicyield.svg` },
  Easypromos: { filename: `easypromos.svg` },
  DSI: { filename: `dsi.svg` },
  Easyvista: { filename: `easyvist.svg` },
  Dropbox: { filename: `dropbox.svg` },
  dotdigital: { filename: `dotdigital.png` },
  'Dun & Bradstreet, Inc.': { filename: `dunbradstreetinc.png` },
  Dynamo: { filename: `dynamo.svg` },
  'Duo Security': { filename: `duosecurity.svg` },
  eGain: { filename: `egain.svg` },
  Dynatrace: { filename: `dynatrace.svg` },
  easypost: { filename: `easypost.svg` },
  'Elastic Path': { filename: `elasticpath.svg` },
  'Ellucian Ethos': { filename: `ellucianethos.svg` },
  Emarsys: { filename: `emarsys.svg` },
  Eloqua: { filename: `eloqua.svg` },
  ebay: { filename: `ebay.svg` },
  Egnyte: { filename: `egnyte.svg` },
  Emma: { filename: `emma.svg` },
  Engagio: { filename: `engagio.svg` },
  EISOO: { filename: `eisoo.svg` },
  'Epicor ERP': { filename: `epicorerp.svg` },
  Envoy: { filename: `envoy.svg` },
  Episerver: { filename: `episerver.svg` },
  eTapestry: { filename: `etapestry.svg` },
  ETermin: { filename: `etermin.png` },
  Eventbrite: { filename: `eventbrite.svg` },
  Evergage: { filename: `evergage.svg` },
  Everhour: { filename: `everhour.svg` },
  EverString: { filename: `everstring.png` },
  Eracent: { filename: `eracent.svg` },
  EventGeek: { filename: `eventgeek.png` },
  Everbridge: { filename: `everbridge.svg` },
  Everteam: { filename: `everteam.svg` },
  'Expert System': { filename: `expertsystem.svg` },
  Evernote: { filename: `evernote.svg` },
  Everstring: { filename: `everstring.svg` },
  Fabasoft: { filename: `fabasoft.svg` },
  Facebook: { filename: `facebook.svg` },
  Fairsail: { filename: `fairsail.svg` },
  Evidian: { filename: `evidian.svg` },
  Feedly: { filename: `feedly.svg` },
  'Financial Force': { filename: `financialforce.svg` },
  Firebase: { filename: `firebase.svg` },
  ExponentHR: { filename: `exponenthr.svg` },
  Figma: { filename: `figma.svg` },
  Flow: { filename: `flow.svg` },
  'Financial Edge NXT': { filename: `financialedgenxt.svg` },
  Forcepoint: { filename: `forcepoint.svg` },
  Expensify: { filename: `expensify.svg` },
  'Filemaker Pro': { filename: `filemakerpro.png` },
  'Formstack Documents': { filename: `formstackdocuments.svg` },
  Five9: { filename: `five9.svg` },
  Fivetran: { filename: `fivetran.svg` },
  Flexera: { filename: `flexera.svg` },
  FirstRain: { filename: `firstrain.png` },
  Float: { filename: `float.png` },
  FloQast: { filename: `floqast.svg` },
  FollowAnalytics: { filename: `followanalytics.svg` },
  Freshbooks: { filename: `freshbooks.svg` },
  'Force.com': { filename: `force.com.svg` },
  Formstack: { filename: `formstack.svg` },
  Freshmail: { filename: `freshmail.svg` },
  Freshsales: { filename: `freshsales.svg` },
  Freshworks: { filename: `freshworks.svg` },
  Foursquare: { filename: `foursquare.svg` },
  FPX: { filename: `fpx.svg` },
  'FTP Client': { filename: `ftpclient.svg` },
  'Fudo Security': { filename: `fudosecurity.svg` },
  Freshdesk: { filename: `freshdesk.svg` },
  FreshService: { filename: `freshservice.svg` },
  'Full Circle Insights': { filename: `fullcircleinsights.svg` },
  GeneXus: { filename: `genexus.svg` },
  'Genus Technologies': { filename: `genustechnologies.svg` },
  'Full Contact': { filename: `fullcontact.svg` },
  FullStory: { filename: `fullstory.svg` },
  Funnelback: { filename: `funnelback.svg` },
  GetResponse: { filename: `getresponse.svg` },
  'G2 Crowd': { filename: `g2crowd.svg` },
  'Global Relay': { filename: `globalrelay.svg` },
  'GlobalMeet Collaboration': { filename: `globalmeetcollaboration.svg` },
  Gainsight: { filename: `gainsight.svg` },
  GoodData: { filename: `gooddata.svg` },
  GetFeedback: { filename: `getfeedback.png` },
  GitHub: { filename: `github.svg` },
  'Google Analytics': { filename: `googleanalytics.svg` },
  GitLab: { filename: `gitlab.svg` },
  Gmail: { filename: `gmail.svg` },
  'Google Classroom': { filename: `googleclassroom.svg` },
  'Google Cloud': { filename: `googlecloud.svg` },
  'Gong.io': { filename: `gong.io.svg` },
  'Google Ad Manager': { filename: `googleadmanager.png` },
  'Google Ads': { filename: `googleads.svg` },
  'Google Dialogflow': { filename: `googledialogflow.svg` },
  'Google Docs': { filename: `googledocs.svg` },
  'Google Calendar': { filename: `googlecalendar.svg` },
  'Google BigQuery': { filename: `googlebigquery.svg` },
  'Google DoubleClick': { filename: `googledoubleclick.svg` },
  'Google Cloud Storage': { filename: `googlecloudstorage.svg` },
  'Google Forms': { filename: `googleforms.svg` },
  'Google Drive': { filename: `googledrive.svg` },
  'Google Keep': { filename: `googlekeep.svg` },
  'Google Contacts': { filename: `googlecontacts.png` },
  'Google Search Console': { filename: `googlesearchconsole.png` },
  'Google Sheets': { filename: `googlesheets.svg` },
  'Google Speech to Text': { filename: `googlespeechtotext.svg` },
  'Google Hangouts Meet': { filename: `googlehangoutsmeet.svg` },
  'Google Translate': { filename: `googletranslate.svg` },
  'Google Vision': { filename: `googlevision.svg` },
  'Google Maps': { filename: `googlemaps.svg` },
  'Google Tag Manager': { filename: `googletagmanager.svg` },
  GoToWebinar: { filename: `gotowebinar.svg` },
  'Google Tasks': { filename: `googletasks.svg` },
  'Google Tasks API': { filename: `googletasksapi.svg` },
  Groove: { filename: `groove.svg` },
  Goombal: { filename: `goombal.svg` },
  'G Suite': { filename: `gsuite.svg` },
  GoSquared: { filename: `gosquared.svg` },
  GoToMeeting: { filename: `gotomeeting.svg` },
  Grafana: { filename: `grafana.svg` },
  Gumtree: { filename: `gumtree.svg` },
  Greenhouse: { filename: `greenhouse.svg` },
  'GRM Information Management': { filename: `grminformationmanagement.svg` },
  Growbots: { filename: `growbots.svg` },
  Guru: { filename: `guru.svg` },
  'HCL Domino': { filename: `hcldomino.png` },
  Gusto: { filename: `gusto.svg` },
  'H2O.ai': { filename: `h2o.ai.svg` },
  Haivision: { filename: `haivision.svg` },
  Heap: { filename: `heap.svg` },
  'Help Scout': { filename: `helpscout.png` },
  Halo: { filename: `halo.svg` },
  Harvest: { filename: `harvest.svg` },
  Haufe: { filename: `haufe.svg` },
  Heroku: { filename: `heroku.svg` },
  HighQ: { filename: `highq.svg` },
  HelloSign: { filename: `hellosign.svg` },
  Hive: { filename: `hive.svg` },
  Highspot: { filename: `highspot.svg` },
  Hoopla: { filename: `hoopla.png` },
  Hootsuite: { filename: `hootsuite.png` },
  'HG Focus': { filename: `hgfocus.svg` },
  HipChat: { filename: `hipchat.png` },
  'Hitachi ID Systems': { filename: `hitachiidsystems.svg` },
  'Host Analytics': { filename: `hostanalytics.svg` },
  'HubSpot CRM': { filename: `hubspotcrm.svg` },
  Huddle: { filename: `huddle.svg` },
  'HP Application Lifecycle Management': { filename: `hpapplicationlifecyclemanagement.svg` },
  'IBM DA': { filename: `ibmda.svg` },
  Hyland: { filename: `hyland.svg` },
  'IBM Db2 Warehouse on Cloud': { filename: `ibmdb2warehouseoncloud.svg` },
  HubSpot: { filename: `hubspot.svg` },
  'IBM Watson TTS': { filename: `ibmwatsontts.png` },
  Hunter: { filename: `hunter.svg` },
  'Icertis Contract Management Software': { filename: `icertiscontractmanagementsoftware.svg` },
  iCIMS: { filename: `icims.svg` },
  'IBM DB2': { filename: `ibmdb2.svg` },
  'IBM Watson': { filename: `ibmwatson.svg` },
  'IBM MQ': { filename: `ibmmq.png` },
  'IHS Markit': { filename: `ihsmarkit.svg` },
  'i-exceed': { filename: `iexceed.svg` },
  'IBM Watson Workspace': { filename: `ibmwatsonworkspace.svg` },
  'Influitive AdvocateHub': { filename: `influitiveadvocatehub.svg` },
  'InsideSales.com': { filename: `insidesales.com.svg` },
  IgnitionOne: { filename: `ignitionone.svg` },
  Infer: { filename: `infer.png` },
  IMImobile: { filename: `imimobile.svg` },
  iContact: { filename: `icontact.png` },
  InsideView: { filename: `insideview.svg` },
  'Information Builders': { filename: `informationbuilders.svg` },
  Instapage: { filename: `instapage.svg` },
  Instagram: { filename: `instagram.svg` },
  Infor: { filename: `infor.svg` },
  Infusionsoft: { filename: `infusionsoft.svg` },
  IQMS: { filename: `iqms.svg` },
  Iterable: { filename: `iterable.svg` },
  Ivanti: { filename: `ivanti.svg` },
  Insightly: { filename: `insightly.svg` },
  Intercom: { filename: `intercom.svg` },
  iOffice: { filename: `ioffice.svg` },
  Jenkins: { filename: `jenkins.svg` },
  Jira: { filename: `jira.svg` },
  'Jira Cloud': { filename: `jira-cloud.png` },
  'Jira Service Desk': { filename: `jiraservicedesk.svg` },
  jive: { filename: `jive.png` },
  Jobvite: { filename: `jobvite.svg` },
  Jabmo: { filename: `jabmo.png` },
  JDBC: { filename: `jdbc.png` },
  Jedox: { filename: `jedox.svg` },
  Kaltura: { filename: `kaltura.svg` },
  JobScience: { filename: `jobscience.svg` },
  'Kaufman Hall': { filename: `kaufmanhall.svg` },
  JotForm: { filename: `jotform.svg` },
  JumpCloud: { filename: `jumpcloud.png` },
  Kafka: { filename: `kafka.svg` },
  Kahuna: { filename: `kahuna.svg` },
  'KBM Group': { filename: `kbmgroup.png` },
  'Kanban Tool': { filename: `kanbantool.png` },
  Kapost: { filename: `kapost.svg` },
  Kenandy: { filename: `kenandy.svg` },
  'Kazoo HR': { filename: `kazoohr.png` },
  Kepion: { filename: `kepion.svg` },
  Keas: { filename: `keas.svg` },
  Keatext: { filename: `keatext.png` },
  Kintone: { filename: `kintone.svg` },
  Keen: { filename: `keen.svg` },
  Klaviyo: { filename: `klaviyo.svg` },
  Kenshoo: { filename: `kenshoo.svg` },
  Knack: { filename: `knack.svg` },
  'Kentico Software': { filename: `kenticosoftware.svg` },
  KNIME: { filename: `knime.svg` },
  Kibo: { filename: `kibo.svg` },
  Kony: { filename: `kony.svg` },
  KeyedIn: { filename: `keyedin.svg` },
  Kickbox: { filename: `kickbox.svg` },
  Kronos: { filename: `kronos.svg` },
  Laserfiche: { filename: `laserfiche.svg` },
  'Launch Darkly': { filename: `launchdarkly.svg` },
  KnowledgeOwl: { filename: `knowledgeowl.svg` },
  'KZO Innovations': { filename: `kzoinnovations.svg` },
  LeafLink: { filename: `leaflink.svg` },
  Kochava: { filename: `kochava.svg` },
  Lattice: { filename: `lattice.png` },
  LeadSquared: { filename: `leadsquared.svg` },
  LDAP: { filename: `ldap.png` },
  Librato: { filename: `librato.svg` },
  Liferay: { filename: `liferay.svg` },
  Lingotek: { filename: `lingotek.svg` },
  LeadGnome: { filename: `leadgnome.png` },
  LeadGenius: { filename: `leadgenius.png` },
  LionDesk: { filename: `liondesk.svg` },
  Leadspace: { filename: `leadspace.svg` },
  'Litmos by SAP': { filename: `litmosbysap.svg` },
  'Lithium Communities': { filename: `lithiumcommunities.svg` },
  LeadMaster: { filename: `leadmaster.svg` },
  Leanplum: { filename: `leanplum.svg` },
  LinkedIn: { filename: `linkedin.svg` },
  Lithium: { filename: `lithium.svg` },
  Lodash: { filename: `lodash.svg` },
  Localytics: { filename: `localytics.svg` },
  LivePerson: { filename: `liveperson.svg` },
  LogMeIn: { filename: `logmein.svg` },
  LiveChat: { filename: `livechat.svg` },
  LiveRamp: { filename: `liveramp.svg` },
  'Load test': { filename: `loadtest.svg` },
  Lob: { filename: `lob.svg` },
  Longview: { filename: `longview.svg` },
  'Logic Monitor': { filename: `logicmonitor.png` },
  'Magento 2': { filename: `magento2.svg` },
  'Logi Analytics': { filename: `logianalytics.svg` },
  'Logi Info': { filename: `logiinfo.svg` },
  Mailchimp: { filename: `mailchimp.svg` },
  mailchimp: { filename: `mailchimp.svg` },
  Mailjet: { filename: `mailjet.png` },
  Looker: { filename: `looker.svg` },
  ManageEngine: { filename: `manageengine.svg` },
  Lucidworks: { filename: `lucidworks.svg` },
  MadKudu: { filename: `madkudu.svg` },
  'Mad Mimi': { filename: `madmimi.png` },
  Marketo: { filename: `marketo.svg` },
  MathWorks: { filename: `mathworks.svg` },
  Mattermost: { filename: `mattermost.svg` },
  'Mammoth with Think HR': { filename: `mammothwiththinkhr.svg` },
  Medallia: { filename: `medallia.svg` },
  MediaMath: { filename: `mediamath.svg` },
  'Marin Software': { filename: `marinsoftware.svg` },
  Mattermark: { filename: `mattermark.svg` },
  MeisterTask: { filename: `meistertask.svg` },
  Mendix: { filename: `mendix.svg` },
  Meta4: { filename: `meta4.svg` },
  'Metadata.io': { filename: `metadata.io.jpg` },
  McAfee: { filename: `mcafee.svg` },
  'M-Files': { filename: `mfiles.svg` },
  'Microsoft Dynamics 365': { filename: `microsoftdynamics365.svg` },
  'Microsoft Live OneDrive': { filename: `microsoftliveonedrive.svg` },
  MessageBird: { filename: `messagebird.svg` },
  'Microsoft Office 365': { filename: `microsoftoffice365.svg` },
  'Microsoft Office 365 Outlook.com': { filename: `microsoftoffice365outlook.com.svg` },
  MediaPlatform: { filename: `mediaplatform.svg` },
  'Microsoft Azure': { filename: `microsoftazure.svg` },
  Mavenlink: { filename: `mavenlink.svg` },
  'Microsoft Live Outlook': { filename: `microsoftliveoutlook.svg` },
  MicroStrategy: { filename: `microstrategy.svg` },
  'Microsoft OneDrive for Business': { filename: `microsoftonedriveforbusiness.svg` },
  Mindbody: { filename: `mindbody.svg` },
  'Microsoft Sharepoint': { filename: `microsoftsharepoint.svg` },
  'Microsoft Power BI': { filename: `microsoftpowerbi.svg` },
  Mixmax: { filename: `mixmax.svg` },
  Mixpanel: { filename: `mixpanel.svg` },
  'Microsoft SQL Server': { filename: `microsoftsqlserver.svg` },
  'Microsoft Text Translate': { filename: `microsofttexttranslate.svg` },
  'Model N': { filename: `modeln.svg` },
  Mimecast: { filename: `mimecast.svg` },
  Monetate: { filename: `monetate.svg` },
  MongoDB: { filename: `mongodb.svg` },
  'M365 Privacy Management': { filename: `m365.png` },
  'Microsoft Teams': { filename: `microsoftteams.png` },
  MobileFrame: { filename: `mobileframe.svg` },
  Mode: { filename: `mode.svg` },
  MoEngage: { filename: `moengage.svg` },
  Moxtra: { filename: `moxtra.svg` },
  MySQL: { filename: `mysql.svg` },
  Namely: { filename: `namely.svg` },
  Monday: { filename: `monday.svg` },
  NationBuilder: { filename: `nationbuilder.svg` },
  movingimage: { filename: `movingimage.svg` },
  Netskope: { filename: `netskope.svg` },
  Nasa: { filename: `nasa.svg` },
  NewsAPI: { filename: `newsapi.svg` },
  NewsCred: { filename: `newscred.svg` },
  NetIQ: { filename: `netiq.svg` },
  'NetLine Corporation': { filename: `netlinecorporation.svg` },
  NetSuite: { filename: `netsuite.svg` },
  Nuxeo: { filename: `nuxeo.svg` },
  Oanda: { filename: `oanda.svg` },
  Oath: { filename: `oath.svg` },
  Newgen: { filename: `newgen.svg` },
  'New Relic': { filename: `newrelic.svg` },
  Nexmo: { filename: `nexmo.svg` },
  Octiv: { filename: `octiv.svg` },
  Office365: { filename: `office365.png` },
  Numetric: { filename: `numetric.svg` },
  Oceanos: { filename: `oceanos.svg` },
  Odoo: { filename: `odoo.svg` },
  One2Team: { filename: `one2team.svg` },
  Okta: { filename: `okta.svg` },
  Olark: { filename: `olark.svg` },
  ON24: { filename: `on24.svg` },
  Mindbreeze: { filename: `mindbreeze.svg` },
  'ONEPOINT Projects': { filename: `onepointprojects.svg` },
  'One Identity': { filename: `oneidentity.svg` },
  Ontraport: { filename: `ontraport.png` },
  Omada: { filename: `omada.svg` },
  OneLogin: { filename: `onelogin.svg` },
  'OneStream Software': { filename: `onestreamsoftware.svg` },
  Ooma: { filename: `ooma.svg` },
  'Oracle Bronto': { filename: `oraclebronto.png` },
  OptimoRoute: { filename: `optimoroute.png` },
  'Oracle E-Business Suite EBS': { filename: `oracleebusinesssuiteebs.svg` },
  Opal: { filename: `opal.png` },
  'Oracle CRM on Demand': { filename: `oraclecrmondemand.png` },
  OpenStack: { filename: `openstack.svg` },
  OpenWeatherMap: { filename: `openweathermap.svg` },
  OpsGenie: { filename: `opsgenie.svg` },
  Optimizely: { filename: `optimizely.svg` },
  'Oracle Service Cloud': { filename: `oracleservicecloud.svg` },
  'Oracle Siebel': { filename: `oraclesiebel.svg` },
  OutSystems: { filename: `outsystems.svg` },
  'Oracle PeopleSoft': { filename: `oraclepeoplesoft.svg` },
  Outreach: { filename: `outreach.svg` },
  ownCloud: { filename: `owncloud.svg` },
  'Oracle Responsys': { filename: `oracleresponsys.svg` },
  'Oracle Sales Cloud': { filename: `oraclesalescloud.svg` },
  'Oxford Dictionaries': { filename: `oxforddictionaries.png` },
  'Oracle Taleo': { filename: `oracletaleo.svg` },
  Osirium: { filename: `osirium.svg` },
  PagerDuty: { filename: `pagerduty.svg` },
  Owler: { filename: `owler.svg` },
  PageUp: { filename: `pageup.svg` },
  Panopto: { filename: `panopto.svg` },
  ParseHub: { filename: `parsehub.svg` },
  Paycom: { filename: `paycom.svg` },
  Paylocity: { filename: `paylocity.svg` },
  Paymo: { filename: `paymo.png` },
  PayPal: { filename: `paypal.svg` },
  'Palo Alto Networks': { filename: `paloaltonetworks.svg` },
  PandaDoc: { filename: `pandadoc.svg` },
  PeopleGoal: { filename: `peoplegoal.png` },
  Pendo: { filename: `pendo.svg` },
  Pegasystems: { filename: `pegasystems.svg` },
  Percolate: { filename: `percolate.svg` },
  PGP: { filename: `pgp.svg` },
  'Periscope Data': { filename: `periscopedata.svg` },
  Personio: { filename: `personio.svg` },
  PersistIQ: { filename: `persistiq.svg` },
  PestRoutes: { filename: `pestroutes.svg` },
  'Ping Identity': { filename: `pingidentity.svg` },
  PipelineDeals: { filename: `pipelinedeals.svg` },
  Pipedrive: { filename: `pipedrive.svg` },
  Planview: { filename: `planview.svg` },
  Planisware: { filename: `planisware.svg` },
  Phocas: { filename: `phocas.svg` },
  Plex: { filename: `plex.svg` },
  Pipeliner: { filename: `pipeliner.svg` },
  Pingdom: { filename: `pingdom.svg` },
  'Pivotal Tracker': { filename: `pivotaltracker.svg` },
  Postmates: { filename: `postmates.svg` },
  PowerSchool: { filename: `powerschool.svg` },
  Podio: { filename: `podio.svg` },
  'Product Hunt': { filename: `producthunt.svg` },
  ProdPad: { filename: `prodpad.svg` },
  Podium: { filename: `podium.svg` },
  PortaOne: { filename: `portaone.svg` },
  PostgreSQL: { filename: `postgresql.svg` },
  Progress: { filename: `progress.svg` },
  'Project Objects': { filename: `projectobjects.svg` },
  Procore: { filename: `procore.svg` },
  Productboard: { filename: `productboard.svg` },
  Prophix: { filename: `prophix.svg` },
  Puppet: { filename: `puppet.svg` },
  'Promoter.io': { filename: `promoter.io.svg` },
  QAD: { filename: `qad.svg` },
  Prontoforms: { filename: `prontoforms.svg` },
  Proofpoint: { filename: `proofpoint.svg` },
  Propel: { filename: `propel.svg` },
  QlikView: { filename: `qlikview.svg` },
  Qualtrics: { filename: `qualtrics.svg` },
  PROS: { filename: `pros.svg` },
  Quantcast: { filename: `quantcast.svg` },
  Qubit: { filename: `qubit.svg` },
  QuickBase: { filename: `quickbase.svg` },
  Pyze: { filename: `pyze.svg` },
  'Pyramid Analytics': { filename: `pyramidanalytics.svg` },
  Qualaroo: { filename: `qualaroo.svg` },
  Qualys: { filename: `qualys.svg` },
  Quip: { filename: `quip.svg` },
  RainKing: { filename: `rainking.png` },
  'QuickBooks Online': { filename: `quickbooksonline.svg` },
  'Ramco Systems': { filename: `ramcosystems.svg` },
  Qumu: { filename: `qumu.svg` },
  rabbitmq: { filename: `rabbitmq.svg` },
  "Raider's Edge NXT": { filename: `raidersedgenxt.svg` },
  RapidMiner: { filename: `rapidminer.svg` },
  'Rare.io': { filename: `rare.io.png` },
  'Real Magnet': { filename: `realmagnet.png` },
  Recurly: { filename: `recurly.svg` },
  'QuickBooks Payments': { filename: `quickbookspayments.png` },
  Rally: { filename: `rally.svg` },
  RealPage: { filename: `realpage.svg` },
  Redshift: { filename: `redshift.svg` },
  Reflektion: { filename: `reflektion.svg` },
  'Red Hat': { filename: `redhat.svg` },
  Rejoiner: { filename: `rejoiner.png` },
  'Relational Junction': { filename: `relationaljunction.svg` },
  'ReSci (Retention Science)': { filename: `resciretentionscience.png` },
  Reddit: { filename: `reddit.svg` },
  Replicon: { filename: `replicon.svg` },
  Redis: { filename: `redis.svg` },
  'Return Path': { filename: `returnpath.svg` },
  Reltio: { filename: `reltio.png` },
  Rho: { filename: `rho.png` },
  Resco: { filename: `resco.svg` },
  'Retention Rocket': { filename: `retentionrocket.png` },
  RichRelevance: { filename: `richrelevance.svg` },
  'Rootstock Software': { filename: `rootstocksoftware.svg` },
  Revegy: { filename: `revegy.png` },
  Rollbar: { filename: `rollbar.svg` },
  'Revel Systems': { filename: `revelsystems.svg` },
  'RunRun.it': { filename: `runrun.it.svg` },
  RingCentral: { filename: `ringcentral.svg` },
  'Rocket Fuel': { filename: `rocketfuel.svg` },
  'ruum by SAP': { filename: `ruumbysap.svg` },
  Sailthru: { filename: `sailthru.svg` },
  Saba: { filename: `saba.svg` },
  'Sage Intacct': { filename: `sageintacct.svg` },
  'Saba TalentSpace': { filename: `sabatalentspace.svg` },
  'Sage Live': { filename: `sagelive.svg` },
  'Salesforce Marketing Cloud': { filename: `salesforcemarketingcloud.svg` },
  'Salesforce Pardot': { filename: `salesforcepardot.svg` },
  'Salesforce Service Cloud': { filename: `salesforceservicecloud.svg` },
  SailPoint: { filename: `sailpoint.svg` },
  Salesforce: { filename: `salesforce.svg` },
  Samanage: { filename: `samanage.svg` },
  SalesRabbit: { filename: `salesrabbit.png` },
  SalesforceIQ: { filename: `salesforceiq.svg` },
  Samsara: { filename: `samsara.svg` },
  Salesfusion: { filename: `salesfusion.svg` },
  'SAP Business ByDesign': { filename: `sapbusinessbydesign.svg` },
  'SAP CRM': { filename: `sapcrm.svg` },
  SalesLoft: { filename: `salesloft.svg` },
  'SAP ERP': { filename: `saperp.svg` },
  'SAP Analytics Cloud': { filename: `sapanalyticscloud.svg` },
  'SAP SuccessFactors': { filename: `sapsuccessfactors.svg` },
  SAS: { filename: `sas.svg` },
  Scoop: { filename: `scoop.svg` },
  Sciforma: { filename: `sciforma.svg` },
  'SAP Hybris': { filename: `saphybris.png` },
  ScribbleLive: { filename: `scribblelive.svg` },
  Segment: { filename: `segment.svg` },
  'SAP HANA': { filename: `saphana.svg` },
  SellHack: { filename: `sellhack.svg` },
  SEMrush: { filename: `semrush.svg` },
  SEDNA: { filename: `sedna.png` },
  SDL: { filename: `sdl.svg` },
  Saviynt: { filename: `saviynt.svg` },
  Seismic: { filename: `seismic.png` },
  senhasegura: { filename: `senhasegura.svg` },
  ServiceM8: { filename: `servicem8.svg` },
  'SAP S4Hana': { filename: `saps4hana.png` },
  SendinBlue: { filename: `sendinblue.svg` },
  Sendoso: { filename: `sendoso.svg` },
  Sendgrid: { filename: `sendgrid.svg` },
  SendPulse: { filename: `sendpulse.svg` },
  Showpad: { filename: `showpad.svg` },
  Shippo: { filename: `shippo.png` },
  ServiceMax: { filename: `servicemax.svg` },
  ServiceNow: { filename: `servicenow.svg` },
  Sisense: { filename: `sisense.svg` },
  SessionM: { filename: `sessionm.svg` },
  Sizmek: { filename: `sizmek.svg` },
  Shopify: { filename: `shopify.svg` },
  Sitecore: { filename: `sitecore.svg` },
  SketchUp: { filename: `sketchup.svg` },
  'Signal Vine': { filename: `signalvine.svg` },
  'Sigma Systems': { filename: `sigmasystems.svg` },
  Skyhigh: { filename: `skyhigh.svg` },
  'Simpli.fi': { filename: `simpli.fi.png` },
  Skillsoft: { filename: `skillsoft.svg` },
  Sinequa: { filename: `sinequa.svg` },
  Skyward: { filename: `skyward.svg` },
  Skava: { filename: `skava.svg` },
  Smartlogic: { filename: `smartlogic.svg` },
  SmartrMail: { filename: `smartrmail.png` },
  Skype: { filename: `skype.svg` },
  Smartsheet: { filename: `smartsheet.svg` },
  Snowflake: { filename: `snowflake.svg` },
  Slack: { filename: `slack.svg` },
  Smarsh: { filename: `smarsh.svg` },
  SolarWinds: { filename: `solarwinds.svg` },
  'Sonic Foundry': { filename: `sonicfoundry.svg` },
  SnapApp: { filename: `snapapp.png` },
  SmashFly: { filename: `smashfly.svg` },
  'Snow Software': { filename: `snowsoftware.svg` },
  Spredfast: { filename: `spredfast.png` },
  Sopheon: { filename: `sopheon.svg` },
  Sprinklr: { filename: `sprinklr.svg` },
  Squiz: { filename: `squiz.svg` },
  Spiro: { filename: `spiro.png` },
  'Spiceworks Help Desk': { filename: `spiceworkshelpdesk.svg` },
  Splunk: { filename: `splunk.svg` },
  Splash: { filename: `splash.svg` },
  Springcm: { filename: `springcm.svg` },
  Streak: { filename: `streak.svg` },
  Surefire: { filename: `surefire.svg` },
  SurveyMonkey: { filename: `surveymonkey.svg` },
  Strands: { filename: `strands.svg` },
  STAT: { filename: `stat.svg` },
  Strava: { filename: `strava.svg` },
  SugarCRM: { filename: `sugarcrm.svg` },
  Stripe: { filename: `stripe.svg` },
  'Survey Sparrow': { filename: `surveysparrow.svg` },
  SurveySparrow: { filename: `surveysparrow.png` },
  Swrve: { filename: `swrve.svg` },
  Symantec: { filename: `symantec.svg` },
  Syncplicity: { filename: `syncplicity.svg` },
  Tacton: { filename: `tacton.svg` },
  Swoogo: { filename: `swoogo.svg` },
  SwiftSec: { filename: `swiftsec.svg` },
  Talentsoft: { filename: `talentsoft.svg` },
  Synthesio: { filename: `synthesio.svg` },
  'Tableau Desktop': { filename: `tableaudesktop.svg` },
  Targetbase: { filename: `targetbase.png` },
  'SER Group': { filename: `sergroup.svg` },
  'Team Viewer': { filename: `teamviewer.svg` },
  TeamSupport: { filename: `teamsupport.svg` },
  'Talentia Software': { filename: `talentiasoftware.svg` },
  Tenable: { filename: `tenable.svg` },
  Talkdesk: { filename: `talkdesk.svg` },
  Teradata: { filename: `teradata.svg` },
  TaskRay: { filename: `taskray.svg` },
  Terminus: { filename: `terminus.svg` },
  Thru: { filename: `thru.svg` },
  Teamwork: { filename: `teamwork.svg` },
  TensorFlow: { filename: `tensorflow.svg` },
  Thinkific: { filename: `thinkific.svg` },
  'The Trade Desk': { filename: `thetradedesk.svg` },
  ThoughtSpot: { filename: `thoughtspot.svg` },
  Toggl: { filename: `toggl.svg` },
  TrackVia: { filename: `trackvia.svg` },
  Toutapp: { filename: `toutapp.svg` },
  Tradeshift: { filename: `tradeshift.svg` },
  Thycotic: { filename: `thycotic.svg` },
  Toast: { filename: `toast.svg` },
  Todoist: { filename: `todoist.svg` },
  'Treasure Data': { filename: `treasuredata.svg` },
  Totango: { filename: `totango.svg` },
  TravelBank: { filename: `travelbank.png` },
  Tune: { filename: `tune.svg` },
  Trello: { filename: `trello.svg` },
  Twilio: { filename: `twilio.svg` },
  'Uber Conference': { filename: `uberconference.svg` },
  tumblr: { filename: `tumblr.png` },
  TSheets: { filename: `tsheets.svg` },
  Triblio: { filename: `triblio.jpg` },
  Uberflip: { filename: `uberflip.svg` },
  TimeTap: { filename: `timetap.svg` },
  TurnTo: { filename: `turnto.svg` },
  'Ultimate Software': { filename: `ultimatesoftware.svg` },
  Unbounce: { filename: `unbounce.svg` },
  UltiPro: { filename: `ultipro.svg` },
  Typeform: { filename: `typeform.svg` },
  Unit4: { filename: `unit4.svg` },
  Unilog: { filename: `unilog.svg` },
  Twitter: { filename: `twitter.svg` },
  'Udemy for Business': { filename: `udemyforbusiness.svg` },
  validity: { filename: `validity.svg` },
  Veeva: { filename: `veeva.svg` },
  Upland: { filename: `upland.svg` },
  Upwork: { filename: `upwork.svg` },
  'Urban Airship': { filename: `urbanairship.svg` },
  Usabilla: { filename: `usabilla.svg` },
  Velocify: { filename: `velocify.svg` },
  UserVoice: { filename: `uservoice.svg` },
  Vendavo: { filename: `vendavo.svg` },
  Vbrick: { filename: `vbrick.svg` },
  'Veritas Technologies': { filename: `veritastechnologies.svg` },
  'Vena Solutions': { filename: `venasolutions.svg` },
  Vend: { filename: `vend.svg` },
  VerticalResponse: { filename: `verticalresponse.png` },
  Vibes: { filename: `vibes.svg` },
  VIDIZMO: { filename: `vidizmo.svg` },
  VideoAsk: { filename: `videoask.png` },
  Vidyard: { filename: `vidyard.svg` },
  'Virtuous CRM': { filename: `virtuouscrm.svg` },
  'Verint Systems': { filename: `verintsystems.svg` },
  VictorOps: { filename: `victorops.svg` },
  Verve: { filename: `verve.svg` },
  Vonage: { filename: `vonage.svg` },
  Vtiger: { filename: `vtiger.svg` },
  VTEX: { filename: `vtex.svg` },
  WALLIX: { filename: `wallix.svg` },
  Vlocity: { filename: `vlocity.svg` },
  'VMware Carbon Black': { filename: `vmwarecarbonblack.svg` },
  'Walmart Marketplace': { filename: `walmartmarketplace.png` },
  VWO: { filename: `vwo.svg` },
  Webtrends: { filename: `webtrends.svg` },
  'Weather Underground': { filename: `weatherunderground.svg` },
  WhatCounts: { filename: `whatcounts.png` },
  Wistia: { filename: `wistia.svg` },
  'Wolfram Alpha': { filename: `wolframalpha.png` },
  'Watson Tone Analyzer': { filename: `watsontoneanalyzer.svg` },
  Workable: { filename: `workable.svg` },
  Workiva: { filename: `workiva.svg` },
  Workbooks: { filename: `workbooks.svg` },
  WorkflowMAX: { filename: `workflowmax.png` },
  WordPress: { filename: `wordpress.svg` },
  Workfront: { filename: `workfront.svg` },
  WooCommerce: { filename: `woocommerce.svg` },
  Workstack: { filename: `workstack.svg` },
  'WP Engine': { filename: `wpengine.svg` },
  Wrike: { filename: `wrike.svg` },
  Wufoo: { filename: `wufoo.png` },
  X2Engine: { filename: `x2engine.svg` },
  WorkOtter: { filename: `workotter.svg` },
  'Workplace by Facebook': { filename: `workplacebyfacebook.svg` },
  Yammer: { filename: `yammer.png` },
  'xMatters Inc.': { filename: `xmattersinc.svg` },
  Xero: { filename: `xero.svg` },
  xIQ: { filename: `xiq.png` },
  Wunderlist: { filename: `wunderlist.png` },
  Xactly: { filename: `xactly.svg` },
  Xverify: { filename: `xverify.png` },
  Yellowfin: { filename: `yellowfin.svg` },
  Zaius: { filename: `zaius.svg` },
  Zendesk: { filename: `zendesk.svg` },
  Yesware: { filename: `yesware.svg` },
  'Zendesk Chat': { filename: `zendeskchat.svg` },
  Yext: { filename: `yext.png` },
  Yodiz: { filename: `yodiz.png` },
  'YouCanBook.me': { filename: `youcanbook.me.svg` },
  'Zendesk Sunshine': { filename: `zendesksunshine.svg` },
  'Zendesk Sell': { filename: `zendesksell.svg` },
  YouTube: { filename: `youtube.svg` },
  ZenIQ: { filename: `zeniq.svg` },
  'ZL Technologies': { filename: `zltechnologies.svg` },
  'YouTube Live Streaming': { filename: `youtubelivestreaming.svg` },
  'Zoho Creator': { filename: `zohocreator.svg` },
  Zenefits: { filename: `zenefits.svg` },
  'Zoho People': { filename: `zohopeople.svg` },
  'Zoho Projects': { filename: `zohoprojects.svg` },
  Zoom: { filename: `zoom.svg` },
  ZoomInfo: { filename: `zoominfo.svg` },
  'Zoho CRM': { filename: `zohocrm.svg` },
  'Zoho Desk': { filename: `zohodesk.png` },
  Zuora: { filename: `zuora.svg` },
  'custom-connector': { filename: 'custom-connector.png' },
  hadoop: { filename: 'hadoop.png' },
  mongodb: { filename: 'mongodb.png' },
  'rdb-mysql': { filename: 'rdb-mysql.png' },
  'rdb-oracle': { filename: 'rdb-oracle.png' },
  'rdb-hana': { filename: 'rdb-hana.png' },
  'rdb-postgresql': { filename: 'rdb-postgresql.png' },
  'rdb-redshift': { filename: 'rdb-redshift.png' },
  'rdb-mssql': { filename: 'rdb-mssql.png' },
  nfs: { filename: 'nfs.png' },
  emr: { filename: 'emr.png' },
  s3: { filename: 's3.png' },
  hbase: { filename: 'hbase.png' },
  'hive-jdbc': { filename: 'hive-jdbc.png' },
  smb: { filename: 'smb.png' },
  cassandra: { filename: 'cassandra.png' },
  'rdb-db2': { filename: 'rdb-db2.png' },
  'rdb-db2iseries': { filename: 'rdb-db2iseries.png' },
  'Azure Blob Storage': { filename: 'azure-blob.png' },
  'azure-file': { filename: 'azure-file.png' },
  'onedrive-personal': { filename: 'onedrive-personal.png' },
  googledrive: { filename: 'googledrive.png' },
  salesforce: { filename: 'salesforce.png' },
  dynamodb: { filename: 'dynamodb.png' },
  presto: { filename: 'presto.png' },
  'generic-rest-api': { filename: 'generic-rest-api.png' },
  box: { filename: 'box.png' },
  'rdb-teradata': { filename: 'rdb-teradata.png' },
  sharepoint: { filename: 'sharepoint.png' },
  snowflake: { filename: 'snowflake.png' },
  'o365-onedrive': { filename: 'o365-onedrive.png' },
  'o365-outlook': { filename: 'o365-outlook.png' },
  gmail: { filename: 'gmail.png' },
  'rdb-sybase': { filename: 'rdb-sybase.png' },
  exchange: { filename: 'exchange.png' },
  athena: { filename: 'athena.png' },
  'rdb-activedirectory': { filename: 'rdb-activedirectory.png' },
  'gcp-big-query': { filename: 'gcp-big-query.png' },
  slack: { filename: 'slack.png' },
  csv: { filename: 'csv.png' },
  netsuite: { filename: 'netsuite.png' },
  cims: { filename: 'cims.png' },
  zendesk: { filename: 'zendesk.png' },
  informix: { filename: 'informix.png' },
  workday: { filename: 'workday.png' },
  couchbase: { filename: 'couchbase.png' },
  ldap: { filename: 'ldap.png' },
  elasticsearch: { filename: 'elasticsearch.png' },
  kafka: { filename: 'kafka.png' },
  kinesis: { filename: 'kinesis.png' },
  kafkaconnect: { filename: 'kafkaconnect.png' },
  redis: { filename: 'redis.png' },
  netezza: { filename: 'netezza.png' },
  'scan-api': { filename: 'scan-api.png' },
  servicenow: { filename: 'servicenow.png' },
  'sharepoint-online': { filename: 'sharepoint-online.png' },
  vsam: { filename: 'vsam.png' },
  spanner: { filename: 'spanner.png' },
  sap: { filename: 'sap.png' },
  datastore: { filename: 'datastore.png' },
  'google-cloud-storage': { filename: 'google-cloud-storage.png' },
  'google-bigtable': { filename: 'google-bigtable.png' },
  yellowbrick: { filename: 'yellowbrick.png' },
  documentum: { filename: 'documentum.png' },
  'hadoop-hawq': { filename: 'hadoop-hawq.png' },
  jira: { filename: 'jira.png' },
  'microsoft-teams': { filename: 'microsoft-teams.png' },
  vertica: { filename: 'vertica.png' },
  default: { filename: 'unknown.svg' },
  new: { filename: 'new.svg' },
  Square: { filename: 'square.svg' },
  'Facebook Ads': { filename: 'facebookads.png' },
  'LinkedIn Marketing Solutions': { filename: 'linkedinmarketingsolutions.png' },
  BenchmarkOne: { filename: 'benchmarkone.png' },
  PossibleNow: { filename: 'pnlogo.png' },
  possiblenowprod: { filename: 'pnlogo.png' },
  pendolwc: { filename: `pendo.svg` },
  iterablelwc: { filename: `iterable.svg` },
  bigcommercelwc: { filename: `bigcommerce.svg` },
  asanalwc: { filename: `asana.svg` },
  possiblenowlwc: { filename: 'pnlogo.png' },
  amplitudelwc: { filename: `amplitude.svg` },
  reltio: { filename: `reltio.png` },
  outreachlwc: { filename: `outreach.svg` },
  salesforcelwc: { filename: `salesforce.svg` },
  trellolwc: { filename: `trello.svg` },
  jiralwc: { filename: `jira.svg` },
  mixpanellwc: { filename: `mixpanel.svg` },
  qualtricslwc: { filename: `qualtrics.svg` },
  azureb2clwc: { filename: `azureactivedirectory.svg` },
  auth0lwc: { filename: `auth0.svg` },
  oktalwc: { filename: `okta-lwc.svg` },
};
