import { useEffect } from 'react';
import { getFullPageTitle } from '../utils';

type UsePageTitleParams = {
  enable?: boolean;
};

export const usePageTitle = (title: string, { enable = true }: UsePageTitleParams = {}) => {
  useEffect(() => {
    if (enable) {
      document.title = getFullPageTitle(title);
    }
  }, [title, enable]);
};
