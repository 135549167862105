import { isValid } from 'date-fns';

export const DEFAULT_DATE_FORMAT = 'MMM dd, yyyy';
export const SLASH_DATE_FORMAT = 'dd/MM/yyyy';
export const SLASH_MONTH_DATE_FORMAT = 'MM/dd/yyyy';
export const DEFAULT_CRON_START = '0 9 * * *';

/**
 * BE sending UTC date WITHOUT timezone, so format function represent it as local date.
 *
 * Normalize UTC date value without timezone.
 */
export const formatDateToLocal = (date: string | Date) => {
  if (typeof date !== 'string') {
    return date;
  }

  const normalizedUTCDateStr = date + 'Z';

  try {
    if (isValid(new Date(normalizedUTCDateStr))) {
      return new Date(normalizedUTCDateStr);
    } else {
      return new Date(date);
    }
  } catch (error) {
    console.log(error);

    return new Date();
  }
};
