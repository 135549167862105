import React, { ReactNode } from 'react';
import cx from 'classnames';

import { MeUpgradeButton } from '../MeUpgradeButton';

import { useStyles } from './MePageHeaderStyles';

type MePageHeaderPropsType = {
  title?: ReactNode;
  subTitle?: ReactNode;
  leftsideControls?: ReactNode;
  rightsideControls?: ReactNode;
  className?: string;
};

export const MePageHeader = ({
  title,
  subTitle,
  leftsideControls,
  rightsideControls,
  className,
}: MePageHeaderPropsType) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.controlsWrapper}>
        <div className={classes.titleWrapper}>
          <span className={classes.title}>{title}</span>
          {subTitle}
        </div>
        {leftsideControls}
      </div>
      <div className={classes.controlsWrapper}>
        <MeUpgradeButton />
        {rightsideControls && <div className={classes.rightsideControlsSpacer} />}
        {rightsideControls}
      </div>
    </div>
  );
};
