import { BigidBody1, BigidColorsV2 } from '@bigid-ui/components';
import { styled } from '@mui/material';
import { AppealGuidedTourStepTemplateProps } from './AppealGuidedTourStepTemplate';

export const AppealGuidedTourStepTemplateStyled = {
  Root: styled('div')<Pick<AppealGuidedTourStepTemplateProps, 'width' | 'height' | 'stepIndex'>>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    height: ${({ height }) => (height ? `${height}px` : 'auto')};
    align-items: start;
    padding: 20px 30px 0 20px;
  `,
  Text: styled('div')`
    text-align: left;
  `,
  Title: styled(BigidBody1)<Pick<AppealGuidedTourStepTemplateProps, 'icon'>>`
    margin-top: ${({ icon }) => (icon ? `20px` : '0px')};
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 24px;
    margin-bottom: 16px;
    color: ${BigidColorsV2.gray[700]};
  `,
  Spacer: styled('br')``,
  StepBody: styled(BigidBody1)<Pick<AppealGuidedTourStepTemplateProps, 'icon'>>`
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 160%;
    color: ${BigidColorsV2.gray[700]};
  `,
};
