import { useState } from 'react';

const useStep = (stepTotal: number, defaultActiveStep = 0) => {
  const [step, setStep] = useState<number>(defaultActiveStep || 0);

  const isLastStep = step === stepTotal - 1;

  const stepForward = () => setStep(currStep => currStep + 1);
  const stepBack = () => setStep(currStep => currStep - 1);

  return {
    step,
    setStep,
    stepBack,
    stepForward,
    isLastStep,
  };
};

export default useStep;
