import React, { FC, useState } from 'react';
import { BigidMenu } from '@bigid-ui/components';
import { useRefMap } from 'react-use-ref-map';
import { Root, classes } from './ManualCloseResolutionTableStyles';

import {
  ManualCloseResolutionsGetType,
  ManualRequestResolutionsType,
  StageConfigType,
  StageEmailConfigurationType,
} from '../../../types';
import { SimpleTable } from '../../../components/SimpleTable';
import { ManualCloseResolutionRow } from './ManualCloseResolutionRow';

const TABLE_EMAIL_HEADERS_RESOLUTIONS = ['Names', 'Triggers', 'Last Update', 'Enabled', ''];
const TABLE_EMAIL_HEADERS_CLASSES = ['', classes.triggerTd, '', classes.activeTd, classes.activeTd];

interface ManualCloseResolutionTablePropsType {
  tableType: ManualRequestResolutionsType;
  rows: ManualCloseResolutionsGetType[];
  onDefaultManualCloseResolution: (indexMenu: number) => void;
  setDeleteDialogData: (arg: { type: StageConfigType; id: number }) => void;
  onRowClick: (configType: StageConfigType, row: StageEmailConfigurationType) => void;
}

export const ManualCloseResolutionTable: FC<ManualCloseResolutionTablePropsType> = ({
  tableType,
  rows,
  onDefaultManualCloseResolution,
  setDeleteDialogData,
  onRowClick,
}) => {
  const [setRef, refs] = useRefMap();
  const [openIndexMenu, setOpenIndexMenu] = useState<number>(NaN);

  return (
    <Root>
      <SimpleTable
        th={TABLE_EMAIL_HEADERS_RESOLUTIONS}
        thClasses={TABLE_EMAIL_HEADERS_CLASSES}
        bodyTestId={`manualclose-${tableType}-resolutions-table`}
        trElement={
          <ManualCloseResolutionRow
            rows={rows}
            onRowClick={onRowClick}
            setOpenIndexMenu={setOpenIndexMenu}
            setRef={setRef}
          />
        }
      />
      <BigidMenu
        open={!!openIndexMenu}
        anchorEl={refs.current[openIndexMenu]}
        items={[
          {
            id: 'default',
            label: 'Set as Default',
            dataAid: 'default-action',
            onClick: () => onDefaultManualCloseResolution(openIndexMenu),
          },
          {
            id: 'delete',
            label: 'Delete',

            disabled: rows && rows.length === 1,
            dataAid: 'delete-action',
            onClick: () => setDeleteDialogData({ type: 'manual', id: openIndexMenu }),
          },
        ]}
        onMenuClose={() => setOpenIndexMenu(NaN)}
      />
    </Root>
  );
};
