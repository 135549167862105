import React from 'react';
import { SecondaryButton, PrimaryButton } from '@bigid-ui/components';
import {
  WorkflowCustomizationOnboardingStep,
  WorkflowCustomizationOnboardingStepProps,
} from './WorkflowCustomizationOnboardingStep';
import Carousel from 'react-material-ui-carousel';
import { Root, classes } from './WorkflowCustomizationOnboardingStyles';
import { useNavigate } from 'react-router-dom';
import { updateUserViewedFeatures } from '../../../services/user/userService';
import useStep from '../../../hooks/useStep';
import { ReleasedFeatureCodeType } from '../../../types/ReleasedFeaturesTypes';

const steps: WorkflowCustomizationOnboardingStepProps[] = [
  {
    imageSrc: 'images/onboarding/workflowCustomizationOnboardingStep1.png',
    title: 'Stages',
    description: 'Enable/Disable stages in request processing',
  },
  {
    imageSrc: 'images/onboarding/workflowCustomizationOnboardingStep2.png',
    title: 'Emails',
    description: 'Customize emails sent to the requestor at specific points in request processing',
  },
  {
    imageSrc: 'images/onboarding/workflowCustomizationOnboardingStep3.png',
    title: 'Resolutions',
    description: 'Create a list of valid reasons for closing requests, both automatically and manually.',
  },
];

const WorkflowCustomizationOnboarding = ({ onClose }: { onClose: () => Promise<void> }) => {
  const navigate = useNavigate();

  const { step, setStep, stepForward, stepBack, isLastStep } = useStep(steps.length);

  const handleSkipClick = async () => {
    await onClose();
  };

  const handleCloseClick = async () => {
    await onClose();
    navigate('/settings/workflow-customization');
  };

  const handleCarouselOnChange = (currStepIndex?: number) => {
    if (currStepIndex) {
      setStep(currStepIndex);
    }
  };

  return (
    <Root>
      <div className={classes.content}>
        <Carousel
          className={classes.carousel}
          index={step}
          autoPlay={false}
          swipe={false}
          cycleNavigation={false}
          onChange={handleCarouselOnChange}
          indicators
          navButtonsAlwaysInvisible
          indicatorIconButtonProps={{ className: classes.indicator }}
          activeIndicatorIconButtonProps={{ className: classes.activeIndicator }}
        >
          {steps.map(step => (
            <WorkflowCustomizationOnboardingStep key={step.imageSrc} {...step} />
          ))}
        </Carousel>
      </div>
      <div className={classes.buttons}>
        <div className={classes.button}>
          <SecondaryButton
            size="medium"
            onClick={handleSkipClick}
            dataAid="WorkflowCustomizationOnboarding-skip-button"
          >
            Skip
          </SecondaryButton>
        </div>
        <div className={classes.buttons}>
          {step > 0 && (
            <div className={classes.button}>
              <SecondaryButton size="medium" onClick={stepBack} dataAid="WorkflowCustomizationOnboarding-back-button">
                Back
              </SecondaryButton>
            </div>
          )}
          {!isLastStep && (
            <div className={classes.button}>
              <PrimaryButton size="medium" onClick={stepForward} dataAid="WorkflowCustomizationOnboarding-next-button">
                Next
              </PrimaryButton>
            </div>
          )}
          {isLastStep && (
            <div className={classes.button}>
              <PrimaryButton
                size="medium"
                onClick={handleCloseClick}
                dataAid="WorkflowCustomizationOnboarding-close-button"
              >
                Take Me There
              </PrimaryButton>
            </div>
          )}
        </div>
      </div>
    </Root>
  );
};

export const workflowCustomizationOnboardingDialogOptions = {
  title: 'Customize Workflows',
  onClose: async () => {
    await updateUserViewedFeatures(ReleasedFeatureCodeType.WORKFLOW_CUSTOMIZATION);
  },
  content: WorkflowCustomizationOnboarding,
};
