import React, { FC, useCallback, useMemo } from 'react';
import {
  BigidPieChart as PieChart,
  BigidBarChart as BarChart,
  BigidMinimizer as Minimizer,
  BigidChartChip as ChartChip,
  MinimizerButtonPositions,
  BigidChartLegendPosition,
  BarChartModes,
  BarChartTitle,
} from '@bigid-ui/components';
import { ReactComponent as IconCollapseExtend } from '../../assets/icons/Icon-CollapseExtend.svg';
import { MinimizerContainer } from '../MinimizerContainer';
import { useStyles } from './MinimizeableStatisticsStyles';
import { RequestsByStatusType, DataRequestsByTypeType } from '../../types/DashboardTypes';
import { normalizeChartDataRequestsByStatus, normalizeChartDataRequestsByTypes } from './utils';
import { BigidMeChartsColorsByLabels, SingleColor } from '../../assets/styles';

export interface MinimizeableStatisticsType {
  title?: string;
  requestsByStatus: RequestsByStatusType;
  requestsByTypes?: DataRequestsByTypeType[] | undefined;
  onMinimize?: (minimized: boolean) => void;
  status?: string;
  barchartColors?: typeof BigidMeChartsColorsByLabels | typeof SingleColor;
  onDoughnutClick?: (category: string) => void;
  onBarClick?: (category: string, type?: string) => void;
  barchartTitle?: BarChartTitle;
  barchartMode?: BarChartModes;
  barchartWithLegend?: boolean;
  barchartLegendPosition?: BigidChartLegendPosition;
  disableBarchartTooltip?: boolean;
  buttonPosition?: MinimizerButtonPositions;
  minimizedByDefault?: boolean;
}

export interface ChartClickEvent {
  status?: string;
  type?: string;
}

export const MinimizeableStatistics: FC<MinimizeableStatisticsType> = ({
  title,
  requestsByStatus,
  requestsByTypes,
  status,
  barchartColors,
  onMinimize,
  onDoughnutClick,
  onBarClick,
  barchartTitle,
  barchartMode,
  barchartWithLegend,
  barchartLegendPosition,
  disableBarchartTooltip,
  buttonPosition = MinimizerButtonPositions.BOTTOM,
  minimizedByDefault,
}) => {
  const classes = useStyles();

  const requestsByStatusNormalized = useMemo(
    () => normalizeChartDataRequestsByStatus(requestsByStatus),
    [requestsByStatus],
  );
  const requestsByTypesNormalized = useMemo(
    () => normalizeChartDataRequestsByTypes(requestsByTypes),
    [requestsByTypes],
  );

  const onDonutClickCallback = useCallback((e: string) => onDoughnutClick?.(e), [onDoughnutClick]);

  return (
    <Minimizer
      icon={<IconCollapseExtend />}
      buttonPosition={buttonPosition}
      onMinimize={onMinimize}
      childrenContainerClassName={classes.innerContainer}
      minimizedByDefault={minimizedByDefault}
    >
      <MinimizerContainer className="general">
        {title && <MinimizerContainer className="title">{title}</MinimizerContainer>}
        <PieChart
          disableFiltering
          data={requestsByStatusNormalized?.data}
          total={requestsByStatusNormalized?.total || 0}
          activeFilter={status}
          onSectorClick={onDoughnutClick}
          minimizedDescription="All requests"
        />
        <MinimizerContainer className="chips">
          {requestsByStatusNormalized?.data?.map(item => (
            <MinimizerContainer key={item.category} className="chip">
              <ChartChip
                color={item.color}
                label={item.category}
                value={item.value}
                onClick={() => onDonutClickCallback(item.category)}
              />
            </MinimizerContainer>
          ))}
        </MinimizerContainer>
      </MinimizerContainer>
      <MinimizerContainer className="bar" skip>
        <BarChart
          title={barchartTitle}
          data={requestsByTypesNormalized}
          mode={barchartMode}
          withLegend={barchartWithLegend}
          legendPosition={barchartLegendPosition}
          barColorsByCategories={barchartColors}
          onBarClick={onBarClick}
          disableTooltip={disableBarchartTooltip}
          disableXAxisTooltip
        />
      </MinimizerContainer>
    </Minimizer>
  );
};
