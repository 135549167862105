import React, { createRef, ReactNode } from 'react';
import { SnackbarProvider, SnackbarKey } from 'notistack';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { SnackbarUtilsConfigurator } from '../../utils';
import { useStyles } from './SnackbarStyles';

export interface SnackbarPropsType {
  children: ReactNode;
}

export const Snackbar = ({ children }: SnackbarPropsType) => {
  const notistackRef = createRef<SnackbarProvider>();
  const notificationsClasses = useStyles();

  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      classes={{
        root: notificationsClasses.root,
        variantInfo: notificationsClasses.info,
        variantSuccess: notificationsClasses.success,
        variantWarning: notificationsClasses.warning,
        variantError: notificationsClasses.error,
      }}
      className={notificationsClasses.content}
      ref={notistackRef}
      action={key => (
        <IconButton onClick={onClickDismiss(key)} disableRipple size="large">
          <Close className={notificationsClasses.icon} />
        </IconButton>
      )}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </SnackbarProvider>
  );
};
