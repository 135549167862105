import React, { FC, useMemo } from 'react';
// eslint-disable-next-line import/no-unresolved
import { LanguageType } from '@consumer/state/languageContainer';
import { languagesToDropdownOptions, languageToDropdownValue } from '../../utils';
import { Root } from './LanguagePickerStyles';
import { BigidDropdown, BigidDropdownOption } from '@bigid-ui/components';
import { AvailableLanguagesContainer } from '../../state/availableLanguagesContainer';

export interface LanguagePickerPropsType {
  language?: LanguageType;
  testId?: string;
  onChange?: (values: LanguageType) => void;
}

export const LanguagePicker: FC<LanguagePickerPropsType> = ({ language = LanguageType.EN, testId, onChange }) => {
  const { languages } = AvailableLanguagesContainer.useContainer();
  const options = useMemo(() => languagesToDropdownOptions(languages), [languages]);

  const handleLanguageChange = (values: BigidDropdownOption[]) => {
    onChange && onChange(values[0]?.value as LanguageType);
  };

  if (languages?.length <= 1) return null;

  return (
    <Root>
      <BigidDropdown
        value={languageToDropdownValue(language)}
        options={options}
        onSelect={handleLanguageChange}
        dataAid={testId}
      />
    </Root>
  );
};
