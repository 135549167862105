import { styled } from '@mui/material/styles';
import { BORDER_RADIUS } from '../../../assets/styles';

const PREFIX = 'RequestCustomization';

export const classes = {
  root: `${PREFIX}-root`,
  stickyLayout: `${PREFIX}-stickyLayout`,
  header: `${PREFIX}-header`,
  wrapper: `${PREFIX}-wrapper`,
  stageLayout: `${PREFIX}-stageLayout`,
  toastHeader: `${PREFIX}-toastHeader`,
  toastDescription: `${PREFIX}-toastDescription`,
  tableSubTitle: `${PREFIX}-tableSubTitle`,
  tableDescription: `${PREFIX}-tableDescription`,
  tableWrapper: `${PREFIX}-tableWrapper`,
  tr: `${PREFIX}-tr`,
  dashCentering: `${PREFIX}-dashCentering`,
  activeTd: `${PREFIX}-activeTd`,
  triggerTd: `${PREFIX}-triggerTd`,
  default: `${PREFIX}-default`,
  addNew: `${PREFIX}-addNew`,
  nowrap: `${PREFIX}-nowrap`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    position: 'relative',
    top: -20,
    padding: '0 0 44px',
  },
  [`& .${classes.stickyLayout}`]: {
    position: 'sticky',
    top: -20,
    padding: 20,
    zIndex: 1,
    background: theme.vars.palette.bigid?.gray100,
  },
  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`& .${classes.wrapper}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '55px 0',
    overflow: 'auto',
  },
  [`& .${classes.stageLayout}`]: {
    width: 1100,
    margin: 'auto',
  },
  [`& .${classes.toastHeader}`]: {
    fontWeight: 700,
  },
  [`& .${classes.toastDescription}`]: {
    maxWidth: 450,
  },
  [`& .${classes.tableSubTitle}`]: {
    margin: '24px 0 5px',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: theme.vars.palette.bigid?.gray700,
  },
  [`& .${classes.tableWrapper}`]: {
    background: theme.vars.palette.bigid?.gray200,
    padding: 3,
    borderRadius: BORDER_RADIUS,
  },
  [`& .${classes.tr}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.dashCentering}`]: {
    paddingLeft: 33,
  },
  [`& .${classes.activeTd}`]: {
    textAlign: 'end',
    width: 50,
  },
  [`& .${classes.activeTd} span`]: {
    display: 'inline-flex',
  },
  [`& .${classes.triggerTd}`]: {
    width: 420,
  },
  [`& .${classes.nowrap}`]: {
    whiteSpace: 'nowrap',
  },
  [`& .${classes.default}`]: {
    color: theme.vars.palette.bigid?.gray500,
  },
  [`& .${classes.addNew}`]: {
    overflow: 'hidden',
  },
  [`& .${classes.addNew} span`]: {
    display: 'flex',
    padding: '6px 4px',
    marginTop: 3,
    alignItems: 'center',
    float: 'right',
  },
  [`& .${classes.addNew} span:hover`]: {
    background: theme.vars.palette.bigid?.primary100,
    cursor: 'pointer',
  },
  [`& div[data-aid="email-template__slider-content"]`]: {
    overflowY: 'scroll !important',
  },
  [`& div[data-aid="api-trigger__slider-content"]`]: {
    overflowY: 'scroll !important',
  },
  [`& .${classes.tableDescription}`]: {
    color: '#949494',
    marginBottom: 8,
  },
}));
