import React, { FC, useState } from 'react';
import { BigidMenu } from '@bigid-ui/components';
import { useRefMap } from 'react-use-ref-map';
import { map } from 'lodash';
import { BigidMoreActionIcon } from '@bigid-ui/icons';
import { IconButton } from '@mui/material';
import { Root, classes } from './AutoCloseResolutionTableStyles';

import {
  ActionsWorkflowGetType,
  AutoCloseResolutionsGetType,
  StageConfigType,
  StageEmailConfigurationType,
} from '../../../types';
import { useFormatting } from '../../../hooks/useFormatting';

interface AutoCloseResolutionRowPropsType {
  rows: AutoCloseResolutionsGetType[];
  actionsRows?: ActionsWorkflowGetType[];
  setDeleteDialogData: (arg: { type: StageConfigType; id: number }) => void;
  onRowClick: (configType: StageConfigType, row: StageEmailConfigurationType) => void;
}

export const AutoCloseResolutionRow: FC<AutoCloseResolutionRowPropsType> = ({
  rows,
  setDeleteDialogData,
  onRowClick,
}) => {
  const [setRef, refs] = useRefMap();
  const [openIndexMenu, setOpenIndexMenu] = useState<number>(NaN);
  const { getFormattedDate } = useFormatting();

  return (
    <Root>
      {map(rows, (row: AutoCloseResolutionsGetType) => (
        <tr
          key={row.id}
          className={classes.tr}
          data-aid={`autoclose-tr${row.id}`}
          onClick={() => onRowClick('auto', row as StageEmailConfigurationType)}
        >
          <td>{row.name}</td>
          <td className={classes.triggerTd}>Automatic verification rejected</td>
          <td>
            {row.modificationDate ? (
              getFormattedDate(row.modificationDate, {
                hour: 'numeric',
                minute: 'numeric',
              })
            ) : (
              <span className={classes.dashCentering}>-</span>
            )}
          </td>
          <td className={classes.activeTd} onClick={event => event.stopPropagation()}>
            {!row.mandatory && (
              <>
                <IconButton
                  onClick={() => setOpenIndexMenu(row.id)}
                  ref={setRef(row.id)}
                  size="small"
                  data-aid={`autoclose-menu-action-${row.id}`}
                >
                  <BigidMoreActionIcon />
                </IconButton>
                <BigidMenu
                  open={!!openIndexMenu}
                  anchorEl={refs.current[openIndexMenu]}
                  items={[
                    {
                      id: 'delete',
                      label: 'Delete',
                      dataAid: 'delete-action',
                      onClick: () => setDeleteDialogData({ type: 'auto', id: openIndexMenu }),
                    },
                  ]}
                  onMenuClose={() => setOpenIndexMenu(NaN)}
                />
              </>
            )}
          </td>
        </tr>
      ))}
    </Root>
  );
};
