import { styled } from '@mui/material/styles';

const PREFIX = 'AutoCloseResolutionTable';

export const classes = {
  tr: `${PREFIX}-tr`,
  dashCentering: `${PREFIX}-dashCentering`,
  activeTd: `${PREFIX}-activeTd`,
  triggerTd: `${PREFIX}-triggerTd`,
};

export const Root = styled('div')({
  [`& .${classes.tr}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.dashCentering}`]: {
    paddingLeft: 33,
  },
  [`& .${classes.activeTd}`]: {
    textAlign: 'end',
    width: 50,
  },
  [`& .${classes.triggerTd}`]: {
    width: 420,
  },
});
