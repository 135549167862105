import React, { ComponentProps, FC } from 'react';
import { TooltipRenderProps } from '@bigid-ui/guided-tour';
import { BigidXIcon } from '@bigid-ui/icons';
import { BigidAnimationPlayer, PrimaryButton, BigidHeading2, BigidBody1, TertiaryButton } from '@bigid-ui/components';
import { Stack } from '@mui/material';
import { AuthorizedAgentStartTooltipStyled } from './StartTooltipStyles';

type StartTooltipProps = TooltipRenderProps & {
  title: string;
  description: string;
  lottie: ComponentProps<typeof BigidAnimationPlayer>['lottieJson'];
};
export const StartTooltip: FC<StartTooltipProps> = ({
  primaryProps,
  skipProps,
  tooltipProps,
  title,
  description,
  lottie,
}) => {
  const { IconWrapper, TopContent, Content, ButtonsSection, Main } = AuthorizedAgentStartTooltipStyled;

  return (
    <Main {...tooltipProps} data-aid="SmallidGuidedTourTooltip">
      <TopContent>
        <IconWrapper onClick={e => skipProps.onClick(e as React.MouseEvent<HTMLElement, MouseEvent>)}>
          <BigidXIcon />
        </IconWrapper>
        <Content>
          <BigidAnimationPlayer lottieJson={lottie} />
        </Content>
      </TopContent>
      <Stack padding="15px 48px 0 48px" width="520px">
        <BigidHeading2 marginBottom="10px" fontSize="1.375rem">
          {title}
        </BigidHeading2>
        <BigidBody1>{description}</BigidBody1>
      </Stack>
      <ButtonsSection>
        <TertiaryButton
          text="Skip"
          size="medium"
          onClick={e => skipProps.onClick(e as React.MouseEvent<HTMLElement, MouseEvent>)}
          dataAid="BigidButtonTourSkip"
        />
        <PrimaryButton
          text="Start Tour"
          size="medium"
          onClick={e => {
            primaryProps.onClick(e as React.MouseEvent<HTMLElement, MouseEvent>);
          }}
          color="grey"
          dataAid="BigidButtonTourDoneNext"
        />
      </ButtonsSection>
    </Main>
  );
};
