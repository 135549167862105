import React, { FC } from 'react';
import { Box, Stack } from '@mui/system';
import { BigidDialog, BigidLink, TertiaryButton } from '@bigid-ui/components';
import { useToggle } from '../../hooks';

type DetailsDialogPropsType = {
  content: string;
};

export const DetailsDialog: FC<DetailsDialogPropsType> = ({ content }) => {
  const { value: isOpenModal, setTrue: openModal, setFalse: closeModal } = useToggle(false);

  return (
    <Box position="relative">
      <BigidLink onClick={openModal} text="View Details" />
      <BigidDialog
        isOpen={isOpenModal}
        onClose={closeModal}
        title="Details"
        borderBottom
        buttons={[
          {
            component: TertiaryButton,
            text: 'Close',
            onClick: closeModal,
            dataAid: 'details-dialog__cancel',
          },
        ]}
      >
        <Stack height="560px" justifyContent="space-between">
          <Box
            overflow="scroll"
            pr={1}
            sx={{
              wordWrap: 'break-word',
            }}
          >
            {content}
          </Box>
        </Stack>
      </BigidDialog>
    </Box>
  );
};
