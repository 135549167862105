import React, { FC } from 'react';
import { AppealGuidedTourStepTemplate } from './AppealGuidedTourStepTemplate';

interface FormAppealStepProps {
  dataAid?: string;
}

export const FormAppealStep: FC<FormAppealStepProps> = ({ dataAid }) => {
  return (
    <AppealGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Set up Appeal Form"
      firstSectionText="Customize the fields and labels and then click Next"
    />
  );
};
