import { UploadedFileType } from './FileTypes';

export type JiraProjectBasic = {
  name: string;
  configurationId: string;
};

export type issueType = {
  id: string;
  name: string;
};

export type issueAssignee = {
  id: string;
  fullName: string;
};

export type issuePriority = {
  id: string;
  name: string;
  color: string;
};

export type issueField = {
  name: string;
  required: boolean;
  supported: boolean;
};

export type JiraProjectDetails = {
  issueTypes: issueType[];
  issueAssignees: issueAssignee[];
  issuePriorities: issuePriority[];
  issueFields: Record<string, issueField[]>;
};

export type JiraTicketFormValuesType = {
  configurationId: string;
  issuetype: string;
  assignee?: string;
  priority?: string;
  priorityId?: number;
  summary?: string;
  description: string;
  duedate?: string | Date;
};

export type JiraIssueBasic = {
  key: string;
  url: string;
};

export type postSendEmailParamsType = {
  recipients: string[];
  recipientUserIds: number[];
  subject: string;
  header: string;
  title: string;
  content: string;
  files: UploadedFileType[];
} & ({ isExternal: true } | { isExternal: false; buttonName: string });
