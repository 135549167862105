import { FC } from 'react';
import { CountryType, DataSourceSystemOnboardingTypes, UseCaseGetType } from '.';
import { ConnectorMode } from '../components/MeConnectorTile';

export interface OnboardingStepType {
  data: Partial<OnboardingGetDataType>;
  handleDataChange: (
    name: keyof OnboardingGetDataType,
    value: OnboardingGetDataType[keyof OnboardingGetDataType] | ConnectorMode,
  ) => void;
}

export enum OnboardingStepsType {
  WELCOME_STEP = 'welcomeStep',
  REGIONS = 'Regulations',
  REGULATIONS = 'Regulations2',
  PRIVACY_CENTER_STEP = 'Privacy Center',
  REQUEST_FORM = 'Requests2',
  CONNECT_DATA = 'Data',
  FINISH_STEP = 'allSetStep',
}

export enum StepperStates {
  WELCOME_STEP = 0,
  REGIONS,
  REGULATIONS,
  PRIVACY_CENTER_STEP,
  REQUEST_FORM,
  CONNECT_DATA,
  FINISH_STEP,
}

export type OnboardingStepContentType = Record<
  number,
  {
    title: string;
    component: FC<OnboardingStepType>;
    actions: OnboardingActionsType;
  }
>;

export type OnboardingActionsType = {
  next: boolean;
  back: boolean;
  skip: boolean;
};

export interface PopularRegulationsGetType {
  popular: OnboardingRegulation[];
  preselected: OnboardingRegulation[];
}

export interface OnboardingRegulationsType extends PopularRegulationsGetType {
  all: OnboardingRegulation[];
}

export interface OnboardingSourceSystemType {
  all: DataSourceSystemOnboardingTypes[];
  popular: DataSourceSystemOnboardingTypes[];
}

export interface OnboardingGetDataType {
  regions: OnboardingRegionsType;
  regulations: OnboardingRegulationsType;
  useCases: UseCaseGetType[];
  sourceSystems: OnboardingSourceSystemType;
}

export type OnboardingRegionType = {
  id: number;
  name: string;
  iso: string;
  enabled: boolean;
};

export type OnboardingRegionsType = {
  regionsOptions: OnboardingRegionType[];
  headquarters: string;
  headquartersOptions: CountryType[];
};

export type OnboardingRegionsGetType = {
  regions: OnboardingRegionType[];
  headquarters: string;
};

export type OnboardingRegulation = {
  id: number;
  shortName: string;
  label: string;
  enabled: boolean;
};

export type OnboardingStateType = {
  widgetEnabled: boolean;
  currentStep: OnboardingStepsType;
};
