import { useEffect, useState, useCallback, SetStateAction } from 'react';
import { ErrorResponseType } from '../types';

type UseDataParamsType<T> = {
  fetchApi: () => Promise<T>;
  initValue?: T;
  dependencyParams?: unknown[];
  permission?: boolean;
};

export const useFetch = <T>({
  fetchApi,
  initValue,
  dependencyParams = [],
  permission = true,
}: UseDataParamsType<T>) => {
  const [data, setData] = useState<T>(initValue as T);
  const [error, setError] = useState<ErrorResponseType | null>(null);
  const [loading, setLoading] = useState(false);
  const [mutated, setMutated] = useState(false);

  const handleDataLoad = useCallback(async () => {
    try {
      setLoading(true);
      const loadedData = await fetchApi();
      setData(loadedData);
      setLoading(false);
      setMutated(false);

      return loadedData;
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  }, dependencyParams); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (permission) {
      handleDataLoad();
    }
  }, [handleDataLoad, permission]);

  const mutateData = useCallback((data: SetStateAction<T>) => {
    setData(data);
    setMutated(true);
  }, []);

  return { data, loadData: handleDataLoad, setData, error, loading, mutated, mutateData };
};
