import React, { ReactNode, ChangeEvent, FC } from 'react';
import { Tabs, Paper } from '@mui/material';
import { primaryBorder } from '../../assets/styles';

export interface MeTabsPropsType {
  children: ReactNode;
  value: number;
  onChange: (value: number) => void;
}

export const MeTabs: FC<MeTabsPropsType> = ({ children, value, onChange }) => {
  const onChangeMeTabs = (event: ChangeEvent<unknown>, value: number) => {
    event.stopPropagation();
    event.preventDefault();
    onChange(value);
  };
  return (
    <Paper
      sx={{
        '&.MuiPaper-root': {
          backgroundColor: 'transparent',
        },
        '&.MuiPaper-elevation1': {
          boxShadow: 'none',
        },
      }}
    >
      <Tabs
        value={value}
        onChange={onChangeMeTabs}
        sx={theme => ({
          '&.MuiTabs-root': {
            maxHeight: 46,
            borderBottom: primaryBorder,
          },
          '& .MuiTabs-indicator': {
            height: '4px',
            borderRadius: '2px',
            backgroundColor: theme.vars.palette.bigid?.primary900,
          },
          '& .MuiTabs-flexContainer': {
            alignItems: 'center',
          },
          '& .MuiTab-root': {
            color: `${theme.vars.palette.bigid?.gray700} !important`,
            fontSize: '0.93rem',
            letterSpacing: '0',
            lineHeight: '1.2rem',
            textTransform: 'capitalize',
            opacity: 1,

            '&.Mui-selected': {
              fontWeight: 'bold',
              color: `${theme.vars.palette.bigid?.primary900} !important`,
            },
          },
          '& .MuiButtonBase-root': {
            minWidth: '0 !important',
            padding: '0 !important',
            marginRight: '45px',
          },
        })}
      >
        {children}
      </Tabs>
    </Paper>
  );
};
