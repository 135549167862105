import React, { FC, useEffect, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { NavItemType } from '../../types';
import { MainLoader } from '../MainLoader';
import { NavItemsContainer } from '../../state/navItemsContainer';

type NavItemActivationGuardPropsType = {
  itemType: NavItemType;
  activationCallback: () => Promise<unknown>;
  children?: ReactNode;
};

export const NavItemActivationGuard: FC<NavItemActivationGuardPropsType> = ({
  children,
  itemType,
  activationCallback,
}) => {
  const { navItems, fetchNavItems, activateNavItem } = NavItemsContainer.useContainer();

  useEffect(() => {
    if (!navItems || navItems[itemType]?.activated) {
      return;
    }

    activationCallback().then(() => {
      activateNavItem(itemType);
      fetchNavItems();
    });
  }, [activateNavItem, activationCallback, fetchNavItems, itemType, navItems]);

  if (!navItems) {
    return <MainLoader />;
  }

  if (!navItems[itemType]) {
    return <Navigate to="/unauthorized" />;
  }

  return <>{children}</>;
};
