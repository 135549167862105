import React, { useCallback } from 'react';
import { BigidRichTextEditor, BigidRichTextEditorProps } from '@bigid-ui/rich-text-editor';

import { fontSizeList, toolbarButtonsConfig } from './config';

type RichTextEditorPropsType = { toolbarVariant?: keyof typeof toolbarButtonsConfig } & BigidRichTextEditorProps;

/**
 *
 * @example controlled usage
 *
 * <RichTextEditor
 *  isControlled // default true
 *  onBlur // callback
 * />
 *
 *  @example uncontrolled usage
 *
 * <RichTextEditor
 *  isControlled={false} // default true
 *  onChange // callback
 *  // or
 *  onBlur // callback
 * />
 *
 * Add muiOverrides={{ disableEnforceFocus: true }} to BigidDialog for correct work in dialog
 */

export const RichTextEditor = ({
  toolbarVariant = 'default',
  isControlled = true,
  onBlur,
  ...props
}: RichTextEditorPropsType) => {
  const handleBlur = useCallback(
    (html: string) => {
      const updated = html === '<p><br></p>' ? '' : html;
      onBlur?.(updated);
    },
    [onBlur],
  );

  return (
    <BigidRichTextEditor
      forceOpenLinkInNewTab
      isControlled={isControlled}
      fontSizeList={fontSizeList}
      {...props}
      onBlur={onBlur ? handleBlur : undefined}
      buttons={toolbarButtonsConfig[toolbarVariant]}
    />
  );
};
