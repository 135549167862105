import { color, DropShadowFilter, InterfaceColorSet, MouseCursorStyle } from '@amcharts/amcharts4/core';
import {
  XYChart,
  ValueAxis,
  DateAxis,
  Legend,
  LineSeries,
  XYCursor,
  CircleBullet,
  AxisRenderer,
} from '@amcharts/amcharts4/charts';
import {
  chartColors,
  BigidMeChartsColors,
  SingleColor,
  RejectedRequestsColors,
  RequestsByTypeColors,
} from '../../assets/styles';
import { BigidChartLegendPosition } from '../../assets/misc';
import { ClassNameMap } from '@mui/styles/withStyles';
import { RequestStatusIconsProps } from '../../utils';
import { RequestStatusType } from '../../types/SingleRequestTypes';
import { BigidColorsV2 } from '@bigid-ui/components';

export const LineChartTitle = (chart: XYChart, title: string, legend?: BigidChartLegendPosition): void => {
  const chartTitle = chart.titles.create();
  chartTitle.text = title;
  chartTitle.fontSize = 13;
  chartTitle.fontWeight = 'bold';
  chartTitle.align = 'left';
  chartTitle.fill = color(chartColors.valueAxisLabelColor);
  if (!legend) {
    chartTitle.marginBottom = 15;
  }
};

export const LineChartDateAxis = (chart: XYChart): DateAxis<AxisRenderer> => {
  const dateAxis = chart.xAxes.push(new DateAxis());
  dateAxis.dateFormats.setKey('day', 'MM/dd');
  dateAxis.renderer.grid.template.disabled = true;
  dateAxis.renderer.minGridDistance = 50;
  dateAxis.renderer.labels.template.fill = color(chartColors.valueAxisLabelColor);
  dateAxis.renderer.labels.template.fontWeight = 'bold';
  if (dateAxis.tooltip) {
    dateAxis.tooltip.animationDuration = 0;
    dateAxis.tooltip.label.fontWeight = 'bold';
    dateAxis.tooltip.label.fill = color();
  }
  if (dateAxis.renderer.tooltip) {
    dateAxis.renderer.tooltip.background.opacity = 0.5;
    dateAxis.renderer.tooltip.background.fill = color(BigidColorsV2.white);
  }
  return dateAxis;
};

export const LineChartValueAxis = (chart: XYChart): ValueAxis<AxisRenderer> => {
  const valueAxis = chart.yAxes.push(new ValueAxis());
  valueAxis.renderer.grid.template.strokeDasharray = '2 2';
  valueAxis.renderer.labels.template.fill = color(chartColors.valueAxisLabelColor);
  valueAxis.renderer.labels.template.fontWeight = 'bold';
  valueAxis.renderer.minGridDistance = 20;
  valueAxis.renderer.cellStartLocation = 0;
  valueAxis.cursorTooltipEnabled = false;
  const valueAxisLine = valueAxis.dom.querySelector('[stroke-opacity="0.15"]');
  if (valueAxisLine) {
    valueAxisLine.setAttribute('stroke-opacity', '0');
  }
  return valueAxis;
};

export const LineChartLegend = (chart: XYChart, series: string[], noData: boolean): void => {
  chart.legend = new Legend();
  chart.legend.useDefaultMarker = true;
  chart.legend.position = 'top';
  chart.legend.contentAlign = 'right';
  chart.legend.marginTop = -27;
  chart.legend.marginBottom = 15;
  const marker = chart.legend.markers.template;
  marker.width = 8;
  marker.height = 8;
  marker.verticalCenter = 'middle';
  marker.horizontalCenter = 'middle';
  if (series?.length < 2 || noData) {
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = MouseCursorStyle.default;
  }
};

export const LineChartTooltip = (
  Line: LineSeries,
  series: string[],
  colors: typeof BigidMeChartsColors | typeof SingleColor | typeof RejectedRequestsColors | typeof RequestsByTypeColors,
  tooltip: boolean,
  classes: ClassNameMap,
): void => {
  Line.tooltipHTML = `
    <div class="${classes.tooltipContent}">
      <div class="${classes.tooltipDate}">{dateX.formatDate('dd MMM, yyyy')}</div>
        ${series
          .map((item, idx) => {
            if (item === 'value') return undefined;
            // TODO: BE should unify all the types in BME-1028 REFACTORING NEEDED
            return `<div class="${classes.chipRoot}" key="${idx}">
                // <div class="${classes.chipIcon}" style="background-color: ${
              colors[item as keyof typeof colors]
            }"></div>
                <div class="${classes.chipIcon}" style="background-color: ${
              RequestStatusIconsProps[item as RequestStatusType].color
            }"></div>
                <p class="${classes.chipText}">
                  <span class="${classes.chipValue}">{${item}}</span>
                  <span class="${classes.chipLabel}">${RequestStatusIconsProps[item as RequestStatusType].label}</span>
                </p>
              </div>`;
          })
          .join('')}
    </div>
  `;
  if (Line.tooltip) {
    Line.tooltip.disabled = !tooltip;
    Line.tooltip.getFillFromObject = false;
    Line.tooltip.background.fill = color(BigidColorsV2.white);
    Line.tooltip.opacity = 1;
    Line.tooltip.getStrokeFromObject = false;
    Line.tooltip.background.stroke = color(BigidColorsV2.gray[300]);
    Line.tooltip.background.filters.clear();
  }
  const shadow = Line.filters.push(new DropShadowFilter());
  shadow.dx = 0;
  shadow.dy = 6;
  shadow.blur = 12;
  shadow.color = color('rgba(0, 0, 0, 0.09)');
};

export const LineChartCursor = (chart: XYChart, dateAxis: DateAxis, Line: LineSeries): void => {
  chart.cursor = new XYCursor();
  chart.cursor.behavior = 'none';
  chart.cursor.zIndex = 0;
  chart.cursor.xAxis = dateAxis;
  chart.cursor.lineX.stroke = color(chartColors.cursorColor);
  chart.cursor.lineX.strokeOpacity = 1;
  chart.cursor.lineX.strokeWidth = 2;
  chart.cursor.lineX.strokeDasharray = '';
  chart.cursor.lineY.disabled = true;

  const interfaceColors = new InterfaceColorSet();

  const bullet = Line.bullets.push(new CircleBullet());
  bullet.circle.stroke = interfaceColors.getFor('background');
  bullet.circle.strokeWidth = 3;
  bullet.circle.width = 8;
  bullet.circle.height = 8;
  bullet.circle.radius = 7;
};
