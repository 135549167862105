import { useCallback, useState } from 'react';
import { debounce } from 'lodash';

import { JiraConfigurationFormType } from '../../../types';
import { testJiraConnection } from '../../../services/settingsService';
import { SnackbarUtils } from '../../../utils';

export const useJiraConfigurationTestConnection = (values: JiraConfigurationFormType) => {
  const [status, setStatus] = useState<'pending' | 'error' | 'success' | null>(null);
  const handleTestConnection = useCallback(async () => {
    setStatus('pending');
    if (!(values.protocol && values.host && values.userName && values.password && values.port)) {
      SnackbarUtils.error('Please fill all required fields');
      setStatus('error');
      return;
    }
    try {
      const data = await testJiraConnection(values);
      if (data?.connected) {
        SnackbarUtils.success('Jira Project has been connected successfully');
        setStatus('success');
      } else {
        data?.message && SnackbarUtils.error(data.message);
        setStatus('error');
      }
    } catch (error) {
      SnackbarUtils.error('Jira Project connection has been failed');
      setStatus('error');
    }
  }, [values]);

  const handleClick = debounce(handleTestConnection, 300);

  return { status, handleClick };
};
