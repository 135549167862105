import { AxiosError } from 'axios';
import { BigidFilter } from '@bigid-ui/components';
import { TrendsRequestsOptionsType, StatisticPeriodValuesType, ErrorResponseType } from '../types';
import { replace } from 'lodash';

export const processParams = (url: string, options: TrendsRequestsOptionsType = {}): string => {
  options.period_days === StatisticPeriodValuesType.all && delete options.period_days;
  return [
    url,
    Object.entries(options)
      .map(([key, value]) => `${key}=${value}`)
      .join('&'),
  ]
    .filter(Boolean)
    .join('?');
};

export const blobErrorHandler = (err: AxiosError): Promise<ErrorResponseType | AxiosError> => {
  if (err.response?.data) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        reject(JSON.parse(reader.result as string));
      });
      reader.addEventListener('error', () => {
        reject(err);
      });

      reader.readAsText(err.response!.data as Blob);
    });
  } else {
    return Promise.reject(err);
  }
};

export const toStringQuery = (filters?: BigidFilter, applyFiltersIfNoOptionSelected = false) => {
  let filterString = '';
  if (!filters) {
    return '';
  }
  filters.forEach(({ field, operator, value }) => {
    if (Array.isArray(value) && (value.length > 0 || applyFiltersIfNoOptionSelected)) {
      filterString += ` and ${field} ${operator} (${value
        .map(filterValue => {
          const ecranedSlash = replace(filterValue as string, '/', '//');
          const ecranedComma = replace(ecranedSlash, ',', '/,');
          const ecranedString = replace(ecranedComma, '=', '/=');

          return ecranedString;
        })
        .join()})`;
    } else if (!Array.isArray(value) && value) {
      filterString += ` and ${field} ${operator} ${value}`;
    }
  });

  return filterString;
};

export const normalizeLinkToHyperlink = (link = '', protocol = '') => {
  if (!link || link.startsWith('mailto:')) return link;
  if (/@/.test(link) && !/mailto:/.test(link)) return `mailto:${link}`;
  if (!/^(https?:)?\/\//.test(link)) return `${protocol || '//'}${link}`;
  return link;
};

export const normalizeHyperlinkToLink = (link = '') =>
  /^\/\//.test(link) ? link.replace('//', '') : link.replace('mailto:', '');

export const normalizeFormikLinksToHyperlink = (
  data: Record<string, string>,
  linkNames: string[],
): Record<string, string> => {
  const processedData = { ...data };
  linkNames.forEach(name => {
    if (!data[name]) return;
    processedData[name] = normalizeLinkToHyperlink(data[name]);
  });
  return processedData;
};

export const extractTextFromHtml = (htmlString: string): string => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  return tempElement.textContent?.trim() || '';
};
