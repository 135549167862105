import React from 'react';
import { iconSets } from '@bigid-ui/icons';
import { RegulationType } from '../types';

const {
  FlagIcons: { Jp, Ua, Eu, Um, Ru, Ph, Hk, Vn, Mx, Br, Ar, My, Ae, Cl, Th, In, Tr, Kr, Ca, Cn, Za, Il, Co, Sg, Au },
} = iconSets;

const regulationIconMap = {
  APPI: <Jp />,
  CDPA: <Cl />,
  CDR: <Au />,
  CPA: <Um />,
  CPRA: <Um />,
  CTDPA: <Um />,
  DPA_PH: <Ph />,
  DPA_RU: <Ru />,
  DPL_AE: <Ae />,
  DPL_UA: <Ua />,
  DPP: <Hk />,
  GDPR: <Eu />,
  ICDPA: <Um />,
  INCDPA: <Um />,
  FDPL: <Mx />,
  LGPD: <Br />,
  MCDPA: <Um />,
  PDPA_SG: <Sg />,
  PDPA_AR: <Ar />,
  PDPA_MY: <My />,
  PDPA_TH: <Th />,
  PDPB: <In />,
  PDPD: <Vn />,
  PDPL: <Tr />,
  PIPA: <Kr />,
  PIPEDA: <Ca />,
  PIPL: <Cn />,
  POPIA: <Za />,
  PPL: <Il />,
  RDPA: <Co />,
  SB619: <Um />,
  TDPSA: <Um />,
  TIPA: <Um />,
  UCPA: <Um />,
  VCDPA: <Um />,
};

const regionIconMap = {
  JP: <Jp />,
  US: <Um />,
  PH: <Ph />,
  RU: <Ru />,
  AE: <Ae />,
  UA: <Ua />,
  HK: <Hk />,
  EU: <Eu />,
  VN: <Vn />,
  MX: <Mx />,
  BR: <Br />,
  SG: <Sg />,
  AR: <Ar />,
  MY: <My />,
  TH: <Th />,
  IN: <In />,
  TR: <Tr />,
  KR: <Kr />,
  CA: <Ca />,
  CN: <Cn />,
  ZA: <Za />,
  CO: <Co />,
  AT: <Au />,
};

export const getRegionIcon = (iso: string) => regionIconMap[iso as never];

export const getRegulationIcon = (shortName: string) => regulationIconMap[shortName as never];

export const renderRequestRegulation = (regulation: RegulationType) => {
  return regulation ? (
    <span style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
      {getRegulationIcon(regulation)}
      <span style={{ marginLeft: 8 }}>{regulation.split('_')[0]}</span>
    </span>
  ) : (
    'N/A'
  );
};
