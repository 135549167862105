import React, { useEffect, useRef, useMemo, useState, FC } from 'react';
import cx from 'classnames';
import { useStyles } from './TutorialStyles';
import { TutorialContainer, TUTORIAL_COMPLETION_STORAGE_KEY } from '../../state/tutorialContainer';
import { buildGradient } from './utils';
import { TutorialStepsType } from '../../types/TutorialTypes';
import { ChecklistPopover } from './ChecklistPopover';
import { ConfirmationPopover } from './ConfirmationPopover';
import { TUTORIAL_OVERLAY_ID } from './config';

import { pickBy } from 'lodash';
import { useTheme } from '@mui/styles';
import { PrimaryButton } from '@bigid-ui/components';

export const Tutorial: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const widgetButtonWrapperRef = useRef(null);
  const { tutorialChecklist, activeTutorialStep, resetTutorialStep, setFetchChecklistTimestamp } =
    TutorialContainer.useContainer();
  const {
    onboardingFinished,
    privacyCenterEdited,
    privacyCenterPublished,
    systemAdded,
    agentConnected,
    agentUserProfilesMapped,
    consentsAutomated,
  } = tutorialChecklist;

  const completionStatus = pickBy(
    {
      [TutorialStepsType.ONBOARDING]: onboardingFinished,
      [TutorialStepsType.EDIT_PRIVACY_CENTER]: privacyCenterEdited,
      [TutorialStepsType.PUBLISH_PRIVACY_CENTER]: privacyCenterPublished,
      [TutorialStepsType.SYSTEM]: systemAdded,
      [TutorialStepsType.DEEP_DISCOVERY]: agentConnected,
      [TutorialStepsType.MAP_PROFILES]: agentUserProfilesMapped,
      [TutorialStepsType.AUTOMATE_CONSENT]: consentsAutomated,
    },
    value => value !== undefined,
  );

  const [isPopoverOpen, setIsPopoverOpen] = useState(activeTutorialStep !== null);
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);

  const closePopover = () => {
    setIsPopoverOpen(false);
    setIsChecklistOpen(false);
  };

  const closePopoverAndResetTutorialStep = () => {
    resetTutorialStep();
    closePopover();
  };

  useEffect(() => {
    if (activeTutorialStep === null || activeTutorialStep === TutorialStepsType.ONBOARDING) {
      closePopover();
    }
  }, [activeTutorialStep]);

  const currentProgressNormalized = useMemo(() => {
    const tutorialSteps = Object.values(completionStatus);

    return tutorialSteps.reduce((acc, curr) => acc + Number(!!curr), 0) / tutorialSteps.length;
  }, [completionStatus]);

  const progressCircleStyle = useMemo(() => {
    const arc = currentProgressNormalized * 2 * Math.PI;
    const backgroundImage =
      arc <= Math.PI
        ? buildGradient(Math.PI / 2 + arc, theme.vars.palette.bigid?.gray500 || '')
        : buildGradient(arc - Math.PI / 2, theme.vars.palette.bigid?.primary400 || '');

    return { backgroundImage };
  }, [currentProgressNormalized]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleTutorialButtonClick = () => {
    if (!isPopoverOpen) {
      setIsPopoverOpen(true);
    } else {
      closePopoverAndResetTutorialStep();
    }

    setIsChecklistOpen(prev => !prev);
  };

  const handleTutorialFinish = () => {
    closePopoverAndResetTutorialStep();
    sessionStorage.removeItem(TUTORIAL_COMPLETION_STORAGE_KEY);
    setFetchChecklistTimestamp(Date.now());
  };

  return (
    <>
      <div
        id={TUTORIAL_OVERLAY_ID}
        className={cx(classes.popoverContainer, {
          [classes.visible]: isPopoverOpen,
        })}
      >
        <div className={classes.overlay} onClick={closePopoverAndResetTutorialStep} />
        {currentProgressNormalized < 1 ? (
          <ChecklistPopover
            completionStatus={completionStatus}
            progress={currentProgressNormalized}
            open={isChecklistOpen}
            onClose={closePopoverAndResetTutorialStep}
            onItemClick={() => setIsChecklistOpen(false)}
          />
        ) : (
          <ConfirmationPopover onConfirm={handleTutorialFinish} />
        )}
      </div>

      <div className={classes.widgetButtonWrapper} ref={widgetButtonWrapperRef}>
        <PrimaryButton
          onClick={handleTutorialButtonClick}
          startIcon={<span className={classes.circleIcon} style={progressCircleStyle} />}
          text="Getting Started"
          size="medium"
        />
      </div>
    </>
  );
};
