import makeStyles from '@mui/styles/makeStyles';
import { BORDER_RADIUS, primaryBorder } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'block',
  },
  label: {
    textAlign: 'left',
    color: theme.vars.palette.bigid?.gray700,
    fontSize: '0.9375rem',
    lineHeight: '1.7rem',
    transform: 'none',
  },
  input: {
    margin: '0 !important',
    fontSize: '0.8125rem',
    borderRadius: BORDER_RADIUS,
    width: '100%',
    border: `1px solid ${theme.vars.palette.bigid?.gray500}`,
    '& > div:first-child': {
      backgroundColor: theme.vars.palette.bigid?.white,
      padding: '8px 35px 6px 14px',
      borderRadius: BORDER_RADIUS,
      height: 16,
      display: 'flex',
      alignItems: 'center',
    },
    '& > svg': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 12,
    },
    '&:after, &:before': {
      display: 'none',
    },
  },
  menu: {
    borderRadius: BORDER_RADIUS,
    margin: '4px 0',
  },
  menuItem: {
    fontWeight: 'bold',
    fontSize: '0.8125rem',
    color: theme.vars.palette.bigid?.gray500,
    '&:not(:last-child)': {
      borderBottom: primaryBorder,
    },
  },
  menuItemSelected: {
    backgroundColor: `${theme.vars.palette.bigid?.white} !important`,
    color: theme.vars.palette.bigid?.black,
    opacity: '1 !important',
  },
}));
