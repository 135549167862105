import React, { FC } from 'react';
import { AppealGuidedTourStepTemplate } from './AppealGuidedTourStepTemplate';

interface ClosedRequestsGridStepProps {
  dataAid?: string;
}

export const ClosedRequestsGridStep: FC<ClosedRequestsGridStepProps> = ({ dataAid }) => {
  return (
    <AppealGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Add link to Appeal"
      firstSectionText="Open a Close Request email where you want to add a link to the Appeal form."
    />
  );
};
