import { useMemo } from 'react';
import { createContainer } from 'unstated-next';

import { getCountries } from '../../../services/settingsService';
import { useFetch } from '../../../hooks/useFetch';
import { generateCountriesDropdownOptions } from '../utils/countriesUtils';

const useWorkflowSettingsData = () => {
  const { data: countries } = useFetch({
    fetchApi: getCountries,
    initValue: [],
  });
  const countriesOptions = useMemo(() => {
    return countries && generateCountriesDropdownOptions(countries);
  }, [countries]);

  return { countriesOptions };
};

export const WorkflowSettingsDataContainer = createContainer(useWorkflowSettingsData);
