import React, { FC, ComponentProps, useMemo } from 'react';
import { BigidDropdown, BigidTextField } from '@bigid-ui/components';
import { Stack } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { BigidMeTooltipWithIcon } from '../BigidMeTooltipWithIcon';

const defaultOptions = [
  {
    id: 'D',
    value: 'D',
    displayValue: 'Days',
  },
  {
    id: 'M',
    value: 'M',
    displayValue: 'Month',
  },
  {
    id: 'Y',
    value: 'Y',
    displayValue: 'Years',
  },
];

export type TimeDurationFieldPropsType = {
  name?: string;
  label?: string;
  tooltip?: string;
  value?: string; // time duration, https://en.wikipedia.org/wiki/ISO_8601
  onChange: (value: string) => void;
};

export const TimeDurationField: FC<TimeDurationFieldPropsType> = ({
  value = 'P0D',
  name = '',
  label,
  tooltip,
  onChange,
}) => {
  const [[, numberValue, typeValue]] = useMemo(() => [...value.matchAll(/P(\d+)([D,M,Y])/g)], [value]);

  const handleNumberChange: ComponentProps<typeof BigidTextField>['onChange'] = ({ target: { value: fieldValue } }) => {
    onChange(`P${Number(fieldValue) || 0}${typeValue}`);
  };

  const handleTypeChange: ComponentProps<typeof BigidDropdown>['onSelect'] = ([{ value: fieldValue }]) => {
    onChange(`P${numberValue}${fieldValue}`);
  };

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center" mb={1}>
        {label && <Typography>{label}</Typography>}
        {tooltip && <BigidMeTooltipWithIcon title={tooltip} />}
      </Stack>
      <Stack direction="row" spacing={1}>
        <Box maxWidth={80}>
          <BigidTextField
            name="number"
            value={numberValue}
            placeholder="0"
            type="number"
            inputProps={{ min: '0' }}
            size="medium"
            onChange={handleNumberChange}
            dataAid={`${name}_time_duration_number`}
          />
        </Box>
        <Box maxWidth={110}>
          <BigidDropdown
            value={defaultOptions.filter(({ value }) => value === typeValue)}
            options={defaultOptions}
            onSelect={handleTypeChange}
            dataAid={`${name}_time_duration_type`}
          />
        </Box>
      </Stack>
    </Stack>
  );
};
