import { useState, useEffect, useCallback } from 'react';
import { createContainer } from 'unstated-next';

import { loadSidebar } from '../services/sidebarService';
import { NavItemsType, NavItemType } from '../types';

export const useNavItems = () => {
  const [navItems, setNavItems] = useState<Partial<NavItemsType>>();

  const activateNavItem = useCallback((navItem: NavItemType) => {
    setNavItems((prev = {}) => ({
      ...prev,
      [navItem]: {
        ...prev[navItem],
        activated: true,
      },
    }));
  }, []);

  const fetchNavItems = () => {
    loadSidebar().then(setNavItems);
  };

  useEffect(() => {
    fetchNavItems();
  }, []);

  return { navItems, setNavItems, activateNavItem, fetchNavItems };
};

export const NavItemsContainer = createContainer(useNavItems);
