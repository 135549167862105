import React, { FC, ReactElement, ReactNode } from 'react';
import { BigidInfoIcon } from '@bigid-ui/icons';

import { BigidTooltip, BigidTooltipProps } from '@bigid-ui/components';

export type BigidMeTooltipWithIconPropsType = Omit<BigidTooltipProps, 'children'> & {
  title: string | ReactElement | false | ReactNode;
  onClick?: () => void;
};

export const BigidMeTooltipWithIcon: FC<BigidMeTooltipWithIconPropsType> = ({
  title,
  placement = 'top',
  dataAid,
  onClick,
  ...rest
}) => (
  <BigidTooltip onClick={onClick} title={title} placement={placement} dataAid={`${dataAid}_body`} {...rest}>
    <span style={{ verticalAlign: 'baseline' }}>
      <BigidInfoIcon size="small" dataAid={`${dataAid}_icon`} style={{ verticalAlign: 'sub', margin: '0 2px' }} />
    </span>
  </BigidTooltip>
);
