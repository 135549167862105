import { api } from './request';
import {
  SettingsLocalesType,
  SettingsProfileGetType,
  PopularRegulationsGetType,
  OnboardingStepsType,
  OnboardingStateType,
} from '../types';

export const getPopularRegulations = async (): Promise<PopularRegulationsGetType> =>
  await api.get<PopularRegulationsGetType>('/regulations/popular').then(resolve => resolve.data);

export const getOnboardingLocales = async (): Promise<SettingsLocalesType[]> =>
  await api.get<{ locales: SettingsLocalesType[] }>('/locales').then(resolve => resolve.data.locales);

export const getEditorProfiles = async (brandId: number, enabledOnly = true): Promise<SettingsProfileGetType[]> =>
  await api
    .get<{ profiles: SettingsProfileGetType[] }>(`/brands/${brandId}/user-profiles`, { params: { enabledOnly } })
    .then(resolve => resolve.data.profiles);

export const getProfiles = async (enabledOnly = true): Promise<SettingsProfileGetType[]> =>
  await api
    .get<{ profiles: SettingsProfileGetType[] }>(`/user-profiles`, { params: { enabledOnly } })
    .then(resolve => resolve.data.profiles);

export const updateOnboardingState = async (currentStep: OnboardingStepsType): Promise<OnboardingStateType> =>
  await api.patch<OnboardingStateType>('/onboarding/state', { currentStep }).then(({ data }) => data);

export const getOnboardingState = async (): Promise<OnboardingStateType> =>
  await api.get<OnboardingStateType>('/onboarding/state').then(({ data }) => data);

export const addPrivacyProduct = async (): Promise<void> =>
  await api.post<void>('/privacy').then(resolve => resolve.data);
