import axios from 'axios';
import { authStore } from './authStore';
import {
  AuthenticationType,
  AuthenticatedUserType,
  AuthResponseType,
  TokenClaimsType,
  AuthSettingType,
} from '../../types/AuthenticationTypes';

const AUTH_SESSION_EXPIRE_TIME = process.env.REACT_APP_AUTH_SESSION_EXPIRE_TIME
  ? Number(process.env.REACT_APP_AUTH_SESSION_EXPIRE_TIME)
  : 15 * 60 * 1000;

const tokenApi = axios.create({
  method: 'post',
  responseType: 'json',
  auth: {
    username: process.env.REACT_APP_AUTH_CLIENT_ID || '',
    password: process.env.REACT_APP_AUTH_CLIENT_SECRET || '',
  },
  headers: { 'content-type': 'application/x-www-form-urlencoded' },
});

const settingsApi = axios.create({
  baseURL: process.env.REACT_APP_WORKFLOW_API_BASE_URL,
  responseType: 'json',
});

const requestAccessToken = (username: string, password: string): Promise<AuthResponseType> => {
  username = encodeURIComponent(username);
  password = encodeURIComponent(password);

  return tokenApi({
    url: '/oauth/token',
    data: `grant_type=password&scope=privacy_request&username=${username}&password=${password}`,
  }).then(response => response.data);
};

const extractTokenClaims = (token: string): TokenClaimsType => JSON.parse(atob(token.split('.')[1]));

const processAccessToken = (accessToken: string): AuthenticationType => {
  const accessTokenClaims = extractTokenClaims(accessToken);
  const accessTokenExpiration = accessTokenClaims.exp * 1000;

  const user: AuthenticatedUserType = {
    id: accessTokenClaims.user_id,
    tenant: accessTokenClaims.user_tenant,
    defaultTenant: accessTokenClaims.user_default_tenant,
  };

  const auth: AuthenticationType = {
    accessTokenExpiration,
    user,
  };

  authStore.saveAuthentication(auth);

  return auth;
};

const getUser = (): AuthenticatedUserType | null => {
  const auth = authStore.getAuthentication();
  return auth && auth.user;
};

const isAuthenticated = (): boolean => {
  const auth = authStore.getAuthentication();

  return !!auth && !!auth.accessTokenExpiration && auth.accessTokenExpiration > Date.now();
};

const loginInstantly = (username: string, password: string): Promise<AuthenticationType> =>
  requestAccessToken(username, password).then(response => processAccessToken(response.access_token));

const loginWithBigIDAccessToken = (accessToken: string): Promise<string> =>
  axios({
    url: '/bc/login',
    method: 'get',
    headers: {
      'X-BigID-Access-Token': accessToken,
    },
    responseType: 'text',
  }).then(response => {
    processAccessToken(response.data);
    return response.data;
  });

const startLogin = (settings: AuthSettingType) => {
  window.location.href = settings.loginUrl!;
};

const finishLogin = (accessToken: string) => {
  const accessTokenClaims = extractTokenClaims(accessToken);
  const accessTokenExpiration = accessTokenClaims.exp * 1000;

  const user: AuthenticatedUserType = {
    id: accessTokenClaims.user_id,
    tenant: accessTokenClaims.user_tenant,
    defaultTenant: accessTokenClaims.user_default_tenant,
  };

  const auth: AuthenticationType = {
    accessTokenExpiration,
    user,
  };

  authStore.saveAuthentication(auth);

  return auth;
};

const logOut = async () => {
  const tenantId = authStore.getAuthentication()?.user.defaultTenant;
  authStore.removeAuthentication();
  const settings = await authService.getSettings(tenantId);
  if (settings.logoutUrl) {
    window.location.href = settings.logoutUrl;
  }
};

export const putAuthSessionTimer = () => {
  let sessionTimerId = setTimeout(() => {
    logOut();
  }, AUTH_SESSION_EXPIRE_TIME);
  const listeners: { event: string; fn: () => void }[] = [];
  ['mousemove', 'focus', 'blur', 'click', 'resize', 'scroll', 'change', 'keypress'].forEach(event => {
    const fn = () => {
      clearTimeout(sessionTimerId);
      sessionTimerId = setTimeout(() => {
        logOut();
      }, AUTH_SESSION_EXPIRE_TIME);
    };
    listeners.push({ event, fn });
    window.addEventListener(event, fn);
  });
  return () => {
    clearTimeout(sessionTimerId);
    listeners.forEach(listener => {
      window.removeEventListener(listener.event, listener.fn);
    });
  };
};

export const getSettings = (tenantId?: string): Promise<AuthSettingType> =>
  settingsApi
    .get<AuthSettingType>(tenantId ? `/${tenantId}/authentication-settings` : '/authentication-settings')
    .then(resolve => resolve.data)
    .catch(() => ({ useNativeLogin: true }));

export const authService = {
  isAuthenticated,
  getSettings,
  getUser,
  putAuthSessionTimer,
  loginInstantly,
  loginWithBigIDAccessToken,
  startLogin,
  finishLogin,
  logOut,
};
