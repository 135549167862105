import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    '& svg': {
      display: 'block',
    },
  },
  unavailable: {
    '& svg circle': {
      stroke: theme.vars.palette.bigid?.gray200,
      fill: theme.vars.palette.bigid?.white,
    },
  },
}));
