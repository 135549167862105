import { ReportTemplate, RequestDataType, RequestType } from '../types';
import { itemsToDropdownOptions } from './dropdownUtils';

export const reportTemplatesToDropdownOptions = itemsToDropdownOptions<ReportTemplate>(
  item => item.id.toString(),
  item => item.name,
);

export const getDefaultSelectedTemplateId = (request: RequestDataType, templateOptions: ReportTemplate[]) =>
  request.reportTemplateId || templateOptions.find(option => option.name === 'Default')?.id || templateOptions[0]?.id;

export const canUserChooseReportTemplate = (request: RequestDataType, templateOptions: ReportTemplate[]) =>
  !request.closed && request.type === RequestType.VIEW && templateOptions.length > 1;
