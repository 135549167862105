import { BigidDropdownOption, BigidDropdownValue } from '@bigid-ui/components';

export const stringToDropdownValue = (value?: string): BigidDropdownValue =>
  value ? [{ value, displayValue: value, id: value }] : [];

export const dropdownOptionsToValues = (options: BigidDropdownOption[]) => options.map(o => o.value);

export const itemsToDropdownOptions =
  <T>(getValue: (item: T) => string, getLabel: (item: T) => string) =>
  (items: T[], selectedValues?: string[]): BigidDropdownOption[] =>
    items
      .filter(item => (selectedValues ? selectedValues.includes(getValue(item)) : true))
      .map(item => ({ id: getValue(item), value: getValue(item), displayValue: getLabel(item) }));
