export enum BigidChartLegendPosition {
  NONE,
  TOPLEFT,
  /*
    if more positions needed - check to bigid-ui repo, it currently supports:
      BigidChartLegendPosition.TOP,
      BigidChartLegendPosition.BOTTOM,
      BigidChartLegendPosition.LEFT,
      BigidChartLegendPosition.RIGHT,
   */
}
