import React, { FC } from 'react';
import cx from 'classnames';
import { Stepper, Step, StepButton, StepConnector } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { StepperIcon } from '../StepperIcon';

import { useStyles, defaultConnectorStyles } from './MeStepperStyles';
import { MeProgressBar } from '../MeProgressBar';

// progress visit in [0; 100]%
export const STEP_VISITED = 15;
export const STEP_HALFCOMPLETED = 50;
export const STEP_COMPLETED = 100;

export interface MeStepperPropsType {
  steps: string[];
  activeStep: number;
  hiddenStepperSteps: string[];
  stepsProgress: number[];
  onStepClick?: (step: number) => void;
}

export const MeStepper: FC<MeStepperPropsType> = ({
  steps,
  activeStep,
  hiddenStepperSteps,
  stepsProgress,
  onStepClick,
}) => {
  const Connector = withStyles(defaultConnectorStyles)(StepConnector);
  const classes = useStyles();

  const handleStepClick = (step: number) => {
    // if step was completed or visited
    onStepClick &&
      (stepsProgress[step] === STEP_COMPLETED || stepsProgress[step] === STEP_VISITED) &&
      onStepClick(step);
  };

  return (
    <Stepper className={classes.root} activeStep={activeStep} connector={<Connector />}>
      {steps.map((label, index) => {
        const completed = stepsProgress[index] === STEP_COMPLETED;
        const current = stepsProgress[index] < STEP_COMPLETED && stepsProgress[index] > 0;
        const progress = `${stepsProgress[index]}%`;
        const isHiddenStep = hiddenStepperSteps.find(hiddenLabel => label === hiddenLabel);

        return (
          <Step
            key={index}
            className={cx(classes.step, { [classes.hidden]: isHiddenStep })}
            onClick={() => handleStepClick(index)}
            completed={completed}
          >
            <StepButton
              disableRipple
              className={classes.stepButton}
              disabled={activeStep === index || (index !== 0 && !stepsProgress[index - 1])}
              icon={<StepperIcon current={current} completed={completed} />}
            >
              <span
                className={cx(classes.label, {
                  [classes.current]: current,
                  [classes.completed]: completed,
                })}
              >
                {label}
              </span>
            </StepButton>
            <MeProgressBar width="100px" progress={progress} />
          </Step>
        );
      })}
    </Stepper>
  );
};
