export enum NavItemType {
  DASHBOARD = 'DASHBOARD',
  OPEN_REQUESTS = 'OPEN_REQUESTS',
  CLOSED_REQUESTS = 'CLOSED_REQUESTS',
  CONSENT = 'CONSENTS_PREFERENCES',
  BASIC_SETTINGS = 'BASIC_SETTINGS',
  EDITOR = 'EDITOR',
  USERS = 'USERS',
  ROLES = 'ROLES',
  DATA_SOURCES = 'DATA_SOURCES',
  SCOPES = 'SCOPES',
  PUBLISH = 'PUBLISH',
  COOKIE_CONSENT = 'COOKIE_CONSENT',
  WORKFLOW_CUSTOMIZATION = 'WORKFLOW_CUSTOMIZATION',
  ABOUT = 'ABOUT',
}

export type VersionType = {
  appVersion: string;
  releaseDate: string;
  minimalBigIDCoreVersion: string;
};

export type NavItemsType = Record<NavItemType, { activated: boolean; version: VersionType }>;
