import { styled } from '@mui/material/styles';

const PREFIX = 'InfoMessage';

export const classes = {
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
};

export const Root = styled('div')({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '20px 0',
    background: '#FAE8FF',
    padding: 20,
    borderRadius: 4,
    color: '#3D3D3D',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
  },
  [`& .${classes.icon}`]: {
    marginRight: 20,
    height: 16,
    width: 16,
    display: 'flex',
    color: '#0011FF',
  },
});
