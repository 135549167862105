import React, { useState, useEffect, useRef, FC, FocusEvent, MouseEvent, useMemo, ReactNode, ChangeEvent } from 'react';
import { Stack } from '@mui/material';
import cx from 'classnames';
import { ValidationError, BaseSchema } from 'yup';
import { debounce } from 'lodash';
import {
  PrimaryButton,
  BigidTooltip,
  BigidFormFieldLabelWrapper,
  TertiaryButton,
  BigidButtonIcon,
} from '@bigid-ui/components';

import { BigidEditIcon, BigidRedoIcon, BigidXIcon } from '@bigid-ui/icons';
import { MeTextField, MeTextFieldPropsType } from '../MeTextField';
import { BigidMeTooltipWithIcon } from '../BigidMeTooltipWithIcon';
import {
  linkValidationNew,
  linkValidationRequired,
  makeAllowedSymbolsValidation,
  makeOptionalAllowedSymbolsValidation,
  parseHTML,
} from '../../utils';
import { useStyles } from './MeFormFieldStyles';
import * as Yup from 'yup';
import { RichTextEditor } from '../RichTextEditor';

export type MeFormFieldPropsType = {
  label?: string;
  value?: string;
  placeholder?: string;
  tip?: string;
  onChange?: (value: string) => void;
  onOpen?: (event: MouseEvent<SVGSVGElement>) => void;
  onClose?: () => void;
  filled?: boolean;
  required?: boolean;
  applyButtonLabel?: string;
  className?: string;
  valueWrapperClassName?: string;
  additonalControls?: ReactNode;
  disabled?: boolean;
  showInEdiModeByDefault?: boolean;
  customValidationSchema?: BaseSchema;
  withHTMLEditor?: boolean;
  withColorEditor?: boolean;
  resetValue?: string;
} & Pick<MeTextFieldPropsType, 'type' | 'testId' | 'dataAttr'>;

export const MeFormField: FC<MeFormFieldPropsType> = ({
  label,
  value = '',
  placeholder,
  onChange,
  onOpen,
  onClose,
  tip,
  filled = false,
  required = false,
  applyButtonLabel = 'Save',
  className,
  valueWrapperClassName,
  additonalControls,
  disabled,
  showInEdiModeByDefault = false,
  type,
  testId,
  dataAttr,
  customValidationSchema,
  withHTMLEditor,
  withColorEditor,
  resetValue,
}) => {
  const [internalValue, setInternalValue] = useState('');
  const [isEditMode, setEditMode] = useState(showInEdiModeByDefault);
  const [error, setError] = useState('');
  const applyButtonRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();

  const handleEnterEditMode = (event: MouseEvent<SVGSVGElement>) => {
    setEditMode(true);
    onOpen && onOpen(event);
  };

  const handleApply = (event?: MouseEvent) => {
    event?.stopPropagation();
    onChange && onChange(internalValue);
    setEditMode(false);
    onClose && onClose();
  };

  const handleCancel = (event?: FocusEvent) => {
    if (event?.relatedTarget !== applyButtonRef.current?.getElementsByTagName('button')?.[0]) {
      setEditMode(false);
      setInternalValue(value);
      onClose && onClose();
      setError('');
    }
  };

  const handleInputRef = (inputElement: HTMLInputElement | null) => {
    isEditMode && inputElement?.focus();
  };

  const validationSchema = useMemo(() => {
    if (customValidationSchema) {
      return customValidationSchema;
    }

    if (withHTMLEditor) {
      return Yup.string();
    }

    if (type === 'link') {
      return required ? linkValidationRequired : linkValidationNew;
    }

    return required ? makeAllowedSymbolsValidation() : makeOptionalAllowedSymbolsValidation();
  }, [required, type, customValidationSchema, withHTMLEditor]);

  const handleInternalValueChange = (value: string) => {
    setInternalValue(value);
    try {
      validationSchema.validateSync(value, { abortEarly: false });
      setError('');
    } catch (error) {
      const { inner } = error as ValidationError;
      setError(inner[0]?.message);
    }
  };

  const handleColorChange = useMemo(() => {
    const onChangeDebounced = debounce((value: string) => onChange && onChange(value), 500);
    return (event: ChangeEvent<HTMLInputElement>) => onChangeDebounced(event.target.value);
  }, [onChange]);

  const handleResetButtonClick = () => {
    onChange && resetValue && onChange(resetValue);
  };

  const showResetButton = withColorEditor && value !== resetValue;

  useEffect(() => setInternalValue(value), [value]);

  return (
    <div className={cx(classes.root, className, { [classes.disabled]: disabled })} data-aid={testId}>
      {filled && (
        <div className={classes.label}>
          {label}
          {tip && <BigidMeTooltipWithIcon title={tip} />}
          {showResetButton && (
            <BigidTooltip title="Reset to Default">
              <BigidButtonIcon
                icon={BigidRedoIcon}
                onClick={handleResetButtonClick}
                dataAid={`${testId}_resetButton`}
              />
            </BigidTooltip>
          )}
        </div>
      )}
      <div
        className={cx(classes.valueWrapper, valueWrapperClassName, {
          [classes.filled]: filled && !isEditMode,
          [classes.valueWrapperFlex]: !(isEditMode && withHTMLEditor),
        })}
        data-aid={`${testId}_fullfield`}
      >
        {!isEditMode ? (
          <>
            <span className={classes.valuePreviewWrapper}>
              {withColorEditor && (
                <input
                  className={classes.colorInput}
                  onChange={handleColorChange}
                  type="color"
                  value={value}
                  data-aid={`${testId}_colorInput`}
                />
              )}
              <span className={classes.valuePreview}>{parseHTML(value)}</span>
            </span>
            {!withColorEditor && (
              <span className={cx('previewControls', classes.previewControls)}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {onChange && (
                    <BigidButtonIcon
                      icon={BigidEditIcon}
                      size="small"
                      onClick={handleEnterEditMode}
                      dataAid={`${testId}_BigidEditIcon`}
                    />
                  )}
                  {additonalControls}
                </Stack>
              </span>
            )}
          </>
        ) : (
          <>
            {withHTMLEditor ? (
              <div className={classes.richTextEditorWrapper}>
                <BigidFormFieldLabelWrapper id="disclaimerEditor" errorIsShown error={error}>
                  <RichTextEditor
                    dataAid={`${testId}_input`}
                    toolbarVariant="preferences"
                    value={internalValue}
                    onBlur={handleInternalValueChange}
                  />
                </BigidFormFieldLabelWrapper>
              </div>
            ) : (
              <MeTextField
                value={internalValue}
                onChange={event => handleInternalValueChange(event.target.value)}
                onBlur={handleCancel}
                onRef={handleInputRef}
                error={error}
                placeholder={placeholder}
                type={type}
                testId={testId}
                dataAttr={dataAttr}
              />
            )}

            {!withHTMLEditor ? (
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" ml={1} flexShrink={0}>
                <span ref={applyButtonRef}>
                  <PrimaryButton
                    text={applyButtonLabel}
                    onClick={handleApply}
                    disabled={!!error}
                    dataAid={`${testId}_applyButton`}
                    size="medium"
                  />
                </span>
                <BigidButtonIcon icon={BigidXIcon} onClick={() => handleCancel()} dataAid={`${testId}_cancelButton`} />
              </Stack>
            ) : (
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <TertiaryButton
                  text="Cancel"
                  onClick={() => handleCancel()}
                  data-aid={`${testId}_cancelButton`}
                  size="medium"
                />
                <span ref={applyButtonRef}>
                  <PrimaryButton
                    text={applyButtonLabel}
                    onClick={handleApply}
                    disabled={!!error}
                    dataAid={`${testId}_applyButton`}
                    size="medium"
                  />
                </span>
              </Stack>
            )}
          </>
        )}
      </div>
    </div>
  );
};
