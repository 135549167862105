export const openConfigWindow = (
  connectorUrl: string,
  callback: (configData: any) => void,
  errorCallback: (error: string) => void,
  auth_state?: string,
): Window | null => {
  // Must open window from user interaction code otherwise it is likely
  // to be blocked by a popup blocker:
  const configWindow = window.open(connectorUrl, '_blank', 'width=500,height=500,scrollbars=no');
  if (!configWindow || configWindow.closed || typeof configWindow.closed == 'undefined') {
    errorCallback('Popup was blocked with the browser, please allow popups and continue');
  }

  if (configWindow) {
    configWindow.sessionStorage.setItem('auth_state', auth_state || '');
  }

  // Listen to popup messages
  let configFinished = false;
  let configData = {};
  const onmessage = (e: {
    data: { type: string; err: any; data: { visibleSlots: any[]; slotValues: { [x: string]: any } } };
  }) => {
    if (e.data.type === 'ldsar.authpopup.finish') {
      // Handle popup finish message
      configFinished = true;
      configData = e.data;
      configWindow?.close();
    }
  };
  window.addEventListener('message', onmessage);

  // Check if popup window has been closed before finishing the configuration.
  // We use a polling function due to the fact that some browsers may not
  // display prompts created in the beforeunload event handler.
  const CHECK_TIMEOUT = 1000;
  const checkWindow = () => {
    if (configWindow?.closed) {
      // Handle popup closing
      if (!configFinished) {
        errorCallback('Connector config failed');
      } else {
        callback(configData);
      }
      window.removeEventListener('message', onmessage);
    } else {
      setTimeout(checkWindow, CHECK_TIMEOUT);
    }
  };

  checkWindow();

  return configWindow;
};
