import React, { useMemo } from 'react';
import {
  BigidBody1,
  BigidDropdown,
  BigidDropdownOption,
  BigidFormFieldLabelWrapper,
  BigidInlineNotification,
  BigidSwitch,
} from '@bigid-ui/components';

import { useFetch } from '../../../hooks';
import { getAvailableVendorEngagements } from '../../../services/settingsService';
import { Box, Stack } from '@mui/material';
import { StageEmailCustomizationContainer } from '../hooks/useStageEmailCustomization';
import { useParams } from 'react-router-dom';
import { intersection } from 'lodash';
import { transformVendorEngagementToOption } from './utils';
import { StyledWarningList } from './StageEmailCustomizationStyles';

export const StageEmailVendorDeletion = () => {
  const { requestTypeId } = useParams();
  const { data: availableVendorEngagements } = useFetch({
    fetchApi: () => getAvailableVendorEngagements(requestTypeId!),
  });

  const { formikEmail } = StageEmailCustomizationContainer.useContainer();
  const values = formikEmail.values.general.configuration;
  const errors = formikEmail.errors?.general?.configuration;

  const engagementWarningLists: {
    missedEmail: number[];
    noAssignedAssets: number[];
    missedDataSource: number[];
  } = useMemo(
    () =>
      availableVendorEngagements?.reduce(
        (acc, { id, groupEmail, hasAssets, hasDataSources }) => ({
          ...acc,
          missedEmail: groupEmail ? acc.missedEmail : [...acc.missedEmail, id],
          noAssignedAssets: hasAssets ? acc.noAssignedAssets : [...acc.noAssignedAssets, id],
          missedDataSource: hasDataSources ? acc.missedDataSource : [...acc.missedDataSource, id],
        }),
        {
          missedEmail: [] as number[],
          noAssignedAssets: [] as number[],
          missedDataSource: [] as number[],
        },
      ),
    [availableVendorEngagements],
  );

  const selectedIds = values?.vendorEngagements?.map(({ value }) => +value) || [];

  const selectedEngagementWarningLists = engagementWarningLists && {
    missedEmail: intersection(engagementWarningLists.missedEmail, selectedIds),
    noAssignedAssets: intersection(engagementWarningLists.noAssignedAssets, selectedIds),
    missedDataSource: intersection(engagementWarningLists.missedDataSource, selectedIds),
  };

  const showWaring =
    selectedEngagementWarningLists &&
    (selectedEngagementWarningLists.missedEmail?.length ||
      selectedEngagementWarningLists.noAssignedAssets?.length ||
      selectedEngagementWarningLists.missedDataSource?.length);

  const vendorEngagementOptions: BigidDropdownOption[] = useMemo(
    () => availableVendorEngagements?.map(transformVendorEngagementToOption),
    [availableVendorEngagements],
  );

  return (
    <Box>
      <BigidFormFieldLabelWrapper
        isRequired
        id="vendor_engagements_select"
        label="Select vendor engagement(s)"
        errorIsShown
        error={errors?.vendorEngagements}
      >
        <BigidDropdown
          applyOnChange
          isMulti
          isSelectAll
          onSelect={selectedOptions =>
            formikEmail.setFieldValue('general.configuration.vendorEngagements', selectedOptions)
          }
          options={vendorEngagementOptions}
          value={values?.vendorEngagements}
          dataAid="vendor_engagements_select"
          entityNameSingular="engagement(s)"
          entityNamePlural="engagement(s)"
        />
      </BigidFormFieldLabelWrapper>
      {!!showWaring && (
        <Box mb={2}>
          <BigidInlineNotification
            open
            type="warning"
            title="Missing vendor information"
            text={[
              {
                subText: (
                  <>
                    <>This missing information may affect communication with the vendor(s):</>
                    <StyledWarningList>
                      {!!selectedEngagementWarningLists.missedEmail.length && (
                        <li>{`${selectedEngagementWarningLists.missedEmail.length} vendor engagement(s) missing compliance contact emails`}</li>
                      )}
                      {!!selectedEngagementWarningLists.noAssignedAssets.length && (
                        <li>{`${selectedEngagementWarningLists.noAssignedAssets.length} vendor engagement(s) missing assigned assets`}</li>
                      )}
                      {!!selectedEngagementWarningLists.missedDataSource.length && (
                        <li>{`${selectedEngagementWarningLists.missedDataSource.length} assets missing data source mapping`}</li>
                      )}
                    </StyledWarningList>
                  </>
                ),
              },
            ]}
          />
        </Box>
      )}
      <Stack direction="row" alignItems="baseline">
        <BigidSwitch
          size="small"
          checked={values?.responseRequired}
          dataAid="responseRequired__switch"
          onChange={(event, checked) => formikEmail.setFieldValue('general.configuration.responseRequired', checked)}
        />
        <BigidBody1>
          Require vendor input for deletion resolution - Allow vendors to respond with resolutions and comments when
          viewing the request details.
        </BigidBody1>
      </Stack>
    </Box>
  );
};
