import { BigidGridRow } from '@bigid-ui/grid';
import {
  RequestType,
  RequestStatusType,
  ProcessingStageType,
  ManagePreferencesStatus,
  RequestKeyType,
  CustomerDetailsType,
  ActionsType,
  CustomerPreferenceType,
} from './SingleRequestTypes';
import { RegulationType } from './RegulationsTypes';
import { BigidMeFilterType } from '../components/MeFilterToolbar';
import { RequestorTypeType, RequestWorkflowDefinitionGetType, RequestWorkflowStageEmailType } from './SettingsTypes';
import { BusinessUserType } from './AuthenticationTypes';
import { DateRangeDates } from '@bigid-ui/components/lib/BigidDateRangePicker/types';
import { BigidGridSorting } from '@bigid-ui/grid/lib/BigidGrid/types';
import { BigidAdvancedToolbarFilterTypes } from '@bigid-ui/components/lib/BigidAdvancedToolbarFilter/types/common';
import { BigidFieldFilterOperator } from '@bigid-ui/components/lib/BigidSideFilter/BigidSideFilter';
import { ReactText } from 'react';
import { BigidFilterOptionValue } from '@bigid-ui/components';

export interface GridRowType extends BigidGridRow {
  id: string;
  type: RequestType;
  requestKey: string;
  requestKeyType: RequestKeyType;
  userType: string;
  owner: string;
  status: RequestStatusType;
  processingStartDate: string;
  dueDate: string;
  dueDays: number;
  processingStage: ProcessingStageType;
  regulation: RegulationType;
  submitFormName?: string;
  brandName?: string;
  brandId?: number;
  responseActualDays?: number;
  responseDeadlineDays?: number;
  closeDate?: string;
  closingResolution?: string;
  closed: boolean;
  expired: boolean;
  inLimit?: boolean;
  trackingNumber?: string;
  attributes: CustomerDetailsType[];
  agentAttributes: CustomerDetailsType[];
  preferences?: CustomerPreferenceType[];
  newMessageCount: number;
  collaborators: GridRequestCollaboratorType[];
  notesCount: number;
  noteFilesCount: number;
  requestedBy: string;
  requestorType: RequestorTypeType;
  externalId?: string;
  requestOrigin: string;
}
export interface GridFileType {
  id: number;
  fileName: string;
  contentType: string;
}
export interface GridActivityLogRowType extends BigidGridRow {
  id: string;
  date: string;
  action: string;
  noteId?: string;
  // webhook details is in noteContent
  noteContent?: string;
  requestProcessingStage: string;
  requestStatus: RequestStatusType;
  actionBy: string;
  actionByRequestOwner: boolean;
  editedByUserFullName: string;
  editedByUserId: number;
  editedDate: string;
  actions: {
    [ActionsType.DELETE_ACTIVITY_LOG]?: boolean;
    [ActionsType.UPDATE_ACTIVITY_LOG]?: boolean;
  };
}

export interface GridWorkflowCustomizationRowType extends RequestWorkflowDefinitionGetType {}

export interface GridWorkflowRequestRowType extends RequestWorkflowStageEmailType {}
export interface UpdateConsentPreferencesGridRowType {
  id: string;
  system: string;
  systemId: number;
  collaborator: string;
  consentType: string;
  category: string;
  selected?: boolean;
  status: ManagePreferencesStatus;
  dataSourceId: number;
  dataSourceName?: string;
  dataSourceType?: string;
}

export interface GridOptOutDataUpdateRowType {
  id: string;
  system: string;
  collaborator: string;
  completed: boolean;
}

export interface GridOptOutDataUpdateType {
  systems: GridOptOutDataUpdateRowType[];
  completed: boolean;
}

export interface GridFetchRequestArgsType {
  requireTotalCount?: boolean;
  skip?: number;
  limit?: number;
  sort?: BigidGridSorting[];
  filter?: BigidMeFilterType[];
  search?: string;
  ids?: number[];
}

export interface GridFetchResponseType {
  totalCount: number;
  data: any;
}

export type VerificationProviderType = VerificationProviderEnum.EXPERIAN | null;

export enum VerificationProviderEnum {
  EXPERIAN = 'Experian',
}

export interface GridAssignUsersType {
  users: BusinessUserType[];
  verificationProvider?: VerificationProviderType;
}

export enum GridToolbarType {
  Included,
  Excluded,
}

export enum GridToolbarActionType {
  Include,
  Exclude,
  Mask,
  Unmask,
  Edit,
}

export enum AddOptionAvailablePropsNames {
  FRIENDLY_NAME = 'friendlyName',
  PURPOSES = 'purposes',
  CATEGORIES = 'categories',
}

export type MultirowEditPopupValues = {
  id?: string | number;
  friendlyName?: string;
  purposes?: string[];
  categories?: string[];
  masked?: boolean;
};

export type ExportRequestsType = GridFetchRequestArgsType & {
  fileFormat: string;
  me?: string;
  requestId?: string;
  selectedColumns?: ExportRequestColumnsType;
  rangeDates?: DateRangeDates;
};

export type ExportRequestColumnsType = {
  requestColumns: string[];
  requestAttributeColumns: string[];
  requestPreferenceColumns: string[];
};

export type UserRequestAttributeInfoType = {
  name: string;
  label: string;
};

export type GridRequestCollaboratorType = {
  userId: string | number;
  userFullName: string;
};

export type UserRequestPreferenceInfoType = {
  name: string;
  label: string;
};

export type GridRequestsFilterType = {
  id?: ReactText; // string
  field: string;
  value: BigidFilterOptionValue; // string | number | boolean | string[];
  dateFrom?: string | Date;
  dateTo?: string | Date;
  currentRangeOption?: string;
  currentRangeMode?: string;
  operator: BigidFieldFilterOperator;
  type?: BigidAdvancedToolbarFilterTypes;
};
