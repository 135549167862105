export enum PRICING_PLAN {
  TRIAL = 'Freemium',
  STARTER = 'Starter',
  STANDARD = 'Standard',
  GROWTH = 'Growth',
  CC_TRIAL = 'CC_Trial',
  CC_STARTER = 'CC_Starter',
  CC_STANDARD = 'CC_Standard',
  CC_GROWTH = 'CC_Growth',
}

export enum CURRENCY {
  USD = 'USD',
}

export enum ADDITIONAL_PRICING_PLAN {
  ENTERPRISE = 'ENTERPRISE',
}

export enum PRODUCT_TYPES {
  PRIVACY_REQUEST = 'privacy_request',
  COOKIE_CONSENT = 'cookie_consent',
}

export enum PAYMENT_METHOD {
  NO_METHOD = 'NO_METHOD',
  HAS_METHOD = 'HAS_METHOD',
}
export interface PricingType {
  planName: PRICING_PLAN;
  paymentPeriod: PAYMENT_PERIOD;
  promoCode: string;
  token?: string;
  billingAddress?: BillingAddressType;
}

export interface PricingPlanUpgradeStateType {
  upgradeEnabled: boolean;
}

export interface BillingAddressType {
  countryCode: string;
  street: string;
  state: string;
  postalCode: string;
  city: string;
}

export interface PaymentDetailsType {
  promoCode: string;
  paymentPeriod: PAYMENT_PERIOD;
  planName: PRICING_PLAN;
}

export enum CreditCardsType {
  VISA = 'Visa',
  MASTERCARD = 'Mastercard',
  AMERICAN_EXPRESS = 'American Express',
  CREDIT_CARD = 'credit-card',
}

export type PaymentPrice = {
  price: number;
  priceWithDiscount: number;
  discountAmount: number;
  discountPercentage: number;
  currency: string;
};

export type UpgradePlanType = PaymentPrice & {
  balance: number;
  cardNumber: string;
  cardType: CreditCardsType;
  newPlanPrice: number;
};

export type TitledContentType = {
  icon: string;
  iconSmall?: string;
  title: string;
  text: string;
};

export enum ProductType {
  PRIVACY_REQUEST = 'privacy_request',
  COOKIE_CONSENT = 'cookie_consent',
}

export enum PAYMENT_PERIOD {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export interface PricingPlanSubscriptionType {
  paymentPeriod: PAYMENT_PERIOD;
  expirationDate: string;
  expired: boolean;
}

export interface PricingPlansResponseType {
  productType: ProductType;
  trial: boolean;
  planName: PRICING_PLAN | ADDITIONAL_PRICING_PLAN;
  monthly: number;
  yearly: number;
  currency: CURRENCY.USD;
  validForMonthlyUpgrade: boolean;
  validForYearlyUpgrade: boolean;
  subscription?: PricingPlanSubscriptionType;
}
export interface PricingPlanDefaultType {
  features: string[] | { name: string; value: string; enabled: boolean }[];
}
export interface PricingPlansFullDataType {
  productType: ProductType;
  trial: boolean;
  planName: PRICING_PLAN | ADDITIONAL_PRICING_PLAN;
  planDisplayName: PRICING_PLAN | 'FREE';
  monthly: number;
  yearly: number;
  monthlyWithDiscount: number;
  currency: CURRENCY.USD;
  validForMonthlyUpgrade: boolean;
  validForYearlyUpgrade: boolean;
  subscription?: PricingPlanSubscriptionType;
  features: string[] | { name: string; value: string; enabled: boolean }[];
}

export type PricingPlanResponseType = {
  planName: PRICING_PLAN;
  expirationDate: Date;
};

export type SubmitPricingPlanParamsType = {
  planName: PRICING_PLAN | ADDITIONAL_PRICING_PLAN;
  companyPhone: string;
  preferableContactTime: string;
};

export type FeatureType = {
  code: string;
  enabled: boolean;
  quota?: number | null;
  count?: number;
};

export enum FeatureCode {
  CONSUMER_MAIL_LOCALIZATION = 'consumer_mail_localization',
  REPORT_PDF = 'report_pdf',
  FLOW_EDIT = 'flow_edit',
  FLOW_PREFERENCES = 'flow_preferences',
  FLOW_OPT_OUT = 'flow_optOut',
  FLOW_APPEAL = 'flow_appeal',
  DATASOURCE_ADD_QUOTA = 'datasource_add_quota',
  USER_INVITE = 'user_invite',
  WHITELABEL_BIGIDME_ICON = 'whitelabel_bigidmeicon',
  DEEPDISCOVERY_UPGRADE = 'deepdiscovery_upgrade',
  MULTILINGUAL = 'consumer_multilingual',
  EDITOR_MULTIPLE_FORMS = 'editor_multiple_forms',
  INTEGRATION_MANAGEMENT_SCREEN = 'integration_management_screen',
}
