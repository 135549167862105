import { styled } from '@mui/styles';
import { BigidBody1 } from '@bigid-ui/components';

export const StyledRequestManagerNotesDialog = {
  Note: styled('div')(() => ({
    margin: '6px 0',
    fontSize: '0.875rem',
  })),

  File: styled('div')({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginRight: 8,
  }),
  ViewNote: styled('u')(({ theme }) => ({
    color: theme.vars.palette.bigid?.blue700,
    cursor: 'pointer',
  })),
  Body1: styled(BigidBody1)(() => ({
    fontSize: '0.875rem!important',
  })),
};
