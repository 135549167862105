import { map, omit } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import { LanguageType } from '@consumer/state/languageContainer';

import { EmailSenderDataType, PortalEditorGeneralTranslationsType, EmailTemplateFieldsType } from '../types';

// use it instead of btoa to fix this problem https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
export const b64EncodeUnicode = (str: string) =>
  btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    }),
  );

// use it instead of atob to fix this problem https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
export const b64DecodeUnicode = (str: string) =>
  decodeURIComponent(
    Array.prototype.map.call(atob(str), (c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''),
  );

export const encodeEmail = ({ senderName, translations }: EmailSenderDataType) => {
  const normalizedEmailsTranslations = map(translations, (val, ind) => ({
    [ind]: { footerText: b64EncodeUnicode(val!.footerText) },
  }));

  return {
    senderName,
    translations: normalizedEmailsTranslations.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
  };
};

export const encodeWorkflowEmail = ({
  translations,
  appealEnabled = false,
}: {
  translations: Partial<Record<LanguageType, EmailTemplateFieldsType>>;
  appealEnabled?: boolean;
}) => {
  const normalizedMessage = map(translations, (val, ind) => ({
    [ind]: {
      ...omit(val, ['appealText']),
      message: b64EncodeUnicode(val!.message || ''),
      ...(appealEnabled ? { appealText: b64EncodeUnicode(val!.appealText || '') } : {}),
    },
  }));

  return {
    translations: normalizedMessage.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
  };
};

export const encodeOnScreenMassage = ({
  translations,
}: {
  translations: Partial<Record<LanguageType, Record<string, string>>>;
}) => {
  const normalizedMessage = map(translations, (val, ind) => ({
    [ind]: {
      ...val,
      message: b64EncodeUnicode(val!.message),
    },
  }));

  return {
    translations: normalizedMessage.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
  };
};

export const b64EncodeGeneralSettingsDescription = ({
  translations,
}: {
  translations: PortalEditorGeneralTranslationsType;
}) => {
  const normalizedMessage = map(translations, (val, ind) => ({
    [ind]: { ...val, description: val?.description ? b64EncodeUnicode(val.description) : '' },
  }));

  return {
    translations: normalizedMessage.reduce((acc, cur) => ({ ...acc, ...cur }), {}),
  };
};
