import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Stack } from '@mui/system';
import { useParams } from 'react-router-dom';
import {
  BigidAdvancedToolbarFilter,
  BigidAdvancedToolbarFilterUnion,
  BigidChip,
  BigidColorsV2,
  BigidDialog,
  BigidHeading6,
  BigidNotes2,
  BigidTabs,
  BigidTextField,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { NoteItem } from './NoteItem';
import { BigidAddIcon, BigidAttachmentIcon, BigidFiltersIllustration } from '@bigid-ui/icons';
import useNotesDialog, { NOTE_DEFAULT_MAX_SYMBOLS } from './useNotesDialog';
import { useTheme } from '@mui/styles';
import { FileUploaderLink } from '../../FileUploaderLink';
import { fileUploadRules } from '../../../configs/files';
import { LayoutEmptyState } from '../../LayoutEmptyState';
import { ReactComponent as IconEmptyState } from '../../../assets/illustrations/emptyState/Contact_Requestor_Empty_State.svg';
import { Loader } from '../../Loader';
import { NoteFileLoader } from './NoteFileLoader';
import { NoteFileChip } from './NoteFileChip';
import { StyledRequestManagerNotesDialog } from './NotesDialogStyles';
import { isArray } from 'lodash';

enum NoteTabs {
  REQUEST_NOTES_TAB,
  DATASOURCE_NOTES_TAB,
}

type NotesDialogPropsType = {
  requestId?: string;
  isViewNote?: boolean;
  noteId?: string;
  readOnly?: boolean;
  noteFilesCount?: number;
  notesCount?: number;
  createNote?: boolean;
  onDialogClose?: () => void;
};

export const NotesDialog: FC<NotesDialogPropsType> = ({
  requestId,
  readOnly,
  noteFilesCount,
  notesCount,
  createNote = true,
  isViewNote,
  noteId,
  onDialogClose,
}) => {
  const { ViewNote } = StyledRequestManagerNotesDialog;
  const [selectedTab, setSelectedTab] = useState(NoteTabs.REQUEST_NOTES_TAB);
  const [showAddNote, setShowAddNote] = useState(false);
  const [filterValues, setFilterValues] = useState<BigidAdvancedToolbarFilterUnion[]>([]);
  const { id } = useParams<'id'>();
  const scrollRef = useRef<null | HTMLDivElement>(null);

  const theme = useTheme();
  const {
    isOpenModal,
    openModal,
    onCloseModal,
    notes,
    onSaveNote,
    onEditNote,
    onDeleteNote,
    files,
    onFileRemove,
    onRemoveFileContinue,
    handleFileUpload,
    onChangeNewNote,
    fileToRemove,
    setFileToRemove,
    formikNewNote,
    resetNote,
    notesLoading,
    disableSaving,
    uploading,
    filters,
    filterSelectedOptionsConfig,
    onResetFilters,
  } = useNotesDialog(requestId || id, readOnly);

  const stageFilterOptions: string[] = useMemo(() => {
    const filterOptions = filterValues.find(f => f.field === 'Stage')?.options;
    return isArray(filterOptions) ? filterOptions.map(o => o.value) : [];
  }, [filterValues]);

  const requestNotes = useMemo(() => {
    return (
      notes?.filter(note => {
        const isRequestNote = !note.dataSourceName;

        const isInStageFilter = stageFilterOptions.length ? stageFilterOptions.includes(note.stage) : true;

        return isRequestNote && isInStageFilter;
      }) || []
    );
  }, [notes, stageFilterOptions]);

  const dataSourceNotes = useMemo(() => notes?.filter(n => n.dataSourceName) || [], [notes]);

  useEffect(() => {
    const isDSTab = dataSourceNotes.find(n => n.id === noteId);
    if (isDSTab) {
      setSelectedTab(NoteTabs.DATASOURCE_NOTES_TAB);
    }
  }, [dataSourceNotes, noteId]);

  const onNotesModalClosed = () => {
    onDialogClose && onDialogClose();
    onCloseModal();
  };

  return (
    <Box position="relative">
      {readOnly && !isViewNote ? (
        <BigidChip
          bgColor={BigidColorsV2.white}
          color={BigidColorsV2.gray[700]}
          iconPlacement="right"
          icon={noteFilesCount ? <BigidAttachmentIcon /> : undefined}
          label={`${notesCount} Notes${noteFilesCount ? ' +' : ''}`}
          onClick={openModal}
          outline="solid"
          outlineColor={BigidColorsV2.gray[700]}
          shadow
          size="medium"
          width="auto"
          dataAid={`notes-column-${requestId || id}`}
        />
      ) : isViewNote ? (
        <ViewNote onClick={openModal} data-aid={`activity-log-see-note-${noteId}`}>
          View note
        </ViewNote>
      ) : (
        <SecondaryButton
          text={`Notes (${notes?.length || 0})`}
          size="medium"
          onClick={openModal}
          dataAid="notes-button"
        />
      )}
      <BigidDialog
        isOpen={isOpenModal}
        onClose={onNotesModalClosed}
        title={`Notes (Request ID ${requestId || id})`}
        borderBottom
        buttons={[
          {
            component: TertiaryButton,
            text: 'Close',
            onClick: onNotesModalClosed,
            dataAid: 'notes-dialog__cancel',
          },
        ]}
      >
        <Stack height="560px" justifyContent="space-between">
          <Box>
            <Box borderBottom={`1px solid ${theme.vars.palette.bigid?.gray300}`} margin="0 -15px">
              <BigidTabs
                selectedIndex={selectedTab}
                onChange={setSelectedTab}
                tabs={[
                  {
                    label: 'Request notes',
                    counter: requestNotes?.length,
                  },
                  {
                    label: 'Data source notes',
                    counter: dataSourceNotes?.length,
                    disabled: !dataSourceNotes?.length,
                  },
                ]}
                variant="fullWidth"
                centered
              />
            </Box>
          </Box>
          <Box
            height="525px"
            overflow="scroll"
            sx={{
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                width: '0px',
              },
            }}
          >
            {notesLoading ? (
              <Loader />
            ) : (
              <>
                {selectedTab === NoteTabs.REQUEST_NOTES_TAB && (
                  <Stack height="100%">
                    <Box mt={2}>
                      <BigidAdvancedToolbarFilter
                        filters={filters}
                        selectedOptionsConfig={filterSelectedOptionsConfig}
                        onFiltersChange={setFilterValues}
                        dataAid="notes-filter"
                      />
                    </Box>
                    {!showAddNote && requestNotes?.length === 0 && (
                      <Stack justifyContent="center" height="100%">
                        <LayoutEmptyState
                          illustration={filterValues.length ? <BigidFiltersIllustration /> : <IconEmptyState />}
                          actions={
                            !readOnly && createNote && !filterValues.length
                              ? [
                                  {
                                    label: 'Add a note',
                                    onClick: () => setShowAddNote(true),
                                    isGlobal: true,
                                    icon: BigidAddIcon,
                                  },
                                ]
                              : filterValues.length
                              ? [
                                  {
                                    label: 'Reset filters',
                                    onClick: onResetFilters,
                                    isGlobal: true,
                                    testId: 'reset-filter',
                                  },
                                ]
                              : []
                          }
                          description={
                            filterValues.length
                              ? 'There are no results for this filter'
                              : 'No notes have been added yet'
                          }
                        />
                      </Stack>
                    )}
                    <Stack mt={2}>
                      {requestNotes.map((note, noteIndex) => (
                        <NoteItem
                          key={note.id}
                          scrollRef={note.id === noteId ? scrollRef : null}
                          note={note}
                          isHighlighted={note.id === noteId}
                          noteIndex={noteIndex}
                          onEdit={onEditNote}
                          onDelete={onDeleteNote}
                          readOnly={readOnly}
                          disableSaving={disableSaving}
                        />
                      ))}
                    </Stack>
                  </Stack>
                )}
                {selectedTab === NoteTabs.DATASOURCE_NOTES_TAB && (
                  <Stack mt={2}>
                    {dataSourceNotes.map((note, noteIndex) => (
                      <NoteItem
                        key={note.id}
                        scrollRef={note.id === noteId ? scrollRef : null}
                        note={note}
                        isHighlighted={note.id === noteId}
                        noteIndex={noteIndex}
                        onEdit={onEditNote}
                        onDelete={onDeleteNote}
                        readOnly={readOnly}
                        disableSaving={disableSaving}
                      />
                    ))}
                  </Stack>
                )}
              </>
            )}
          </Box>
          <Box>
            {selectedTab === NoteTabs.REQUEST_NOTES_TAB &&
              !readOnly &&
              !showAddNote &&
              requestNotes?.length > 0 &&
              createNote && (
                <Box mt={2} mb={-2}>
                  <TertiaryButton
                    startIcon={<BigidAddIcon />}
                    text="Add a note"
                    onClick={() => setShowAddNote(true)}
                    dataAid="add-note-button"
                    size="medium"
                  />
                </Box>
              )}
            {showAddNote && selectedTab === NoteTabs.REQUEST_NOTES_TAB && (
              <Stack bgcolor={theme.vars.palette.bigid?.gray150} p={2} mt={2} borderRadius={1}>
                <Stack flexDirection="row" alignItems="center" mb={2}>
                  <BigidHeading6 fontWeight={700}>New Note</BigidHeading6>
                </Stack>
                <BigidTextField
                  value={formikNewNote.values.content}
                  onChange={e => onChangeNewNote(e.target.value)}
                  errorMessage={formikNewNote.errors.content}
                  multiline
                  dataAid="edit-note-textarea"
                />
                <BigidNotes2>
                  {formikNewNote.values.content.length}/{NOTE_DEFAULT_MAX_SYMBOLS}
                </BigidNotes2>
                <Stack flexDirection="row" alignItems="center" flexWrap="wrap">
                  {files.map((file, fileIndex) => (
                    <Box key={file.id} mt={1}>
                      <NoteFileChip
                        onDelete={() => onFileRemove(file)}
                        dataAid={`added-note-file_${fileIndex}`}
                        label={file.fileName}
                      />
                    </Box>
                  ))}
                </Stack>
                {uploading && <NoteFileLoader />}
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={2}
                  pt={2}
                  borderTop={`1px solid ${theme.vars.palette.bigid?.gray300}`}
                >
                  <FileUploaderLink
                    maxSize={fileUploadRules.noteFiles.MAX_FILE_SIZE_MB}
                    isDisabled={files.length >= fileUploadRules.noteFiles.MAX_FILES}
                    onSelect={handleFileUpload}
                    format={fileUploadRules.noteFiles.FORMATS}
                    tooltip={`Files supported: png, jpg, jpeg, csv, pdf.\nMax. ${fileUploadRules.noteFiles.MAX_FILES} files, no more than\n${fileUploadRules.noteFiles.MAX_FILE_SIZE_MB} MB each`}
                    testId="note-file-uploader"
                  />
                  <Stack flexDirection="row">
                    <TertiaryButton
                      text="Cancel"
                      onClick={() => {
                        setShowAddNote(false);
                        resetNote();
                      }}
                      size="medium"
                    />
                    <PrimaryButton
                      text="Save"
                      onClick={async () => {
                        await onSaveNote();
                        setShowAddNote(false);
                      }}
                      size="medium"
                      disabled={!formikNewNote.isValid || !formikNewNote.values.content.length || disableSaving}
                      dataAid="edit-note-save-button"
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Box>
        </Stack>
      </BigidDialog>
      <BigidDialog
        isOpen={!!fileToRemove}
        onClose={() => setFileToRemove(undefined)}
        title="Delele file"
        maxWidth="xs"
        buttons={[
          {
            text: 'Cancel',
            component: TertiaryButton,
            onClick: () => setFileToRemove(undefined),
            dataAid: 'remove-file-dialog__cancel',
          },
          {
            text: 'Yes, continue',
            component: PrimaryButton,
            onClick: onRemoveFileContinue,
            dataAid: 'remove-file-dialog__apply',
          },
        ]}
      >
        You are about to delete a file, sure you want to continue?
      </BigidDialog>
    </Box>
  );
};
