import { styled } from '@mui/material';
import { BigidArrowLeftIcon } from '@bigid-ui/icons';

const Button = styled('div')`
  display: inline-flex;
  padding: 3px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.vars.palette.bigid?.gray300};
  background-color: ${({ theme }) => theme.vars.palette.bigid?.white};
  cursor: pointer;
  margin-right: 17px;
`;

const Icon = styled(BigidArrowLeftIcon)`
  fill: ${({ theme }) => theme.vars.palette.bigid?.gray500};
`;

export const StyledBackButton = {
  Button,
  Icon,
};
