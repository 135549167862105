import React, { FC, useState } from 'react';

import { classes, Root } from './WebhookCustomizationStyles';
import { FormikProps } from 'formik';
import {
  BigidCheckbox,
  BigidDialog,
  BigidDropdown,
  BigidDropdownOption,
  BigidDropdownValue,
  BigidFormFieldLabelWrapper,
  BigidTabsAndContent,
  BigidTabsAndContentProps,
  BigidTextField,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { BigidApplyIcon, BigidClearFilledIcon } from '@bigid-ui/icons';
import { Box, DialogContentText } from '@mui/material';
import {
  useExtraKeyValueFieldsControl,
  MeExtraKeyValueFields,
  ExtraFieldItemData,
} from '../../../components/MeExtraField';
import { SystemRequestTypeWithUrl } from '../../../components/systems/SystemRequestTypeWithUrl';
import { MeTextFieldAutocompleteWithForm } from '../../../components/MeTextFieldAutocompleteWithForm';
import { onChangeExtraField } from '../../../components/systems/SystemOperationFlowForm/helpers/helpers';
import { WebhooksReasonType, WebhookTestResponseType, WebhookType } from '../../../types';
import { postTestWebhook } from '../../../services/settingsService';
import { BigidMeTooltipWithIcon } from '../../../components';
import { SystemInfoMessage } from '../../../components/systems/SystemInfoMessage';

export const webhookReason: BigidDropdownOption[] = [
  {
    value: WebhooksReasonType.STAGE_START,
    displayValue: 'Stage start',
    id: WebhooksReasonType.STAGE_START,
  },
  { value: WebhooksReasonType.STAGE_END, displayValue: 'Stage end', id: WebhooksReasonType.STAGE_END },
];

const toDropdownValue = (value?: WebhooksReasonType): BigidDropdownValue =>
  value ? webhookReason.filter(({ id }) => value === id) : [];

const createNewExtraFieldItem = () => ({ name: '', value: '' });

interface WebhookCustomizationPropsType {
  formikWebhook: FormikProps<WebhookType>;
  webhookPlaceholders?: { value: string; label: string }[];
  onSaveWebhookCustomization: (webhook: WebhookType) => void;
}

export const WebhookCustomization: FC<WebhookCustomizationPropsType> = ({
  formikWebhook,
  webhookPlaceholders,
  onSaveWebhookCustomization,
}) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [isPending, setPending] = useState(false);
  const [isMoreInfoOpen, setMoreInfoOpen] = useState(false);
  const [testConnectionStatus, setTestConnectionStatus] = useState<WebhookTestResponseType>();

  const {
    onAddNew: onAddNewHeaders,
    onDelete: onDeleteHeaders,
    onChangeItem: onChangeHeadersItem,
  } = useExtraKeyValueFieldsControl({
    initialData: formikWebhook.values.headers?.map((b, index) => ({ ...b, id: index.toString() })) || [
      createNewExtraFieldItem(),
    ],
    createNewItem: createNewExtraFieldItem,
    onChange: onChangeExtraField('headers', formikWebhook.setFieldValue),
    formData: formikWebhook.values.headers as ExtraFieldItemData[],
  });

  const {
    onAddNew: onAddNewParameters,
    onDelete: onDeleteParameters,
    onChangeItem: onChangeParametersItem,
  } = useExtraKeyValueFieldsControl({
    initialData: formikWebhook.values.parameters?.map((b, index) => ({ ...b, id: index.toString() })) || [
      createNewExtraFieldItem(),
    ],
    createNewItem: createNewExtraFieldItem,
    onChange: onChangeExtraField('parameters', formikWebhook.setFieldValue),
    formData: formikWebhook.values.parameters as ExtraFieldItemData[],
  });

  const tabsAndContentConfig: BigidTabsAndContentProps = {
    tabProps: {
      tabs: [
        {
          label: 'Params',
          data: {
            component: MeExtraKeyValueFields,
            customProps: {
              onAddNew: onAddNewParameters,
              onDelete: (id: number) => {
                onDeleteParameters(id);
                formikWebhook.setTouched({ parameters: [] });
              },
              data: formikWebhook.values.parameters.map((r, i) => ({ ...r, id: i.toString() })),
              onChangeItem: onChangeParametersItem,
              autocompleteListItems: webhookPlaceholders,
              formData: formikWebhook,
              valueName: 'parameters',
              keyName: 'parameters',
              testId: `webhook-${formikWebhook.values.id}__parameter`,
              allowToDeleteAll: true,
            },
          },
        },
        {
          label: 'Headers',
          data: {
            component: MeExtraKeyValueFields,
            customProps: {
              onAddNew: onAddNewHeaders,
              onDelete: (id: number) => {
                onDeleteHeaders(id);
                formikWebhook.setTouched({ headers: [] });
              },
              data: formikWebhook.values.headers.map((r, i) => ({ ...r, id: i.toString() })),
              onChangeItem: onChangeHeadersItem,
              autocompleteListItems: webhookPlaceholders,
              formData: formikWebhook,
              valueName: 'headers',
              keyName: 'headers',
              testId: `webhook-${formikWebhook.values.id}__header`,
              allowToDeleteAll: true,
            },
          },
        },
        {
          label: 'Body',
          data: {
            component: MeTextFieldAutocompleteWithForm,
            customProps: {
              onChange: onChangeExtraField('body', formikWebhook.setFieldValue),
              autocompleteListItems: webhookPlaceholders || [],
              formData: formikWebhook,
              data: formikWebhook.values.body,
              testId: `webhook-${formikWebhook.values.id}__body`,
              name: 'body',
              triggerChar: '$',
              dropDownOffsetY: 30,
              dropdownHeight: 160,
              dataAid: `WebhookFormBodyTextField-${formikWebhook.values.id}`,
              ignoreRegex: /[\n\t\}]/g,
              isMultiline: true,
            },
          },
        },
      ],
      onChange: (index: number) => setSelectedTab(index),
      selectedIndex: selectedTab,
    },
  };

  const onTestWebhookConnection = async () => {
    setPending(true);
    const status = await postTestWebhook(formikWebhook.values);
    setTestConnectionStatus(status);
    setPending(false);
  };

  return (
    <Root>
      <div className={classes.controls}>
        <div className={classes.status}>
          <SecondaryButton
            text={isPending ? 'Pending...' : 'Test Connection'}
            dataAid="test-connection-webhook__button"
            size="medium"
            onClick={onTestWebhookConnection}
            disabled={!formikWebhook.isValid}
          />
          {testConnectionStatus && (
            <span className={classes.status}>
              {testConnectionStatus.httpStatusCode >= 200 && testConnectionStatus.httpStatusCode < 300 ? (
                <span className={classes.statusLabel}>
                  <BigidApplyIcon color="positive" />
                  &nbsp;Test Connection Succeeded
                </span>
              ) : (
                <span className={classes.statusLabel}>
                  <BigidClearFilledIcon color="negative" />
                  &nbsp;Test Connection Failed
                </span>
              )}
              <BigidMeTooltipWithIcon
                title="Click on the info icon to see more info"
                dataAid="test-tooltip"
                onClick={() => setMoreInfoOpen(true)}
              />
              <BigidDialog
                borderTop
                maxWidth="sm"
                title="Test Connection Info"
                isOpen={isMoreInfoOpen}
                onClose={() => setMoreInfoOpen(false)}
              >
                <DialogContentText>
                  <div>
                    <b>HTTP Status: </b>
                    {testConnectionStatus.httpStatusCode}
                  </div>
                  <div>
                    <b>HTTP Response: </b>
                    <div>{testConnectionStatus.response}</div>
                  </div>
                </DialogContentText>
              </BigidDialog>
            </span>
          )}
        </div>
        <PrimaryButton
          text="Save"
          dataAid="save-webhook__button"
          size="medium"
          onClick={() => onSaveWebhookCustomization(formikWebhook.values)}
          disabled={!formikWebhook.dirty || !formikWebhook.isValid}
        />
      </div>
      <div className={classes.root}>
        <BigidTextField
          errorMessage={formikWebhook.errors.name}
          value={formikWebhook.values.name}
          type="text"
          label="Action Name"
          dataAid={`webhook-${formikWebhook.values.id}__name`}
          placeholder="Enter descriptive name for this action"
          onChange={value => formikWebhook.setFieldValue('name', value.target.value)}
          size="medium"
        />
        <Box py={1}>
          <BigidFormFieldLabelWrapper label="When to trigger?" id={`webhookReason-${formikWebhook.values.id}`}>
            <BigidDropdown
              options={webhookReason}
              onSelect={values => formikWebhook.setFieldValue('trigger', values[0].value)}
              placeholder="Select when to trigger the API call"
              dataAid={`webhook-${formikWebhook.values.id}__reason`}
              value={toDropdownValue(formikWebhook.values.trigger)}
            />
          </BigidFormFieldLabelWrapper>
        </Box>
        <Box py={1}>
          <SystemRequestTypeWithUrl
            formData={formikWebhook as any}
            requestTypeName="method"
            urlName="url"
            testId={`webhook-${formikWebhook.values.id}__url`}
          />
        </Box>
        <Box py={1}>
          <BigidTabsAndContent {...tabsAndContentConfig} classes={{ tabsContainer: classes.tabsContainer }} />
          <SystemInfoMessage
            text={'Type "$" to open a list of available parameter values. For more information {link}'}
            link="https://docs.bigid.com/v1/docs/generic-api"
            linkText="click here"
          />
        </Box>
        <Box py={1}>
          <BigidCheckbox
            checked={formikWebhook.values.validateSsl}
            label="Support server self-signed certificates"
            dataAid={`webhook-${formikWebhook.values.id}-support__checkbox`}
            onChange={(event, checked) => formikWebhook.setFieldValue('validateSsl', checked)}
          />
        </Box>
      </div>
    </Root>
  );
};
