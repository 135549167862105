import { styled } from '@mui/material';

export const StepTooltipStyled = {
  Main: styled('div')({
    position: 'relative',
    boxSizing: 'border-box',
    width: 'fit-content',
    margin: 'auto',
    backgroundColor: 'white',
    paddingBottom: '20px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  IconWrapper: styled('div')({
    position: 'absolute',
    right: 8,
    top: 8,
  }),
  ButtonsSection: styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    columnGap: 6,
    paddingTop: 12,
    padding: '0 18px',
    alignItems: 'center',
  }),
  Button: styled('div')({
    marginLeft: '18px',
  }),
};
