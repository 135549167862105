import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  pricingPageLink: {
    color: theme.vars.palette.bigid?.primary900,
    textDecoration: 'underline',

    '&:hover': {
      color: theme.vars.palette.bigid?.primary900,
    },
  },
}));
