import React from 'react';
import { ReactComponent as IconPrivacyPortal } from '../../assets/illustrations/Bigid-PRIVACY-PORTAL.svg';
import { BigidMergerLogoPrivacy } from '@bigid-ui/icons';
import { isSmallIdApp } from '../../utils';

export const MeLogo = () => {
  if (isSmallIdApp()) {
    return <BigidMergerLogoPrivacy />;
  } else {
    return <IconPrivacyPortal />;
  }
};
