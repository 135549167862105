import { useEffect, useReducer } from 'react';
import { createContainer } from 'unstated-next';

import { RequestManagerStateType } from '../../types';
import { getRequestManagerState } from '../../services/request';
import { FEATURES, isNewFeature, markFeatureAsKnown } from '../../utils/featuresGuideUtils';

type RequestManagerStateReducerType = {
  requestManagerState: RequestManagerStateType;
  requestManagerStateLoaded: boolean;
};

type RequestManagerStateLoadedAction = {
  type: 'loaded';
  payload: RequestManagerStateType;
};

type RequestManagerStateAction = RequestManagerStateLoadedAction;

const requestManagerStateReducer = (
  state: RequestManagerStateReducerType,
  action: RequestManagerStateAction,
): RequestManagerStateReducerType => {
  switch (action.type) {
    case 'loaded':
      return {
        requestManagerState: {
          ...action.payload,
          actions: {
            ...action.payload.actions,
          },
        },
        requestManagerStateLoaded: true,
      };
    default:
      return state;
  }
};

const useRequestManagerState = () => {
  const [{ requestManagerState, requestManagerStateLoaded }, dispatch] = useReducer(requestManagerStateReducer, {
    requestManagerState: {
      actions: {},
      dashboardEnabled: false,
      requestManagerEnabled: false,
    },
    requestManagerStateLoaded: false,
  });

  useEffect(() => {
    const loadRequestManagerState = async () => {
      const state = await getRequestManagerState();
      dispatch({ type: 'loaded', payload: state });
    };
    loadRequestManagerState();
  }, []);

  useEffect(() => {
    if (isNewFeature(FEATURES.PRIVACY_REQUEST)) {
      markFeatureAsKnown(FEATURES.PRIVACY_REQUEST);
    }
  }, []);

  return {
    requestManagerState,
    requestManagerStateLoaded,
    requestManagerStateActions: requestManagerState.actions,
  };
};

export const RequestManagerStateContainer = createContainer(useRequestManagerState);
