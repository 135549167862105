import React, { ReactElement, ReactNode } from 'react';
import { BigidTooltip } from '@bigid-ui/components';
import { useStyles } from './ConsentTooltipStyles';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@bigid-ui/components';
import { Stack } from '@mui/system';
import { BigidXIcon } from '@bigid-ui/icons';

type ConsentTooltipType = {
  title?: string;
  content?: ReactNode;
  children: ReactElement;
  closeTooltip: () => void;
};

export const ConsentTooltip = ({ children, closeTooltip }: ConsentTooltipType) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClose = (e?: React.MouseEvent<Element, globalThis.MouseEvent>) => {
    e?.stopPropagation();
    e?.preventDefault();
    closeTooltip();
  };

  const handleTryIt = (e?: React.MouseEvent<Element, globalThis.MouseEvent>) => {
    handleClose(e);
    navigate('/consents-mapping');
  };

  return (
    <BigidTooltip
      width="300px"
      title={
        <div className={classes.content}>
          <div className={classes.title}>
            Consent & Preferences{' '}
            <div className={classes.closeIconWrapper}>
              <BigidXIcon onClick={e => handleClose(e)} className={classes.closeIcon} dataAid="CloseIcon" />
            </div>
          </div>
          <b>Free until 31 Jan 2025 - automated consent management.</b> Connect consumer preference selections to your
          consent systems for self-service consent management.
          <Stack flexDirection="row" justifyContent="end">
            <PrimaryButton onClick={e => handleTryIt(e)} text="Try it" size="medium" />
          </Stack>
        </div>
      }
      id="consent-tooltip"
      className={classes.tooltip}
      placement="right"
      open
      PopperProps={{
        container: () => document.getElementById('consent-tooltip-container'),
        anchorEl: {
          getBoundingClientRect: () => {
            const anchor = document.getElementById('consent-tooltip-anchor')?.getBoundingClientRect();
            return {
              x: 0,
              y: 0,
              width: 0,
              height: 0,
              top: (anchor?.y || 0) - 10,
              right: 0,
              bottom: 0,
              left: (anchor?.x || 0) + 310,
              toJSON: () => null,
            };
          },
        },
      }}
    >
      {children}
    </BigidTooltip>
  );
};
