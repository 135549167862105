import {
  BigidBody1,
  BigidDialog,
  BigidMenu,
  BigidMenuItem,
  BigidTabs,
  BigidTextField,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import React, { FC, useMemo, useRef, useState } from 'react';
import { Box, Stack } from '@mui/system';
import { IconButton } from '@mui/material';
import { BigidChevronRightIcon, BigidMoreActionIcon } from '@bigid-ui/icons';
import { BigidTabsItem } from '@bigid-ui/components/lib/BigidTabs/BigidTabs';
import { RequestManagerViewsContainer } from '../useRequestManagerViews';
import { UserGridPreferenceView } from '../../../types';
import { RequestManagerGridContainer } from '../useRequestManagerGrid';
import { find } from 'lodash';

type OptionPropsType = {
  option: BigidTabsItem<UserGridPreferenceView>;
  onDeleteView: () => void;
  onApplyView: () => void;
  onRenameView: () => void;
  onReset: () => void;
};

const Option: FC<OptionPropsType> = ({ option, onDeleteView, onApplyView, onRenameView, onReset }) => {
  const { label, data } = option;

  const [open, setOpen] = useState(false);
  const iconRef = useRef<HTMLDivElement>(null);

  const handleClick = () => setOpen(!open);
  const handleClose = () => setOpen(false);

  return (
    <BigidMenuItem dataAid={`view-${option.id}`}>
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleClick}
        minWidth="100%"
        ref={iconRef}
      >
        {label}
        <span>
          <BigidChevronRightIcon />
        </span>
        <BigidMenu
          anchorEl={iconRef.current as Element}
          open={open}
          onMenuClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          minWidth={155}
          transformOrigin={{ horizontal: 0, vertical: 0 }}
        >
          <BigidMenuItem onClick={onApplyView}>Apply View</BigidMenuItem>
          {!data?.predefined ? (
            <>
              <BigidMenuItem onClick={onRenameView} dataAid="rename-button">
                Rename
              </BigidMenuItem>
              <BigidMenuItem onClick={onDeleteView} dataAid="delete-button">
                Delete
              </BigidMenuItem>
            </>
          ) : (
            <BigidMenuItem onClick={onReset} dataAid="reset-to-default-button">
              Restore to BigID default
            </BigidMenuItem>
          )}
        </BigidMenu>
      </Stack>
    </BigidMenuItem>
  );
};

export const RequestManagerViews = () => {
  const [renameViewId, setRenameViewId] = useState<number | undefined>();
  const [deleteViewId, setDeleteViewId] = useState<string | undefined>();
  const [newViewName, setNewViewName] = useState<string>('');
  const [openTabsMenu, setOpenTabsMenu] = useState(false);
  const iconRef = useRef<HTMLButtonElement | null>(null);
  const tabsDivRef = useRef<HTMLDivElement>(null);
  const tabsContainerDivRef = useRef<HTMLDivElement>(null);

  const { views, onSelectView, onChangeViewName, onDeleteView, currentView, handleResetToDefault } =
    RequestManagerViewsContainer.useContainer();

  const { requestsCount, getRequestsCountForCurrentView } = RequestManagerGridContainer.useContainer();

  const tabs: BigidTabsItem<UserGridPreferenceView>[] = useMemo(
    () =>
      views.map(item => ({
        id: String(item.id),
        label: `${item.label}`,
        data: item,
        counter:
          find(requestsCount, i => i.viewId === item.id) !== undefined
            ? find(requestsCount, i => i.viewId === item.id)?.count
            : undefined,
      })),
    [requestsCount, views],
  );

  const handleClick = () => setOpenTabsMenu(!openTabsMenu);
  const handleClose = () => setOpenTabsMenu(false);

  const tabsContainerWidth = tabsContainerDivRef.current ? tabsContainerDivRef.current.offsetWidth : 0;
  const tabsDivWidth = tabsDivRef.current ? tabsDivRef.current.offsetWidth : 0;
  const isScrollableTabs = tabsContainerWidth - 26 < tabsDivWidth;

  if (requestsCount.length === 0) return null;

  return (
    <Stack flexDirection="row" alignItems="center" mt={-2} ref={tabsContainerDivRef}>
      <Box maxWidth="calc(100% - 25px)" ref={tabsDivRef}>
        <BigidTabs
          selectedIndex={tabs.findIndex(tab => String(currentView?.id) === tab.id)}
          onChange={(_, tab) => {
            if (tab?.id) {
              getRequestsCountForCurrentView();
              onSelectView(Number(tab.id));
            }
          }}
          key={String(isScrollableTabs)}
          tabs={tabs}
          scrollButtonsVisible={isScrollableTabs}
          indexByTabId
          variant={isScrollableTabs ? 'scrollable' : 'standard'}
        />
      </Box>
      <Box>
        <Box position="relative" bottom={3}>
          <IconButton ref={iconRef} onClick={handleClick}>
            <BigidMoreActionIcon dataAid="more-views-button" />
          </IconButton>
        </Box>
        <BigidMenu
          anchorEl={iconRef.current as Element}
          open={openTabsMenu}
          onMenuClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          minWidth={155}
          transformOrigin={{ horizontal: 30, vertical: -30 }}
        >
          {tabs.map(item => (
            <Option
              key={item.id}
              option={item}
              onDeleteView={() => {
                setDeleteViewId(item.id);
                handleClose();
              }}
              onApplyView={() => {
                const tabId = tabs.find(tab => item.id === String(tab.id))?.id;
                if (tabId) {
                  onSelectView(Number(tabId));
                }
                handleClose();
              }}
              onRenameView={() => {
                setRenameViewId(Number(item.id));
                setNewViewName(item.label);
                handleClose();
              }}
              onReset={() => {
                if (item.id) {
                  handleResetToDefault(Number(item.id));
                  handleClose();
                }
              }}
            />
          ))}
        </BigidMenu>
      </Box>
      <BigidDialog
        isOpen={!!renameViewId}
        onClose={() => setRenameViewId(undefined)}
        title="Rename View"
        maxWidth="xs"
        buttons={[
          {
            text: 'Cancel',
            onClick: () => setRenameViewId(undefined),
            component: SecondaryButton,
          },
          {
            text: 'Save',
            onClick: () => {
              if (renameViewId) {
                setRenameViewId(undefined);

                onChangeViewName(renameViewId, newViewName);
              }
            },
            component: PrimaryButton,
            disabled: !newViewName,
          },
        ]}
      >
        <BigidTextField
          onChange={event => setNewViewName(event.target.value)}
          label="View Name"
          placeholder="Enter View´s Name"
          value={newViewName}
        />
      </BigidDialog>
      <BigidDialog
        isOpen={!!deleteViewId}
        onClose={() => setDeleteViewId(undefined)}
        title="Delete from saved views"
        maxWidth="xs"
        buttons={[
          {
            text: 'Cancel',
            onClick: () => setDeleteViewId(undefined),
            component: SecondaryButton,
          },
          {
            text: 'Delete',
            onClick: () => {
              if (deleteViewId) {
                setDeleteViewId(undefined);
                onDeleteView(Number(deleteViewId));
              }
            },
            component: PrimaryButton,
            dataAid: 'dialog-delete-button',
          },
        ]}
      >
        <BigidBody1>{`Are you sure you wand to delete “${currentView?.label}” from saved views?`}</BigidBody1>
      </BigidDialog>
    </Stack>
  );
};
