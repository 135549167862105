import { useEffect, useMemo, useState } from 'react';
import { createContainer } from 'unstated-next';
import { useNavigate, useLocation } from 'react-router-dom';

import { TutorialStateType, TutorialStepsType } from '../types/TutorialTypes';
import { loadTutorialState } from '../services/tutorialService';
import { AuthContainer } from './authContainer';

type TutorialCompletionStateType = {
  completed?: boolean;
  completionConfirmed?: boolean;
  pending: boolean;
};

export const TUTORIAL_COMPLETION_STORAGE_KEY = 'pending-tutorial-completion';

export const useTutorial = () => {
  const { auth } = AuthContainer.useContainer();
  const [tutorialChecklist, setTutorialChecklist] = useState<TutorialStateType & TutorialCompletionStateType>({
    tutorialEnabled: false,
    pending: true,
  });
  const [fetchChecklistTimestamp, setFetchChecklistTimestamp] = useState<number>();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const tutorialStepFromUrl = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    const tutorialStep = urlSearchParams.get('tutorialStep') || '';

    return !isNaN(parseInt(tutorialStep)) ? parseInt(tutorialStep) : null;
  }, [search]);

  const [activeTutorialStep, setActiveTutorialStep] = useState<TutorialStepsType | null>(tutorialStepFromUrl);

  const fetchIntroProgress = async () => {
    const tutorialState = await loadTutorialState();

    const completed = Object.values(tutorialState).reduce((acc, curr) => acc && curr, true) as boolean;

    if (!completed) {
      sessionStorage.setItem(TUTORIAL_COMPLETION_STORAGE_KEY, '1');
    }

    setTutorialChecklist({
      ...tutorialState,
      completed,
      completionConfirmed: completed && !sessionStorage.getItem(TUTORIAL_COMPLETION_STORAGE_KEY),
      pending: false,
    });
  };

  useEffect(() => {
    //Collaborator doesn't have onboarding functionality BME-2136
    if (auth.isAuthenticated) {
      fetchIntroProgress();
    }
  }, [auth, fetchChecklistTimestamp]);

  useEffect(() => {
    if (!tutorialChecklist.completionConfirmed && !tutorialChecklist.pending) {
      setActiveTutorialStep(tutorialStepFromUrl);
    }
  }, [tutorialChecklist, tutorialStepFromUrl]);

  const resetTutorialStep = () => {
    if (tutorialStepFromUrl !== null) {
      navigate(`../${pathname}`, { replace: true });
    }
  };

  return {
    fetchChecklistTimestamp,
    setFetchChecklistTimestamp,
    tutorialChecklist,
    setTutorialChecklist,
    activeTutorialStep,
    resetTutorialStep,
  };
};

export const TutorialContainer = createContainer(useTutorial);
