import * as Yup from 'yup';

export enum ValidationErrors {
  REQUIRED = 'Field must not be empty',
  EMAIL_DOMAIN = 'Please enter work email',
  EMAIL_FORMAT = 'Invalid email format',
  LINK_FORMAT = 'Invalid link format',
  PASSWORD_SHORT = 'Should contain at least 4 characters',
  PASSWORD_MISMATCH = "Password doesn't match",
  FORBIDDEN_SYMBOLS = 'Forbidden symbols',
  URL_FORMAT = 'Invalid url format',
}

export const urlSchema = Yup.string().url();
export const emailSchema = Yup.string().email();
export const bracketsLink = Yup.string().test('link', ValidationErrors.LINK_FORMAT, (link = '') =>
  /{{[a-zA-Z]+}}/.test(link),
);
export const linkValidationNew = Yup.string().test(
  'link',
  ValidationErrors.LINK_FORMAT,
  (link = '') => urlSchema.isValidSync(link) || urlSchema.isValidSync(`//${link}`) || emailSchema.isValidSync(link),
);

export const linkValidationWithBrackets = Yup.string().test(
  'link',
  ValidationErrors.LINK_FORMAT,
  (link = '') => linkValidationNew.isValidSync(link) || bracketsLink.isValidSync(link),
);

export const linkValidationRequired = linkValidationNew.required(ValidationErrors.REQUIRED);

export const emailDomainValidation = Yup.string()
  .test(
    'email',
    ValidationErrors.EMAIL_DOMAIN,
    (email = '') => !/@(gmail|yahoo|yandex|me|mac|icloud|zoho|hotmail|protonmail)\./.test(email),
  )
  .email(ValidationErrors.EMAIL_FORMAT);

export const requiredStringField = Yup.string().required(ValidationErrors.REQUIRED);
export const requiredNumberField = Yup.number().required(ValidationErrors.REQUIRED);
export const requiredArrayField = Yup.array().min(1, ValidationErrors.REQUIRED);

export const makeOptionalAllowedSymbolsValidation = (fieldName?: string) =>
  Yup.string().test({
    name: fieldName,
    message: ValidationErrors.FORBIDDEN_SYMBOLS,
    test: (value = '') =>
      /^(?!.*\\\\\\)[¡¿+\p{L}\s"'`@().:,\/\\&#0-9-_{}~*^%$;=\p{Pd}!\u003F\u200E\u200F\u0590-\u05FF\uFB1D-\uFB4F\u0900-\u097F\u0E00-\u0E7F\u0600-\u06FF\u201d\u00A0\u2019\uFF08\uFF09\uff1a\uff0c\u3001\u3002\u30FB\u30A0\uFF1D]{0,}$/gu.test(
        value,
      ),
  });

export const makeAllowedSymbolsValidation = (fieldName?: string) =>
  makeOptionalAllowedSymbolsValidation(fieldName).required(ValidationErrors.REQUIRED);

const GeneralSettingsTranslationValidationSchema = Yup.object({
  headerTitle: makeOptionalAllowedSymbolsValidation('headerTitle'),
  pageTitle: makeOptionalAllowedSymbolsValidation('pageTitle'),
  pageSubtitle: makeOptionalAllowedSymbolsValidation('pageSubtitle'),
  copyright: Yup.string().when('copyrightEnabled', {
    is: true,
    then: requiredStringField,
  }),
  copyrightEnabled: Yup.boolean(),
});

export const makePortalEditorValidationSchema = (availableLanguages: { value: string; label: string }[]) =>
  Yup.object({
    generalSettings: Yup.object({
      companyName: Yup.string().required(ValidationErrors.REQUIRED),
      supportLink: linkValidationNew.required(ValidationErrors.REQUIRED),
      termsConditionsLink: Yup.string().when('termsConditionsLinkEnabled', {
        is: true,
        then: linkValidationNew.required(ValidationErrors.REQUIRED),
      }),
      privacyPolicyLink: Yup.string().when('privacyPolicyLinkEnabled', {
        is: true,
        then: linkValidationNew.required(ValidationErrors.REQUIRED),
      }),
      translations: Yup.object(
        availableLanguages.reduce(
          (acc: Record<string, typeof GeneralSettingsTranslationValidationSchema>, { value }: { value: string }) => ({
            ...acc,
            [value]: GeneralSettingsTranslationValidationSchema,
          }),
          {},
        ),
      ),
    }),
  });

const WorkflowTranslationValidationSchema = (rules = {}) =>
  Yup.object({
    subject: requiredStringField,
    header: requiredStringField,
    title: requiredStringField,
    message: requiredStringField,
    ...rules,
  });

const WorkflowTranslationDueDateNotificationValidationSchema = Yup.object({
  subject: Yup.string().required(ValidationErrors.REQUIRED),
  header: Yup.string().required(ValidationErrors.REQUIRED),
  buttonText: Yup.string().required(ValidationErrors.REQUIRED),
});

const WorkflowTranslationPartialValidationSchema = Yup.object({
  subject: Yup.string().required(ValidationErrors.REQUIRED),
});

const OnscreenMessageValidationSchema = Yup.object({
  title: Yup.string().required(ValidationErrors.REQUIRED),
});

const AutoCloseTranslationValidationSchema = Yup.object({
  auditLogMessage: Yup.string().required(ValidationErrors.REQUIRED),
});

const validateTranslationsThroughLanguages = (
  ValidationSchema:
    | typeof OnscreenMessageValidationSchema
    | typeof WorkflowTranslationValidationSchema
    | typeof AutoCloseTranslationValidationSchema
    | typeof WorkflowTranslationPartialValidationSchema
    | typeof WorkflowTranslationDueDateNotificationValidationSchema,
  availableLanguages: { value: string }[],
) =>
  Yup.object(
    availableLanguages.reduce(
      (acc, { value }: { value: string }) => ({
        ...acc,
        [value]: ValidationSchema,
      }),
      {},
    ),
  );

export const makeRequestWorkflowValidationSchema = (availableLanguages: { value: string }[]) =>
  Yup.object({
    emailTemplate: Yup.object({
      translations: validateTranslationsThroughLanguages(
        WorkflowTranslationPartialValidationSchema,
        availableLanguages,
      ),
    }),
  });

export const makeOutsideRequestorsOnScreenMessageValidationSchema = (availableLanguages: { value: string }[]) =>
  Yup.object({
    onScreenMessage: Yup.object({
      translations: validateTranslationsThroughLanguages(OnscreenMessageValidationSchema, availableLanguages),
    }),
  });

export const makeOutsideRequestorsEmailTemplateValidationSchema = (availableLanguages: { value: string }[]) =>
  Yup.object({
    emailTemplate: Yup.object({
      translations: validateTranslationsThroughLanguages(
        WorkflowTranslationPartialValidationSchema,
        availableLanguages,
      ),
    }),
  });

export const makeDueDateNotificationValidationSchema = (availableLanguages: { value: string }[]) =>
  Yup.object({
    translations: validateTranslationsThroughLanguages(
      WorkflowTranslationDueDateNotificationValidationSchema,
      availableLanguages,
    ),
  });

export const makeStageWorkflowValidationSchema = (
  availableLanguages: { value: string }[],
  isVendorDeletion?: boolean,
) =>
  Yup.object({
    general: Yup.object({
      ...(isVendorDeletion
        ? {
            configuration: Yup.object().shape({
              vendorEngagements: Yup.array().min(1, 'Please select vendor engagement').required(),
            }),
          }
        : {}),
      emailTemplate: Yup.object({
        appealEnabled: Yup.boolean(),
        translations: Yup.object().when('appealEnabled', appealEnabled =>
          validateTranslationsThroughLanguages(
            WorkflowTranslationValidationSchema({
              appealText: appealEnabled && requiredStringField,
              buttonText: isVendorDeletion && requiredStringField,
            }),
            availableLanguages,
          ),
        ),
      }),
    }),
    manual: Yup.object({
      configuration: Yup.object({
        name: Yup.string().required(ValidationErrors.REQUIRED),
      }),
      emailTemplate: Yup.object().when('configuration.emailEnabled', {
        is: true,
        then: Yup.object({
          translations: Yup.object().when('appealEnabled', appealEnabled =>
            validateTranslationsThroughLanguages(
              WorkflowTranslationValidationSchema({
                appealText: appealEnabled && requiredStringField,
                buttonText: isVendorDeletion && requiredStringField,
              }),
              availableLanguages,
            ),
          ),
        }),
      }),
    }),
    auto: Yup.object({
      configuration: Yup.object({
        name: Yup.string().required(ValidationErrors.REQUIRED),
        text: Yup.string().required(ValidationErrors.REQUIRED),
        translations: Yup.object().when('mandatory', {
          is: false,
          then: validateTranslationsThroughLanguages(AutoCloseTranslationValidationSchema, availableLanguages),
        }),
      }),
      emailTemplate: Yup.object().when('configuration.emailEnabled', {
        is: true,
        then: Yup.object({
          translations: Yup.object().when('appealEnabled', appealEnabled =>
            validateTranslationsThroughLanguages(
              WorkflowTranslationValidationSchema({
                appealText: appealEnabled && requiredStringField,
                buttonText: isVendorDeletion && requiredStringField,
              }),
              availableLanguages,
            ),
          ),
        }),
      }),
    }),
  });

export const apiTriggerValidationSchema = () =>
  Yup.object({
    request: Yup.object({
      url: Yup.string().required('URL is required'),
      type: Yup.string().required('Request type is required'),
      headers: Yup.array().of(
        Yup.object({
          name: Yup.string().required('Key is required'),
          value: Yup.string().required('Value is required'),
        }),
      ),
      body: Yup.string(),
    }),
  });

export const webhooksValidationSchema = () =>
  Yup.object({
    url: Yup.string().required('URL is required'),
    headers: Yup.array().of(
      Yup.object({
        name: Yup.string().required('Key is required'),
      }),
    ),
    parameters: Yup.array().of(
      Yup.object({
        name: Yup.string().required('Key is required'),
      }),
    ),
    body: Yup.string(),
  });

export const makeNewManualResolutionValidationSchema = (availableLanguages: { value: string }[]) =>
  Yup.object({
    manual: Yup.object({
      configuration: Yup.object({
        name: Yup.string().required(ValidationErrors.REQUIRED),
      }),
      emailTemplate: Yup.object({
        translations: validateTranslationsThroughLanguages(WorkflowTranslationValidationSchema, availableLanguages),
      }),
    }),
  });

export const makeNewAutoResolutionValidationSchema = (availableLanguages: { value: string }[]) =>
  Yup.object({
    auto: Yup.object({
      configuration: Yup.object({
        name: Yup.string().required(ValidationErrors.REQUIRED),
        text: Yup.string().required(ValidationErrors.REQUIRED),
        translations: validateTranslationsThroughLanguages(AutoCloseTranslationValidationSchema, availableLanguages),
      }),
      emailTemplate: Yup.object({
        translations: validateTranslationsThroughLanguages(WorkflowTranslationValidationSchema, availableLanguages),
      }),
    }),
  });

export const roleValidationSchema = Yup.object({
  name: makeAllowedSymbolsValidation('name'),
  permissionGroupId: requiredNumberField,
  scopeId: requiredNumberField,
});

export const userValidationSchema = Yup.object({
  firstName: makeAllowedSymbolsValidation('firstName'),
  lastName: makeAllowedSymbolsValidation('lastName'),
  email: emailDomainValidation.required(ValidationErrors.REQUIRED),
  roles: requiredArrayField,
});

export const loginValidationSchema = Yup.object({
  userName: requiredStringField,
  password: requiredStringField,
});

const passwordMatches = Yup.string()
  .required('No password provided.')
  .min(8, 'Password is too short - should be 8 characters minimum.')
  .matches(/(?=.*[a-z])/, 'Password requires at least one lowercase letter')
  .matches(/(?=.*\d)/, 'Password requires at least one digit')
  .matches(/(?=.*[A-Z])/, 'Password requires at least one uppercase')
  .matches(/(?=.*[\W_])/, 'Password requires at least one symbol');

export const changePasswordValidationSchema = Yup.object({
  currentPassword: requiredStringField,
  password: passwordMatches,
  confirmPassword: passwordMatches,
});

export const createAccountStep1ValidationSchema = Yup.object({
  email: emailDomainValidation.required(ValidationErrors.REQUIRED),
});

export const createAccountStep2ValidationSchema = Yup.object({
  companyName: makeAllowedSymbolsValidation('companyName'),
  firstName: makeAllowedSymbolsValidation('firstName'),
  lastName: makeAllowedSymbolsValidation('lastName'),
  captcha: requiredStringField,
});

export const createAccountNoCaptchaStep2ValidationSchema = Yup.object({
  companyName: makeAllowedSymbolsValidation('companyName'),
  firstName: makeAllowedSymbolsValidation('firstName'),
  lastName: makeAllowedSymbolsValidation('lastName'),
});

export const multirowEditPopupValidationSchema = Yup.object({
  name: requiredStringField,
  purposes: Yup.array().of(makeAllowedSymbolsValidation('purpose')).required(ValidationErrors.REQUIRED),
  categories: Yup.array().of(makeAllowedSymbolsValidation('categories')).required(ValidationErrors.REQUIRED),
});
