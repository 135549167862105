import { UserRequestStatus } from '../../constants/userRequestStatus';
import { LanguageType } from '../../state/languageContainer';
import { TranslationsType } from '../../utils';

export interface FlowsResponse {
  requestorTypes: RequestorTypesGetType;
  flows: RequestDefinition[];
  brandAuthorizedAgent: AuthorizedAgentType;
}

export type RequestorTypesTranslationsType = Record<string, LocalizableRequestorTypesType>;

export type LocalizableRequestorTypesType = {
  locale: string;
  title: string;
  description: string;
};

export type RequestorTypesGetType = {
  enabled: boolean;
  requestorTypes: RequestorTypeGetType[];
};

export type RequestorTypeGetType = {
  id: number;
  requestorType: RequestorTypeType;
  translations: RequestorTypesTranslationsType;
};

export interface RequestDefinition {
  id: string | RequestStatusTypes;
  fields: FieldDefinition[];
  ordinal: number;
  translations: RequestTranslationsType;
  texts: TextConfiguration[];
  layout: RequestDefinitionLayoutType;
  authorizedAgentEnabled: boolean;
  submitFormId: number;
}

export enum RequestDefinitionLayoutType {
  Default = 'default',
  Compact = 'compact',
}

export enum PasscodeType {
  NONE = 'NONE',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}
export interface RequestStatus {
  status: UserRequestStatus;
  history: RequestStatusHistory[];
  requestId: string;
  requestType: RequestStatusTypes;
  requestName: string;
  userOs: string;
  userLocation: string;
  expirationDate: string;
  expirationDays: number;
  userLocale: LanguageType;
  passcodeType: PasscodeType;
  waitingPreferencesSubmit: boolean;
  hasFilesToDownload: boolean;
}

export enum ManagePreferencesSectionTypes {
  MULTI_SELECT = 'MULTI_SELECT',
  TOGGLE = 'TOGGLE',
  RADIO = 'RADIO',
  DROPDOWN = 'DROPDOWN',
  OPT_LIST = 'OPT_LIST',
}

export interface ManagePreferencesGetType {
  steps: ManagePreferencesStepType[];
}

export type ManagePreferencesDataItemType = {
  id: number;
  name: string;
  selected: boolean;
  description?: string;
};

export enum ManagePreferencesStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
}

export enum SectionDefaultType {
  NONE = 'NONE',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  CURRENT = 'CURRENT',
  PRESELECTED = 'PRESELECTED',
}

export interface ManagePreferencesType {
  steps: ManagePreferencesStepType[];
  status?: ManagePreferencesStatus;
  selectedReason?: string;
}

export interface ManagePreferencesStepType {
  id: number;
  name: string;
  sections: ManagePreferencesSectionsType[];
}

export interface ManagePreferencesSectionsType {
  id: string;
  enabled: boolean;
  title: string;
  subtitle: string;
  hint: string;
  positiveEnabled?: boolean;
  negativeEnabled?: boolean;
  sectionOrder: number;
  selectAllEnabled?: boolean;
  positive?: string;
  negative?: string;
  selectAllLabel?: string;
  sectionDefault?: SectionDefaultType | number;
  predefined?: boolean;
  type: ManagePreferencesSectionTypes;
  items: ManagePreferencesItemType[];
  translations?: TranslationsType;
}

export type ManagePreferencesItemType = {
  id: string;
  label: string;
  selected?: boolean | null;
  description?: string;
};

export interface ManagePreferencesDataPostType {
  preferences: ManagePreferencesStepType[];
  selectedReason: string;
}

export interface SubmitRequestReturnType {
  fields: FieldDefinition[];
  steps: ManagePreferencesStepType[];
}

export interface SelectOptionsType {
  label: string;
  value: string;
}

export interface SelectOptionsCountriesType extends SelectOptionsType {
  divisions: SelectOptionsType[];
}

export interface DivisionsType {
  code: string;
  name: string;
  type: string;
  displayName: string;
}

export interface CountriesType {
  code: string;
  name: string;
  displayName: string;
  divisions: DivisionsType[];
}

export interface RequestCountriesType {
  countries: CountriesType[];
}

export interface CountriesOptionsType {
  value: string;
  label: string;
  divisions: { value: string; label: string }[];
}

export interface RequestStatusHistory {
  status: UserRequestStatus;
  passed: boolean;
  current: boolean;
  future: boolean;
  changeDate?: string;
}

export enum FieldsType {
  USER_EMAIL = 'userEmail',
  USER_NAME = 'userFirstName',
  USER_LASTNAME = 'userLastName',
  USER_PHONE = 'userPhone',
  USER_COUNTRY = 'userCountry',
  USER_COUNTRY_DIVISION = 'userCountryDivision',
  USER_TYPE = 'userType',
  USER_PHOTO = 'userPhoto',
  USER_LINKEDIN_ACCOUNT = 'userLinkedIn',
  DELIVERY_METHOD = 'deliveryMethod',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  CITY = 'city',
  ZIPCODE = 'zipCode',
  CORRECTION_FIELD = 'userComment',
  DEFAULT = 'default',
  DATE = 'date',
  SINGLE_SELECTION = 'singleSelection',
  ORIGINAL_REQUEST_ID = 'originalRequestId',
}

export enum DeliveryMethodType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum FieldControlType {
  READONLY = 'readonly',
  EDITABLE = 'editable',
  HIDDEN = 'hidden',
}

export enum FormFieldType {
  DATE = 'DATE',
  TEXT = 'TEXT',
  PHONE = 'PHONE',
  COUNTRY = 'COUNTRY',
  COUNTRY_DIVISION = 'COUNTRY_DIVISION',
  DELIVERY_METHOD = 'DELIVERY_METHOD',
  MULTI_LINE = 'MULTI_LINE',
  USER_PHOTO = 'USER_PHOTO',
  USER_TYPE = 'USER_TYPE',
  SINGLE_SELECTION = 'SINGLE_SELECTION',
}

export type FieldTranslationsType = Record<string, LocalizableFieldType>;
export type RequestTranslationsType = Record<string, LocalizableRequestType>;

export type LocalizableFieldType = {
  locale?: string;
  label?: string;
  defaultValue?: string;
  hint?: string;
  tooltip?: string;
  options?: string[];
  title?: string;
  description?: string;
};

export type LocalizableRequestType = {
  locale: string;
  title?: string;
  description?: string;
};

export interface FieldDefinition {
  id: FieldsType;
  translations: FieldTranslationsType;
  masked: boolean;
  fieldType: FormFieldType;
  type: FieldTypeDefinition;
  enabled: boolean;
  enabledForPhysicalMail: boolean;
  fieldControl?: FieldControlType;
  dateFormat?: 'MM_DD_YYYY' | 'DD_MM_YYYY';
}

export interface Translation {
  locale: string;
  label: string;
  defaultValue: string;
  hint: string;
  tooltip: string;
}

export interface FieldTypeDefinition {
  name: string;
  constraints: { [type: string]: Record<string, never> | { value: number | boolean | string } };
  valueSource?: string;
  regExpType?: FieldRegExpType;
}

export interface UserPhoneSubmitPostType {
  id: FieldsType.USER_PHONE;
  validationId: string;
  value: string;
}

export interface FieldSubmitPost {
  id: FieldsType;
  value: FieldValue;
}

export type FieldsPost = FieldSubmitPost | UserPhoneSubmitPostType;

export enum RequestStatusTypes {
  VIEW = 'View',
  UPDATE = 'Update',
  DELETE = 'Delete',
  PREFERENCES = 'Preferences',
  OPT_OUT = 'OptOut',
  APPEAL = 'Appeal',
}

export enum RequestorTypeType {
  SELF = 'SELF',
  AUTHORIZED_AGENT = 'AUTHORIZED_AGENT',
}

export type progressStepStateTypes = 'success' | 'failed' | 'nodata';

export interface ProgressStepType {
  label: string;
  subLabel?: string;
  state?: progressStepStateTypes;
}

export interface ErrorResponse {
  traceId: string;
  errorCode: number;
  message: string;
}

export interface UploadPhotoType {
  uploadId: string;
}

export type FieldValue = UserPhoneType | UserFilesType | string;

export type FieldRegExpType = 'email' | 'phone' | 'cpf' | 'ssn';

export enum ValidationPhotoType {
  NATIONAL_STATE_ID = 'National/StateID',
  DRIVING_LICENSE = 'Driving License',
  PASSPORT = 'Passport',
}

export interface UserFilesType {
  uploadId: string;
  type?: ValidationPhotoType;
  id?: FieldsType.USER_PHOTO | string;
}

export interface SubmitRequestResponse {
  id: string;
  rc: string;
  emailConfirmationRequired: boolean;
  emailConfirmationRecipient?: string;
  onScreenMessage: OnScreenMessageDto;
  requestKey: string;
}

export interface RequestData extends SubmitRequestResponse {
  deliveryMethod: FieldValue;
}

export type OnScreenMessageDto = {
  translations: { [key: string]: OnScreenMessageTranslationDto };
};

export type OnScreenMessageTranslationDto = {
  title?: string;
  message?: string;
};

export type UserPhoneType = {
  validationId?: string;
  countryCode?: string;
  phone: string;
};

export enum SettingsTexts {
  HEADER_TITLE = 'headerTitle',
  PAGE_SUBTITLE = 'pageSubtitle',
  PAGE_TITLE = 'pageTitle',
  COPYRIGHT = 'copyright',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  SELECT_ALL = 'selectAllLabel',
  DESCRIPTION = 'description',
  SUPPORT_TITLE = 'supportTitle',
  PRIVACY_POLICY_TITLE = 'privacyPolicyTitle',
  TERMS_CONDITIONS_TITLE = 'termsConditionsTitle',
}

export enum GlobalTexts {
  DATA_STORING_TOP_DISCLAIMER = 'topPrivacyDisclaimer',
  DATA_STORING_BOTTOM_DISCLAIMER = 'bottomPrivacyDisclaimer',
}

export type TextConfiguration = {
  id: number;
  label: string;
  name: string;
  enabled: boolean;
  disclaimerAdditionalData?: {
    useIcon: boolean;
    useBlueBackground: boolean;
  };
  translations: Record<string, { value: string }>;
};

export enum RequestFormStep {
  SetDefinition,
  SetDetails,
}

export enum RequestAuthorizedAgentFormStep {
  SetRequestorType,
  SetDefinition,
  SetDetails,
}

export enum DeliveryMethodTypes {
  email = 'EMAIL',
  physicalMail = 'PHYSICAL_MAIL',
}

export enum DateFormats {
  DD_MM_YYYY = 'dd/MM/yyyy',
  MM_DD_YYYY = 'MM/dd/yyyy',
}

export type MessageDto = {
  id: number;
  toConsumer: boolean;
  sentDate: string;
  sentBy: string;
  secret: string;
  seen: boolean;
  title: string;
  subject: string;
  content: string;
  userRequestId: string;
  files: MessageFileDto[];
  removed: boolean;
};

export type MessageFileDto = {
  id: number;
  fileName: string;
  contentType: string;
  removed: boolean;
};

export type AuthorizedAgentTranslationsType = Record<string, LocalizableFieldType>;

export enum AuthorizedAgentFormFieldType {
  TEXT = 'TEXT',
  PHONE = 'PHONE',
  COUNTRY = 'COUNTRY',
  USER_TYPE = 'USER_TYPE',
  COUNTRY_DIVISION = 'COUNTRY_DIVISION',
  SINGLE_SELECTION = 'SINGLE_SELECTION',
  AUTHORIZED_AGENT_TYPE = 'AUTHORIZED_AGENT_TYPE',
}

export enum AuthorizedAgentFieldNameType {
  AUTHORIZED_AGENT_TYPE = 'authorizedAgentType',
  AGENT_FIRST_NAME_INDIVIDUAL = 'agentFirstNameIndividual',
  AGENT_FIRST_NAME_BUSINESS = 'agentFirstNameBusiness',
  AGENT_LAST_NAME_INDIVIDUAL = 'agentLastNameIndividual',
  AGENT_LAST_NAME_BUSINESS = 'agentLastNameBusiness',
  EMAIL = 'email',
  MOBILE_PHONE_NUMBER = 'mobilePhoneNumber',
  PHONE_NUMBER = 'phoneNumber',
  COUNTRY = 'country',
  STATE = 'state',
  BUSINESS_NAME = 'businessName',
  NATIONAL_Id = 'nationalId',
  UPLOAD_DOCUMENTS = 'uploadDocuments',
  BUSINESS_ENTITY_NUMBER = 'businessEntityNumber',
  DEFAULT = 'default',
}

export enum AuthorizedAgents {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
}

export type AuthorizedAgentFieldType = {
  id: number;
  canChangeRequired: boolean;
  canToggle: boolean;
  enabled: boolean;
  name: AuthorizedAgentFieldNameType;
  options?: {
    label: string;
    name: string;
    translations: FieldTranslationsType;
  }[];
  ordinal: number;
  predefined: boolean;
  translations: FieldTranslationsType;
  type: AuthorizedAgentFormFieldType;
  authorizedAgentType?: AuthorizedAgents;
  required: boolean;
  showDescription: boolean;
  showTooltip: boolean;
  valueRegexType?: FieldRegExpType;
};

export type AuthorizedAgentTextConfiguration = {
  id: number;
  label: string;
  name: string;
  enabled: boolean;
  useIcon: boolean;
  useBlueBackground: boolean;
  translations: Record<string, { value: string }>;
};

export type AuthorizedAgentType = {
  fields: AuthorizedAgentFieldType[];
  translations: AuthorizedAgentTranslationsType;
  texts: AuthorizedAgentTextConfiguration[];
  layout: RequestDefinitionLayoutType;
};

export interface AuthorizedAgentPhoneSubmitPostType {
  id: AuthorizedAgentFieldNameType;
  validationId: string;
  value: string;
}

export interface AutorizedAgentFieldSubmitPost {
  id: AuthorizedAgentFieldNameType;
  value: FieldValue;
}

export type AuthorizedAgentFieldsPost = AutorizedAgentFieldSubmitPost | AuthorizedAgentPhoneSubmitPostType;
