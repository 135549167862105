import { useCallback, useEffect, useState } from 'react';
import { FileType, NoteType, NoteUpdateType } from '../../../types';
import { useFilesUpload, useToggle } from '../../../hooks';
import { downloadRequestNoteFile, uploadFileNote } from '../../../services/request';
import { NOTE_DEFAULT_MAX_SYMBOLS } from './useNotesDialog';
import { useFormik } from 'formik';
import { noop } from 'lodash';
import * as Yup from 'yup';
import { makeOptionalAllowedSymbolsValidation } from '../../../utils';

const useNoteItem = (note: NoteType) => {
  const [fileToRemove, setFileToRemove] = useState<FileType>();
  const { value: isOpenMenu, setTrue: openMenu, setFalse: closeMenu } = useToggle(false);

  const { files, onFileUpload, removeFile, setFiles, uploading } = useFilesUpload({
    upload: uploadFileNote,
  });

  const formikEditNote = useFormik<NoteUpdateType>({
    initialValues: {
      id: '',
      content: '',
      files: [],
    },
    onSubmit: noop,
    validationSchema: () =>
      Yup.object({
        content: makeOptionalAllowedSymbolsValidation(),
      }),
  });

  const handleEditNote = () => {
    setFiles(note.files.map(({ id, ...file }) => ({ id: id.toString(), ...file })));
    formikEditNote.setValues({ content: note.content, files, id: note.id });
  };

  const onRemoveFileContinue = () => {
    fileToRemove && removeFile(fileToRemove.id);
    setFileToRemove(undefined);
  };

  const onFileRemove = (file: FileType) => {
    setFileToRemove(file);
  };

  useEffect(() => {
    formikEditNote.setFieldValue(
      'files',
      files.map(({ isNew, ...file }) => (isNew ? { fileUploadId: file.id } : file)),
    );
  }, [files, formikEditNote.setFieldValue]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleFileUpload = useCallback(
    async (_: any, file: File) => {
      await onFileUpload([file]);
    },
    [onFileUpload],
  );

  const onFileClick = useCallback(async (fileId: number | string, fileName: string) => {
    await downloadRequestNoteFile(fileId, fileName);
  }, []);

  const onChangeEditNote = (value: string) => {
    if (value.length > NOTE_DEFAULT_MAX_SYMBOLS) return;
    formikEditNote.setFieldValue('content', value);
  };

  return {
    formikEditNote,
    fileToRemove,
    isOpenMenu,
    files,
    handleEditNote,
    onRemoveFileContinue,
    onFileRemove,
    handleFileUpload,
    onChangeEditNote,
    onFileClick,
    openMenu,
    closeMenu,
    setFileToRemove,
    uploading,
  };
};

export default useNoteItem;
