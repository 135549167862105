import React, { FC, useCallback, useEffect } from 'react';
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { appealTourSelectors, appealTourSteps } from './steps';
import { BigidGuidedTour } from '@bigid-ui/guided-tour';
import { useLocation, useNavigate } from 'react-router-dom';
import { NewFeatureContainer } from '../../state/newFeatureContainer';
import { updateUserViewedFeatures } from '../../services/user/userService';
import { AppealGuidedTourContainer } from '../../state/appealGuidedTourContainer';
import { ReleasedFeatureCodeType } from '../../types/ReleasedFeaturesTypes';

export const AppealGuidedTour: FC = () => {
  const { isAppealEnabled, setNewFeaturesList, setIsAppealEnabled } = NewFeatureContainer.useContainer();

  const {
    isAppealTourRunning: isTourRunning,
    setIsAppealTourRunning: setIsTourRunning,
    appealTourStepIndex: stepIndex,
    setAppealTourStepIndex: setStepIndex,
    setAppealTourStartedFrom,
    appealTourStartedFrom,
  } = AppealGuidedTourContainer.useContainer();

  const location = useLocation();
  const navigate = useNavigate();

  const editorPage = location.pathname.includes('editor');
  const brandsPage = location.pathname.includes('brands');

  useEffect(() => {
    const startTourIfRequirementsSatisfied = async (): Promise<void> => {
      if (isAppealEnabled && (brandsPage || editorPage)) {
        setIsTourRunning(true);

        if (editorPage && !appealTourStartedFrom) {
          setAppealTourStartedFrom('editor');
        }
        if (brandsPage && !appealTourStartedFrom) {
          setAppealTourStartedFrom('brands');
        }
      }
    };

    startTourIfRequirementsSatisfied();
  }, [brandsPage, editorPage, setIsTourRunning, isAppealEnabled, setAppealTourStartedFrom, appealTourStartedFrom]);

  const editBrandButton = document.querySelector(appealTourSelectors.BRANDS_EDIT_BUTTON) as HTMLElement;
  const appealToggle = document.querySelector(appealTourSelectors.USE_CASES_ACCORDION_APPEAL_TOGGLE) as HTMLElement;
  const useCaseAppealAccordion = document.querySelector(
    appealTourSelectors.USE_CASES_ACCORDION_APPEAL_HEADER,
  ) as HTMLElement;
  const formsTab = document.querySelector(appealTourSelectors.FORM_TAB) as HTMLElement;
  const workflowTable = document.querySelector(appealTourSelectors.WORKFLOW_GRID) as HTMLElement;
  const saveAndPublish = document.querySelector(appealTourSelectors.SAVE_AND_PUBLISH) as HTMLElement;
  const workflowSettingsAllowAppeal = document.querySelector(appealTourSelectors.ALLOW_APPEAL) as HTMLElement;
  const closedRequests = document.querySelectorAll(
    `${appealTourSelectors.CLOSE_REQUESTS_GRID} tbody tr td:not(:last-child)`,
  );

  const onClickEditBrandButton = useCallback(() => {
    setIsTourRunning(false);
    setTimeout(() => {
      setStepIndex(2);
      setIsTourRunning(true);
      // waiting when the open Edit page
    }, 3000);
  }, [setIsTourRunning, setStepIndex]);

  const onClickAppealToggle = useCallback(() => {
    // waiting when open accordion
    setTimeout(() => {
      setStepIndex(3);
    }, 50);
  }, [setStepIndex]);

  const onClickFormsTab = useCallback(() => {
    // waiting when open form accordion
    setTimeout(() => {
      setStepIndex(5);
    }, 1500);
  }, [setStepIndex]);

  const onClickSaveAndPublishTab = useCallback(() => {
    setTimeout(() => {
      navigate('/settings/workflow-customization');
      setIsTourRunning(false);
      // waiting publish request
    }, 500);

    setTimeout(() => {
      setStepIndex(7);
      setIsTourRunning(true);
      // waiting when the open WorkflowCustomization page
    }, 1500);
  }, [navigate, setIsTourRunning, setStepIndex]);

  const onClickWorkflowGrid = useCallback(() => {
    setIsTourRunning(false);
    setTimeout(() => {
      setStepIndex(8);
      setIsTourRunning(true);
      // waiting when the open page
    }, 1000);
  }, [setIsTourRunning, setStepIndex]);

  const onClickCloseRequests = useCallback(() => {
    setIsTourRunning(false);
    setTimeout(() => {
      setStepIndex(9);
      setIsTourRunning(true);
      // waiting when open modal
    }, 1000);
  }, [setIsTourRunning, setStepIndex]);

  useEffect(() => {
    if (isTourRunning && stepIndex <= 2) {
      appealToggle?.addEventListener('click', onClickAppealToggle);
    }

    if (isTourRunning) {
      editBrandButton?.addEventListener('click', onClickEditBrandButton);
      formsTab?.addEventListener('click', onClickFormsTab);
      workflowTable?.addEventListener('click', onClickWorkflowGrid);
      saveAndPublish?.addEventListener('click', onClickSaveAndPublishTab);

      closedRequests.forEach(i => i.addEventListener('click', onClickCloseRequests));
    }

    return () => {
      editBrandButton && editBrandButton?.removeEventListener('click', onClickEditBrandButton);
      formsTab && formsTab?.removeEventListener('click', onClickFormsTab);
      workflowTable && workflowTable?.removeEventListener('click', onClickWorkflowGrid);
      saveAndPublish && saveAndPublish?.removeEventListener('click', onClickSaveAndPublishTab);

      closedRequests?.forEach(element => element.removeEventListener('click', onClickCloseRequests));
    };
  }, [
    stepIndex,
    isTourRunning,
    setIsTourRunning,
    onClickEditBrandButton,
    onClickAppealToggle,
    editBrandButton,
    formsTab,
    appealToggle,
    onClickFormsTab,
    workflowTable,
    onClickWorkflowGrid,
    onClickCloseRequests,
    saveAndPublish,
    onClickSaveAndPublishTab,
    closedRequests,
  ]);

  useEffect(() => {
    if (isTourRunning && useCaseAppealAccordion && stepIndex === 2) {
      useCaseAppealAccordion?.scrollIntoView({ behavior: 'auto' });
    }
  }, [useCaseAppealAccordion, isTourRunning, stepIndex]);

  useEffect(() => {
    if (isTourRunning && workflowSettingsAllowAppeal && stepIndex === 9) {
      workflowSettingsAllowAppeal?.scrollIntoView({ behavior: 'auto' });
    }
  }, [workflowSettingsAllowAppeal, isTourRunning, stepIndex]);

  const getNextStepIndex = useCallback(
    ({ action, index }: CallBackProps) => {
      const dispatchClickEventOnElementByQuerySelector = (selector: string): void => {
        const targetElement = document.querySelector(selector) as HTMLElement;
        const clickEvent = new MouseEvent('click', { bubbles: true });
        targetElement?.dispatchEvent(clickEvent);
      };
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (action === ACTIONS.PREV) {
        if (index === 3) {
          dispatchClickEventOnElementByQuerySelector(appealTourSelectors.USE_CASES_ACCORDION_APPEAL_TOGGLE);
          return nextStepIndex;
        } else if (index === 5) {
          dispatchClickEventOnElementByQuerySelector(appealTourSelectors.GENERAL_TAB);
          return nextStepIndex;
        } else if (index === 7) {
          navigate('/editor/1');
          return 2;
        } else if (index === 8) {
          navigate('/settings/workflow-customization');
          return nextStepIndex;
        } else if (index === 9) {
          navigate('/brands');
          return 0;
        }
      }
      if (action === ACTIONS.NEXT) {
        if (index === 2) {
          appealToggle && appealToggle?.removeEventListener('click', onClickAppealToggle);
        }
      }
      return nextStepIndex;
    },
    [appealToggle, navigate, onClickAppealToggle],
  );

  const joyrideCallback = useCallback(
    (data?: CallBackProps) => {
      if (data) {
        const { status, type } = data;
        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
          setIsTourRunning(false);
          setNewFeaturesList([
            {
              code: ReleasedFeatureCodeType.APPEAL,
              viewed: true,
            },
          ]);
          setIsAppealEnabled(false);
          updateUserViewedFeatures(ReleasedFeatureCodeType.APPEAL);
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
          setStepIndex(getNextStepIndex(data));
        }
      }
    },
    [getNextStepIndex, setIsTourRunning, setNewFeaturesList, setStepIndex, setIsAppealEnabled],
  );

  return (
    <BigidGuidedTour
      run={isTourRunning}
      joyrideCallback={joyrideCallback}
      floaterProps={{
        hideArrow: true,
      }}
      styles={{
        options: {
          zIndex: 100000,
        },
      }}
      spotlightPadding={0}
      steps={appealTourSteps()}
      stepIndex={stepIndex}
      disableCloseOnEsc
      disableOverlayClose
      disableScrollParentFix
      disableScrolling
      delay={1000}
    />
  );
};
