import React, { FC } from 'react';
import { AuthorizedAgentGuidedTourStepTemplate } from './AuthorizedAgentGuidedTourStepTemplate';

interface RequestorTypeAccordionStepProps {
  dataAid?: string;
}

export const RequestorTypeAccordionStep: FC<RequestorTypeAccordionStepProps> = ({ dataAid }) => {
  return (
    <AuthorizedAgentGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Customize tile"
      firstSectionText="You can change the Authorized Agent card or keep it as the default"
    />
  );
};
