import { useCallback, useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';

import { getUserToAssign, setRequestOwner } from '../../services/request';
import { useShowSnackbar } from '../../hooks/useShowSnackbar';
import { GridAssignUsersType } from '../../types';

const useRequestOwners = () => {
  const { showSnackbarMessage, closeSnackbar } = useShowSnackbar();
  const [usersToAssign, setUsersToAssign] = useState<GridAssignUsersType>({ users: [] });

  const fetchUsersToAssign = useCallback(async () => {
    const users = await getUserToAssign();
    setUsersToAssign(users);
  }, []);

  const updateRequestOwner = useCallback(
    ({
      requestId,
      ownerId,
      owner,
      onSuccess,
    }: {
      requestId: string;
      ownerId: string;
      owner: string;
      onSuccess: () => void;
    }) => {
      setRequestOwner([{ requestId, ownerId, owner }]).then(newRowData => {
        onSuccess();
        entityEventsEmitter.emit(EntityEvents.UPDATE, newRowData);
        showSnackbarMessage(`1 Request assigned to ${newRowData[0].owner}`);
      });
    },
    [showSnackbarMessage],
  );

  useEffect(
    () => () => {
      closeSnackbar();
    },
    [closeSnackbar],
  );

  return { usersToAssign, fetchUsersToAssign, updateRequestOwner };
};

export const RequestOwnersContainer = createContainer(useRequestOwners);
