import React from 'react';
import { FormikProps } from 'formik';

import { MeTextFieldAutocomplete, MeTextFieldAutocompleteProps } from '../MeTextFieldAutocomplete';

export type MeTextFieldWithFormProps<T = Record<string, string>> = MeTextFieldAutocompleteProps & {
  formData: FormikProps<T>;
  name: keyof T;
  onBlur?: () => void;
  validateOnChange?: boolean;
  testId?: string;
  isMultiline?: boolean;
};

export const MeTextFieldAutocompleteWithForm = <T,>({
  name,
  formData,
  onBlur,
  validateOnChange,
  ...textFieldProps
}: MeTextFieldWithFormProps<T>) => {
  const { value, error, touched } = formData.getFieldMeta(name as string);

  return (
    <MeTextFieldAutocomplete
      {...textFieldProps}
      name={name as string}
      value={value as string}
      onChange={value => {
        formData.setFieldValue(name as string, value);

        if (validateOnChange) {
          formData.setFieldTouched(name as string, true);
        }
      }}
      onBlur={() => {
        onBlur && onBlur();
        formData.setFieldTouched(name as string, true);
      }}
      errorMessage={touched ? error : ''}
    />
  );
};
