import makeStyles from '@mui/styles/makeStyles';
import { BORDER_RADIUS } from '../../assets/styles';

type MeTileStyleType = { compact?: boolean };

export const useStyles = makeStyles(theme => ({
  root: ({ compact }: MeTileStyleType) => ({
    boxSizing: 'border-box',
    width: compact ? 198 : 411,
    minHeight: compact ? 178 : 237,
    margin: compact ? 5 : 11,
    position: 'relative',
    boxShadow: '0 2px 25px 0 rgba(0,0,0,0.1)',
    borderRadius: BORDER_RADIUS,
    backgroundColor: theme.vars.palette.bigid?.white,
    padding: compact ? 20 : 32,
    cursor: 'pointer',
  }),
  disabled: {
    opacity: 0.4,
    pointerEvents: 'none',
  },
  icon: ({ compact }: MeTileStyleType) => ({
    width: compact ? 65 : 92,
    height: compact ? 65 : 92,

    '& svg': {
      width: '100%',
      height: '100%',
    },
  }),
  title: ({ compact }: MeTileStyleType) => ({
    color: theme.vars.palette.bigid?.gray700,
    fontSize: `${compact ? 0.9375 : 1.25}rem`,
    fontWeight: 600,
    marginTop: 13,
  }),
  text: ({ compact }: MeTileStyleType) => ({
    color: theme.vars.palette.bigid?.gray700,
    fontSize: `${compact ? 0.6875 : 0.8125}rem`,
    marginTop: 4,
  }),
  image: {
    maxWidth: '100%',
  },
}));
