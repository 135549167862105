import { AnalyticModuleType, BrandType, RequestDataType, UpdateEventActionType } from '../types';

export const getAnalyticModuleByPath = (pathName: string) => {
  if (/^\/editor(\/.*)*$/.test(pathName)) return AnalyticModuleType.Editor;
  if (pathName === '/') return AnalyticModuleType.Dashboard;
  if (/^\/request-manager(\/.*)*$/.test(pathName)) return AnalyticModuleType.Requests;
  if (/^\/systems(\/.*)*$/.test(pathName)) return AnalyticModuleType.Systems;
  if (/^\/consents-mapping(\/.*)*$/.test(pathName)) return AnalyticModuleType.ConsentAndPreferences;
  if (/^\/cookie-consent(\/.*)*$/.test(pathName)) return AnalyticModuleType.CookieConsent;
  return AnalyticModuleType.Other;
};

export const getCompanyNameFromBrands = (brands: BrandType[]) => brands.find(brand => brand.defaultBrand)?.name || '';

export const getRequestPublicInformation = (request: RequestDataType) => ({
  requestId: request.id,
  deliveryMethod: request.attributes.find(atrr => atrr.name === 'deliveryMethod')?.value || '',
  userCountry: request.attributes.find(atrr => atrr.name === 'userCountry')?.value || '',
  userCountryDivision: request.attributes.find(atrr => atrr.name === 'userCountryDivision')?.value || '',
  userType: request.userType,
  brandName: request.brandName,
  brandId: request.brandId,
  dueDate: request.dueDate,
  dueDays: request.dueDays,
  expired: request.expired,
  inLimit: request.inLimit,
  newMessageCount: request.newMessageCount,
  processingStage: request.processingStage,
  processingStartDate: request.processingStartDate,
  regulation: request.regulation,
  requestKeyType: request.requestKeyType,
  responseDeadlineDays: request.responseDeadlineDays,
  submitFormName: request.submitFormName,
  type: request.type,
  verificationMethod: request.verificationMethod,
  status: request.status,
});

export const getUpdateRequestEventData = (
  request: RequestDataType,
  updateAction: UpdateEventActionType,
  extraProps: Record<string, unknown> = {},
) => ({
  ...getRequestPublicInformation(request),
  ...extraProps,
  updateAction,
});
