import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { RegulationType, StatisticPeriodValuesType } from '../../types';

import { useStyles } from './PeriodDashboardStyles';

const statisticsPeriods: { label: string; value: StatisticPeriodValuesType }[] = [
  { label: 'All Time', value: StatisticPeriodValuesType.all },
  { label: 'Last Year', value: StatisticPeriodValuesType.year },
  { label: 'Last Month', value: StatisticPeriodValuesType.month },
  { label: 'Last Week', value: StatisticPeriodValuesType.week },
];

export const PeriodDashboard = ({
  statisticsPeriod,
  onPeriodChange,
}: {
  statisticsPeriod: StatisticPeriodValuesType;
  onPeriodChange: (value: StatisticPeriodValuesType) => void;
}) => {
  const classes = useStyles();
  const statisticsButtonClass = (
    className: string,
    period: StatisticPeriodValuesType | RegulationType,
    statisticsPeriod: StatisticPeriodValuesType | RegulationType,
  ) => {
    return [
      classes[className as keyof typeof classes],
      period === statisticsPeriod ? classes.statisticsButtonActive : '',
    ].join(' ');
  };

  return (
    <div className={classes.statisticPeriods}>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        {statisticsPeriods.map(({ label, value }) => (
          <Button
            key={value}
            disableRipple
            disabled={value === statisticsPeriod}
            className={statisticsButtonClass('statisticsButton', value, statisticsPeriod)}
            onClick={() => onPeriodChange(value)}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};
