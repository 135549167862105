import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '1rem',
    color: theme.vars.palette.bigid?.gray700,
  },
  text: {
    margin: '20px 0',
    fontWeight: 600,
  },
  regulationsLabel: {
    paddingBottom: 16,
    '& img': {
      width: 40,
      height: 40,
    },
  },
  regulationsAllLabel: {
    marginTop: 15,
    paddingBottom: 10,
  },
  regulationsLayout: {
    position: 'relative',
    overflow: 'auto',
  },
  opacity: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    height: 15,
    zIndex: 10,
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 100%)',
  },
  regulations: {
    width: '99.9%',
    columnWidth: 245,
  },
  allLabel: {
    fontWeight: 800,
    paddingLeft: 8,
  },
  regulationLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8125rem',
    padding: '12px 0',
  },
  bold: {
    fontWeight: 800,
    padding: '0 5px',
  },
  normal: {
    fontWeight: 300,
  },
}));
