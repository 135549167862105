import { FormFieldType, RequestSubmitFieldType, RequestSubmitFormType, RequestSubmitPortalType } from '../../types';
import { findFormInPortals, findFormPathInPortals } from './requestSubmitFormUtils';
import { maxBy, minBy } from 'lodash';
import { clonePortalInPortals } from './requestSubmitPortalUtils';
import { FieldsType } from '@consumer/services';

export const findFieldPathInPortals = (portals: RequestSubmitPortalType[], fieldId: number) => {
  let portalIndex = -1;
  let formIndex = -1;
  let fieldIndex = -1;
  let portal = undefined;
  let form = undefined;
  let field = undefined;
  for (let pi = 0; pi < portals.length; pi++) {
    for (let fi = 0; fi < portals[pi].forms.length; fi++) {
      for (let fdi = 0; fdi < portals[pi].forms[fi].fields.length; fdi++) {
        if (portals[pi].forms[fi].fields[fdi].id === fieldId) {
          portalIndex = pi;
          formIndex = fi;
          fieldIndex = fdi;
          portal = portals[portalIndex];
          form = portal.forms[formIndex];
          field = form.fields[fieldIndex];
          break;
        }
      }
    }
  }
  return { portalIndex, formIndex, fieldIndex, portal, form, field };
};

export const findFieldInPortals = (portals: RequestSubmitPortalType[], fieldId: number) =>
  findFieldPathInPortals(portals, fieldId).field;

export const addRequestSubmitFieldInPortals = (
  portals: RequestSubmitPortalType[],
  formId: number,
  data: RequestSubmitFieldType,
) => {
  const { portal } = findFormPathInPortals(portals, formId);
  if (portal) {
    const updatedPortals = clonePortalInPortals(portals, portal.id);
    findFormInPortals(updatedPortals, formId)?.fields.push(data);
    return updatedPortals;
  }
  return portals;
};

export const updateRequestSubmitFieldInPortals = (
  portals: RequestSubmitPortalType[],
  fieldId: number,
  data: Partial<RequestSubmitFieldType>,
) => {
  const { portal } = findFieldPathInPortals(portals, fieldId);
  if (portal) {
    const updatedPortals = clonePortalInPortals(portals, portal.id);
    const updatedField = findFieldInPortals(updatedPortals, fieldId);
    if (updatedField) {
      Object.assign(updatedField, data);
    }
    return updatedPortals;
  }
  return portals;
};

export const updateRequestSubmitFieldOrderingInPortals = (
  portals: RequestSubmitPortalType[],
  formId: number,
  newOrder: { id: number; name: string; ordinal: number }[],
) => {
  const { portal } = findFormPathInPortals(portals, formId);
  if (portal) {
    const updatedPortals = clonePortalInPortals(portals, portal.id);
    const updatedForm = findFormInPortals(updatedPortals, formId);
    if (updatedForm) {
      const newFields = updatedForm.fields
        .map(f => {
          const fieldNewOrder = newOrder.find(o => o.id === f.id)?.ordinal;
          return { ...f, ordinal: fieldNewOrder !== undefined ? fieldNewOrder : f.ordinal };
        })
        .sort((o1, o2) => o1.ordinal - o2.ordinal)
        .map((f, i) => ({ ...f, ordinal: i + 1 }));
      updatedForm.fields = newFields;
    }
    return updatedPortals;
  }
  return portals;
};

export const deleteRequestSubmitFieldInPortals = (portals: RequestSubmitPortalType[], fieldId: number) => {
  const { portal, form } = findFieldPathInPortals(portals, fieldId);
  if (portal && form) {
    const updatedPortals = clonePortalInPortals(portals, portal.id);
    const updatedForm = findFormInPortals(updatedPortals, form.formId);
    if (updatedForm) {
      updatedForm.fields = updatedForm.fields.filter(field => field.id !== fieldId);
    }
    return updatedPortals;
  }
  return portals;
};

export const isRequestSubmitFieldNew = (field: RequestSubmitFieldType) => field.id < 0;

export const getAllFieldsInPortals = (portals: RequestSubmitPortalType[]) =>
  portals.flatMap(portal => portal.forms.flatMap(form => form.fields));

export const getAllExistingFieldsInPortals = (portals: RequestSubmitPortalType[]) =>
  getAllFieldsInPortals(portals).filter(f => !isRequestSubmitFieldNew(f));

export const getAllNewFieldsInPortals = (portals: RequestSubmitPortalType[]) =>
  getAllFieldsInPortals(portals).filter(isRequestSubmitFieldNew);

export const createIdForNewRequestSubmitField = (portals: RequestSubmitPortalType[]) => {
  const currentMinId = minBy(getAllFieldsInPortals(portals), field => field.id)?.id || 0;
  if (currentMinId > 0) return -1;
  return currentMinId - 1;
};

export const getMaxOrdinalForFieldsInForm = (form: RequestSubmitFormType) =>
  maxBy(form?.fields, field => field.ordinal)?.ordinal || 0;

export const createOrdinalForNewRequestSubmitField = (form: RequestSubmitFormType) =>
  getMaxOrdinalForFieldsInForm(form) + 1;

export const getInitialValuesForRequestSubmitField = (): RequestSubmitFieldType => ({
  id: 0,
  ordinal: 0,
  name: '',
  label: '',
  canToggle: true,
  masked: false,
  type: FormFieldType.TEXT,
  valueRequired: false,
  enabled: true,
  showTooltip: false,
  hideField: false,
  predefined: false,
  translations: {},
  requestSubmitFormId: 0,
  enabledForPhysicalMail: true,
  valueRequiredForPhysicalMail: false,
});

export const hasRequiredFieldsForAllForms = (forms: RequestSubmitFormType[]) => forms.every(hasRequiredFields);

export const hasRequiredFields = ({ fields }: RequestSubmitFormType) => {
  const deliveryMethod = fields.find(field => field.name === FieldsType.DELIVERY_METHOD)?.enabled;
  const phoneField = fields.find(field => field.name === FieldsType.USER_PHONE);
  const emailField = fields.find(field => field.name === FieldsType.USER_EMAIL);

  if (!deliveryMethod || !phoneField || !emailField) return true;

  return (
    (phoneField.enabledForPhysicalMail && phoneField.valueRequiredForPhysicalMail) ||
    (emailField.enabledForPhysicalMail && emailField.valueRequiredForPhysicalMail)
  );
};
