import makeStyles from '@mui/styles/makeStyles';
import { primaryBorder, BORDER_RADIUS } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  inputWrapper: {
    position: 'relative',
    textAlign: 'left',
  },
  inputRoot: {
    padding: '1px 20px',
    fontSize: '0.8125rem',
    backgroundColor: theme.vars.palette.bigid?.white,
    border: `1px solid ${theme.vars.palette.bigid?.gray500}`,
    borderRadius: BORDER_RADIUS,
    width: '100%',
    '&::after, &::before': {
      display: `none !important`,
    },
    '&.Mui-focused': {
      border: `1px solid ${theme.vars.palette.bigid?.blue900}`,
    },
    '&input': {
      backgroundSize: '0 0 !important',
    },
    '& input': {
      padding: '6px 8px 7px 0',
      textOverflow: 'ellipsis',
      height: '1.1876em',
    },
  },
  isSquare: {
    borderRadius: 8,
    padding: '7px 20px',
    border: primaryBorder,
  },
  rightIconPadding: {
    paddingRight: 50,
  },
  leftIconPadding: {
    paddingLeft: 50,
  },
  showHidePadding: {
    paddingRight: 60,
  },
  showHideWithIconPadding: {
    paddingRight: 90,
  },
  label: {
    textAlign: 'left',
    color: theme.vars.palette.bigid?.gray600,
    fontWeight: 500,
    fontSize: '0.8125rem',
    lineHeight: '1.7rem',
    transform: 'none',
  },
  isSquareLabel: {
    color: theme.vars.palette.bigid?.gray600,
    fontWeight: 500,
    textTransform: 'capitalize',
    lineHeight: '2.7rem',
  },
  inputError: {
    border: `1px solid ${theme.vars.palette.bigid?.red500} !important`,
  },
  error: {
    position: 'absolute',
    bottom: -20,
    left: 10,
    zIndex: 10,
    height: 20,
    fontSize: '0.7rem',
    color: `${theme.vars.palette.bigid?.red500} !important`,
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '0.8125rem',
    fontWeight: 'normal',
  },
  maskAction: {
    paddingRight: 10,
  },
  rightIcon: {
    paddingRight: 5,
    width: 36,
    height: '100%',
    display: 'flex',
    '& svg': {
      margin: 'auto',
    },
  },
  leftIcon: {
    position: 'absolute',
    left: 5,
    top: 0,
    width: 36,
    height: '100%',
    display: 'flex',
    '& svg': {
      margin: 'auto',
    },
  },
  required: {
    color: theme.vars.palette.bigid?.red500,
  },
  rightElement: {
    position: 'absolute',
    left: '100%',
    top: 0,
    height: '100%',
    display: 'flex',
  },
  rightsideElements: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
}));
