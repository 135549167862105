import React, { ComponentType, FC, memo } from 'react';
import { Divider, IconButton } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { ExtraFieldItemData } from './useExtraKeyValueFieldsControl';
import { BigidDeleteIcon, BigidAddIcon, BigidObjectsIllustration } from '@bigid-ui/icons';
import { BigidBody1, BigidFormFieldErrorHelper, TertiaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { useStyles, ExtraFieldWrapper } from './MeExtraKeyValueFieldsStyles';
import { SystemNameValueFields } from '../systems/SystemNameValueFields';
import { FormikProps } from 'formik';

export enum EXTRA_FIELD_LABEL_POSITION {
  left = 'left',
  top = 'top',
}
export interface EmptyTemplateData {
  isShow?: boolean;
  title?: string;
  btnLabel?: string;
}
export interface MeExtraKeyValueFieldsProps {
  fieldComponent: ComponentType<any>;
  fieldsBottomOffset?: number;
  data: ExtraFieldItemData[];
  label?: string;
  labelPosition?: EXTRA_FIELD_LABEL_POSITION;
  onAddNew: () => void;
  onDelete: (index: number) => void;
  onChangeItem: (index: number, value: any) => void;
  onBlurItem?: (id: string) => void;
  addLabel?: string;
  disabled?: boolean;
  labelWidth?: string;
  alignCenter?: boolean;
  isDataInAll?: boolean;
  errorMessage?: string;
  errorMessageArray?: { name: string; value?: string }[];
  allowToDeleteAll?: boolean;
  emptyTemplate?: EmptyTemplateData;
  autocompleteListItems?: { value: string; label: string }[];
  formData: FormikProps<any>;
  valueName?: string;
  keyName?: string;
  testId?: string;
}

export const MeExtraKeyValueFields: FC<MeExtraKeyValueFieldsProps> = memo(
  ({
    fieldsBottomOffset = 0,
    data,
    label,
    labelPosition = EXTRA_FIELD_LABEL_POSITION.left,
    onAddNew,
    onDelete,
    addLabel = 'Add another',
    disabled = false,
    labelWidth,
    alignCenter,
    errorMessage,
    allowToDeleteAll,
    emptyTemplate,
    autocompleteListItems,
    formData,
    valueName,
    keyName,
    testId,
  }) => {
    const {
      fieldWrapper,
      deleteWrapper,
      deleteButton,
      fieldsWrapper,
      addMoreWrapper,
      labelWrapper,
      divider,
      addMoreLabel,
      emptyTemplateContainer,
      emptyTemplateMoreTitle,
    } = useStyles({
      fieldsBottomOffset,
      labelWidth,
      alignCenter,
    });
    const labelRendered = label && (
      <FormLabel className={labelWrapper} component={BigidBody1}>
        {label}
      </FormLabel>
    );
    const toDisplayDeleteIcon = (index: number) => (allowToDeleteAll ? true : data.length === 1 ? index !== 0 : true);
    return (
      <div data-aid={generateDataAid('MeExtraField', [label])} className={fieldsWrapper}>
        {labelPosition === EXTRA_FIELD_LABEL_POSITION.top && labelRendered}
        {data.map((item, index) => (
          <div key={index} className={fieldWrapper}>
            {labelPosition === EXTRA_FIELD_LABEL_POSITION.left && labelRendered}
            <SystemNameValueFields
              displayAsRequired
              type={item.dropdownOptions ? 'dropdown' : 'autocomplete'}
              autocompleteListItems={autocompleteListItems}
              dropdownOptions={item.dropdownOptions}
              formData={formData}
              valueName={`${valueName}.${index}.value`}
              keyName={`${keyName}.${index}.name`}
              testId={`${testId}-${index}`}
              disabledName={item.disabledName}
              disabledValue={item.disabledValue}
            />

            {!disabled && toDisplayDeleteIcon(index) && !item.mandatory && (
              <div className={deleteWrapper}>
                <Divider className={divider} orientation="vertical" />
                <IconButton
                  onClick={() => onDelete(index)}
                  className={deleteButton}
                  disableRipple
                  disableFocusRipple
                  size="large"
                  data-aid={`${testId}-${index}-delete-button`}
                >
                  <BigidDeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
        ))}
        {errorMessage && (
          <BigidFormFieldErrorHelper errorMessage={errorMessage} dataAid={'MeExtraField-errorMessage'} />
        )}
        {!disabled && (!emptyTemplate?.isShow || data.length > 0) && (
          <div className={addMoreWrapper}>
            <TertiaryButton
              size="small"
              onClick={onAddNew}
              disabled={disabled}
              dataAid={generateDataAid('BigidExtraFieldAddMore', [label])}
            >
              <ExtraFieldWrapper data-aid={`${testId}-add-item-button`}>
                <BigidAddIcon />
                <BigidBody1 className={addMoreLabel}>{addLabel}</BigidBody1>
              </ExtraFieldWrapper>
            </TertiaryButton>
          </div>
        )}
        {emptyTemplate?.isShow && data.length === 0 && (
          <div className={emptyTemplateContainer}>
            <BigidBody1 className={emptyTemplateMoreTitle}>{emptyTemplate?.title}</BigidBody1>
            <BigidObjectsIllustration />
            <TertiaryButton
              size="small"
              onClick={onAddNew}
              disabled={disabled}
              dataAid={generateDataAid('BigidExtraFieldAddMore', [label])}
            >
              <ExtraFieldWrapper data-aid={`${testId}-add-item-button`}>
                <BigidAddIcon />
                <BigidBody1 className={addMoreLabel}>{emptyTemplate?.btnLabel || 'Add item'}</BigidBody1>
              </ExtraFieldWrapper>
            </TertiaryButton>
          </div>
        )}
      </div>
    );
  },
);

MeExtraKeyValueFields.displayName = 'MeExtraField';
