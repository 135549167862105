import { BigidDropdownOption, BigidSelectOption } from '@bigid-ui/components';
import { CountryDivisionType, CountryType } from '../types';

export const countriesToDropdownOptions = (countries: CountryType[]): BigidDropdownOption[] =>
  countries.map(country => ({
    id: country.name,
    value: country.name,
    displayValue: country.displayName,
    children: country.divisions.map(division => ({
      id: division.name,
      value: division.name,
      displayValue: division.displayName,
    })),
  }));

export const countriesToSelectOptions = (countries: CountryType[], includeDivisions = true): BigidSelectOption[] =>
  countries.reduce((acc, country) => {
    acc.push({
      id: country.name,
      value: country.name,
      label: country.displayName,
    });
    if (includeDivisions) {
      country.divisions.forEach(division => {
        acc.push({
          id: division.name,
          value: division.name,
          label: division.displayName,
        });
      });
    }
    return acc;
  }, [] as BigidSelectOption[]);

export const countryDivisionsToSelectOptions = (divisions: CountryDivisionType[]): BigidSelectOption[] =>
  divisions.reduce((acc, country) => {
    acc.push({
      id: country.name,
      value: country.name,
      label: country.displayName,
    });
    return acc;
  }, [] as BigidSelectOption[]);

export const countryDivisionsToDropdownOptions = (divisions: CountryDivisionType[]): BigidDropdownOption[] =>
  divisions.reduce((acc, country) => {
    acc.push({
      id: country.name,
      value: country.name,
      displayValue: country.displayName,
    });
    return acc;
  }, [] as BigidDropdownOption[]);

export const countriesWithDivisionsToDropdownOptions = (
  countries: CountryType[],
  includeDivisions = true,
): BigidDropdownOption[] =>
  countries.reduce((acc, country) => {
    acc.push({
      id: country.name,
      value: country.name,
      displayValue: country.displayName,
    });
    if (includeDivisions) {
      country.divisions.forEach(division => {
        acc.push({
          id: division.name,
          value: division.name,
          displayValue: division.displayName,
        });
      });
    }
    return acc;
  }, [] as BigidDropdownOption[]);
