import React from 'react';
import { RequestManager } from './RequestManager';
import { RequestManagerStateContainer } from './useRequestManagerState';
import { SystemUpdateMessageContainer } from './useSystemUpdateMessage';
import { RequestManagerGridContainer } from './useRequestManagerGrid';
import { RequestOwnersContainer } from './useRequestOwners';
import { RequestManagerViewsContainer } from './useRequestManagerViews';

export const RequestManagerContainer = () => (
  <RequestManagerStateContainer.Provider>
    <RequestOwnersContainer.Provider>
      <SystemUpdateMessageContainer.Provider>
        <RequestManagerViewsContainer.Provider>
          <RequestManagerGridContainer.Provider>
            <RequestManager />
          </RequestManagerGridContainer.Provider>
        </RequestManagerViewsContainer.Provider>
      </SystemUpdateMessageContainer.Provider>
    </RequestOwnersContainer.Provider>
  </RequestManagerStateContainer.Provider>
);
