import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import {
  BusinessUserType,
  CustomerFieldType,
  GridRequestCollaboratorType,
  GridRequestsFilterType,
  GridRowType,
  UserGridPreferenceViewFilter,
  UserRequestAttributeInfoType,
  UserRequestPreferenceInfoType,
} from '../../types';
import {
  renderNotesCell,
  renderRequestClosingTime,
  renderRequestorId,
  renderRequestorType,
  renderActualDays,
  renderDeadlineDays,
  renderRequestStatus,
  renderRequestType,
  renderUserRequestAttribute,
} from '../../utils/rendersUtils';
import { renderUpgradeButton } from '../../utils/gridUtils';
import { GetFormattedDateType } from '../../types/CommonTypes';
import { BigidAdvancedToolbarFilterUnion, BigidDropdown } from '@bigid-ui/components';
import React from 'react';
import {
  businessUsersToBigidDropdownOptions,
  businessUsersToBigidDropdownValue,
  collaboratorsToBigidDropdownValue,
} from '../../utils/businessUserUtils';
import { isArray, isEqual } from 'lodash';
import { Typography } from '@mui/material';
import { StopPropagationContainer } from '../../components/StopPropagationContainer';
import { getRequestOriginLabel, renderRequestRegulation } from '../../utils';

export const requestManagerColumns = ({
  onOwnerChange,
  onCollaboratorsChange,
  requestAttributesInfo,
  requestPreferencesInfo,
  actions,
  getFormattedDate,
  usersToAssign,
}: {
  onOwnerChange: (requestId: string, ownerId: string, owner: string) => void;
  onCollaboratorsChange: (requestId: string, collaborators: GridRequestCollaboratorType[]) => void;
  requestAttributesInfo: UserRequestAttributeInfoType[];
  requestPreferencesInfo: UserRequestPreferenceInfoType[];
  actions?: { CHANGE_REQUEST_OWNER?: boolean; CHANGE_REQUEST_COLLABORATOR?: boolean; SHOW_NOTES?: boolean };
  getFormattedDate: GetFormattedDateType;
  usersToAssign: BusinessUserType[];
}): BigidGridColumn<GridRowType>[] => [
  {
    name: 'id',
    title: 'Request ID',
    width: 140,
    getCellValue: (row: GridRowType) => <Typography>{row.id}</Typography>,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'requestKey',
    title: 'Requester ID',
    getCellValue: (row: GridRowType) => renderRequestorId(row.requestKey, row.newMessageCount > 0),
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
    // no sorting for encrypted field
    sortingEnabled: false,
  },
  {
    name: 'status',
    title: 'Compliance Progress',
    width: 150,
    getCellValue: (row: GridRowType) => renderRequestStatus(row.status),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
  },
  {
    name: 'type',
    title: 'Type',
    width: 150,
    getCellValue: (row: GridRowType) => {
      return renderRequestType(row.type);
    },
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'processingStage',
    title: 'Stage',
    width: 150,
    getCellValue: (row: GridRowType) => row.processingStage,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'userType',
    title: 'Profile',
    getCellValue: (row: GridRowType) => row.userType,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
    // no sorting for encrypted field
    sortingEnabled: false,
  },
  {
    name: 'regulation',
    title: 'Regulation',
    width: 150,
    getCellValue: (row: GridRowType) => {
      return renderRequestRegulation(row.regulation);
    },
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
  },
  {
    name: 'responseDeadlineDays',
    title: 'Regulatory Period (Days)',
    width: 140,
    getCellValue: (row: GridRowType) => renderDeadlineDays(row),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
  },
  {
    name: 'responseActualDays',
    title: 'Actual Days',
    width: 140,
    getCellValue: (row: GridRowType) => renderActualDays(row),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
  },
  {
    name: 'dueDays',
    title: 'Due in (Days)',
    width: 140,
    getCellValue: (row: GridRowType) => row.dueDays || 'N/A',
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'owner',
    title: 'Owner',
    width: 280,
    getCellValue: row =>
      actions?.CHANGE_REQUEST_OWNER && row.inLimit && !row.closed ? (
        <BigidDropdown
          dataAid={`owner-${row.id}`}
          value={businessUsersToBigidDropdownValue(usersToAssign, [row.owner])}
          options={businessUsersToBigidDropdownOptions(usersToAssign)}
          onSelect={values => {
            const newValue = values[0]?.value as string;
            if (newValue !== row.owner) {
              onOwnerChange(row.id, values[0]?.id, values[0]?.displayValue);
            }
          }}
          isSearchable
          isErasable
        />
      ) : (
        row.owner
      ),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
  },
  {
    name: 'collaborators',
    title: 'Collaborators',
    width: 280,
    getCellValue: row => {
      const mappedValues = (row.collaborators || []).map((item: GridRequestCollaboratorType) => item.userFullName);
      return actions?.CHANGE_REQUEST_COLLABORATOR && !row.closed ? (
        // div needed to prevent row clicking when selecting apply button in multi mode
        <StopPropagationContainer>
          <BigidDropdown
            dataAid={`collaborators-${row.id}`}
            value={collaboratorsToBigidDropdownValue(row.collaborators)}
            options={businessUsersToBigidDropdownOptions(usersToAssign)}
            onSelect={values => {
              const newValues = values.map(({ id, displayValue }) => ({
                userId: Number(id),
                userFullName: displayValue,
              }));
              if (!isEqual(row.collaborators, newValues)) {
                onCollaboratorsChange(row.id, newValues);
              }
            }}
            placement="top"
            applyOnChange
            isSearchable
            isErasable
            isMulti
          />
        </StopPropagationContainer>
      ) : (
        mappedValues.join(', ')
      );
    },
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
    sortingEnabled: false,
  },
  {
    name: 'brandName',
    title: 'Brand',
    width: 150,
    getCellValue: (row: GridRowType) => row.brandName,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'submitFormName',
    title: 'Form',
    width: 150,
    getCellValue: (row: GridRowType) => row.submitFormName,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'requestorType',
    title: 'Requestor Type',
    width: 150,
    getCellValue: (row: GridRowType) => renderRequestorType(row.requestorType),
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
    isHiddenByDefault: true,
  },
  {
    name: 'requestedBy',
    title: 'Requested By',
    width: 150,
    getCellValue: (row: GridRowType) => row.requestedBy,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
    isHiddenByDefault: true,
    sortingEnabled: false,
  },
  {
    name: 'notes',
    title: 'Notes',
    width: 130,
    getCellValue: (row: GridRowType) => {
      return renderNotesCell(row, !!actions?.SHOW_NOTES);
    },
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
    sortingEnabled: false,
  },
  {
    name: 'processingStartDate',
    title: 'Create Date',
    getCellValue: (row: GridRowType) =>
      row.inLimit ? `${getFormattedDate(row.processingStartDate)}` : renderUpgradeButton(),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
  },
  {
    name: 'requestOrigin',
    title: 'Received via',
    width: 150,
    getCellValue: (row: GridRowType) => getRequestOriginLabel(row.requestOrigin),
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
    isHiddenByDefault: true,
  },
  {
    name: 'closeDate',
    title: 'Closing Time',
    width: 230,
    getCellValue: (row: GridRowType) => renderRequestClosingTime(row, getFormattedDate),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
  },
  {
    name: 'closingResolution',
    title: 'Closing Resolution',
    width: 190,
    getCellValue: (row: GridRowType) => row.closingResolution,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'externalId',
    title: 'External ID',
    width: 150,
    getCellValue: (row: GridRowType) => row.externalId,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
    isHiddenByDefault: true,
    sortingEnabled: false,
  },
  ...requestAttributesInfo.map(
    attributeInfo =>
      ({
        name: `attribute-${attributeInfo.name}`,
        title: attributeInfo.label,
        width: 140,
        getCellValue: (row: GridRowType) => {
          const attr = row.attributes?.find(a => a.name === attributeInfo.name);
          if (attr?.name === CustomerFieldType.USER_DATE_OF_BIRTH) {
            return getFormattedDate(attr.value, { month: 'long', day: 'numeric' }, false);
          }
          if (attr) {
            return renderUserRequestAttribute(attr.value, attr.masked);
          }
          return '';
        },
        type: BigidGridColumnTypes.CUSTOM,
        disableTooltip: true,
        isHiddenByDefault: true,
        // sorting is not implemented on backend
        sortingEnabled: false,
        showSortingControls: false,
      } as BigidGridColumn<GridRowType>),
  ),
  ...requestPreferencesInfo.map(
    preferenceInfo =>
      ({
        name: `preference-${preferenceInfo.name}`,
        title: preferenceInfo.label,
        width: 140,
        getCellValue: (row: GridRowType) => {
          const attr = row.preferences?.find(a => a.name === preferenceInfo.name);
          if (attr?.value === true) return 'Opt-In';
          if (attr?.value === false) return 'Opt-Out';
          return '';
        },
        type: BigidGridColumnTypes.CUSTOM,
        disableTooltip: true,
        // sorting is not implemented on backend
        sortingEnabled: false,
        showSortingControls: false,
        isHiddenByDefault: true,
      } as BigidGridColumn<GridRowType>),
  ),
];

export const mapViewFiltersToRequestsFilters = (
  viewFilters?: UserGridPreferenceViewFilter[],
): GridRequestsFilterType[] => {
  return viewFilters
    ? viewFilters
        .filter(filter => filter.enabled && filter.options.length > 0)
        .map(filter => ({
          id: String(filter.id),
          field: filter.name,
          value: filter.options,
          operator: filter.operator,
          dateFrom: filter.dateFrom,
          dateTo: filter.dateTo,
        }))
    : [];
};

export const mergeAdvancedToolbarFilterToRequestsFilters = (
  filters: BigidAdvancedToolbarFilterUnion[],
): GridRequestsFilterType[] => {
  return (filters || []).map(filter => ({
    id: String(filter.id),
    field: filter.field,
    operator: filter.operator,
    value: isArray(filter.options) ? filter.options.map(option => option.value) : [],
    dateFrom: !isArray(filter.options) ? filter.options?.pickersState?.dates.from?.toISOString() : undefined,
    dateTo: !isArray(filter.options) ? filter.options?.pickersState?.dates.until?.toISOString() : undefined,
    currentRangeMode: !isArray(filter.options) ? filter.options.pickersState.currentMode : undefined,
    currentRangeOption: !isArray(filter.options) ? filter.options.currentRangeOptionSelected : undefined,
    type: filter.type,
  }));
};

export const isOpenRequestsView = (requestsFilters?: GridRequestsFilterType[]) => {
  const closedFiledValue = requestsFilters?.find(f => f.field === 'closed')?.value;
  const allRequests = !closedFiledValue || (isArray(closedFiledValue) && closedFiledValue.length === 2);
  const openRequests =
    !closedFiledValue ||
    (isArray(closedFiledValue) && closedFiledValue.length === 1 && closedFiledValue.includes('Open'));

  return allRequests || openRequests;
};
