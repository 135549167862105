import React, { FC, useEffect, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { NavItemType } from '../../types';
import { MainLoader } from '../MainLoader';
import { NavItemsContainer } from '../../state/navItemsContainer';

type RoutePermissionGuardPropsType = {
  itemType: NavItemType;
  children?: ReactNode;
};

export const RoutePermissionGuard: FC<RoutePermissionGuardPropsType> = ({ children, itemType }) => {
  const { navItems, fetchNavItems, activateNavItem } = NavItemsContainer.useContainer();

  useEffect(() => {
    if (!navItems) {
      fetchNavItems();
    }

    if (navItems?.[itemType]?.activated) {
      return;
    }
  }, [activateNavItem, fetchNavItems, itemType, navItems]);

  if (!navItems) {
    return <MainLoader />;
  }

  if (!navItems[itemType]) {
    return <Navigate to="/unauthorized" />;
  }

  return <>{children}</>;
};
