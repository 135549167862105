import {
  PricingPlanResponseType,
  SubmitPricingPlanParamsType,
  PricingPlansResponseType,
  FeatureType,
  FeatureCode,
} from '../types';
import { api } from './request/apiService';
import axios from 'axios';

export const publicApi = axios.create({
  baseURL: process.env.REACT_APP_WORKFLOW_API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  responseType: 'json',
});

export const loadPricingPlans = async (productType: string): Promise<PricingPlansResponseType[]> =>
  await publicApi
    .get<{ plans: PricingPlansResponseType[] }>(`/payment/plans?productType=${productType}`)
    .then(({ data }) => data.plans);

export const loadTenantPricingPlans = async (productType: string): Promise<PricingPlansResponseType[]> =>
  await api
    .get<{ plans: PricingPlansResponseType[] }>(`/payment/plans?productType=${productType}`)
    .then(({ data }) => data.plans);

export const submitPricingPlan = async (
  requestPayload: SubmitPricingPlanParamsType,
): Promise<PricingPlanResponseType> =>
  await api.post<PricingPlanResponseType>('/plan-enterprise', requestPayload).then(({ data }) => data);

export const loadFeature = (code: FeatureCode): Promise<FeatureType> =>
  api.get<FeatureType>(`/features/${code}`).then(({ data }) => data);
