import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import { BigidTooltip } from '@bigid-ui/components';

import { useStyles } from './MeTileStyles';

export type MeTilePropsType = {
  icon: string | ReactNode;
  title?: ReactNode;
  text?: ReactNode;
  tooltip?: string;
  disabled?: boolean;
  compact?: boolean;
  onClick?: () => void;
  children?: ReactNode;
  testId?: string;
};

export const MeTile: FC<MeTilePropsType> = ({
  icon,
  title,
  text,
  tooltip,
  disabled,
  compact,
  onClick,
  children,
  testId,
}) => {
  const classes = useStyles({ compact });

  return (
    <BigidTooltip title={tooltip || ''}>
      <div
        onClick={onClick}
        className={cx(classes.root, {
          [classes.disabled]: disabled,
        })}
        data-aid={testId}
      >
        <div className={classes.icon}>
          {typeof icon === 'string' ? <img src={icon} className={classes.image} /> : icon}
        </div>
        <div className={classes.title}>{title}</div>
        <div className={classes.text}>{text}</div>
        {children}
      </div>
    </BigidTooltip>
  );
};
