import * as Yup from 'yup';

import {
  SystemInfoType,
  SystemDataSourceType,
  SystemFieldNames,
  DatasourcesProviderType,
  CreateDatasourseType,
  SystemOperationFlowType,
  SystemRegisterStepFormType,
  HttpRequestMethodType,
  SystemOperationTypeType,
} from '../types';
import { DEFAULT_SYSTEM, NEW_DATASOURCE_TYPE_ID } from './settingsUtils';
import { makeAllowedSymbolsValidation, requiredStringField } from './validationUtils';

export const makeSystemFromDataSource = (dataSource: SystemDataSourceType): SystemInfoType => {
  let systemName = dataSource.displayName;

  if (dataSource.id === NEW_DATASOURCE_TYPE_ID) {
    systemName = '';
  }

  return {
    ...DEFAULT_SYSTEM,
    name: systemName,
    dataSourceType: dataSource,
  };
};

export const makeValidationForDefaultSystem = (system: SystemInfoType): Yup.AnySchema => {
  const config: Record<string, Yup.AnySchema> = {
    [SystemFieldNames.NAME]: makeAllowedSymbolsValidation(SystemFieldNames.NAME),
  };

  if (system.dataSourceType.id === NEW_DATASOURCE_TYPE_ID) {
    config[SystemFieldNames.DATA_SOURCE_NAME] = requiredStringField;
  }

  return Yup.object(config);
};

export const doesSystemSupportsSignIn = (system: SystemInfoType) =>
  system.dataSourceType.providerType === DatasourcesProviderType.LWC;

export const convertSystemInfoToEditableForm = (system: SystemInfoType): CreateDatasourseType => {
  const { scopes, profiles, dataSourceType, accesses, ...rest } = system;

  const result = {
    ...rest,
    scopeIds: scopes.map(s => s.id.toString()),
    profileIds: profiles.map(p => p.id.toString()),
    dataSourceTypeId: dataSourceType.id,
    dataSourceTypeName: dataSourceType.name,
    dataSourceTypeProviderType: dataSourceType.providerType,
    dataSourceTypeSupported: dataSourceType.supported,
    accesses: accesses.map(a => a.accessName),
  };

  if (dataSourceType.id === NEW_DATASOURCE_TYPE_ID) {
    result.dataSourceTypeName = '';
  }

  return result;
};

export const systemOperationFlowSchema = Yup.object({
  request: Yup.object({
    url: Yup.string().required('URL is required'),
    type: Yup.string().required('Request type is required'),
    headers: Yup.array().of(
      Yup.object({
        name: Yup.string().required('Key is required'),
        value: Yup.string().required('Value is required'),
      }),
    ),
    body: Yup.string(),
  }),
});

export const makeDefaultValuesForSystemRegisterStep = (): SystemRegisterStepFormType => ({
  name: SystemFieldNames.NAME,
  scopeIds: [],
  profileIds: [],
  thirdParty: false,
});

export const makeDefaultValuesForSystemOperationFlow = (): SystemOperationFlowType => ({
  request: {
    url: '',
    type: HttpRequestMethodType.POST,
    headers: [],
    body: '',
  },
  response: {
    type: 'sync',
  },
});

export const isDataSourceIsGenericConnector = (dataSource: SystemDataSourceType) =>
  dataSource.name === 'generic_connector';

export const getSystemOperationFlowByType = (system: SystemInfoType, type: SystemOperationTypeType) =>
  system.supportedOperations?.find(item => item.type === type)?.operationFlow?.[0];

export const getSystemOperationFlowOrDefault = (system: SystemInfoType, type: SystemOperationTypeType) =>
  getSystemOperationFlowByType(system, type) || makeDefaultValuesForSystemOperationFlow();

export const getSystemOperationTypes = (system: SystemInfoType): SystemOperationTypeType[] =>
  system.supportedOperations?.map(operation => operation.type) || [];
