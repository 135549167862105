import { cloneElement, createElement } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fixReactElementInParams = <T extends Record<string, any>>(params: T): T => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(params).reduce<any>((acc, key) => {
    if (params[key]?.type) acc[key] = cloneElement(params[key]);
    else acc[key] = params[key];
    return acc;
  }, {});
};

export const createTestBox = (name: string, width: number | string, height: number | string, background: string) =>
  createElement(
    'div',
    {
      style: {
        width,
        height,
        background,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    name,
  );
