import React, { useCallback, useState } from 'react';
import PDFViewer from 'pdf-viewer-reactjs';
import { ReactComponent as IconConsumerCard } from '../../assets/illustrations/icon-documents-blue.svg';
import { BigidViewIcon } from '@bigid-ui/icons';
import { BigidDownloadIcon } from '@bigid-ui/icons';
import { useStyles } from './CustomerCardStyles';
import { downloadCustomerCard } from '../../services/request';

export interface CustomerCardPropsType {
  file: string;
  fileName?: string;
  from?: string;
  requestId?: string;
  fileId?: string;
}

export const CustomerCard = ({ file, from = 'consumer', fileName, requestId, fileId }: CustomerCardPropsType) => {
  const [isImageVisible, setImageVisibility] = useState(false);
  const classes = useStyles();

  const setPhotoTimer = useCallback(() => {
    let timerId: number | undefined = undefined;
    setImageVisibility(true);
    timerId = window.setTimeout(() => {
      setImageVisibility(false);
      if (timerId) {
        window.clearTimeout(timerId);
      }
    }, 20000);
  }, []);

  const isPdf = file.includes('application/pdf');
  const onFileDownload = async () => {
    if (requestId && fileId && fileName) {
      await downloadCustomerCard(requestId, fileId, fileName);
    }
  };

  return (
    <div className={classes.root}>
      {!isImageVisible && (
        <div className={classes.previewRoot}>
          <div className={classes.wrapper}>
            <div className={classes.iconWrapper}>
              <IconConsumerCard />
              <div>Received file from {from}</div>
              {fileName && <p>{fileName}</p>}
            </div>
            <div className={classes.preview}>
              <div onClick={setPhotoTimer} data-aid="consumer-id-preview">
                <BigidViewIcon />
                <span>Preview</span>
              </div>
              {fileId && (
                <div onClick={onFileDownload}>
                  <BigidDownloadIcon />
                  <span>Download File</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isImageVisible && !isPdf && (
        <div className={classes.padding}>
          <img src={file as unknown as string} width="354px" />
        </div>
      )}
      {isImageVisible && isPdf && (
        <PDFViewer
          hideZoom
          hideRotation
          navigation={{
            css: {
              navbarWrapper: classes.navbarWrapper,
              nextPageBtn: classes.nextPageBtn,
              previousPageBtn: classes.previousPageBtn,
            },
          }}
          document={{
            url: file,
          }}
        />
      )}
    </div>
  );
};
