import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const useAuthorizedAgentGuidedTour = () => {
  const [isAuthorizedAgentTourRunning, setIsAuthorizedAgentTourRunning] = useState<boolean>(false);
  const [authorizedAgentTourStartedFrom, setAuthorizedAgentTourStartedFrom] = useState<'editor' | 'brands' | null>(
    null,
  );
  const [authorizedAgentTourStepIndex, setAuthorizedAgentTourStepIndex] = useState<number>(0);

  const restartAuthorizedAgentTour = () => {
    setAuthorizedAgentTourStartedFrom('editor');
    setAuthorizedAgentTourStepIndex(0);
    setIsAuthorizedAgentTourRunning(true);
  };

  return {
    isAuthorizedAgentTourRunning,
    setIsAuthorizedAgentTourRunning,
    authorizedAgentTourStartedFrom,
    setAuthorizedAgentTourStartedFrom,
    authorizedAgentTourStepIndex,
    setAuthorizedAgentTourStepIndex,
    restartAuthorizedAgentTour,
  };
};

export const AuthorizedAgentGuidedTourContainer = createContainer(useAuthorizedAgentGuidedTour);
