import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button } from '@mui/material';

import { RegulationType, StatisticPeriodValuesType, TrendsRequestsOptionsType } from '../../types';
import { getRequestsByRegulations } from '../../services/request';

import { useStyles } from './RegulationTypesDashboardStyles';
import { getRegulationIcon } from '../../utils';

export type RegulationFilterItemType = {
  name: RegulationType;
  label?: string;
  value?: number;
}[];

export const RegulationTypesDashboard = ({
  regulation = '',
  statisticsPeriod = StatisticPeriodValuesType.week,
  onRegulationChange,
}: {
  regulation: RegulationType;
  statisticsPeriod: StatisticPeriodValuesType;
  onRegulationChange: (name: RegulationType) => void;
}) => {
  const classes = useStyles();
  const [regulationFilterItems, setRegulationFilterItems] = useState<RegulationFilterItemType>([]);

  useEffect(() => {
    fetchRequestsByRegulations({ period_days: statisticsPeriod });
  }, [statisticsPeriod]);

  const fetchRequestsByRegulations = async (options: TrendsRequestsOptionsType) => {
    const data = await getRequestsByRegulations(options);
    const items = data
      .map(({ type, value }) => ({
        name: type,
        label: type.split('_')[0],
        value: value,
      }))
      .filter(q => !!q.label);

    setRegulationFilterItems([{ name: '', label: 'All', value: 0 }, ...items]);
  };

  const statisticsButtonClass = (
    className: string,
    period: StatisticPeriodValuesType | RegulationType,
    statisticsPeriod: StatisticPeriodValuesType | RegulationType,
  ) => {
    return [
      classes[className as keyof typeof classes],
      period === statisticsPeriod ? classes.statisticsButtonActive : '',
    ].join(' ');
  };

  return (
    <div className={classes.flexWrapper}>
      <div className={classes.regulationsWrapper}>
        <span className={classes.switcherTitle}>Regulation Types</span>
        <div className={classes.statisticPeriods}>
          <ButtonGroup color="primary" aria-label="outlined primary button group" className={classes.buttonsGroup}>
            {regulationFilterItems.map(({ name, label, value }) => (
              <Button
                key={name}
                disableRipple
                disabled={name === regulation}
                onClick={() => onRegulationChange(name)}
                startIcon={getRegulationIcon(name)}
                size="medium"
                className={statisticsButtonClass('statisticsButton', name, regulation)}
              >
                <span className={classes.bold}>{label}</span>
                {value ? `(${value})` : ''}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};
