import { useCallback, useEffect, useReducer, useState } from 'react';
import { createContainer } from 'unstated-next';

import { AuthContainer } from './authContainer';
import { loadFeature, loadTenantPricingPlans } from '../services/pricingPlansService';
import { FeatureCode, FeatureType, PRICING_PLAN, PAYMENT_PERIOD } from '../types';
import { ADDITIONAL_PRICING_PLAN, PricingPlansResponseType, PRODUCT_TYPES } from '../types/PricingPlanTypes';
import { convertArrayToObject } from '../utils/commonUtils';
import { pricingReducer, initialPricingState, PricingActionTypes } from '../components/pricing/PricingPlans/reducer';

export type FeaturesStateType = Partial<Record<FeatureCode, FeatureType>>;

const getFeatureItem = (featureData: FeatureType) => {
  const { code, enabled, count, quota } = featureData;

  return { [code]: { enabled, count, quota } };
};

export const PurchasedPlansDefault = [
  {
    planName: PRICING_PLAN.TRIAL,
    paymentPeriod: PAYMENT_PERIOD.MONTHLY,
  },
  {
    planName: PRICING_PLAN.CC_TRIAL,
    paymentPeriod: PAYMENT_PERIOD.MONTHLY,
  },
];

export interface PurchasedPlanType {
  planName: PRICING_PLAN | ADDITIONAL_PRICING_PLAN;
  paymentPeriod: PAYMENT_PERIOD;
}

export const usePricingPlan = () => {
  const { auth } = AuthContainer.useContainer();
  const [{ plans, isEnterprise, isTrial }, dispatch] = useReducer(pricingReducer, initialPricingState);
  const [fetchDataTimestamp, setFetchDataTimestamp] = useState<number>();
  const [features, setFeatures] = useState<FeaturesStateType>({});

  const fetchPricingPlan = async () => {
    const plans_PR = await loadTenantPricingPlans(PRODUCT_TYPES.PRIVACY_REQUEST);
    const plans_CC = await loadTenantPricingPlans(PRODUCT_TYPES.COOKIE_CONSENT);
    const convertedPlans = convertArrayToObject<PricingPlansResponseType>([...plans_PR, ...plans_CC], 'planName');

    dispatch({
      type: PricingActionTypes.SET_PRICING_PLANS_DATA,
      payload: convertedPlans,
    });
  };

  const fetchFeature = useCallback(async (featureCode: FeatureCode) => {
    const featureData = await loadFeature(featureCode);

    if (featureData) {
      setFeatures(prev => ({
        ...prev,
        ...getFeatureItem(featureData),
      }));
    }
  }, []);

  const fetchFeatures = useCallback(async (featureCodes: FeatureCode[]) => {
    const featuresData = await Promise.all(featureCodes.map(code => loadFeature(code)));

    setFeatures(prev => ({
      ...prev,
      ...featuresData.reduce((acc, featureData) => {
        if (!featureData) {
          return acc;
        }

        return {
          ...acc,
          ...getFeatureItem(featureData),
        };
      }, {}),
    }));
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      fetchPricingPlan();
    }
  }, [auth, fetchDataTimestamp]);

  return {
    plans,
    isTrial,
    isEnterprise,
    setFetchDataTimestamp,
    features,
    fetchFeature,
    fetchFeatures,
  };
};

export const PricingPlanContainer = createContainer(usePricingPlan);
