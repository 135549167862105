import React, { useCallback, useMemo, useState } from 'react';
import { BigidDropdown, BigidDropdownOption, TertiaryButton } from '@bigid-ui/components';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { getTrendClosedRequests, getTrendClosedRequestsByType, getTrendRejectedRequests } from '../../services/request';
import { SingleColor, RejectedRequestsColors, RequestsByTypeColors } from '../../assets/styles';
import { RegulationType, TrendRequestsTypes, StatisticPeriodValuesType } from '../../types';
import { LineChart } from '../../components/LineChart';
import { BigidChartLegendPosition } from '../../assets/misc';
import { TabsPanel } from '../../components/TabsPanel';
import { simpleSeries, typeSeries, rejectSeries } from '../Dashboard/utils';

import { useStyles } from './ClosedRequestTrendsDashboardStyles';

const closedRequestsSelectOptions = [
  { displayValue: 'Total Requests', id: TrendRequestsTypes.TOTAL.toString(), value: TrendRequestsTypes.TOTAL },
  { displayValue: 'Request Type', id: TrendRequestsTypes.TYPE.toString(), value: TrendRequestsTypes.TYPE },
  { displayValue: 'Declined by reason', id: TrendRequestsTypes.REASON.toString(), value: TrendRequestsTypes.REASON },
];

export const ClosedRequestTrendsDashboard = ({
  regulation = '',
  statisticsPeriod = StatisticPeriodValuesType.week,
}: {
  regulation: RegulationType;
  statisticsPeriod: StatisticPeriodValuesType;
}) => {
  const classes = useStyles();
  const [closedTrends, setClosedTrends] = useState<TrendRequestsTypes>(TrendRequestsTypes.TOTAL);
  const navigate = useNavigate();

  const closedTrendsDropdownValue = useMemo(
    () => closedRequestsSelectOptions.filter(o => o.value === closedTrends),
    [closedTrends],
  );

  const handleClosedTrendsSelect = useCallback((options: BigidDropdownOption[]) => {
    setClosedTrends(options[0].value);
  }, []);

  const fetchRequests = useCallback(
    () => getTrendClosedRequests({ period_days: statisticsPeriod, regulation }),
    [regulation, statisticsPeriod],
  );

  const fetchRequestsByType = useCallback(
    () => getTrendClosedRequestsByType({ period_days: statisticsPeriod, regulation }),
    [regulation, statisticsPeriod],
  );

  const fetchRejectedRequests = useCallback(
    () => getTrendRejectedRequests({ period_days: statisticsPeriod, regulation }),
    [regulation, statisticsPeriod],
  );

  return (
    <div className={classes.trendWrapper}>
      <div className={classes.trendHeader}>
        <h4 className={classes.trendTitle}>
          <TertiaryButton text="Closed Request Trends" onClick={() => navigate('/closed-requests')} size="medium" />
        </h4>
        <Box display="flex">
          <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
            View by
          </Typography>
          <Box>
            <BigidDropdown
              dataAid="closed-requests"
              value={closedTrendsDropdownValue}
              onSelect={handleClosedTrendsSelect}
              options={closedRequestsSelectOptions}
            />
          </Box>
        </Box>
      </div>
      <TabsPanel value={closedTrends} index={TrendRequestsTypes.TOTAL}>
        <LineChart
          fetchData={fetchRequests}
          legend={BigidChartLegendPosition.TOPLEFT}
          colors={SingleColor}
          title="Requests"
          series={simpleSeries}
          name="Total Closed Requests"
        />
      </TabsPanel>
      <TabsPanel value={closedTrends} index={TrendRequestsTypes.TYPE}>
        <LineChart
          fetchData={fetchRequestsByType}
          legend={BigidChartLegendPosition.TOPLEFT}
          colors={RequestsByTypeColors}
          title="Requests"
          series={typeSeries}
        />
      </TabsPanel>
      <TabsPanel value={closedTrends} index={TrendRequestsTypes.REASON}>
        <LineChart
          fetchData={fetchRejectedRequests}
          legend={BigidChartLegendPosition.TOPLEFT}
          colors={RejectedRequestsColors}
          title="Requests"
          series={rejectSeries}
        />
      </TabsPanel>
    </div>
  );
};
