import React, { ReactNode, ReactElement } from 'react';
import { SecondaryButton, BigidTooltip } from '@bigid-ui/components';
import { BigidAdministrationIcon, BigidClearFilledIcon, BigidClockIcon, BigidWarningIcon } from '@bigid-ui/icons';
import { Link } from 'react-router-dom';
import { BigidColorsV2 } from '@bigid-ui/components';

import {
  RequestStatusType,
  RequestType,
  TrendRequestStatusType,
  TrendRequestType,
  RequestRejectReasonType,
  GridRowType,
  ManagePreferencesStatus,
  DatasourcesConnectionStatuses,
  RequestorTypeType,
} from '../types';
import {
  BigidViewIcon,
  BigidEditIcon,
  BigidDeleteIcon,
  BigidPoliciesIcon,
  BigidAdvanceSearchIcon,
  BigidUserIcon,
  BigidInProgressIcon,
  BigidHeaderTasksIcon,
  BigidRulesIcon,
} from '@bigid-ui/icons';

import { BigidMeChartsColors } from '../assets/styles';
import { getDataSourceLogo } from './dataSourceUtils';
import { SystemWrapper } from '../components/SystemWrapper/SystemWrapper';
import { ConsentSystemBadge } from '../components/ConsentSystemBadge';
import { MaskedValue } from '../components/MaskedValue';
import { GetFormattedDateType } from '../types/CommonTypes';
import { StopPropagationContainer } from '../components/StopPropagationContainer';
import { parseHTML } from './commonUtils';
import { Box } from '@mui/material';
import { Loader } from '../components/Loader';
import { NotesDialog } from '../components/notes/NotesDialog';
import { DetailsDialog } from '../components/DetailsDialog';

export const RequestStatusIconsProps = {
  [RequestStatusType.OVERDUE]: {
    color: BigidMeChartsColors[RequestStatusType.OVERDUE],
    label: 'Overdue',
  },
  [RequestStatusType.NA]: {
    color: BigidColorsV2.gray[400],
    label: 'N/A',
  },
  [RequestStatusType.ALMOST_OVERDUE]: {
    color: BigidMeChartsColors[RequestStatusType.ALMOST_OVERDUE],
    label: 'Almost Overdue',
  },
  [RequestStatusType.IN_PROGRESS]: {
    color: BigidMeChartsColors[RequestStatusType.IN_PROGRESS],
    label: 'On Time',
  },
  [RequestType.VIEW]: {
    label: 'View',
  },
  [RequestType.UPDATE]: {
    label: 'Update',
  },
  [RequestType.PREFERENCES]: {
    label: 'Preferences',
  },
  [RequestType.OPT_OUT]: {
    label: `Don't Sell/Share`,
  },
  [RequestType.APPEAL]: {
    label: 'Appeal',
  },
  [RequestType.DELETE]: {
    label: 'Delete',
  },
  [TrendRequestStatusType.OVERDUE]: {
    icon: BigidWarningIcon,
    color: '#FC5C42',
    label: 'Overdue',
  },
  [TrendRequestStatusType.ALMOST_OVERDUE]: {
    icon: BigidClockIcon,
    color: '#FFBD42',
    label: 'Almost Overdue',
  },
  [TrendRequestStatusType.IN_PROGRESS]: {
    icon: BigidInProgressIcon,
    color: '#8ED8B7',
    label: 'On Time',
  },
  [TrendRequestType.VIEW]: {
    icon: BigidViewIcon,
    color: BigidColorsV2.gray[400],
    label: 'View',
  },
  [TrendRequestType.UPDATE]: {
    icon: BigidEditIcon,
    color: BigidColorsV2.gray[400],
    label: 'Update',
  },
  [TrendRequestType.PREFERENCES]: {
    icon: BigidAdvanceSearchIcon,
    color: BigidColorsV2.gray[400],
    label: 'Preferences',
  },
  [TrendRequestType.OPT_OUT]: {
    icon: BigidRulesIcon,
    color: BigidColorsV2.gray[400],
    label: 'Opt Out',
  },
  [TrendRequestType.APPEAL]: {
    icon: BigidPoliciesIcon,
    color: BigidColorsV2.gray[400],
    label: 'Appeal',
  },
  [TrendRequestType.DELETE]: {
    icon: BigidDeleteIcon,
    color: BigidColorsV2.gray[400],
    label: 'Delete',
  },
  [RequestRejectReasonType.AUTO]: {
    color: BigidColorsV2.gray[400],
    label: 'Automatically declined',
  },
  [RequestRejectReasonType.MANUAL]: {
    color: BigidColorsV2.gray[400],
    label: 'Manually declined',
  },
};

export const renderRequestStatus = (status: RequestStatusType) => {
  switch (status) {
    case RequestStatusType.OVERDUE: {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidWarningIcon color="negative" />
          &nbsp;&nbsp;{RequestStatusIconsProps[RequestStatusType.OVERDUE].label}
        </Box>
      );
    }
    case RequestStatusType.ALMOST_OVERDUE: {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidClockIcon color={BigidMeChartsColors[RequestStatusType.ALMOST_OVERDUE]} />
          &nbsp;&nbsp;{RequestStatusIconsProps[RequestStatusType.ALMOST_OVERDUE].label}
        </Box>
      );
    }
    case RequestStatusType.IN_PROGRESS: {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidInProgressIcon color="positive" />
          &nbsp;&nbsp;{RequestStatusIconsProps[RequestStatusType.IN_PROGRESS].label}
        </Box>
      );
    }
    default: {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidInProgressIcon />
          &nbsp;&nbsp;{RequestStatusIconsProps[RequestStatusType.NA].label}
        </Box>
      );
    }
  }
};

export const renderNoteActivityGridCell = (action: string, noteId: string, reloadActivityLogGrid: () => void) => {
  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      {action}
      &nbsp;&nbsp;{renderActivityLogSeeNote(noteId, reloadActivityLogGrid)}
    </Box>
  );
};

export const renderDetailsDialogActivityGridCell = (action: string, noteContent: string) => {
  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      {action}
      &nbsp;&nbsp;{renderActivityLogDetails(noteContent)}
    </Box>
  );
};

export const renderRequestType = (type: RequestType) => {
  switch (type) {
    case RequestType.VIEW: {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidViewIcon />
          &nbsp;&nbsp;{RequestStatusIconsProps[RequestType.VIEW].label}
        </Box>
      );
    }
    case RequestType.UPDATE: {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidEditIcon />
          &nbsp;&nbsp;{RequestStatusIconsProps[RequestType.UPDATE].label}
        </Box>
      );
    }
    case RequestType.PREFERENCES: {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidAdvanceSearchIcon />
          &nbsp;&nbsp;{RequestStatusIconsProps[RequestType.PREFERENCES].label}
        </Box>
      );
    }
    case RequestType.OPT_OUT: {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidRulesIcon />
          &nbsp;&nbsp;{RequestStatusIconsProps[RequestType.OPT_OUT].label}
        </Box>
      );
    }
    case RequestType.DELETE: {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidDeleteIcon />
          &nbsp;&nbsp;{RequestStatusIconsProps[RequestType.DELETE].label}
        </Box>
      );
    }
    case RequestType.APPEAL: {
      return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidPoliciesIcon />
          &nbsp;&nbsp;{RequestStatusIconsProps[RequestType.APPEAL].label}
        </Box>
      );
    }
  }
};

export const renderRequestorType = (type: RequestorTypeType) => {
  switch (type) {
    case RequestorTypeType.SELF: {
      return 'Data Subject';
    }
    case RequestorTypeType.AUTHORIZED_AGENT: {
      return 'Authorized Agent';
    }
  }
};

export const renderRequestorId = (requestorId: string, newMessages: boolean) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {requestorId}
      {newMessages && (
        <span
          style={{
            backgroundColor: BigidColorsV2.red[500],
            borderRadius: 17,
            width: 8,
            height: 8,
            position: 'relative',
            top: 1,
            marginLeft: 5,
          }}
        />
      )}
    </div>
  );
};

export const renderUserNameOrSystem = (userName: 'System' | string, isOwner: boolean) => (
  <div style={{ display: 'flex' }}>
    {userName === 'System' ? <BigidAdministrationIcon /> : <BigidUserIcon />}
    <span style={{ marginLeft: 10 }}>{`${userName}${isOwner ? ' (owner)' : ''}`}</span>
  </div>
);

export const renderUserRequestAttribute = (value: string, masked: boolean) => {
  return masked ? <MaskedValue value="*****" originalValue={value} /> : value;
};

export const formatDataSource = (name: string, type: string, bold = false) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <img style={{ maxWidth: 35, marginRight: 15 }} src={getDataSourceLogo(type)} alt="" />{' '}
    <div style={{ whiteSpace: 'normal' }}>{bold ? <b style={{ fontSize: '1rem' }}>{name}</b> : name}</div>
  </div>
);

export const tooltipifyEntry = (entries: string[] = [], availableWidth?: number, emptyListLabel = 'N/A') => {
  let showLength = 1;
  if (availableWidth && entries && entries.length > 1) {
    while (entries[showLength - 1] && availableWidth - entries[showLength - 1].length > 0) {
      availableWidth -= entries[showLength - 1].length;
      showLength++;
    }
  }
  return (
    <>
      {!entries.length && emptyListLabel}
      {entries.length === 1 && entries[0]}
      {entries.length > 1 && (
        <>
          {entries.slice(0, showLength).join(', ')}{' '}
          {entries.length > showLength && (
            <BigidTooltip title={entries.slice(showLength).join('\n')}>
              <b>+{entries.length - showLength}</b>
            </BigidTooltip>
          )}
        </>
      )}
    </>
  );
};

const COLORS_BY_STATUS_MAP = {
  [RequestStatusType.OVERDUE]: '#FADAD4',
  [RequestStatusType.ALMOST_OVERDUE]: '#FBEDD3',
  [RequestStatusType.IN_PROGRESS]: '#D0EEE0',
  [RequestStatusType.NA]: BigidColorsV2.gray[400],
};

export const renderActualDays = (row: GridRowType) => (
  <span style={{ backgroundColor: COLORS_BY_STATUS_MAP[row.status], padding: '4px 8px', borderRadius: 2 }}>
    {row.responseActualDays}
  </span>
);

export const renderDeadlineDays = (row: GridRowType) => (
  <span style={{ backgroundColor: COLORS_BY_STATUS_MAP[row.status], padding: '4px 8px', borderRadius: 2 }}>
    {row.responseDeadlineDays}
  </span>
);

export const renderRequestClosingTime = (row: GridRowType, getFormattedDate: GetFormattedDateType) => (
  <>
    {row.closeDate
      ? `${getFormattedDate(row.closeDate, {
          hour: 'numeric',
          minute: 'numeric',
        })}`
      : ''}
    {row.expired ? (
      <span style={{ backgroundColor: '#F0F0F0', padding: '4px 8px', marginLeft: 8 }}>Expired</span>
    ) : null}
  </>
);

type SystemCellProps = {
  stagedForDeletion?: boolean;
  updateRequired?: boolean;
  connectionStatus?: DatasourcesConnectionStatuses;
  content: ReactNode;
  name?: string;
};

export const renderSystemCell = ({
  stagedForDeletion,
  connectionStatus,
  updateRequired,
  content,
  name,
}: SystemCellProps) => (
  <SystemWrapper
    stagedForDeletion={stagedForDeletion}
    connectionStatus={connectionStatus}
    updateRequired={updateRequired}
    name={name}
  >
    <>{content}</>
  </SystemWrapper>
);

const renderConsentCellWithTooltip = (tooltipContent: ReactNode, children: ReactElement) => {
  return (
    <BigidTooltip
      title={
        <span style={{ display: 'block', width: 200, fontWeight: 'normal', color: 'inherit' }}>{tooltipContent}</span>
      }
    >
      {children}
    </BigidTooltip>
  );
};

const renderConsentStatusIndicator = (
  id: number,
  status: ManagePreferencesStatus,
  systemId: number,
  onRetry?: (rowId: number) => void,
) => {
  if (status === ManagePreferencesStatus.IN_PROGRESS) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: 20, height: 20, marginRight: 8 }}>
          <Loader label="" color={BigidColorsV2.purple[600]} size={20} thickness={2} position="static" />
        </div>
        <span>In Progress</span>
      </div>
    );
  }

  if (status === ManagePreferencesStatus.UPDATE_FAILED || status === ManagePreferencesStatus.CONNECTION_ERROR) {
    return renderConsentCellWithTooltip(
      <span>
        The update could not be completed. Try again or update manually. Also please verify your{' '}
        <Link to={`systems/${systemId}`}>system credentials</Link>
      </span>,
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <BigidClearFilledIcon color="negative" />
          &nbsp;&nbsp;Update Failed
        </Box>
        {onRetry && (
          <span
            onClick={e => {
              e.stopPropagation();
              onRetry(id);
            }}
            style={{ marginLeft: 8, textDecoration: 'underline', cursor: 'pointer', fontWeight: 600 }}
          >
            Retry
          </span>
        )}
      </div>,
    );
  }

  if (status === ManagePreferencesStatus.USER_NOT_FOUND) {
    return renderConsentCellWithTooltip(
      'We could not locate this user in this system.',
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <BigidClearFilledIcon color="negative" />
        &nbsp;&nbsp;User not Found
      </Box>,
    );
  }

  if (status === ManagePreferencesStatus.COMPLETED) {
    return (
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <BigidHeaderTasksIcon color="positive" />
        &nbsp;&nbsp;Completed
      </Box>
    );
  }

  return <span data-aid="preferences-request-status-unknown">-</span>;
};

export const renderManagePreferencesStatus = (
  id: number,
  status: ManagePreferencesStatus,
  systemId: number,
  onRetry?: (rowId: number) => void,
) => {
  const statusIndicator = renderConsentStatusIndicator(id, status, systemId, onRetry);

  return <span data-aid={`consent-${id}-status-${status}`}>{statusIndicator}</span>;
};

export const renderSystemBadge = (dataSourceName?: string, dataSourceType?: string) =>
  dataSourceName && dataSourceType ? <ConsentSystemBadge name={dataSourceName} type={dataSourceType} /> : null;

export const renderConsentCompletionButton = (
  id: number,
  status: ManagePreferencesStatus,
  onClick: (id: number[]) => void,
) =>
  status !== ManagePreferencesStatus.COMPLETED ? (
    <SecondaryButton
      size="medium"
      text="Mark completed"
      onClick={event => {
        event?.stopPropagation();
        onClick([id]);
      }}
    />
  ) : null;

export const renderConsentType = (consentType: string) => <div>{parseHTML(consentType)}</div>;

export const renderNotesCell = (row: GridRowType, showNotes: boolean) => {
  const { noteFilesCount, notesCount } = row;

  return (
    <StopPropagationContainer>
      {notesCount > 0 && showNotes && (
        <>
          <NotesDialog requestId={row.id} notesCount={notesCount} noteFilesCount={noteFilesCount} readOnly />
        </>
      )}
    </StopPropagationContainer>
  );
};

export const renderActivityLogSeeNote = (noteId: string, reloadActivityLogGrid: () => void) => {
  return (
    <StopPropagationContainer>
      <NotesDialog isViewNote noteId={noteId} onDialogClose={reloadActivityLogGrid} />
    </StopPropagationContainer>
  );
};

export const renderActivityLogDetails = (content: string) => {
  return (
    <StopPropagationContainer>
      <DetailsDialog content={content} />
    </StopPropagationContainer>
  );
};
