import { styled } from '@mui/material/styles';
import { primaryBorder } from '../../../assets/styles';

const PREFIX = 'StageEmailCustomization';

export const classes = {
  root: `${PREFIX}-root`,
  toastHeader: `${PREFIX}-toastHeader`,
  toastDescription: `${PREFIX}-toastDescription`,
  purpleTitle: `${PREFIX}-purpleTitle`,
  input: `${PREFIX}-input`,
  inputWrapper: `${PREFIX}-inputWrapper`,
  controls: `${PREFIX}-controls`,
  marginLeft: `${PREFIX}-marginLeft`,
  progressBarText: `${PREFIX}-progressBarText`,
  closingResolutionName: `${PREFIX}-closingResolutionName`,
  resolutionCode: `${PREFIX}-resolutionCode`,
  activityLogMessage: `${PREFIX}-activityLogMessage`,
  accordion: `${PREFIX}-accordion`,
  exampleDialogRoot: `${PREFIX}-exampleDialogRoot`,
  exampleDialog: `${PREFIX}-exampleDialog`,
  exampleDialogHeader: `${PREFIX}-exampleDialogHeader`,
  exampleDialogBody: `${PREFIX}-exampleDialogBody`,
  exampleDialogButtons: `${PREFIX}-exampleDialogButtons`,
  exampleDialogCloseButton: `${PREFIX}-exampleDialogCloseButton`,
  exampleDialogTitle: `${PREFIX}-exampleDialogTitle`,
  inputTooltipName: `${PREFIX}-inputTooltipName`,
  inputTooltipBar: `${PREFIX}-inputTooltipBar`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: '20px 30px',
    maxHeight: 'calc(100vh - 122px)',
    overflowY: 'auto',
  },
  [`& .${classes.toastHeader}`]: {
    fontWeight: 700,
  },
  [`& .${classes.accordion}`]: {
    boxShadow: 'none !important',
    border: 'none !important',
  },
  [`& .${classes.toastDescription}`]: {
    maxWidth: 450,
  },
  [`& .${classes.purpleTitle}`]: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: theme.vars.palette.bigid?.primary500,
  },
  [`& .${classes.input}`]: {
    width: 80,
    margin: '0 8px',
  },
  [`& .${classes.inputWrapper}`]: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 14,
  },
  [`& .${classes.controls}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px 0 5px',
    borderBottom: primaryBorder,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.vars.palette.bigid?.white,
    zIndex: 10,
  },
  [`& .${classes.marginLeft}`]: {
    marginLeft: -5,
  },
  [`& .${classes.progressBarText} > button`]: {
    position: 'absolute',
    left: 117,
    top: -6,
    height: 25,
    zIndex: 9,
  },
  [`& .${classes.closingResolutionName} > button`]: {
    position: 'absolute',
    left: 175,
    top: -6,
    height: 25,
    zIndex: 9,
  },
  [`& .${classes.resolutionCode} > button`]: {
    position: 'absolute',
    left: 117,
    top: -6,
    height: 25,
    zIndex: 9,
  },
  [`& .${classes.activityLogMessage} > button`]: {
    position: 'absolute',
    left: 151,
    top: -6,
    height: 25,
    zIndex: 9,
  },
  [`& .${classes.exampleDialogRoot}`]: {
    position: 'fixed',
    width: '684px',
    right: '608px',
    height: '100%',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 10,
  },
  [`& .${classes.exampleDialog}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.vars.palette.bigid?.white,
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    width: '100%',
    flexDirection: 'column',
    padding: 18,
  },
  [`& .${classes.exampleDialogHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '56px',
    borderBottom: `1px solid ${theme.vars.palette.bigid?.gray300}`,
    width: '100%',
    position: 'relative',
    marginTop: -16,
  },
  [`& .${classes.exampleDialogCloseButton}`]: {
    position: 'absolute',
    right: -10,
    top: 10,
    color: theme.vars.palette.bigid?.gray500,

    '& svg': {
      width: 15,
      height: 15,
    },
  },
  [`& .${classes.exampleDialogTitle}`]: {
    margin: 0,
    fontSize: '1rem',
    fontWeight: 600,
    width: '100%',
  },
  [`& .${classes.exampleDialogBody}`]: {
    padding: '15px 0px 20px',
  },
  [`& .${classes.exampleDialogButtons}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    width: '100%',
  },
  [`& .${classes.inputTooltipName}`]: {
    position: 'absolute',
    top: -4,
    left: 174,
  },
  [`& .${classes.inputTooltipBar}`]: {
    position: 'absolute',
    top: -4,
    left: 115,
  },
}));

export const StyledWarningList = styled('ul')`
  padding-left: 24px;
  margin: 0;
`;
