import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    color: theme.vars.palette.bigid?.gray700,
  },
  text: {
    margin: '20px 0',
    fontSize: '1rem',
    fontWeight: 600,
  },
  dropdown: {
    width: 270,
  },
  regionLabel: {
    margin: '44px 0 30px',
    fontSize: '0.9375rem',
  },
  iso: {
    padding: '0 8px',
    width: 175,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8125rem',
  },
  regions: {
    display: 'flex',
    flexWrap: 'wrap',
    '& label': {
      width: '30%',
      padding: 10,
    },
  },
}));
