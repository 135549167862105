import React from 'react';

import { useStyles } from './SidebarStyles';

export const ActivateLabel = () => {
  const classes = useStyles();

  return (
    <span className={classes.activateLabel} data-aid="activate-label">
      Activate
    </span>
  );
};
