import makeStyles from '@mui/styles/makeStyles';
import { paperBoxShadow } from '../../../assets/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: 558,
    width: 1060,
    borderRadius: 12,
    overflow: 'hidden',
    margin: '0 auto',
    boxShadow: paperBoxShadow,
    background: theme.vars.palette.bigid?.white,
  },
  purpleLogo: {
    position: 'absolute',
    top: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  layout: {
    textAlign: 'center',
    '& div': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: -2,
    },
    '& span:nth-child(1)': {
      paddingLeft: 6,
    },
    '& span:nth-child(2)': {
      paddingLeft: 82,
    },
    '& span:nth-child(3)': {
      paddingLeft: 88,
    },
  },
  button: {
    margin: '55px auto 0',
    padding: '3px 69px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    paddingTop: 106,
    fontSize: '1.75rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  text: {
    padding: '14px 0 36px',
    fontSize: '1.25rem',
    textAlign: 'center',
  },
}));
