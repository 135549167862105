import {
  BigidButtonIcon,
  BigidDialog,
  BigidRadioGroup,
  BigidSearch,
  BigidTextField,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { GridReducerActionTypes } from '../gridReducer';
import { Box } from '@mui/material';
import { postExportRequestsCSV } from '../../../services/request';
import { BigidExportIcon } from '@bigid-ui/icons';
import { Stack } from '@mui/system';
import React, { FC, useState } from 'react';
import { RequestManagerGridContainer } from '../useRequestManagerGrid';
import { RequestManagerStateContainer } from '../useRequestManagerState';
import { RequestManagerViewsContainer } from '../useRequestManagerViews';

enum SaveType {
  CURRENT = 'current',
  NEW = 'new',
}

export const RequestManagerGridControls: FC = () => {
  const {
    dispatch,
    nextGridState,
    getSelectedColumns,
    isViewChanged,
    onResetView,
    search,
    requestsFilters,
    getRequestsCountForCurrentView,
  } = RequestManagerGridContainer.useContainer();
  const { handleAddNewView, handleUpdateCurrentView, isToolbarBusy } = RequestManagerViewsContainer.useContainer();
  const { requestManagerStateActions } = RequestManagerStateContainer.useContainer();

  const [saveViewDialog, setSaveViewDialog] = useState<boolean>(false);
  const [newViewName, setNewViewName] = useState<string>('');
  const [saveType, setSaveType] = useState(SaveType.CURRENT);

  return (
    <Stack flexDirection="row" alignItems="center">
      <BigidSearch
        onSubmit={() => {
          dispatch({
            type: GridReducerActionTypes.SET_SEARCH_TEXT,
            payload: '',
          });
        }}
        value={search}
        onChange={value => {
          dispatch({
            type: GridReducerActionTypes.SET_SEARCH_TEXT,
            payload: value,
          });
        }}
        placeholder="Search"
      />
      {requestManagerStateActions.EXPORT && (
        <>
          <Box marginLeft={1}>
            <BigidButtonIcon
              icon={BigidExportIcon}
              dataAid="export_button"
              onClick={async () => {
                postExportRequestsCSV({
                  fileFormat: 'csv',
                  filter: requestsFilters,
                  sort: nextGridState?.sort,
                  search,
                  selectedColumns: getSelectedColumns,
                });
                return Promise.resolve({});
              }}
            />
          </Box>
        </>
      )}
      {isViewChanged && !isToolbarBusy && (
        <Box position="absolute" right={16} top={16}>
          <TertiaryButton onClick={onResetView} dataAid="reset-view" size="medium" text="Reset" />
          <SecondaryButton text="Save View" dataAid="save-view" onClick={() => setSaveViewDialog(true)} size="medium" />
        </Box>
      )}
      <BigidDialog
        isOpen={saveViewDialog}
        onClose={() => setSaveViewDialog(false)}
        title="Save View"
        maxWidth="xs"
        buttons={[
          {
            text: 'Cancel',
            onClick: () => setSaveViewDialog(false),
            component: SecondaryButton,
          },
          {
            text: 'Save',
            onClick: () => {
              if (saveType === SaveType.NEW) {
                getRequestsCountForCurrentView();
                handleAddNewView(newViewName);
              } else {
                handleUpdateCurrentView();
              }
              setSaveViewDialog(false);
            },
            component: PrimaryButton,
            disabled: saveType === SaveType.NEW && !newViewName,
            dataAid: 'save-button',
          },
        ]}
      >
        <Box mb={2}>
          <BigidRadioGroup
            options={[
              { value: SaveType.CURRENT, label: 'Update current view', dataAid: 'option-update' },
              { value: SaveType.NEW, label: 'Save as new view', dataAid: 'option-save' },
            ]}
            onChange={e => setSaveType(e.target.value as SaveType)}
            value={saveType}
            horizontal
          />
        </Box>
        {saveType === SaveType.NEW && (
          <BigidTextField
            onChange={event => setNewViewName(event.target.value)}
            label="View Name"
            placeholder="Enter View´s Name"
          />
        )}
      </BigidDialog>
    </Stack>
  );
};
