export type TutorialStateType = {
  tutorialEnabled: boolean;
  contactLinkSet?: boolean;
  regulationSelected?: boolean;
  privacyCenterEdited?: boolean;
  privacyCenterPublished?: boolean;
  systemAdded?: boolean;
  agentConnected?: boolean;
  agentUserProfilesMapped?: boolean;
  onboardingFinished?: boolean;
  consentsAutomated?: boolean;
};

export enum TutorialStepsType {
  ONBOARDING,
  EDIT_PRIVACY_CENTER,
  PUBLISH_PRIVACY_CENTER,
  SYSTEM,
  DEEP_DISCOVERY,
  MAP_PROFILES,
  AUTOMATE_CONSENT,
}
