import React, { FC } from 'react';
import { AppealGuidedTourStepTemplate } from './AppealGuidedTourStepTemplate';

interface UseCasesAccordionAppealBodyStepProps {
  dataAid?: string;
}

export const UseCasesAccordionAppealBodyStep: FC<UseCasesAccordionAppealBodyStepProps> = ({ dataAid }) => {
  return (
    <AppealGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Customize tile"
      firstSectionText="Specify the Appeal tile's title and description, and then click next"
    />
  );
};
