import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    '& svg': {
      cursor: 'pointer',
    },
    '& > div': {
      zIndex: 'inherit',
    },
  },
  logoLink: ({ logoClickable }: { logoClickable?: boolean }) => ({
    ...(!logoClickable ? { pointerEvents: 'none' } : {}),
  }),
});
