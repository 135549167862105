import { PricingPlansResponseType } from '../types';
import { ADDITIONAL_PRICING_PLAN, PRICING_PLAN } from '../types/PricingPlanTypes';
import { sanitize } from 'dompurify';
import parse from 'html-react-parser';

// Routes that don't need to be authorized
export enum PUBLIC_ROUTES {
  EMAIL_VERIFICATION = '/registration/email-verification/:onboarding_token',
}

// Route matcher outside <Router />, where its methods are not available
export const externalRouteMatcher = (routes: string[]) => {
  const { hash } = window.location;
  return routes.some(route => {
    const routeRegExp = new RegExp(route.replace(/\/||:[^/]+/g, '/[^/]*'));
    return routeRegExp.test(hash);
  });
};

export const objectToQueryString = (params: Record<string, string>) => new URLSearchParams(params).toString();

export const queryStringToObject = (queryString: string) => {
  const urlParams = new URLSearchParams(queryString).entries();
  const queryParamsObject: Record<string, string> = {};

  for (const [name, value] of urlParams) {
    queryParamsObject[name] = value;
  }

  return queryParamsObject;
};

export const mergeBIntoAByP = <T>(a: T[], b: T[], p: keyof T) =>
  a.filter(aa => !b.find(bb => aa[p] === bb[p])).concat(b);

// needed to be able to mock window.location.origin in cypress
window.getOrigin = () => window.location.origin;

export const isSmallIdApp = () => window.getOrigin().indexOf('bigid.me') != -1;
export const BRAND_NAME = isSmallIdApp() ? 'SmallID' : 'Privacy Portal';

export const getFullPageTitle = (pageTitle: string) => `${BRAND_NAME} ${pageTitle}`;

export const maskValueForPageTitle = (value: string) => `${value.substring(0, 3)}***`;

export function convertArrayToObject<T>(
  array: PricingPlansResponseType[],
  key: keyof PricingPlansResponseType,
): Record<string, T> {
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key] as string]: item,
    };
  }, {});
}

export const getPurePlanName = (planName: PRICING_PLAN | ADDITIONAL_PRICING_PLAN | 'FREE') =>
  planName.slice(planName.indexOf('_') + 1);

export const isCookieConsentProduct = (planName: PRICING_PLAN | ADDITIONAL_PRICING_PLAN) => planName.includes('CC_');

export const getPlanPickedFullName = (planName: PRICING_PLAN | ADDITIONAL_PRICING_PLAN) =>
  isCookieConsentProduct(planName) ? `Cookies Consent - ${planName}` : `Data Rights Requests - ${planName}`;

export const formatPrice = (price: number) => new Intl.NumberFormat('en-EN').format(price);

export const parseHTML = (html: string) => parse(sanitize(html));
