import React, { FC } from 'react';
import { AuthorizedAgentGuidedTourStepTemplate } from './AuthorizedAgentGuidedTourStepTemplate';

interface AllowAgentStepProps {
  dataAid?: string;
}

export const AllowAgentStep: FC<AllowAgentStepProps> = ({ dataAid }) => {
  return (
    <AuthorizedAgentGuidedTourStepTemplate
      stepIndex={1}
      width={490}
      dataAid={dataAid}
      title="Select request types"
      firstSectionText='Toggle on to activate Authorized Agent for the selected request type(s). If off, "Submit a request for" will not be shown.'
    />
  );
};
