import React from 'react';
import WorkflowSettings from '../WorkflowSettings';
import { WorkflowCustomizationContainer } from '../hooks/useWorkflowCustomization';
import { RequestCustomizationContainer } from '../hooks/useRequestCustomization';
import { WorkflowSettingsDataContainer } from '../hooks/useWorkflowSettingsData';
import { AllowOutsideRequestorsContainer } from '../hooks/useAllowOutsideRequestors';
import { DueDateNotificationsContainer } from '../hooks/useDueDateNotifications';

export const WorkflowSettingsContainerComponent = () => (
  <WorkflowCustomizationContainer.Provider>
    <RequestCustomizationContainer.Provider>
      <WorkflowSettingsDataContainer.Provider>
        <AllowOutsideRequestorsContainer.Provider>
          <DueDateNotificationsContainer.Provider>
            <WorkflowSettings />
          </DueDateNotificationsContainer.Provider>
        </AllowOutsideRequestorsContainer.Provider>
      </WorkflowSettingsDataContainer.Provider>
    </RequestCustomizationContainer.Provider>
  </WorkflowCustomizationContainer.Provider>
);
