import { useState, useCallback, useMemo } from 'react';
import {
  addRequestNote,
  deleteRequestNote,
  getRequestNotes,
  updateRequestNote,
  uploadFileNote,
} from '../../../services/request';
import { FileType, NoteUpdateType } from '../../../types';
import { useFetch } from '../../../hooks/useFetch';
import { useFilesUpload, useToggle } from '../../../hooks';
import { useRefreshNotesEvent } from '../useRefreshNotesEvent';
import { delay, noop } from 'lodash';
import {
  AdvancedToolbarOverrideValueStrategy,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
} from '@bigid-ui/components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeOptionalAllowedSymbolsValidation } from '../../../utils';

export const NOTE_DEFAULT_MAX_SYMBOLS = 500;

const useNotesDialog = (requestId?: string, readOnly?: boolean) => {
  const { value: isOpenModal, setTrue: openModal, setFalse: closeModal } = useToggle(false);
  const [disableSaving, setDisableSaving] = useState(false);

  const formikNewNote = useFormik({
    initialValues: {
      content: '',
      files: [],
    },
    onSubmit: noop,
    validationSchema: () =>
      Yup.object({
        content: makeOptionalAllowedSymbolsValidation(),
      }),
  });

  const {
    data: { notes, currentStage, possibleStages },
    loadData: loadNotesData,
    loading: notesLoading,
    setData: setNotesData,
  } = useFetch({
    fetchApi: () => getRequestNotes(requestId),
    permission: readOnly ? !!requestId && isOpenModal : !!requestId,
    initValue: {
      notes: [],
      currentStage: '',
      possibleStages: [],
    },
  });

  const { files, onFileUpload, removeFile, resetFiles, uploading } = useFilesUpload({
    upload: uploadFileNote,
  });

  const resetNote = useCallback(() => {
    formikNewNote.resetForm();
  }, [formikNewNote]);

  const onChangeNewNote = (value: string) => {
    if (value.length > NOTE_DEFAULT_MAX_SYMBOLS) return;
    formikNewNote.setFieldValue('content', value);
  };

  const onSaveNote = useCallback(async () => {
    if (requestId && formikNewNote.values.content) {
      try {
        setDisableSaving(true);
        const note = await addRequestNote(requestId, {
          content: formikNewNote.values?.content,
          files: files.map(({ id }) => ({ fileUploadId: id })),
        });
        setNotesData(prev => ({
          ...prev,
          notes: [note, ...prev.notes],
        }));
        resetNote();
        resetFiles();
      } catch (error) {
        console.log(error);
      } finally {
        setDisableSaving(false);
      }
    }
  }, [requestId, formikNewNote.values.content, files, setNotesData, resetNote, resetFiles]);

  const onEditNote = useCallback(
    async (note: NoteUpdateType) => {
      if (requestId && note?.content) {
        try {
          setDisableSaving(true);
          const editedNote = await updateRequestNote(note);
          setNotesData(prev => {
            const updatedNotes = prev.notes.map(n => (n.id === note.id ? editedNote : n));
            return {
              ...prev,
              notes: updatedNotes,
            };
          });

          resetNote();
          resetFiles();
          return Promise.resolve();
        } catch (error) {
          console.log(error);
        } finally {
          setDisableSaving(false);
        }
      }
    },
    [requestId, setNotesData, resetNote, resetFiles],
  );

  const onDeleteNote = useCallback(
    async (id: string) => {
      await deleteRequestNote(id);
      setNotesData(prev => {
        return {
          ...prev,
          notes: prev.notes.filter(note => note.id !== id),
        };
      });
    },
    [setNotesData],
  );

  const [fileToRemove, setFileToRemove] = useState<FileType>();

  const onRemoveFileContinue = async () => {
    fileToRemove && removeFile(fileToRemove.id);
    setFileToRemove(undefined);
  };

  const onFileRemove = (file: FileType) => {
    setFileToRemove(file);
  };

  const handleFileUpload = async (fileName: string, file: File) => {
    try {
      setDisableSaving(true);
      await onFileUpload([file]);
    } catch (error) {
      console.log(error);
    } finally {
      setDisableSaving(false);
    }
  };

  const onCloseModal = () => {
    resetFiles();
    resetNote();
    closeModal();
  };

  useRefreshNotesEvent(() => {
    delay(loadNotesData, 300); // should wait create/delete new datasource note
  });

  const filters: BigidAdvancedToolbarFilterUnion[] = [
    {
      id: '1',
      title: 'Stage',
      field: 'Stage',
      operator: 'in',
      type: 0,
      isSearchable: false,
      options: (possibleStages || []).map(stage => ({ id: stage, value: stage, displayValue: stage })),
      shouldNotReloadOnChange: true,
      dataAid: 'stage-filter',
    },
  ];

  const filterSelectedOptionsConfig = useMemo(
    () => ({
      strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITHOUT_RELOAD_OVERRIDE,
      values: [
        {
          id: '1',
          type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
          options:
            currentStage && readOnly
              ? [{ displayValue: currentStage, value: currentStage, id: currentStage, isSelected: true }]
              : [],
        },
      ],
    }),
    [currentStage, readOnly],
  );

  const onResetFilters = () => {
    setNotesData(prev => ({
      ...prev,
      currentStage: '',
    }));
  };

  return {
    formikNewNote,
    isOpenModal,
    openModal,
    notes,
    onSaveNote,
    onEditNote,
    onDeleteNote,
    files,
    onCloseModal,
    onFileRemove,
    onRemoveFileContinue,
    handleFileUpload,
    onChangeNewNote,
    fileToRemove,
    setFileToRemove,
    resetNote,
    notesLoading,
    disableSaving,
    uploading,
    filters,
    filterSelectedOptionsConfig,
    onResetFilters,
  };
};

export default useNotesDialog;
