import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '1rem',
    color: theme.vars.palette.bigid?.gray700,
  },
  text: {
    margin: '20px 0',
    fontWeight: 600,
  },
  expert: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8125rem',
    lineHeight: 2,

    '& svg': {
      marginTop: -25,
      marginRight: 10,
    },
  },
  wrapper: {
    display: 'flex',
    marginBottom: 30,
    flexWrap: 'wrap',

    ['@media (max-width: 1180px)']: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      justifyItems: 'center',
      width: 430,
      margin: '0 auto 20px auto',
    },
  },
}));
