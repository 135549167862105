import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Menu, styled } from '@mui/material';
import { map, noop } from 'lodash';
import { BigidDropdownOption, PrimaryButton, SecondaryButton, TertiaryButton } from '@bigid-ui/components';
import { BigidMeFilterType } from '../MeFilterToolbar';
import { BigidDropdownListOption } from '@bigid-ui/components/lib/BigidDropdown/BigidDropdownListOption';
import { BigidDropdownBarProps } from '@bigid-ui/components/lib/BigidDropdown/BigidDropdownBar';
import { DropdownFilterBar } from '@bigid-ui/components/lib/BigidFilterBar/Dropdown/DropdownFilterBar';
import { BigidDropdownListSearchBar } from '@bigid-ui/components/lib/BigidDropdown/BigidDropdownListSearchBar';
import {
  getOptionsSelectionMap,
  getToggledOptionsSet,
} from '@bigid-ui/components/lib/BigidDropdown/BigidDropdownUtils';
import { BigidFilterOptionType } from '@bigid-ui/components/lib/BigidFilterItem/BigidFilterTypes';

const dropdownOptionsToFilterOptions = (options: BigidDropdownOption[]): BigidFilterOptionType[] =>
  options.map(o => ({
    ...o,
    label: o.displayValue,
    isSelected: o.isSelected || false,
    children: o.children ? dropdownOptionsToFilterOptions(o.children) : undefined,
  }));

const filterOptionsToDropdownOptions = (options: BigidFilterOptionType[]): BigidDropdownOption[] =>
  options.map(o => ({
    ...o,
    id: (o.id || o.value) as string,
    displayValue: o.label,
    children: o.children ? filterOptionsToDropdownOptions(o.children) : undefined,
  }));

const FilterStyleOverrides = styled('div')({
  '& [data-aid=CloseIcon]': {
    display: 'none',
  },
});

interface BigidMeFilterProps extends BigidMeFilterType {
  onApply: (filter: BigidMeFilterType) => void;
  showCounter?: boolean;
}

export const MeFilter = ({
  options,
  field,
  operator,
  title,
  onApply,
  showSearch,
  showSelectAll,
}: BigidMeFilterProps) => {
  const [dropdownOptions, setDropdownOptions] = useState<BigidDropdownOption[]>(
    filterOptionsToDropdownOptions(options),
  );
  const [searchValue, setSearchValue] = useState('');
  const [isOpen, setOpen] = useState(false);

  const anchorElRef = useRef<HTMLDivElement>(null);

  const selectedValueMap = new Map();

  dropdownOptions.forEach(option => {
    if (option.isSelected) selectedValueMap.set(option.id, option);
  });

  const optionsSelectionMap = getOptionsSelectionMap(selectedValueMap, dropdownOptions, true);
  const optionsToggledSet = getToggledOptionsSet(optionsSelectionMap);

  useEffect(() => {
    setDropdownOptions(filterOptionsToDropdownOptions(options));
  }, [options]);

  const handleBarClick = () => {
    setOpen(s => !s);
  };

  const handleClose = () => {
    setSearchValue('');
    setOpen(false);
    setDropdownOptions(filterOptionsToDropdownOptions(options));
  };

  const handleApply = () => {
    onApply({
      field,
      title,
      operator,
      showSearch,
      showSelectAll,
      options: dropdownOptionsToFilterOptions(dropdownOptions),
      value: dropdownOptions.map(({ isSelected, value }) => isSelected && value).filter(Boolean),
    });

    setSearchValue('');
    setOpen(false);
  };

  const handleCheckboxChange = (option: BigidDropdownOption) => {
    const newFiltersOptions = dropdownOptions.map(item =>
      option.value === item.value
        ? {
            ...item,
            isSelected: !item.isSelected,
          }
        : item,
    );
    setDropdownOptions(newFiltersOptions);
  };

  const handleToggleAll = (isSelected: boolean) => {
    setDropdownOptions(dropdownOptions.map(option => ({ ...option, isSelected })));
  };

  const searchedDropdownOptions = useMemo(
    () =>
      dropdownOptions.filter(option =>
        option.displayValue.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
      ),
    [dropdownOptions, searchValue],
  );

  const barComponentProps: BigidDropdownBarProps = {
    dataAid: 'dynamicFiltersBar',
    query: searchValue,
    displayValue: '',
    isSearchable: true,
    popperAnchorElRef: anchorElRef,
    isInputFocused: isOpen,
    isPopperOpen: isOpen,
    onInputFocus: handleBarClick,
    onInputChange: noop,
    onInputClearAll: noop,
    isFetchingOptionsAsync: false,
    isCreatingNewOption: false,
    selectedOptionsCount: selectedValueMap.size,
    filterProps: {
      title,
      field,
      onClose: handleClose,
      selectedOptions: [...selectedValueMap.values()].map(o => o.displayValue).join(', '),
    },
    areOptionsOpen: isOpen,
    firstSelectedValue: selectedValueMap.values().next().value,
    value: [...selectedValueMap.values()],
    onListChipDelete: noop,
    isMulti: true,
  };

  return (
    <FilterStyleOverrides>
      <DropdownFilterBar {...barComponentProps} />
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorElRef.current}
        open={isOpen}
        onClose={handleClose}
        sx={{
          '& > .MuiPaper-root': {
            marginTop: 1,
          },
        }}
      >
        <Box padding="4px 12px 4px 12px">
          <BigidDropdownListSearchBar
            dataAid="dynamicFilterSearch"
            query={searchValue}
            onListSearchChange={e => setSearchValue(e.target.value)}
          />
        </Box>
        {showSelectAll && (
          <Box padding="4px 12px">
            <TertiaryButton
              size="small"
              text="Select All"
              dataAid="filters-clear-all"
              margin="0 2px"
              onClick={() => handleToggleAll(true)}
            />
            <TertiaryButton
              size="small"
              text="Clear All"
              dataAid="filters-clear-all"
              margin="0 2px"
              onClick={() => handleToggleAll(false)}
            />
          </Box>
        )}
        {map(searchedDropdownOptions, (option, key) => (
          <BigidDropdownListOption
            key={key}
            dataAid="dynamicDropdownListOption"
            onListOptionSelect={handleCheckboxChange}
            onListOptionToggle={noop}
            option={option}
            optionsSelectionMap={optionsSelectionMap}
            optionsToggledSet={optionsToggledSet}
            isMulti
          />
        ))}
        <Box
          sx={theme => ({
            borderTop: `1px solid ${theme.vars.palette.bigid?.gray400}`,
            padding: '8px 16px 0px 16px',
            display: 'flex',
            justifyContent: 'space-between',
          })}
        >
          <SecondaryButton size="small" onClick={handleClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="small" onClick={handleApply}>
            Apply
          </PrimaryButton>
        </Box>
      </Menu>
    </FilterStyleOverrides>
  );
};
