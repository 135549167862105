import React, { useMemo, FC } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { TutorialStepsType } from '../../types/TutorialTypes';
import { TutorialStep } from './TutorialStep';
import { TUTORIAL_STEPS } from './config';

import { useStyles } from './TutorialStyles';

type ChecklistPopoverType = {
  completionStatus: Partial<Record<TutorialStepsType, boolean>>;
  progress: number;
  open?: boolean;
  onClose: () => void;
  onItemClick: () => void;
};

export const ChecklistPopover: FC<ChecklistPopoverType> = ({
  completionStatus,
  progress,
  open,
  onClose,
  onItemClick,
}) => {
  const classes = useStyles();

  const stepsList = useMemo(
    () => TUTORIAL_STEPS.filter(({ name }) => completionStatus[name] !== undefined),
    [completionStatus],
  );

  const progressBarStyle = useMemo(
    () => ({
      width: `${progress * 100}%`,
    }),
    [progress],
  );

  if (!open) {
    return null;
  }

  return (
    <div className={classes.popover}>
      <div className={classes.popoverHeader}>
        <div className={classes.checklistTitle}>
          Getting Started
          <IconButton classes={{ root: classes.closeButton }} onClick={onClose} size="large">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <div className={classes.progessBar}>
          <div className={classes.progessValue} style={progressBarStyle} />
        </div>
      </div>
      <div className={classes.popoverContent}>
        {stepsList.map(({ name, ...stepConfig }) => (
          <TutorialStep
            key={name}
            name={name}
            {...stepConfig}
            completed={completionStatus[name]}
            onClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
};
