import React, { ChangeEvent, memo, useCallback } from 'react';
import { FormikProps } from 'formik';

import { MeTextField, MeTextFieldPropsType } from '../MeTextField';

export const MASKED_PREDEFINED_DOTS_VALUE = '***********';

const MeTextFieldWithMemo = memo(MeTextField);

export type MeTextFieldWithFormProps<T = Record<string, string>> = MeTextFieldPropsType & {
  formData: FormikProps<T>;
  name: string;
  displayAsRequired?: boolean;
  onBlur?: () => void;
  cleanOnTouch?: boolean;
  validateOnChange?: boolean;
};

export const MeTextFieldWithForm = <T,>({
  name,
  formData: { getFieldMeta, setFieldValue, setFieldTouched },
  displayAsRequired,
  cleanOnTouch,
  onBlur,
  validateOnChange,
  ...textFieldProps
}: MeTextFieldWithFormProps<T>) => {
  const { touched, value, error } = getFieldMeta(name);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(name, event.target.value);

      if (validateOnChange) {
        setFieldTouched(name, true);
      }
    },
    [setFieldValue, setFieldTouched, name, validateOnChange],
  );

  const handleBlur = useCallback(() => {
    onBlur && onBlur();
    cleanOnTouch && value === '' && setFieldValue(name, MASKED_PREDEFINED_DOTS_VALUE);
    setFieldTouched(name, true);
  }, [onBlur, cleanOnTouch, value, setFieldValue, setFieldTouched, name]);

  const handleTouchStart = useCallback(() => setFieldValue(name, ''), [setFieldValue, name]);

  return (
    <MeTextFieldWithMemo
      name={name}
      displayAsRequired={displayAsRequired}
      value={value as string}
      onChange={handleChange}
      error={touched ? error : ''}
      onBlur={handleBlur}
      onTouchStart={cleanOnTouch && value === MASKED_PREDEFINED_DOTS_VALUE ? handleTouchStart : undefined}
      {...textFieldProps}
    />
  );
};
