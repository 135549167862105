import { BigidRichTextButtonsType } from '@bigid-ui/rich-text-editor';

export const defaultToolbarButtons: BigidRichTextButtonsType[] = [
  'bold',
  'italic',
  'underline',
  'superscript',
  'subscript',
  '|',
  'brush',
  '|',
  'fontsize',
  '|',
  'align',
  '|',
  'ul',
  'ol',
  '|',
  'table',
  '|',
  'link',
  'unlink',
  '|',
  'undo',
  'redo',
];

export const basicToolbarButtons: BigidRichTextButtonsType[] = [
  'bold',
  'italic',
  'underline',
  '|',
  'link',
  'unlink',
  '|',
  'undo',
  'redo',
];

export const preferencesToolbarButtons: BigidRichTextButtonsType[] = [
  'bold',
  'italic',
  'underline',
  '|',
  'link',
  'unlink',
  '|',
  'emoji',
  '|',
  'undo',
  'redo',
];

export const toolbarButtonsConfig = {
  default: defaultToolbarButtons,
  basic: basicToolbarButtons,
  preferences: preferencesToolbarButtons,
};

export const fontSizeList = [12, 14, 16, 18, 20];
