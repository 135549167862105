import { styled } from '@mui/material/styles';

const PREFIX = 'RequestTypeWithUrl';

export const classes = {
  container: `${PREFIX}-container`,
  dropdown: `${PREFIX}-dropdown`,
  urlInput: `${PREFIX}-urlInput`,
};

export const Root = styled('div')({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.dropdown}`]: {
    width: 100,
    marginRight: 15,
  },
  [`& .${classes.urlInput}`]: {
    flex: 1,
    margin: '10px 0',
  },
});
