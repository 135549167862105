import { useCallback } from 'react';
import { NextGridState } from '@bigid-ui/grid/lib/BigidGrid/hooks';
import { BigidGridColumn, BigidGridRow } from '@bigid-ui/grid';
import { isEqual } from 'lodash';
import {
  createUserPreferenceView,
  deleteUserPreferenceView,
  fetchUserPreferences,
  patchUserPreferences,
} from '../services/settingsService';
import { generateNewGridColumnState } from '../utils';
import { useFetch } from './useFetch';
import { UserGridPreferenceView } from '../types';

// grids list
export const USER_REQUEST_GRID = 'userRequestGrid';
export const ACTIVITY_LOG_GRID = 'activity_log';

export const useUserPreferences = (gridName: string, permission?: boolean) => {
  const {
    data: userPreferences,
    setData: setUserPreferences,
    loading: userPreferencesLoading,
    loadData: userPreferencesLoadData,
  } = useFetch({
    fetchApi: () => fetchUserPreferences(gridName),
    dependencyParams: [gridName],
    permission,
    initValue: {
      views: [],
    },
  });

  const updateUserPreferenceView = useCallback(
    async (changedView: UserGridPreferenceView) => {
      try {
        if (changedView.id) {
          await patchUserPreferences({ views: [changedView] }, gridName);
          setUserPreferences(prev => ({
            ...prev,
            views: [...prev.views.map(v => (v.id === changedView?.id ? changedView : v))],
          }));
        } else {
          const view = await createUserPreferenceView(changedView, gridName);
          setUserPreferences(prev => ({
            ...prev,
            views: [...prev.views, view],
          }));

          return view;
        }
      } catch (error) {
        console.log(error);
      }
    },
    [gridName, setUserPreferences],
  );

  const onDeleteUserPreferenceView = async (id: string | number) => {
    await deleteUserPreferenceView(gridName, id);
    const userPreferences = await userPreferencesLoadData();

    return userPreferences?.views.sort((o1, o2) => o1.ordinal - o2.ordinal);
  };

  const handleGridStateChanged = useCallback(
    async ({
      nextGridState,
      columns,
      viewId,
    }: {
      nextGridState: NextGridState;
      columns: BigidGridColumn<BigidGridRow>[];
      viewId?: number;
    }) => {
      if (!nextGridState.hiddenColumnNames && !nextGridState.columnsOrder) {
        return;
      }

      const defaultColumnState = columns.map((c, i) => ({
        label: c.name,
        name: c.name,
        ordinal: i,
        enabled: !c.isHiddenByDefault,
      }));

      const newColumnState = generateNewGridColumnState({ nextGridState, columns: defaultColumnState });

      const curView = viewId ? userPreferences.views.find(v => v.id === viewId) : userPreferences.views[0];
      const curColumnState = curView?.columns;

      if (curColumnState ? !isEqual(curColumnState, newColumnState) : !isEqual(defaultColumnState, newColumnState)) {
        await updateUserPreferenceView({
          ...curView,
          gridName,
          columns: newColumnState,
          filters: [],
          predefined: false,
          ordinal: 0,
          label: 'Default View',
        });
      }
    },
    [gridName, updateUserPreferenceView, userPreferences.views],
  );

  const sortColumns = useCallback(
    (columns: BigidGridColumn<BigidGridRow>[], viewId?: number) => {
      const curView = viewId ? userPreferences.views.find(v => v.id === viewId) : userPreferences.views[0];
      const columnsPreferences = curView?.columns;

      if (columnsPreferences && columnsPreferences.length > 0) {
        return columns
          .map((a, i) => {
            const column = columnsPreferences.find(o => a.name === o.name);
            return { ...a, ordinal: column ? column.ordinal : i, isHiddenByDefault: column ? !column.enabled : false };
          })
          .sort((o1, o2) => o1.ordinal - o2.ordinal)
          .map((a, i) => ({ ...a, ordinal: i }));
      }
      return columns;
    },
    [userPreferences.views],
  );

  return {
    sortColumns,
    handleGridStateChanged,
    userPreferences,
    userPreferencesLoading,
    userPreferencesLoadData,
    updateUserPreferenceView,
    onDeleteUserPreferenceView,
  };
};
