import { useCallback, useEffect } from 'react';
import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';

import { setRequestCollaborators } from '../../../services/request';
import { useShowSnackbar } from '../../../hooks/useShowSnackbar';
import { GridRequestCollaboratorType } from '../../../types';

export const useRequestCollaborators = () => {
  const { showSnackbarMessage, closeSnackbar } = useShowSnackbar();

  const updateRequestCollaborators = useCallback(
    ({
      requestId,
      collaborators,
      onSuccess,
    }: {
      requestId: string;
      collaborators: GridRequestCollaboratorType[];
      onSuccess: () => void;
    }) => {
      setRequestCollaborators(collaborators, requestId).then(newRowData => {
        onSuccess();
        entityEventsEmitter.emit(EntityEvents.UPDATE, [newRowData]);
        showSnackbarMessage(
          `1 Request assigned to ${
            newRowData?.collaborators?.length > 0
              ? (newRowData.collaborators || []).map((i: GridRequestCollaboratorType) => i.userFullName).join(', ')
              : 'Empty'
          }`,
        );
      });
    },
    [showSnackbarMessage],
  );

  useEffect(
    () => () => {
      closeSnackbar();
    },
    [closeSnackbar],
  );

  return { updateRequestCollaborators };
};
