import { useEffect, useState, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { createContainer } from 'unstated-next';
import {
  AutoCloseResolutionsGetType,
  ManualCloseResolutionsGetType,
  RequestWorkflowDefinitionGetType,
  RequestWorkflowStageType,
} from '../../../types';
import {
  fetchAutoCloseResolutions,
  fetchManualCloseResolutions,
  fetchWorkflowCustomizationDefinitions,
} from '../../../services/settingsService';

const useWorkflowCustomization = () => {
  const location = useLocation();
  const { requestTypeId } = useParams<'requestTypeId'>();
  const [requestWorkflowData, setRequestWorkflowData] = useState<RequestWorkflowDefinitionGetType>();
  const [stages, setStages] = useState<RequestWorkflowStageType[]>();
  const [autoCloseResolutions, setAutoCloseResolutions] = useState<AutoCloseResolutionsGetType[]>();
  const [manualCloseResolutions, setManualCloseResolutions] = useState<ManualCloseResolutionsGetType[]>();

  useEffect(() => {
    reloadWorkflowDefinitions();
  }, [requestTypeId]); // eslint-disable-line react-hooks/exhaustive-deps

  const reloadAutoCloseResolutions = useCallback(async () => {
    const resolutions = await fetchAutoCloseResolutions(+requestTypeId!);
    setAutoCloseResolutions(resolutions);
  }, [requestTypeId]);

  const reloadManualCloseResolutions = useCallback(async (stageId: number) => {
    const resolutions = await fetchManualCloseResolutions(stageId);
    setManualCloseResolutions(resolutions);
  }, []);

  const reloadWorkflowDefinitions = useCallback(async () => {
    const { data } = await fetchWorkflowCustomizationDefinitions();
    const workflowData = data.find(({ id }) => id === +requestTypeId!);
    setRequestWorkflowData(workflowData);
    setStages(workflowData!.stages);
    location.pathname.includes('settings/workflow-customization/') &&
      !location.pathname.includes('settings/workflow-customization/workflow-settings') &&
      reloadAutoCloseResolutions();
  }, [location, reloadAutoCloseResolutions, requestTypeId]);

  return {
    reloadWorkflowDefinitions,
    requestWorkflowData,
    autoCloseResolutions,
    manualCloseResolutions,
    reloadManualCloseResolutions,
    reloadAutoCloseResolutions,
    requestTypeId,
    stages,
  };
};

export const WorkflowCustomizationContainer = createContainer(useWorkflowCustomization);
