export interface LWCLoginField {
  type: string;
  name: string;
  label: string;
}

export interface LWCLoginFieldList {
  fields: LWCLoginField[];
}

export interface LWCSubmitFieldsResponse {
  authenticationId: string;
}
