import * as Yup from 'yup';
import { FormikHelpers, useFormik } from 'formik';

import { ValidationErrors } from '../../../utils';
import { JiraConfigurationFormType } from '../../../types';

const validationSchema = Yup.object({
  name: Yup.string().required(ValidationErrors.REQUIRED),
  host: Yup.string().required(ValidationErrors.REQUIRED),
  userName: Yup.string().required(ValidationErrors.REQUIRED),
  password: Yup.string().required(ValidationErrors.REQUIRED),
  projectName: Yup.string().required(ValidationErrors.REQUIRED),
  projectId: Yup.string().required(ValidationErrors.REQUIRED),
  port: Yup.string().required(ValidationErrors.REQUIRED),
});

const initial: JiraConfigurationFormType = {
  name: '',
  protocol: 'https',
  host: '',
  userName: '',
  password: '',
  projectName: '',
  projectId: '',
  port: '',
  strictSsl: false,
};

type useJiraConfigurationFormOptionsType = {
  initialValues?: JiraConfigurationFormType;
  onSubmit: (
    values: JiraConfigurationFormType | (JiraConfigurationFormType & { id: string }),
    formikHelpers: FormikHelpers<JiraConfigurationFormType>,
  ) => void;
};

export const useJiraConfigurationForm = ({
  initialValues = initial,
  onSubmit,
}: useJiraConfigurationFormOptionsType) => {
  const formik = useFormik<JiraConfigurationFormType>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return formik;
};
