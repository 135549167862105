import React, { FC } from 'react';
import { AuthorizedAgentGuidedTourStepTemplate } from './AuthorizedAgentGuidedTourStepTemplate';

interface AgentTabStepProps {
  dataAid?: string;
}

export const AgentTabStep: FC<AgentTabStepProps> = ({ dataAid }) => {
  return (
    <AuthorizedAgentGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Go to the Authorized Agent section"
      firstSectionText="Go to the Authorized Agent section to customize its fields and labels."
    />
  );
};
