import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  disabled: {
    '& > div > *': {
      opacity: 0.4,
    },
  },
  toggle: ({ compact }: { compact?: boolean }) => ({
    position: 'absolute',
    top: compact ? 20 : 32,
    right: compact ? 20 : 32,
    opacity: '1 !important',
  }),
});
