import React, { FC, useEffect, useState, ReactNode } from 'react';
import { OnboardingStateType } from '../../types';
import { getOnboardingState } from '../../services/onboardingService';
import { OnboardingWindow } from '../../pages/OnBoarding/OnboardingWindow';
import { TutorialContainer } from '../../state/tutorialContainer';
import { useNavigate } from 'react-router-dom';
import { BrandsContainer } from '../../state/brandsContainer';

interface OnboardingWindowGuideStateType {
  onboardingState?: OnboardingStateType;
  completed: boolean;
}

type OnboardingWindowGuardType = {
  children?: ReactNode;
};

export const OnboardingWindowGuard: FC<OnboardingWindowGuardType> = ({ children }) => {
  const { setFetchChecklistTimestamp } = TutorialContainer.useContainer();
  const { brands } = BrandsContainer.useContainer();
  const navigate = useNavigate();

  const [state, setState] = useState<OnboardingWindowGuideStateType>({
    completed: false,
  });

  const fetchState = async () => {
    const onboardingState = await getOnboardingState();
    setState({ onboardingState, completed: !onboardingState.widgetEnabled });
  };

  const handleOnComplete = () => {
    if (brands?.brands?.length > 1) {
      navigate('/brands');
    } else {
      navigate(`/editor/${encodeURIComponent(brands.brands[0]?.id)}`);
    }
    setFetchChecklistTimestamp(Date.now());
    setState(state => ({ ...state, completed: true }));
  };

  useEffect(() => {
    fetchState();
  }, []);

  if (state.completed || !state.onboardingState) {
    return <>{children}</>;
  }

  return <OnboardingWindow onboardingState={state.onboardingState} onComplete={handleOnComplete} />;
};
