import React, { FC } from 'react';
import { Root, classes } from './AutoCloseResolutionTableStyles';

import {
  ActionsWorkflowGetType,
  AutoCloseResolutionsGetType,
  StageConfigType,
  StageEmailConfigurationType,
} from '../../../types';
import { SimpleTable } from '../../../components/SimpleTable';
import { AutoCloseResolutionRow } from './AutoCloseResolutionRow';

const TABLE_EMAIL_HEADERS_RESOLUTIONS = ['Names', 'Triggers', 'Last Update', 'Enabled', ''];
const TABLE_EMAIL_HEADERS_CLASSES = ['', classes.triggerTd, '', classes.activeTd, classes.activeTd];

interface AutoCloseResolutionTablePropsType {
  rows: AutoCloseResolutionsGetType[];
  actionsRows?: ActionsWorkflowGetType[];
  setDeleteDialogData: (arg: { type: StageConfigType; id: number }) => void;
  onRowClick: (configType: StageConfigType, row: StageEmailConfigurationType) => void;
}

export const AutoCloseResolutionTable: FC<AutoCloseResolutionTablePropsType> = ({
  rows,
  setDeleteDialogData,
  onRowClick,
}) => {
  return (
    <Root>
      <SimpleTable
        th={TABLE_EMAIL_HEADERS_RESOLUTIONS}
        thClasses={TABLE_EMAIL_HEADERS_CLASSES}
        bodyTestId="autoclose-resolutions-table"
        trElement={
          <AutoCloseResolutionRow rows={rows} setDeleteDialogData={setDeleteDialogData} onRowClick={onRowClick} />
        }
      />
    </Root>
  );
};
