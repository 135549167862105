export enum ReleasedFeatureCodeType {
  WORKFLOW_CUSTOMIZATION = 'workflow_customization',
  AUTHORIZED_AGENT = 'authorized_agent',
  APPEAL = 'appeal_flow',
  COMMUNACATIONS = 'communications',
}

export type ReleasedFeatureType = {
  code: ReleasedFeatureCodeType;
  viewed: boolean;
};
