import { styled } from '@mui/material/styles';

const PREFIX = 'EmailTemplate';

export const classes = {
  root: `${PREFIX}-root`,
  row: `${PREFIX}-row`,
  editor: `${PREFIX}-editor`,
  tooltip: `${PREFIX}-tooltip`,
};

export const Root = styled('div')({
  [`&.${classes.root}`]: {
    position: 'relative',
  },
  [`& .${classes.row}`]: {
    padding: '8px 0',
    width: '100%',
    maxWidth: 560,
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.editor}`]: {
    width: '100%',
    maxWidth: 560,
  },
  [`& .${classes.tooltip}`]: {
    position: 'absolute',
    top: 7,
    left: 122,
  },
});
