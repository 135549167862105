import React, { FC } from 'react';
import { BigidChip, BigidChipProps } from '@bigid-ui/components';
import { useTheme } from '@mui/styles';

export const NoteFileChip: FC<BigidChipProps> = props => {
  const theme = useTheme();
  return (
    <BigidChip
      {...props}
      variant="outlined"
      size="small"
      outlineColor={theme.vars.palette.bigid?.gray200}
      bgColor={theme.vars.palette.bigid?.gray50}
    />
  );
};
