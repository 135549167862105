import { UserRequestAttributeInfoType, UserRequestPreferenceInfoType } from '../../types';

export type GridReducerStateType = {
  requestsCount: { viewId: number; count: number }[];
  search: string;
  collaboratorSearch: string;
  isViewChanged: boolean;
  allUserRequestAttributesInfo?: UserRequestAttributeInfoType[];
  allUserRequestPreferencesInfo?: UserRequestPreferenceInfoType[];
  toolbarKey: number;
};

export enum GridReducerActionTypes {
  SET_REQUESTS_INFO,
  SET_COLLABORATOR_SEARCH,
  SET_SEARCH_TEXT,
  SET_IS_VIEW_CHANGED,
  SET_USER_ATTRIBUTES_INFO,
  SET_USER_PREFERENCES_INFO,
  SET_TOOLBAR_KEY,
}

export type GridReducerActionType = {
  type: GridReducerActionTypes;
  payload?: any;
};

export const gridReducer = (state: GridReducerStateType, action: GridReducerActionType): GridReducerStateType => {
  const { type, payload } = action;
  switch (type) {
    case GridReducerActionTypes.SET_REQUESTS_INFO: {
      return {
        ...state,
        requestsCount: payload.requestsCount,
      };
    }
    case GridReducerActionTypes.SET_IS_VIEW_CHANGED: {
      return {
        ...state,
        isViewChanged: payload,
      };
    }
    case GridReducerActionTypes.SET_SEARCH_TEXT: {
      return {
        ...state,
        search: payload,
      };
    }
    case GridReducerActionTypes.SET_USER_ATTRIBUTES_INFO: {
      return {
        ...state,
        allUserRequestAttributesInfo: payload,
      };
    }
    case GridReducerActionTypes.SET_USER_PREFERENCES_INFO: {
      return {
        ...state,
        allUserRequestPreferencesInfo: payload,
      };
    }
    case GridReducerActionTypes.SET_TOOLBAR_KEY: {
      return {
        ...state,
        toolbarKey: payload,
      };
    }
    default: {
      return state;
    }
  }
};
