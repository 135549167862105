import { authStore } from '../services/authentication';

export const FEATURES = {
  COOKIE_CONSENT: 'cookie_consent',
  PRIVACY_REQUEST: 'privacy_request',
  WORKFLOW_CUSTOMIZATION: 'workflow_customization',
};

const makeFeatureKey = (featureName: string, userId: number) => `${featureName}-${userId}`;

export const isNewFeature = (featureName: string) => {
  const auth = authStore.getAuthentication();

  return auth && !localStorage.getItem(makeFeatureKey(featureName, auth.user.id));
};

export const markFeatureAsKnown = (featureName: string) => {
  const auth = authStore.getAuthentication();

  auth && localStorage.setItem(makeFeatureKey(featureName, auth.user.id), '1');
};
