import { BusinessUserType, GridRequestCollaboratorType } from '../types';
import { itemsToDropdownOptions } from './dropdownUtils';

export const businessUsersToBigidDropdownOptions = itemsToDropdownOptions<BusinessUserType>(
  user => String(user.id),
  user => user.fullName,
);

export const businessUsersToBigidDropdownValue = itemsToDropdownOptions<BusinessUserType>(
  user => user.fullName,
  user => user.fullName,
);

export const collaboratorsToBigidDropdownValue = itemsToDropdownOptions<GridRequestCollaboratorType>(
  user => String(user.userId),
  user => user.userFullName,
);
