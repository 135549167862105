import makeStyles from '@mui/styles/makeStyles';
import { MinimizerContainerType } from './MinimizerContainer';

export const useStyles = makeStyles({
  chips: ({ minimized }: Pick<MinimizerContainerType, 'minimized'>) => ({
    display: 'flex',
    flexDirection: minimized ? 'row' : 'column',
    minWidth: 230,
    ...(minimized ? { borderLeft: '1px solid #ccc' } : { borderRight: '1px solid #ccc' }),
    alignSelf: 'center',
    marginLeft: 40,
    ...(minimized ? { paddingLeft: 20 } : {}),
  }),
  chip: ({ minimized }: Pick<MinimizerContainerType, 'minimized'>) => ({
    '&:not(:last-child)': {
      marginBottom: minimized ? 0 : 12,
    },
  }),
  general: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 406,
  },
  bar: ({ minimized }: Pick<MinimizerContainerType, 'minimized'>) => ({
    ...(minimized ? { display: 'none' } : {}),
    width: '100%',
    height: '100%',
    paddingLeft: 44,
  }),
  title: ({ minimized }: Pick<MinimizerContainerType, 'minimized'>) => ({
    ...(minimized ? { display: 'none' } : {}),
    width: '100%',
    margin: '-7px 0 7px',
    fontWeight: 'bold',
  }),
});
