import React, { FC } from 'react';
import { TooltipRenderProps } from '@bigid-ui/guided-tour';
import { StepTooltip } from '../../../GuidedTour/tooltips/StepTooltip';
import { AuthorizedAgentGuidedTourContainer } from '../../../../state/authorizedAgentGuidedTourContainer';

const DISABLED_INDEXES = [1, 2, 4, 6];

export const AuthorizedAgentStepTooltip: FC<TooltipRenderProps> = ({ ...props }) => {
  const { authorizedAgentTourStartedFrom } = AuthorizedAgentGuidedTourContainer.useContainer();
  const startedFromEditor = authorizedAgentTourStartedFrom === 'editor';

  return (
    <StepTooltip
      {...props}
      disabledIndexes={DISABLED_INDEXES}
      indexDifference={startedFromEditor ? 0 : undefined}
      sizeDifference={startedFromEditor ? 1 : undefined}
    />
  );
};
