import React, { useState, useCallback, useMemo, useEffect, FC } from 'react';

import { RegulationType, RequestManagerStateTypeActions, StatisticPeriodValuesType } from '../../types';
import { ClosedRequestTrendsDashboard } from '../ClosedRequestTrendsDashboard';
import { OpenRequestTrendsDashboard } from '../OpenRequestTrendsDashboard';
import { RegulationTypesDashboard } from '../RegulationTypesDashboard';
import { DonutDashboard } from '../DonutDashboard';
import { PeriodDashboard } from '../PeriodDashboard';
import { ProfileTypesDashboard } from '../ProfileTypesDashboard';
import { MapChartDashboard } from '../MapChartDashboard';
import { cancelRequests } from '../../services/request';
import { MePageHeader } from '../../components/MePageHeader';

import { useStyles } from './DashboardStyles';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@bigid-ui/components';

interface DashboardProps {
  actions: RequestManagerStateTypeActions;
}

export const Dashboard: FC<DashboardProps> = ({ actions }) => {
  const classes = useStyles();
  const [statisticsPeriod, setStatisticsPeriod] = useState<StatisticPeriodValuesType>(StatisticPeriodValuesType.week);
  const [regulation, setRegulation] = useState<RegulationType>('');
  const navigate = useNavigate();
  const memorizedDonutDashboard = useMemo(() => <DonutDashboard />, []);

  const onRegulationChange = useCallback((name: RegulationType) => {
    setRegulation(name);
  }, []);

  const onPeriodChange = useCallback((period: StatisticPeriodValuesType) => {
    setStatisticsPeriod(period);
    setRegulation('');
  }, []);

  useEffect(() => () => cancelRequests(), []);

  return (
    <div>
      <MePageHeader
        title="Dashboard"
        rightsideControls={
          <PrimaryButton size="medium" text="View Requests" onClick={() => navigate('/request-manager')} />
        }
      />
      {memorizedDonutDashboard}
      <PeriodDashboard statisticsPeriod={statisticsPeriod} onPeriodChange={onPeriodChange} />
      <RegulationTypesDashboard
        regulation={regulation}
        statisticsPeriod={statisticsPeriod}
        onRegulationChange={onRegulationChange}
      />
      <div className={classes.flexWrapper}>
        <OpenRequestTrendsDashboard regulation={regulation} statisticsPeriod={statisticsPeriod} />
        <ProfileTypesDashboard
          regulation={regulation}
          statisticsPeriod={statisticsPeriod}
          showProfiles={actions.SHOW_PROFILES}
        />
      </div>
      <div className={classes.flexWrapper}>
        <ClosedRequestTrendsDashboard regulation={regulation} statisticsPeriod={statisticsPeriod} />
        <MapChartDashboard regulation={regulation} statisticsPeriod={statisticsPeriod} />
      </div>
    </div>
  );
};
