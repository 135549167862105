import React, { FC, ReactNode } from 'react';
import { BigidBody1, BigidIconType, PrimaryButton, SecondaryButton } from '@bigid-ui/components';
import { Box, Stack } from '@mui/system';

interface LayoutEmptyStateActionType {
  onClick: () => void;
  label: string;
  icon?: BigidIconType;
  isGlobal?: boolean;
  testId?: string;
}

export interface LayoutEmptyStatePropsType {
  illustration?: ReactNode;
  description?: string;
  actions?: LayoutEmptyStateActionType[];
  testId?: string;
}

export const LayoutEmptyState: FC<LayoutEmptyStatePropsType> = ({
  illustration: Illustration,
  description,
  actions,
  testId,
}) => (
  <Stack width="100%" height="100%" alignItems="center" justifyContent="center" data-aid={testId}>
    <Stack alignItems="center" width="100%">
      {Illustration}
      {description && (
        <BigidBody1 mt={1} mb={1}>
          {description}
        </BigidBody1>
      )}
      {actions && (
        <Stack flexDirection="row" alignItems="center" justifyContent="center">
          {actions.map(action => {
            const { isGlobal, icon: Icon } = action;
            return (
              <Box key={action.label}>
                {isGlobal ? (
                  <SecondaryButton
                    text={action.label}
                    onClick={action.onClick}
                    size="medium"
                    dataAid={action.testId}
                    startIcon={Icon && <Icon color="inherit" />}
                  />
                ) : (
                  <PrimaryButton
                    text={action.label}
                    onClick={action.onClick}
                    size="medium"
                    dataAid={action.testId}
                    startIcon={Icon && <Icon color="inherit" />}
                  />
                )}
              </Box>
            );
          })}
        </Stack>
      )}
    </Stack>
  </Stack>
);
