import React, { FC } from 'react';
import { StyledAboutApplicationDialog } from './AboutApplicationDialogStyles';
import { VersionType } from '../../types';
import { BigidDialog, BigidDialogProps, TertiaryButton } from '@bigid-ui/components';

type AboutApplicationDialogPropType = { version?: VersionType } & Pick<BigidDialogProps, 'isOpen' | 'onClose'>;

export const AboutApplicationDialog: FC<AboutApplicationDialogPropType> = ({ isOpen, version, onClose }) => {
  return (
    <BigidDialog
      isOpen={isOpen}
      onClose={onClose}
      title="About"
      buttons={[
        {
          text: 'Close',
          component: TertiaryButton,
          onClick: onClose,
          dataAid: 'about-dialog__close',
        },
      ]}
    >
      <div data-aid="about-dialog">
        <StyledAboutApplicationDialog.AppVersionLabel>
          Application Version:
        </StyledAboutApplicationDialog.AppVersionLabel>
        <StyledAboutApplicationDialog.AppVersionText data-aid="about-dialog_app-version">
          {version?.appVersion}
        </StyledAboutApplicationDialog.AppVersionText>
        <br />

        <StyledAboutApplicationDialog.AppVersionLabel>Release Date:</StyledAboutApplicationDialog.AppVersionLabel>
        <StyledAboutApplicationDialog.AppVersionText data-aid="about-dialog_app-release_date">
          {version?.releaseDate}
        </StyledAboutApplicationDialog.AppVersionText>
        <br />

        <StyledAboutApplicationDialog.AppVersionLabel>
          Minimal Compatible BigID core version:
        </StyledAboutApplicationDialog.AppVersionLabel>
        <StyledAboutApplicationDialog.AppVersionText data-aid="about-dialog_app-minimal_bigID_Core_version">
          {version?.minimalBigIDCoreVersion}
        </StyledAboutApplicationDialog.AppVersionText>
        <br />

        <StyledAboutApplicationDialog.ReleaseNotes>
          <a
            data-aid="about-dialog_release-notes"
            href="https://docs.bigid.com/releases/docs/privacy-portal-rn"
            target="_blank"
            rel="noreferrer"
          >
            Release Notes Link
          </a>
        </StyledAboutApplicationDialog.ReleaseNotes>
      </div>
    </BigidDialog>
  );
};
