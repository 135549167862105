export type UploadedFileType = {
  fileUploadId: string;
};

export type FileType = {
  fileName: string;
  id: string;
  isNew?: boolean;
};

export type FileErrorType = {
  fileName: string;
  error: string;
};

export type FileRuleType = {
  MAX_FILES: number;
  MAX_FILE_SIZE_MB: number;
  FORMATS: string;
};
