import { styled } from '@mui/material/styles';

export const StyledAboutApplicationDialog = {
  AppVersionLabel: styled('div')({
    fontSize: '0.875rem',
  }),
  AppVersionText: styled('div')({
    marginTop: 4,
    fontSize: '0.8125rem',
    fontWeight: 'bold',
  }),
  ReleaseNotes: styled('div')(({ theme }) => ({
    marginTop: 16,
    fontSize: '0.875rem',
    '& a': {
      color: theme.vars.palette.bigid?.blue700,
    },
  })),
};
