import React, { useEffect } from 'react';
import { Stack } from '@mui/material';

import { RequestManagerHeader } from './RequestManagerHeader';
import { RequestManagerGrid } from './RequestManagerGrid';
import { RequestManagerStateContainer } from './useRequestManagerState';
import { SystemUpdateMessageContainer } from './useSystemUpdateMessage';
import { RequestManagerGridContainer } from './useRequestManagerGrid';
import { RequestOwnersContainer } from './useRequestOwners';
import { usePageTitle } from '../../hooks/usePageTitle';
import { RequestManagerViews } from './RequestManagerViews';

export const RequestManager = () => {
  const { requestManagerStateLoaded, requestManagerStateActions } = RequestManagerStateContainer.useContainer();
  const { checkForSystemUpdates } = SystemUpdateMessageContainer.useContainer();
  const { fetchAllUserRequestAttributesInfo, fetchAllUserRequestPreferencesInfo } =
    RequestManagerGridContainer.useContainer();
  const { fetchUsersToAssign } = RequestOwnersContainer.useContainer();

  usePageTitle('Requests Manager');

  useEffect(() => {
    if (requestManagerStateLoaded) {
      if (requestManagerStateActions.UPDATE_DATA_SOURCES) {
        checkForSystemUpdates();
      }
      if (requestManagerStateActions.SHOW_USERS) {
        fetchUsersToAssign();
      }
      fetchAllUserRequestAttributesInfo();
      fetchAllUserRequestPreferencesInfo();
    }
  }, [requestManagerStateLoaded, requestManagerStateActions]); //eslint-disable-line react-hooks/exhaustive-deps

  if (!requestManagerStateLoaded) {
    return null;
  }

  return (
    <Stack width="100%" height="100%">
      <RequestManagerHeader />
      <RequestManagerViews />
      <RequestManagerGrid />
    </Stack>
  );
};
