import { StopPropagationContainer } from '../../StopPropagationContainer';
import { Box, Divider, Stack } from '@mui/material';
import { BigidBody1, BigidButtonIcon, BigidNotes1, BigidNotes2, BigidTooltip } from '@bigid-ui/components';
import { BigidAttachmentIcon, BigidDeleteIcon, BigidEditIcon } from '@bigid-ui/icons';
import React, { FC } from 'react';
import { NoteType } from '../../../types';
import { useFormatting } from '../../../hooks/useFormatting';

type NoteTooltipPropsType = {
  note: NoteType;
  onEdit?: () => void;
  onDelete?: () => void;
};

export const NoteTooltip: FC<NoteTooltipPropsType> = ({ note, onEdit, onDelete }) => {
  const { getFormattedDate } = useFormatting();

  return (
    <BigidTooltip
      arrow={false}
      title={
        <StopPropagationContainer>
          <Stack direction="row" spacing={2} alignItems="start" padding="10px 0">
            <Box>
              <BigidNotes1>{note?.content}</BigidNotes1>
              {(note.files || []).map(file => (
                <Stack flexDirection="row" key={file.id} mt={1}>
                  <BigidAttachmentIcon />
                  <BigidBody1 size="small">{file.fileName}</BigidBody1>
                </Stack>
              ))}
              <Box display="flex" mt={1} height="10px">
                <BigidNotes2 mr={0.5}>{note?.userFullName}</BigidNotes2>
                <Divider orientation="vertical" variant="fullWidth" />
                <BigidNotes2 ml={0.5}>
                  {getFormattedDate(note?.date, {
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </BigidNotes2>
              </Box>
            </Box>
            <Box display="flex" position="relative" bottom={0} left={5}>
              {onEdit && (
                <BigidButtonIcon
                  icon={BigidEditIcon}
                  onClick={e => {
                    e?.stopPropagation();
                    onEdit();
                  }}
                  dataAid="edit-note-button"
                />
              )}
              {onDelete && (
                <BigidButtonIcon
                  icon={BigidDeleteIcon}
                  onClick={e => {
                    e?.stopPropagation();
                    onDelete();
                  }}
                  dataAid="delete-note-button"
                />
              )}
            </Box>
          </Stack>
        </StopPropagationContainer>
      }
    >
      <Box>{note.content}</Box>
    </BigidTooltip>
  );
};
