import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipIcon: {
    marginRight: 8,
  },
});
