import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { GetFormattedDateType } from '../types/CommonTypes';
import { formatDateToLocal } from '../utils/dateTimeFormat';

export const useFormatting = () => {
  const { formatDate } = useIntl();

  const getFormattedDate: GetFormattedDateType = useCallback(
    (date, options, convertToLocal = true) => {
      if (!date) return date;

      const dateToFormat = convertToLocal ? formatDateToLocal(date) : date;

      return formatDate(dateToFormat, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        ...options,
      });
    },
    [formatDate],
  );

  return {
    getFormattedDate,
  };
};
