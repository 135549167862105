import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: 12,
    borderRadius: 4,
    backgroundColor: theme.vars.palette.bigid?.primary100,
    color: theme.vars.palette.bigid?.black,
    fontSize: '0.81rem',
    fontWeight: 400,
  },
  icon: {
    '& svg': {
      marginRight: 12,
      width: 12,
      color: theme.vars.palette.bigid?.primary900,
      fill: theme.vars.palette.bigid?.primary900,
    },
  },
  upgrade: {
    color: theme.vars.palette.bigid?.primary900,
    textDecoration: 'underline',
  },
}));
