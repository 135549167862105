import { useState, useCallback, useEffect } from 'react';
import { BigidDropdownOption } from '@bigid-ui/components';

export interface ExtraFieldItemData {
  name: string;
  value: string;
  disabledName?: boolean;
  disabledValue?: boolean;
  mandatory?: boolean;
  dropdownOptions?: BigidDropdownOption[];
}

export interface UseExtraFieldControl {
  initialData: ExtraFieldItemData[];
  createNewItem: (currValue?: ExtraFieldItemData[]) => ExtraFieldItemData;
  onChange: (newValue: ExtraFieldItemData[]) => void;
  formData?: ExtraFieldItemData[];
}

export const useExtraKeyValueFieldsControl = ({
  initialData,
  createNewItem,
  onChange,
  formData,
}: UseExtraFieldControl) => {
  const [data, setData] = useState<ExtraFieldItemData[]>(initialData);
  const onAddNew = useCallback(() => {
    setData(current => {
      const newData = [...current, createNewItem(current)];
      onChange(newData);
      return newData;
    });
  }, [createNewItem, onChange]);
  const onDelete = useCallback(
    (deleteIndex: number) => {
      setData(current => {
        const newData = current.filter((item, index) => index !== deleteIndex);
        onChange(newData);
        return newData;
      });
    },
    [onChange],
  );
  const onChangeItem = useCallback(
    (changeIndex: number, value: any) => {
      setData(current => {
        const newData = current.map((item, index) => (index === changeIndex ? { ...item, value } : item));
        onChange(newData);
        return newData;
      });
    },
    [onChange],
  );

  useEffect(() => {
    if (formData) {
      setData(formData);
    }
  }, [formData]);

  return {
    data,
    onAddNew,
    onDelete,
    onChangeItem,
  };
};
