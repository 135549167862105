import React, { ReactNode } from 'react';
import { useSnackbar, SnackbarKey, OptionsObject } from 'notistack';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/styles';

type SnackbarOptionsType = {
  to?: string;
  text?: string;
  onClick?: () => void;
  manualClose?: boolean;
} & OptionsObject;

export const useShowSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLinkClick = (to: string, snackbarKey: SnackbarKey) => {
    closeSnackbar(snackbarKey);
    navigate(to);
  };

  const showSnackbarMessage = (message: ReactNode, options: SnackbarOptionsType = {}) => {
    const snackbarKey = enqueueSnackbar(message, {
      action: (
        <>
          {(options.to || options.onClick) && (
            <span
              style={{
                textDecoration: 'underline',
                color: theme.vars.palette.bigid?.blue700,
                marginRight: 20,
                cursor: 'pointer',
              }}
              onClick={options.to ? () => handleLinkClick(options.to!, snackbarKey) : options.onClick}
              data-aid="snakbar-message-action-button"
            >
              {options.text}
            </span>
          )}
          {options.manualClose && (
            <CloseIcon
              style={{ marginRight: 15, cursor: 'pointer', width: 18 }}
              onClick={() => closeSnackbar(snackbarKey)}
            />
          )}
        </>
      ),
      preventDuplicate: true,
      ...options,
    });
  };

  return { showSnackbarMessage, closeSnackbar };
};
