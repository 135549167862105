// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { DataSourceAssets } from './dataSourceAssetsUtils';
import { authStore } from '../services/authentication';
import { DatasourcesConnectionStatuses, DatasourcesGridType } from '../types';

export const getDataSourceLogo = (dataSourceType: string) => {
  const file = (!!dataSourceType && DataSourceAssets[dataSourceType]) || DataSourceAssets['default'];
  return `${process.env.PUBLIC_URL}/images/data-source/${file.filename}`;
};

export const getSystemUpdateMessageAknowledgementKey = () => {
  const auth = authStore.getAuthentication();

  return auth && `displayed-system-update-message-for-${auth.user.id}`;
};

export const markSystemUpdateMessageAknowledged = (systems: DatasourcesGridType[]) => {
  const systemUpdateMessageAknowledgementKey = getSystemUpdateMessageAknowledgementKey();

  if (systemUpdateMessageAknowledgementKey) {
    const currentAknowledgementKey = sessionStorage.getItem(systemUpdateMessageAknowledgementKey) || '';

    sessionStorage.setItem(
      systemUpdateMessageAknowledgementKey,
      currentAknowledgementKey.concat(systems.map(({ id }) => id).join(',')),
    );
  }
};

export const isSystemUpdateMessageAknowledged = () => {
  const systemUpdateMessageAknowledgementKey = getSystemUpdateMessageAknowledgementKey();

  return systemUpdateMessageAknowledgementKey && sessionStorage.getItem(systemUpdateMessageAknowledgementKey);
};

export const getSystemsWithUpdateAvailable = (systems: DatasourcesGridType[]) => {
  const systemUpdateMessageAknowledgementKey = getSystemUpdateMessageAknowledgementKey();

  if (!systemUpdateMessageAknowledgementKey) {
    return [];
  }

  const updateAknowledSystemIds = (sessionStorage.getItem(systemUpdateMessageAknowledgementKey) || '')
    .split(',')
    .map(parseInt);

  return systems.filter(({ id, updateRequired }) => updateRequired && !updateAknowledSystemIds.includes(id));
};

export const getDataSourceConnectionStatus = (
  systemId: number,
  connectionStatus: DatasourcesConnectionStatuses,
  updateRequired?: boolean,
  connectionStatusUpdateTimestamp?: string,
) => {
  if (connectionStatus === DatasourcesConnectionStatuses.NONE) {
    return '-';
  }

  const isConnectionFailed = connectionStatus === DatasourcesConnectionStatuses.FAILED;

  if (!isConnectionFailed && updateRequired) {
    return 'Updates Available';
  }

  if (isConnectionFailed) {
    return `Connection Error ${moment(connectionStatusUpdateTimestamp).format('ll')}`;
  }

  return `Last Connection ${moment(connectionStatusUpdateTimestamp).format('ll')}`;
};
