import React, { FC, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { BigidTooltip, BigidStatusBadge, BigidStatusBadgeType, BigidStatusBadgeSize } from '@bigid-ui/components';

import {
  CreateDatasourseType,
  DatasourcesConnectionStatuses,
  DatasourcesProviderType,
  DataSourceSystemOnboardingTypes,
  SignInValidationResponseType,
} from '../../types';
import { getDataSourceLogo } from '../../utils/dataSourceUtils';
import {
  signinDatasource,
  createSystem,
  getScopesOptions,
  deleteDatasourceById,
  validateDatasourceSignIn,
} from '../../services/systemsService';
import { openConfigWindow } from '../../utils';
import { useStyles } from './MeConnectorTileStyles';
import { trackOnboardingEvent } from '../../services/eventsTrackingService';
import { TrackingEvents } from '../../types/TrackingEventTypes';
import { useWebSocket } from '../../hooks/useWebSocket';
import { BigidApplyIcon, BigidInProgressIcon } from '@bigid-ui/icons';

interface MeConnectorTilePropsType {
  source: DataSourceSystemOnboardingTypes;
  mode: ConnectorMode;
  handleSystemConection: (source: DataSourceSystemOnboardingTypes, mode: ConnectorMode) => void;
}

export enum ConnectorMode {
  DEFAULT = 'default',
  DISABLED = 'disabled',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
}

export const MeConnectorTile: FC<MeConnectorTilePropsType> = ({ source, mode, handleSystemConection }) => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [dataSourceToCreate, setDataSourceToCreate] = useState<CreateDatasourseType>();

  const auth_state = sessionStorage.getItem('auth_state') || '';

  useEffect(() => {
    if (source.connectionStatus && source.connectionStatus === DatasourcesConnectionStatuses.CONNECTED) {
      handleSystemConection(source, ConnectorMode.CONNECTED);
    } else if (source.connectionStatus && source.connectionStatus === DatasourcesConnectionStatuses.FAILED) {
      trackOnboardingEvent(TrackingEvents.ONBOARDING_CONNECTION_ERROR);
      setError('Something went wrong, please try again later');
      handleSystemConection(source, ConnectorMode.DEFAULT);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSignInValidationCompleted = useCallback(
    async (message: SignInValidationResponseType) => {
      if (!dataSourceToCreate || dataSourceToCreate.authId != message.authenticationId) {
        return;
      }

      if (message.valid) {
        const { id } = await createSystem(dataSourceToCreate);
        handleSystemConection({ ...source, connectionId: id }, ConnectorMode.CONNECTED);
      } else {
        handleSystemConection(source, ConnectorMode.DEFAULT);
        trackOnboardingEvent(TrackingEvents.ONBOARDING_CONNECTION_ERROR);
        setError('Unable to connect');
      }
    },
    [dataSourceToCreate], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useWebSocket<SignInValidationResponseType>({
    topic: 'sign-in-validation-completed',
    onMessage: onSignInValidationCompleted,
  });

  const onConnectorSelect = async (source: DataSourceSystemOnboardingTypes) => {
    try {
      trackOnboardingEvent(TrackingEvents.ONBOARDING_SYSTEM_CLICK);
      handleSystemConection(source, ConnectorMode.CONNECTING);
      setError('');
      const { authorizationUrl, externalId, dataSourceProviderType, dataSourceTypeName } = await signinDatasource(
        source.id,
      );
      openConfigWindow(
        authorizationUrl,
        resp => onConnectorFinish(resp.authId, externalId, dataSourceProviderType, dataSourceTypeName),
        onConnectorError,
        auth_state,
      );
    } catch {
      handleSystemConection(source, ConnectorMode.DEFAULT);
      trackOnboardingEvent(TrackingEvents.ONBOARDING_CONNECTION_ERROR);
      setError('Something went wrong, please try again later');
    }
  };

  const onConnectorFinish = useCallback(
    async (
      authId: string,
      dataSourceExternalId?: number,
      dataSourceProviderType?: DatasourcesProviderType,
      dataSourceTypeName?: string,
    ) => {
      try {
        const scopes = await getScopesOptions();

        setDataSourceToCreate({
          authId,
          ...source,
          externalId: dataSourceExternalId,
          scopeIds: scopes.map(s => s.id.toString()),
          dataSourceTypeId: source.id,
        });

        await validateDatasourceSignIn({
          dataSourceExternalId,
          authId,
          dataSourceProviderType,
          dataSourceTypeName,
        });
      } catch {
        handleSystemConection(source, ConnectorMode.DEFAULT);
        trackOnboardingEvent(TrackingEvents.ONBOARDING_CONNECTION_ERROR);
        setError('Something went wrong, please try again later');
      }
    },
    [handleSystemConection, source],
  );

  const onConnectorError = (_errMessage: string) => {
    handleSystemConection(source, ConnectorMode.DEFAULT);
  };

  const onDisconnectClick = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    source.connectionId && (await deleteDatasourceById(source.connectionId));
    trackOnboardingEvent(TrackingEvents.ONBOARDING_SYSTEM_DISCONNECT);
    handleSystemConection(source, ConnectorMode.DEFAULT);
  };

  return (
    <BigidTooltip title={mode === ConnectorMode.DISABLED && 'The free plan includes 1 auto-scan system'}>
      <div
        data-aid="onboarding-connector-tile"
        key={source.name}
        className={cx(classes.system, {
          [classes.error]: !!error,
          [classes.disabled]: mode === ConnectorMode.DISABLED,
          [classes.connecting]: mode === ConnectorMode.CONNECTING,
          [classes.connected]: mode === ConnectorMode.CONNECTED,
        })}
        onClick={() =>
          mode !== ConnectorMode.DISABLED &&
          mode !== ConnectorMode.CONNECTING &&
          mode !== ConnectorMode.CONNECTED &&
          onConnectorSelect(source)
        }
      >
        <img className={classes.systemLogo} src={getDataSourceLogo(source.name)} alt={source.name} />
        <span className={cx(classes.systemInfo, classes.systemText)}>{source.displayName}</span>
        {mode === ConnectorMode.CONNECTED && (
          <div className={classes.disconnect} onClick={onDisconnectClick}>
            Disconnect
          </div>
        )}
        {!!error && <div className={classes.errorText}>{error}</div>}
        {mode === ConnectorMode.CONNECTING && (
          <BigidStatusBadge
            label="Connecting"
            icon={<BigidInProgressIcon />}
            type={BigidStatusBadgeType.GENERAL}
            size={BigidStatusBadgeSize.SMALL}
          />
        )}
        {mode === ConnectorMode.CONNECTED && (
          <BigidStatusBadge
            label="Connected"
            icon={<BigidApplyIcon />}
            type={BigidStatusBadgeType.SUCCESS}
            size={BigidStatusBadgeSize.SMALL}
          />
        )}
      </div>
    </BigidTooltip>
  );
};
