import { ActionsType, NoteType, NoteUpdateType } from '../../../types';
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { StyledRequestManagerNotesDialog } from './NotesDialogStyles';
import { useTheme } from '@mui/styles';
import { useFormatting } from '../../../hooks/useFormatting';
import { Box, Stack } from '@mui/system';
import { IconButton } from '@mui/material';
import { BigidAttachmentIcon, BigidMoreActionIcon } from '@bigid-ui/icons';
import {
  BigidCaption,
  BigidAvatar,
  BigidDialog,
  BigidHeading6,
  BigidMenu,
  BigidMenuItem,
  BigidTextField,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { fileUploadRules } from '../../../configs/files';
import { FileUploaderLink } from '../../FileUploaderLink';
import useNoteItem from './useNoteItem';
import { DeletionNoteDialog } from '../DeletionNoteDialog';
import { useToggle } from '../../../hooks';
import { getDataSourceLogo } from '../../../utils/dataSourceUtils';
import { NOTE_DEFAULT_MAX_SYMBOLS } from './useNotesDialog';
import { NoteFileLoader } from './NoteFileLoader';
import { NoteFileChip } from './NoteFileChip';

type NoteItemPropsType = {
  note: NoteType;
  noteIndex: number;
  scrollRef: MutableRefObject<null | HTMLDivElement> | null;
  isHighlighted?: boolean;
  onEdit: (note: NoteUpdateType) => Promise<void>;
  onDelete: (id: string) => void;
  readOnly?: boolean;
  disableSaving?: boolean;
};

export const NoteItem: FC<NoteItemPropsType> = ({
  note,
  scrollRef,
  isHighlighted,
  noteIndex,
  onEdit,
  onDelete,
  readOnly,
  disableSaving,
}) => {
  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);
  const iconRef = useRef<HTMLButtonElement | null>(null);

  const { Note, File, Body1 } = StyledRequestManagerNotesDialog;

  const theme = useTheme();
  const { getFormattedDate } = useFormatting();
  const { value: isOpenDeleteModal, setTrue: openDeleteModal, setFalse: closeDeleteModal } = useToggle(false);

  const onDeleteClick = (id: string) => {
    openDeleteModal();
    closeMenu();
    setSelectedNoteId(id);
  };

  const {
    fileToRemove,
    isOpenMenu,
    files,
    handleEditNote,
    onRemoveFileContinue,
    onFileRemove,
    handleFileUpload,
    onChangeEditNote,
    onFileClick,
    openMenu,
    closeMenu,
    setFileToRemove,
    formikEditNote,
    uploading,
  } = useNoteItem(note);

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  }, [scrollRef]);

  return (
    <Note ref={scrollRef}>
      {!formikEditNote.values.id ? (
        <Stack
          flexDirection="row"
          border={`1px solid ${theme.vars.palette.bigid?.gray200}`}
          bgcolor={isHighlighted ? theme.vars.palette.bigid?.blue50 : 'none'}
          p={2}
          borderRadius="6px"
        >
          <Box mt={1}>
            <BigidAvatar userName={note.userFullName} size="small" />
          </Box>
          <Box width="100%" ml={1}>
            <Stack flexDirection="row" justifyContent="space-between">
              <Stack>
                <BigidHeading6 data-aid={`note-user-full-name-${noteIndex}`}>{note.userFullName} </BigidHeading6>
                <Body1 color={theme.vars.palette.bigid?.gray400}>
                  {getFormattedDate(note.date, { hour: 'numeric', minute: 'numeric' })} | Stage: {note.stage}
                </Body1>
              </Stack>
              <Box>
                {!readOnly && (ActionsType.UPDATE_NOTE in note.actions || ActionsType.DELETE_NOTE in note.actions) && (
                  <>
                    <IconButton ref={iconRef} onClick={openMenu}>
                      <BigidMoreActionIcon dataAid={`more-button-${noteIndex}`} />
                    </IconButton>
                    <BigidMenu
                      anchorEl={iconRef.current as Element}
                      open={isOpenMenu}
                      onMenuClose={closeMenu}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      minWidth={155}
                      transformOrigin={{ horizontal: 0, vertical: 0 }}
                    >
                      {ActionsType.UPDATE_NOTE in note.actions && (
                        <BigidMenuItem onClick={handleEditNote} dataAid="edit-button">
                          Edit
                        </BigidMenuItem>
                      )}
                      {ActionsType.DELETE_NOTE in note.actions && (
                        <BigidMenuItem onClick={() => onDeleteClick(note.id)} dataAid="delete-button">
                          Delete
                        </BigidMenuItem>
                      )}
                    </BigidMenu>
                  </>
                )}
              </Box>
            </Stack>
            {note.dataSourceName && (
              <Stack flexDirection="row" alignItems="center" mb={2}>
                {note.dataSourceType && (
                  <Stack alignItems="center" justifyContent="center" mr={0.5}>
                    <img
                      height={20}
                      width={20}
                      src={getDataSourceLogo(note.dataSourceType)}
                      alt={note.dataSourceName}
                    />
                  </Stack>
                )}
                <Body1 margin="5px">{'Data Source: ' + note.dataSourceName}</Body1>
              </Stack>
            )}
            <Body1 data-aid={`note-content-${noteIndex}`}>{note.content}</Body1>
            <Stack flexDirection="row" alignItems="center" flexWrap="wrap">
              {note.files.map(file => (
                <File
                  key={file.id}
                  onClick={() => onFileClick(file.id, file.fileName)}
                  data-aid={`added-note-file_${file.id}`}
                >
                  <BigidAttachmentIcon />
                  <Body1>{file.fileName}</Body1>
                </File>
              ))}
            </Stack>
          </Box>
        </Stack>
      ) : (
        <Stack bgcolor={theme.vars.palette.bigid?.gray150} p={2} borderRadius={1}>
          <Stack flexDirection="row">
            <Box mt={1}>
              <BigidAvatar userName={note.userFullName} size="small" />
            </Box>
            <Box width="100%" ml={1}>
              <Stack mb={2}>
                <BigidHeading6 fontWeight={700} data-aid={`note-user-full-name-${noteIndex}`}>
                  {note.userFullName}
                </BigidHeading6>
                <Body1 color={theme.vars.palette.bigid?.gray400}>
                  {getFormattedDate(note.date, { hour: 'numeric', minute: 'numeric' })} | Stage: {note.stage}
                </Body1>
              </Stack>
            </Box>
          </Stack>
          <BigidTextField
            value={formikEditNote.values.content}
            onChange={e => onChangeEditNote(e.target.value)}
            multiline
            errorMessage={formikEditNote.errors.content}
            dataAid="edit-note-textarea"
          />
          <BigidCaption size="small">
            {formikEditNote.values.content.length}/{NOTE_DEFAULT_MAX_SYMBOLS}
          </BigidCaption>
          <Stack flexDirection="row" alignItems="center" flexWrap="wrap">
            {files.map(file => (
              <Box key={file.id} mt={1}>
                <NoteFileChip
                  onDelete={() => onFileRemove(file)}
                  data-aid={`added-note-file_${file.id}`}
                  label={file.fileName}
                />
              </Box>
            ))}
          </Stack>
          {uploading && <NoteFileLoader />}
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
            pt={2}
            borderTop={`1px solid ${theme.vars.palette.bigid?.gray300}`}
          >
            <FileUploaderLink
              maxSize={fileUploadRules.noteFiles.MAX_FILE_SIZE_MB}
              isDisabled={files.length >= fileUploadRules.noteFiles.MAX_FILES}
              onSelect={handleFileUpload}
              format={fileUploadRules.noteFiles.FORMATS}
              tooltip={`Files supported: png, jpg, jpeg, csv, pdf.\nMax. ${fileUploadRules.noteFiles.MAX_FILES} files, no more than\n${fileUploadRules.noteFiles.MAX_FILE_SIZE_MB} MB each`}
              testId="note-file-uploader"
            />
            <Stack flexDirection="row">
              <TertiaryButton
                text="Cancel"
                onClick={() => {
                  formikEditNote.resetForm();
                  closeMenu();
                }}
                size="medium"
              />
              <PrimaryButton
                text="Save"
                onClick={() => {
                  onEdit(formikEditNote.values);
                  formikEditNote.resetForm();
                  closeMenu();
                }}
                size="medium"
                disabled={
                  !formikEditNote.isValid || !formikEditNote.values.content.length || disableSaving || uploading
                }
                dataAid="edit-note-save-button"
              />
            </Stack>
          </Stack>
        </Stack>
      )}
      <BigidDialog
        isOpen={!!fileToRemove}
        onClose={() => setFileToRemove(undefined)}
        title="Delele file"
        maxWidth="xs"
        buttons={[
          {
            text: 'Cancel',
            component: TertiaryButton,
            onClick: () => setFileToRemove(undefined),
            dataAid: 'remove-file-dialog__cancel',
          },
          {
            text: 'Yes, continue',
            component: PrimaryButton,
            onClick: onRemoveFileContinue,
            dataAid: 'remove-file-dialog__apply',
          },
        ]}
      >
        You are about to delete a file, sure you want to continue?
      </BigidDialog>
      <DeletionNoteDialog
        isOpen={isOpenDeleteModal}
        onClose={closeDeleteModal}
        onSubmit={() => {
          if (selectedNoteId) {
            onDelete(selectedNoteId);
            closeDeleteModal();
          }
        }}
      />
    </Note>
  );
};
