import { styled } from '@mui/material/styles';
import { BORDER_RADIUS } from '../../../assets/styles/constants';

const PREFIX = 'WorkflowEmailTemplate';

export const classes = {
  root: `${PREFIX}-root`,
  testEmail: `${PREFIX}-testEmail`,
};

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',
  },
  [`& .${classes.testEmail}`]: {
    width: 176,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '7px 16px',
    margin: '0 5px',
    borderRadius: BORDER_RADIUS,
    cursor: 'pointer',
  },
  [`& .${classes.testEmail}:hover`]: {
    backgroundColor: theme.vars.palette.bigid?.gray200,
  },
}));
