import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    padding: '12px 16px 12px 20px',
    fontSize: '0.8125rem',
    background: theme.vars.palette.bigid?.gray150,
    borderRadius: 8,
  },
  info: {
    backgroundColor: theme.vars.palette.bigid?.gray150,
  },
  error: {
    backgroundColor: '#FFF5F4',
  },
  title: {
    fontWeight: 800,
  },
  messageBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  timestampBlock: {
    flexShrink: 0,
  },
}));
