import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { TertiaryButton, BigidDropdown, BigidDropdownOption } from '@bigid-ui/components';
import { useNavigate } from 'react-router-dom';

import { useStyles } from './OpenRequestTrendsDashboardStyles';
import { TrendRequestsTypes, StatisticPeriodValuesType, RegulationType } from '../../types';
import { TabsPanel } from '../../components/TabsPanel';
import { LineChart } from '../../components/LineChart';
import { getTrendOpenRequests, getTrendOpenRequestsByType, getTrendOpenRequestsByStatus } from '../../services/request';
import { SingleColor, RequestsByTypeColors, BigidMeChartsColors } from '../../assets/styles';
import { simpleSeries, typeSeries, statusSeries } from '../Dashboard/utils';
import { BigidChartLegendPosition } from '../../assets/misc';

const openRequestsSelectOptions = [
  { displayValue: 'Total Requests', id: TrendRequestsTypes.TOTAL.toString(), value: TrendRequestsTypes.TOTAL },
  { displayValue: 'Request Type', id: TrendRequestsTypes.TYPE.toString(), value: TrendRequestsTypes.TYPE },
  { displayValue: 'Request Status', id: TrendRequestsTypes.STATUS.toString(), value: TrendRequestsTypes.STATUS },
];

export const OpenRequestTrendsDashboard = ({
  regulation = '',
  statisticsPeriod = StatisticPeriodValuesType.week,
}: {
  regulation: RegulationType;
  statisticsPeriod: StatisticPeriodValuesType;
}) => {
  const classes = useStyles();
  const [openTrends, setOpenTrends] = useState<TrendRequestsTypes>(TrendRequestsTypes.STATUS);

  const navigate = useNavigate();

  const openTrendsDropdownValue = useMemo(
    () => openRequestsSelectOptions.filter(o => o.value === openTrends),
    [openTrends],
  );

  const handleOpenTrendsSelect = useCallback((options: BigidDropdownOption[]) => {
    setOpenTrends(options[0].value);
  }, []);

  const fetchRequests = useCallback(
    () => getTrendOpenRequests({ period_days: statisticsPeriod, regulation }),
    [regulation, statisticsPeriod],
  );

  const fetchRequestsByType = useCallback(
    () => getTrendOpenRequestsByType({ period_days: statisticsPeriod, regulation }),
    [regulation, statisticsPeriod],
  );

  const fetchRequestsByStatus = useCallback(
    () => getTrendOpenRequestsByStatus({ period_days: statisticsPeriod, regulation }),
    [regulation, statisticsPeriod],
  );

  return (
    <div className={classes.trendWrapper}>
      <div className={classes.trendHeader}>
        <h4 className={classes.trendTitle}>
          <TertiaryButton size="medium" text="Open Request Trends" onClick={() => navigate('/request-manager')} />
        </h4>
        <Box display="flex">
          <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
            View by
          </Typography>
          <Box>
            <BigidDropdown
              dataAid="open-requests"
              value={openTrendsDropdownValue}
              onSelect={handleOpenTrendsSelect}
              options={openRequestsSelectOptions}
            />
          </Box>
        </Box>
      </div>
      <TabsPanel value={openTrends} index={TrendRequestsTypes.TOTAL}>
        <LineChart
          fetchData={fetchRequests}
          legend={BigidChartLegendPosition.TOPLEFT}
          colors={SingleColor}
          title="Requests"
          series={simpleSeries}
          name="Total Open Requests"
        />
      </TabsPanel>
      <TabsPanel value={openTrends} index={TrendRequestsTypes.TYPE}>
        <LineChart
          fetchData={fetchRequestsByType}
          legend={BigidChartLegendPosition.TOPLEFT}
          title="Requests"
          colors={RequestsByTypeColors}
          series={typeSeries}
        />
      </TabsPanel>
      <TabsPanel value={openTrends} index={TrendRequestsTypes.STATUS}>
        <LineChart
          fetchData={fetchRequestsByStatus}
          legend={BigidChartLegendPosition.TOPLEFT}
          title="Requests"
          colors={BigidMeChartsColors}
          series={statusSeries}
        />
      </TabsPanel>
    </div>
  );
};
