import React, { ReactElement, ReactNode } from 'react';
import { BigidTooltip, BigidTooltipProps } from '@bigid-ui/components';

import { useStyles } from './TutorialTooltipStyles';

type TutorialTooltipType = Pick<BigidTooltipProps, 'placement' | 'className'> & {
  title?: string;
  content?: ReactNode;
  children: ReactElement;
};

export const TutorialTooltip = ({ children, title, content, placement, className }: TutorialTooltipType) => {
  const classes = useStyles();

  return (
    <BigidTooltip
      title={
        <div className={classes.content}>
          <div className={classes.title}>{title}</div>
          {content}
        </div>
      }
      className={className}
      placement={placement}
      open
    >
      {children}
    </BigidTooltip>
  );
};
