export const fileUploadRules = {
  default: {
    MAX_FILES: 5,
    MAX_FILE_SIZE_MB: 300,
    FORMATS: '.jpg, .jpeg, .png, .pdf, .csv',
  },
  noteFiles: {
    MAX_FILES: 5,
    MAX_FILE_SIZE_MB: 10,
    FORMATS: '.jpg, .jpeg, .png, .pdf, .csv',
  },
  logoFiles: {
    MAX_FILES: 5,
    MAX_FILE_SIZE_MB: 10,
    FORMATS: '.jpg, .jpeg, .png',
  },
};
