import React, { Children, cloneElement, FunctionComponent, isValidElement, ReactElement, ReactNode } from 'react';

import { useStyles } from './MinimizerContainerStyles';

export interface MinimizerContainerType {
  minimized?: boolean;
  className: ClassKey;
  skip?: boolean;
  children?: ReactNode;
}

type ClassKey = 'chips' | 'chip' | 'general' | 'bar' | 'title';

export const MinimizerContainer: FunctionComponent<MinimizerContainerType> = ({
  minimized,
  className,
  skip,
  children,
}) => {
  const classes = useStyles({ minimized });
  return (
    <div className={classes[className]}>
      {!skip
        ? Children.map(children, child =>
            isValidElement(child) ? cloneElement(child as ReactElement<any>, { minimized }) : child,
          )
        : children}
    </div>
  );
};
