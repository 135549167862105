import { STEP_VISITED, STEP_HALFCOMPLETED, STEP_COMPLETED } from '../../../components/MeStepper';
import { trackOnboardingEvent } from '../../../services/eventsTrackingService';
import { OnboardingStepsType, StepperStates } from '../../../types';
import { TrackingEvents } from '../../../types/TrackingEventTypes';
import { onboardingSteps } from '../onboardingUtils';

export const defaultProgressStepper = [0, STEP_VISITED, 0, 0, 0, 0, 0];

export const uncheckData = <T>(data: T[]) => data.map(item => ({ ...item, enabled: false }));

export const onBack = (activeStep: number, goTo: (step: number, activeStep: number) => void) => {
  switch (activeStep) {
    case StepperStates.REGIONS: {
      trackOnboardingEvent(TrackingEvents.SELECT_REGION_BACK);
      break;
    }
    case StepperStates.REGULATIONS: {
      trackOnboardingEvent(TrackingEvents.SELECT_REGULATIONS_BACK);
      break;
    }
    case StepperStates.PRIVACY_CENTER_STEP: {
      trackOnboardingEvent(TrackingEvents.REQUEST_TYPE_BACK);
      break;
    }
    case StepperStates.REQUEST_FORM: {
      trackOnboardingEvent(TrackingEvents.FORM_BACK);
      break;
    }
    case StepperStates.CONNECT_DATA: {
      trackOnboardingEvent(TrackingEvents.ONBOARDING_SYSTEM_BACK);
      break;
    }
  }
  goTo(-1, activeStep);
};
export const onNext = async (
  activeStep: number,
  goTo: (step: number, activeStep: number) => void,
  handleStepVisit: (step: number, progress: number) => void,
  updateRegions: () => void,
  updateRegulations: () => void,
  updateUseCases: () => void,
  sendCurrentStepForTutorial: (nextStep: OnboardingStepsType) => void,
) => {
  try {
    switch (activeStep) {
      case StepperStates.REGIONS: {
        trackOnboardingEvent(TrackingEvents.SELECT_REGION_NEXT);
        updateRegions();
        handleStepVisit(StepperStates.REGIONS, STEP_HALFCOMPLETED);
        break;
      }
      case StepperStates.REGULATIONS: {
        trackOnboardingEvent(TrackingEvents.SELECT_REGULATIONS_NEXT);
        updateRegulations();
        handleStepVisit(StepperStates.REGIONS, STEP_COMPLETED);
        handleStepVisit(StepperStates.PRIVACY_CENTER_STEP, STEP_VISITED);
        break;
      }
      case StepperStates.PRIVACY_CENTER_STEP: {
        trackOnboardingEvent(TrackingEvents.REQUEST_TYPE_NEXT);
        updateUseCases();
        handleStepVisit(StepperStates.PRIVACY_CENTER_STEP, STEP_HALFCOMPLETED);
        break;
      }
      case StepperStates.REQUEST_FORM: {
        trackOnboardingEvent(TrackingEvents.FORM_NEXT);
        handleStepVisit(StepperStates.PRIVACY_CENTER_STEP, STEP_COMPLETED);
        handleStepVisit(StepperStates.CONNECT_DATA, STEP_VISITED);
        break;
      }
      case StepperStates.CONNECT_DATA: {
        trackOnboardingEvent(TrackingEvents.ONBOARDING_SYSTEM_NEXT);
        handleStepVisit(StepperStates.CONNECT_DATA, STEP_COMPLETED);
        break;
      }
    }
    sendCurrentStepForTutorial(onboardingSteps[activeStep + 1]);
    goTo(1, activeStep);
  } catch {}
};

export const onSkip = (
  activeStep: number,
  stepsProgress: number[],
  goTo: (step: number, activeStep: number) => void,
  updateEmptyRegions: () => void,
  handleStepVisit: (step: number, progress: number) => void,
) => {
  switch (activeStep) {
    case StepperStates.REGIONS: {
      trackOnboardingEvent(TrackingEvents.SELECT_REGION_SKIP);
      updateEmptyRegions();
      handleStepVisit(StepperStates.REGIONS, STEP_HALFCOMPLETED);
      break;
    }
    case StepperStates.REGULATIONS: {
      handleStepVisit(StepperStates.REGIONS, stepsProgress[activeStep]);
      break;
    }
    case StepperStates.PRIVACY_CENTER_STEP: {
      handleStepVisit(StepperStates.PRIVACY_CENTER_STEP, stepsProgress[activeStep]);
      break;
    }
    case StepperStates.REQUEST_FORM: {
      handleStepVisit(StepperStates.PRIVACY_CENTER_STEP, stepsProgress[activeStep]);
      break;
    }
    case StepperStates.CONNECT_DATA: {
      trackOnboardingEvent(TrackingEvents.ONBOARDING_SYSTEM_SKIP);
      handleStepVisit(StepperStates.CONNECT_DATA, stepsProgress[activeStep]);
      break;
    }
  }
  goTo(1, activeStep);
};
