import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    width: 56,
    height: 'calc(100% - 65px)',
    margin: 5,
    borderRadius: 7,
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.28)',
    backgroundColor: theme.vars.palette.bigid?.gray600,
    zIndex: 10,
    transition: 'width 0.3s ease',
    padding: '0 5px',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      margin: '5px auto',
      height: 4,
      width: 'calc(100% - 10px)',
      borderRadius: 3,
      backgroundColor: theme.vars.palette.bigid?.primary400,
    },
  },
  expanded: {
    transitionDelay: '0.75s',
    width: 250,
    '& > div > div > section': {
      visibility: 'visible',
      transition: 'visibility 0.75s',
      transitionDelay: '0.75s',
      padding: '15px 0 0 0',
      height: 300,
      '&:after': {
        transitionDelay: '0.75s',
        opacity: 1,
      },
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  navigation: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0',
    alignItems: 'center',
    width: 'calc(100% - 10px)',
    height: 'calc(100% - 65px)',
    overflowX: 'hidden',
    overflowY: 'auto',

    '& > a, & > div': {
      width: 'calc(100% - 10px)',
      position: 'relative',
      margin: 0,
      padding: '20px 10px 15px',
    },
    '& > a, & > div > span': {
      color: theme.vars.palette.bigid?.gray400,
      '& > label': {
        position: 'absolute',
        top: 0,
        left: 0,
        padding: '20px 10px 20px 42px',
        color: theme.vars.palette.bigid?.gray400,
        fontSize: '0.75rem',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        '&::before': {
          content: '""',
          position: 'absolute',
          width: 5,
          height: 5,
          top: 13,
          left: -3,
          transform: 'rotate(-45deg)',
          borderTop: `5px solid ${theme.vars.palette.bigid?.gray600}`,
          borderLeft: `5px solid ${theme.vars.palette.bigid?.gray600}`,
        },
      },
      '& > $sidebarItem > svg:last-of-type, & > svg:last-of-type': {
        display: 'none',
      },
      '& > span': {
        position: 'absolute',
        top: 17,
        left: 200,
        zIndex: 1000,
        cursor: 'pointer',
      },
    },
    '& > a': {
      '&:not(:last-child)': {
        borderBottom: '1px solid rgba(151,151,151,0.3)',
      },
    },
    '& > div > section': {
      visibility: 'hidden',
      padding: '15px 0 0 50px',
      transition: 'padding 0.3s ease, height 0s linear',
      height: 0,
      '&:after': {
        content: '""',
        borderBottom: '1px solid rgba(151,151,151,0.3)',
        position: 'absolute',
        width: '100%',
        top: 56,
        left: 0,
        transition: 'opacity 0s linear',
        opacity: 0,
      },
      '& > a': {
        whiteSpace: 'nowrap',
        color: theme.vars.palette.bigid?.gray400,
        fontSize: '0.75rem',
        display: 'block',
        padding: '10px 0',
        textDecorationColor: 'transparent',
      },
    },
  },
  settings: {
    userSelect: 'none',
  },
  active: {
    '& > label': {
      color: `${theme.vars.palette.bigid?.white} !important`,
    },
    '& > $sidebarItem > svg, & > svg': {
      width: 0,
    },
    '& > $sidebarItem > svg:last-of-type, & > svg:last-of-type': {
      display: 'inline-block !important',
      width: 15,
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      margin: '6px 0',
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: theme.vars.palette.bigid?.white,
    },
  },
  subActive: {
    color: `${theme.vars.palette.bigid?.white} !important`,
  },
  itemWithTutorial: {
    position: 'relative',
  },
  tutorialTooltipWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: -15,
    bottom: 0,
    left: -15,
    paddingLeft: 5,
    border: `1px solid ${theme.vars.palette.bigid?.gray400}`,
    borderRadius: 2,

    '&::before': {
      width: 4,
      height: 4,
      content: '""',
      borderRadius: '50%',
      background: theme.vars.palette.bigid?.white,
    },
  },
  tutorialTooltipAnchor: {
    position: 'absolute',
    right: 0,
  },
  consentTooltipContainer: {
    position: 'absolute',
    right: 0,
  },
  upgradeButton: {
    marginTop: 32,
  },
  sidebarItem: {
    position: 'relative',
    display: 'inline',
    cursor: 'pointer',
  },
  sidebarSubItem: {
    position: 'relative',
    display: 'inline',
    marginLeft: 5,
  },
  notificationDot: {
    position: 'absolute',
    top: -6,
    right: -6,
    border: `1px solid ${theme.vars.palette.bigid?.white}`,
    width: 7,
    height: 7,
    borderRadius: 4,
    background: theme.vars.palette.bigid?.primary400,
  },
  activateLabel: {
    position: 'absolute',
    top: '20px !important',
    left: '180px !important',
    right: 0,
    fontSize: '0.75rem',
    color: theme.vars.palette.bigid?.primary400,
  },
  subItemLabel: {
    cursor: 'pointer',
  },
}));
