import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { BigidTooltip } from '@bigid-ui/components';
import { BigidButton, StyledButtonType } from '@bigid-ui/components/lib/BigidButton/BigidButton';
import { PricingPlanUpgradeStateType } from '../../types';
import { PricingPlanContainer } from '../../state/pricingPlanContainer';

import { useStyles } from './MeUpgradeButtonStyles';
import { loadPricingPlanUpgradeState } from '../../services/registration';

export type MeUpgradeButtonPropsType = Partial<Pick<StyledButtonType, 'margin'>> & {
  tooltip?: string;
  classes?: string;
};

export const MeUpgradeButton: FC<MeUpgradeButtonPropsType> = ({ margin, tooltip, classes }) => {
  const { isEnterprise, plans } = PricingPlanContainer.useContainer();
  const [upgradeState, setUpgradeState] = useState<PricingPlanUpgradeStateType>();
  const ownClasses = useStyles();

  const navigate = useNavigate();

  const button = useMemo(
    () => (
      <div className={classes}>
        <BigidButton
          size="medium"
          variant="outlined"
          margin={margin}
          text="Upgrade"
          palette="secondary"
          onClick={() => navigate('/pricing')}
        />
      </div>
    ),
    [classes, margin, navigate],
  );

  const fetchUpgradeState = async () => {
    const state = await loadPricingPlanUpgradeState();
    setUpgradeState(state);
  };

  useEffect(() => {
    fetchUpgradeState();
  }, []);

  // if plans === undefined, then they are not loaded and we don't know if isEnterprise is true
  if (!plans) return null;

  return !isEnterprise && upgradeState?.upgradeEnabled ? (
    tooltip ? (
      <BigidTooltip
        title={
          tooltip && (
            <>
              {tooltip}
              <div>
                <Link to="/pricing" className={ownClasses.pricingPageLink}>
                  Compare Plans
                </Link>
              </div>
            </>
          )
        }
        disableInteractive={!tooltip}
      >
        <span>{button}</span>
      </BigidTooltip>
    ) : (
      button
    )
  ) : null;
};
