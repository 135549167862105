import makeStyles from '@mui/styles/makeStyles';
import { primaryBorder } from '../../../assets/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    height: 558,
    width: 1060,
    overflow: 'hiden',
    borderRadius: 12,
    margin: 'auto',
    backgroundColor: theme.vars.palette.bigid?.white,
    border: primaryBorder,
  },
  wrapper: {
    fontSize: '0.9375rem',
    textAlign: 'center',
    color: theme.vars.palette.bigid?.gray700,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingBottom: 12,
  },
  linkWrapper: {
    padding: '24px 0 20px',
    margin: 'auto',
    display: 'table',
  },
  link: {
    display: 'inline-block',
    margin: 22,
    color: theme.vars.palette.bigid?.gray700,
    fontSize: '0.8125rem',
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
