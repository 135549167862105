import React, { FC } from 'react';
import { OnboardingRegulation, OnboardingStepType, SettingsProfileGetType, UseCaseGetType } from '../../../types';
import { BigidAcademyIcon } from '@bigid-ui/icons';
import { UseCase } from '../../../components/UseCase';

import { useStyles } from './RequestsTypeStepStyles';
import { cloneDeep } from 'lodash';

export const RequestsTypeStep: FC<OnboardingStepType> = ({ data: { useCases }, handleDataChange }) => {
  const classes = useStyles();

  if (!useCases) return <></>;

  const showTooltip = Boolean(useCases.filter(({ enabled }) => enabled).length < useCases.length);

  return (
    <div className={classes.root}>
      <div className={classes.text}>Which request types do you want your users to submit?</div>
      <div className={classes.wrapper}>
        {useCases.map(({ id, type, enabled, ordinal, translations }) => (
          <UseCase
            compact
            tooltip={showTooltip ? 'Choose at least one request type' : ''}
            key={id}
            id={id}
            type={type}
            enabled={enabled}
            ordinal={ordinal}
            translations={translations}
            onChange={enabled => {
              const newUseCases = cloneDeep(useCases);
              const changeIndex = newUseCases!.findIndex(
                (item: UseCaseGetType | OnboardingRegulation | SettingsProfileGetType) => item.id === id,
              );
              newUseCases![changeIndex].enabled = enabled;
              handleDataChange('useCases', newUseCases);
            }}
          />
        ))}
      </div>
      <div className={classes.expert}>
        <BigidAcademyIcon />
        According to most privacy regulations, organizations must provide their users the right to view and
        <br /> delete their personal data, as well as the ability to manage consent and data sharing preferences.
      </div>
    </div>
  );
};
