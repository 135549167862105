import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: () => ({
    position: 'relative',
    display: 'inline-block',
    cursor: 'pointer',
  }),
  upload: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    padding: '6px 0',
    display: 'inline-block',
    opacity: 0,
    cursor: 'pointer',
  },
  error: {
    color: theme.vars.palette.bigid?.red700,
    marginLeft: 14,
  },
}));
