import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ReactComponent as StepCompletedIcon } from '../../assets/icons/stepper_completed.svg';
import { TutorialContainer } from '../../state/tutorialContainer';
import { TutorialStepsType } from '../../types/TutorialTypes';

import { useStyles } from './TutorialStyles';

type TutorialStepType = {
  text: string;
  link?: string;
  completed?: boolean;
  name: TutorialStepsType;
  onClick: () => void;
};

export const TutorialStep = ({ text, link = '', name, completed, onClick }: TutorialStepType) => {
  const classes = useStyles();
  const { activeTutorialStep } = TutorialContainer.useContainer();

  return (
    <Link
      to={`${link}?tutorialStep=${name}`}
      className={cx(classes.tutorialStep, {
        active: name === activeTutorialStep,
      })}
      onClick={onClick}
    >
      {completed ? (
        <StepCompletedIcon className={classes.tutorialStepIcon} />
      ) : (
        <div className={cx(classes.tutorialStepIcon, classes.notPassedStep)} />
      )}
      <span className={classes.tutorialStepText}>{text}</span>
    </Link>
  );
};
