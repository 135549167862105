import React from 'react';
import { FormikProps } from 'formik';

import { BigidBody3, BigidDropdown, BigidDropdownOption, BigidDropdownProps, BigidTooltip } from '@bigid-ui/components';
import { find, map } from 'lodash';

export type BigidDropdownWithFormProps = Omit<BigidDropdownProps, 'onSelect' | 'onCreate'> & {
  isMulti?: boolean;
  formData: FormikProps<any>;
  name: string;
  options: BigidDropdownOption[];
  value: number[] | string[];
  label?: string;
  tip?: string;
  onCreate?: (name: string, value: string, options?: BigidDropdownOption[] | undefined) => Promise<any>;
  // TODO: after implementation https://bigidio.atlassian.net/browse/BDT-88562
  // onBlur?: () => void;
};

export const BigidDropdownWithForm = <T,>({
  name,
  formData,
  tip,
  isMulti,
  onCreate,
  // onBlur,
  label,
  value,
  options,
  ...dropdownProps
}: BigidDropdownWithFormProps) => {
  const { touched } = formData.getFieldMeta(name as string);
  const error = touched ? (formData.errors[name as keyof T] as string) : '';

  const onSelect = (options: BigidDropdownOption[]) => {
    if (isMulti) {
      formData.setFieldValue(
        name.toString(),
        map(options, ({ value }) => value),
      );
    } else {
      formData.setFieldValue(name.toString(), options[0] ? options[0].value : undefined);
    }
  };

  return (
    <BigidTooltip title={tip}>
      <>
        {label && <BigidBody3>{label}</BigidBody3>}
        <BigidDropdown
          {...dropdownProps}
          isMulti={isMulti}
          options={options}
          value={map(value, val => find(options, ({ value }) => `${value}` === `${val}`)!)}
          onCreate={async (value: string, options?: BigidDropdownOption[] | undefined) => {
            return onCreate && (await onCreate(name, value, options));
          }}
          onSelect={onSelect}
          isError={!!error}
          errorMessage={error}
          // onBlur={() => {
          //   onBlur && onBlur();
          //   formData.setFieldTouched(name as string, true);
          // }}
        />
      </>
    </BigidTooltip>
  );
};
