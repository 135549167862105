import makeStyles from '@mui/styles/makeStyles';
import { BORDER_RADIUS } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputRoot: {
    fontSize: '0.85rem',
    backgroundColor: theme.vars.palette.bigid?.white,
    border: `1px solid ${theme.vars.palette.bigid?.gray500}`,
    borderRadius: BORDER_RADIUS,
    width: '100%',
    height: 100,
    padding: 10,
    marginTop: 5,
    '& > textarea': {
      height: '100% !important',
      padding: 10,
    },
    '&.Mui-focused': {
      border: `1px solid ${theme.vars.palette.bigid?.blue900}`,
    },
  },
  label: {
    textAlign: 'left',
    color: theme.vars.palette.bigid?.gray600,
    fontWeight: 500,
    fontSize: '0.8125rem',
    lineHeight: '1.7rem',
    transform: 'none',
  },
  inputError: {
    border: `1px solid ${theme.vars.palette.bigid?.red500} !important`,
  },
  error: {
    fontSize: '0.7rem',
    color: `${theme.vars.palette.bigid?.red500} !important`,
  },
  counter: {
    fontSize: '0.56rem',
    padding: '5px 0',
    textAlign: 'end',
  },
}));
