import React, { FC } from 'react';
import { AppealGuidedTourStepTemplate } from './AppealGuidedTourStepTemplate';

interface BrandsEditStepProps {
  dataAid?: string;
}

export const BrandsEditStep: FC<BrandsEditStepProps> = ({ dataAid }) => {
  return (
    <AppealGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Select Brand"
      firstSectionText="Select the brand in which to enable the Appeal request type"
    />
  );
};
