import React, { FC } from 'react';
import { get } from 'lodash';
import { FormikErrors } from 'formik';
import {
  BigidFormFieldLabelWrapper,
  BigidSwitch,
  BigidTextField,
  BigidFormFieldLabelWrapperProps,
} from '@bigid-ui/components';
import { LanguageType } from '@consumer/state/languageContainer';
import { Root, classes } from './WorkflowEmailTemplateStyles';
import { BigidMeTooltipWithIcon, EmailTemplate, Loader } from '../../../components';
import { UpdateEmailTemplateType } from '../../../types';

interface WorkflowEmailTemplatePropsType {
  namespace?: string;
  selectedLanguage: LanguageType;
  emailTemplate?: UpdateEmailTemplateType;
  emailTemplateErrors?: FormikErrors<UpdateEmailTemplateType | unknown>;
  hideButton?: boolean;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
  testIdPrefix?: string;
  emailTemplateAllFieldRequired?: boolean;
  buttonFieldLabelWrapperProps?: Pick<BigidFormFieldLabelWrapperProps, 'isRequired' | 'tooltipText'>;
  bodyTooltipText?: string;
}

export const WorkflowEmailTemplate: FC<WorkflowEmailTemplatePropsType> = ({
  namespace = '',
  selectedLanguage,
  hideButton,
  emailTemplate,
  emailTemplateErrors,
  setFieldValue,
  testIdPrefix = '',
  emailTemplateAllFieldRequired = false,
  buttonFieldLabelWrapperProps = {},
  bodyTooltipText,
}) => {
  if (!emailTemplate) return <Loader />;

  return (
    <Root className={classes.root}>
      <EmailTemplate
        allFieldsRequired={emailTemplateAllFieldRequired}
        testIdPrefix={testIdPrefix}
        selectedLanguage={selectedLanguage}
        setFieldValue={setFieldValue}
        emailTemplate={emailTemplate}
        emailTemplateErrors={emailTemplateErrors}
        namespace={namespace}
        bodyTooltipText={bodyTooltipText}
      />
      {!hideButton && (
        <div className={classes.textRow}>
          <BigidFormFieldLabelWrapper
            id="buttonText_field"
            label="Button"
            errorIsShown
            error={get(emailTemplateErrors, `translations[${selectedLanguage}].buttonText`)}
            tooltipText="Opens a browser with request progress information"
            {...buttonFieldLabelWrapperProps}
          >
            <BigidTextField
              id="buttonText_field"
              value={emailTemplate.translations[selectedLanguage]!.buttonText}
              dataAid="buttonText_field"
              onChange={value =>
                setFieldValue(
                  `${namespace}emailTemplate.translations[${selectedLanguage}].buttonText`,
                  value.target.value,
                )
              }
              size="medium"
              type="text"
            />
          </BigidFormFieldLabelWrapper>
        </div>
      )}
      {emailTemplate.progressEnabled !== undefined && (
        <div className={classes.progressbarWrapper}>
          <BigidSwitch
            checked={emailTemplate.progressEnabled}
            label="Show Progress Bar in Email"
            dataAid="progressbar__switch"
            onChange={(event, checked) => setFieldValue(`${namespace}emailTemplate.progressEnabled`, checked)}
          />
          <BigidMeTooltipWithIcon title="Toggle on to show the progress bar at the bottom of emails" />
        </div>
      )}
    </Root>
  );
};
