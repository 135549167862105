import { PreferencesGridFilter, RequestStatusType, UserGridPreferenceViewFilter } from '../../../types';
import {
  AdvancedToolbarOverrideValueStrategy,
  BigidAdvancedToolbarDateRangeFilter,
  BigidAdvancedToolbarDropdownFilter,
  BigidAdvancedToolbarFilterTypes,
  BigidAdvancedToolbarFilterUnion,
  BigidDropdownOption,
} from '@bigid-ui/components';
import { delay, isArray } from 'lodash';
import { getPreferencesGridFiltersOptions } from '../../../services/request';
import { USER_REQUEST_GRID } from '../../../hooks/useUserPreferences';
import { MutableRefObject } from 'react';
import { DateModes, DateRangeDates, RangeSelectionOptions } from '@bigid-ui/components/lib/BigidDateRangePicker/types';

const FILTERS_SEPARATOR = '::';
const FILTER_VALUES_SEPARATOR = ',';
const DATE_RANGE_SEPARATOR = '**';
const FILTER_NAME_SEPARATOR = '=';

const getRequestStatusOption = (status: string) => {
  switch (status) {
    case RequestStatusType.OVERDUE: {
      return {
        name: 'Overdue',
        value: 'Overdue',
      };
    }
    case RequestStatusType.ALMOST_OVERDUE: {
      return {
        name: 'Almost Overdue',
        value: 'AboutOverdue',
      };
    }
    case RequestStatusType.IN_PROGRESS: {
      return {
        name: 'On Time',
        value: 'InProgress',
      };
    }
    default: {
      return {
        name: 'On Time',
        value: 'InProgress',
      };
    }
  }
};

export const getMappedFiltersFromParams = (searchParams: URLSearchParams) => {
  const filters = Object.fromEntries([...searchParams])?.['filters'];

  return filters
    ?.split(FILTERS_SEPARATOR)
    .filter(item => item)
    .map(item => {
      const [name, value] = item.split(FILTER_NAME_SEPARATOR);
      const [dateFrom, dateTo, currentRangeMode, currentRangeOption] = value?.split(DATE_RANGE_SEPARATOR) || [];
      const type = (dateFrom && dateTo ? 'Date' : 'String') as 'String' | 'Date';

      return {
        name,
        options: value?.split(FILTER_VALUES_SEPARATOR)?.filter(Boolean),
        dateFrom,
        dateTo,
        currentRangeMode,
        currentRangeOption,
        type,
      };
    });
};

const formatFiltersToParams = (
  filters: {
    options: string[];
    field: string;
    dateFrom?: string | Date | undefined;
    dateTo?: string | Date | undefined;
    currentRangeOption?: string | undefined;
    currentRangeMode?: string | undefined;
  }[],
) =>
  filters.reduce((acc, curr) => {
    const value = curr.options || [];
    const dateParams = curr.dateFrom
      ? [curr.dateFrom, curr.dateTo, curr.currentRangeMode, curr.currentRangeOption].join(DATE_RANGE_SEPARATOR)
      : '';
    const filterSeparator = acc ? FILTERS_SEPARATOR : ''; // Add filterSeparator only if there's an existing value in acc
    return `${curr.field}${FILTER_NAME_SEPARATOR}${value.join(FILTER_VALUES_SEPARATOR)}${
      dateParams ? `${dateParams}` : ''
    }${filterSeparator}${acc}`;
  }, '');

export const createToolbarFilters = (
  filtersList: PreferencesGridFilter[],
  viewFilters: UserGridPreferenceViewFilter[],
  optionsCacheRef: MutableRefObject<Map<string, { options: BigidDropdownOption[] }>>,
) => {
  return (filtersList || [])
    .map(filter =>
      filter.type === 'String'
        ? createDropdownFilter(filter, viewFilters, optionsCacheRef)
        : createDateRangeFilter(filter, viewFilters),
    )
    .sort((o1, o2) => o1.ordinal - o2.ordinal);
};

const createDateRangeFilter = (filter: PreferencesGridFilter, viewFilters: UserGridPreferenceViewFilter[]) => {
  const viewFilter = viewFilters.find(i => i.name === filter.name);
  return {
    id: filter.name,
    type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
    field: filter.name,
    title: filter.label,
    operator: viewFilter?.operator || 'in',
    disabled: !viewFilter?.enabled,
    options: {
      pickersState: {
        dates: {
          from: null,
          until: null,
        },
        currentMode: 'from',
      },
      currentRangeOptionSelected: 'none',
    },
    value: [],
    dataAid: filter.name,
    isSearchable: false,
    ordinal: viewFilter?.ordinal !== undefined ? viewFilter.ordinal : 100,
    shouldNotReloadOnChange: true,
  } as BigidAdvancedToolbarDateRangeFilter & { ordinal: number };
};

const createDropdownFilter = (
  filter: PreferencesGridFilter,
  viewFilters: UserGridPreferenceViewFilter[],
  optionsCacheRef: MutableRefObject<Map<string, { options: BigidDropdownOption[] }>>,
) => {
  const viewFilter = viewFilters.find(i => i.name === filter.name);
  const options =
    viewFilter?.options.map(option =>
      filter.name === 'status'
        ? {
            displayValue: getRequestStatusOption(option).name,
            value: getRequestStatusOption(option).value,
            id: getRequestStatusOption(option).name,
            isSelected: true,
          }
        : {
            id: option,
            displayValue: option,
            value: option,
            isSelected: true,
          },
    ) || [];

  return {
    id: filter.name,
    type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
    field: filter.name,
    title: filter.label,
    operator: viewFilter?.operator || 'in',
    disabled: !viewFilter?.enabled,
    options,
    value: [],
    asyncOptionsFetch: async (_, search) => {
      if (!search && optionsCacheRef?.current.has(filter.name)) {
        return new Promise(resolve => {
          delay(() => resolve(optionsCacheRef?.current.get(filter.name)?.options || []), 0);
        });
      }

      const optionsData = await getPreferencesGridFiltersOptions(USER_REQUEST_GRID, [
        { name: filter.name, source: filter.source, search: search || '' },
      ]);

      const options =
        optionsData?.[0]?.options.map(item => ({
          id: item.name,
          displayValue: item.name,
          value: item.value,
          isSelected: viewFilter?.options?.includes(item.value),
        })) || [];

      if (!search) optionsCacheRef.current.set(filter.name, { options });
      return options;
    },
    dataAid: filter.name,
    isSearchable: filter.searchable,
    ordinal: viewFilter?.ordinal !== undefined ? viewFilter.ordinal : 100,
    optionsDisplayLimit: 100,
  } as BigidAdvancedToolbarDropdownFilter & { ordinal: number };
};

export const createToolbarSelectedOptions = (
  filters: Pick<
    UserGridPreferenceViewFilter,
    'name' | 'options' | 'dateTo' | 'dateFrom' | 'currentRangeOption' | 'currentRangeMode' | 'type'
  >[],
) => ({
  strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITHOUT_RELOAD_OVERRIDE,
  values: filters.length
    ? filters.map(f => (f.type === 'Date' ? createDateRangeOption(f) : createDropdownOption(f)))
    : [{ id: '', type: BigidAdvancedToolbarFilterTypes.DROPDOWN, ordinal: 0, options: [] }],
});

const createDateRangeOption = (
  filter: Pick<
    UserGridPreferenceViewFilter,
    'name' | 'options' | 'dateTo' | 'dateFrom' | 'currentRangeOption' | 'currentRangeMode'
  >,
) => ({
  id: filter.name,
  type: BigidAdvancedToolbarFilterTypes.DATE_RANGE,
  options: {
    pickersState: {
      dates: {
        from: filter.dateFrom ? new Date(filter.dateFrom) : null,
        until: filter.dateTo ? new Date(filter.dateTo) : null,
      } as DateRangeDates,
      currentMode: filter.currentRangeMode as DateModes,
    },
    currentRangeOptionSelected: filter.currentRangeOption as RangeSelectionOptions,
  },
});

const createDropdownOption = (
  filter: Pick<
    UserGridPreferenceViewFilter,
    'name' | 'options' | 'dateTo' | 'dateFrom' | 'currentRangeOption' | 'currentRangeMode'
  >,
) => ({
  id: filter.name,
  type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
  options: filter.options.map(option =>
    filter.name === 'status'
      ? {
          displayValue: getRequestStatusOption(option).name,
          value: getRequestStatusOption(option).value,
          id: getRequestStatusOption(option).name,
        }
      : {
          displayValue: option,
          value: option,
          id: option,
        },
  ),
});

export const updateSearchParams = (
  filters: BigidAdvancedToolbarFilterUnion[],
  setSearchParams: (v: { [p: string]: string; filters: string }) => void,
  searchParams: URLSearchParams,
  viewFilters?: UserGridPreferenceViewFilter[],
) => {
  if (viewFilters) {
    const viewFiltersToParams = createViewFiltersToParams(viewFilters, filters);
    const newViewFiltersToParams = createNewViewFiltersToParams(viewFilters, filters);

    const formattedFilters = formatFiltersToParams([...viewFiltersToParams, ...newViewFiltersToParams]);
    setSearchParams({ ...Object.fromEntries(searchParams), filters: formattedFilters });
  }
};

export const formatIsoDateFilter = (isoDateString?: string): string => {
  if (!isoDateString) return '';

  return isoDateString.slice(0, 10);
};

const createViewFiltersToParams = (
  viewFilters: UserGridPreferenceViewFilter[],
  filters: BigidAdvancedToolbarFilterUnion[],
): {
  options: string[];
  field: string;
  dateFrom?: string | Date | undefined;
  dateTo?: string | Date | undefined;
  currentRangeOption?: string | undefined;
  currentRangeMode?: string | undefined;
}[] =>
  viewFilters.map(vf => {
    const allOptions = filters.find(filter => filter.field === vf.name)?.options;

    const dropDownOptions = allOptions && isArray(allOptions) ? allOptions.map(i => i.value) : [];
    const dateRangeOptions = allOptions && !isArray(allOptions) ? allOptions : undefined;

    if (dateRangeOptions) {
      return {
        field: vf.name,
        options: vf.options,
        dateFrom: dateRangeOptions.pickersState?.dates?.from?.toISOString(),
        dateTo: dateRangeOptions.pickersState?.dates?.until?.toISOString(),
        currentRangeOption: dateRangeOptions.currentRangeOptionSelected,
        currentRangeMode: dateRangeOptions.pickersState.currentMode,
      };
    }

    if (dropDownOptions) {
      return {
        field: vf.name,
        options: dropDownOptions,
        dateFrom: vf.dateFrom,
        dateTo: vf.dateTo,
        currentRangeOption: vf.currentRangeOption,
        currentRangeMode: vf.currentRangeMode,
      };
    }

    return {
      field: vf.name,
      options: vf.options,
      dateFrom: vf.dateFrom,
      dateTo: vf.dateTo,
      currentRangeOption: vf.currentRangeOption,
      currentRangeMode: vf.currentRangeMode,
    };
  });

const createNewViewFiltersToParams = (
  viewFilters: UserGridPreferenceViewFilter[],
  filters: BigidAdvancedToolbarFilterUnion[],
) => {
  return filters
    .filter(filter => !viewFilters.map(f => f.name).includes(filter.field))
    .map(i => {
      const dropDownOptions = isArray(i.options) ? i.options.map(i => i.value) : [];
      const dateRangeOptions = !isArray(i.options) ? i.options : undefined;

      return {
        field: i.field,
        options: dropDownOptions,
        dateFrom: dateRangeOptions?.pickersState?.dates?.from?.toISOString(),
        dateTo: dateRangeOptions?.pickersState?.dates?.until?.toISOString(),
        currentRangeOption: dateRangeOptions?.currentRangeOptionSelected,
        currentRangeMode: dateRangeOptions?.pickersState?.currentMode,
      };
    });
};
