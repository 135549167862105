export type RequestManagerStateTypeActions = {
  OPEN_REQUESTS?: boolean;
  CLOSE_REQUESTS?: boolean;
  EXTEND_REQUEST?: boolean;
  VERIFY_STAGE_APPROVE?: boolean;
  CHANGE_REQUEST_OWNER?: boolean;
  EXPORT?: boolean;
  UPDATE_DATA_SOURCES?: boolean;
  SHOW_USERS?: boolean;
  SHOW_FILTERS?: boolean;
  SHOW_PROFILES?: boolean;
  CHANGE_REQUEST_COLLABORATOR?: boolean;
  SHOW_NOTES?: boolean;
};

export type RequestManagerStateType = {
  dashboardEnabled: boolean;
  requestManagerEnabled: boolean;
  actions: RequestManagerStateTypeActions;
};
