import { RequestSubmitFormType, RequestSubmitPortalType } from '../../types';
import { clonePortalInPortals } from './requestSubmitPortalUtils';

export const findFormPathInPortals = (portals: RequestSubmitPortalType[], formId?: number) => {
  let portalIndex = -1;
  let formIndex = -1;
  let portal = undefined;
  let form = undefined;
  for (let pi = 0; pi < portals.length; pi++) {
    for (let fi = 0; fi < portals[pi].forms.length; fi++) {
      if (portals[pi].forms[fi].formId === formId) {
        portalIndex = pi;
        formIndex = fi;
        portal = portals[portalIndex];
        form = portal.forms[formIndex];
        break;
      }
    }
  }
  return { portalIndex, formIndex, form, portal };
};

export const findFormInPortals = (portals: RequestSubmitPortalType[], formId?: number) =>
  findFormPathInPortals(portals, formId).form;

export const getEnabledFormVerification = (form: RequestSubmitFormType) =>
  form.verifications.find(verif => verif.enabled);

export const updateRequestSubmitFormInPortals = (
  portals: RequestSubmitPortalType[],
  formId: number,
  data: Partial<RequestSubmitFormType>,
) => {
  const { portal } = findFormPathInPortals(portals, formId);
  if (portal) {
    const updatedPortals = clonePortalInPortals(portals, portal.id);
    const updatedForm = findFormInPortals(updatedPortals, formId);
    if (updatedForm) {
      Object.assign(updatedForm, data);
    }
    return updatedPortals;
  }
  return portals;
};

export const toggleFormVerificationInPortals = (
  portals: RequestSubmitPortalType[],
  formId: number,
  verificationId: number,
  enabled: boolean,
) => {
  const { portal, form } = findFormPathInPortals(portals, formId);
  if (portal && form) {
    const updatedPortals = clonePortalInPortals(portals, portal.id);
    const updatedForm = findFormInPortals(updatedPortals, formId);
    if (updatedForm) {
      updatedForm.verifications.forEach(verification => {
        if (verification.consumerVerificationId === verificationId) {
          verification.enabled = enabled;
        } else {
          verification.enabled = false;
        }
      });
    }
    return updatedPortals;
  }
  return portals;
};

export const getAllFormsInPortals = (portals: RequestSubmitPortalType[]) => portals.flatMap(portal => portal.forms);

export const getAllEnabledFormVerifications = (portals: RequestSubmitPortalType[]) =>
  getAllFormsInPortals(portals)
    .filter(form => form.verifications.some(v => v.enabled))
    .map(f => ({
      formId: f.formId,
      consumerVerificationId: f.verifications.find(v => v.enabled)!.consumerVerificationId,
    }));
