import { AuthenticationType } from '../../types';

const authKey = 'auth_state';
const storage = sessionStorage;

const getAuthentication = (): AuthenticationType | null =>
  storage.getItem(authKey) && JSON.parse(storage.getItem(authKey) as string);

const saveAuthentication = (auth: AuthenticationType): void => {
  storage.setItem(authKey, JSON.stringify(auth));
};

const removeAuthentication = (): void => {
  storage.removeItem(authKey);
};

export const authStore = {
  getAuthentication,
  saveAuthentication,
  removeAuthentication,
};
