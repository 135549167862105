import { getPreferencesGridFilters } from '../../../services/request';
import { useFetch } from '../../../hooks';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridRequestsFilterType, UserGridPreferenceViewFilter } from '../../../types';
import { BigidAdvancedToolbarFilterUnion, BigidDropdownOption } from '@bigid-ui/components';
import { mergeAdvancedToolbarFilterToRequestsFilters } from '../utils';
import { USER_REQUEST_GRID } from '../../../hooks/useUserPreferences';
import { useSearchParams } from 'react-router-dom';
import {
  createToolbarFilters,
  createToolbarSelectedOptions,
  getMappedFiltersFromParams,
  updateSearchParams,
} from './helpers';

export const useRequestManagerGridFilters = ({
  currentViewFilters,
  changedViewFilters,
  onChangeToolbarBusy,
}: {
  currentViewFilters?: UserGridPreferenceViewFilter[];
  changedViewFilters?: UserGridPreferenceViewFilter[];
  onChangeToolbarBusy: Dispatch<SetStateAction<boolean>>;
}) => {
  const [toolbarFilters, setToolbarFilters] = useState<BigidAdvancedToolbarFilterUnion[]>([]);
  const [requestsFilters, setRequestsFilters] = useState<GridRequestsFilterType[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAppliedFiltersFromParams, setIsAppliedFiltersFromParams] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<
    Pick<
      UserGridPreferenceViewFilter,
      'name' | 'options' | 'dateTo' | 'dateFrom' | 'currentRangeOption' | 'currentRangeMode' | 'type'
    >[]
  >([]);

  const optionsCacheRef = useRef(new Map<string, { options: BigidDropdownOption[] }>());

  const { data: filtersList, loading: loadingGridFilters } = useFetch({
    fetchApi: () => getPreferencesGridFilters(USER_REQUEST_GRID),
    permission: !!currentViewFilters,
  });

  const advancedToolbarFilters = useMemo(
    () => (changedViewFilters ? createToolbarFilters(filtersList, changedViewFilters, optionsCacheRef) : []),
    [filtersList, changedViewFilters],
  );

  useEffect(() => {
    if (!currentViewFilters) return;

    const isFiltersInParams = Object.fromEntries([...searchParams])?.['filters'];

    if (isFiltersInParams && isAppliedFiltersFromParams) return;

    if (isFiltersInParams) {
      setSelectedFilters(getMappedFiltersFromParams(searchParams));
    } else {
      setSelectedFilters(currentViewFilters);
    }
    setIsAppliedFiltersFromParams(true);
  }, [isAppliedFiltersFromParams, searchParams, currentViewFilters]);

  const advancedToolbarSelectedOptionsConfig = useMemo(
    () => createToolbarSelectedOptions(selectedFilters),
    [selectedFilters],
  );

  const onRequestsFiltersChange = useCallback(
    (filters: BigidAdvancedToolbarFilterUnion[], isBusy?: boolean) => {
      setToolbarFilters(filters);

      onChangeToolbarBusy(!!isBusy);
      const bigIdFilters = mergeAdvancedToolbarFilterToRequestsFilters(filters);
      setRequestsFilters(bigIdFilters);
    },
    [onChangeToolbarBusy],
  );

  const onResetRequestsFilters = useCallback(() => {
    if (currentViewFilters) {
      setSelectedFilters(currentViewFilters);
    }
    setRequestsFilters(mergeAdvancedToolbarFilterToRequestsFilters(advancedToolbarFilters));
  }, [advancedToolbarFilters, currentViewFilters]);

  useEffect(() => {
    if (isAppliedFiltersFromParams && currentViewFilters) {
      updateSearchParams(toolbarFilters, setSearchParams, searchParams, currentViewFilters);
    }
  }, [isAppliedFiltersFromParams, searchParams, setSearchParams, currentViewFilters, toolbarFilters]);

  const onFilterReload = useCallback(() => onChangeToolbarBusy(false), [onChangeToolbarBusy]);

  return {
    advancedToolbarFilters,
    loadingGridFilters,
    advancedToolbarSelectedOptionsConfig,
    requestsFilters,
    onRequestsFiltersChange,
    onResetRequestsFilters,
    onFilterReload,
  };
};
