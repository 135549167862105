import React from 'react';
import { FormikProps } from 'formik';
import { BigidDropdownOption, BigidDropdownValue } from '@bigid-ui/components';
import { MeTextFieldWithForm } from '../../MeTextFieldWithForm';
import { BigidDropdownWithForm } from '../../BigidDropdownWithForm';
import { MeTextFieldAutocompleteWithForm } from '../../MeTextFieldAutocompleteWithForm';
import { Root, classes } from './SystemNameValueFieldsStyles';

export interface HeaderItem {
  name: string;
  value: string | string[];
}

export interface SystemNameValueFieldsProps<T> {
  type?: 'dropdown' | 'autocomplete';
  autocompleteListItems?: { value: string; label: string }[];
  valueTooltip?: string;
  dropdownOptions?: BigidDropdownOption[];
  disabledName?: boolean;
  disabledValue?: boolean;
  isMultiline?: boolean;
  displayAsRequired?: boolean;
  formData: FormikProps<T>;
  keyName: string;
  valueName: string;
  placeholder?: string;
  testId?: string;
}

export const SystemNameValueFields = <T,>({
  type = 'autocomplete',
  autocompleteListItems,
  displayAsRequired,
  isMultiline,
  dropdownOptions,
  disabledName,
  disabledValue,
  valueTooltip,
  formData,
  keyName,
  valueName,
  placeholder,
  testId,
}: SystemNameValueFieldsProps<T>) => {
  const renderValueField = (type: string) => {
    switch (type) {
      case 'dropdown': {
        return (
          <BigidDropdownWithForm
            name={valueName as any}
            formData={formData}
            value={[formData.getFieldMeta(valueName).value] as BigidDropdownValue & string[]}
            options={dropdownOptions || []}
            label="Value"
            tip={valueTooltip}
            placeholder={placeholder}
            dataAid={`${testId}-value`}
            isDisabled={disabledValue}
          />
        );
      }
      default: {
        return (
          <MeTextFieldAutocompleteWithForm
            label="Value"
            name={valueName as any}
            formData={formData}
            autocompleteListItems={autocompleteListItems || []}
            triggerChar="$"
            dropDownOffsetY={30}
            dropdownHeight={160}
            dataAid="WebhookFormBodyTextField"
            ignoreRegex={/[\n\t\}]/g}
            tip={valueTooltip}
            placeholder={placeholder}
            testId={`${testId}-value`}
            disabled={disabledValue}
            isMultiline={isMultiline}
          />
        );
      }
    }
  };

  return (
    <Root>
      <div className={classes.field}>
        <MeTextFieldWithForm
          displayAsRequired={displayAsRequired}
          label="Key"
          formData={formData}
          name={keyName}
          disabled={disabledName}
          testId={`${testId}-key`}
        />
      </div>
      <div className={classes.field}>{renderValueField(type)}</div>
    </Root>
  );
};
