import { useMemo } from 'react';
import { BigidDropdownOption } from '@bigid-ui/components';

import { AllowOutsideRequestorsType, OnScreenMessageType, UpdateEmailTemplateType } from '../../../types';
import { LanguageType } from '@consumer/state/languageContainer';
import { useFormik } from 'formik';
import {
  encodeOnScreenMassage,
  encodeWorkflowEmail,
  makeOutsideRequestorsEmailTemplateValidationSchema,
  makeOutsideRequestorsOnScreenMessageValidationSchema,
} from '../../../utils';
import { createContainer } from 'unstated-next';
import { AvailableLanguagesContainer } from '../../../state/availableLanguagesContainer';
import { noop } from 'lodash';
import { useFetch } from '../../../hooks';
import { getAllowOutsideRequestors, patchAllowOutsideRequestors } from '../../../services/settingsService';
import { WorkflowCustomizationContainer } from './useWorkflowCustomization';

const asDto = (values: FormikAllowOutsideRequestorsPropsType): AllowOutsideRequestorsType => ({
  allowOutsideRequestors: values.allowOutsideRequestors,
  exceptionResidencies: values.exceptionResidencies,
  emailMessageAvailable: values.emailMessageAvailable,
  onScreenMessage: {
    ...encodeOnScreenMassage({
      translations: values?.onScreenMessage.translations,
    }),
  },
  emailTemplate: {
    ...encodeWorkflowEmail({
      translations: values?.emailTemplate.translations,
      appealEnabled: values?.emailTemplate.appealEnabled,
    }),
    progressEnabled: false,
    appealEnabled: values?.emailTemplate.appealEnabled,
    authorizedAgentMessageMode: values?.emailTemplate.authorizedAgentMessageMode,
  },
});

export type FormikAllowOutsideRequestorsPropsType = {
  allowOutsideRequestors: boolean;
  exceptionResidencies?: string[];
  countryDropdownValueOptions?: BigidDropdownOption[];
  onScreenMessageLanguage: LanguageType;
  onScreenMessage: OnScreenMessageType;
  emailMessageLanguage: LanguageType;
  emailMessageAvailable?: boolean;
  emailTemplate: UpdateEmailTemplateType;
};

export const useAllowOutsideRequestors = () => {
  const { languagesDropdownOptions } = AvailableLanguagesContainer.useContainer();
  const { requestWorkflowData } = WorkflowCustomizationContainer.useContainer();

  const {
    data: allowOutsideRequestorsData,
    loadData: loadAllowOutsideRequestors,
    setData: setAllowOutsideRequestorsData,
  } = useFetch({
    fetchApi: () => getAllowOutsideRequestors(requestWorkflowData?.id),
    permission: !!requestWorkflowData?.id,
    dependencyParams: [requestWorkflowData?.id],
  });

  const emailTemplateValidationSchema = useMemo(
    () => makeOutsideRequestorsEmailTemplateValidationSchema(languagesDropdownOptions),
    [languagesDropdownOptions],
  );

  const onScreenMessageValidationSchema = useMemo(
    () => makeOutsideRequestorsOnScreenMessageValidationSchema(languagesDropdownOptions),
    [languagesDropdownOptions],
  );

  const formikInitialValues = useMemo(
    () => ({
      exceptionResidencies: allowOutsideRequestorsData?.exceptionResidencies,
      allowOutsideRequestors: allowOutsideRequestorsData?.allowOutsideRequestors,
      onScreenMessageLanguage: LanguageType.EN,
      onScreenMessage: allowOutsideRequestorsData?.onScreenMessage as OnScreenMessageType,
      emailMessageLanguage: LanguageType.EN,
      emailMessageAvailable: allowOutsideRequestorsData?.emailMessageAvailable,
      emailTemplate: {
        translations: allowOutsideRequestorsData?.emailTemplate?.translations || {},
        progressEnabled: false,
        canToggleAppeal: allowOutsideRequestorsData?.emailTemplate?.canToggleAppeal,
        appealEnabled: allowOutsideRequestorsData?.emailTemplate?.appealEnabled,
        authorizedAgentMessageMode: allowOutsideRequestorsData?.emailTemplate!.authorizedAgentMessageMode,
        id: allowOutsideRequestorsData?.emailTemplate?.id,
      },
    }),
    [allowOutsideRequestorsData],
  );

  const settingFormik = useFormik<FormikAllowOutsideRequestorsPropsType>({
    initialValues: formikInitialValues,
    enableReinitialize: true,
    onSubmit: values => {
      updateAllowOutsideRequestors(asDto(values));
    },
  });

  const emailTemplateFormik = useFormik<{ emailTemplate: UpdateEmailTemplateType }>({
    initialValues: {
      emailTemplate: allowOutsideRequestorsData?.emailTemplate,
    },
    enableReinitialize: true,
    onSubmit: noop,
    validationSchema: emailTemplateValidationSchema,
  });

  const onScreenMessageFormik = useFormik<{ onScreenMessage: OnScreenMessageType }>({
    initialValues: {
      onScreenMessage: formikInitialValues.onScreenMessage,
    },
    enableReinitialize: true,
    onSubmit: noop,
    validationSchema: onScreenMessageValidationSchema,
  });

  const updateAllowOutsideRequestors = async (formData: AllowOutsideRequestorsType) => {
    if (requestWorkflowData?.id) {
      await patchAllowOutsideRequestors(requestWorkflowData?.id, formData);
      await loadAllowOutsideRequestors();
    }
  };

  const updateAllowOutsideRequestorsEmailTemplate = async () => {
    if (requestWorkflowData?.id) {
      await patchAllowOutsideRequestors(requestWorkflowData?.id, {
        ...allowOutsideRequestorsData,
        emailTemplate: {
          ...emailTemplateFormik.values?.emailTemplate,
          ...encodeWorkflowEmail({
            translations: emailTemplateFormik.values?.emailTemplate.translations,
            appealEnabled: emailTemplateFormik.values?.emailTemplate.appealEnabled,
          }),
        },
      });

      setAllowOutsideRequestorsData({
        ...settingFormik.values,
        onScreenMessage: onScreenMessageFormik.values.onScreenMessage,
        emailTemplate: emailTemplateFormik.values.emailTemplate,
      });

      emailTemplateFormik.resetForm();
    }
  };

  const updateAllowOutsideRequestorsOnScreenMessage = async () => {
    if (requestWorkflowData?.id) {
      await patchAllowOutsideRequestors(requestWorkflowData?.id, {
        ...allowOutsideRequestorsData,
        onScreenMessage: {
          ...encodeOnScreenMassage({
            translations: onScreenMessageFormik.values?.onScreenMessage.translations,
          }),
        },
      });

      setAllowOutsideRequestorsData({
        ...settingFormik.values,
        emailTemplate: emailTemplateFormik.values.emailTemplate,
        onScreenMessage: onScreenMessageFormik.values.onScreenMessage,
      });

      emailTemplateFormik.resetForm();
    }
  };

  return {
    settingFormik,
    emailTemplateFormik,
    onScreenMessageFormik,
    allowOutsideRequestorsData,
    updateAllowOutsideRequestorsEmailTemplate,
    updateAllowOutsideRequestorsOnScreenMessage,
  };
};

export const AllowOutsideRequestorsContainer = createContainer(useAllowOutsideRequestors);
