import React, { FC } from 'react';
import { authService } from '../../services/authentication';
import { Stack } from '@mui/material';
import { BigidErrorPage, BigidErrorPageType } from '@bigid-ui/components';
import { useEmbeddedApp } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { HttpStatusCode } from 'axios';
import { LoginErrorCodes } from '../../utils';

const getErrorTypeByHttpStatus = (status?: HttpStatusCode): BigidErrorPageType => {
  switch (status) {
    case HttpStatusCode.InternalServerError: {
      return 'internalError';
    }
    case HttpStatusCode.NotFound: {
      return 'notFound';
    }
    case HttpStatusCode.Unauthorized: {
      return 'expiredCredentials';
    }
    default:
      return 'unauthorized';
  }
};

const getErrorType = (errorCode?: LoginErrorCodes, status?: HttpStatusCode): BigidErrorPageType => {
  if (errorCode === LoginErrorCodes.USER_DOESNT_EXIST) {
    return 'expiredCredentials';
  }
  return getErrorTypeByHttpStatus(status);
};

const getErrorTitle = (errorCode?: LoginErrorCodes) => {
  switch (errorCode) {
    case LoginErrorCodes.USER_DOESNT_EXIST: {
      return 'Account cannot be found or disabled';
    }
    default:
      return undefined;
  }
};

export const LoginError: FC<{ status?: HttpStatusCode; errorCode?: LoginErrorCodes }> = ({ status, errorCode }) => {
  const { isEmbeddedInIframe } = useEmbeddedApp();
  const navigate = useNavigate();

  const reLogin = () => {
    if (isEmbeddedInIframe) {
      navigate('/#/login');
    } else {
      authService.logOut();
    }
  };

  return (
    <Stack alignContent="center" justifyContent="center" height="100vh">
      <BigidErrorPage type={getErrorType(errorCode, status)} title={getErrorTitle(errorCode)} onLinkClick={reLogin} />
    </Stack>
  );
};
