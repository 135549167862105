import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    cursor: 'pointer',
  },
  step: {
    padding: 3,
  },
  hidden: {
    display: 'none !important',
  },
  stepButton: {
    display: 'block',
    margin: '-5px 0',
    padding: 0,
    textAlign: 'left',
    borderRadius: '50%',
  },
  label: {
    fontSize: '0.625rem',
    color: theme.vars.palette.bigid?.gray700,
    fontWeight: 300,
  },
  current: {
    fontWeight: 'bold',
  },
  completed: {
    fontWeight: 'bold',
    color: theme.vars.palette.bigid?.primary600,
  },
}));

export const defaultConnectorStyles = {
  line: {
    display: 'none',
  },
};
