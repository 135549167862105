import React from 'react';
import { Step } from '@bigid-ui/guided-tour';
import {
  BrandsEditStep,
  FromsTabStep,
  FormAppealStep,
  UseCasesAccordionAppealToggleStep,
  UseCasesAccordionAppealBodyStep,
  AllowAppealStep,
  ClosedRequestsGridStep,
  WorkflowCustomizationGridStep,
} from '.';
import { getGuidedTourIdSelector } from '@bigid-ui/utils';
import { AppealStartTooltip } from '../tooltips/AppealStartTooltip';
import { AppealStepTooltip } from '../tooltips/AppealStepTooltip';
import { RequestType } from '../../../types';
import { SavePublishStep } from './SavePublishStep';

export const appealTourSelectors = {
  START_TOUR: getGuidedTourIdSelector('Editor', ['start']),
  BRANDS_EDIT_BUTTON: getGuidedTourIdSelector('EditButton-0', ['button']),
  USE_CASES_ACCORDION_APPEAL_HEADER: `#${RequestType.APPEAL}`,
  USE_CASES_ACCORDION_APPEAL_TOGGLE: `#${RequestType.APPEAL}-use-case-toggle`,
  USE_CASES_ACCORDION_APPEAL_BODY: getGuidedTourIdSelector(`UseCase-${RequestType.APPEAL}`, ['accordion-body']),
  FORM_TAB: '#vertical-tab-1',
  APPEAL_FORM: `#accordion-form-${RequestType.APPEAL}`,
  SAVE_AND_PUBLISH: '#saveAndPublishButton',
  GENERAL_TAB: '#vertical-tab-0',
  WORKFLOW_GRID: getGuidedTourIdSelector('BigidGrid'),
  CLOSE_REQUESTS_GRID: getGuidedTourIdSelector('ClosedRequests', ['table']),
  ALLOW_APPEAL: getGuidedTourIdSelector('AllowAppeal', ['workflow']),
};
export const appealTourSteps = () => {
  const dataAid = 'AppealGuidedTour';
  const steps: Step[] = [
    {
      content: null,
      target: appealTourSelectors.START_TOUR,
      disableBeacon: true,
      placement: 'center',
      tooltipComponent: AppealStartTooltip,
    },
    {
      content: <BrandsEditStep dataAid={dataAid} />,
      target: appealTourSelectors.BRANDS_EDIT_BUTTON,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-end',
      tooltipComponent: AppealStepTooltip,
      spotlightPadding: 10,
    },
    {
      content: <UseCasesAccordionAppealToggleStep dataAid={dataAid} />,
      target: appealTourSelectors.USE_CASES_ACCORDION_APPEAL_HEADER,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-end',
      tooltipComponent: AppealStepTooltip,
      spotlightPadding: 0,
    },
    {
      content: <UseCasesAccordionAppealBodyStep dataAid={dataAid} />,
      target: appealTourSelectors.USE_CASES_ACCORDION_APPEAL_BODY,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-end',
      tooltipComponent: AppealStepTooltip,
      spotlightPadding: 15,
    },
    {
      content: <FromsTabStep dataAid={dataAid} />,
      target: appealTourSelectors.FORM_TAB,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-end',
      tooltipComponent: AppealStepTooltip,
    },
    {
      content: <FormAppealStep dataAid={dataAid} />,
      target: appealTourSelectors.APPEAL_FORM,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-start',
      tooltipComponent: AppealStepTooltip,
      spotlightPadding: 5,
    },
    {
      content: <SavePublishStep dataAid={dataAid} />,
      target: appealTourSelectors.SAVE_AND_PUBLISH,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-start',
      tooltipComponent: AppealStepTooltip,
      spotlightPadding: 5,
    },
    {
      content: <WorkflowCustomizationGridStep dataAid={dataAid} />,
      target: appealTourSelectors.WORKFLOW_GRID,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-start',
      tooltipComponent: AppealStepTooltip,
      spotlightPadding: 10,
      hideBackButton: true,
    },
    {
      content: <ClosedRequestsGridStep dataAid={dataAid} />,
      target: appealTourSelectors.CLOSE_REQUESTS_GRID,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-start',
      tooltipComponent: AppealStepTooltip,
      spotlightPadding: 20,
      hideBackButton: true,
    },
    {
      content: <AllowAppealStep dataAid={dataAid} />,
      target: appealTourSelectors.ALLOW_APPEAL,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'left-start',
      tooltipComponent: AppealStepTooltip,
      spotlightPadding: 5,
      hideBackButton: true,
    },
  ];

  return steps;
};
