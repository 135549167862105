import React, { FC } from 'react';
import { BigidLink } from '@bigid-ui/components';
import { BigidWarningIcon } from '@bigid-ui/icons';

import { useStyles } from './UpgradePanelStyles';

export interface UpgradePanelPropsType {
  firstTextRow?: string;
  secondTextRow?: string;
  link?: string;
}

export const UpgradePanel: FC<UpgradePanelPropsType> = ({
  firstTextRow = '',
  secondTextRow = '',
  link = '/pricing',
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <BigidWarningIcon />
      </div>
      <div>
        <div>{firstTextRow}</div>
        <div>
          <BigidLink text="Upgrade" href={link} classNames={classes.upgrade} /> {secondTextRow}
        </div>
      </div>
    </div>
  );
};
