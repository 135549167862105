import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '4px 15px 4px 0px',
  },
  subtitle: {
    padding: 5,
    fontSize: '0.8125rem',
    fontWeight: 'bold',
    color: theme.vars.palette.bigid?.gray600,
  },
  filterWrapper: {
    padding: 4,
  },
}));
