import React, { FC, useEffect, useState } from 'react';
import { authService } from '../../services/authentication';
import { useStyles } from './MainLoaderStyles';
import { Loader } from '../Loader';

export const MainLoader: FC = () => {
  const [longLoad, setLongLoad] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setLongLoad(true);
    }, 10000);

    return () => {
      timeoutID && clearTimeout(timeoutID);
    };
  });

  const reLogin = () => {
    authService.logOut();
  };

  return (
    <div className={classes.root}>
      <div className={classes.mainLoader}>
        <Loader />
      </div>
      {longLoad && (
        <p>
          Logging you in takes longer than usual. Please wait for a bit, or try to{' '}
          <button className={classes.link} onClick={reLogin}>
            re-login
          </button>
          .
        </p>
      )}
    </div>
  );
};
