import { isSmallIdApp } from './commonUtils';

export const BIGID_ME_SUPPORT_EMAIL = isSmallIdApp() ? 'support-smallid@bigid.com' : 'support@bigid.com';
export const BIGID_ME_DOCUMENTATION = 'https://docs.bigid.me';

export const TERMS_OF_USE = 'https://bigid.com/terms';
export const PRIVACY_POLICY = 'https://bigid.com/privacy-notice';
export const TERMS_AND_CONDITIONS = 'https://bigid.com/terms-and-conditions';
export const CC_TERMS_AND_CONDITIONS = 'https://usercentrics.com/terms-and-conditions-usa';

// redirect to support app
export const SUPPORT = 'https://bigid.force.com/support/login';

// redirect to Privacy Portal documentation app
export const DOCUMENTATION = 'https://docs.bigid.com/privacy-portal';

export const DOCUMENTATION_COMMUNICATION = 'https://docs.bigid.com/v1/docs/contact-requestor';
