import React, { ChangeEvent, FC, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import { BigidChevronLeftIcon, BigidChevronRightIcon } from '@bigid-ui/icons';
import { useStyles } from './MeTabsVerticalStyles';
import { primaryBorder } from '../../assets/styles';

interface MeTabsVerticalPropsType {
  children: JSX.Element[];
  value: number;
  isCollapsed?: boolean;
  hiddenCollapse?: boolean;
  onChange: (value: number) => void;
}

export const MeTabsVertical: FC<MeTabsVerticalPropsType> = ({
  children,
  value,
  hiddenCollapse,
  isCollapsed,
  onChange,
}) => {
  const [collapsed, setCollapsed] = useState(isCollapsed);
  const classes = useStyles();

  const onChangeMeTabsVertical = (event: ChangeEvent<unknown>, value: number) => {
    event.stopPropagation();
    event.preventDefault();
    onChange(value);
  };

  return (
    <div className={classes.root}>
      {!collapsed && (
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={onChangeMeTabsVertical}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          sx={theme => ({
            '.MuiTab-root': {
              height: 'auto',
              padding: '20px',
              minWidth: '192px !important',
              maxWidth: '400px',
              borderBottom: primaryBorder,
              color: `${theme.vars.palette.bigid?.gray600}!important`,
              fontSize: '0.93rem',
              lineHeight: '1.2rem',
              alignItems: 'flex-start',
              textTransform: 'capitalize',
              opacity: 1,
              '&.Mui-selected': {
                color: `${theme.vars.palette.bigid?.gray600} !important`,
                fontWeight: 'bold',
                backgroundColor: theme.vars.palette.bigid?.blue100,
              },

              '&.Mui-disabled': {
                opacity: 0.5,
              },
            },
            '.MuiTabs-indicator': {
              height: 0,
              width: 0,
            },
          })}
        >
          {children && children[0].props.children}
        </Tabs>
      )}
      {collapsed && !hiddenCollapse && <div className={classes.collapsedPanel} />}
      {!hiddenCollapse && (
        <span onClick={() => setCollapsed(!collapsed)} className={classes.collapseTrigger} data-aid="collapse-button">
          {collapsed ? <BigidChevronRightIcon /> : <BigidChevronLeftIcon />}
        </span>
      )}
      <div className={classes.tabsBody}>{children && children[1].props.children}</div>
    </div>
  );
};
