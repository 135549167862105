import { useCallback, useEffect, useReducer, useState, useMemo } from 'react';
import { createContainer } from 'unstated-next';
import { useLocation, useNavigate } from 'react-router-dom';
import { EntityEvents, entityEventsEmitter } from '@bigid-ui/components';
import { BigidGridColumn, FetchDataFunction } from '@bigid-ui/grid';

import { ExportRequestColumnsType, GridRequestCollaboratorType, GridRowType } from '../../types';
import { gridReducer, GridReducerActionTypes } from './gridReducer';
import {
  cancelRequests,
  getAllUserPreferencesAttributesInfo,
  getAllUserRequestAttributesInfo,
  postCollaboratorRequestManagerDataGrid,
  postAllRequestsSearch,
  postRequestsTotalCount,
} from '../../services/request';
import { isOpenRequestsView, mapViewFiltersToRequestsFilters, requestManagerColumns } from './utils';
import { RequestOwnersContainer } from './useRequestOwners';
import { RequestManagerStateContainer } from './useRequestManagerState';
import { BigidGridRow } from '@bigid-ui/grid';
import { useRequestCollaborators } from './hooks/useRequestCallaborators';
import { NextGridState } from '@bigid-ui/grid/lib/BigidGrid/hooks';
import { useFormatting } from '../../hooks/useFormatting';
import { RequestManagerViewsContainer } from './useRequestManagerViews';
import { differenceWith, isEqual } from 'lodash';
import {
  generateNewGridColumnState,
  generateNewGridFiltersState,
  getGridDefaultSorting,
  removeSearchFromFilters,
} from '../../utils';
import { useRequestManagerGridFilters } from './hooks/useRequestManagerGridFilters';
import { formatIsoDateFilter } from './hooks/helpers';

const useRequestManagerGrid = () => {
  const [
    {
      search,
      requestsCount,
      isViewChanged,
      collaboratorSearch,
      allUserRequestAttributesInfo,
      allUserRequestPreferencesInfo,
      toolbarKey,
    },
    dispatch,
  ] = useReducer(gridReducer, {
    requestsCount: [],
    search: '',
    collaboratorSearch: '',
    isViewChanged: false,
    toolbarKey: 0,
  });
  const [nextGridState, setNextGridState] = useState<NextGridState>();

  const {
    usersToAssign: { users },
    updateRequestOwner,
  } = RequestOwnersContainer.useContainer();

  const { requestManagerStateActions } = RequestManagerStateContainer.useContainer();

  const {
    currentView,
    onChangeCurrentView,
    onChangeChangedView,
    changedView,
    views,
    sortColumns,
    onChangeToolbarBusy,
  } = RequestManagerViewsContainer.useContainer();

  const { updateRequestCollaborators } = useRequestCollaborators();
  const navigate = useNavigate();
  const location = useLocation();
  const { getFormattedDate } = useFormatting();

  const {
    advancedToolbarSelectedOptionsConfig,
    advancedToolbarFilters,
    requestsFilters,
    onRequestsFiltersChange,
    loadingGridFilters,
    onResetRequestsFilters,
    onFilterReload,
  } = useRequestManagerGridFilters({
    currentViewFilters: currentView?.filters,
    changedViewFilters: changedView?.filters,
    onChangeToolbarBusy,
  });

  const defaultSorting = useMemo(() => {
    const sorting = isOpenRequestsView(requestsFilters)
      ? getGridDefaultSorting('issueDate')
      : getGridDefaultSorting('closeDate');

    const sortingByCurrentView = (currentView?.columns || [])
      .filter(({ sort }) => !!sort)
      .map(c => ({
        field: c.name,
        order: c.sort as 'asc' | 'desc',
      }));

    return sortingByCurrentView.length > 0 ? sortingByCurrentView : sorting;
  }, [currentView?.columns, requestsFilters]);

  const fetchRequests: FetchDataFunction<GridRowType> = useCallback(
    async args => {
      const requests = await postAllRequestsSearch({ ...args, filter: requestsFilters, search });
      const count = !!requestsCount.find(rc => rc.viewId === currentView?.id)
        ? requestsCount.map(rc => (rc.viewId === currentView?.id ? { ...rc, count: requests.totalCount } : rc))
        : [...requestsCount, { viewId: currentView?.id, count: requests.totalCount }];

      if (count.length > 2) {
        dispatch({
          type: GridReducerActionTypes.SET_REQUESTS_INFO,
          payload: {
            requestsCount: count,
          },
        });
      }

      return requests;
    },
    [currentView?.id, requestsCount, requestsFilters, search],
  );

  const getRequestsCount = useCallback(async () => {
    const requestsCount = await Promise.all(
      views.map(async view => {
        const requestsData = await postRequestsTotalCount({
          filter: mapViewFiltersToRequestsFilters(view.filters),
        });

        return { viewId: view.id, count: requestsData.totalCount };
      }),
    );

    dispatch({
      type: GridReducerActionTypes.SET_REQUESTS_INFO,
      payload: {
        requestsCount,
      },
    });
  }, [views]);

  const getRequestsCountForCurrentView = useCallback(async () => {
    if (currentView) {
      const requestsData = await postRequestsTotalCount({
        filter: mapViewFiltersToRequestsFilters(currentView.filters),
      });

      const updatedRequestsCount = requestsCount.map(rc =>
        rc.viewId === currentView.id ? { ...rc, count: requestsData.totalCount } : rc,
      );

      dispatch({
        type: GridReducerActionTypes.SET_REQUESTS_INFO,
        payload: {
          requestsCount: updatedRequestsCount,
        },
      });
    }
  }, [currentView, requestsCount]);

  const updateRequestsCount = useCallback(() => {
    getRequestsCount();
  }, [getRequestsCount]);

  const fetchCollaboratorRequests: FetchDataFunction<GridRowType> = useCallback(
    async args => {
      const filtersWithoutSearch = removeSearchFromFilters(args.filter);
      return await postCollaboratorRequestManagerDataGrid({
        ...args,
        filter: filtersWithoutSearch,
        search: collaboratorSearch,
      });
    },
    [collaboratorSearch],
  );

  const onRowClickHandler = useCallback(
    (row: BigidGridRow) => {
      sessionStorage.setItem('requestManagerState', location.search);
      navigate(`/request-manager/${row.id}`);
    },
    [navigate, location],
  );

  const fetchAllUserRequestAttributesInfo = useCallback(async () => {
    const data = await getAllUserRequestAttributesInfo();
    dispatch({
      type: GridReducerActionTypes.SET_USER_ATTRIBUTES_INFO,
      payload: data,
    });
  }, []);

  const fetchAllUserRequestPreferencesInfo = useCallback(async () => {
    const data = await getAllUserPreferencesAttributesInfo();
    dispatch({
      type: GridReducerActionTypes.SET_USER_PREFERENCES_INFO,
      payload: data,
    });
  }, []);

  const handleUpdateRequestOwner = useCallback(
    (requestId: string, ownerId: string, owner: string) => {
      updateRequestOwner({ requestId, ownerId, owner, onSuccess: updateRequestsCount });
    },
    [updateRequestOwner, updateRequestsCount],
  );

  const handleUpdateRequestCollaborators = useCallback(
    (requestId: string, collaborators: GridRequestCollaboratorType[]) => {
      updateRequestCollaborators({ requestId, collaborators, onSuccess: updateRequestsCount });
    },
    [updateRequestCollaborators, updateRequestsCount],
  );

  const onGridStateChanged = async (gridState: NextGridState) => {
    setNextGridState(gridState);
  };

  const onResetView = () => {
    dispatch({
      type: GridReducerActionTypes.SET_TOOLBAR_KEY,
      payload: toolbarKey + 1,
    });

    onResetRequestsFilters();
  };

  const columns = useMemo(() => {
    if (!allUserRequestAttributesInfo || !allUserRequestPreferencesInfo) return [];

    const defaultColumns = requestManagerColumns({
      usersToAssign: users,
      onOwnerChange: handleUpdateRequestOwner,
      onCollaboratorsChange: handleUpdateRequestCollaborators,
      requestAttributesInfo: allUserRequestAttributesInfo || [],
      requestPreferencesInfo: allUserRequestPreferencesInfo || [],
      actions: {
        CHANGE_REQUEST_OWNER: requestManagerStateActions.CHANGE_REQUEST_OWNER,
        CHANGE_REQUEST_COLLABORATOR: requestManagerStateActions.CHANGE_REQUEST_COLLABORATOR,
        SHOW_NOTES: requestManagerStateActions.SHOW_NOTES,
      },
      getFormattedDate,
    });
    return sortColumns(defaultColumns as BigidGridColumn<BigidGridRow>[], currentView?.id);
  }, [
    users,
    handleUpdateRequestOwner,
    handleUpdateRequestCollaborators,
    allUserRequestAttributesInfo,
    allUserRequestPreferencesInfo,
    requestManagerStateActions.CHANGE_REQUEST_OWNER,
    requestManagerStateActions.CHANGE_REQUEST_COLLABORATOR,
    requestManagerStateActions.SHOW_NOTES,
    getFormattedDate,
    sortColumns,
    currentView?.id,
  ]);

  const getSelectedColumns = useMemo(
    () =>
      (changedView?.columns || [])
        .filter(c => c.enabled)
        .map(column => column.name)
        .reduce<ExportRequestColumnsType>(
          (result, column) => {
            if (column.indexOf('attribute-') === 0)
              result.requestAttributeColumns.push(column.replace('attribute-', ''));
            else if (column.indexOf('preference-') === 0)
              result.requestPreferenceColumns.push(column.replace('preference-', ''));
            else result.requestColumns.push(column);
            return result;
          },
          { requestColumns: [], requestAttributeColumns: [], requestPreferenceColumns: [] },
        ),
    [changedView?.columns],
  );

  useEffect(() => {
    if (views.length === 0) return;

    if (
      !requestsCount.length ||
      (views.length &&
        differenceWith(
          requestsCount.map(c => c.viewId),
          views.map(v => v.id),
          isEqual,
        ).length > 0)
    ) {
      getRequestsCount();
    }
  }, [getRequestsCount, requestsCount, views]);

  useEffect(() => {
    if (currentView?.columns.length === 0) {
      const newColumnState = generateNewGridColumnState({
        columns: [],
        defaultColumns: columns,
      });

      const columnsWithDefaultSorting = !isOpenRequestsView(mapViewFiltersToRequestsFilters(currentView.filters))
        ? newColumnState.map(c => (c.name === 'closeDate' && c.sort !== 'asc' ? { ...c, sort: 'desc' } : c))
        : newColumnState;

      onChangeCurrentView({
        ...currentView,
        columns: columnsWithDefaultSorting,
      });
      onChangeChangedView({
        ...currentView,
        columns: columnsWithDefaultSorting,
      });
    }
  }, [columns, currentView, onChangeChangedView, onChangeCurrentView]);

  useEffect(() => {
    if (!currentView || currentView?.id !== changedView?.id) return;
    const newFiltersState = generateNewGridFiltersState({
      gridFilters: requestsFilters,
      filters: currentView.filters,
    });

    const newColumnState = generateNewGridColumnState({
      nextGridState,
      columns: currentView.columns,
    });

    onChangeChangedView({
      ...currentView,
      columns: newColumnState,
      filters: newFiltersState,
    });
  }, [changedView?.id, currentView, nextGridState, requestsFilters, onChangeChangedView]);

  useEffect(() => {
    if (currentView?.id !== changedView?.id) return;

    const changedViewFilters = changedView?.filters.map(filter => ({
      name: filter.name,
      options: filter.options,
      dateFrom: formatIsoDateFilter(filter.dateFrom),
      dateTo: formatIsoDateFilter(filter.dateTo),
      currentRangeOption: filter.currentRangeOption,
      currentRangeMode: filter.currentRangeMode,
    }));
    const currentViewFilters = currentView?.filters.map(filter => ({
      name: filter.name,
      options: filter.options || [],
      dateFrom: formatIsoDateFilter(filter.dateFrom),
      dateTo: formatIsoDateFilter(filter.dateTo),
      currentRangeOption: filter.currentRangeOption,
      currentRangeMode: filter.currentRangeMode,
    }));
    if (
      (changedViewFilters &&
        currentViewFilters &&
        differenceWith(changedViewFilters, currentViewFilters, isEqual).length > 0) ||
      (changedView?.columns &&
        currentView?.columns &&
        differenceWith(currentView.columns, changedView.columns, isEqual).length > 0) ||
      (changedView?.columns.length && !currentView?.columns.length)
    ) {
      dispatch({
        type: GridReducerActionTypes.SET_IS_VIEW_CHANGED,
        payload: true,
      });
    } else {
      dispatch({
        type: GridReducerActionTypes.SET_IS_VIEW_CHANGED,
        payload: false,
      });
    }
  }, [
    changedView?.filters,
    currentView?.filters,
    changedView?.columns,
    currentView?.columns,
    currentView?.id,
    changedView?.id,
  ]);

  useEffect(
    () => () => {
      cancelRequests();
    },
    [],
  );

  useEffect(() => {
    entityEventsEmitter.emit(EntityEvents.RELOAD);
  }, [search]);

  return {
    dispatch,
    requestsCount,
    allUserRequestAttributesInfo,
    allUserRequestPreferencesInfo,
    fetchAllUserRequestAttributesInfo,
    fetchAllUserRequestPreferencesInfo,
    fetchCollaboratorRequests,
    fetchRequests,
    onRowClickHandler,
    columns,
    getSelectedColumns,
    onGridStateChanged,
    nextGridState,
    isViewChanged,
    defaultSorting,
    search,
    requestsFilters,
    onResetView,
    collaboratorSearch,
    getRequestsCountForCurrentView,

    // Advanced toolbar
    advancedToolbarSelectedOptionsConfig,
    advancedToolbarFilters,
    loadingGridFilters,
    onRequestsFiltersChange,
    onFilterReload,
    toolbarKey,
  };
};

export const RequestManagerGridContainer = createContainer(useRequestManagerGrid);
