import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px',
    width: '100%',
    borderRadius: 8,
    backgroundColor: theme.vars.palette.bigid?.blue150,
    color: theme.vars.palette.bigid?.black,
    fontSize: '0.85rem',
    '& span': {
      padding: '0 5px',
    },
    '& hr': {
      margin: '0 15px',
      border: `.5px solid ${theme.vars.palette.bigid?.gray500}`,
      width: '0',
      fontWeight: 100,
    },
  },
}));
