import React, { FC, useEffect, useState } from 'react';
import { trackOnboardingEvent } from '../../../services/eventsTrackingService';
import { TrackingEvents } from '../../../types/TrackingEventTypes';
import { MeTextField } from '../../../components';
import { ReactComponent as IconFinished } from '../../../assets/illustrations/onboarding-finished.svg';

import { useStyles } from './OnboardingFinishedStyles';
import { fetchConsumerAppUrlAll } from '../../../services/settingsService';
import { BrandsContainer } from '../../../state/brandsContainer';
import { getDefaultBrand } from '../../../utils/brandUtils';
import { PrimaryButton } from '@bigid-ui/components';

export interface OnboardingFinishedPropsType {
  onDone: () => void;
  onCustomize: () => void;
}

export const OnboardingFinished: FC<OnboardingFinishedPropsType> = ({ onDone, onCustomize }) => {
  const classes = useStyles();
  const { brands } = BrandsContainer.useContainer();
  const [copyLink, setCopyLink] = useState<string>('');

  const defaultBrandId = getDefaultBrand(brands.brands)?.id;

  useEffect(() => {
    const fetchConsumerLink = async () => {
      if (defaultBrandId) {
        const urlInfo = await fetchConsumerAppUrlAll(defaultBrandId);
        setCopyLink(urlInfo.portalUrl);
      }
    };

    fetchConsumerLink();
  }, [defaultBrandId]);

  const handlePreviewClick = () => {
    trackOnboardingEvent(TrackingEvents.CONGRATULATIONS_PREVIEW);
  };

  const handleCustomizeClick = () => {
    trackOnboardingEvent(TrackingEvents.CONGRATULATIONS_CUSTOMIZE);
    onCustomize();
  };

  return (
    <div className={classes.root}>
      <IconFinished />
      <div className={classes.wrapper}>
        <div className={classes.title}>You’re All Set!</div>
        <div>You can keep customizing, or add the below URL to your site to start receiving requests.</div>
        <div className={classes.linkWrapper}>
          <label>Privacy Center link:</label>
          <MeTextField
            isLink
            value={copyLink}
            width={317}
            onLinkClick={handlePreviewClick}
            testId="onboarding-finished-copy-link"
          />
        </div>
        <PrimaryButton text="Done" size="medium" onClick={onDone} dataAid="onboarding-finished-btn" />
        <div>
          <span onClick={handleCustomizeClick} className={classes.link}>
            I want to customize
          </span>
        </div>
      </div>
    </div>
  );
};
