import axios, { RawAxiosRequestConfig } from 'axios';
import { map } from 'lodash';
import { InviteConsentTokenDataType } from '../../pages/InviteConsent/InviteConsent';
import {
  BillingAddressType,
  PricingType,
  ConsentsActionType,
  ConsentsExternalIdType,
  CreateAccountType,
  PricingPlanUpgradeStateType,
  PAYMENT_PERIOD,
} from '../../types';
import { toErrorResponse } from '../../utils/errorsUtils';
import { SnackbarUtils } from '../../utils/snackbarUtils';
import { api } from '../request/apiService';
import { PAYMENT_METHOD, PRICING_PLAN } from '../../types/PricingPlanTypes';

export const registrationApi = axios.create({
  baseURL: process.env.REACT_APP_ONBOARDING,
  headers: { 'Content-Type': 'application/json' },
  responseType: 'json',
});

registrationApi.interceptors.response.use(
  response => response,
  error => {
    const responseError = toErrorResponse(error);
    SnackbarUtils.error(responseError.messageWithTrace);

    return Promise.reject(responseError);
  },
);

export const getAccountValidation = async (params: { email: string }) =>
  await registrationApi.post('/validate', params).then(res => res.data);

export const validateCompany = async (params: { companyName: string }) =>
  await registrationApi.post('/validate-company', params).then(res => res.data);

export const createAccount = async (
  createAccount: CreateAccountType,
  utmParams: Record<string, string> | null = null,
  idp: string | null,
  product: string,
) => {
  const { email, firstName, lastName, captcha, companyName, consents } = createAccount;
  const config: RawAxiosRequestConfig = { headers: { 'x-captcha-token': captcha } };

  let data = {
    email,
    firstName,
    lastName,
    companyName,
    password: 'test',
    consents: map(consents, ({ externalId, action }) => ({
      externalId,
      version: externalId === ConsentsExternalIdType.PRIVACY_NOTICE ? '2' : '1', // TODO: Let's implement some kind of versioning system
      action: action ? ConsentsActionType.ACCEPT : ConsentsActionType.DECLINE,
    })),
    utm: utmParams,
    idp,
    product,
  };

  if (!idp) {
    data = { ...data, idp };
  }

  return await registrationApi.post('/user', data, config).then(res => res.data);
};

export const verifyAccount = async (onboardingToken: string) =>
  await registrationApi.post(`/registration/email-verification/${onboardingToken}`, {}).then(res => res.data);

export const getInviteTokenData = async (
  tenant: string,
  activationToken: string,
): Promise<InviteConsentTokenDataType> =>
  await axios
    .get(`${process.env.REACT_APP_WORKFLOW_API_BASE_URL}/${tenant}/user-activations/${activationToken}`)
    .then(res => res.data);

export const getRecurlyKey = async (): Promise<string> =>
  await api.get<{ token: string }>('/payment/token').then(({ data }) => data.token);

export const getPrice = async ({
  promoCode,
  planName,
  paymentPeriod,
  billingAddress,
}: {
  promoCode: string;
  planName: PRICING_PLAN;
  paymentPeriod: PAYMENT_PERIOD;
  billingAddress?: BillingAddressType;
}) =>
  await api
    .post('/payment/preview', {
      promoCode,
      planName,
      paymentPeriod,
      ...billingAddress,
    })
    .then(res => res.data);

export const getPaymentMethod = async (): Promise<PAYMENT_METHOD> =>
  await api.get('/payment-methods').then(res => res.data.paymentMethod);

export const submitPayment = async ({ planName, paymentPeriod, promoCode, token, billingAddress }: PricingType) => {
  const externalId = planName.includes('CC_')
    ? ConsentsExternalIdType.USERCENTRICS_TERMS_AND_CONDITIONS
    : ConsentsExternalIdType.TERMS_AND_CONDITIONS;

  return await api
    .post('/payment', {
      recurlyToken: token,
      planName,
      paymentPeriod,
      promoCode,
      consents: [{ externalId, version: '1' }],
      ...billingAddress,
    })
    .then(res => res.data);
};

export const loadPricingPlanUpgradeState = () =>
  api.get<PricingPlanUpgradeStateType>('/upgrade').then(({ data }) => data);

export const sendSecondUserConsents = async (
  tenant: string,
  userEmail: string,
  consents: {
    sendEmails: { externalId: ConsentsExternalIdType; action: boolean };
    privacyNotice: { externalId: ConsentsExternalIdType; action: boolean };
  },
) =>
  await axios.post(`${process.env.REACT_APP_WORKFLOW_API_BASE_URL}/${tenant}/consentlogs`, {
    userEmail,
    consents: map(consents, ({ externalId, action }) => ({
      externalId,
      version: externalId === ConsentsExternalIdType.PRIVACY_NOTICE ? '2' : '1', // SEND_EMAILS still use version 1
      action: action ? ConsentsActionType.ACCEPT : ConsentsActionType.DECLINE,
    })),
  });

export const resendEmail = (email: string) =>
  registrationApi.post('/registration/email-verification/confirmation-email', { email });

export const fetchCaptchaConfig = (): Promise<{ enabled: boolean }> =>
  registrationApi.get('/captcha').then(({ data }) => data);

export const logoutEvent = async () => await api.post('/logout');
