import { BigidColorsV2 } from '@bigid-ui/components';
import { styled } from '@mui/material/styles';

const PREFIX = 'WorkflowCustomizationOnboarding';

export const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  carousel: `${PREFIX}-carousel`,
  indicator: `${PREFIX}-indicator`,
  activeIndicator: `${PREFIX}-activeIndicator`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
};

export const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  [`& .${classes.content}`]: {
    height: 380,
    width: 480,
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${classes.carousel}`]: {
    height: '100%',
    width: '100%',
    '& > :first-child': {
      height: 'calc(100% - 42px) !important',
    },
  },
  [`& .${classes.indicator}`]: {
    color: BigidColorsV2.gray[500],
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  [`& .${classes.activeIndicator}`]: {
    color: BigidColorsV2.purple[400],
  },
  [`& .${classes.buttons}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`& .${classes.button}`]: {
    '&:not(:last-child)': {
      marginRight: 16,
    },
  },
});
