import React, { FC, useEffect } from 'react';
import cx from 'classnames';
import { FeatureCode, RequestType, UseCaseGetType, UseCaseTranslationsType } from '../../types';
import { UseCases } from './utils';
import { useStyles } from './UseCaseStyles';
import { PricingPlanContainer } from '../../state/pricingPlanContainer';
import { isRequestTypeDisabled } from '../../utils';
import { MeUpgradeButton } from '../MeUpgradeButton';
import { MeTile } from '../MeTile';
import { BigidSwitch } from '@bigid-ui/components';

export interface UseCasePropsType {
  id: number;
  type: RequestType;
  enabled: boolean;
  ordinal: number;
  compact?: boolean;
  tooltip?: string;
  onChange: (enabled: boolean) => void;
  translations: UseCaseTranslationsType;
}

export const UseCase: FC<UseCasePropsType> = ({
  id,
  type,
  enabled,
  ordinal,
  compact,
  tooltip,
  onChange,
  translations,
}) => {
  const classes = useStyles({ compact });

  const { features, fetchFeatures } = PricingPlanContainer.useContainer();

  useEffect(() => {
    fetchFeatures([
      FeatureCode.FLOW_EDIT,
      FeatureCode.FLOW_PREFERENCES,
      FeatureCode.FLOW_OPT_OUT,
      FeatureCode.FLOW_APPEAL,
    ]);
  }, [fetchFeatures]);

  const renderToggle = ({ id, type, enabled }: UseCaseGetType) => {
    const { featureCode } = UseCases[type];

    if (isRequestTypeDisabled(features, type)) {
      return (
        <span className={classes.toggle}>
          <MeUpgradeButton tooltip={`Upgrade your plan to \n enable this use case.`} />
        </span>
      );
    }

    if (!featureCode || features[featureCode]?.enabled) {
      return (
        <div className={classes.toggle}>
          <BigidSwitch
            id={String(id)}
            checked={enabled}
            onChange={(_, enabled) => onChange(enabled)}
            dataAid={`useCaseToggle-${id}`}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div
      key={id}
      className={cx({
        [classes.disabled]: !enabled || isRequestTypeDisabled(features, type),
      })}
    >
      <MeTile
        icon={UseCases[type].iconSrc}
        title={UseCases[type].title}
        text={compact ? UseCases[type].descriptionShort : UseCases[type].description}
        tooltip={tooltip}
        compact={compact}
        testId={`useCaseTile-${id}`}
      >
        {renderToggle({ id, type, enabled, ordinal, translations })}
      </MeTile>
    </div>
  );
};
