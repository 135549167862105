import React, { FC, ReactNode } from 'react';
import cx from 'classnames';

import { useStyles } from './SystemNotificationStyles';
import { useFormatting } from '../../hooks/useFormatting';
import { BigidSyncIcon, BigidClearFilledIcon } from '@bigid-ui/icons';
import { Box } from '@mui/material';

export enum SystemNotificationStatus {
  ERROR,
  UPDATE_AVAILABLE,
}

const config: Record<
  SystemNotificationStatus,
  {
    icon: ReactNode;
    title: string;
    message: string;
  }
> = {
  [SystemNotificationStatus.ERROR]: {
    icon: <BigidClearFilledIcon color="negative" />,
    title: 'Connection Error',
    message: 'No connection to the system. Please re-enter credentials details.',
  },
  [SystemNotificationStatus.UPDATE_AVAILABLE]: {
    icon: <BigidSyncIcon color="info" />,
    title: 'Updates Available',
    message:
      'These updates requires re-authentication. Please click the Update Login Credentials button to confirm your credentials.',
  },
};

type SystemNotificationPropsType = {
  status?: SystemNotificationStatus;
  lastUpdateTimestamp?: string;
};

export const SystemNotification: FC<SystemNotificationPropsType> = ({ status, lastUpdateTimestamp }) => {
  const classes = useStyles();
  const { getFormattedDate } = useFormatting();

  if (status === undefined || !config[status]) {
    return null;
  }

  const { icon, title, message } = config[status] || {};

  return (
    <div
      className={cx(classes.root, {
        [classes.error]: status === SystemNotificationStatus.ERROR,
        [classes.info]: status === SystemNotificationStatus.UPDATE_AVAILABLE,
      })}
      data-aid={`system-notification-${status}`}
    >
      <div className={classes.messageBlock}>
        <Box mr={2}>{!!icon && icon}</Box>
        <div>
          <div className={classes.title}>{title}</div>
          <div>{message}</div>
        </div>
      </div>
      <div className={classes.timestampBlock}>
        {lastUpdateTimestamp
          ? getFormattedDate(lastUpdateTimestamp, {
              hour: 'numeric',
              minute: 'numeric',
            })
          : ''}
      </div>
    </div>
  );
};
