import React, { FC, useMemo } from 'react';
import { BigidSwitch } from '@bigid-ui/components';
import { Root, classes } from './RequestStageTableStyles';
import { map } from 'lodash';

import {
  RequestWorkflowStageEmailCode,
  RequestWorkflowStageEmailType,
  StageConfigType,
  StageEmailConfigurationType,
} from '../../../types';
import { useFormatting } from '../../../hooks/useFormatting';

interface RequestStageRowPropsType {
  rows: RequestWorkflowStageEmailType[];
  rowsToUpdate?: RequestWorkflowStageEmailType[];
  isConfirmSection?: boolean;
  onRowClick: (configType: StageConfigType, row: StageEmailConfigurationType) => void;
  onStageEmailActiveSwitch: (args: {
    rowsData: RequestWorkflowStageEmailType[];
    row: RequestWorkflowStageEmailType;
    id: number;
    checked: boolean;
    isConfirmSection?: boolean;
  }) => void;
}

export const RequestStageRow: FC<RequestStageRowPropsType> = ({
  rows,
  rowsToUpdate,
  isConfirmSection,
  onRowClick,
  onStageEmailActiveSwitch,
}) => {
  const { getFormattedDate } = useFormatting();

  const confirmEmailsEnableModificated = useMemo(
    () =>
      rows.reduce(
        (
          prev: RequestWorkflowStageEmailType[],
          curr: RequestWorkflowStageEmailType,
          id: number,
          arr: RequestWorkflowStageEmailType[],
        ) => {
          return [
            ...prev,
            {
              ...curr,
              disabled:
                curr.code !== RequestWorkflowStageEmailCode.COMMON_REQUEST_RECEIVED && id !== 0 && !arr[0].enabled,
              enabled:
                curr.code !== RequestWorkflowStageEmailCode.COMMON_REQUEST_RECEIVED && id !== 0 && !arr[0].enabled
                  ? false
                  : curr.enabled,
            },
          ];
        },
        [],
      ),
    [rows],
  );
  const rowsData = useMemo(
    () => (isConfirmSection ? confirmEmailsEnableModificated : rows),
    [confirmEmailsEnableModificated, isConfirmSection, rows],
  );

  return (
    <Root>
      {map(rowsData, (row: RequestWorkflowStageEmailType, id: number) => (
        <tr
          key={row.id}
          className={classes.tr}
          data-aid={`general-tr${row.id}`}
          onClick={() => onRowClick('general', row as StageEmailConfigurationType)}
        >
          <td>{row.name}</td>
          <td className={classes.triggerTd}>{row.trigger}</td>
          <td>
            {row.modificationDate ? (
              getFormattedDate(row.modificationDate, {
                hour: 'numeric',
                minute: 'numeric',
              })
            ) : (
              <span className={classes.dashCentering}>-</span>
            )}
          </td>
          <td className={classes.activeTd} onClick={event => event.stopPropagation()}>
            <BigidSwitch
              checked={row.enabled}
              dataAid={`${row.code}-stage-email__switch`}
              disabled={row.disabled}
              onChange={(event, checked) =>
                onStageEmailActiveSwitch({ rowsData: rowsToUpdate || rowsData, row, id, checked, isConfirmSection })
              }
            />
          </td>
          <td className={classes.activeTd}></td>
        </tr>
      ))}
    </Root>
  );
};
