import { MapImageSeries, MapPolygonSeries, MapChart, ZoomControl } from '@amcharts/amcharts4/maps';
import { Pattern, Image, color, Circle, AMElement } from '@amcharts/amcharts4/core';

import mapPattern from '../../assets/images/charts/map-pattern.png';
import { BigidColorsV2 } from '@bigid-ui/components';

const getPattern = (): Pattern => {
  const pattern = new Pattern();
  const image = new Image();
  image.href = mapPattern;
  image.width = 4;
  image.height = 4;
  pattern.width = 5;
  pattern.height = 5;
  pattern.addElement(image.element as AMElement);
  return pattern;
};

export const setPolygonSeries = (chart: MapChart): void => {
  const polygonSeries = chart.series.push(new MapPolygonSeries());
  polygonSeries.mapPolygons.template.strokeWidth = 1; // hidding countries borders 0
  polygonSeries.exclude = ['AQ'];
  // Make map load polygon (like country names) data from GeoJSON
  polygonSeries.useGeodata = true;
  const polygonTemplate = polygonSeries.mapPolygons.template;
  polygonTemplate.fill = getPattern();
  polygonTemplate.propertyFields.fill = 'fill';
};

export const setImageSeries = (chart: MapChart): MapImageSeries => {
  const imageSeries = chart.series.push(new MapImageSeries());
  imageSeries.mapImages.template.propertyFields.longitude = 'longitude';
  imageSeries.mapImages.template.propertyFields.latitude = 'latitude';
  imageSeries.mapImages.template.tooltipText = '{title}: {requestsCount}';
  // imageSeries.mapImages.template.propertyFields.url = 'url';
  return imageSeries;
};

export const setZoomControl = (chart: MapChart): void => {
  chart.zoomControl = new ZoomControl();
  chart.zoomControl.plusButton.background.fill = color(BigidColorsV2.white);
  chart.zoomControl.plusButton.stroke = color(BigidColorsV2.gray[500]);
  chart.zoomControl.plusButton.strokeWidth = 1;
  chart.zoomControl.plusButton.background.cornerRadius(15, 15, 5, 5);

  chart.zoomControl.minusButton.background.fill = color(BigidColorsV2.white);
  chart.zoomControl.minusButton.stroke = color(BigidColorsV2.gray[500]);
  chart.zoomControl.minusButton.strokeWidth = 1;
  chart.zoomControl.minusButton.background.cornerRadius(5, 5, 15, 15);
};

export const setBullets = (imageSeries: MapImageSeries): void => {
  const circle = imageSeries.mapImages.template.createChild(Circle);
  circle.fill = color(BigidColorsV2.blue[400]);
  circle.radius = 2;

  // TODO: calculate halo relatively to requests count
  const halo = imageSeries.mapImages.template.createChild(Circle);
  halo.propertyFields.radius = 'requestsCount';
  halo.fill = color(BigidColorsV2.blue[400]);
  halo.fillOpacity = 0.3;
};
