import { useState } from 'react';
import { createContainer } from 'unstated-next';

export const useAppealGuidedTour = () => {
  const [isAppealTourRunning, setIsAppealTourRunning] = useState<boolean>(false);
  const [appealTourStartedFrom, setAppealTourStartedFrom] = useState<'editor' | 'brands' | null>(null);
  const [appealTourStepIndex, setAppealTourStepIndex] = useState<number>(0);

  const restartAppealTour = () => {
    setAppealTourStartedFrom('editor');
    setAppealTourStepIndex(0);
    setIsAppealTourRunning(true);
  };

  return {
    isAppealTourRunning,
    setIsAppealTourRunning,
    appealTourStartedFrom,
    setAppealTourStartedFrom,
    appealTourStepIndex,
    setAppealTourStepIndex,
    restartAppealTour,
  };
};

export const AppealGuidedTourContainer = createContainer(useAppealGuidedTour);
