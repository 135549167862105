import React, { FC } from 'react';
import { map } from 'lodash';
import { IconButton } from '@mui/material';
import { Root, classes } from './ManualCloseResolutionTableStyles';

import { ManualCloseResolutionsGetType, StageConfigType, StageEmailConfigurationType } from '../../../types';
import { useFormatting } from '../../../hooks/useFormatting';
import { BigidMoreActionIcon } from '@bigid-ui/icons';

interface ManualCloseResolutionRowPropsType {
  rows: ManualCloseResolutionsGetType[];
  setOpenIndexMenu: (indexMenu: number) => void;
  setRef: (key: string | number) => (instance: HTMLElement | null) => void;
  onRowClick: (configType: StageConfigType, row: StageEmailConfigurationType) => void;
}

export const ManualCloseResolutionRow: FC<ManualCloseResolutionRowPropsType> = ({
  rows,
  onRowClick,
  setOpenIndexMenu,
  setRef,
}) => {
  const { getFormattedDate } = useFormatting();

  return (
    <Root>
      {map(rows, (row: ManualCloseResolutionsGetType) => (
        <tr
          key={row.id}
          className={classes.tr}
          data-aid={`manualclose-tr${row.id}`}
          onClick={() => onRowClick('manual', row as StageEmailConfigurationType)}
        >
          <td>
            {row.name} {row.defaultForStage && <span className={classes.default}>(Default)</span>}
          </td>
          <td className={classes.triggerTd}>Request closed manually</td>
          <td>
            {row.modificationDate ? (
              getFormattedDate(row.modificationDate, {
                hour: 'numeric',
                minute: 'numeric',
              })
            ) : (
              <span className={classes.dashCentering}>-</span>
            )}
          </td>
          <td className={classes.activeTd}></td>
          <td className={classes.activeTd} onClick={event => event.stopPropagation()}>
            {row.emailTemplateId && (
              <IconButton
                onClick={() => setOpenIndexMenu(row.id)}
                ref={setRef(row.id)}
                size="small"
                data-aid={`manualclose-${row.type}-menu-action-${row.id}`}
              >
                <BigidMoreActionIcon />
              </IconButton>
            )}
          </td>
        </tr>
      ))}
    </Root>
  );
};
