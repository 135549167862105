import React, { FC, useMemo } from 'react';
import { workflowCustomizationOnboardingDialogOptions } from './WorkflowCustomizationOnboarding/WorkflowCustomizationOnboarding';
import { isEmpty } from 'lodash';
import { NewFeatureContainer } from '../../state/newFeatureContainer';
import { ReleasedFeatureCodeType } from '../../types/ReleasedFeaturesTypes';
import { BigidDialog } from '@bigid-ui/components';

const NewFeatures = [
  {
    name: ReleasedFeatureCodeType.WORKFLOW_CUSTOMIZATION,
    dialogOptions: workflowCustomizationOnboardingDialogOptions,
  },
];

export const NewFeatureOnboarding: FC = () => {
  const { getFeatureByCode, setNewFeaturesList } = NewFeatureContainer.useContainer();

  const activeDialog = useMemo(
    () => NewFeatures.find(({ name }) => getFeatureByCode(name) && !getFeatureByCode(name)?.viewed)?.dialogOptions,
    [getFeatureByCode],
  );

  const { title, content: Content, onClose } = activeDialog || {};

  const handleClose = async () => {
    if (onClose) {
      await onClose();
    }
    setNewFeaturesList([]);
  };

  return (
    <>
      {!isEmpty(activeDialog) && (
        <BigidDialog title={title || ''} isOpen={!isEmpty(activeDialog)} onClose={handleClose}>
          <div data-aid="newFeatureOnboardingDialog">
            {Content && <Content onClose={handleClose} key={Date.now()} />}
          </div>
        </BigidDialog>
      )}
    </>
  );
};
