import React, { ReactNode } from 'react';
import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { ActionData, BigidDropdownValue, TertiaryButton, ToolbarActionType } from '@bigid-ui/components';
import { FormikProps } from 'formik';
// eslint-disable-next-line import/no-unresolved

import {
  AddScopeStates,
  DatasourcesConnectionMethod,
  DatasourcesConnectionStatuses,
  DatasourcesConnectorType,
  DataSourceScopeType,
  DatasourcesGridType,
  DatasourcesProviderType,
  DatasourcesRowType,
  EmailSenderDataType,
  GeneralSettingsGetType,
  InstancesRowType,
  PortalEditorGetDataType,
  PreferencesStepType,
  RequestType,
  RoleInfoFieldsType,
  RoleInfoType,
  RoleOptionsType,
  RolesRowType,
  StatusCardType,
  SystemFieldNames,
  SystemInfoType,
  UserInfoType,
} from '../types';
import { ReactComponent as DeepDiscoveryIcon } from '../assets/icons/deep-discovery.svg';
import { FeaturesStateType } from '../state/pricingPlanContainer';
import { UseCases } from '../components/UseCase/utils';
import { SystemNotificationStatus } from '../components/SystemNotification/SystemNotification';
import { MeTextFieldWithForm } from '../components/MeTextFieldWithForm';
import { BigidDropdownWithForm } from '../components/BigidDropdownWithForm';
import { formatDataSource, renderSystemCell, tooltipifyEntry } from './rendersUtils';
import { normalizeHyperlinkToLink } from './normalizerUtils';
import { GetFormattedDateType } from '../types/CommonTypes';
import { BigidMeColorSchemesVars, ColorSchemes } from '@consumer/assets/theme';
import { BigidConfigurationIcon, BigidSyncIcon, BigidSavedQueriesIcon, BigidClearFilledIcon } from '@bigid-ui/icons';
import { Box, Stack } from '@mui/material';

export const DEFAULT_DATASOURCE_TYPE = {
  name: '',
  displayName: '',
  id: 0,
  providerType: DatasourcesProviderType.BIGID,
  supported: false,
};

export const DEFAULT_SYSTEM: SystemInfoType = {
  id: 0,
  name: '',
  connectionStatus: DatasourcesConnectionStatuses.NONE,
  connectionStatusUpdateTimestamp: '',
  collectingMethod: DatasourcesConnectionMethod.NONE,
  authorizationUrl: '',
  dataSourceType: {
    id: 0,
    name: '',
    displayName: '',
    supported: true,
    providerType: DatasourcesProviderType.BIGID,
  },
  scopes: [],
  profiles: [],
  accesses: [],
  connectorUserEmail: '',
};

export const NEW_DATASOURCE_TYPE_ID = -1;

export const SystemConnectionStatusesChips = {
  [DatasourcesConnectionStatuses.NONE]: {
    text: '',
    type: StatusCardType.NONE,
  },
  [DatasourcesConnectionStatuses.NOT_CONNECTED]: {
    text: 'Pending',
    type: StatusCardType.PROGRESS,
  },
  [DatasourcesConnectionStatuses.CONNECTED]: {
    text: 'Connected',
    type: StatusCardType.SUCCESS,
  },
  [DatasourcesConnectionStatuses.SIGN_IN]: {
    text: 'Authentication Success',
    type: StatusCardType.SUCCESS,
  },
  [DatasourcesConnectionStatuses.PENDING]: {
    text: 'Pending',
    type: StatusCardType.PROGRESS,
  },
  [DatasourcesConnectionStatuses.FAILED]: {
    text: 'Connection Error',
    type: StatusCardType.WARNING,
  },
  [DatasourcesConnectionStatuses.EXPIRED]: {
    text: 'Connection Expired',
    type: StatusCardType.WARNING,
  },
  [SystemNotificationStatus.UPDATE_AVAILABLE]: {
    text: 'Update Available',
    type: StatusCardType.NONE,
  },
};

export const getSystemsColumns = (
  onUpdateSystem: (id: number) => void,
  getFormattedDate: GetFormattedDateType,
): BigidGridColumn<DatasourcesRowType>[] => [
  {
    name: 'id',
    title: 'System ID',
    getCellValue: ({ id, stagedForDeletion }: DatasourcesRowType) =>
      renderSystemCell({ stagedForDeletion, content: id }),
    width: 150,
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
  },
  {
    name: 'datasourceType',
    title: 'System',
    getCellValue: ({ datasourceType, stagedForDeletion }: DatasourcesRowType) =>
      renderSystemCell({ stagedForDeletion, content: formatDataSource(datasourceType, datasourceType) }),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
    sortingEnabled: false,
  },
  {
    name: 'name',
    title: 'Name',
    getCellValue: ({ name, stagedForDeletion }: DatasourcesRowType) =>
      renderSystemCell({ stagedForDeletion, content: name }),
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'scopes',
    title: 'Department',
    getCellValue: ({ scopes, stagedForDeletion }: DatasourcesRowType) =>
      renderSystemCell({ stagedForDeletion, content: tooltipifyEntry(scopes, undefined, 'All') }),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
    sortingEnabled: false,
  },
  {
    name: 'profiles',
    title: 'Profile',
    getCellValue: ({ profiles, stagedForDeletion }: DatasourcesRowType) =>
      renderSystemCell({ stagedForDeletion, content: tooltipifyEntry(profiles, undefined, 'All') }),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
    sortingEnabled: false,
  },
  {
    name: 'connectionStatus',
    title: 'Last Update',
    width: 270,
    getCellValue: ({
      id,
      name,
      connectionStatus,
      updateRequired,
      stagedForDeletion,
      connectionStatusUpdateTimestamp,
    }: DatasourcesRowType) =>
      renderSystemCell({
        stagedForDeletion,
        updateRequired,
        connectionStatus,
        name,
        content: renderConnectionStatus(
          id,
          connectionStatus,
          onUpdateSystem,
          getFormattedDate,
          updateRequired,
          connectionStatusUpdateTimestamp,
        ),
      }),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
    sortingEnabled: false,
  },
  {
    name: 'connectorType',
    title: 'Connector Type',
    width: 160,
    getCellValue: ({ connectorType, stagedForDeletion }: DatasourcesRowType) =>
      renderSystemCell({ stagedForDeletion, content: renderConnectorType(connectorType) }),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
    sortingEnabled: false,
  },
];

export const CONNECTOR_TYPE_CONFIG = {
  [DatasourcesConnectorType.AUTO_SCANNING]: {
    label: 'Auto Scanning',
    icon: BigidSavedQueriesIcon,
  },
  [DatasourcesConnectorType.DEEP_DISCOVERY]: {
    label: 'Deep Discovery',
    icon: DeepDiscoveryIcon,
  },
  [DatasourcesConnectorType.WORKFLOW]: {
    label: 'Workflow',
    icon: BigidConfigurationIcon,
  },
};

export const renderConnectorType = (connectorType?: DatasourcesConnectorType): ReactNode => {
  if (!connectorType || !CONNECTOR_TYPE_CONFIG[connectorType]) {
    return null;
  }

  const { icon: ConnectorTypeIcon, label } = CONNECTOR_TYPE_CONFIG[connectorType];

  return (
    <div style={{ display: 'flex' }}>
      <ConnectorTypeIcon /> <span style={{ marginLeft: 10 }}>{label}</span>
    </div>
  );
};

export const renderConnectionStatus = (
  systemId: number,
  connectionStatus: DatasourcesConnectionStatuses,
  onUpdateSystem: (id: number) => void,
  getFormattedDate: GetFormattedDateType,
  updateRequired?: boolean,
  connectionStatusUpdateTimestamp?: string,
): ReactNode => {
  if (connectionStatus === DatasourcesConnectionStatuses.NONE) {
    return '-';
  }

  const isConnectionFailed = connectionStatus === DatasourcesConnectionStatuses.FAILED;

  if (!isConnectionFailed && updateRequired) {
    return (
      <Stack direction="row" justifyContent="center">
        <TertiaryButton
          startIcon={<BigidSyncIcon />}
          onClick={e => {
            e?.stopPropagation();
            onUpdateSystem(systemId);
          }}
          size="medium"
          text="Updates Available"
        />
      </Stack>
    );
  }

  if (isConnectionFailed) {
    return (
      <>
        <Box sx={{ verticalAlign: 'middle', display: 'inline', mr: 1 }}>
          <BigidClearFilledIcon color="negative" />
        </Box>
        Connection Error{' '}
        {connectionStatusUpdateTimestamp
          ? getFormattedDate(connectionStatusUpdateTimestamp, {
              month: 'short',
            })
          : ''}
      </>
    );
  }

  return (
    <Stack direction="row" alignItems="center">
      Last Connection{' '}
      {connectionStatusUpdateTimestamp
        ? getFormattedDate(connectionStatusUpdateTimestamp, {
            month: 'short',
          })
        : ''}
    </Stack>
  );
};

export const usersColumns: BigidGridColumn<UserInfoType>[] = [
  {
    name: 'id',
    title: 'User ID',
    getCellValue: ({ id }: UserInfoType) => id,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'name',
    title: 'Full Name',
    getCellValue: ({ firstName, lastName }: UserInfoType) => [firstName, lastName].join(' '),
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'email',
    title: 'Email',
    getCellValue: ({ email }: UserInfoType) => email,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'role',
    title: 'Role',
    getCellValue: ({ roles }: UserInfoType) => tooltipifyEntry(roles.map(r => r.name)),
    type: BigidGridColumnTypes.CUSTOM,
  },
];

export const connectFields = {
  [SystemFieldNames.NAME]: {
    label: 'System Name',
    name: SystemFieldNames.NAME,
    placeholder: 'e.g. Campaign Monitor_Company name',
  },
  [SystemFieldNames.DATA_SOURCE_NAME]: {
    label: 'Type',
    name: SystemFieldNames.DATA_SOURCE_NAME,
    placeholder: '- select system type -',
  },
  [SystemFieldNames.SCOPE]: {
    label: 'Department',
    name: SystemFieldNames.SCOPE,
    tooltip: 'The System Department determines which roles can access this system',
    placeholder: 'All',
  },
  [SystemFieldNames.USER_PROFILES]: {
    label: 'Profile',
    name: SystemFieldNames.USER_PROFILES,
    tooltip: {
      privacyPortal: 'Specify for which types of requestors this system is scanned.',
      deepDiscovery:
        'Profiles for Deep Discovery systems are configured in Data Rights Fulfillment in the core enterprise platform.',
    },
    placeholder: 'All',
  },
  [SystemFieldNames.ACCESSES]: {
    label: 'Use Cases',
    name: SystemFieldNames.ACCESSES,
    placeholder: 'All',
  },
  [SystemFieldNames.THIRD_PARTY]: {
    label: 'Mark system as 3rd party',
    name: SystemFieldNames.THIRD_PARTY,
    tooltip: 'This will allow you to identify the systems managed by 3rd parties',
  },
};

export const normalizeDatasources = (datasources: DatasourcesGridType[]) => {
  const data = datasources.map(
    ({
      dataSourceType,
      id,
      name,
      scopes,
      profiles = [],
      connectionStatus,
      connectionStatusUpdateTimestamp,
      collectingMethod,
      connectorType,
      stagedForDeletion,
      updateRequired,
      thirdParty,
    }) => ({
      id,
      name,
      scopes: scopes.map(({ name }) => name),
      profiles: profiles.map(({ name }) => name),
      datasourceType: dataSourceType.name,
      datasourceTypeDisplayName: dataSourceType.displayName,
      connectionStatus,
      connectionStatusUpdateTimestamp,
      collectingMethod,
      connectorType,
      stagedForDeletion,
      updateRequired,
      thirdParty,
    }),
  );

  return {
    totalCount: data.length,
    data,
  };
};

export const rolesColumns: BigidGridColumn<RolesRowType>[] = [
  {
    name: 'name',
    title: 'Role Name',
    getCellValue: ({ name }: RolesRowType) => name,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'permissionGroup',
    title: 'Permissions',
    getCellValue: ({ permissionGroup }: RolesRowType) => permissionGroup?.name || '',
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'scope',
    title: 'Department',
    getCellValue: ({ scope }: RolesRowType) => scope?.name || '',
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
];

export const roleInfoFields = [
  {
    name: RoleInfoFieldsType.NAME,
    label: 'Role Name',
    type: 'text',
    placeholder: 'e.g. Collaborator - HR',
  },
  {
    name: RoleInfoFieldsType.PERMISSION,
    label: 'Permissions',
    placeholder: 'Select Permissions Group',
  },
  {
    name: RoleInfoFieldsType.SCOPE,
    label: 'Department',
    placeholder: 'Select a Department',
  },
];

export const defaultRolesData: RoleInfoType = {
  name: '',
  permissionGroupId: 1,
  scopeId: 1,
};

export const scopesColumns: BigidGridColumn<DataSourceScopeType>[] = [
  {
    name: 'name',
    title: 'Department Name',
    getCellValue: ({ name }: DataSourceScopeType) => name,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'dataSourceTypes',
    title: 'Systems',
    getCellValue: ({ dataSourceTypes }: DataSourceScopeType) => tooltipifyEntry(dataSourceTypes),
    type: BigidGridColumnTypes.CUSTOM,
  },
  {
    name: 'dataSources',
    title: 'Instances',
    width: 800,
    getCellValue: ({ dataSources }: DataSourceScopeType) => tooltipifyEntry(dataSources, 80),
    type: BigidGridColumnTypes.CUSTOM,
  },
];

export const instancesColumns: BigidGridColumn<InstancesRowType>[] = [
  {
    name: 'id',
    title: 'System ID',
    getCellValue: ({ id }: InstancesRowType) => id,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
  {
    name: 'datasourceType',
    title: 'System',
    getCellValue: ({ datasourceType, datasourceTypeDisplayName }: InstancesRowType) =>
      formatDataSource(datasourceTypeDisplayName, datasourceType),
    type: BigidGridColumnTypes.CUSTOM,
    disableTooltip: true,
  },
  {
    name: 'name',
    title: 'Instance Name',
    getCellValue: ({ name }: InstancesRowType) => name,
    type: BigidGridColumnTypes.TEXT,
    disableTooltip: true,
  },
];

export const defaultScopesData = {
  name: '',
  instances: [],
};

export const renderScopesGridToolbarActions = (onToolbarAction: (id: Array<string | number>) => Promise<void>) => [
  {
    label: 'Remove from Department',
    type: ToolbarActionType.SECONDARY,
    execute: async ({ selectedRowIds }: ActionData) => {
      return onToolbarAction(selectedRowIds!).then(() => ({
        shouldGridReload: true,
        shouldClearSelection: true,
      }));
    },
    show: ({ selectedRowIds }: ActionData) => !!selectedRowIds?.length,
  },
];

export const AddScopeTabs = [
  {
    label: 'Name Department',
    id: AddScopeStates.NAME_SCOPE,
  },
  {
    label: 'Select Instances',
    id: AddScopeStates.SELECT_INSTANCES,
  },
];

export const renderRolesFields = (
  formData: FormikProps<RoleInfoType>,
  classes: Record<string, string>,
  options?: RoleOptionsType,
  readonly?: boolean,
) =>
  roleInfoFields.map(({ name, label, placeholder, type }) =>
    name === RoleInfoFieldsType.NAME ? (
      <MeTextFieldWithForm
        key={name}
        displayAsRequired
        name={name}
        type={type as 'text'}
        label={label}
        placeholder={placeholder}
        formData={formData}
        disabled={readonly}
        testId="name-field"
      />
    ) : (
      <div className={classes.inputWrapper} key={name}>
        <BigidDropdownWithForm
          name={name}
          formData={formData}
          label={label}
          options={options?.[name] || []}
          value={[formData.values[name]] as BigidDropdownValue & number[]}
          placeholder="Select a role"
          dataAid={`${name}-field`}
          isDisabled={readonly}
        />
      </div>
    ),
  );

export const portalEditorDefaultData = {
  textToggles: [],
  fieldToggles: [],
  steps: [],
  optOuts: [],
  generalSettings: {
    logoUrl: '',
    logoWidth: 'auto',
    logoHeight: 'auto',
    companyName: '',
    companyPhone: '',
    companyAddress: '',
    supportLink: '',
    termsConditionsLink: '',
    privacyPolicyLink: '',
    copyrightEnabled: false,
    termsConditionsLinkEnabled: false,
    privacyPolicyLinkEnabled: false,
    translations: {},
    theme: {
      name: ColorSchemes.LIGHT,
      ...BigidMeColorSchemesVars.light,
    },
  },
  emailSettings: {
    senderName: '',
    translations: {
      en: { footerText: '' },
    },
  },
};

export const normalizePortalEditorGetData = (
  {
    logoUrl,
    companyName,
    supportLink,
    termsConditionsLink,
    privacyPolicyLink,
    ...generalSettingsRest
  }: GeneralSettingsGetType,
  { steps }: { steps: PreferencesStepType[] },
  { steps: optOuts }: { steps: PreferencesStepType[] },
  emailSettings: EmailSenderDataType,
): PortalEditorGetDataType => {
  return {
    generalSettings: {
      logoUrl,
      companyName: companyName || '',
      supportLink: normalizeHyperlinkToLink(supportLink),
      termsConditionsLink: normalizeHyperlinkToLink(termsConditionsLink),
      privacyPolicyLink: normalizeHyperlinkToLink(privacyPolicyLink),
      ...generalSettingsRest,
    },
    steps,
    emailSettings,
    optOuts,
  };
};

export const defaultBasicSettings = {
  state: {
    permissionsEnabled: false,
    securityConfigurationEnabled: false,
  },
  allowOutsideRequestors: {
    allowOutsideRequestors: true,
  },
  useCases: [],
  regulations: [],
  integrations: [],
  locales: [],
  profiles: [],
};

export const isRequestTypeDisabled = (features: FeaturesStateType, requestType: RequestType) => {
  const featureCode = UseCases[requestType]?.featureCode;
  return features && featureCode && (features[featureCode]?.enabled === false || !features[featureCode]);
};
