import { PRICING_PLAN } from '.';

export enum CookieConsentPlanStatus {
  DISABLED = 'DISABLED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  ACTIVE_TRIAL = 'ACTIVE_TRIAL',
  EXPIRED_TRIAL = 'EXPIRED_TRIAL',
  ACTIVE_PAID = 'ACTIVE_PAID',
  EXPIRED_PAID = 'EXPIRED_PAID',
}

export enum COOKIE_CONSENT_LINK_KEYS {
  LOGIN_URL = 'loginUrl',
  ANALYTICS_URL = 'analyticsUrl',
  APPEARANCE_URL = 'appearanceUrl',
  CONSENTS_MANAGEMENT_URL = 'consentsManagementUrl',
  EMBEDDED_SCRIPT_URL = 'embeddedScriptUrl',
}

export type CookieConsentLinks = {
  [COOKIE_CONSENT_LINK_KEYS.LOGIN_URL]: string;
  [COOKIE_CONSENT_LINK_KEYS.ANALYTICS_URL]?: string;
  [COOKIE_CONSENT_LINK_KEYS.APPEARANCE_URL]?: string;
  [COOKIE_CONSENT_LINK_KEYS.CONSENTS_MANAGEMENT_URL]?: string;
  [COOKIE_CONSENT_LINK_KEYS.EMBEDDED_SCRIPT_URL]?: string;
};

export type CookieConsentPurchaseStateType = {
  planName: PRICING_PLAN;
  status: CookieConsentPlanStatus;
  expiresInDays: number;
  links: CookieConsentLinks;
};
