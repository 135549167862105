import makeStyles from '@mui/styles/makeStyles';
import { primaryBorder } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  system: {
    height: 137,
    width: 153,
    border: primaryBorder,
    borderRadius: 12,
    backgroundColor: theme.vars.palette.bigid?.white,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 8,
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.1)',
    },
  },
  systemLogo: {
    maxWidth: '100%',
    maxHeight: '80%',
    alignSelf: 'flex-end',
  },
  systemInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 26,
  },
  systemText: {
    display: 'block',
    color: theme.vars.palette.bigid?.gray700,
    fontSize: '0.8125rem',
    textAlign: 'center',
    fontWeight: 800,
    textTransform: 'capitalize',
    alignSelf: 'flex-end',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  connecting: {
    border: `1px solid ${theme.vars.palette.bigid?.blue900}`,
    boxShadow: '0 2px 25px 0 rgba(75,123,255,0.24)',
    '& $systemLogo': {
      maxHeight: '45%',
    },
  },
  connected: {
    border: `1px solid ${theme.vars.palette.bigid?.blue900}`,
    boxShadow: '0 2px 25px 0 rgba(75,123,255,0.24)',
    '& $systemLogo': {
      maxHeight: '45%',
    },
  },
  disabled: {
    border: primaryBorder,
    '& $systemLogo, & $systemInfo, & $systemText': {
      opacity: 0.4,
    },
  },
  error: {
    border: `1px solid ${theme.vars.palette.bigid?.red500}`,
    boxShadow: '0 0 8px 0 rgba(252,92,66,0.5)',
  },
  errorText: {
    position: 'absolute',
    bottom: -70,
    width: 150,
    textAlign: 'center',
    fontSize: '0.75rem',
    fontWeight: 600,
    color: theme.vars.palette.bigid?.red500,
  },
  disconnect: {
    position: 'absolute',
    bottom: -57,
    width: 150,
    textAlign: 'center',
    fontSize: '0.625rem',
    textDecoration: 'underline',
  },
}));
