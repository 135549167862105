import React, { FC } from 'react';
import { AuthorizedAgentGuidedTourStepTemplate } from './AuthorizedAgentGuidedTourStepTemplate';

interface AgentFormStepProps {
  dataAid?: string;
}

export const AgentFormStep: FC<AgentFormStepProps> = ({ dataAid }) => {
  return (
    <AuthorizedAgentGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Customize Authorized Agent form"
      firstSectionText="Customize fields and labels, and then click “Next”."
    />
  );
};
