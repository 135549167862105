import React, { ReactNode } from 'react';

import { TutorialStepsType } from '../../types/TutorialTypes';

export const TUTORIAL_STEPS = [
  {
    name: TutorialStepsType.ONBOARDING,
    text: 'Welcome',
    link: '/',
  },
  {
    name: TutorialStepsType.EDIT_PRIVACY_CENTER,
    text: 'Edit Privacy Center',
  },
  {
    name: TutorialStepsType.SYSTEM,
    text: 'Add System',
  },
  {
    name: TutorialStepsType.DEEP_DISCOVERY,
    text: 'Deep Discovery',
  },
  {
    name: TutorialStepsType.MAP_PROFILES,
    text: 'Map Profiles',
  },
  {
    name: TutorialStepsType.AUTOMATE_CONSENT,
    text: 'Automate Consent',
  },
  {
    name: TutorialStepsType.PUBLISH_PRIVACY_CENTER,
    text: 'Publish',
  },
];

export type TooltipText = { title: string; content: ReactNode };

export const tutorialTooltipSidebarText: Partial<Record<TutorialStepsType, TooltipText>> = {
  [TutorialStepsType.EDIT_PRIVACY_CENTER]: {
    title: 'Edit Privacy Center',
    content: 'Customize with your branding, texts, and required fields.',
  },
};

export const tutorialTooltipText: Partial<Record<TutorialStepsType, TooltipText>> = {
  [TutorialStepsType.EDIT_PRIVACY_CENTER]: {
    title: 'Save & Publish your customization',
    content: (
      <>
        The Privacy Center will be visible
        <br />
        to your users once you link it to
        <br />
        your website
      </>
    ),
  },
  [TutorialStepsType.SYSTEM]: {
    title: 'Add System',
    content: 'Click here to Select and register systems so you can start collecting data.',
  },
  [TutorialStepsType.DEEP_DISCOVERY]: {
    title: 'Deep Discovery',
    content: 'Click here to connect Privacy Portal to your core instance.',
  },
  [TutorialStepsType.MAP_PROFILES]: {
    title: 'Map Profiles',
    content: 'Click here to map profiles so you can optimize scan per profile.',
  },
  [TutorialStepsType.PUBLISH_PRIVACY_CENTER]: {
    title: 'Publish',
    content: 'Add this link to your website footer, privacy notice and emails.',
  },
  [TutorialStepsType.AUTOMATE_CONSENT]: {
    title: 'Automate Consent',
    content: 'Map consent type to system fields to automate consent management.',
  },
};

export const TUTORIAL_OVERLAY_ID = 'tutorialOverlay';
