import React, { FC } from 'react';
import { Root, classes } from './ActionsWorkflowTableStyles';

import { ActionsWorkflowGetType } from '../../../types';
import { SimpleTable } from '../../../components/SimpleTable';
import { ActionsWorkflowRow } from './ActionsWorkflowRow';

const TABLE_EMAIL_HEADERS = ['Names', 'Triggers', 'Last Update', 'Enabled', ''];
const TABLE_EMAIL_HEADERS_CLASSES = ['', classes.triggerTd, '', classes.activeTd, classes.activeTd];

interface ActionsWorkflowTablePropsType {
  rows: ActionsWorkflowGetType[];
}

export const ActionsWorkflowTable: FC<ActionsWorkflowTablePropsType> = ({ rows }) => {
  return (
    <Root>
      <SimpleTable
        th={TABLE_EMAIL_HEADERS}
        thClasses={TABLE_EMAIL_HEADERS_CLASSES}
        bodyTestId="actions-workflow-table"
        trElement={<ActionsWorkflowRow rows={rows} />}
      />
    </Root>
  );
};
