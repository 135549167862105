import makeStyles from '@mui/styles/makeStyles';
import { primaryBorder } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.vars.palette.bigid?.white,
    display: 'flex',
    height: '100%',
    minHeight: 224,
    minWidth: 1200,
    borderRadius: 4,
    boxShadow: '0 0 16px 0 rgba(103,102,102,0.11)',
  },
  tabs: {
    position: 'relative',
    borderRight: primaryBorder,
    '& button': {
      textAlign: 'start',
    },
    flexShrink: 0,
  },
  tabsBody: {
    padding: '0 20px 0 0',
    width: '100%',
  },
  collapseTrigger: {
    position: 'relative',
    top: 16,
    right: 14,
    width: 27,
    height: 23,
    borderRadius: '50%',
    border: primaryBorder,
    backgroundColor: theme.vars.palette.bigid?.white,
    cursor: 'pointer',
  },
  collapsedPanel: {
    width: 32,
    borderRight: primaryBorder,
  },
}));
