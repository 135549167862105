import { LanguageType } from '@consumer/state/languageContainer';

export type AgentAccountType = {
  accountUrl: string;
  userName: string;
  userPassword?: string;
  userPasswordHidden: boolean;
};

export type BigIdCloudConnectionType = {
  isVisible: boolean;
  isEnabled: boolean;
  bigIDCoreUrl: string;
  apiToken: string;
  hasApiTokenValue: boolean;
};

export type BigIdCloudConnectionUpdateType = {
  isEnabled: boolean;
  bigIDCoreUrl: string;
  apiToken: string;
};

export type BigIdCloudConnectionViewType = {
  visible: boolean;
  enabled: boolean;
  bigIDCoreUrl: string;
  hasApiTokenValue: boolean;
};

export enum AgentConnectionStatus {
  NOT_CONNECTED = 'NOT_CONNECTED',
  CONNECTED = 'CONNECTED',
  FAILED = 'FAILED',
}

export type AgentConnectivityType = {
  enabled: boolean;
  connectionStatus: AgentConnectionStatus;
  lastConnectionTimestamp: string;
  dataSourcesCount: number;
  profilesCount: number;
};

export type AgentProfileType = {
  name: string;
};

export type LocaleType = {
  code: LanguageType;
  name: string;
  useAsDefault: boolean;
};
