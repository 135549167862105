import React, { FC } from 'react';

import { useStyles } from './SystemUpdateMessageStyles';

type SystemUpdateMessagePropsType = {
  systemsList: string;
};

export const SystemUpdateMessage: FC<SystemUpdateMessagePropsType> = ({ systemsList }) => {
  const classes = useStyles();

  return (
    <div className={classes.systemUpdateMessage} data-aid="system-update-message">
      <div>A new version exists for {systemsList}. You&apos;ll need</div>
      <div>to re-authenticate to enable the update.</div>
    </div>
  );
};
