import React, { FC } from 'react';
import cx from 'classnames';

import { getDataSourceLogo } from '../../utils/dataSourceUtils';
import { SystemWrapper } from '../SystemWrapper/SystemWrapper';

import { useStyles } from './SystemLabelStyles';

export type SystemLabelPropsType = {
  name: string;
  type: string;
  className?: string;
  iconClassName?: string;
  labelClassName?: string;
};

export const SystemLabel: FC<SystemLabelPropsType> = ({ name, type, className, iconClassName, labelClassName }) => {
  const classes = useStyles();

  return (
    <SystemWrapper>
      <span className={cx(classes.system, className)}>
        <div
          className={cx(classes.systemIcon, iconClassName)}
          style={{
            background: `url('${getDataSourceLogo(type)}') no-repeat 50% 50%/contain`,
          }}
        />
        <span className={cx(classes.systemName, labelClassName)}>{name}</span>
      </span>
    </SystemWrapper>
  );
};
