import React from 'react';
import { upperFirst } from 'lodash';
import { classes, Root } from './SystemRequestTypeWithUrlStyles';
import { MeTextFieldWithForm } from '../../MeTextFieldWithForm';
import { FormikProps } from 'formik';
import { HttpRequestMethodType, NestedKeyOf } from '../../../types';
import { BigidDropdownWithForm } from '../../../components';
import { BigidDropdownOption } from '@bigid-ui/components';

export type SystemRequestTypeWithUrlProps<T = Record<string, string>> = {
  formData: FormikProps<T>;
  requestTypeName: NestedKeyOf<T>;
  urlName: NestedKeyOf<T>;
  testId?: string;
  errorMessage?: string;
};

const requestTypeOptions = Object.values(HttpRequestMethodType).map(option => ({
  displayValue: upperFirst(option.toLowerCase()),
  value: option as string,
  id: option as string,
}));

export const SystemRequestTypeWithUrl = <T extends Record<string, unknown>>({
  formData,
  requestTypeName,
  urlName,
  testId,
}: SystemRequestTypeWithUrlProps<T>) => {
  const requestTypeMeta = formData.getFieldMeta(requestTypeName as string);

  return (
    <Root>
      <div className={classes.container}>
        <div className={classes.dropdown}>
          <BigidDropdownWithForm
            name={requestTypeName}
            formData={formData}
            options={requestTypeOptions}
            value={[requestTypeMeta.value] as BigidDropdownOption[] & string[]}
            dataAid={`${testId}-type`}
            label="Request type"
          />
        </div>
        <MeTextFieldWithForm
          label="URL"
          displayAsRequired
          className={classes.urlInput}
          placeholder="https://bigiddemo.com/#/action-center/actions/"
          formData={formData}
          name={urlName}
          testId={testId}
        />
      </div>
    </Root>
  );
};
