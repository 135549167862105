import React, { FC } from 'react';
import { BigidSwitch } from '@bigid-ui/components';
import { Root, classes } from './RequestStageTableStyles';
import { map } from 'lodash';

import { RequestWorkflowStageEmailType, StageConfigType, StageEmailConfigurationType } from '../../../types';
import { useFormatting } from '../../../hooks/useFormatting';
import { VendorEngagementPreview } from './VendorEngagementPreview';

interface RequestStageRowPropsType {
  rows: RequestWorkflowStageEmailType[];
  rowsToUpdate?: RequestWorkflowStageEmailType[];
  isConfirmSection?: boolean;
  onRowClick: (configType: StageConfigType, row: StageEmailConfigurationType) => void;
  onStageEmailActiveSwitch: (args: {
    rowsData: RequestWorkflowStageEmailType[];
    row: RequestWorkflowStageEmailType;
    id: number;
    checked: boolean;
    isConfirmSection?: boolean;
  }) => void;
}

export const VendorDeletionRow: FC<RequestStageRowPropsType> = ({
  rows,
  isConfirmSection,
  onRowClick,
  onStageEmailActiveSwitch,
}) => {
  const { getFormattedDate } = useFormatting();

  return (
    <Root>
      {map(rows, (row: RequestWorkflowStageEmailType, id: number) => (
        <tr
          key={row.id}
          className={classes.tr}
          data-aid={`vendor-communication-row-${row.id}`}
          onClick={() => onRowClick('general', row as StageEmailConfigurationType)}
        >
          <td>{row.name}</td>
          <td className={classes.triggerTd}>
            {row.vendorEngagements?.length ? (
              <VendorEngagementPreview vendorEngagements={row.vendorEngagements} />
            ) : (
              <span className={classes.dashCentering}>-</span>
            )}
          </td>
          <td>
            {row.modificationDate ? (
              getFormattedDate(row.modificationDate, {
                hour: 'numeric',
                minute: 'numeric',
              })
            ) : (
              <span className={classes.dashCentering}>-</span>
            )}
          </td>
          <td className={classes.activeTd} onClick={event => event.stopPropagation()}>
            <BigidSwitch
              checked={row.enabled}
              dataAid={`${row.code}-stage-email__switch`}
              disabled={row.disabled}
              onChange={(event, checked) =>
                onStageEmailActiveSwitch({ rowsData: rows, row, id, checked, isConfirmSection })
              }
            />
          </td>
          <td className={classes.activeTd}></td>
        </tr>
      ))}
    </Root>
  );
};
