import { styled } from '@mui/material/styles';

const PREFIX = 'WorkflowEmailTemplate';

export const classes = {
  root: `${PREFIX}-root`,
  textRow: `${PREFIX}-textRow`,
  textRowTooltip: `${PREFIX}-textRowTooltip`,
  row: `${PREFIX}-row`,
  purpleTitle: `${PREFIX}-purpleTitle`,
  progressbarWrapper: `${PREFIX}-progressbarWrapper`,
  emailTemplateTooltip: `${PREFIX}-emailTemplateTooltip`,
  editorWrapper: `${PREFIX}-editorWrapper`,
};

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
  },
  [`& .${classes.purpleTitle}`]: {
    margin: '12px 0 12px',
    padding: '8px 0',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: theme.vars.palette.bigid?.primary500,
  },
  [`& .${classes.row}`]: {
    padding: '8px 0',
    width: '100%',
    maxWidth: 560,
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.textRowTooltip}`]: {
    position: 'absolute',
    top: -6,
    left: 44,
  },
  [`& .${classes.textRow}`]: {
    width: 260,
    position: 'relative',
    marginTop: 20,
  },
  [`& .${classes.progressbarWrapper}`]: {
    display: 'flex',
    margin: '15px 0 0 5px',
  },
  [`& .${classes.emailTemplateTooltip}`]: {
    cursor: 'pointer',
  },
}));
