import React, { useCallback, useEffect, useState } from 'react';
import { RequestManagerStateType } from '../../types';
import { getRequestManagerState } from '../../services/request';
import Dashboard from '../../pages/Dashboard';
import { Navigate } from 'react-router-dom';
import OnboardingWindowGuard from '../OnboardingWindowGuard';
import { usePageTitle } from '../../hooks/usePageTitle';
import { authService } from '../../services/authentication';

export const DashboardGuard = () => {
  const [state, setState] = useState<RequestManagerStateType>();

  usePageTitle('Dashboard');

  const fetchState = useCallback(async () => {
    const isAuthenticated = authService.isAuthenticated();

    if (isAuthenticated && !state) {
      const data = await getRequestManagerState();
      setState(data);
    }
  }, [state]);

  useEffect(() => {
    fetchState();
  }, [fetchState]);

  if (!state) {
    return <></>;
  }

  if (state.requestManagerEnabled) {
    return state.dashboardEnabled ? (
      <OnboardingWindowGuard>
        <Dashboard actions={state.actions} />
      </OnboardingWindowGuard>
    ) : (
      <Navigate to="/request-manager" />
    );
  }

  return <Navigate to="/cookie-consent" />;
};
