import { ReactElement } from 'react';

export type RegulationType =
  | ''
  | 'Non-regulated'
  | 'CPRA'
  | 'GDPR'
  | 'LGPD'
  | 'PIPEDA'
  | 'APPI'
  | 'CDPA'
  | 'CDR'
  | 'DPA'
  | 'DPA_PH'
  | 'DPA_RU'
  | 'DPL'
  | 'DPL_UA'
  | 'DPL_AE'
  | 'DPP'
  | 'LCS'
  | 'LCIS/LCS'
  | 'LFPDPPP'
  | 'PDPA'
  | 'PDPA_AR'
  | 'PDPA_MY'
  | 'PDPA_SG'
  | 'PDPA_TH'
  | 'PDPB'
  | 'PDPL'
  | 'PIPA'
  | 'PISS'
  | 'POPIA'
  | 'PPA'
  | 'RDPA'
  | 'VCDPA'
  | 'CPA'
  | 'CTDPA'
  | 'UCPA'
  | 'INCDPA'
  | 'ICDPA'
  | 'MCDPA'
  | 'TIPA'
  | 'TDPSA'
  | 'SB619';

export type RegulationLogosType = { [key in RegulationType]: { label: string; icon?: ReactElement } };
