import { useCallback, useEffect, useMemo, useState } from 'react';
import { UserGridPreferenceView } from '../../types';
import { createContainer } from 'unstated-next';
import { USER_REQUEST_GRID, useUserPreferences } from '../../hooks/useUserPreferences';
import { useSearchParams } from 'react-router-dom';
import { RequestManagerStateContainer } from './useRequestManagerState';

export const useRequestManagerViews = () => {
  const [views, setViews] = useState<UserGridPreferenceView[]>([]);
  const [currentView, setCurrentView] = useState<UserGridPreferenceView | undefined>();
  const [changedView, setChangedView] = useState<UserGridPreferenceView | undefined>();
  const [isToolbarBusy, setIsToolbarBusy] = useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const { requestManagerStateLoaded } = RequestManagerStateContainer.useContainer();

  const { sortColumns, userPreferences, userPreferencesLoading, updateUserPreferenceView, onDeleteUserPreferenceView } =
    useUserPreferences(USER_REQUEST_GRID, requestManagerStateLoaded);

  useEffect(() => {
    setViews(userPreferences.views.sort((o1, o2) => o1.ordinal - o2.ordinal));
  }, [userPreferences.views]);

  const onDeleteView = useCallback(
    async (id: number) => {
      const dataViews = await onDeleteUserPreferenceView(id);
      if (dataViews) {
        const view = dataViews.sort((o1, o2) => o1.ordinal - o2.ordinal)[0];

        setCurrentView(view);
        setChangedView(view);

        setSearchParams({
          view: String(view.id),
        });
      }
    },
    [onDeleteUserPreferenceView, setSearchParams],
  );

  const onChangeViewName = useCallback(
    async (id: number, name: string) => {
      const newView = views.find(view => view.id === id);

      if (newView) {
        await updateUserPreferenceView({ ...newView, label: name });
        setCurrentView({ ...newView, label: name });
        setChangedView({ ...newView, label: name });
      }
    },
    [updateUserPreferenceView, views],
  );

  const handleAddNewView = useCallback(
    async (name: string) => {
      if (changedView) {
        const newView = {
          ...changedView,
          filters: changedView.filters.filter(filter => filter.dateFrom || filter.options.length > 0),
          label: name,
          id: undefined,
          predefined: false,
        };

        const view = await updateUserPreferenceView(newView);

        if (view) {
          setCurrentView(view);
          setChangedView(view);
          setSearchParams({
            view: String(view.id),
          });
        }
      }
    },
    [changedView, updateUserPreferenceView, setSearchParams],
  );

  const handleUpdateCurrentView = useCallback(async () => {
    if (changedView) {
      const view = {
        ...changedView,
        filters: changedView.filters.filter(filter => filter.dateFrom || filter.options.length > 0),
      };
      await updateUserPreferenceView(view);
      setCurrentView(view);
    }
  }, [changedView, updateUserPreferenceView]);

  const allSearchParams = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);

  useEffect(() => {
    if (!currentView) {
      const viewFromParams = allSearchParams?.['view'];
      if (viewFromParams) {
        const view = views.find(view => String(view.id) === viewFromParams);
        setCurrentView(view);
        setChangedView(view);
      } else {
        setCurrentView(views[0]);
        setChangedView(views[0]);

        if (views[0]?.id) {
          setSearchParams({
            ...allSearchParams,
            view: String(views[0]?.id),
          });
        }
      }
    }
  }, [currentView, views, allSearchParams, searchParams, setSearchParams]);

  const onSelectView = useCallback(
    (id: number) => {
      setIsToolbarBusy(true);
      const view = views.find(item => item.id === id);
      setCurrentView(view);
      setChangedView(view);
      setSearchParams({
        view: String(id),
      });
    },
    [views, setSearchParams],
  );

  return {
    onDeleteView,
    onSelectView,
    onChangeViewName,
    onChangeChangedView: setChangedView,
    onChangeCurrentView: setCurrentView,
    onChangeViews: setViews,
    onChangeToolbarBusy: setIsToolbarBusy,
    handleAddNewView,
    handleUpdateCurrentView,
    handleResetToDefault: onDeleteView,
    currentView,
    changedView,
    views,
    sortColumns,
    viewLoading: userPreferencesLoading,
    isToolbarBusy,
  };
};

export const RequestManagerViewsContainer = createContainer(useRequestManagerViews);
