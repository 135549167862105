import React, { FC } from 'react';
import { BigidBody1, BigidDialog, PrimaryButton, TertiaryButton } from '@bigid-ui/components';

type DeletionNoteDialogPropsType = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const DeletionNoteDialog: FC<DeletionNoteDialogPropsType> = ({ isOpen, onClose, onSubmit }) => (
  <BigidDialog
    isOpen={isOpen}
    onClose={onClose}
    title="Delete note"
    maxWidth="xs"
    buttons={[
      {
        component: TertiaryButton,
        onClick: onClose,
        text: 'Cancel',
        alignment: 'right',
        size: 'large',
      },
      {
        component: PrimaryButton,
        onClick: onSubmit,
        text: 'Delete',
        alignment: 'right',
        size: 'large',
        dataAid: 'allow-deletion__apply',
      },
    ]}
  >
    <BigidBody1>You are about to delete your note.</BigidBody1>
    <BigidBody1>Are you sure you wish to proceed?</BigidBody1>
  </BigidDialog>
);
