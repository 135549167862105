import { BigidColorsV2 } from '@bigid-ui/components';

export const paperBoxShadow = '0 0 10px 0 rgba(103,102,102,0.11)';
export const primaryBorder = `1px solid ${BigidColorsV2.gray[300]}`;

export const accordionSimpleTableHeaders = {
  position: 'absolute',
  top: -26,
  fontSize: '0.75rem',
  fontWeight: 600,
  color: BigidColorsV2.gray,
  textAlign: 'start',
};

export const accordionSimpleTableHeadersLayout = {
  backgroundColor: BigidColorsV2.gray[150],
  width: '100%',
  content: '""',
  padding: 12,
  display: 'inline-block',
  borderRadius: 3,
};

export const fadeinFadeout = {
  '@keyframes fadein-fadeout': {
    '0%, 100%': { opacity: 0 },
    '20%': { opacity: 1 },
    '70%': { opacity: 1 },
  },
};
