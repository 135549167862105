import {
  RequestSubmitGeneralTextNameType,
  RequestSubmitPortalType,
  RequestSubmitTextNameType,
  RequestSubmitTextType,
} from '../../types';
import { clonePortalInPortals } from './requestSubmitPortalUtils';

export const getTextByNameAndForm = (
  name: RequestSubmitTextNameType | RequestSubmitGeneralTextNameType,
  formId: number,
  requestSubmitTexts: RequestSubmitTextType[],
) => {
  return requestSubmitTexts.find(text => text.name === name && text.requestSubmitFormId === formId);
};

export const getTextByNamesAndForm = (
  names: RequestSubmitTextNameType[],
  formId: number,
  requestSubmitTexts: RequestSubmitTextType[],
) => {
  return requestSubmitTexts.filter(text => names.indexOf(text.name) !== -1 && text.requestSubmitFormId === formId);
};

export const findTextPathInPortals = (portals: RequestSubmitPortalType[], textId: number) => {
  let portalIndex = -1;
  let formIndex = -1;
  let textIndex = -1;
  let portal = undefined;
  let form = undefined;
  let text = undefined;
  for (let pi = 0; pi < portals.length; pi++) {
    for (let fi = 0; fi < portals[pi].forms.length; fi++) {
      for (let ti = 0; ti < portals[pi].forms[fi].texts.length; ti++) {
        if (portals[pi].forms[fi].texts[ti].id === textId) {
          portalIndex = pi;
          formIndex = fi;
          textIndex = ti;
          portal = portals[portalIndex];
          form = portal.forms[formIndex];
          text = form.texts[textIndex];
          break;
        }
      }
    }
  }
  return { portalIndex, formIndex, form, textIndex, portal, text };
};

export const findTextInPortals = (portals: RequestSubmitPortalType[], textId: number) =>
  findTextPathInPortals(portals, textId).text;

export const updateRequestSubmitTextInPortals = (
  portals: RequestSubmitPortalType[],
  textId: number,
  data: Partial<RequestSubmitTextType>,
): RequestSubmitPortalType[] => {
  const { portal } = findTextPathInPortals(portals, textId);
  if (portal) {
    const updatedPortals = clonePortalInPortals(portals, portal.id);
    const updatedText = findTextInPortals(updatedPortals, textId);
    if (updatedText) {
      Object.assign(updatedText, data);
    }
    return updatedPortals;
  }
  return portals;
};

export const getAllTextsInPortals = (portals: RequestSubmitPortalType[]) =>
  portals.flatMap(portal => portal.forms.flatMap(form => form.texts));
