import {
  GridFetchResponseType,
  GridAssignUsersType,
  GridRowType,
  UserRequestAttributeInfoType,
  UserRequestPreferenceInfoType,
  GridRequestCollaboratorType,
  ExportRequestColumnsType,
  GridRequestsFilterType,
} from '../../types';
import { api } from './';
import { downloadFile, getCellSortMarker, toStringQuery } from '../../utils';
import set from 'date-fns/set';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { DateRangeDates } from '@bigid-ui/components/lib/BigidDateRangePicker/types';
import { BigidFilter } from '@bigid-ui/components';
import { BigidGridSorting } from '@bigid-ui/grid/lib/BigidGrid/types';
import { authService } from '../authentication';
import axios from 'axios';
import { isArray } from 'lodash';

const getMappedFilter = (filter: GridRequestsFilterType[]) =>
  filter?.map(f =>
    f.field === 'closed'
      ? {
          name: f.field,
          values: isArray(f.value) ? f.value.map(v => (v === 'Closed' ? 'true' : 'false')) : f.value,
        }
      : {
          name: f.field,
          values: f.value,
          dateFrom: f.dateFrom,
          dateTo: f.dateTo,
        },
  );

export const postAllRequestsSearch = async ({
  skip = 0,
  limit = 30,
  sort = [],
  filter = [],
  search,
}: Pick<BigidGridQueryComponents, 'sort' | 'skip' | 'limit'> & {
  search?: string;
  filter: GridRequestsFilterType[];
}): Promise<GridFetchResponseType> => {
  const mappedFilter = filter?.map(f =>
    f.field === 'closed'
      ? {
          name: f.field,
          values: isArray(f.value) ? f.value.map(v => (v === 'Closed' ? 'true' : 'false')) : f.value,
        }
      : {
          name: f.field,
          values: f.value,
          dateFrom: f.dateFrom,
          dateTo: f.dateTo,
        },
  );

  return await api
    .post<GridFetchResponseType>(
      `/requests/search`,
      {
        search,
        filters: mappedFilter,
      },
      {
        params: {
          skip,
          limit,
          sort: getCellSortMarker(sort),
        },
      },
    )
    .then(resolve => resolve.data);
};

export const postRequestsTotalCount = async ({
  filter = [],
  search,
}: {
  search?: string;
  filter: GridRequestsFilterType[];
}): Promise<Pick<GridFetchResponseType, 'totalCount'>> =>
  await api
    .post<Pick<GridFetchResponseType, 'totalCount'>>(`/requests/count`, {
      search,
      filters: getMappedFilter(filter),
    })
    .then(resolve => resolve.data);

export const postCollaboratorRequestManagerDataGrid = async ({
  skip = 0,
  limit = 30,
  sort = [],
  filter = [],
  search,
}: BigidGridQueryComponents & { search?: string }) =>
  await api
    .post<GridFetchResponseType>(
      `/requests/search`,
      {
        filters: [
          { name: 'closed', values: ['false'] },
          ...filter.map(f => ({
            name: f.field,
            values: f.value,
          })),
        ],
        search,
      },
      {
        params: {
          skip,
          limit,
          sort: getCellSortMarker(sort),
        },
      },
    )
    .then(resolve => resolve.data);

export const getAllUserRequestAttributesInfo = async (): Promise<UserRequestAttributeInfoType[]> =>
  await api.get<UserRequestAttributeInfoType[]>(`/requests/attributes-info`).then(resolve => resolve.data);

export const getAllUserPreferencesAttributesInfo = async (): Promise<UserRequestPreferenceInfoType[]> =>
  await api.get<UserRequestPreferenceInfoType[]>(`/requests/preferences-info`).then(resolve => resolve.data);

// ===============================GRID ACTIONS=======================================
export const getUserToAssign = async (): Promise<GridAssignUsersType> =>
  await api.get<GridAssignUsersType>(`/business-users`).then(resolve => resolve.data);

export const getBusinessUsersByTenant = async (): Promise<GridAssignUsersType> =>
  await api.get<GridAssignUsersType>(`/request-definitions/business-users`).then(resolve => resolve.data);

export const extendDueDate = async (
  dueDates: { requestId: string }[],
): Promise<{ requests: GridRowType[]; extendedBefore: string[]; nonExtensible: string[] }> =>
  await api
    .post(`/request-due-dates-extension`, {
      dueDates,
    })
    .then(resolve => resolve.data);

export const setRequestOwner = async (
  owners: { requestId: string; ownerId?: string; owner: string }[],
): Promise<GridRowType[]> =>
  await api
    .post<{ requests: GridRowType[] }>(`/request-owners`, {
      owners,
    })
    .then(resolve => resolve.data.requests);

export const setRequestCollaborators = async (
  collaborators: GridRequestCollaboratorType[],
  requestId: string,
): Promise<GridRowType> =>
  await api
    .put<GridRowType>(`/requests/${requestId}/collaborators`, {
      collaborators,
    })
    .then(resolve => resolve.data);

const getExportEndpointURL = (fileFormat: string, requestId?: string) => {
  return [
    process.env.REACT_APP_WORKFLOW_API_BASE_URL,
    '/',
    authService.getUser()?.tenant,
    `/requests/${requestId}/activity-logs`,
    '/',
    fileFormat,
  ].join('');
};

export const exportRequestLogs = ({
  sort = [],
  filter,
  search,
  requestId,
  selectedColumns,
}: {
  requestId?: string;
  selectedColumns?: ExportRequestColumnsType;
  rangeDates?: DateRangeDates;
  search?: string;
  filter?: BigidFilter;
  sort?: BigidGridSorting[];
}) => {
  const url = axios.getUri({
    url: getExportEndpointURL('csv', requestId),
    paramsSerializer: {
      encode: param => encodeURIComponent(param),
    },
    params: {
      sort: getCellSortMarker(sort),
      filter: `${toStringQuery(filter)}`,
      search,
      requestColumns: selectedColumns?.requestColumns.join(','),
      requestAttributeColumns: selectedColumns?.requestAttributeColumns.join(','),
      allPreferenceColumns: selectedColumns?.requestPreferenceColumns.length ? 'true' : 'false',
    },
  });

  return downloadFile(url, `exported-logs.csv`);
};

const getDownloadFile = (url: string, fileName: string, params: { skip: number; limit: number }) =>
  api
    .get(url, {
      responseType: 'blob',
      params,
    })
    .then(response => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

export const postExportRequestsCSV = async ({
  skip = 0,
  limit = 100000,
  sort = [],
  filter = [],
  fileFormat = 'csv',
  search,
  rangeDates,
  selectedColumns,
}: Pick<BigidGridQueryComponents, 'sort' | 'skip' | 'limit'> & {
  search?: string;
  fileFormat?: string;
  filter: GridRequestsFilterType[];
  rangeDates?: DateRangeDates;
  selectedColumns?: ExportRequestColumnsType;
  exportAll?: boolean;
}): Promise<void> => {
  const { id } = await api
    .post<{ id: number }>(
      '/requests/csv/filters',
      {
        search,
        filters: getMappedFilter(filter),
        requestColumns: selectedColumns?.requestColumns,
        requestAttributeColumns: selectedColumns?.requestAttributeColumns,
        rangeDatesFrom: rangeDates?.from && set(rangeDates?.from, { hours: 0, minutes: 0, seconds: 1 }),
        rangeDatesTo: rangeDates?.until && set(rangeDates?.until, { hours: 23, minutes: 59, seconds: 59 }),
        allPreferenceColumns: selectedColumns?.requestPreferenceColumns.length ? 'true' : 'false',
      },
      {
        params: {
          sort: getCellSortMarker(sort),
        },
      },
    )
    .then(resolve => resolve.data);

  return getDownloadFile(`/requests/csv/${id}`, `exported-requests.${fileFormat}`, { skip, limit });
};

export const postExportAllRequestsCSV = async ({
  skip = 0,
  limit = 100000,
  fileFormat = 'csv',
  rangeDates,
}: Pick<BigidGridQueryComponents, 'sort' | 'skip' | 'limit'> & {
  fileFormat?: string;
  rangeDates?: DateRangeDates;
}): Promise<void> => {
  const { id } = await api
    .post<{ id: number }>('/requests/csv/filters', {
      rangeDatesFrom: rangeDates?.from && set(rangeDates?.from, { hours: 0, minutes: 0, seconds: 1 }),
      rangeDatesTo: rangeDates?.until && set(rangeDates?.until, { hours: 23, minutes: 59, seconds: 59 }),
    })
    .then(resolve => resolve.data);

  return getDownloadFile(`/requests/all/csv/${id}`, `exported-requests.${fileFormat}`, {
    skip,
    limit,
  });
};
