import React, { FC, useState } from 'react';
import {
  BigidDateRangePicker,
  BigidDateRangePickerProps,
  BigidHeading4,
  BigidTooltip,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { BigidChevronDownIcon, BigidChevronUpIcon } from '@bigid-ui/icons';
import { Box, Divider, Stack } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { postExportAllRequestsCSV } from '../../services/request';
import { useToggle } from '../../hooks';

type StateType = Pick<BigidDateRangePickerProps, 'currentMode' | 'currentRangeOptionSelected' | 'dates'>;

const stateDefault: StateType = {
  currentRangeOptionSelected: 'none',
  currentMode: 'from',
  dates: {
    from: null,
    until: null,
  },
};

export const RequestsExportAll: FC = () => {
  const [state, setState] = useState<StateType>(stateDefault);
  const { value: popupIsOpened, setFalse: closePopup, toggle: togglePopup } = useToggle(false);
  const onClose = () => {
    closePopup();
    setState(stateDefault);
  };
  const handleExportRequests = async (fileFormat?: string) => {
    await postExportAllRequestsCSV({
      fileFormat,
      rangeDates: state.dates,
    });

    onClose();
  };

  return (
    <BigidTooltip
      open={popupIsOpened}
      disableHoverListener={false}
      fontSize="medium"
      placement="bottom-start"
      arrow={false}
      width="440px"
      title={
        <ClickAwayListener onClickAway={onClose}>
          <Box py={1}>
            <BigidHeading4 gutterBottom>Export All</BigidHeading4>
            <Divider sx={{ my: 2 }} />
            <BigidDateRangePicker
              {...state}
              noneLabel="All"
              onOptionChange={(dates, option) => {
                setState(prevState => ({ ...prevState, currentRangeOptionSelected: option, dates }));
              }}
              onDateModeChange={mode => {
                setState(prevState => ({ ...prevState, currentMode: mode }));
              }}
              onDateChange={date => {
                setState(prevState => ({
                  ...prevState,
                  currentMode: prevState.currentMode === 'from' ? 'until' : prevState.currentMode,
                  dates: {
                    ...prevState.dates,
                    [prevState.currentMode]: date,
                  },
                }));
              }}
            />
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" gap={1} justifyContent={'flex-end'}>
              <div>
                <TertiaryButton onClick={onClose} size="medium" text="Cancel" />
              </div>
              <div>
                <PrimaryButton
                  dataAid="export-all-button"
                  onClick={() => handleExportRequests('csv')}
                  size="medium"
                  text="Export"
                />
              </div>
            </Stack>
          </Box>
        </ClickAwayListener>
      }
    >
      <div>
        <SecondaryButton
          dataAid="open-export-all-button"
          endIcon={popupIsOpened ? <BigidChevronUpIcon /> : <BigidChevronDownIcon />}
          onClick={togglePopup}
          size="medium"
          text="Export All"
        />
      </div>
    </BigidTooltip>
  );
};
