import React, { ReactNode } from 'react';

export const StopPropagationContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      onClick={event => {
        event.stopPropagation();
      }}
    >
      {children}
    </div>
  );
};
