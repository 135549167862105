import { styled } from '@mui/material/styles';
import { paperBoxShadow } from '../../../assets/styles';

const PREFIX = 'WorkflowCustomization';

export const classes = {
  bigidGridRoot: `${PREFIX}-bigidGridRoot`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.bigidGridRoot}`]: {
    height: 'calc(100% - 90px)',
    display: 'flex',
    margin: '20px 0 0',
    background: theme.vars.palette.bigid?.white,
    borderRadius: 20,
    flexFlow: 'column nowrap',
    boxShadow: paperBoxShadow,
    '& > div': {
      borderRadius: 20,
    },
  },
}));
