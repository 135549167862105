import { PRICING_PLAN, PricingPlansResponseType, ADDITIONAL_PRICING_PLAN, PAYMENT_METHOD } from '../../../types';

export const initialPricingState = {
  plans: undefined,
  PR_plans: undefined,
  CC_plans: undefined,
  paymentMethod: PAYMENT_METHOD,
  isEnterprise: false,
  isTrial: false,
};

export interface PricingReducerStateType {
  plans?: Record<PRICING_PLAN, PricingPlansResponseType>;
  PR_plans?: Record<PRICING_PLAN, PricingPlansResponseType>;
  CC_plans?: Record<PRICING_PLAN, PricingPlansResponseType>;
  paymentMethod: PAYMENT_METHOD.NO_METHOD;
  isEnterprise: boolean | undefined;
  isTrial: boolean | undefined;
}

export type PricingReducerActionType = {
  type: PricingActionTypes;
  payload?: any; // FIXME: consider changing to Record<PRICING_PLAN, PricingPlansResponseType>
};

export enum PricingActionTypes {
  SET_PRICING_PLANS_DATA,
  SET_PRICING_PLANS_DATA_PORTAL,
  SET_PRICING_PLANS_DATA_COOKIE_CONCENT,
  SET_PAYMENT_METHOD,
}

export const pricingReducer = (state: PricingReducerStateType, action: PricingReducerActionType) => {
  const { type, payload } = action;
  switch (type) {
    case PricingActionTypes.SET_PRICING_PLANS_DATA: {
      return {
        ...state,
        plans: payload,
        isEnterprise:
          payload[ADDITIONAL_PRICING_PLAN.ENTERPRISE] && !!payload[ADDITIONAL_PRICING_PLAN.ENTERPRISE].subscription,
        isTrial: payload[PRICING_PLAN.TRIAL] && !!payload[PRICING_PLAN.TRIAL].subscription,
      };
    }
    case PricingActionTypes.SET_PRICING_PLANS_DATA_PORTAL: {
      return {
        ...state,
        PR_plans: payload,
      };
    }
    case PricingActionTypes.SET_PRICING_PLANS_DATA_COOKIE_CONCENT: {
      return {
        ...state,
        CC_plans: payload,
      };
    }
    case PricingActionTypes.SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: payload,
      };
    }
    default: {
      return state;
    }
  }
};
