import React, { FC } from 'react';
import { AppealGuidedTourStepTemplate } from './AppealGuidedTourStepTemplate';

interface WorkflowCustomizationGridStepProps {
  dataAid?: string;
}

export const WorkflowCustomizationGridStep: FC<WorkflowCustomizationGridStepProps> = ({ dataAid }) => {
  return (
    <AppealGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Add link to Appeal"
      firstSectionText="Select Request Type where you want to add a link to the Appeal form."
    />
  );
};
