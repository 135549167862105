import React, { FC, ComponentType } from 'react';
import { generateDataAid } from '@bigid-ui/utils';
import { IconComponentProps } from '@bigid-ui/icons';
import { AppealGuidedTourStepTemplateStyled } from './AppealGuidedTourStepTemplateStyles';

export interface AppealGuidedTourStepTemplateProps {
  dataAid?: string;
  title: string;
  icon?: ComponentType<IconComponentProps>;
  firstSectionText?: string;
  secondSectionText?: string;
  width?: number;
  height?: number;
  stepIndex: number;
}

export const AppealGuidedTourStepTemplate: FC<AppealGuidedTourStepTemplateProps> = ({
  dataAid = 'AppealGuidedTourStep',
  title,
  firstSectionText,
  icon: Icon,
  width,
  height,
  stepIndex,
}) => {
  const { Root, Text, StepBody, Spacer, Title } = AppealGuidedTourStepTemplateStyled;

  return (
    <Root stepIndex={stepIndex} data-aid={dataAid} width={width} height={height}>
      <Text>
        <Title icon={Icon} data-aid={generateDataAid(dataAid, ['title'])}>
          {title}
        </Title>
        <StepBody data-aid={generateDataAid(dataAid, ['body1'])}>{firstSectionText}</StepBody>
        <Spacer />
      </Text>
    </Root>
  );
};
