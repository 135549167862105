import { BigidColorsV2 } from '@bigid-ui/components';
import { styled } from '@mui/material/styles';

const PREFIX = 'ManualCloseResolutionTable';

export const classes = {
  tr: `${PREFIX}-tr`,
  dashCentering: `${PREFIX}-dashCentering`,
  activeTd: `${PREFIX}-activeTd`,
  triggerTd: `${PREFIX}-triggerTd`,
};

export const Root = styled('div')(() => ({
  [`& .${classes.tr}`]: {
    cursor: 'default',
  },
  [`& .${classes.tr}:hover td`]: {
    backgroundColor: BigidColorsV2.white,
  },
  [`& .${classes.dashCentering}`]: {
    paddingLeft: 33,
  },
  [`& .${classes.activeTd}`]: {
    textAlign: 'end',
    width: 50,
  },
  [`& .${classes.activeTd} span`]: {
    display: 'inline-flex',
  },
  [`& .${classes.triggerTd}`]: {
    width: 420,
  },
}));
