import { SystemDataSourceType } from './SettingsTypes';
import { RequestType } from './SingleRequestTypes';

export enum ConsentDataSourceScanStatus {
  NA = 'NA',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type ConsentAttributeType = {
  name: string;
  value: string;
};

export type DataSourceConsentType = {
  consentId: number;
  consentExternalId: number;
  name: string;
  state: ConsentStateType;
  mapped: boolean;
  primary: boolean;
  attributes: ConsentAttributeType[];
};

export type AutoConsentDataSourceType = {
  supported: boolean;
  connected: boolean;
  consentMetaScanStatus: ConsentDataSourceScanStatus;
  consentMetaScanDate?: string;
};

export type ConsentDataSourceType = SystemDataSourceType & {
  autoConsent: AutoConsentDataSourceType;
  consents: DataSourceConsentType[];
  actions: Record<string, boolean>;
  primary?: boolean;
};

export type ConsentsMappingType = {
  id: number;
  preference: {
    consentType: string;
    category: string;
  };
  request: {
    type: RequestType;
  };
  brandId: number;
  brandName: string;
  dataSources: ConsentDataSourceType[];
  consentMetaScanStatus?: ConsentDataSourceScanStatus;
  consentMetaScanDate?: string;
};

export type ConsentMetaScanCompletedType = {
  dataSourceId: number;
  consentMetaScanStatus: ConsentDataSourceScanStatus;
  consentMetaScanDate: string;
};

export type UserPreferenceUpdatesType = {
  userPreferenceIds: number[];
};

export enum ConsentStateType {
  CONNECTED = 'CONNECTED',
  NOT_FOUND = 'NOT_FOUND',
}
