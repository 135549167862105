import React, { FC } from 'react';
import { MePageHeader, RequestsExportAll } from '../../../components';
import { RequestManagerStateContainer } from '../useRequestManagerState';

export const RequestManagerHeader: FC = () => {
  const { requestManagerStateActions } = RequestManagerStateContainer.useContainer();

  return (
    <MePageHeader title="Requests" rightsideControls={requestManagerStateActions.EXPORT && <RequestsExportAll />} />
  );
};
