import React, { FC } from 'react';
import { AuthorizedAgentGuidedTourStepTemplate } from './AuthorizedAgentGuidedTourStepTemplate';

interface FromsTabStepProps {
  dataAid?: string;
}

export const FromsTabStep: FC<FromsTabStepProps> = ({ dataAid }) => {
  return (
    <AuthorizedAgentGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Select request types"
      firstSectionText="Go to Forms to select which request types to activate Authorize Agent for."
    />
  );
};
