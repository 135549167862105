import React from 'react';
import { BigidGridColumn, BigidGridColumnTypes } from '@bigid-ui/grid';
import { LanguageType } from '@consumer/state/languageContainer';
import { reduce } from 'lodash';
import {
  BusinessUserType,
  GridWorkflowCustomizationRowType,
  RequestWorkflowDefinitionDefaultCollaborators,
} from '../../../types';
import {
  businessUsersToBigidDropdownValue,
  collaboratorsToBigidDropdownValue,
  renderRequestType,
} from '../../../utils';
import { GetFormattedDateType } from '../../../types/CommonTypes';
import { BigidDropdown } from '@bigid-ui/components';
import { businessUsersToBigidDropdownOptions } from '../../../utils/businessUserUtils';
import { updateWorkflow } from '../../../services/settingsService';
import { StopPropagationContainer } from '../../../components/StopPropagationContainer';
import { BigidDropdownOption } from '@bigid-ui/components/lib/BigidDropdown/BigidDropdown';

export const workflowCustomizationColumnsAssigned = ({
  usersToAssign,
  onOwnerChange,
  getFormattedDate,
  onChangeCollaborators,
}: {
  usersToAssign: BusinessUserType[];
  onOwnerChange: (
    requestDefinitionId: number,
    value: BigidDropdownOption,
    defaultCollaborators: RequestWorkflowDefinitionDefaultCollaborators[],
  ) => void;
  getFormattedDate: GetFormattedDateType;
  onChangeCollaborators: (
    requestDefinitionId: number,
    values: BigidDropdownOption[],
    defaultOwnerUserFullName?: string,
    defaultOwnerUserId?: number,
  ) => void;
}) =>
  [
    {
      name: 'workflowName',
      title: 'Workflow Name',
      width: 300,
      getCellValue: ({ workflowName }: GridWorkflowCustomizationRowType) => workflowName,
      type: BigidGridColumnTypes.TEXT,
      disableTooltip: true,
    },
    {
      name: 'type',
      title: 'Request Type',
      width: 250,
      getCellValue: (row: GridWorkflowCustomizationRowType) => {
        return renderRequestType(row.type);
      },
      type: BigidGridColumnTypes.TEXT,
      disableTooltip: true,
    },
    {
      name: 'modificationDate',
      title: 'Date Modified',
      width: 300,
      getCellValue: ({ modificationDate }: GridWorkflowCustomizationRowType) =>
        modificationDate ? getFormattedDate(modificationDate, { hour: 'numeric', minute: 'numeric' }) : '-',
      type: BigidGridColumnTypes.CUSTOM,
      disableTooltip: true,
      sortingEnabled: false,
    },
    {
      name: 'defaultOwnerUserFullName',
      title: 'Default Owner',
      width: 300,
      getCellValue: row => (
        <BigidDropdown
          dataAid={`defaultOwnerUserFullName-${row.id.toString()}`}
          value={businessUsersToBigidDropdownValue(usersToAssign, [row.defaultOwnerUserFullName])}
          options={businessUsersToBigidDropdownOptions(usersToAssign)}
          onSelect={values => {
            onOwnerChange(row.id, values[0], row.defaultCollaborators);
          }}
          isSearchable
          isErasable
        />
      ),
      type: BigidGridColumnTypes.CUSTOM,
      disableTooltip: true,
    },
    {
      name: 'defaultCollaborators',
      title: 'Collaborators',
      width: 300,
      getCellValue: row => (
        <StopPropagationContainer>
          <BigidDropdown
            dataAid={`defaultCollaborators-${row.id.toString()}`}
            value={collaboratorsToBigidDropdownValue(row.defaultCollaborators)}
            options={businessUsersToBigidDropdownOptions(usersToAssign)}
            onSelect={values => {
              onChangeCollaborators(row.id, values, row.defaultOwnerUserFullName, row.defaultOwnerUserId);
            }}
            isMulti
            isErasable
          />
        </StopPropagationContainer>
      ),
      type: BigidGridColumnTypes.CUSTOM,
      disableTooltip: true,
    },
  ] as BigidGridColumn<GridWorkflowCustomizationRowType>[];

export const setRequiredDefaultsForValidation = (fieldName: string) =>
  reduce(
    LanguageType,
    (acc: any, cur: LanguageType) => ({
      ...acc,
      [cur]: {
        locale: cur,
        [fieldName]: 'required',
      },
    }),
    {},
  );

export const setRequiredDefaultsForValidationStageEmail = () =>
  reduce(
    LanguageType,
    (acc: any, cur: LanguageType) => ({
      ...acc,
      [cur]: {
        locale: cur,
        subject: 'required',
        title: 'required',
        header: 'required',
        message: '<p>Required</p>',
      },
    }),
    {},
  );

export const updateRequestWorkflowOwnerAndCollaborators = async ({
  requestDefinitionId,
  defaultOwnerUserFullName,
  defaultCollaborators,
  defaultOwnerUserId,
}: {
  requestDefinitionId: number;
  defaultOwnerUserFullName?: string;
  defaultOwnerUserId?: number;
  defaultCollaborators: RequestWorkflowDefinitionDefaultCollaborators[];
}) => {
  return await updateWorkflow(requestDefinitionId, {
    defaultOwnerUserFullName,
    defaultOwnerUserId,
    defaultCollaborators,
  });
};

export const getDefaultOwnerChangedSuccessText = (text?: string) => `Default Owner changed to ${text || 'no owner'}`;
export const getDefaultCollaboratorsChangedSuccessText = (text?: string) =>
  `Default Collaborators changed to ${text || 'no collaborators'}`;
