import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormikProps } from 'formik';
import { omit } from 'lodash';
import { Box, FormHelperText, useTheme } from '@mui/material';
import {
  BigidCheckbox,
  BigidDropdown,
  BigidFormFieldLabelWrapper,
  BigidPasswordField,
  BigidTextField,
} from '@bigid-ui/components';
import { debounce } from 'lodash';

import { getJiraProjects } from '../../../services/settingsService';
import { JiraConfigurationFormType } from '../../../types';

type JiraConfigurationFormPropsType = {
  formik: FormikProps<JiraConfigurationFormType>;
};

const protocolOptions = {
  https: { id: 'https', value: 'https', displayValue: 'https' },
  http: { id: 'http', value: 'http', displayValue: 'http' },
};

export const JiraConfigurationForm: FC<JiraConfigurationFormPropsType> = ({ formik }) => {
  const theme = useTheme();
  const [{ data, totalCount } = { data: [], totalCount: 0 }, setData] = useState({ data: [], totalCount: 0 });
  const { initialValues, values, errors, touched, setFieldValue, setFieldTouched, setFieldError } = formik;

  const handleProjectDetailsLoading: typeof getJiraProjects = async params => {
    setData(
      await getJiraProjects(params)
        .catch(() => {
          setFieldError('projectId', 'Fill the fields correctly in order to be able to select a project');
        })
        .then(response => {
          if (!response?.totalCount) {
            setFieldError('projectId', 'Fill the fields correctly in order to be able to select a project');
          }
          return response;
        }),
    );
  };

  const debouncedHandleProjectDetailsLoading = useMemo(() => debounce(handleProjectDetailsLoading, 500), []); // eslint-disable-line react-hooks/exhaustive-deps
  const projectsCanBeLoaded = !!(values.protocol && values.host && values.userName && values.password && values.port);

  useEffect(() => {
    if (values.protocol && values.host && values.userName && values.password && values.port) {
      debouncedHandleProjectDetailsLoading(omit(values, ['projectName']));
    }
  }, [values.protocol, values.host, values.userName, values.password, values.port, values.strictSsl]); // eslint-disable-line react-hooks/exhaustive-deps

  const jiraProjectOptions = totalCount
    ? data?.map((project: { id: number; name: string }) => ({
        id: project.id.toString(),
        value: project.id.toString(),
        displayValue: project.name,
      }))
    : initialValues.projectId
    ? [{ id: initialValues.projectId, value: initialValues.projectId, displayValue: initialValues.projectName }]
    : [];

  const selectedJiraProject = jiraProjectOptions?.find(({ value }) => +value === +values.projectId);

  const textFieldCommonProps = (fieldName: keyof JiraConfigurationFormType) => ({
    errorMessage: touched[fieldName] ? errors[fieldName] : '',
    value: values[fieldName] as string,
    onChange: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setFieldValue(fieldName, value.target.value),
    onBlur: () => setFieldTouched(fieldName, true),
  });

  return (
    <Box
      sx={{
        // @TODO: rewrite with BigidFormField set
        '.MuiFormControl-root label span': {
          color: theme.vars.palette.bigid?.primary400,
        },
      }}
    >
      <Box mb={2}>
        <BigidTextField
          {...textFieldCommonProps('name')}
          required
          label="Configuration Name"
          size="medium"
          type="text"
        />
      </Box>
      <Box mb={2}>
        <BigidFormFieldLabelWrapper id="protocol" label="Protocol" isRequired>
          <BigidDropdown
            onSelect={([{ value }]) => setFieldValue(`protocol`, value)}
            options={Object.values(protocolOptions)}
            value={[protocolOptions[values.protocol]]}
            placeholder="Select protocol"
            dataAid="configuration_protocol_field"
          />
        </BigidFormFieldLabelWrapper>
      </Box>
      <Box mb={2}>
        <BigidTextField {...textFieldCommonProps('host')} required label="Host" size="medium" type="text" />
      </Box>
      <Box mb={2}>
        <BigidTextField {...textFieldCommonProps('port')} required label="Port" size="medium" type="text" />
      </Box>
      <Box mb={2}>
        <BigidTextField {...textFieldCommonProps('userName')} required label="Username" size="medium" type="text" />
      </Box>
      <Box mb={2}>
        <BigidPasswordField touched required {...textFieldCommonProps('password')} label="Password" size="medium" />
      </Box>
      <Box mb={2}>
        <BigidCheckbox
          checked={values.strictSsl}
          label="Strict SSL"
          dataAid="configuration_strictSsl_switch"
          onChange={(event, checked) => setFieldValue('strictSsl', checked)}
        />
      </Box>
      <BigidFormFieldLabelWrapper
        id="project"
        label="Project Name"
        isRequired
        error={errors.projectId}
        errorIsShown={projectsCanBeLoaded && !!errors.projectId && !!totalCount}
      >
        <BigidDropdown
          isSearchable
          onSelect={([{ value, displayValue }]) => {
            setFieldValue(`projectName`, displayValue);
            setFieldValue(`projectId`, value);
          }}
          options={jiraProjectOptions}
          value={selectedJiraProject ? [selectedJiraProject] : undefined}
          placeholder="Select Project Name"
          dataAid="configuration_project_name_field"
          isDisabled={!jiraProjectOptions?.length}
        />
        {!totalCount && (
          <FormHelperText sx={{ mx: 0 }} error={!!errors.projectId && projectsCanBeLoaded && !totalCount}>
            {'Fill the fields correctly in order to be able to select a project'}
          </FormHelperText>
        )}
      </BigidFormFieldLabelWrapper>
    </Box>
  );
};
