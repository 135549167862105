import React from 'react';
import cx from 'classnames';

import { ConsentDataSourceScanStatus } from '../../types/ConsentTypes';
import { SystemLabel } from '../SystemLabel';

import { useStyles } from '../../pages/ConsentsMapping/ConsentsMappingStyles';

type SystemBadgePropsType = {
  name: string;
  type: string;
  lastScanStatus?: ConsentDataSourceScanStatus;
  className?: string;
};

export const ConsentSystemBadge = ({ name, type, lastScanStatus, className }: SystemBadgePropsType) => {
  const classes = useStyles();

  return (
    <SystemLabel
      name={name}
      type={type}
      className={cx(classes.systemBadge, className, {
        [classes.systemBadgeWithError]: lastScanStatus === ConsentDataSourceScanStatus.FAILED,
      })}
      iconClassName={classes.systemBadgeIcon}
      labelClassName={classes.systemBadgeLabel}
    />
  );
};
