import React, {
  useEffect,
  MouseEvent,
  FC,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
  useMemo,
  ChangeEvent,
  RefObject,
} from 'react';
import { BigidExternalLinkIcon } from '@bigid-ui/icons';
import { FormHelperText, Input, IconButton } from '@mui/material';
import cx from 'classnames';

import { BigidMeTooltipWithIcon } from '../BigidMeTooltipWithIcon';

import { useStyles } from './MeTextFieldStyles';
import { MeCopy } from '../MeCopy';
import { Box } from '@mui/system';

const TOOLTIP_TIMEOUT = 1000;

export type MeTextFieldPropsType = {
  displayAsRequired?: boolean;
  name?: string;
  type?: 'text' | 'password' | 'link' | 'email';
  isSquare?: boolean;
  disabled?: boolean;
  value?: string;
  label?: string;
  error?: string;
  tip?: string;
  rightIcon?: ReactElement;
  leftIcon?: ReactElement;
  isShowHide?: boolean;
  clearable?: boolean;
  isCopy?: boolean;
  isLink?: boolean;
  placeholder?: string;
  width?: string | number;
  margin?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onKeyDown?: (e: SyntheticEvent) => void;
  onClick?: (e: MouseEvent) => void;
  onLinkClick?: () => void;
  onTouchStart?: () => void;
  RightSideForeignComponent?: FC<{ value?: string; error?: boolean }>;
  testId?: string;
  dataAttr?: Record<string, string>;
  onRef?: (ref: HTMLInputElement | null) => void;
  className?: string;
  inputRef?: RefObject<HTMLTextAreaElement>;
  multiline?: boolean;
  readOnly?: boolean;
};

export const MeTextField: FC<MeTextFieldPropsType> = ({
  displayAsRequired = false,
  type = 'text',
  disabled = false,
  isSquare = false,
  value = '',
  label = '',
  error,
  name,
  tip,
  isShowHide,
  clearable,
  isCopy,
  isLink,
  rightIcon,
  leftIcon,
  placeholder,
  width = '100%',
  margin,
  onClick,
  onChange,
  onKeyDown,
  onBlur,
  onLinkClick,
  onTouchStart,
  RightSideForeignComponent,
  testId,
  dataAttr = {},
  onRef,
  className,
  inputRef,
  multiline,
  readOnly,
}) => {
  const classes = useStyles();
  const [unmasked, setUnmasked] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInternalValue(value);

    onChange && onChange(event);
  };

  const handleClear = () => {
    setInternalValue('');
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleMaskClick = useCallback(() => {
    setUnmasked(!unmasked);
  }, [unmasked]);

  const handleRef = (ref: HTMLInputElement) => {
    onRef && onRef(ref);
  };

  const handleLinkClick = useCallback(() => {
    onLinkClick && onLinkClick();
    window.open(value, '_blank');
  }, [value, onLinkClick]);

  useEffect(() => {
    let timeoutId: number;

    if (isTooltipOpen) {
      timeoutId = window.setTimeout(() => setTooltipOpen(false), TOOLTIP_TIMEOUT);
    }

    return () => clearTimeout(timeoutId);
  }, [isTooltipOpen]);

  const inputRightPaddingClass = useMemo(() => {
    if (!!rightIcon || isCopy) {
      return isShowHide ? classes.showHideWithIconPadding : classes.rightIconPadding;
    }

    return isShowHide && classes.showHidePadding;
  }, [classes, isCopy, isShowHide, rightIcon]);

  return (
    <div className={cx(classes.wrapper, className)} style={{ width, margin }}>
      {label && <span className={cx(classes.label, { [classes.isSquareLabel]: isSquare })}>{label}</span>}
      {displayAsRequired && <span className={classes.required}>&#42;</span>}
      {tip && (
        <Box mr="auto" ml="5px" display="inline-block">
          <BigidMeTooltipWithIcon title={tip} />
        </Box>
      )}

      <div className={cx(classes.inputWrapper, 'wideInput')}>
        <Input
          inputRef={inputRef ? inputRef : handleRef}
          name={name}
          type={unmasked && type === 'password' ? 'text' : type}
          error={!!error}
          disabled={disabled}
          fullWidth={true}
          value={internalValue}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          onFocus={onTouchStart}
          classes={{
            root: cx(classes.inputRoot, inputRightPaddingClass, {
              [classes.isSquare]: isSquare,
              [classes.leftIconPadding]: !!leftIcon,
            }),
            error: classes.inputError,
          }}
          inputProps={{ ...(testId ? { 'data-aid': `${testId}_input` } : {}), ...dataAttr }}
          placeholder={placeholder}
          onClick={onClick}
          onBlur={onBlur}
          data-aid={testId}
          multiline={multiline}
          readOnly={readOnly}
        />
        {leftIcon && <div className={classes.leftIcon}>{leftIcon}</div>}
        {RightSideForeignComponent && (
          <div className={classes.rightElement}>
            <RightSideForeignComponent value={value} error={!!error} />
          </div>
        )}
        <div>
          <div className={classes.rightsideElements}>
            {isShowHide && (
              <div className={cx(classes.action, classes.maskAction)} onClick={handleMaskClick}>
                {unmasked ? 'Hide' : 'Show'}
              </div>
            )}
            {clearable && (
              <div className={cx(classes.action, classes.maskAction)} onClick={handleClear}>
                Clear
              </div>
            )}
            {isCopy && <MeCopy textToCopy={internalValue}></MeCopy>}
            {isLink && (
              <IconButton className={classes.action} onClick={handleLinkClick} size="large">
                <BigidExternalLinkIcon />
              </IconButton>
            )}
            {rightIcon && (
              <div className={classes.rightIcon} onClick={(e: MouseEvent) => e.stopPropagation()}>
                {rightIcon}
              </div>
            )}
          </div>
        </div>
        {!!error && (
          <FormHelperText error classes={{ root: `${classes.error}` }} data-aid={testId && `${testId}-error`}>
            {error}
          </FormHelperText>
        )}
      </div>
    </div>
  );
};
