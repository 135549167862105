import React from 'react';
import { useStyles } from './MeProgressBarStyles';

interface MeProgressBarType {
  width?: number | string;
  progress?: number | string;
}

export const MeProgressBar = ({ width = '100%', progress = '0%' }: MeProgressBarType) => {
  const classes = useStyles({ width, progress });

  return <span className={classes.root}></span>;
};
