import React, { FC } from 'react';
import { get } from 'lodash';
import { FormikErrors, FormikProps } from 'formik';

import { LanguageType } from '@consumer/state/languageContainer';
import { BigidFormFieldLabelWrapper, BigidTextField } from '@bigid-ui/components';

import { OnScreenMessageType } from '../../../types';
import { classes, Root } from './OnScreenMessageStyles';
import { Loader, RichTextEditor } from '../../../components';
import { FormikAllowOutsideRequestorsPropsType } from '../hooks/useAllowOutsideRequestors';

type OnScreenMessagePropsType = {
  selectedLanguage: LanguageType;
  onScreenMessage: OnScreenMessageType;
  onScreenMessageErrors?: FormikErrors<OnScreenMessageType>;
  setFieldValue: FormikProps<OnScreenMessageType | FormikAllowOutsideRequestorsPropsType>['setFieldValue'];
};

export const OnScreenMessage: FC<OnScreenMessagePropsType> = ({
  selectedLanguage,
  onScreenMessage,
  onScreenMessageErrors,
  setFieldValue,
}) => {
  if (!onScreenMessage) {
    return <Loader />;
  }

  return (
    <Root className={classes.root}>
      <div className={classes.row}>
        <BigidTextField
          required
          errorMessage={get(onScreenMessageErrors, `translations[${selectedLanguage}].title`)}
          value={onScreenMessage.translations[selectedLanguage]!.title}
          label="Title"
          dataAid="message_field"
          onChange={value =>
            setFieldValue(`onScreenMessage.translations[${selectedLanguage}].title`, value.target.value)
          }
          size="medium"
          type="text"
        />
      </div>
      <div className={classes.editor}>
        <BigidFormFieldLabelWrapper id="message-body_input" name="messageBody" label="Body Text">
          <RichTextEditor
            dataAid="message-body_input"
            onBlur={html => setFieldValue(`onScreenMessage.translations[${selectedLanguage}].message`, html)}
            value={onScreenMessage.translations[selectedLanguage]!.message}
          />
        </BigidFormFieldLabelWrapper>
      </div>
    </Root>
  );
};
