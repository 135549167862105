import { styled } from '@mui/material/styles';

const PREFIX = 'WorkflowSettings';

export const classes = {
  relative: `${PREFIX}-relative`,
  flex: `${PREFIX}-flex`,
  header: `${PREFIX}-header`,
  layout: `${PREFIX}-layout`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  selectRow: `${PREFIX}-selectRow`,
  selectOwnerRow: `${PREFIX}-selectOwnerRow`,
  row: `${PREFIX}-row`,
  label: `${PREFIX}-label`,
  controls: `${PREFIX}-controls`,
  backButton: `${PREFIX}-backButton`,
  accordionHeading: `${PREFIX}-accordionHeading`,
  accordionBody: `${PREFIX}-accordionBody`,
  collaboratorsDropdown: `${PREFIX}-collaboratorsDropdown`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.relative}`]: {
    position: 'relative',
  },
  [`& .${classes.flex}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.header}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`& .${classes.layout}`]: {
    padding: 20,
  },
  [`& .${classes.title}`]: {
    display: 'inline-block',
    padding: '0 15px',
    verticalAlign: 'super',
    fontSize: '1.375rem',
    fontWeight: 700,
  },
  [`& .${classes.subtitle}`]: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  [`& .${classes.row}`]: {
    padding: '8px 0',
    width: 560,
    display: 'flex',
    alignItems: 'center',

    '& label': {
      marginLeft: -7,
    },
  },
  [`& .${classes.selectRow}`]: {
    width: 80,
    marginLeft: 12,
  },
  [`& .${classes.selectOwnerRow}`]: {
    width: 376,
  },
  [`& .${classes.label}`]: {
    width: 160,
    marginRight: 24,
  },
  [`& .${classes.backButton}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.accordionHeading}`]: {
    paddingTop: 16,
    paddingBottom: 16,
    '& .MuiIconButton-root': {
      marginLeft: '7px',
    },
  },
  [`& .${classes.accordionBody}`]: {
    background: theme.vars.palette.bigid?.white,
    '& .MuiIconButton-root': {
      marginLeft: '7px',
    },
  },
  [`& .${classes.collaboratorsDropdown}`]: {
    '& > div > div > div': {
      minHeight: 32,
    },
  },
}));
