import { createContainer } from 'unstated-next';
import { fetchAppSettings } from '../services/appService';
import { useFetch } from '../hooks/useFetch';

const useAppSettings = () => {
  const settings = useFetch({
    fetchApi: fetchAppSettings,
    dependencyParams: [],
    initValue: { gpcEnabled: false },
  });

  return { settings: settings.data };
};

export const AppSettingsContainer = createContainer(useAppSettings);
