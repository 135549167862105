import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface TabsPanelPropsType {
  children: ReactNode;
  value: number;
  index: number;
  isVertical?: boolean;
  height?: string;
}

export const TabsPanel = ({ children, value, index, isVertical, height }: TabsPanelPropsType) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={isVertical ? `vertical-tabpanel-${index}` : ''}
    aria-labelledby={isVertical ? `vertical-tab-${index}` : ''}
    style={{ height }}
  >
    {value === index && (
      <Box display="contents" height="-webkit-fill-available" p={0}>
        {children}
      </Box>
    )}
  </div>
);
