import makeStyles from '@mui/styles/makeStyles';
import { primaryBorder } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  popoverContainer: {
    display: 'none',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
  },
  visible: {
    display: 'block',
  },
  overlay: {
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.25)',
  },
  widgetButtonWrapper: {
    position: 'fixed',
    bottom: 10,
    right: 32,
    paddingTop: 12,
    zIndex: 1,
  },
  circleIcon: {
    position: 'relative',
    width: 18,
    height: 18,
    marginRight: 10,
    borderRadius: '50%',
    backgroundColor: theme.vars.palette.bigid?.primary400,
    backgroundImage: `linear-gradient(90deg, transparent 50%, ${theme.vars.palette.bigid?.gray500} 50%), linear-gradient(90deg, ${theme.vars.palette.bigid?.gray500} 50%, transparent 50%)`,

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 1,
      left: 1,
      width: 16,
      height: 16,
      borderRadius: '50%',
      backgroundColor: theme.vars.palette.bigid?.gray700,
    },
  },
  popover: {
    position: 'fixed',
    width: 204,
    right: 32,
    bottom: 60,
    borderRadius: 12,
    background: theme.vars.palette.bigid?.white,
    boxShadow: '0 8px 26px 0 rgba(89,75,86,0.24)',
  },
  popoverOverlay: {
    zIndex: 1,
    background: 'rgba(0, 0, 0, 0.5)',
  },
  popoverHeader: {
    padding: `10px 2px 16px 16px`,
    borderBottom: `1px solid ${theme.vars.palette.bigid?.gray200}`,
  },
  checklistTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 600,
  },
  contentTitle: {
    fontSize: 15,
    fontWeight: 800,
  },
  closeButton: {
    padding: 10,
  },
  confirmPoverText: {
    marginBottom: 12,
    fontSize: '0.8125rem',
  },
  confirmButton: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  closeIcon: {
    width: 14,
    height: 14,
  },
  progessBar: {
    position: 'relative',
    height: 4,
    borderRadius: 2,
    background: theme.vars.palette.bigid?.gray150,
    marginRight: 14,
  },
  progessValue: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    borderRadius: 2,
    background: theme.vars.palette.bigid?.primary500,
  },
  popoverContent: {
    padding: '16px 16px 10px 16px',
  },
  confirmPopoverContent: {
    padding: '16px 33px 20px 33px',
    textAlign: 'center',
  },
  tutorialStep: {
    display: 'flex',
    marginBottom: 12,
    fontSize: 13,
    textDecoration: 'none',
    color: theme.vars.palette.bigid?.gray700,

    '&:hover, &.active': {
      fontWeight: 600,

      '& $notPassedStep': {
        borderColor: theme.vars.palette.bigid?.primary900,
      },
    },
  },
  tutorialStepIcon: {
    width: 20,
    height: 20,
  },
  tutorialStepText: {
    marginLeft: 12,
  },
  notPassedStep: {
    borderRadius: '50%',
    border: primaryBorder,
  },
  tutorialTooltip: {
    maxWidth: 240,
  },
}));
