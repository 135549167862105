import React from 'react';
import { WorkflowCustomizationContainer } from '../hooks/useWorkflowCustomization';
import { RequestCustomizationContainer } from '../hooks/useRequestCustomization';
import { StageEmailCustomizationContainer } from '../hooks/useStageEmailCustomization';
import { WebhookCustomizationContainer } from '../hooks/useWebhookCustomization';
import RequestCustomization from '../RequestCustomization';

export const RequestWorkflowContainerComponent = () => (
  <WorkflowCustomizationContainer.Provider>
    <RequestCustomizationContainer.Provider>
      <WebhookCustomizationContainer.Provider>
        <StageEmailCustomizationContainer.Provider>
          <RequestCustomization />
        </StageEmailCustomizationContainer.Provider>
      </WebhookCustomizationContainer.Provider>
    </RequestCustomizationContainer.Provider>
  </WorkflowCustomizationContainer.Provider>
);
