import React, { useState, useEffect, useCallback } from 'react';
import { options as AMChartsCoreOptions } from '@amcharts/amcharts4/core';
import { MinimizerButtonPositions, BarChartModes } from '@bigid-ui/components';
import { isEqual } from 'lodash';
import { RequestsByStatusType, DataRequestsByTypeType, DataRequestsByStatusType } from '../../types';
import { ChartClickEvent, MinimizeableStatistics } from '../../components/MinimizeableStatistics';
import { getRequestsByStatus, getRequestsTimeByType } from '../../services/request';
import { BigidMeChartsColors, SingleColor } from '../../assets/styles';
import { barchartTitle } from '../Dashboard/utils';

import { useStyles } from './DonutDashboardStyles';
import { useNavigate } from 'react-router-dom';

export const DonutDashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [requestsByStatus, setRequestsByStatus] = useState<RequestsByStatusType>({ total: 0, data: [] });
  const [requestsByTypes, setRequestsByTypes] = useState<DataRequestsByTypeType[]>();
  const [activeFilter, setActiveFilter] = useState<ChartClickEvent>({});

  useEffect(() => {
    AMChartsCoreOptions.onlyShowOnViewport = false;
    AMChartsCoreOptions.queue = true;
    fetchRequestsByStatus();
    fetchRequestsTimeByType();
  }, []);

  const fetchRequestsByStatus = async () => {
    const data = await getRequestsByStatus();
    const dataWithColors = {
      ...data,
      data: data.data.map((item: DataRequestsByStatusType) => ({
        ...item,
        color: BigidMeChartsColors[item.type as keyof typeof BigidMeChartsColors],
      })),
    };
    setRequestsByStatus(dataWithColors);
  };

  const fetchRequestsTimeByType = async () => {
    const data = await getRequestsTimeByType();
    setRequestsByTypes(data);
  };

  const onChartClick = useCallback(
    (status: string) => {
      navigate('/request-manager');
      setActiveFilter(activeFilter => (isEqual(activeFilter, { status }) ? {} : { status }));
    },
    [navigate],
  );

  return (
    <div className={classes.marginWrapper}>
      <MinimizeableStatistics
        title="Open Requests Status"
        requestsByStatus={requestsByStatus}
        requestsByTypes={requestsByTypes}
        status={activeFilter.status}
        barchartTitle={barchartTitle}
        barchartMode={BarChartModes.SIMPLE}
        barchartWithLegend={false}
        barchartColors={SingleColor}
        onDoughnutClick={onChartClick}
        disableBarchartTooltip={true}
        buttonPosition={MinimizerButtonPositions.NONE}
      />
    </div>
  );
};
