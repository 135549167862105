import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BigidArrowLeftIcon } from '@bigid-ui/icons';
import { filter } from 'lodash';
import {
  OnboardingGetDataType,
  OnboardingRegulation,
  OnboardingStateType,
  OnboardingStepsType,
  StepperStates,
} from '../../../types';
import {
  defaultOnboardingValues,
  getOnboardingTitle,
  OnboardingHiddenStepperSteps,
  onboardingStepContent,
  onboardingSteps,
} from '../onboardingUtils';
import { MeStepper, STEP_COMPLETED } from '../../../components/MeStepper';
import { getPopularRegulations, updateOnboardingState } from '../../../services/onboardingService';
import {
  getCountries,
  getUseCasesData,
  putOnboardingRegions,
  patchRegulations,
  putUseCases,
  getRegulations,
  getOnboardingRegions,
} from '../../../services/settingsService';
import { useStyles } from './OnboardingWindowStyles';
import { defaultProgressStepper, onBack, onNext, onSkip, uncheckData } from './utils';
import { mergeBIntoAByP } from '../../../utils';
import { ConnectorMode } from '../../../components/MeConnectorTile';
import { WelcomePopup } from '../WelcomePopup';
import { OnboardingFinished } from '../OnboardingFinished';
import { trackOnboardingEvent } from '../../../services/eventsTrackingService';
import { TrackingEvents } from '../../../types/TrackingEventTypes';
import { STEP_HALFCOMPLETED, STEP_VISITED } from '../../../components/MeStepper/MeStepper';
import { BrandsContainer } from '../../../state/brandsContainer';
import {
  getPopularDataproviderTypes,
  getDataproviderTypes,
  loadAvailableDataSources,
} from '../../../services/systemsService';
import { getDefaultBrand } from '../../../utils/brandUtils';
import { PrimaryButton, TertiaryButton } from '@bigid-ui/components';

export interface OnboardingWindowPropsType {
  onComplete: () => void;
  onboardingState: OnboardingStateType;
}

interface OnboardingWindowStateType {
  activeStep: number;
  stepsProgress: number[];
}

export const OnboardingWindow: FC<OnboardingWindowPropsType> = ({ onComplete, onboardingState }) => {
  const [hiddenSkip, setHiddenSkip] = useState<boolean>(false);
  const [data, setData] = useState<OnboardingGetDataType>(defaultOnboardingValues);
  const { brands } = BrandsContainer.useContainer();
  const classes = useStyles();
  const [state, setState] = useState<OnboardingWindowStateType>({
    stepsProgress: defaultProgressStepper,
    activeStep: 0,
  });

  const defaultBrandId = getDefaultBrand(brands.brands)?.id;

  const fetchRegions = async () => {
    const { regions, headquarters } = await getOnboardingRegions();
    const countries = await getCountries();

    setData(prev => ({
      ...prev,
      regions: {
        headquarters: headquarters || '',
        regionsOptions: regions,
        headquartersOptions: countries,
      },
    }));
  };

  const fetchRegulations = async () => {
    const regulations = await getRegulations();
    const popular = await getPopularRegulations();
    const filteredRegulations = filter(regulations, ({ shortName }) => shortName !== 'Non-regulated');

    setData(prev => ({
      ...prev,
      regulations: { all: filteredRegulations, ...popular },
    }));
  };

  const fetchUseCases = async () => {
    if (defaultBrandId) {
      const useCases = await getUseCasesData(defaultBrandId, true);
      setData(prev => ({ ...prev, useCases }));
    }
  };

  const fetchConnectors = async () => {
    const datasources = await getDataproviderTypes('');
    const popularDatasources = await getPopularDataproviderTypes();
    const connections = await loadAvailableDataSources();
    // if already have one for TRIAL connections connected
    if (connections.length === 1) {
      const dataSourceTypeConnectionId = connections[0].dataSourceType.id;
      const connectionStatus = connections[0].connectionStatus;
      const connectionId = connections[0].id;
      const popular = popularDatasources.map(sys =>
        sys.id === dataSourceTypeConnectionId ? { ...sys, connectionStatus, connectionId } : sys,
      );
      const all = datasources.map(sys =>
        sys.id === dataSourceTypeConnectionId ? { ...sys, connectionStatus, connectionId } : sys,
      );
      setData(prev => ({ ...prev, sourceSystems: { all, popular } }));
    } else {
      setData(prev => ({ ...prev, sourceSystems: { all: datasources, popular: popularDatasources } }));
    }
  };

  const handleStepClick = (step: number) => {
    setState(state => ({ ...state, activeStep: step }));
  };

  useEffect(() => {
    fetchRegions();
    fetchUseCases();
    fetchRegulations();
    fetchConnectors();

    setState(getState(onboardingState));
  }, [onboardingState]); //eslint-disable-line react-hooks/exhaustive-deps

  const getState = (onboardingState: OnboardingStateType): OnboardingWindowStateType => ({
    activeStep: onboardingSteps.indexOf(onboardingState.currentStep),
    stepsProgress: getStepsProgress(onboardingState.currentStep),
  });

  const getStepsProgress = (currentStep: OnboardingStepsType): number[] => {
    switch (currentStep) {
      case OnboardingStepsType.REGIONS: {
        return defaultProgressStepper;
      }
      case OnboardingStepsType.REGULATIONS: {
        return [0, STEP_HALFCOMPLETED, 0, 0, 0, 0, 0];
      }
      case OnboardingStepsType.PRIVACY_CENTER_STEP: {
        return [0, STEP_COMPLETED, 0, STEP_VISITED, 0, 0, 0];
      }
      case OnboardingStepsType.REQUEST_FORM: {
        return [0, STEP_COMPLETED, 0, STEP_HALFCOMPLETED, 0, 0, 0];
      }
      case OnboardingStepsType.CONNECT_DATA: {
        return [0, STEP_COMPLETED, 0, STEP_COMPLETED, 0, STEP_HALFCOMPLETED];
      }
      default:
        return defaultProgressStepper;
    }
  };

  const goTo = useCallback((step: number, activeStep: number) => {
    const defineNextActiveStep = onboardingSteps.findIndex((_element, index) => index === activeStep) + step;
    setState(state => ({ ...state, activeStep: defineNextActiveStep }));
  }, []);

  const handleDataChange = (
    name: keyof OnboardingGetDataType,
    value: OnboardingGetDataType[keyof OnboardingGetDataType] | ConnectorMode,
  ) => {
    if (name === 'sourceSystems') {
      fetchConnectors();
      if (value === ConnectorMode.CONNECTED) {
        // connected systems
        handleStepVisit(StepperStates.CONNECT_DATA, STEP_COMPLETED);
        setHiddenSkip(true);
      } else {
        setHiddenSkip(false);
      }
      return;
    }
    setData(prev => ({ ...prev, [name]: value }));
  };

  const updateRegions = async () => {
    await putOnboardingRegions({
      headquarters: data.regions.headquarters,
      regions: data.regions.regionsOptions,
    });
    const popular = await getPopularRegulations();
    let all: OnboardingRegulation[];
    const allEmpty = uncheckData<OnboardingRegulation>(data.regulations.all);
    if (popular.preselected.length === 0) {
      all = mergeBIntoAByP<OnboardingRegulation>(allEmpty, popular.popular, 'id');
    } else {
      all = mergeBIntoAByP<OnboardingRegulation>(allEmpty, popular.preselected, 'id');
    }
    setData(prev => ({ ...prev, regulations: { ...popular, all } }));
  };

  const updateEmptyRegions = async () => {
    const { regions, regulations } = data;
    await putOnboardingRegions({
      headquarters: '',
      regions: uncheckData(regions.regionsOptions),
    });
    const popular = await getPopularRegulations();
    const all = uncheckData(regulations.all);
    setData(prev => ({ ...prev, regulations: { ...popular, all } }));
  };

  const updateRegulations = async () => {
    await patchRegulations(data.regulations.all);
  };

  const updateUseCases = async () => {
    if (defaultBrandId) {
      await putUseCases(defaultBrandId, data.useCases);
    }
  };

  const saveCurrentStep = async (nextOnboardingStep: OnboardingStepsType) => {
    const onboardingState = await updateOnboardingState(nextOnboardingStep);
    setState(getState(onboardingState));
  };

  const isStepValid = useMemo(() => {
    const { regionsOptions, headquarters } = data.regions;

    const is1StepValid = regionsOptions.some(({ enabled }) => enabled) || headquarters !== '';
    const is2StepValid = data.regulations.all.some(({ enabled }) => enabled);
    const is3StepValid = data.useCases.some(({ enabled }) => enabled);
    const stepCompleted = [true, is1StepValid, is2StepValid, is3StepValid, true, true, true];
    return stepCompleted[state.activeStep];
  }, [data, state]);

  const handleStepVisit = (step: number, progress: number) => {
    setState(({ activeStep, stepsProgress }) => ({
      activeStep,
      stepsProgress: {
        ...stepsProgress,
        // complete steps doesn't fallback
        [step]: stepsProgress[step] === STEP_COMPLETED ? STEP_COMPLETED : progress,
      },
    }));
  };

  const handleDoneOnboarding = useCallback(async () => {
    saveCurrentStep(OnboardingStepsType.FINISH_STEP);
    trackOnboardingEvent(TrackingEvents.CONGRATULATIONS_DONE);
    onComplete();
  }, [onComplete]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleCustomize = useCallback(async () => {
    saveCurrentStep(OnboardingStepsType.FINISH_STEP);
    onComplete();
  }, [onComplete]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleOnWelcomeContinue = useCallback(() => {
    trackOnboardingEvent(TrackingEvents.WELCOME_GO_CLICK);
    goTo(1, state.activeStep);
  }, [state, goTo]);

  return (
    <div className={classes.root}>
      {!onboardingStepContent[state.activeStep] && (
        <>
          {state.activeStep === StepperStates.WELCOME_STEP && (
            <WelcomePopup onWelcomeContinue={handleOnWelcomeContinue} />
          )}
          {state.activeStep === StepperStates.FINISH_STEP && (
            <OnboardingFinished onDone={handleDoneOnboarding} onCustomize={handleCustomize} />
          )}
        </>
      )}

      {!!onboardingStepContent[state.activeStep] &&
        (() => {
          const StepContent = onboardingStepContent[state.activeStep].component;
          return (
            <div className={classes.stepsRoot}>
              <div className={classes.heading}>
                <div className={classes.title}>
                  {getOnboardingTitle(state.activeStep, data.regulations.preselected.length === 0)}
                </div>
                <div>
                  <MeStepper
                    steps={onboardingSteps}
                    hiddenStepperSteps={OnboardingHiddenStepperSteps}
                    activeStep={state.activeStep}
                    stepsProgress={state.stepsProgress}
                    onStepClick={handleStepClick}
                  />
                </div>
              </div>
              <StepContent data={data} handleDataChange={handleDataChange} />
              <div className={classes.controls}>
                <div>
                  {onboardingStepContent[state.activeStep].actions.back && (
                    <TertiaryButton
                      text="Back"
                      startIcon={<BigidArrowLeftIcon />}
                      onClick={() => onBack(state.activeStep, goTo)}
                      dataAid="onboarding-back-btn"
                      size="medium"
                    />
                  )}
                </div>
                <div className={classes.controlsRight}>
                  {onboardingStepContent[state.activeStep].actions.skip && !hiddenSkip && (
                    <div className={classes.underlined}>
                      <TertiaryButton
                        text="Skip"
                        onClick={() =>
                          onSkip(state.activeStep, state.stepsProgress, goTo, updateEmptyRegions, handleStepVisit)
                        }
                        dataAid="onboarding-skip-btn"
                        size="medium"
                      />
                    </div>
                  )}
                  {onboardingStepContent[state.activeStep].actions.next && (
                    <PrimaryButton
                      text="Next"
                      onClick={() =>
                        onNext(
                          state.activeStep,
                          goTo,
                          handleStepVisit,
                          updateRegions,
                          updateRegulations,
                          updateUseCases,
                          saveCurrentStep,
                        )
                      }
                      disabled={!isStepValid}
                      dataAid="onboarding-next-btn"
                      size="medium"
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })()}
    </div>
  );
};
