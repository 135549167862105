import React, { FC, useState, useEffect } from 'react';
import { BigidAcademyIcon, BigidArrowRightIcon } from '@bigid-ui/icons';

import { trackOnboardingEvent } from '../../../services/eventsTrackingService';
import { TrackingEvents } from '../../../types/TrackingEventTypes';
import { MeConnectorTile, ConnectorMode } from '../../../components/MeConnectorTile';
import { DataSourceSystemOnboardingTypes, OnboardingStepType } from '../../../types';

import { useStyles } from './ConnectDataStepStyles';

export const ConnectDataStep: FC<OnboardingStepType> = ({ data: { sourceSystems }, handleDataChange }) => {
  const classes = useStyles();
  const [isAll, setAll] = useState(false);
  const [system, setSystem] = useState<{ mode: ConnectorMode; id: number | null; connectionId: number | null }>({
    mode: ConnectorMode.DEFAULT,
    id: null,
    connectionId: null,
  });

  const openAllSystems = () => {
    setAll(true);
  };

  useEffect(() => {
    const isConnection = sourceSystems?.all.some(({ connectionId }) => !!connectionId);
    isConnection && openAllSystems();
  }, [sourceSystems]);

  const onSeeAllClick = () => {
    trackOnboardingEvent(TrackingEvents.ONBOARDING_SEE_ALL_SYSTEM);
    setAll(true);
  };

  const onSystemSelect = (source: DataSourceSystemOnboardingTypes, mode: ConnectorMode) => {
    if (mode === ConnectorMode.CONNECTING || mode === ConnectorMode.CONNECTED) {
      setSystem({ id: source.id, connectionId: source.connectionId || null, mode });
      // to hide skip button
      handleDataChange('sourceSystems', mode);
    } else {
      setSystem({ id: null, connectionId: null, mode });
      // to show skip button
      handleDataChange('sourceSystems', ConnectorMode.DEFAULT);
    }
  };

  if (!sourceSystems) return <></>;

  const { all, popular } = sourceSystems;

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.text}>
          Fulfilling privacy requests requires you to locate the user&apos;s data. <br />
          Automate the process by connecting to your systems.
        </div>
        <div className={classes.systemWrapper}>
          <span className={classes.systemsLabel}>Popular Systems</span>
          {popular.map(source => {
            let mode;
            if (system.id !== source.id && system.id !== null) {
              mode = ConnectorMode.DISABLED;
            } else {
              mode = system.mode;
            }
            return (
              <MeConnectorTile
                mode={mode}
                key={source.id}
                source={source}
                handleSystemConection={onSystemSelect}
                data-aid="onboarding-tile"
              />
            );
          })}
          {!isAll && (
            <span className={classes.seeAll} onClick={onSeeAllClick} data-aid="onboarding-see-all-btn">
              See all systems
              <BigidArrowRightIcon size="small" />
            </span>
          )}
          {isAll && (
            <>
              <span className={classes.systemsLabel}>All Systems</span>
              <div className={classes.allSystemsWrapper}>
                {all.map(source => {
                  let mode;
                  if (system.id !== source.id && system.id !== null) {
                    mode = ConnectorMode.DISABLED;
                  } else {
                    mode = system.mode;
                  }
                  return (
                    <MeConnectorTile
                      mode={mode}
                      key={source.id}
                      source={source}
                      handleSystemConection={onSystemSelect}
                      data-aid="onboarding-tile"
                    />
                  );
                })}
              </div>
              <span className={classes.expert}>
                <BigidAcademyIcon />
                You can add as many systems as you like manually in the Systems tab.
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
