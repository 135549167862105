import { useCallback, useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

import { AuthContainer } from './authContainer';
import { getUserReleasedFeatures } from '../services/user/userService';
import { ReleasedFeatureCodeType, ReleasedFeatureType } from '../types/ReleasedFeaturesTypes';

export const useNewFeature = () => {
  const { auth } = AuthContainer.useContainer();
  const [newFeaturesList, setNewFeaturesList] = useState<ReleasedFeatureType[]>([]);

  const [isAppealEnabled, setIsAppealEnabled] = useState<boolean>(false);
  const [isAppealEnabledToRestart, setIsAppealEnabledToRestart] = useState<boolean>(false);
  const [isAuthorizedAgentEnabled, setIsAuthorizedAgentEnabled] = useState<boolean>(false);
  const [isAuthorizedAgentEnabledToRestart, setIsAuthorizedAgentEnabledToRestart] = useState<boolean>(false);
  const [isCommunicationsEnabled, setIsCommunicationsEnabled] = useState<boolean>(false);

  const fetchNewFeatures = async () => {
    const releasedFeatures = await getUserReleasedFeatures();

    setNewFeaturesList(releasedFeatures);
  };

  const getFeatureByCode = useCallback(
    (featureCode: ReleasedFeatureCodeType) => newFeaturesList.find(item => item.code === featureCode),
    [newFeaturesList],
  );

  useEffect(() => {
    if (auth.isAuthenticated) {
      fetchNewFeatures();
    }
  }, [auth]);

  useEffect(() => {
    if (
      getFeatureByCode(ReleasedFeatureCodeType.AUTHORIZED_AGENT) &&
      !getFeatureByCode(ReleasedFeatureCodeType.AUTHORIZED_AGENT)?.viewed
    ) {
      setIsAuthorizedAgentEnabled(true);
      return;
    } else if (
      getFeatureByCode(ReleasedFeatureCodeType.APPEAL) &&
      !getFeatureByCode(ReleasedFeatureCodeType.APPEAL)?.viewed
    ) {
      setIsAppealEnabled(true);
      return;
    } else if (
      getFeatureByCode(ReleasedFeatureCodeType.COMMUNACATIONS) &&
      !getFeatureByCode(ReleasedFeatureCodeType.COMMUNACATIONS)?.viewed
    ) {
      setIsCommunicationsEnabled(true);
      return;
    }
  }, [getFeatureByCode]);

  useEffect(() => {
    if (
      getFeatureByCode(ReleasedFeatureCodeType.AUTHORIZED_AGENT)?.viewed ||
      getFeatureByCode(ReleasedFeatureCodeType.AUTHORIZED_AGENT) === undefined
    ) {
      setIsAuthorizedAgentEnabledToRestart(true);
      return;
    } else if (getFeatureByCode(ReleasedFeatureCodeType.APPEAL)?.viewed) {
      setIsAppealEnabledToRestart(true);
      return;
    } else if (
      getFeatureByCode(ReleasedFeatureCodeType.COMMUNACATIONS) &&
      !getFeatureByCode(ReleasedFeatureCodeType.COMMUNACATIONS)?.viewed
    ) {
      setIsCommunicationsEnabled(true);
      return;
    }
  }, [getFeatureByCode]);

  return {
    newFeaturesList,
    setNewFeaturesList,
    isAppealEnabled,
    isAuthorizedAgentEnabled,
    setIsAuthorizedAgentEnabled,
    setIsAppealEnabled,
    isAppealEnabledToRestart,
    isAuthorizedAgentEnabledToRestart,
    getFeatureByCode,
    isCommunicationsEnabled,
    setIsCommunicationsEnabled,
  };
};

export const NewFeatureContainer = createContainer(useNewFeature);
