import { createContainer } from 'unstated-next';
import { authService } from '../services/authentication';
import { useFetch } from '../hooks/useFetch';
import { fetchEnabledLanguages } from '../services/settingsService';
import { mapLanguagesToDropdownOptions } from '../utils';

export const useAvailableLanguages = () => {
  const tenant = authService.getUser()?.tenant || '';
  const { data } = useFetch({
    fetchApi: () => fetchEnabledLanguages(tenant),
    initValue: [],
    dependencyParams: [tenant],
    permission: !!tenant,
  });

  return {
    languages: data,
    languagesDropdownOptions: mapLanguagesToDropdownOptions(data),
  };
};

export const AvailableLanguagesContainer = createContainer(useAvailableLanguages);
