export interface AuthenticationType {
  accessTokenExpiration: number;
  user: AuthenticatedUserType;
}

export interface AuthenticatedUserType {
  id: number;
  tenant: string;
  defaultTenant: string;
}

export interface UserType extends AuthenticatedUserType {
  fullName: string;
}

export interface AuthResponseType {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  jti: string;
}

export interface TokenClaimsType {
  user_id: number;
  user_tenant: string;
  user_default_tenant: string;
  scope: string[];
  ati: string;
  exp: number;
  jti?: string;
  client_id: string;
}

export interface UserTenantType {
  tenantId: string;
  defaultTenant: boolean;
}

export interface BusinessUserType {
  fullName: string;
  id: number;
  tenants: UserTenantType[];
}

export interface AuthSettingType {
  useNativeLogin: boolean;
  loginUrl?: string;
  logoutUrl?: string;
}
