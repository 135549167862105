import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { BigidChip, BigidSearchableList } from '@bigid-ui/components';
import { getDataSourceIconByDsType } from '@bigid-ui/icons';

import { VendorEngagement } from '../../../types';

export const VendorEngagementPreview: FC<{ vendorEngagements: VendorEngagement[] }> = ({ vendorEngagements }) => {
  const restEngagements = vendorEngagements.slice(1);
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <div>{vendorEngagements[0].vendorName}</div>
      {!!restEngagements.length && (
        <BigidChip
          dataAid="VendorEngagementMoreChip"
          size="small"
          variant="outlined"
          label={`+${restEngagements.length}`}
          tooltipProps={{
            title: (
              <Box
                sx={{
                  width: '356px',
                  maxHeight: '344px',
                  overflow: 'auto',
                }}
              >
                <BigidSearchableList
                  listItems={restEngagements.map(({ vendorName, vendorType }) => ({
                    entity: {
                      name: vendorName,
                      icon: getDataSourceIconByDsType(vendorType),
                    },
                  }))}
                  nameColumnWidth={100}
                  showDividers={false}
                  hideSearch
                />
              </Box>
            ),
            placement: 'top-start',
            arrow: false,
            dataAid: 'VendorEngagementMoreTooltip',
          }}
        />
      )}
    </Stack>
  );
};
