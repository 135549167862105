import React, { FunctionComponent, useState, useRef, useCallback, useMemo, useEffect, ChangeEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';
import { BigidCaption, BigidPaper, TextFieldProps } from '@bigid-ui/components';
import classNames from 'classnames';
import { MeTextField } from '../MeTextField';
import getCaretCoordinates from 'textarea-caret';
import { ClickAwayListener } from '@mui/material';
import { MeTextArea } from '../MeTextArea';
import { Loader } from '../Loader';

export interface MeTextFieldAutocompleteProps extends Omit<TextFieldProps, 'onChange' | 'inputRef' | 'onKeyDown'> {
  autocompleteListItems: { value: string; label: string }[];
  dropDownOffsetX?: number;
  isMultiline?: boolean;
  dropDownOffsetY?: number;
  triggerChar?: string;
  maxAutocompleteListLength?: number;
  shouldEvalOnChangeWhenInit?: boolean;
  onChange?: (textFieldContent: string) => void;
  onSearch?: (stringToSearch: string) => void;
  dropdownHeight?: number | string;
  characterPositionLeft?: number;
  characterPositionTop?: number;
  disableEnter?: boolean;
  ignoreRegex?: RegExp;
  noMargin?: boolean;
  tip?: string;
  testId?: string;
}

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  autocompleteBox: ({
    dropdownHeight,
    characterPositionLeft,
    dropDownOffsetX,
    characterPositionTop,
    dropDownOffsetY,
    autocompleteListItems,
  }: Partial<MeTextFieldAutocompleteProps>) => ({
    position: 'absolute',
    zIndex: 3,
    maxHeight: `${dropdownHeight}px`,
    overflow: 'auto',
    left: characterPositionLeft && dropDownOffsetX ? characterPositionLeft + dropDownOffsetX : 0,
    top: characterPositionTop && dropDownOffsetY ? characterPositionTop + dropDownOffsetY : 0,
    scrollBehavior: 'smooth',
    height: autocompleteListItems && autocompleteListItems?.length < 1 ? '100px' : 'auto',
    width: autocompleteListItems && autocompleteListItems?.length < 1 ? '100px' : 'auto',
  }),
  rowStyle: {
    padding: '5px 10px',
    '&:focus': {
      outline: 'none',
      border: `1px solid ${theme.vars.palette.bigid?.gray100}`,
      borderRadius: 6,
    },
  },
  focusRow: {
    background: theme.vars.palette.bigid?.gray100,
  },
  textareaWrapper: ({ noMargin }: Pick<MeTextFieldAutocompleteProps, 'noMargin'>) => ({
    ...(!noMargin && { marginTop: '20px' }),
    position: 'relative',
  }),
  loaderWrapper: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
}));

const getInputToComplete = (userInput: string, triggerChar: string, selectionEnd = 0) => {
  if (!userInput.includes(triggerChar)) {
    return '';
  }

  const splitInput = userInput.substring(0, selectionEnd).split(triggerChar);
  return splitInput[splitInput.length - 1];
};

export const MeTextFieldAutocomplete: FunctionComponent<MeTextFieldAutocompleteProps> = ({
  autocompleteListItems,
  dropDownOffsetX = -5,
  dropDownOffsetY = 45,
  maxAutocompleteListLength,
  shouldEvalOnChangeWhenInit = true,
  triggerChar = '@',
  onChange,
  onSearch,
  dropdownHeight = 'none',
  value = '',
  ignoreRegex,
  noMargin,
  label,
  tip,
  name,
  errorMessage,
  onBlur,
  testId,
  disabled,
  isMultiline = false,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [userInput, setUserInput] = useState<string>(value);
  const [touched, setTouched] = useState(false);
  const [characterPositionLeft, setCharacterPositionLeft] = useState<number>(0);
  const [characterPositionTop, setCharacterPositionTop] = useState<number>(0);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [selection, setSelection] = useState<number>(0);
  const [cursorPosition, setCursorPosition] = useState<number | null>(0);
  const [textToComplete, setTextToComplete] = useState<string>('');

  const autocompleteListLength = maxAutocompleteListLength || autocompleteListItems.length;

  const classes = useStyles({
    dropdownHeight,
    characterPositionLeft,
    characterPositionTop,
    dropDownOffsetX,
    dropDownOffsetY,
    autocompleteListItems,
    noMargin,
  });

  useEffect(() => {
    setUserInput(value);
  }, [value]);

  useEffect(() => {
    const textareaEl = textAreaRef.current;

    if (shouldEvalOnChangeWhenInit) {
      onChange && onChange(userInput);
    }

    setTextToComplete(getInputToComplete(userInput, triggerChar, textareaEl?.selectionEnd));
    setShowSuggestions(userInput.includes(triggerChar));
    if (cursorPosition) {
      textareaEl?.setSelectionRange(cursorPosition, cursorPosition);
      setCursorPosition(null);
    }
  }, [cursorPosition, triggerChar, userInput, shouldEvalOnChangeWhenInit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onSearch?.(textToComplete);
  }, [textToComplete, onSearch]);

  const autocompleteList = useMemo(() => {
    const textareaEl = textAreaRef.current;
    const inputToComplete = getInputToComplete(userInput, triggerChar, textareaEl?.selectionEnd);
    const valueToFindInAutocompleteList = (
      ignoreRegex ? inputToComplete.replace(ignoreRegex, '') : inputToComplete
    ).toLowerCase();
    const hasTriggerChar = userInput.includes(triggerChar);

    const shouldDisplayFullMenu = hasTriggerChar && !valueToFindInAutocompleteList;
    const shouldDisplayPartialMenu = !!valueToFindInAutocompleteList;
    const autoCompleteListNotEmpty = shouldDisplayFullMenu || shouldDisplayPartialMenu;
    const autoCompleteList = shouldDisplayFullMenu
      ? autocompleteListItems
      : autocompleteListItems?.filter(item => {
          const lowerCaseItem = item.label.toLowerCase();
          return (
            lowerCaseItem.includes(valueToFindInAutocompleteList) && lowerCaseItem !== valueToFindInAutocompleteList
          );
        });

    return touched && autoCompleteListNotEmpty && autoCompleteList;
  }, [userInput, triggerChar, ignoreRegex, autocompleteListItems, touched]);

  const autocompleteListToRender = autocompleteList && autocompleteList?.slice(0, autocompleteListLength);
  const showSuggestionsList = showSuggestions && autocompleteListToRender && autocompleteListToRender?.length > 0;

  const setCursorPositionToEndOfAutocomplete = (input: string, autocompleteItem: string) => {
    const cursorPosition = input.lastIndexOf(autocompleteItem) + autocompleteItem.length;
    setCursorPosition(cursorPosition);
  };

  const insertAutocompleteItem = (item: string) => {
    if (textAreaRef.current === null) {
      return;
    }

    const { selectionEnd } = textAreaRef.current;
    const splitInput = userInput.substring(0, selectionEnd).split(triggerChar);
    const restOfInput = userInput.substring(selectionEnd);

    const inputToComplete = splitInput[splitInput.length - 1];
    const wordToReplace = ignoreRegex ? inputToComplete.replace(ignoreRegex, '') : inputToComplete;
    splitInput.pop();
    splitInput.push(inputToComplete.replace(wordToReplace, item));

    const newInputWithAutocomplete = splitInput.join(triggerChar);
    const completeInput = newInputWithAutocomplete + restOfInput;
    setUserInput(completeInput);

    if (!shouldEvalOnChangeWhenInit) {
      onChange && onChange(userInput);
    }

    setCursorPositionToEndOfAutocomplete(newInputWithAutocomplete, item);
    closeSuggestions();
  };

  const onTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      setSelection(0);
      setTouched(true);
      const textareaEl = textAreaRef.current;
      setUserInput(value);

      if (!shouldEvalOnChangeWhenInit) {
        onChange && onChange(value);
      }

      if (textareaEl) {
        const caret = getCaretCoordinates(textareaEl, textareaEl?.selectionEnd);
        setCharacterPositionLeft(caret.left);
        setCharacterPositionTop(caret.top > 100 ? 89 : caret.top);
      }
    },
    [onChange, shouldEvalOnChangeWhenInit],
  );

  const handleItemClick = (item: string, index: number) => {
    setSelection(index);
    insertAutocompleteItem(item);
  };

  const closeSuggestions = () => {
    const timeOutId = setTimeout(() => setShowSuggestions(false), 200);
    return () => clearTimeout(timeOutId);
  };

  return (
    <FormControl className={classes.formControl}>
      <div className={classes.textareaWrapper}>
        {isMultiline ? (
          <MeTextArea
            name={name}
            onChange={onTextFieldChange}
            value={userInput}
            label={label}
            tip={tip}
            inputRef={textAreaRef}
            error={errorMessage}
            onBlur={onBlur as () => void}
            testId={testId}
            disabled={disabled}
          />
        ) : (
          <MeTextField
            name={name}
            onChange={onTextFieldChange}
            value={userInput}
            label={label}
            tip={tip}
            inputRef={textAreaRef}
            error={errorMessage}
            onBlur={onBlur as () => void}
            testId={testId}
            disabled={disabled}
          />
        )}
        {showSuggestionsList && (
          <ClickAwayListener onClickAway={closeSuggestions}>
            <div>
              <BigidPaper
                classes={{ root: classes.autocompleteBox }}
                dataAid={`MeTextFieldAutocomplete-list-items`}
                paperProps={{
                  tabIndex: -1,
                }}
              >
                {autocompleteListItems.length > 0 ? (
                  (autocompleteListToRender || [])?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classNames(classes.rowStyle, index === selection && classes.focusRow)}
                        onClick={() => handleItemClick(item.value, index)}
                        tabIndex={1}
                      >
                        <BigidCaption>
                          <span data-aid={`autocomplete-${item.value}`}>{item.label}</span>
                        </BigidCaption>
                      </div>
                    );
                  })
                ) : (
                  <div className={classes.loaderWrapper}>
                    <Loader label="" size={40} />
                  </div>
                )}
              </BigidPaper>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </FormControl>
  );
};
