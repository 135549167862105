import React, { FC, useCallback, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import cx from 'classnames';
import { NavLink, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { ReactComponent as IconNavDahsboardDefault } from '../../assets/icons/nav-dashboard-default.svg';
import { ReactComponent as IconNavDahsboardActive } from '../../assets/icons/nav-dashboard-active.svg';
import { ReactComponent as IconNavRequestManagerDefault } from '../../assets/icons/nav-request-manager-default.svg';
import { ReactComponent as IconNavRequestManagerActive } from '../../assets/icons/nav-request-manager-active.svg';
import { ReactComponent as IconNavCookieConsentDefault } from '../../assets/icons/nav-cookie-default-icon.svg';
import { ReactComponent as IconNavCookieConsentActive } from '../../assets/icons/nav-cookie-active-icon.svg';
import { ReactComponent as IconNavEditorDefault } from '../../assets/icons/nav-editor-default-icon.svg';
import { ReactComponent as IconNavEditorActive } from '../../assets/icons/nav-editor-active-icon.svg';
import { ReactComponent as IconNavSystemsDefault } from '../../assets/icons/nav-systems-default-icon.svg';
import { ReactComponent as IconNavSystemsActive } from '../../assets/icons/nav-systems-active-icon.svg';
import { ReactComponent as IconNavSettingsDefault } from '../../assets/icons/nav-settings-default.svg';
import { ReactComponent as IconNavSettingsActive } from '../../assets/icons/nav-settings-active.svg';
import { ReactComponent as IconNavConsentAutomationDefault } from '../../assets/icons/nav-consent-mapping-default.svg';
import { ReactComponent as IconNavConsentAutomationActive } from '../../assets/icons/nav-consent-mapping-active.svg';

import { NavItemType } from '../../types';
import { TutorialContainer } from '../../state/tutorialContainer';
import { TutorialStepsType } from '../../types/TutorialTypes';
import { TutorialTooltip } from '../TutorialTooltip';
import { tutorialTooltipText, tutorialTooltipSidebarText } from '../Tutorial/config';
import { MeUpgradeButton } from '../MeUpgradeButton';

import { isNewFeature, FEATURES } from '../../utils/featuresGuideUtils';
import { PricingPlanContainer } from '../../state/pricingPlanContainer';
import { NotificationDot } from './NotificationDot';
import { ActivateLabel } from './ActivateLabel';
import { NavItemsContainer } from '../../state/navItemsContainer';

import { useStyles } from './SidebarStyles';
import { ConsentTooltip } from '../ConsentTooltip';
import { BrandsContainer } from '../../state/brandsContainer';
import { AboutApplicationDialog } from '../AboutApplicationDialog/AboutApplicationDialog';

interface SidebarPropType {
  disabled: boolean;
}

export const Sidebar: FC<SidebarPropType> = ({ disabled }) => {
  const { pathname } = useLocation();
  const { navItems } = NavItemsContainer.useContainer();
  const { brands } = BrandsContainer.useContainer();
  const [settingsExpanded, setSettingsExpanded] = useState(/^\/settings/.test(pathname));
  const [isExpanded, setIsExpanded] = useState<boolean>();
  const [isConsentPopoverOpen, setConsentPopoverOpen] = useState(false);
  const [isAboutDialogOpen, setAboutDialogOpen] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const { activeTutorialStep } = TutorialContainer.useContainer();
  const { plans, isEnterprise } = PricingPlanContainer.useContainer();
  const isOnPortalEditorPage = !!useMatch('/brands');
  const isOnBasicSettingsPage = !!useMatch('/settings/basic');
  const isOnSystemSettingsPage = !!useMatch('/systems');
  const isOnConsentsMappingPage = !!useMatch('/consents-mapping');
  const isOnCookieConsentsPage = !!useMatch('/cookie-consent');
  const isOnRequestsPage = !!useMatch('/request-manager');
  const isOnPublishPage = !!useMatch('/settings/publish');
  const isOnWorkflowCustomizationPage = !!useMatch('/settings/workflow-customization');

  const isPortalEditorTutorialRequested = activeTutorialStep === TutorialStepsType.EDIT_PRIVACY_CENTER;
  const isSystemTutorialRequested = activeTutorialStep === TutorialStepsType.SYSTEM;
  const isSettingsTutorialRequested =
    activeTutorialStep === TutorialStepsType.DEEP_DISCOVERY || activeTutorialStep === TutorialStepsType.MAP_PROFILES;
  const isPublishTutorialRequested = activeTutorialStep === TutorialStepsType.PUBLISH_PRIVACY_CENTER;
  const isConsentsMappingTutorialRequested = activeTutorialStep === TutorialStepsType.AUTOMATE_CONSENT;

  const shouldDisplayTutorial =
    (!isOnPortalEditorPage && isPortalEditorTutorialRequested) ||
    (!isOnBasicSettingsPage && isSettingsTutorialRequested) ||
    (!isOnSystemSettingsPage && isSystemTutorialRequested) ||
    (!isOnConsentsMappingPage && isConsentsMappingTutorialRequested) ||
    (!isOnPublishPage && isPublishTutorialRequested);

  const [isTutorialTooltipVisible, setIsTutorialTooltipVisible] = useState(false);
  const tutorialTooltipTimeout = useRef<number>();
  const aboutRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    setSettingsExpanded(/^\/settings/.test(pathname));
  }, [pathname]);

  useEffect(() => {
    if (shouldDisplayTutorial) {
      setIsExpanded(true);
      setSettingsExpanded(true);

      // NOTE: using material-ui Tooltip's enterDelay or enterNextDelay not works for some reason
      tutorialTooltipTimeout.current = window.setTimeout(() => {
        setIsTutorialTooltipVisible(true);
      }, 1000);

      return () => window.clearTimeout(tutorialTooltipTimeout.current);
    } else {
      setIsExpanded(false);
      setIsTutorialTooltipVisible(false);
    }
  }, [shouldDisplayTutorial]);

  useEffect(() => {
    if (!localStorage.getItem('consentTooltipViewed') && isEnterprise) {
      setConsentPopoverOpen(true);
    }
  }, [isEnterprise]);

  const handleMouseEnter = useCallback((e: MouseEvent) => {
    if ((e.target as HTMLElement).closest('#consent-tooltip')) return;
    setIsExpanded(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (!shouldDisplayTutorial) {
      setIsExpanded(false);
    }
  }, [shouldDisplayTutorial]);

  const handleAboutClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setAboutDialogOpen(true);
  }, []);

  const handleAboutDialogClose = useCallback(() => {
    setAboutDialogOpen(false);
  }, []);

  const tutorialTooltip = useMemo(() => {
    if (!(activeTutorialStep && shouldDisplayTutorial)) {
      return null;
    }

    const { title, content } =
      tutorialTooltipSidebarText[activeTutorialStep] || tutorialTooltipText[activeTutorialStep] || {};

    if (!(title || content)) {
      return null;
    }

    return (
      <div className={classes.tutorialTooltipWrapper}>
        {isTutorialTooltipVisible && (
          <TutorialTooltip title={title} content={content} placement="right">
            <div className={classes.tutorialTooltipAnchor} />
          </TutorialTooltip>
        )}
      </div>
    );
  }, [classes, isTutorialTooltipVisible, activeTutorialStep, shouldDisplayTutorial]);

  const getLinkWithTutorial = useCallback(
    (basePath: string) => {
      if (!(activeTutorialStep && shouldDisplayTutorial)) {
        return basePath;
      }

      return `${basePath}?tutorialStep=${activeTutorialStep}`;
    },
    [activeTutorialStep, shouldDisplayTutorial],
  );

  const handleConsentClick = (e: MouseEvent) => {
    e.preventDefault();
    if (isConsentPopoverOpen) {
      closeConsentTooltipAndMarkAsViewed();
    }
    navigate(getLinkWithTutorial('/consents-mapping'));
  };

  const closeConsentTooltipAndMarkAsViewed = () => {
    localStorage.setItem('consentTooltipViewed', 'true');
    setConsentPopoverOpen(false);
  };

  const shouldDisplayCookieConsentNotificationDot = useMemo(
    () => plans && !isOnCookieConsentsPage && isNewFeature(FEATURES.COOKIE_CONSENT),
    [isOnCookieConsentsPage, plans],
  );

  const shouldDisplayRequestsNotificationDot = useMemo(
    () => plans && !isOnRequestsPage && isNewFeature(FEATURES.PRIVACY_REQUEST),
    [isOnRequestsPage, plans],
  );

  const shouldDisplayWorkflowCustomizationDot = useMemo(
    () =>
      navItems?.[NavItemType.WORKFLOW_CUSTOMIZATION] &&
      !isOnWorkflowCustomizationPage &&
      isNewFeature(FEATURES.WORKFLOW_CUSTOMIZATION),
    [isOnWorkflowCustomizationPage, navItems],
  );

  if (!navItems) {
    return <></>;
  }

  return (
    <div
      className={cx(classes.root, { [classes.disabled]: disabled, [classes.expanded]: isExpanded })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={e => {
        if (e.target !== aboutRef.current) {
          handleAboutDialogClose();
        }
      }}
    >
      <div className={classes.navigation}>
        {navItems[NavItemType.DASHBOARD] && (
          <NavLink data-aid="nav-dashboard" to="/" className={navData => (navData.isActive ? classes.active : '')}>
            <label>Dashboard</label>
            <IconNavDahsboardDefault />
            <IconNavDahsboardActive />
          </NavLink>
        )}
        {navItems[NavItemType.OPEN_REQUESTS] && (
          <NavLink
            data-aid="nav-requests-manager"
            to="/request-manager"
            className={navData => (navData.isActive ? classes.active : '')}
          >
            <label>Requests</label>
            {!navItems[NavItemType.OPEN_REQUESTS]?.activated && <ActivateLabel />}
            <div className={classes.sidebarItem}>
              {shouldDisplayRequestsNotificationDot && <NotificationDot />}
              <IconNavRequestManagerDefault />
              <IconNavRequestManagerActive />
            </div>
          </NavLink>
        )}
        {/* TODO: restore when consents epic is done */}
        {navItems[NavItemType.CONSENT] && (
          <NavLink
            onClick={handleConsentClick}
            data-aid="nav-consent-mapping"
            to={getLinkWithTutorial('/consents-mapping')}
            className={navData => (navData.isActive ? classes.active : classes.itemWithTutorial)}
          >
            <label>Consent & Preferences</label>
            <IconNavConsentAutomationDefault />
            <IconNavConsentAutomationActive />
            {isConsentsMappingTutorialRequested && tutorialTooltip}
            {isConsentPopoverOpen && (
              <ConsentTooltip closeTooltip={closeConsentTooltipAndMarkAsViewed}>
                <div className={classes.tutorialTooltipAnchor} id="consent-tooltip-anchor"></div>
              </ConsentTooltip>
            )}
          </NavLink>
        )}
        {navItems[NavItemType.COOKIE_CONSENT] && (
          <NavLink
            data-aid="nav-cookie-consent"
            to="/cookie-consent"
            className={navData => (navData.isActive ? classes.active : '')}
          >
            <label>Cookie Consent</label>
            {!navItems[NavItemType.COOKIE_CONSENT]?.activated && <ActivateLabel />}
            <div className={classes.sidebarItem}>
              {shouldDisplayCookieConsentNotificationDot && <NotificationDot />}
              <IconNavCookieConsentDefault />
              <IconNavCookieConsentActive />
            </div>
          </NavLink>
        )}
        {navItems[NavItemType.EDITOR] && (
          <NavLink
            data-aid="nav-editor"
            to={
              brands?.brands?.length > 1
                ? getLinkWithTutorial('/brands')
                : getLinkWithTutorial(`/editor/${encodeURIComponent(brands.brands[0]?.id)}`)
            }
            className={
              pathname.includes('brands') || pathname.includes('editor') ? classes.active : classes.itemWithTutorial
            }
          >
            <label>Portal Editor</label>
            <IconNavEditorDefault />
            <IconNavEditorActive />
            {isPortalEditorTutorialRequested && tutorialTooltip}
          </NavLink>
        )}
        {navItems[NavItemType.DATA_SOURCES] && (
          <NavLink
            to={getLinkWithTutorial('/systems')}
            className={navData => (navData.isActive ? classes.active : classes.itemWithTutorial)}
            data-aid="nav-systems"
          >
            <label>Systems</label>
            <IconNavSystemsDefault />
            <IconNavSystemsActive />
            {isSystemTutorialRequested && tutorialTooltip}
          </NavLink>
        )}
        <div>
          <span
            data-aid="nav-settings"
            onClick={() => setSettingsExpanded(prev => !prev)}
            className={cx(classes.settings, {
              [classes.active]: /^\/settings/.test(pathname),
            })}
          >
            <label>Settings</label>
            <div className={classes.sidebarItem}>
              {!settingsExpanded && shouldDisplayWorkflowCustomizationDot && <NotificationDot />}
              <IconNavSettingsDefault />
              <IconNavSettingsActive />
            </div>
            <span>
              {settingsExpanded ? <KeyboardArrowUp data-aid="arrow-up" /> : <KeyboardArrowDown data-aid="arrow-down" />}
            </span>
          </span>

          {settingsExpanded && (
            <section>
              {navItems[NavItemType.BASIC_SETTINGS] && (
                <NavLink
                  data-aid="basic-settings"
                  to={getLinkWithTutorial('/settings/basic')}
                  className={navData => (navData.isActive ? classes.subActive : classes.itemWithTutorial)}
                >
                  Basic Settings
                  {isSettingsTutorialRequested && tutorialTooltip}
                </NavLink>
              )}
              {navItems[NavItemType.WORKFLOW_CUSTOMIZATION] && (
                <NavLink
                  data-aid="workflow-customization"
                  to="/settings/workflow-customization"
                  className={navData => (navData.isActive ? classes.subActive : '')}
                >
                  <label className={classes.subItemLabel}>Workflow Customization</label>
                  {shouldDisplayWorkflowCustomizationDot && (
                    <div className={classes.sidebarSubItem}>
                      <NotificationDot />
                    </div>
                  )}
                </NavLink>
              )}
              {navItems[NavItemType.USERS] && (
                <NavLink
                  data-aid="users"
                  to="/settings/users"
                  className={navData => (navData.isActive ? classes.subActive : '')}
                >
                  Users
                </NavLink>
              )}
              {navItems[NavItemType.ROLES] && (
                <NavLink
                  data-aid="roles"
                  to="/settings/roles"
                  className={navData => (navData.isActive ? classes.subActive : '')}
                >
                  Roles
                </NavLink>
              )}
              {navItems[NavItemType.SCOPES] && (
                <NavLink
                  data-aid="scopes"
                  to="/settings/scopes"
                  className={navData => (navData.isActive ? classes.subActive : '')}
                >
                  Departments
                </NavLink>
              )}
              {navItems[NavItemType.PUBLISH] && (
                <NavLink
                  data-aid="publish"
                  to={getLinkWithTutorial('/settings/publish')}
                  className={navData => (navData.isActive ? classes.subActive : classes.itemWithTutorial)}
                >
                  Publish
                  {isPublishTutorialRequested && tutorialTooltip}
                </NavLink>
              )}
              {navItems[NavItemType.ABOUT] && (
                <a className={classes.sidebarItem} ref={aboutRef} data-aid="nav-about" onClick={handleAboutClick}>
                  About
                </a>
              )}
              <MeUpgradeButton classes={classes.upgradeButton} />
            </section>
          )}
        </div>
      </div>
      <div id="consent-tooltip-container" className={classes.consentTooltipContainer} />
      <AboutApplicationDialog
        version={navItems[NavItemType.ABOUT]?.version}
        isOpen={isAboutDialogOpen}
        onClose={handleAboutDialogClose}
      />
    </div>
  );
};
