import React, { FC } from 'react';
import cx from 'classnames';
import { ReactComponent as IconStepperCompleted } from '../../assets/icons/stepper_completed.svg';
import { ReactComponent as IconStepper } from '../../assets/icons/stepper_not-completed.svg';

import { useStyles } from './StepperIconStyles';

export interface StepperIconType {
  current: boolean;
  completed: boolean;
}

export const StepperIcon: FC<StepperIconType> = ({ completed, current }) => {
  const classes = useStyles();

  return (
    <div
      className={cx(classes.root, {
        [classes.unavailable]: !(current || completed),
      })}
    >
      {completed ? <IconStepperCompleted /> : <IconStepper />}
    </div>
  );
};
