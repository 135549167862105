import { styled } from '@mui/material';

const PREFIX = 'UploadedFiles';

export const classes = {
  root: `${PREFIX}-root`,
  fileChipLabel: `${PREFIX}-fileChipLabel`,
  fileChipIcon: `${PREFIX}-fileChipIcon`,
  removeFileIcon: `${PREFIX}-removeFileIcon`,
  errorHint: `${PREFIX}-errorHint`,
};

export const Root = styled('div')(({ theme }) => ({
  [`&`]: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 8,
    marginTop: 8,
    padding: '8px',
    borderRadius: 3,
    backgroundColor: theme.vars.palette.bigid?.gray150,
  },
  [`& .${classes.fileChipLabel}`]: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  [`& .${classes.fileChipIcon}`]: {
    marginRight: 4,
  },
  [`& .${classes.removeFileIcon}`]: {
    width: 14,
    height: 14,
    fill: theme.vars.palette.bigid?.gray500,
    cursor: 'pointer',
  },
  [`& .${classes.errorHint}`]: {
    marginTop: 8,
    fontSize: '0.75rem',
    color: theme.vars.palette.bigid?.red400,
  },
}));

export const StyledFileChip = styled('div')<{ error?: boolean }>(({ theme, error }) => ({
  display: 'flex',
  gap: 4,
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: 194,
  height: 28,
  margin: '0 0 8px 8px',
  padding: '0 8px 0 10px',
  borderRadius: 3,
  border: `1px solid ${theme.vars.palette.bigid?.gray300}`,
  backgroundColor: error ? theme.vars.palette.bigid?.red400 : theme.vars.palette.bigid?.white,
}));
