import React, { FC } from 'react';
import { useSnackbar } from 'notistack';
// eslint-disable-next-line import/no-unresolved
import { LanguageType } from '@consumer/state/languageContainer';
import { BigidMessageIcon } from '@bigid-ui/icons';
import { Root, classes } from './EmailTemplateControlsBarStyles';
import { LanguagePicker } from '../../../components/LanguagePicker';
import { TestEmailType, UpdateEmailTemplateType } from '../../../types';
import { encodeWorkflowEmail } from '../../../utils';
import { postTestEmail } from '../../../services/settingsService';
import { BigidTooltip, TertiaryButton } from '@bigid-ui/components';

interface EmailTemplateControlsBarPropsType {
  emailTemplateId?: number;
  emailTemplate?: UpdateEmailTemplateType;
  selectedLanguage: LanguageType;
  onSelectedLanguageChange: (language: LanguageType) => void;
  languagePickerTestId?: string;
  showLanguagePicker?: boolean;
  emailCode?: string;
}

export const EmailTemplateControlsBar: FC<EmailTemplateControlsBarPropsType> = ({
  emailTemplateId,
  emailTemplate,
  selectedLanguage,
  onSelectedLanguageChange,
  languagePickerTestId = 'email-template',
  showLanguagePicker = true,
  emailCode,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const sendTestEmail = async (event?: React.MouseEvent<Element, MouseEvent>) => {
    event?.stopPropagation();
    emailTemplate &&
      !!emailTemplateId &&
      (await postTestEmail(emailTemplateId, {
        ...encodeWorkflowEmail(emailTemplate).translations[selectedLanguage],
        progressEnabled: emailTemplate.progressEnabled,
        appealEnabled: emailTemplate.appealEnabled,
        authorizedAgentMessageMode: emailTemplate.authorizedAgentMessageMode,
        emailCode,
      } as TestEmailType));

    enqueueSnackbar('Test email was sent successfully');
  };

  return (
    <Root className={classes.root}>
      {!!emailTemplateId && (
        <BigidTooltip title={`Click to send yourself a preview test email`} placement="bottom" width="210px">
          <TertiaryButton
            margin="0 16px"
            text="Send Test Email"
            dataAid="send-test-email_button"
            onClick={sendTestEmail}
            startIcon={<BigidMessageIcon />}
            size="medium"
          />
        </BigidTooltip>
      )}
      {showLanguagePicker && <LanguagePicker onChange={onSelectedLanguageChange} testId={languagePickerTestId} />}
    </Root>
  );
};
