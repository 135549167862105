import { styled } from '@mui/material/styles';

const PREFIX = 'ManualCloseResolutionTable';

export const classes = {
  tr: `${PREFIX}-tr`,
  dashCentering: `${PREFIX}-dashCentering`,
  activeTd: `${PREFIX}-activeTd`,
  triggerTd: `${PREFIX}-triggerTd`,
  default: `${PREFIX}-default`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.tr}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.dashCentering}`]: {
    paddingLeft: 33,
  },
  [`& .${classes.activeTd}`]: {
    textAlign: 'end',
    width: 50,
  },
  [`& .${classes.activeTd} span`]: {
    display: 'inline-flex',
  },
  [`& .${classes.triggerTd}`]: {
    width: 420,
  },
  [`& .${classes.default}`]: {
    color: theme.vars.palette.bigid?.gray500,
  },
}));
