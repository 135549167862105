import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: theme.vars.palette.bigid?.gray100,
    color: theme.vars.palette.bigid?.gray600,
    overflow: 'auto',
  },
  wrapper: ({ isEmbeddedInIframe }: { isEmbeddedInIframe: boolean }) => ({
    position: isEmbeddedInIframe ? 'inherit' : 'fixed',
    top: isEmbeddedInIframe ? 0 : 57,
    left: isEmbeddedInIframe ? 0 : 65,
    right: 0,
    bottom: 0,
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: 20,
  }),
  '@global': {
    html: {
      overflowY: 'auto',
    },
    /* scrollbar custom styles */
    '*::-webkit-scrollbar': {
      width: '4px',
      height: '5px',
    },
    '*::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 4px trasparent`,
      borderRadius: '4px',
    },
    '*::-webkit-scrollbar-thumb': {
      background: theme.vars.palette.bigid?.gray300,
      borderRadius: '4px',
    },
    '*::-webkit-scrollbar-thumb:hover': {
      background: theme.vars.palette.bigid?.gray600,
    },
  },
}));
