import React, { FC, useCallback, useState, MouseEvent } from 'react';
import copy from 'copy-to-clipboard';
import { BigidTooltip } from '@bigid-ui/components';
import { IconButton } from '@mui/material';

import { MeCopyTile } from '../MeCopyTile';
import { StyledCopyIcon } from './MeCopyStyles';

export type MeCopyPropsType = {
  textToCopy: string;
};

export const MeCopy: FC<MeCopyPropsType> = ({ textToCopy }) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const handleCopyClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      copy(textToCopy);
      setTooltipOpen(true);
    },
    [textToCopy],
  );

  const handleClose = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  return (
    <BigidTooltip
      disableInteractive
      open={isTooltipOpen}
      title={<MeCopyTile />}
      arrow={false}
      leaveDelay={2000}
      onClose={handleClose}
    >
      <IconButton onClick={handleCopyClick} size="medium" style={{ height: '100%' }}>
        <StyledCopyIcon />
      </IconButton>
    </BigidTooltip>
  );
};
