import makeStyles from '@mui/styles/makeStyles';
import success from '../../../assets/icons/true.svg';

export type SwitchToggleColorsType = {
  checkedColor?: string;
  uncheckedColor?: string;
};

type SwitchToggleStylePropsType = {
  isTablet: boolean;
  clickable: boolean;
} & SwitchToggleColorsType;

export const useStyles = makeStyles(theme => ({
  root: ({ isTablet, clickable }: SwitchToggleStylePropsType) => ({
    position: 'relative',
    width: isTablet ? 55 : 29,
    height: isTablet ? 34 : 18,
    userSelect: 'none',
    flexShrink: 0,
    ...(!clickable ? { pointerEvents: 'none' } : {}),
  }),
  checkbox: ({ checkedColor }: SwitchToggleStylePropsType) => ({
    position: 'absolute',
    opacity: 0,
    pointerEvents: 'none',
    '&:checked + label': {
      backgroundColor: checkedColor || theme.vars.palette.bigid?.gray700,
      borderColor: checkedColor || theme.vars.palette.bigid?.gray700,

      '&:before': {
        backgroundImage: `url(${success})`,
        right: 1,
      },
    },
  }),
  label: ({ isTablet, uncheckedColor }: SwitchToggleStylePropsType) => ({
    display: 'block',
    overflow: 'hidden',
    cursor: 'pointer',
    height: isTablet ? 34 : 18,
    padding: 0,
    lineHeight: 18,
    border: `1px solid ${uncheckedColor || theme.vars.palette.bigid?.gray500}`,
    borderRadius: 18,
    backgroundColor: uncheckedColor || 'rgba(143,155,179,0.16)',
    transition: 'background-color 0.3s ease-in',
    '&:before': {
      content: '""',
      display: 'block',
      width: isTablet ? 30 : 14,
      margin: '1px 0',
      background: theme.vars.palette.bigid?.white,
      position: 'absolute',
      top: 1,
      bottom: 1,
      right: isTablet ? 23 : 13,
      borderRadius: 14,
      transition: 'all 0.3s ease-in 0s',
      backgroundPosition: '50% 50%',
      backgroundRepeat: 'no-repeat',
    },
  }),
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  toggleStateText: {
    position: 'absolute',
    top: '0',
    left: '31px',
  },
}));
