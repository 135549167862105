import React, { FC } from 'react';
import { BigidWarningIcon } from '@bigid-ui/icons';
import { useStyles } from './NotificationPanelStyles';

export interface NotificationPanelPropsType {
  title: string;
  subtitle: string;
  reasonText: string;
}

export const NotificationPanel: FC<NotificationPanelPropsType> = ({ title, subtitle, reasonText }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BigidWarningIcon />
      <span>{title}</span>
      <hr />
      <span>
        <b>{subtitle}:</b>
      </span>
      <span>{reasonText}</span>
    </div>
  );
};
