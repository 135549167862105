import React, { useCallback, useEffect } from 'react';
import { BigidGrid, BigidGridRow, FetchDataFunction, UseFetchActions, useFetch as useGridFetch } from '@bigid-ui/grid';
import { BigidBreadcrumbs } from '@bigid-ui/components';
import { useNavigate } from 'react-router-dom';
import {
  workflowCustomizationColumnsAssigned,
  getDefaultOwnerChangedSuccessText,
  getDefaultCollaboratorsChangedSuccessText,
  updateRequestWorkflowOwnerAndCollaborators,
} from './utils';

import { Root, classes } from './WorkflowCustomizationStyles';
import { GridRowType, RequestWorkflowDefinitionDefaultCollaborators } from '../../../types';
import { fetchWorkflowCustomizationDefinitions, REQUEST_DEFINITIONS_AMOUNT } from '../../../services/settingsService';
import { FEATURES, isNewFeature, markFeatureAsKnown } from '../../../utils/featuresGuideUtils';
import { useFormatting } from '../../../hooks/useFormatting';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useShowSnackbar } from '../../../hooks/useShowSnackbar';
import { getBusinessUsersByTenant } from '../../../services/request';
import { useFetch } from '../../../hooks';
import { BigidDropdownOption } from '@bigid-ui/components/lib/BigidDropdown/BigidDropdown';

export const WorkflowCustomization = () => {
  const navigate = useNavigate();
  const { showSnackbarMessage } = useShowSnackbar();
  const { data: usersToAssign } = useFetch({
    initValue: {
      users: [],
    },
    fetchApi: () => getBusinessUsersByTenant(),
  });
  const { getFormattedDate } = useFormatting();

  usePageTitle('Workflows');

  useEffect(() => {
    if (isNewFeature(FEATURES.WORKFLOW_CUSTOMIZATION)) {
      markFeatureAsKnown(FEATURES.WORKFLOW_CUSTOMIZATION);
    }
  }, []);

  const onRowClick = useCallback(
    (row: BigidGridRow) => {
      navigate(`/settings/workflow-customization/${row.id}`);
    },
    [navigate],
  );

  const { rows, isLoading, totalRowsCount, skip, onPagingChanged, dispatch } = useGridFetch({
    fetchDataFunction: fetchWorkflowCustomizationDefinitions as unknown as FetchDataFunction<GridRowType>,
  });

  const handleUpdateRequestOwner = useCallback(
    async (
      requestDefinitionId: number,
      value: BigidDropdownOption,
      defaultCollaborators: RequestWorkflowDefinitionDefaultCollaborators[],
    ) => {
      const { defaultOwnerUserFullName } = await updateRequestWorkflowOwnerAndCollaborators({
        requestDefinitionId,
        defaultOwnerUserFullName: value?.displayValue,
        defaultOwnerUserId: Number(value?.id),
        defaultCollaborators,
      });
      showSnackbarMessage(getDefaultOwnerChangedSuccessText(defaultOwnerUserFullName));
      dispatch({
        type: UseFetchActions.FORCE_RELOAD,
        payload: {},
      });
    },
    [dispatch, showSnackbarMessage],
  );

  const handleUpdateRequestCollaborators = useCallback(
    async (
      requestDefinitionId: number,
      values: BigidDropdownOption[],
      defaultOwnerUserFullName?: string,
      defaultOwnerUserId?: number,
    ) => {
      const { defaultCollaborators: defaultCollaboratorsData } = await updateRequestWorkflowOwnerAndCollaborators({
        requestDefinitionId,
        defaultCollaborators: values.map(({ id, displayValue }) => ({
          userId: Number(id),
          userFullName: displayValue,
        })),
        defaultOwnerUserFullName,
        defaultOwnerUserId,
      });
      showSnackbarMessage(
        getDefaultCollaboratorsChangedSuccessText(defaultCollaboratorsData.map(i => i.userFullName).join(', ')),
      );
      dispatch({
        type: UseFetchActions.FORCE_RELOAD,
        payload: {},
      });
    },
    [dispatch, showSnackbarMessage],
  );

  return (
    <Root>
      <BigidBreadcrumbs
        breadcrumbs={[
          {
            label: 'Workflow Customization',
          },
        ]}
      />
      <div className={classes.bigidGridRoot}>
        <BigidGrid
          pageSize={REQUEST_DEFINITIONS_AMOUNT}
          rows={rows}
          columns={workflowCustomizationColumnsAssigned({
            usersToAssign: usersToAssign?.users || [],
            onOwnerChange: handleUpdateRequestOwner,
            onChangeCollaborators: handleUpdateRequestCollaborators,
            getFormattedDate,
          })}
          loading={isLoading}
          onRowClick={onRowClick}
          totalRowsCount={totalRowsCount}
          skip={skip}
          showSortingControls={false}
          showFilteringControls={false}
          onPagingChanged={onPagingChanged}
        />
      </div>
    </Root>
  );
};
