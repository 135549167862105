import React, { ReactNode } from 'react';
import copy from 'copy-to-clipboard';
import { AxiosError } from 'axios';
import { BigidCopyIcon } from '@bigid-ui/icons';
import { IconButton } from '@mui/material';

import { ErrorResponseType } from '../types';
import { BIGID_ME_SUPPORT_EMAIL } from './commonConstants';
import { SnackbarUtils } from './snackbarUtils';
import { BigidLink } from '@bigid-ui/components';

export enum ErrorCodes {
  NETWORK_ERROR = 0,
  NOT_AUTHORIZED = 403,
  TENANT_DOESNT_EXIST = 16420,
  SCOPE_DOESNT_EXIST = 16421,
  ROLE_DOESNT_EXIST = 16422,
  PERMISSION_GROUP_DOESNT_EXIST = 16423,
  CANNOT_DELETE_ROOT_SCOPE = 16424,
  CANNOT_UPDATE_ROOT_SCOPE = 16425,
  DATA_SOURCE_DOESNT_EXIST = 16426,
  USER_DOESNT_EXIST = 16427,
  USER_DOESNT_EXIST_IN_IDP = 16434,
  USER_NAME_ALREADY_EXISTS = 16428,
  USER_EMAIL_ALREADY_EXISTS = 16429,
  SCOPE_NAME_ALREADY_EXISTS = 16430,
  ROLE_NAME_ALREADY_EXISTS = 16431,
  USERS_LIMIT_EXCEEDED = 16433,
  DATASOURCENAME_ALREADY_EXISTS = 13315,
  WRONG_FILE_SIZE_OR_FORMAT = 11426,
  INVALID_TENANT = 11430,
  INVALID_PAYLOAD = 11454,
  DATASOURCE_TYPE_SIGN_IN_FORBIDDEN = 13314,
  CONNECTOR_SOLUTION_DOESNT_EXIST = 17401,
  FAILED_TO_ONBOARD_USER_CREATION = 14305,
  PAYMENT_ERROR = 14306,
  NOT_VALID_EMAIL = 14301,
  SUBSCRIPTION_FAILED = 13318,
  DS3_REQUIRED = 13319,
  CANNOT_UPGRADE = 13321,
  COMPANY_NAME_ALREADY_EXISTS = 13325,
  DATA_SOURCE_NAME_ALREADY_EXISTS = 13326,
  DATA_SOURCE_TYPE_NAME_ALREADY_EXISTS = 13327,
  DATA_SOURCE_TYPE_DISPLAY_NAME_ALREADY_EXISTS = 13328,
  CANNOT_DELETE_MANDATORY_FIELD = 13316,
  DISABLED_DEFAULT_LOCALE = 13329,
  FIELD_DOESNT_EXIST = 13332,
  NOTE_INCORRECT_REQUEST_TYPE = 12432,
  NOTE_UPDTE_INCORRECT_USER = 12433,
  NOTE_DELETE_INCORRECT_USER = 12434,
  BACKEND_CUSTOM_MESSAGE = 12425,
  DUPLICATED_REPORT_IMPORT = 12444,
  BACKEND_CUSTOM_MESSAGE2 = 13319,
  INVITE_USER_ERROR = 16436,
  CANNOT_DELETE_MAPPED_ROLE = 16437,
  CANNOT_DELETE_DEFAULT_BRAND = 13348,
  MESSAGES_RETENTION_POLICY = 12452,
  CANNOT_ADD_USER_TO_ORGANIZATION = 16443,
  PROFILE_IS_USED_AS_DEFAULT_VALUE = 13361,
  FAILED_SEND_EMAIL = 11456,
  PASSCODE_DOESNT_EXIST = 13377,
  JIRA_CONFIGURATION_DO_NOT_EXIST = 13378,
  JIRA_PROJECT_EXIST = 13379,
  NOT_FOUND_REQUEST = 12401,
  EMAILS_PER_DATASOURCE_LIMIT_EXCEEDED = 12457,
  DATA_SOURCE_EMAIL_EXCEPTION = 11461,
  INVALID_CREDENTIALS = 16446,
  DIFFERENT_CREDENTIALS = 16447,
}

export enum LoginErrorCodes {
  BIGID_LOGIN_ERROR = 1,
  LOGIN_ACCESS_DENIED = 16432,
  USER_DOESNT_EXIST = 16427,
}

const renderError = (message: ReactNode, code: ErrorCodes | number, traceId?: string) => {
  return (
    <span data-aid="notification-error">
      {message}
      <br />
      <BigidLink
        href={`mailto:${BIGID_ME_SUPPORT_EMAIL}`}
        shouldOpenNewTab
        text="Additional info for support:"
        rel="noopener noreferrer"
      />{' '}
      {traceId}{' '}
      <IconButton
        size="medium"
        onClick={() => {
          copy(traceId || code.toString());
          SnackbarUtils.success('Copied!');
        }}
      >
        <BigidCopyIcon />
      </IconButton>
    </span>
  );
};

export const handleError = (code: ErrorCodes | number = 0, customMessage = ''): ReactNode => {
  switch (code) {
    case ErrorCodes.NETWORK_ERROR:
      return 'Network error occurred';
    case ErrorCodes.NOT_AUTHORIZED:
      return 'You are not authorized to access data';
    case ErrorCodes.TENANT_DOESNT_EXIST:
      return "Tenant doesn't exist";
    case ErrorCodes.SCOPE_DOESNT_EXIST:
      return "Department doesn't exist";
    case ErrorCodes.ROLE_DOESNT_EXIST:
      return "Role doesn't exist";
    case ErrorCodes.PERMISSION_GROUP_DOESNT_EXIST:
      return "Permission group doesn't exist";
    case ErrorCodes.CANNOT_DELETE_ROOT_SCOPE:
      return 'Root department cannot be deleted';
    case ErrorCodes.CANNOT_UPDATE_ROOT_SCOPE:
      return 'Root department cannot be updated';
    case ErrorCodes.DATA_SOURCE_DOESNT_EXIST:
      return "System doesn't exist";
    case ErrorCodes.USER_DOESNT_EXIST:
      return "User doesn't exist";
    case ErrorCodes.USER_DOESNT_EXIST_IN_IDP:
      return "User doesn't exist or out of sync";
    case ErrorCodes.USER_NAME_ALREADY_EXISTS:
      return 'User with such name already exists';
    case ErrorCodes.USER_EMAIL_ALREADY_EXISTS:
      return 'User with such email already exists';
    case ErrorCodes.SCOPE_NAME_ALREADY_EXISTS:
      return 'Department with such name already exists';
    case ErrorCodes.ROLE_NAME_ALREADY_EXISTS:
      return 'Role with such name already exists';
    case ErrorCodes.DATASOURCENAME_ALREADY_EXISTS:
      return 'System name already exists';
    case ErrorCodes.WRONG_FILE_SIZE_OR_FORMAT:
      return 'File is wrong format/size';
    case ErrorCodes.DATASOURCE_TYPE_SIGN_IN_FORBIDDEN:
      return 'System type is not applicable for sign in';
    case ErrorCodes.CONNECTOR_SOLUTION_DOESNT_EXIST:
      return 'System type is not applicable due to misconfiguration';
    case ErrorCodes.PAYMENT_ERROR:
      return 'Payment error';
    case ErrorCodes.SUBSCRIPTION_FAILED:
      return 'Failed to create subscription for tenant';
    case ErrorCodes.CANNOT_UPGRADE:
      return 'Upgrade failed';
    case ErrorCodes.DS3_REQUIRED:
      return '3DS check validation required';
    case ErrorCodes.FAILED_TO_ONBOARD_USER_CREATION:
      return 'Error creating user during registration';
    case ErrorCodes.USERS_LIMIT_EXCEEDED:
      return 'User can not be created: limit of users has exceeded';
    case ErrorCodes.NOT_VALID_EMAIL:
      return 'Please enter work email';
    case ErrorCodes.COMPANY_NAME_ALREADY_EXISTS:
      return 'Company with such name already exists';
    case ErrorCodes.DATA_SOURCE_NAME_ALREADY_EXISTS:
      return 'System with such name already exists';
    case ErrorCodes.DATA_SOURCE_TYPE_NAME_ALREADY_EXISTS:
      return 'System type with such name already exists';
    case ErrorCodes.DATA_SOURCE_TYPE_DISPLAY_NAME_ALREADY_EXISTS:
      return 'System type with such display name already exists';
    case ErrorCodes.CANNOT_DELETE_MANDATORY_FIELD:
      return 'Cannot delete mandatory field';
    case ErrorCodes.DISABLED_DEFAULT_LOCALE:
      return 'Disabled locale cannot be set as default';
    case ErrorCodes.FIELD_DOESNT_EXIST:
      return 'Field does not exist';
    case ErrorCodes.CANNOT_DELETE_DEFAULT_BRAND:
      return 'Cannot delete default brand for the tenant';
    case ErrorCodes.NOTE_INCORRECT_REQUEST_TYPE:
      return 'The note cannot be added to the system: incorrect request type (request must be of type Delete)';
    case ErrorCodes.NOTE_UPDTE_INCORRECT_USER:
      return 'The note can be updated only by the creator';
    case ErrorCodes.NOTE_DELETE_INCORRECT_USER:
      return 'The note can be deleted only by the creator';
    case ErrorCodes.CANNOT_ADD_USER_TO_ORGANIZATION:
      return 'Cannot add user';
    case ErrorCodes.BACKEND_CUSTOM_MESSAGE:
      return customMessage;
    case ErrorCodes.BACKEND_CUSTOM_MESSAGE2:
      return customMessage;
    case ErrorCodes.INVITE_USER_ERROR:
      return (
        <span data-aid="invite-user-error">
          We couldn’t complete the request, it might be related to your identity provider.
        </span>
      );
    case ErrorCodes.CANNOT_DELETE_MAPPED_ROLE:
      return 'This role is used by SAML. To delete it, remove it from Settings > Users > Identity Provider.';
    case ErrorCodes.PROFILE_IS_USED_AS_DEFAULT_VALUE:
      return 'The profile cannot be disabled/removed because one of the forms uses it as the default value';
    case ErrorCodes.FAILED_SEND_EMAIL:
      return 'Email to requestor was not sent. Try again';
    case ErrorCodes.PASSCODE_DOESNT_EXIST:
      return 'Passcode configuration does not exist';
    case ErrorCodes.JIRA_CONFIGURATION_DO_NOT_EXIST:
      return 'Jira configuration does not exist';
    case ErrorCodes.JIRA_PROJECT_EXIST:
      return 'The Jira Project has been already configured';
    case ErrorCodes.NOT_FOUND_REQUEST:
      return 'The request does not exist';
    case ErrorCodes.EMAILS_PER_DATASOURCE_LIMIT_EXCEEDED:
      return 'You have exceeded the limit of emails sent per system';
    case ErrorCodes.INVALID_PAYLOAD:
      return `Invalid input${customMessage ? '. ' + customMessage : ''}`;
    case ErrorCodes.DATA_SOURCE_EMAIL_EXCEPTION:
      return 'Failed to send email, data source may have been deleted';
    case ErrorCodes.INVALID_CREDENTIALS:
      return 'Current password is wrong';
    case ErrorCodes.DIFFERENT_CREDENTIALS:
      return 'New password must be different then the current';
    default:
      return 'Unknown error occurred. Please contact your system administrator for more details';
  }
};

export const toErrorResponse = (
  error: AxiosError<{ traceId: string; errorCode: number; message: string }>,
): ErrorResponseType => {
  const { status, data } = error?.response || {};

  const traceId = data?.traceId || 'unknown';
  const errorCode = data?.errorCode || status || ErrorCodes.NETWORK_ERROR;
  const message = handleError(errorCode, data?.message);
  const messageWithTrace = renderError(handleError(errorCode, data?.message), errorCode, traceId);

  const responseError: ErrorResponseType = {
    message,
    messageWithTrace,
    traceId,
    errorCode,
    status,
  };

  return responseError;
};

export const shouldAvoidSnackbarNotification = (error: ErrorResponseType) => {
  const errorCode = error.errorCode;

  return errorCode === ErrorCodes.DUPLICATED_REPORT_IMPORT || errorCode === ErrorCodes.MESSAGES_RETENTION_POLICY;
};
