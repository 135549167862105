import React, { FC, useEffect, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment-timezone';
import { create, color, Color } from '@amcharts/amcharts4/core';
import { XYChart, LineSeries } from '@amcharts/amcharts4/charts';
import { BigidMeChartsColors, RequestsByTypeColors, SingleColor, RejectedRequestsColors } from '../../assets/styles';
import { BigidChartLegendPosition } from '../../assets/misc';
import { RequestStatusIconsProps } from '../../utils';
import {
  LineChartCursor,
  LineChartDateAxis,
  LineChartLegend,
  LineChartTitle,
  LineChartTooltip,
  LineChartValueAxis,
} from './LineChartPartials';
import { useStyles } from './LineChartStyles';
import { TrendRequestDataItemType } from '../../types/DashboardTypes';

const MIN_EMPTY_LINECHART_VALUE = 50;

export interface LineChartType {
  series: string[];
  legend?: BigidChartLegendPosition;
  title?: string;
  xTitle?: string;
  yTitle?: string;
  colors?:
    | typeof BigidMeChartsColors
    | typeof SingleColor
    | typeof RejectedRequestsColors
    | typeof RequestsByTypeColors;
  tooltip?: boolean;
  name?: string;
  fetchData: () => Promise<TrendRequestDataItemType[]>;
}

export const LineChart: FC<LineChartType> = ({
  series,
  legend,
  title,
  xTitle,
  yTitle,
  colors = BigidMeChartsColors,
  tooltip = true,
  name,
  fetchData,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();

  useEffect(() => {
    const chart: XYChart = create(chartRef?.current || '', XYChart);
    chart.padding(12, 0, 0, 0);

    fetchData().then((data: TrendRequestDataItemType[]) => {
      const isNoData = data.length === 0;

      if (isNoData) {
        const DEFAULT_DATA: TrendRequestDataItemType[] = Array(7)
          .fill(0)
          .map((_, index) => ({
            date: moment().subtract(index, 'day').format(),
            ...series.reduce((acc, item) => ({ ...acc, [item]: MIN_EMPTY_LINECHART_VALUE + index * 10 }), {}),
          }));

        chart.data = DEFAULT_DATA;
      } else {
        chart.data = data;
      }
      if (title) {
        LineChartTitle(chart, title, legend);
      }

      const dateAxis = LineChartDateAxis(chart);
      const valueAxis = LineChartValueAxis(chart);

      chart.colors.list = Object.values<string>(colors).reduce<Color[]>((acc, value) => {
        acc.push(color(value));
        return acc;
      }, []);

      series.forEach((field, index) => {
        const Line = chart.series.push(new LineSeries());
        Line.dataFields.valueY = field;
        Line.dataFields.dateX = 'date';
        Line.hidden = isNoData;
        Line.start = 0;
        Line.strokeWidth = 2;
        Line.yAxis = valueAxis;
        const lineName = name ? name : RequestStatusIconsProps[field as keyof typeof RequestStatusIconsProps]?.label;
        Line.name = lineName ? lineName : field;
        if (Line.tooltip) {
          if (index === 0) {
            LineChartTooltip(Line, series, colors, tooltip, classes);
          }
        }
        Line.tensionX = 0.85;
        Line.showOnInit = true;

        LineChartCursor(chart, dateAxis, Line);
      });

      if (legend) {
        LineChartLegend(chart, series, isNoData);
      }

      chart.seriesContainer.draggable = false;
      chart.seriesContainer.resizable = false;
    });

    return () => {
      if (chart) {
        chart.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, legend, title, xTitle, yTitle, colors, classes, tooltip, series, name]);

  return <div className={classes.root} ref={chartRef} />;
};
