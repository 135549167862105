import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    color: theme.vars.palette.bigid?.gray700,
  },
  text: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  systemWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    height: 320,
    '& div': {
      margin: '0 7px 35px 0',
    },
  },
  systemsLabel: {
    width: '100%',
    padding: '25px 0 20px',
    fontSize: '0.9375rem',
    display: 'block',
  },
  seeAll: {
    display: 'flex',
    alignItems: 'center',
    padding: '25px 0 20px',
    fontSize: '0.8125rem',
    textDecoration: 'underline',
    cursor: 'pointer',
    '& svg': {
      marginLeft: 7,
    },
  },
  expert: {
    display: 'flex',
    alignItems: 'center',
    margin: '-40px 0 40px',
    fontSize: '0.8125rem',
    '& svg': {
      marginRight: 10,
    },
  },
  allSystemsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
