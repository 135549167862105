import React, { FC } from 'react';
import { BigidColorsV2, BigidLoader } from '@bigid-ui/components';
import { LoaderProps } from '@bigid-ui/components/lib/BigidLoader/BigidLoader';

export const Loader: FC<LoaderProps> = ({
  label = 'Loading...',
  color = BigidColorsV2.gray[500],
  labelColor = BigidColorsV2.gray[500],
  labelPosition,
  size = 70,
  thickness = 3.6,
  position,
}) => (
  <BigidLoader
    color={color}
    label={label}
    labelColor={labelColor}
    labelPosition={labelPosition}
    size={size}
    thickness={thickness}
    position={position}
  />
);
