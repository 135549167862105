export enum AnalyticModuleType {
  Editor = 'PRIVACYPORTAL_EDITOR',
  Dashboard = 'PRIVACYPORTAL_DASHBOARD',
  Requests = 'PRIVACYPORTAL_REQUESTS',
  Systems = 'PRIVACYPORTAL_SYSTEMS',
  ConsentAndPreferences = 'PRIVACYPORTAL_CONSENT_AND_PREFERENCES',
  CookieConsent = 'PRIVACYPORTAL_COOKIE_CONSENT',
  Other = 'PRIVACYPORTAL_OTHER',
}

export enum AnalyticEventType {
  UpdateRequest = 'PRIVACYPORTAL_UPDATE_REQUEST',
}

export enum UpdateEventActionType {
  VerifyAgent = 'verify_agent',
  Verify = 'verify',
  Approve = 'approve',
  Reject = 'reject',
  BackStage = 'back_stage',
  NextStage = 'next_stage',
  Done = 'done',
  CompleteUserPreferences = 'complete_user_preferences',
  Close = 'close',
}

export type AnalyticsConfigType = {
  serverUrl: string;
  apiKey: string;
  enabled: boolean;
  environment: string;
  tenantId: string;
  companyName: string;
  userId?: number;
};

export type MonitoringConfigType = {
  enabled: boolean;
  applicationIdPrivacyPortalApp: string;
  applicationIdConsumerApp: string;
  clientTokenPrivacyPortalApp: string;
  clientTokenConsumerApp: string;
  ddVersion: string;
};

export type AnalyticsAndConfigType = {
  analyticsConfig: AnalyticsConfigType;
  monitoringConfig: MonitoringConfigType;
};
