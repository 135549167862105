import React, { FC } from 'react';
import { map } from 'lodash';
import { OnboardingStepType } from '../../../types';

import { useStyles } from './RegionStepStyles';
import { BigidCheckbox, BigidDropdown, BigidFormFieldLabelWrapper } from '@bigid-ui/components';
import { countriesToDropdownOptions } from '../../../utils/countriesUtils';
import { stringToDropdownValue } from '../../../utils/dropdownUtils';
import { getRegionIcon } from '../../../utils';

export const RegionStep: FC<OnboardingStepType> = ({ data: { regions }, handleDataChange }) => {
  const classes = useStyles();

  if (!regions) return <></>;

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        The location of your organization and of your customers defines your compliance needs
      </div>
      <div className={classes.dropdown}>
        <BigidFormFieldLabelWrapper id="headquarters" label="Our headquarters are in">
          <BigidDropdown
            value={stringToDropdownValue(regions.headquarters)}
            options={countriesToDropdownOptions(regions.headquartersOptions)}
            onSelect={values => {
              handleDataChange('regions', {
                headquarters: values[0]?.value || '',
                regionsOptions: regions.regionsOptions,
                headquartersOptions: regions.headquartersOptions,
              });
            }}
            placeholder="e.g. Canada"
            isSearchable
            dataAid="onboarding-headquarters-dropdown"
          />
        </BigidFormFieldLabelWrapper>
      </div>

      <div className={classes.regionLabel}>Our customers are in</div>
      <div className={classes.regions}>
        {map(regions.regionsOptions, ({ id, iso, name, enabled }) => (
          <BigidCheckbox
            key={id}
            label={
              <div className={classes.label}>
                {!!iso && getRegionIcon(iso)}
                <span className={classes.iso}>{name}</span>
              </div>
            }
            checked={enabled}
            onChange={() => {
              handleDataChange('regions', {
                headquarters: regions.headquarters || '',
                regionsOptions: regions.regionsOptions!.map(option =>
                  id === option.id ? { ...option, enabled: !enabled } : option,
                ),
                headquartersOptions: regions.headquartersOptions,
              });
            }}
            dataAid="onboarding-regulations-checkbox"
          />
        ))}
      </div>
    </div>
  );
};
