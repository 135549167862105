import React, { useState, useEffect } from 'react';
import { getMapChardData } from '../../services/request';
import { RegulationType } from '../../types/RegulationsTypes';
import { MeMapChart } from '../../components/MeMapChart';
import { TrendsRequestsOptionsType, StatisticPeriodValuesType, MapChartItemDataType } from '../../types/DashboardTypes';

import { useStyles } from './MapChartDashboardStyles';

export const MapChartDashboard = ({
  regulation = '',
  statisticsPeriod = StatisticPeriodValuesType.week,
}: {
  regulation: RegulationType;
  statisticsPeriod: StatisticPeriodValuesType;
}) => {
  const classes = useStyles();
  const [mapData, setMapData] = useState<MapChartItemDataType[]>();

  useEffect(() => {
    fetchMapChartData({ period_days: statisticsPeriod, regulation });
  }, [statisticsPeriod, regulation]);

  const fetchMapChartData = async (options: TrendsRequestsOptionsType) => {
    const data = await getMapChardData(options);
    setMapData(data);
  };

  return (
    <div className={classes.trendWrapper}>
      <div className={classes.trendHeader}>
        <h4 className={classes.trendTitle}>Requests Location</h4>
      </div>
      <div className={classes.mapRoot}>{!!mapData && <MeMapChart height={189} data={mapData} />}</div>
    </div>
  );
};
