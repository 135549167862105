import React, { FC } from 'react';
import { Alert, Box } from '@mui/material';
import { flow, isEmpty, keyBy, omit } from 'lodash';
import { FormikErrors } from 'formik';
import { LanguageType } from '@consumer/state/languageContainer';
import { LocaleType } from '../../types';

export const FormLanguageGeneralErrorComponent: FC<{
  fieldName: string;
  fieldLabel: string;
  errors?: FormikErrors<Partial<Record<LanguageType, unknown>>>;
  availableLanguages: LocaleType[];
  selectedLanguage: LanguageType;
}> = ({ errors, fieldName, fieldLabel, availableLanguages, selectedLanguage }) => {
  const localeMap = keyBy(availableLanguages, 'code');

  const filteredErrors =
    errors &&
    flow([
      obj => omit(obj, [selectedLanguage]),
      Object.entries,
      arr => arr.filter(([, value]: [number, Record<string, string>]) => value?.[fieldName]),
      Object.fromEntries,
    ])(errors);

  return !isEmpty(filteredErrors) ? (
    <Box mb={2} data-aid={`language-general-error-${fieldName}`}>
      <Alert severity="error">{`${fieldLabel} field must not be empty for all languages (${Object.keys(filteredErrors)
        .map(code => localeMap[code]?.name)
        .join(', ')})`}</Alert>
    </Box>
  ) : null;
};
