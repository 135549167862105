import React from 'react';

import { ReactComponent as TutorialCompletedHeader } from '../../assets/illustrations/tutorial-completed-header.svg';

import { useStyles } from './TutorialStyles';
import { SecondaryButton } from '@bigid-ui/components';

type ConfirmationPopoverType = {
  onConfirm: () => void;
};

export const ConfirmationPopover = ({ onConfirm }: ConfirmationPopoverType) => {
  const classes = useStyles();

  return (
    <div className={classes.popover}>
      <TutorialCompletedHeader />
      <div className={classes.confirmPopoverContent}>
        <div className={classes.contentTitle}>Great Job!</div>
        <div className={classes.confirmPoverText}>You have completed the set up!</div>
        <SecondaryButton size="medium" onClick={onConfirm} text="Finish" />
      </div>
    </div>
  );
};
