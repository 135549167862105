import React, { FC } from 'react';
import { AuthorizedAgentGuidedTourStepTemplate } from './AuthorizedAgentGuidedTourStepTemplate';

interface RequestorTypeStepProps {
  dataAid?: string;
}

export const RequestorTypeStep: FC<RequestorTypeStepProps> = ({ dataAid }) => {
  return (
    <AuthorizedAgentGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Select Authorized Agent requestor type"
      firstSectionText='Toggle on to enable the "Requestor Type" step in the request intake form.'
    />
  );
};
