import { api } from '../apiService';
import { VerificationProviderType } from '../verification-questionnaire/types';
import { BigidMeSchemeCustomVars } from '../../assets/theme';
import { TranslationsType } from '../../utils';

const getSettings = async ({
  publishName = '',
  language = '',
  requestId,
}: {
  publishName: string;
  language: string;
  requestId?: string;
}): Promise<TenantSettings> =>
  api
    .get<TenantSettings>(
      `/settings?${requestId ? `requestId=${requestId}` : `publishName=${publishName}`}&locale=${language}`,
    )
    .then(res => res.data);

export const tenantService = {
  getSettings,
};

export interface TenantSettings {
  id: string;
  name: string;
  logoUrl: string;
  logoWidth: string | number;
  logoHeight: string | number;
  supportLink: string;
  termsConditionsLink: string;
  privacyPolicyLink: string;
  whitelabelBigidmeIcon: boolean;
  captchaOn: boolean;
  smsValidationOn: boolean;
  verificationProvider?: VerificationProviderType;
  translations: TranslationsType;
  theme: BigidMeSchemeCustomVars;
  analyticsConfig: {
    enabled: boolean;
    apiKey: string;
    serverUrl: string;
    environment: string;
    tenantId: string;
    companyName: string;
  };
  monitoringConfig: {
    enabled: boolean;
    applicationIdPrivacyPortalApp: string;
    applicationIdConsumerApp: string;
    clientTokenPrivacyPortalApp: string;
    clientTokenConsumerApp: string;
    ddVersion: string;
  };
}
