import React, { FC, useCallback, useEffect, useState, MouseEvent } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useStyles } from './MaskedValueStyles';
import { useTheme } from '@mui/styles';

export interface MaskedValuePropsType {
  value: string;
  originalValue: string;
}

export const MaskedValue: FC<MaskedValuePropsType> = ({ value, originalValue }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [unmasked, setUnmasked] = useState(false);

  useEffect(() => {
    let timeoutId: number;
    if (unmasked) {
      timeoutId = window.setTimeout(() => {
        setUnmasked(false);
      }, 10000);
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [unmasked]);

  const handleClick = useCallback((e: MouseEvent<Element>) => {
    e.stopPropagation();
    setUnmasked(true);
  }, []);

  return (
    <>
      {unmasked ? (
        originalValue
      ) : (
        <>
          {value}
          <VisibilityIcon
            htmlColor={theme.vars.palette.bigid?.gray500}
            className={classes.icon}
            onClick={handleClick}
          />
        </>
      )}
    </>
  );
};
