import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { HttpRequestMethodType, WebhookType, WebhooksReasonType } from '../../../types';
import { fetchStageWebhooks, fetchWebhooksAutocomplete } from '../../../services/settingsService';
import { noop } from 'lodash';
import { useFormik } from 'formik';
import { webhooksValidationSchema } from '../../../utils';
export const NEW_WEBHOOK_ID = -1;

export const WEBHOOK_DEFAULT = {
  id: -1,
  name: '',
  trigger: WebhooksReasonType.STAGE_START,
  enabled: true,
  method: HttpRequestMethodType.GET,
  url: '',
  body: '',
  validateSsl: false,
  retryTimeout: 'PT72H',
  modificationDate: '',
  headers: [],
  parameters: [],
};

export const useWebhookCustomization = () => {
  const [webhooks, setWebhooks] = useState<WebhookType[]>();
  const [webhookTemplate, setWebhookTemplate] = useState<WebhookType>(WEBHOOK_DEFAULT);
  const [webhookTemplateId, setWebhookTemplateId] = useState<number>();
  const [webhookPlaceholders, setWebhookPlaceholders] = useState<{ value: string; label: string }[]>();

  const formik = useFormik<WebhookType>({
    initialValues: webhookTemplate,
    enableReinitialize: true,
    validationSchema: webhooksValidationSchema,
    onSubmit: noop,
  });

  useEffect(() => {
    fetchWebhookPlaceholders();
  }, []);

  const reloadWebhooks = async (stageId: number) => {
    const webhooks = await fetchStageWebhooks(stageId);
    setWebhooks(webhooks);
  };

  async function fetchWebhookPlaceholders() {
    const data = await fetchWebhooksAutocomplete();
    setWebhookPlaceholders(data);
  }

  return {
    webhooks,
    formikWebhook: formik,
    webhookTemplateId,
    webhookPlaceholders,
    setWebhookTemplate,
    setWebhookTemplateId,
    reloadWebhooks,
  };
};

export const WebhookCustomizationContainer = createContainer(useWebhookCustomization);
