import React, { FC } from 'react';

import { Root, classes } from './UploadedFilesStyles';
import { FileType, FileErrorType } from '../../types';
import { BigidFileIcon } from '@bigid-ui/icons';
import { BigidChip, BigidColorsV2 } from '@bigid-ui/components';

export type UploadedFilesPropsType = {
  files: FileType[];
  uploadErrors?: FileErrorType[];
  maxFiles: number;
  maxFileSize: number;
  onRemoveFile: (id: string) => void;
  onRemoveUploadError: (id: string) => void;
};

export const UploadedFiles: FC<UploadedFilesPropsType> = ({
  files,
  uploadErrors = [],
  maxFiles,
  maxFileSize,
  onRemoveFile,
  onRemoveUploadError,
}) => {
  return (
    <>
      <Root>
        {files.map(({ fileName, id }) => (
          <BigidChip
            key={id}
            label={fileName}
            icon={<BigidFileIcon color="info" />}
            onDelete={() => onRemoveFile(id)}
            variant="outlined"
            bgColor={BigidColorsV2.white}
            outlineColor={BigidColorsV2.gray[500]}
          />
        ))}
        {uploadErrors.map(({ fileName, error }) => (
          <BigidChip
            key={fileName}
            label={fileName}
            icon={<BigidFileIcon color="info" />}
            onDelete={() => onRemoveUploadError(fileName)}
            variant="outlined"
            bgColor={BigidColorsV2.red[400]}
            outlineColor={BigidColorsV2.gray[500]}
            tooltipProps={{
              title: error,
            }}
          />
        ))}
      </Root>
      {!!uploadErrors.length ||
        (files.length > maxFiles && (
          <div className={classes.errorHint}>
            No more than {maxFiles} files of {maxFileSize} MB each allowed.
          </div>
        ))}
    </>
  );
};
