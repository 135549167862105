import React, { FC, useEffect, useRef } from 'react';

import { useTheme, Optional, create } from '@amcharts/amcharts4/core';
import { MapChart, projections } from '@amcharts/amcharts4/maps';

import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { setImageSeries, setBullets, setPolygonSeries, setZoomControl } from './utils';
import { MapChartItemDataType } from '../../types/DashboardTypes';
import { BigidColorsV2 } from '@bigid-ui/components';

interface MapChartPropsType {
  data: MapChartItemDataType[];
  height?: number;
}

export const MeMapChart: FC<MapChartPropsType> = ({ data, height = 400 }) => {
  const chartRef = useRef<Optional<any>>();

  useTheme(am4themes_animated);

  useEffect(() => {
    const chart = create(chartRef.current, MapChart);

    if (!chart) return;

    chart.geodata = am4geodata_worldLow;
    chart.projection = new projections.Miller();
    setPolygonSeries(chart);
    setZoomControl(chart);
    const imageSeries = setImageSeries(chart);
    setBullets(imageSeries);
    imageSeries.data = data;

    return () => {
      chart.dispose();
    };
  }, [data]);
  return (
    <div ref={chartRef} style={{ width: '100%', height, borderRadius: '12px', background: BigidColorsV2.gray[150] }} />
  );
};
