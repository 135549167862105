import React, { FC, ReactElement } from 'react';
import { BigidTooltip } from '@bigid-ui/components';

import { DatasourcesConnectionStatuses } from '../../types';
import { useStyles } from './SystemWrapperStyles';

type SystemWrapperPropsType = {
  stagedForDeletion?: boolean;
  updateRequired?: boolean;
  connectionStatus?: DatasourcesConnectionStatuses;
  children: ReactElement;
  name?: string;
};

export const SystemWrapper: FC<SystemWrapperPropsType> = ({
  stagedForDeletion,
  updateRequired,
  connectionStatus,
  children,
  name,
}) => {
  const classes = useStyles();

  let tooltip;

  if (stagedForDeletion) {
    tooltip = 'The system was marked for deletion';
  } else if (connectionStatus === DatasourcesConnectionStatuses.FAILED) {
    tooltip = 'The update could not be completed. Try again or update manually.';
  } else if (updateRequired) {
    tooltip = `A new version of ${name} is available`;
  }

  const isDisabled = stagedForDeletion;

  return tooltip ? (
    <BigidTooltip
      title={tooltip}
      className={classes.tooltip}
      // should be testid, for findAllBy, not dataAid
      data-aid={isDisabled ? 'disabledSystemWrapper' : undefined}
    >
      <div style={{ opacity: isDisabled ? 0.5 : 1 }}>{children}</div>
    </BigidTooltip>
  ) : (
    children
  );
};
