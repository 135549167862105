import { useCallback } from 'react';
import { useBeforeUnload } from 'react-router-dom';

export const usePreventLeavePage = (when = false) => {
  useBeforeUnload(
    useCallback(
      e => {
        if (when) {
          e.preventDefault();
          return (e.returnValue = '');
        }
      },
      [when],
    ),
  );
};
