import React, { FC } from 'react';
import { AppealGuidedTourStepTemplate } from './AppealGuidedTourStepTemplate';

interface UseCasesAccordionAppealToggleStepProps {
  dataAid?: string;
}

export const UseCasesAccordionAppealToggleStep: FC<UseCasesAccordionAppealToggleStepProps> = ({ dataAid }) => {
  return (
    <AppealGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Enable Appeal request type"
      firstSectionText="Toggle on the Appeal use case"
    />
  );
};
