import { RequestStatusType, RequestsByStatusType } from '../../types';
import { RequestStatusIconsProps } from '../../utils';
import { BarChartDataItemType } from '@bigid-ui/components';

export const normalizeChartDataRequestsByStatus = (requestsByStatus: RequestsByStatusType) => ({
  total: requestsByStatus.total,
  data: requestsByStatus.data.map(({ value, type }) => ({
    value,
    category: RequestStatusIconsProps[type as RequestStatusType].label,
    color: RequestStatusIconsProps[type as RequestStatusType].color,
  })),
});

export const normalizeChartDataRequestsByTypes: (requestsByTypes: any) => BarChartDataItemType[] = requestsByTypes =>
  requestsByTypes &&
  requestsByTypes.map((item: any) => {
    const keys = Object.keys(item).filter(item => item !== 'type');
    const attributes = keys.reduce(
      (acc, key) => ({ ...acc, [RequestStatusIconsProps[key as RequestStatusType]?.label || key]: item[key] }),
      {},
    );
    return {
      ...attributes,
      category: RequestStatusIconsProps[item.type as RequestStatusType].label,
    };
  });
