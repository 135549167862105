import React, { FC } from 'react';

import { StyledBackButton } from './BackButtonStyles';

export type BackButtonPropsType = {
  onClick: () => void;
  testId?: string;
};

export const BackButton: FC<BackButtonPropsType> = ({ onClick, testId }) => (
  <StyledBackButton.Button data-aid={testId} onClick={onClick}>
    <StyledBackButton.Icon dataAid="KeyboardBackspaceIcon" />
  </StyledBackButton.Button>
);
