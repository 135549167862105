import React, { FC, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
// eslint-disable-next-line import/named
import { List, ListRowProps } from 'react-virtualized';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import cx from 'classnames';
import { BigidMeTooltipWithIcon } from '../BigidMeTooltipWithIcon';

import { useStyles } from './MeCountrySelectStyles';
import { getCountries } from '../../services/settingsService';
import { useFetch } from '../../hooks';
import { CountryType } from '../../types';

export interface MeCountrySelectPropsType {
  value: FlagIconCode | '';
  onChange: (option: { value: string; label?: string }) => void;
  tip?: string;
  label?: string;
  placeholder?: string;
  width?: string | number;
  margin?: string | number;
  className?: string;
  dataAttr?: Record<string, string>;
}

export const MeCountrySelect: FC<MeCountrySelectPropsType> = ({
  value = '',
  onChange,
  tip,
  label = 'Country of Residence',
  placeholder = '',
  width = '100%',
  margin,
  className,
  dataAttr,
}) => {
  const { data: countriesList } = useFetch({
    fetchApi: getCountries,
    initValue: [],
  });
  const [isOpened, setIsOpened] = useState(false);
  const [currentValue, setCurrentValue] = useState<{ value: string | ''; label: string }>({
    value,
    label: '',
  });
  const classes = useStyles();

  const handleChange = (option: CountryType) => {
    setIsOpened(false);
    setCurrentValue({ value: option.code, label: option.displayName });
    onChange({ value: option.code, label: option.displayName });
  };

  const rowRenderer = ({ index, key, style }: ListRowProps) => {
    const country = countriesList[index];

    return (
      <MenuItem
        className={classes.menuItem}
        disabled={currentValue.value === country.code}
        value={country.code}
        key={key}
        style={style}
        onClick={() => handleChange(country)}
      >
        <FlagIcon code={country.code as FlagIconCode} size={24} />
        &nbsp;
        {country.displayName}
      </MenuItem>
    );
  };

  return (
    <label className={classes.root} style={{ width, margin }}>
      <div className={classes.label}>{label}</div>
      {tip && <BigidMeTooltipWithIcon title={tip} />}
      <Select
        value={currentValue.value}
        className={cx(className, classes.input)}
        open={isOpened}
        onClose={() => setIsOpened(false)}
        onOpen={() => setIsOpened(true)}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            className: classes.menu,
          },
        }}
        inputProps={dataAttr}
      >
        {!currentValue.value ? (
          <MenuItem className={cx(classes.menuItem, classes.menuItemSelected)} disabled value={''}>
            {placeholder}
          </MenuItem>
        ) : (
          <MenuItem className={cx(classes.menuItem, classes.menuItemSelected)} disabled value={currentValue.value}>
            <FlagIcon code={currentValue.value as FlagIconCode} size={24} />
            &nbsp;
            {currentValue.label || countriesList.find(({ code }) => code === currentValue.value)?.displayName}
          </MenuItem>
        )}
        <List width={300} height={500} rowHeight={30} rowCount={countriesList.length} rowRenderer={rowRenderer} />
      </Select>
    </label>
  );
};
