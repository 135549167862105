import { BigidColorsV2 } from '@bigid-ui/components';

export const chartColors = {
  chipValueColor: BigidColorsV2.gray[600],
  totalLabelColor: BigidColorsV2.gray[600],
  valueAxisLabelColor: BigidColorsV2.gray[400],
  cursorColor: BigidColorsV2.gray[300],
};

export const BigidMeChartsColorsByLabels = {
  'In Progress': BigidColorsV2.green[400],
  'Almost Overdue': BigidColorsV2.orange[400],
  Overdue: BigidColorsV2.red[400],
};

export const BigidMeChartsColors = {
  InProgress: BigidColorsV2.green[400],
  AboutOverdue: BigidColorsV2.orange[400],
  Overdue: BigidColorsV2.red[400],
};

export const SingleColor = {
  value: BigidColorsV2.purple[400],
};

export const RejectedRequestsColors = {
  auto: BigidColorsV2.green[400],
  manual: BigidColorsV2.purple[400],
};

export const RequestsByTypeColors = {
  view: BigidColorsV2.purple[500],
  preferences: BigidColorsV2.purple[300],
  delete: BigidColorsV2.cyan[300],
  update: BigidColorsV2.blue[300],
  optOut: BigidColorsV2.blue[500],
};
