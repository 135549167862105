import React, { FC } from 'react';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/stepper_completed.svg';

import { useStyles } from './MeCopyTileStyles';

export const MeCopyTile: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CheckmarkIcon className={classes.tooltipIcon} /> Copied!
    </div>
  );
};
