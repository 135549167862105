import { useEffect } from 'react';
import { subscribeOnNotesRefreshEvent, unsubscribeOnNotesRefreshEvent } from './events';

export const useRefreshNotesEvent = (callback: () => void) => {
  useEffect(() => {
    subscribeOnNotesRefreshEvent(callback);
    return () => {
      unsubscribeOnNotesRefreshEvent(callback);
    };
  });
};
