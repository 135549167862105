import React, { FC, useCallback, useEffect } from 'react';
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { authorizedAgentTourSelectors, authorizedAgentTourSteps } from './steps';
import { BigidGuidedTour } from '@bigid-ui/guided-tour';
import { useLocation } from 'react-router-dom';
import { NewFeatureContainer } from '../../state/newFeatureContainer';
import { updateUserViewedFeatures } from '../../services/user/userService';
import { AuthorizedAgentGuidedTourContainer } from '../../state/authorizedAgentGuidedTourContainer';
import { ReleasedFeatureCodeType } from '../../types/ReleasedFeaturesTypes';

export const AuthorizedAgentGuidedTour: FC = () => {
  const { isAuthorizedAgentEnabled, setNewFeaturesList, setIsAuthorizedAgentEnabled } =
    NewFeatureContainer.useContainer();

  const {
    isAuthorizedAgentTourRunning: isTourRunning,
    setIsAuthorizedAgentTourRunning: setIsTourRunning,
    authorizedAgentTourStepIndex: stepIndex,
    setAuthorizedAgentTourStepIndex: setStepIndex,

    setAuthorizedAgentTourStartedFrom,
    authorizedAgentTourStartedFrom,
  } = AuthorizedAgentGuidedTourContainer.useContainer();

  const location = useLocation();

  const editorPage = location.pathname.includes('editor');
  const brandsPage = location.pathname.includes('brands');

  useEffect(() => {
    const startTourIfRequirementsSatisfied = async (): Promise<void> => {
      if (isAuthorizedAgentEnabled && (brandsPage || editorPage)) {
        setIsTourRunning(true);

        if (editorPage && !authorizedAgentTourStartedFrom) {
          setAuthorizedAgentTourStartedFrom('editor');
        }
        if (brandsPage && !authorizedAgentTourStartedFrom) {
          setAuthorizedAgentTourStartedFrom('brands');
        }
      }
    };

    startTourIfRequirementsSatisfied();
  }, [
    brandsPage,
    editorPage,
    setIsTourRunning,
    isAuthorizedAgentEnabled,
    setAuthorizedAgentTourStartedFrom,
    authorizedAgentTourStartedFrom,
  ]);

  const onClickEditBrandButton = useCallback(() => {
    setIsTourRunning(false);
    setTimeout(() => {
      setStepIndex(2);
      setIsTourRunning(true);
      // waiting when the open Edit page
    }, 2500);
  }, [setIsTourRunning, setStepIndex]);

  const onClickRequestorTypeToggle = useCallback(() => {
    // waiting when open accordion
    setTimeout(() => {
      setStepIndex(3);
    }, 50);
  }, [setStepIndex]);

  const onClickAuthorizedAgentTab = () => {
    // waiting when open accordion
    setTimeout(() => {
      setStepIndex(5);
    }, 50);
  };

  const onClickFormsTab = () => {
    // waiting when open form accordion
    setTimeout(() => {
      setStepIndex(7);
    }, 50);
  };

  const editBrandButton = document.querySelector(authorizedAgentTourSelectors.BRANDS_EDIT_BUTTON) as HTMLElement;
  const requestorTypeToggle = document.querySelector(
    authorizedAgentTourSelectors.REQUESTOR_TYPE_ACCORDION_TOGGLE,
  ) as HTMLElement;
  const authorizedAgentTab = document.querySelector(authorizedAgentTourSelectors.AUTHORIZED_AGENT_TAB) as HTMLElement;
  const formsTab = document.querySelector(authorizedAgentTourSelectors.FORM_TAB) as HTMLElement;

  useEffect(() => {
    if (isTourRunning && stepIndex <= 2) {
      requestorTypeToggle?.addEventListener('click', onClickRequestorTypeToggle);
    }

    if (isTourRunning) {
      editBrandButton?.addEventListener('click', onClickEditBrandButton);
      authorizedAgentTab?.addEventListener('click', onClickAuthorizedAgentTab);
      formsTab?.addEventListener('click', onClickFormsTab);
    }

    return () => {
      editBrandButton?.removeEventListener('click', onClickEditBrandButton);
      authorizedAgentTab?.removeEventListener('click', onClickAuthorizedAgentTab);
      formsTab?.removeEventListener('click', onClickFormsTab);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    stepIndex,
    isTourRunning,
    setIsTourRunning,
    onClickEditBrandButton,
    onClickRequestorTypeToggle,
    editBrandButton,
    authorizedAgentTab,
    formsTab,
    requestorTypeToggle,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const getNextStepIndex = useCallback(
    ({ action, index }: CallBackProps) => {
      const dispatchClickEventOnElementByQuerySelector = (selector: string): void => {
        const targetElement = document.querySelector(selector) as HTMLElement;
        const clickEvent = new MouseEvent('click', { bubbles: true });
        targetElement?.dispatchEvent(clickEvent);
      };
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (action === ACTIONS.PREV) {
        if (index === 3) {
          dispatchClickEventOnElementByQuerySelector(authorizedAgentTourSelectors.REQUESTOR_TYPE_ACCORDION_TOGGLE);
          return nextStepIndex;
        } else if (index === 5) {
          dispatchClickEventOnElementByQuerySelector(authorizedAgentTourSelectors.GENERAL_TAB);
          return nextStepIndex;
        } else if (index === 7) {
          dispatchClickEventOnElementByQuerySelector(authorizedAgentTourSelectors.GENERAL_TAB);
          setTimeout(() => {
            dispatchClickEventOnElementByQuerySelector(authorizedAgentTourSelectors.REQUESTOR_TYPE_ACCORDION_TOGGLE);
          }, 1000);
          return 0;
        }
      }
      if (action === ACTIONS.NEXT) {
        if (index === 2) {
          requestorTypeToggle?.removeEventListener('click', onClickRequestorTypeToggle);
        }
      }
      return nextStepIndex;
    },
    [requestorTypeToggle, onClickRequestorTypeToggle],
  );

  const joyrideCallback = useCallback(
    (data?: CallBackProps) => {
      if (data) {
        const { status, type } = data;
        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
          setIsTourRunning(false);

          setNewFeaturesList([
            {
              code: ReleasedFeatureCodeType.AUTHORIZED_AGENT,
              viewed: true,
            },
          ]);
          setIsAuthorizedAgentEnabled(false);
          updateUserViewedFeatures(ReleasedFeatureCodeType.AUTHORIZED_AGENT);
        } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
          setStepIndex(getNextStepIndex(data));
        }
      }
    },
    [setIsTourRunning, setNewFeaturesList, setIsAuthorizedAgentEnabled, setStepIndex, getNextStepIndex],
  );

  return (
    <BigidGuidedTour
      run={isTourRunning}
      joyrideCallback={joyrideCallback}
      floaterProps={{
        hideArrow: true,
      }}
      styles={{
        options: {
          zIndex: 100000,
        },
      }}
      spotlightPadding={0}
      steps={authorizedAgentTourSteps()}
      stepIndex={stepIndex}
      disableCloseOnEsc
      disableOverlayClose
      disableScrollParentFix
      disableScrolling
      delay={1000}
    />
  );
};
