import { BigidColorsV2 } from '@bigid-ui/components/lib/assets/theme/BigidColorsV2';
import { styled } from '@mui/material';

export const AuthorizedAgentStartTooltipStyled = {
  Main: styled('div')({
    position: 'relative',
    width: 'fit-content',
    margin: 'auto',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    paddingBottom: '20px',
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  IconWrapper: styled('div')({
    position: 'absolute',
    right: 8,
    top: 8,

    '& svg path': {
      fill: BigidColorsV2.white,
    },
  }),
  Content: styled('div')({
    width: '520px',
    display: 'flex',
    justifyContent: 'center',
  }),
  ButtonsSection: styled('div')({
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row',
    width: '100%',
    columnGap: 6,
    marginTop: 32,
    padding: '0 48px',
  }),
  TopContent: styled('div')({
    background: 'radial-gradient(68.65% 143.24% at 23.17% 79.17%, #8047D4 0%, #253EAF 71.34%, #161596 100%)',
    padding: '48px 0',
    borderRadius: '8px 8px 0 0',
  }),
};
