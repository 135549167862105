/* eslint prettier/prettier: 0 */
export type NestedKeyOf<ObjectType = Record<string, string>> =
  // eslint-disable-next-line
  {
    [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
      ? // eslint-disable-next-line prettier/prettier
        `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]> extends infer U extends string ? U : never}`
      : `${Key}`;
  }[keyof ObjectType & (string | number)];

export enum ModalAlignments {
    LEFT = 'left',
    RIGHT = 'right'
}