import makeStyles from '@mui/styles/makeStyles';
import { chartColors, primaryBorder } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  statisticPeriods: {
    borderRadius: '20.5px !important',
    border: `1px solid ${theme.vars.palette.bigid?.gray300}`,
    backgroundColor: theme.vars.palette.bigid?.white,
    display: 'inline-block',
  },
  statisticsButton: {
    borderColor: 'transparent',
    color: theme.vars.palette.bigid?.gray700,
    textTransform: 'capitalize',
    '&:hover': {
      borderColor: 'transparent!important',
      borderRadius: '20.5px !important',
    },
    '&:not(:last-child), &:not(:first-child)': {
      borderRadius: '20.5px !important',
    },
    '&:not(:first-child)': {
      '& > span:first-child': {
        position: 'relative',
        '&:after': {
          content: '""',
          width: 1,
          height: 15,
          position: 'absolute',
          top: '50%',
          left: -17,
          transform: 'translateY(-50%)',
          backgroundColor: theme.vars.palette.bigid?.gray300,
        },
      },
    },
  },
  statisticsButtonActive: {
    border: primaryBorder,
    backgroundColor: theme.vars.palette.bigid?.gray150,
    boxShadow: '0 1px 4px 0 rgba(0,0,0,0.13)',
    color: chartColors.chipValueColor,
    fontWeight: 'bold',
    margin: '-1px 0',
    zIndex: 2,
    '&:not(:last-child), &:not(:first-child), &:last-child, &:first-child': {
      border: `${primaryBorder}!important`,
      borderRadius: '20.5px !important',
    },
    '&:first-child': {
      margin: '-1px 0 -1px -1px',
    },
    '&:last-child': {
      margin: '-1px -1px -1px 0',
    },
    '&:not(:first-child)': {
      '& > span:first-child': {
        '&:after': {
          display: 'none',
        },
      },
    },
    '&:disabled': {
      color: chartColors.chipValueColor,
    },
  },
}));
