import React, { useState, useEffect } from 'react';
import { BigidColorsV2, BigidHorizontalBar } from '@bigid-ui/components';
import { unionBy } from 'lodash';
import {
  StatisticPeriodValuesType,
  RegulationType,
  RequestsByUserProfileType,
  TrendsRequestsOptionsType,
  UserProfilesType,
  UserProfileType,
} from '../../types';
import { getRequestsByUserProfile } from '../../services/request';
import { getProfiles } from '../../services/onboardingService';

export const userProfilesPalette: { [key in UserProfileType]: string } = {
  User: BigidColorsV2.blue[300],
  Customer: BigidColorsV2.blue[200],
  Consumer: BigidColorsV2.purple[200],
  'Current Employee': BigidColorsV2.purple[300],
  'Former Employee': BigidColorsV2.purple[400],
};

import { useStyles } from './ProfileTypesDashboardStyles';

const fetchRequestsByUserProfiles = async (options: TrendsRequestsOptionsType, showProfiles?: boolean) => {
  const [{ total, profiles }, userProfiles] = await Promise.all([
    getRequestsByUserProfile(options),
    showProfiles ? getProfiles() : undefined,
  ]);

  return {
    total,
    profiles: unionBy(
      (profiles || []).map(item => ({ ...item, color: userProfilesPalette[item.type] })),
      (userProfiles || []).map(({ name }) => ({ type: name, value: 0 })) as UserProfilesType[],
      'type',
    ),
  };
};

export const ProfileTypesDashboard = ({
  regulation = '',
  statisticsPeriod = StatisticPeriodValuesType.week,
  showProfiles,
}: {
  regulation: RegulationType;
  statisticsPeriod: StatisticPeriodValuesType;
  showProfiles?: boolean;
}) => {
  const classes = useStyles();
  const [userProfiles, setUserProfiles] = useState<RequestsByUserProfileType>({
    total: 0,
    profiles: [],
  });

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchRequestsByUserProfiles({ period_days: statisticsPeriod, regulation }, showProfiles);
      setUserProfiles(data);
    };

    loadData();
  }, [statisticsPeriod, regulation, showProfiles]);

  return (
    <div className={classes.trendWrapper}>
      <div className={classes.trendHeader}>
        <h4 className={classes.trendTitle}>Profile Types</h4>
      </div>
      <div className={classes.horizontalBarRoot}>
        <BigidHorizontalBar
          values={userProfiles.profiles}
          forceTotal={userProfiles.total}
          totalLabel="Total requests"
        />
      </div>
    </div>
  );
};
