import { EventEmitter } from '@bigid-ui/utils';

export enum NotesEvents {
  NOTES_REFRESH_EVENT = 'notes:refresh',
}

export const notesEventEmitter = new EventEmitter<NotesEvents>();

export const triggerNotesRefreshEvent = () => {
  notesEventEmitter.emit(NotesEvents.NOTES_REFRESH_EVENT);
};

export const subscribeOnNotesRefreshEvent = (listener: () => void) => {
  notesEventEmitter.addEventListener(NotesEvents.NOTES_REFRESH_EVENT, listener);
};

export const unsubscribeOnNotesRefreshEvent = (listener: () => void) => {
  notesEventEmitter.removeEventListener(NotesEvents.NOTES_REFRESH_EVENT, listener);
};
