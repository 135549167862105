import { random } from 'lodash';
import { useCallback, useState } from 'react';
import { fileUploadRules } from '../configs/files';
import { FileErrorType, FileType, FileRuleType } from '../types';
import { useToggle } from './useToggle';

const KILOBYTE = 1024;

export const useFilesUpload = ({
  upload,
  rules: { MAX_FILES, MAX_FILE_SIZE_MB } = fileUploadRules.default,
}: {
  upload: (file: File) => Promise<string>;
  rules?: FileRuleType;
}) => {
  const [files, setFiles] = useState<FileType[]>([]);
  const [uploadErrors, setUploadErrors] = useState<FileErrorType[]>([]);
  const { value: uploading, setTrue: startUploading, setFalse: finishUploading } = useToggle(false);

  const handleFileUpload = useCallback(
    async (filesToUpload: File[]) => {
      if (!filesToUpload?.length) {
        return;
      }

      let uploadedFilesLength = files.length;

      for (let i = 0; i < filesToUpload.length; i++) {
        const file = filesToUpload[i];

        if (uploadedFilesLength === MAX_FILES) {
          const error = `You can upload up to ${MAX_FILES} files.`;

          setUploadErrors(prev => [...prev, { fileName: file.name, error }]);
        } else if (file.size / (KILOBYTE * KILOBYTE) > MAX_FILE_SIZE_MB) {
          const error = `Maximum file size: ${MAX_FILE_SIZE_MB}MB per file.`;

          setUploadErrors(prev =>
            prev.some(uploadError => uploadError.fileName === file.name)
              ? prev
              : [...prev, { fileName: file.name, error }],
          );
        } else {
          const tempId = random(0, 100000).toString();

          try {
            startUploading();
            const fileId = await upload(file);
            uploadedFilesLength++;

            setFiles(files => {
              const newFiles = [...files, { fileName: file?.name, id: fileId, isNew: true }];
              return newFiles;
            });
          } catch {
            const error = 'There was an error uploading this file.';

            setFiles(files => {
              const newFiles = files.filter(({ id }) => id !== tempId);
              return newFiles;
            });
            setUploadErrors(prev => [...prev, { fileName: file.name, error }]);
          } finally {
            finishUploading();
          }
        }
      }
    },
    [files.length, upload, MAX_FILES, MAX_FILE_SIZE_MB, finishUploading, startUploading],
  );

  const removeFile = useCallback((id: string) => {
    setFiles(files => {
      const newFiles = files.filter(file => file.id !== id);
      return newFiles;
    });
  }, []);

  const removeUploadError = useCallback((fileName: string) => {
    setUploadErrors(state => state.filter(error => error.fileName !== fileName));
  }, []);

  const resetFiles = useCallback(() => {
    setFiles([]);
  }, []);

  return {
    onFileUpload: handleFileUpload,
    files,
    uploadErrors,
    removeFile,
    removeUploadError,
    resetFiles,
    setFiles,
    uploading,
  };
};
