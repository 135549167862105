import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > div': {
      position: 'static',
      margin: '0 0 20px 0',
    },
  },
  mainLoader: {
    position: 'relative',
    height: 150,
    width: 150,
  },
  link: {
    cursor: 'pointer',
    color: theme.vars.palette.bigid?.blue700,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    fontSize: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
