import { ReactNode, FC } from 'react';
import { useSnackbar, WithSnackbarProps, OptionsObject } from 'notistack';

let snackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator: FC = () => {
  snackbarRef = useSnackbar();
  return null;
};

export const SnackbarUtils = {
  success(msg: ReactNode, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'success' });
  },
  warning(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'warning' });
  },
  info(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'info' });
  },
  error(msg: ReactNode, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'error' });
  },
  toast(msg: ReactNode, options: OptionsObject = {}) {
    snackbarRef?.enqueueSnackbar(msg, options);
  },
};
