import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/system';

const PREFIX = 'SendNotification';

export const classes = {
  select: `${PREFIX}-select`,
  dropdown: `${PREFIX}-dropdown`,
};

export const ScheduleEventPickerStyled = styled(Box)(() => ({
  width: '100%',
  [`& .${classes.select}`]: {
    height: '40px!important',
    '& > div': {
      minHeight: '40px!important',
    },
  },
}));

export const DropdownStyled = styled(Stack)({
  alignItems: 'center',
  width: 336,
  marginLeft: 20,
  marginRight: 8,
  marginTop: 8,

  '& > div > div > div': {
    minHeight: 32,
  },
});
