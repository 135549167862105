import { useCallback, useState } from 'react';

export const useToggle = (initial = false) => {
  const [value, setValue] = useState<boolean>(initial);

  const toggle = useCallback(() => {
    setValue(state => !state);
  }, []);

  const setTrue = useCallback(() => {
    setValue(true);
  }, []);

  const setFalse = useCallback(() => {
    setValue(false);
  }, []);

  return { value, setFalse, setTrue, toggle };
};
