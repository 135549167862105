import { createElement, useCallback } from 'react';
import { createContainer } from 'unstated-next';

import { SystemUpdateMessage } from './SystemUpdateMessage';

import { DatasourcesGridType } from '../../types';
import { getSystemsWithUpdateAvailable, markSystemUpdateMessageAknowledged } from '../../utils/dataSourceUtils';
import { useShowSnackbar } from '../../hooks/useShowSnackbar';
import { getDataSources } from '../../services/systemsService';

const useSystemUpdateMessage = () => {
  const { showSnackbarMessage } = useShowSnackbar();

  const handleFetchSystems = (systems: DatasourcesGridType[]) => {
    const systemsWithUpdateAvailable = getSystemsWithUpdateAvailable(systems);

    if (systemsWithUpdateAvailable.length) {
      let systemsList = systemsWithUpdateAvailable[0].name;

      if (systemsWithUpdateAvailable.length > 1) {
        const additionalSystemsLabel =
          systemsWithUpdateAvailable.length > 3 ? ` +${systemsWithUpdateAvailable.length - 3} more` : '';
        systemsList = `${systemsWithUpdateAvailable
          .slice(0, 3)
          .map(({ name }) => name)
          .join(', ')}${additionalSystemsLabel}`;
      }

      markSystemUpdateMessageAknowledged(systemsWithUpdateAvailable);

      showSnackbarMessage(createElement(SystemUpdateMessage, { systemsList }), {
        text: 'Update',
        to: systemsWithUpdateAvailable.length ? '/systems' : `/systems/${systemsWithUpdateAvailable[0].id}`,
        persist: true,
        manualClose: true,
      });
    }
  };

  const checkForSystemUpdates = useCallback(() => {
    getDataSources({}).then(handleFetchSystems);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return { checkForSystemUpdates };
};

export const SystemUpdateMessageContainer = createContainer(useSystemUpdateMessage);
