import React from 'react';
import { BigidBody1, BigidLoader } from '@bigid-ui/components';
import { Stack } from '@mui/material';

export const NoteFileLoader = () => {
  return (
    <Stack flexDirection="row" alignItems="center" mt={2}>
      <BigidLoader position="relative" size={20} thickness={3} />
      <BigidBody1 fontWeight={600} mx={1} flexGrow={1}>
        Uploading file...
      </BigidBody1>
    </Stack>
  );
};
