import React, { FC, useMemo } from 'react';
import { BigidColorsV2 } from '@bigid-ui/components';
import { Root, classes } from './SystemInfoMessageStyles';
import { BigidInfoFilledIcon } from '@bigid-ui/icons';
import { parseHTML } from '../../../utils';

export type SystemInfoMessageProps = {
  text: string;
  link?: string;
  linkText?: string;
};

export const SystemInfoMessage: FC<SystemInfoMessageProps> = ({ text, link, linkText }) => {
  const renderLink = useMemo(() => `<a href="${link}" target="_blank">${linkText || link}</a>`, [link, linkText]);
  const message = text.replace('{link}', renderLink);

  return (
    <Root>
      <div className={classes.container}>
        <div className={classes.icon}>
          <BigidInfoFilledIcon color={BigidColorsV2.purple[600]} />
        </div>
        <div>{parseHTML(message)}</div>
      </div>
    </Root>
  );
};
