import { OnboardingGetDataType, OnboardingStepContentType, OnboardingStepsType, StepperStates } from '../../types';
import { ConnectDataStep } from './ConnectDataStep';
import { RegionStep } from './RegionStep';
import { RegulationsStep } from './RegulationsStep';
import { RequestsFormStep } from './RequestsFormStep';
import { RequestsTypeStep } from './RequestsTypeStep';

export const onboardingSteps = [
  OnboardingStepsType.WELCOME_STEP,
  OnboardingStepsType.REGIONS,
  OnboardingStepsType.REGULATIONS,
  OnboardingStepsType.PRIVACY_CENTER_STEP,
  OnboardingStepsType.REQUEST_FORM,
  OnboardingStepsType.CONNECT_DATA,
  OnboardingStepsType.FINISH_STEP,
];

export const OnboardingHiddenStepperSteps = [
  OnboardingStepsType.WELCOME_STEP,
  OnboardingStepsType.REGULATIONS,
  OnboardingStepsType.REQUEST_FORM,
  OnboardingStepsType.FINISH_STEP,
];

export const defaultOnboardingValues: OnboardingGetDataType = {
  regions: { regionsOptions: [], headquarters: '', headquartersOptions: [] },
  regulations: { all: [], popular: [], preselected: [] },
  useCases: [],
  sourceSystems: { all: [], popular: [] },
};

export const getOnboardingTitle = (activeStep: number, noPreselectedRegions: boolean) => {
  if (noPreselectedRegions && activeStep === StepperStates.REGULATIONS) {
    return 'Select Regulations';
  }
  return onboardingStepContent[activeStep].title;
};

export const onboardingStepContent: OnboardingStepContentType = {
  [StepperStates.REGIONS]: {
    title: 'Where Do You Operate?',
    component: RegionStep,
    actions: {
      next: true,
      back: false,
      skip: true,
    },
  },
  [StepperStates.REGULATIONS]: {
    title: 'Confirm Regulations',
    component: RegulationsStep,
    actions: {
      next: true,
      back: true,
      skip: false,
    },
  },
  [StepperStates.PRIVACY_CENTER_STEP]: {
    title: 'Select Request Types',
    component: RequestsTypeStep,
    actions: {
      next: true,
      back: true,
      skip: false,
    },
  },
  [StepperStates.REQUEST_FORM]: {
    title: 'Your Privacy Center is Ready!',
    component: RequestsFormStep,
    actions: {
      next: true,
      back: true,
      skip: false,
    },
  },
  [StepperStates.CONNECT_DATA]: {
    title: 'Connect to Your Data',
    component: ConnectDataStep,
    actions: {
      next: true,
      back: true,
      skip: true,
    },
  },
};
