import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';
import styled from '@emotion/styled';
import { MeExtraKeyValueFieldsProps } from './MeExtraKeyValueFields';

export const useStyles = makeStyles({
  fieldWrapper: ({
    fieldsBottomOffset,
    alignCenter,
  }: Pick<MeExtraKeyValueFieldsProps, 'fieldsBottomOffset' | 'alignCenter'>) => ({
    display: 'flex',
    width: '100%',
    marginBottom: fieldsBottomOffset,
    ...(alignCenter
      ? {
          alignItems: 'center',
        }
      : {
          alignItems: 'end',
        }),
  }),
  deleteWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 35,
  },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    '&:hover': { background: 'transparent' },
  },
  fieldsWrapper: {
    width: '100%',
    height: '100%',
  },
  addMoreWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',

    '& button': {
      padding: 0,
    },
  },
  labelWrapper: ({ labelWidth }: Partial<MeExtraKeyValueFieldsProps>) => ({
    display: 'block',
    whiteSpace: 'nowrap',
    margin: '0 24px 0 0',
    lineHeight: 1,
    ...(labelWidth
      ? {
          minWidth: labelWidth,
          width: labelWidth,
        }
      : {}),
  }),
  divider: {
    margin: '0 16px',
    height: 24,
  },
  addMoreLabel: { marginLeft: 4, textTransform: 'none' },
  emptyTemplateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '18px',
    backgroundColor: BigidColorsV2.purple[50],
    gap: '30px',
    height: '100%',
    borderRadius: '4px',
  },
  emptyTemplateMoreTitle: {
    textAlign: 'center',
  },
});

export const ExtraFieldWrapper = styled('span')`
  display: flex;
  align-items: inherit;
  overflow: hidden;
  flex-direction: row;
  padding: 0px 6px;
`;
