import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidCheckbox,
  BigidFormFieldLabelWrapper,
  BigidHeading6,
  BigidSwitch,
  BigidTextField,
  PrimaryButton,
  SecondaryButton,
} from '@bigid-ui/components';
import { debounce, get } from 'lodash';
import cx from 'classnames';
import { LanguageType } from '@consumer/state/languageContainer';
import { ReactComponent as EmailTemplateExample } from '../../../assets/images/emailTemplateExample.svg';
import WorkflowEmailTemplate from '../WorkflowEmailTemplate';
import { Root, classes } from './StageEmailCustomizationStyles';
import { StageEmailCustomizationContainer } from '../hooks/useStageEmailCustomization';
import {
  ManualRequestResolutionsType,
  RequestWorkflowStageEmailCode,
  StageConfigType,
  StageEmailConfigurationType,
  UpdateStageSettingsPostType,
} from '../../../types';
import EmailTemplateControlsBar from '../EmailTemplateControlsBar';
import { BigidMeTooltipWithIcon, Loader } from '../../../components';
import { usePreventLeavePage } from '../../../hooks/usePreventLeavePage';
import { FormLanguageGeneralError } from '../../../components/FormLanguageGeneralError';
import { IconButton } from '@mui/material';
import { StageEmailAppealCustomization } from './StageEmailAppealCustomization';
import { FormikProps } from 'formik';
import { AvailableLanguagesContainer } from '../../../state/availableLanguagesContainer';
import { StopPropagationContainer } from '../../../components/StopPropagationContainer';
import { StageEmailVendorDeletion } from './StageEmailVendorDeletion';

const REQUEST_REJECTED_NAME_CONSTANT = 'Request rejected';
const VENDOR_REQUEST_BODY_TOOLTIP_TEXT = 'You may insert the following variables in the text: {{brandName}}.';

const FormLanguageGeneralErrorLabelsMap = [
  { field: 'title', label: 'Title' },
  { field: 'subject', label: 'Subject' },
  { field: 'header', label: 'Header' },
  { field: 'message', label: 'Message' },
  { field: 'appealText', label: 'Appeal text' },
];

interface StageEmailCustomizationPropsType {
  stageEmail: { configType: StageConfigType; config: StageEmailConfigurationType };
  onSaveEmailCustomization: () => void;
}

export const StageEmailCustomization: FC<StageEmailCustomizationPropsType> = ({
  stageEmail,
  onSaveEmailCustomization,
}) => {
  const { formikEmail, isLoading } = StageEmailCustomizationContainer.useContainer();
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageType>(LanguageType.EN);
  const [emailTemplateModalActive, setEmailTemplateModalActive] = useState(false);
  const { languages } = AvailableLanguagesContainer.useContainer();
  const isVendorRequestStageEmail = stageEmail.config.code === RequestWorkflowStageEmailCode.VENDOR_DELETION_REQUEST;

  usePreventLeavePage(formikEmail.dirty);

  const renderConfirmConfiguration = useCallback(
    () => (
      <>
        <div className={classes.inputWrapper}>
          Send {stageEmail.config.name.includes(REQUEST_REJECTED_NAME_CONSTANT) ? 'rejecting' : 'reminder'}
          <div className={classes.input}>
            <BigidTextField
              value={
                formikEmail.values.general.configuration.firstSendAfter?.match('[0-9]+') !== null
                  ? formikEmail.values.general.configuration.firstSendAfter?.match('[0-9]+')![0]
                  : '0'
              } // P7D - 7 days
              placeholder="days"
              type="number"
              size="medium"
              onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                formikEmail.setFieldValue(`general.configuration.firstSendAfter`, `P${event.target.value}D`)
              }
              dataAid="firstSendAfter__field"
            />
          </div>
          days after initial submission.
        </div>
      </>
    ),
    [formikEmail, stageEmail.config],
  );

  const renderManualResolutionConfiguration = useCallback(
    () => (
      <>
        <div className={cx(classes.inputWrapper, classes.closingResolutionName)}>
          <div className={classes.inputTooltipName}>
            <BigidMeTooltipWithIcon title="This text appears in Close Request dropdown and in the activity log" />
          </div>
          <BigidTextField
            required
            errorMessage={get(formikEmail.errors.manual && formikEmail.errors.manual.configuration, `name`)}
            value={formikEmail.values.manual.configuration.name}
            size="medium"
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              formikEmail.setFieldValue(`manual.configuration.name`, event.target.value)
            }
            label="Closing Resolution Name"
            dataAid="manual-resolution-name__field"
          />
        </div>
        <div className={cx(classes.inputWrapper, classes.progressBarText)}>
          <div className={classes.inputTooltipBar}>
            <BigidMeTooltipWithIcon title="This text appears in the progress bar and in notifications to the requestor" />
          </div>
          <BigidTextField
            label="Progress Bar Text"
            disabled={stageEmail.config.type === ManualRequestResolutionsType.REQUEST_COMPLETION}
            value={formikEmail.values['manual'].configuration.translations?.[selectedLanguage]?.userStatus}
            onChange={value =>
              formikEmail.setFieldValue(
                `manual.configuration.translations[${selectedLanguage}].userStatus`,
                value.target.value,
              )
            }
            dataAid="userStatus_field"
            size="medium"
            type="text"
          />
        </div>
        <div className={cx(classes.inputWrapper, classes.marginLeft)}>
          <BigidCheckbox
            checked={formikEmail.values.manual.configuration.defaultForStage}
            label="Set as Default"
            onChange={(event, checked) => formikEmail.setFieldValue(`manual.configuration.defaultForStage`, checked)}
          />
          <BigidMeTooltipWithIcon title="Set this as the default resolution in the closing resolutions dropdown" />
        </div>
      </>
    ),
    [formikEmail, selectedLanguage, stageEmail.config.type],
  );

  const renderAutoResolutionConfiguration = useCallback(
    () => (
      <>
        <BigidFormFieldLabelWrapper
          id="auto-configuration-name"
          label="Closing Resolution Name"
          tooltipText="This text appears in the activity log when the request is closed"
        >
          <BigidTextField
            id="auto-configuration-name"
            required
            errorMessage={get(formikEmail.errors.auto && formikEmail.errors.auto, `name`)}
            value={formikEmail.values.auto.configuration.name}
            size="medium"
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              formikEmail.setFieldValue(`auto.configuration.name`, event.target.value)
            }
            dataAid="auto-resolution-name__field"
          />
        </BigidFormFieldLabelWrapper>
        <BigidFormFieldLabelWrapper
          id="auto-resolution-text"
          label="Resolution Code"
          tooltipText="Enter the relevant code returned from the identity verification service"
        >
          <BigidTextField
            id="auto-resolution-text"
            required
            disabled={formikEmail.values.auto.configuration.mandatory}
            errorMessage={get(formikEmail.errors.auto && formikEmail.errors.auto, `text`)}
            value={formikEmail.values.auto.configuration.text}
            size="medium"
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
              formikEmail.setFieldValue(`auto.configuration.text`, event.target.value)
            }
            dataAid="auto-resolution-text__field"
          />
        </BigidFormFieldLabelWrapper>
        {!formikEmail.values.auto.configuration.mandatory && (
          <div className={cx(classes.inputWrapper, classes.activityLogMessage)}>
            <BigidMeTooltipWithIcon title="This text appears in the activity log when the resolution code is received" />
            <BigidTextField
              required
              errorMessage={get(
                formikEmail.errors.auto && formikEmail.errors.auto,
                `configuration.translations[${selectedLanguage}].auditLogMessage`,
              )}
              value={
                formikEmail.values.auto.configuration.translations[selectedLanguage] &&
                formikEmail.values.auto.configuration.translations[selectedLanguage]!.auditLogMessage
              }
              size="medium"
              onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                formikEmail.setFieldValue(
                  `auto.configuration.translations[${selectedLanguage}].auditLogMessage`,
                  event.target.value,
                )
              }
              label="Activity Log Message"
              dataAid="auto-resolution-audit-log-__field"
            />
          </div>
        )}
      </>
    ),
    [formikEmail, selectedLanguage],
  );

  const renderEmailTemplateInAccordionBody = (
    formikEmail: FormikProps<UpdateStageSettingsPostType>,
    stageEmail: { configType: StageConfigType; config: StageEmailConfigurationType },
    selectedLanguage: LanguageType,
  ) => (
    <BigidAccordionDetails sx={{ padding: '2px 0 !important' }}>
      <WorkflowEmailTemplate
        testIdPrefix="stage-email-customization"
        emailTemplateAllFieldRequired
        selectedLanguage={selectedLanguage}
        emailTemplate={formikEmail.values[stageEmail.configType].emailTemplate}
        emailTemplateErrors={formikEmail.errors[stageEmail.configType]?.emailTemplate}
        setFieldValue={formikEmail.setFieldValue}
        namespace={`${stageEmail.configType}.`}
        hideButton={
          (stageEmail.configType === 'general' && stageEmail.config.name === REQUEST_REJECTED_NAME_CONSTANT) ||
          (stageEmail.config.attachmentsEnabled !== undefined && !stageEmail.config.attachmentsEnabled)
        }
        buttonFieldLabelWrapperProps={
          isVendorRequestStageEmail
            ? {
                tooltipText: '',
                isRequired: true,
              }
            : {}
        }
        bodyTooltipText={isVendorRequestStageEmail ? VENDOR_REQUEST_BODY_TOOLTIP_TEXT : undefined}
      />
    </BigidAccordionDetails>
  );

  const isConfigurationVisible =
    (stageEmail.configType === 'general' &&
      stageEmail.config.code.includes(RequestWorkflowStageEmailCode.CONFIRM_CONFIG_SECTION + '_')) ||
    stageEmail.configType === 'manual' ||
    stageEmail.configType === 'auto';

  if (isLoading) return <Loader />;

  return (
    <Root>
      <div className={classes.controls}>
        <EmailTemplateControlsBar
          showLanguagePicker={!isVendorRequestStageEmail}
          selectedLanguage={selectedLanguage}
          emailTemplateId={stageEmail.config.emailTemplateId}
          emailTemplate={formikEmail.values[stageEmail.configType].emailTemplate}
          emailCode={stageEmail.config.code}
          onSelectedLanguageChange={setSelectedLanguage}
        />
        <PrimaryButton
          text="Save Changes"
          dataAid="save-changes__button"
          onClick={debounce(onSaveEmailCustomization, 300)}
          size="medium"
          disabled={(formikEmail.dirty && !formikEmail.isValid) || formikEmail.isSubmitting}
        />
      </div>
      <div className={classes.root}>
        {isVendorRequestStageEmail && <StageEmailVendorDeletion />}
        {isConfigurationVisible && (
          <BigidAccordion defaultExpanded className={classes.accordion}>
            <BigidAccordionSummary
              size={AccordionSummarySizeEnum.large}
              sx={{ padding: '0 !important' }}
              dataAid="configure-accordion__header"
            >
              <BigidHeading6>
                <div className={classes.purpleTitle}>Configure</div>
              </BigidHeading6>
            </BigidAccordionSummary>
            <BigidAccordionDetails sx={{ padding: '2px 0 !important' }}>
              {stageEmail.configType === 'general' && renderConfirmConfiguration()}
              {stageEmail.configType === 'manual' && renderManualResolutionConfiguration()}
              {stageEmail.configType === 'auto' && renderAutoResolutionConfiguration()}
            </BigidAccordionDetails>
          </BigidAccordion>
        )}
        <>
          {FormLanguageGeneralErrorLabelsMap.map(({ field, label }) => (
            <FormLanguageGeneralError
              key={field}
              selectedLanguage={selectedLanguage}
              fieldName={field}
              fieldLabel={label}
              errors={formikEmail.errors[stageEmail.configType]?.emailTemplate?.translations}
              availableLanguages={languages}
            />
          ))}
          <BigidAccordion className={classes.accordion} defaultExpanded>
            <BigidAccordionSummary
              size={AccordionSummarySizeEnum.large}
              sx={{ padding: '0 !important' }}
              dataAid={`${stageEmail.configType}-accordion__header`}
            >
              <BigidHeading6
                sx={{ display: 'flex', alignItems: 'center', width: 150, justifyContent: 'space-between' }}
              >
                <div className={classes.purpleTitle}>
                  Send Email
                  <span
                    onClick={event => {
                      setEmailTemplateModalActive(true);
                      event.stopPropagation();
                    }}
                  >
                    <BigidMeTooltipWithIcon title="Click here to see an example of the email template" />
                  </span>
                </div>
                <StopPropagationContainer>
                  {stageEmail.configType === 'manual' &&
                    formikEmail.values.manual.configuration.hasOwnProperty('emailEnabled') && (
                      <BigidSwitch
                        checked={formikEmail.values.manual.configuration.emailEnabled}
                        dataAid="manual-emailEnabled__switch"
                        onChange={(event, checked) =>
                          formikEmail.setFieldValue(`manual.configuration.emailEnabled`, checked)
                        }
                      />
                    )}
                  {stageEmail.configType === 'auto' &&
                    formikEmail.values.auto.configuration.hasOwnProperty('emailEnabled') && (
                      <BigidSwitch
                        checked={formikEmail.values.auto.configuration.emailEnabled}
                        dataAid="auto-emailEnabled__switch"
                        onChange={(event, checked) =>
                          formikEmail.setFieldValue(`auto.configuration.emailEnabled`, checked)
                        }
                      />
                    )}
                </StopPropagationContainer>
              </BigidHeading6>
            </BigidAccordionSummary>
            {renderEmailTemplateInAccordionBody(formikEmail, stageEmail, selectedLanguage)}
          </BigidAccordion>
        </>
        {formikEmail.values[stageEmail.configType].emailTemplate.canToggleAppeal && (
          <StageEmailAppealCustomization
            emailEnabled={
              stageEmail.configType !== 'general' &&
              formikEmail.values[stageEmail.configType].configuration.emailEnabled
            }
            stageEmail={stageEmail}
            emailTemplate={formikEmail.values[stageEmail.configType].emailTemplate}
            emailTemplateErrors={formikEmail.errors[stageEmail.configType]?.emailTemplate}
            selectedLanguage={selectedLanguage}
            setFieldValue={formikEmail.setFieldValue}
          />
        )}
      </div>
      {emailTemplateModalActive && (
        <div className={classes.exampleDialogRoot}>
          <div className={classes.exampleDialog}>
            <div className={classes.exampleDialogHeader}>
              <h2 className={classes.exampleDialogTitle}>Email Template Example</h2>
              <IconButton
                aria-label="close"
                className={classes.exampleDialogCloseButton}
                onClick={() => setEmailTemplateModalActive(false)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.exampleDialogBody}>
              <EmailTemplateExample />
            </div>
            <div className={classes.exampleDialogButtons}>
              <SecondaryButton size="medium" text="Close" onClick={() => setEmailTemplateModalActive(false)} />
            </div>
          </div>
        </div>
      )}
    </Root>
  );
};
