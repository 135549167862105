import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 12,
    marginBottom: 20,
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: theme.vars.palette.bigid?.gray700,
    fontSize: '1.3rem',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '1.75rem',
  },
  controlsWrapper: {
    display: 'inline-flex',
  },
  rightsideControlsSpacer: {
    width: 8,
  },
}));
