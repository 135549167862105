import { authService, authStore } from '../authentication';
import axios, { Canceler, isCancel } from 'axios';
import { ErrorCodes, shouldAvoidSnackbarNotification, toErrorResponse } from '../../utils/errorsUtils';
import { SnackbarUtils } from '../../utils/snackbarUtils';

const requestsCancelers: Record<string, Canceler> = {};

/**
 * Cancels all pending requests sent via apiService.
 * Usually should be called when a top-level container (such as a page) is unmounted.
 */
export const cancelRequests = () => {
  for (const [url, requestCanceler] of Object.entries(requestsCancelers)) {
    requestCanceler('Request was cancelled due to navigation change. This error can be ignored.');

    delete requestsCancelers[url];
  }
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_WORKFLOW_API_BASE_URL,
  responseType: 'json',
});

api.interceptors.request.use(config => {
  const user = authService.getUser();
  config.url = user && config.url?.indexOf(user.tenant) !== 0 ? user.tenant + config.url : config.url;

  const { method, url } = config;

  if (method === 'get' && url) {
    const { token, cancel } = axios.CancelToken.source(); // eslint-disable-line import/no-named-as-default-member

    requestsCancelers[url] = cancel;
    config.cancelToken = token;
  }

  return config;
});

api.interceptors.response.use(
  response => {
    const { method, url } = response.config;

    if (method === 'get' && url && requestsCancelers[url]) {
      delete requestsCancelers[url];
    }

    return response;
  },
  error => {
    if (isCancel(error)) {
      return Promise.reject(error);
    }

    const originalConfig = error?.config;
    const responseError = toErrorResponse(error);

    if (responseError.status === 404 && responseError.errorCode === ErrorCodes.INVALID_TENANT) {
      cancelRequests();
    }

    if (responseError.status === 401 && originalConfig.requestRetried !== true) {
      cancelRequests();
      sessionStorage.setItem('memoized-url', window.location.hash.substring(1));
      authService.logOut();
      authStore.removeAuthentication();
      window.location.reload();
      SnackbarUtils.error(responseError.message);
      return Promise.reject(responseError);
    } else {
      if (!shouldAvoidSnackbarNotification(responseError) && !originalConfig.avoidNotification) {
        SnackbarUtils.error(responseError.messageWithTrace);
      }
      return Promise.reject(responseError);
    }
  },
);
