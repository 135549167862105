import { useTheme, useMediaQuery } from '@mui/material';

export const useResponsive = (): {
  isMobile: boolean;
  isTablet: boolean;
} => {
  const theme = useTheme();

  return {
    isMobile: useMediaQuery(`(max-width:${theme.breakpoints.values.sm}px)`),
    isTablet: useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`),
  };
};
