export const e2e = (attr?: string, suffix = ''): { 'data-aid'?: string } =>
  process.env.REACT_APP_TEST_E2E === 'true' && attr ? { 'data-aid': attr + suffix } : {};
export const E2E_CAPTCHA_TOKEN = 'e2eCaptchaToken'; // must be verified on backend while e2e testing

export type WithTest = {
  'data-aid'?: string;
};

export interface ElementWithTest<T> extends React.HTMLAttributes<T> {
  'data-aid'?: string;
}
