import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { sdk } from '@bigid/app-fw-ui-sdk';
import { useEmbeddedApp } from './useEmbeddedApp';

export const useBigidCoreLocationSync = () => {
  const location = useLocation();
  const { isEmbeddedInIframe } = useEmbeddedApp();

  const navigate = useNavigate();

  window.addEventListener('message', event => {
    if (event.data.data?.topicName === 'updateAppRoute') {
      const newRoute = event.data.data?.data?.appRoute;
      navigate(`/${newRoute || ''}`);
    }
  });

  useEffect(() => {
    if (isEmbeddedInIframe) {
      sdk.changeRoute({
        url: location.pathname,
        params: { search: location.search, withAppInnerNavigation: 'true' },
      });
    }
  }, [location.search, location.pathname, isEmbeddedInIframe]);
};
