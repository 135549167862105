import makeStyles from '@mui/styles/makeStyles';
import { chartColors, paperBoxShadow } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  trendWrapper: {
    padding: '20px',
    borderRadius: 12,
    boxShadow: paperBoxShadow,
    backgroundColor: theme.vars.palette.bigid?.white,
    width: '50%',
    '&:nth-child(even)': {
      marginLeft: '20px',
    },
  },
  trendHeader: {
    height: 43,
    borderBottom: `1px solid ${theme.vars.palette.bigid?.gray400}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 11,
  },
  trendTitle: {
    color: chartColors.chipValueColor,
    fontWeight: 'bold',
    margin: 0,
  },
  mapRoot: {
    paddingTop: 13,
  },
}));
