export const makeCsv = (rows: unknown[][]) => rows.map(row => row.join(',')).join('\n');

export const downloadTextFile = (file: string, type: string, fileNme: string) => {
  const encodedUri = encodeURI(`data:text/${type};charset=utf-8,${file}`);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileNme);
  document.body.appendChild(link);

  link.click();
  link.remove();
};

export const downloadFile = (url: string, fileName: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.href = url;
    document.body.appendChild(link);
    link.onload = () => {
      link.remove();
      resolve(fileName);
    };
    link.onerror = () => {
      reject();
    };
    link.click();
  });
};
