import { useBlocker } from 'react-router-dom';

// A router only supports one blocker at a time
export const usePreventNavigation = (when = false) => {
  const blocker = useBlocker(when);

  return {
    showWarning: blocker.state === 'blocked',
    isProceeding: blocker.state === 'proceeding',
    reset: blocker.reset,
    retry: blocker.proceed,
  };
};
