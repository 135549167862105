import React, { FC } from 'react';
import { AppealGuidedTourStepTemplate } from './AppealGuidedTourStepTemplate';

interface SavePublishStepProps {
  dataAid?: string;
}

export const SavePublishStep: FC<SavePublishStepProps> = ({ dataAid }) => {
  return (
    <AppealGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Save & Publish"
      firstSectionText="Click to save all your changes and publish them to your consumer site."
    />
  );
};
