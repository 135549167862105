import React, { FC } from 'react';
import { TooltipRenderProps } from '@bigid-ui/guided-tour';
import { BigidXIcon } from '@bigid-ui/icons';
import { PrimaryButton, TertiaryButton } from '@bigid-ui/components';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { StepTooltipStyled } from './StepTooltipStyles';

type StepTooltipProps = TooltipRenderProps & {
  disabledIndexes?: number[];
  indexDifference?: number;
  sizeDifference?: number;
};

export const StepTooltip: FC<StepTooltipProps> = ({
  backProps,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
  size,
  disabledIndexes,
  indexDifference,
  sizeDifference,
}) => {
  const { hideBackButton, hideCloseButton } = step;

  const buttonStepText = () => {
    if (isLastStep) {
      return 'Finish';
    }
    return 'Next';
  };

  const { Main, ButtonsSection, Button, IconWrapper } = StepTooltipStyled;

  return (
    <Main {...tooltipProps} data-aid="SmallidGuidedTourTooltip">
      <IconWrapper onClick={e => skipProps.onClick(e as React.MouseEvent<HTMLElement, MouseEvent>)}>
        <BigidXIcon />
      </IconWrapper>
      <div>{step.content}</div>
      <ButtonsSection>
        <Typography>
          {index + (indexDifference !== undefined ? indexDifference : 1)}/{size - (sizeDifference ? sizeDifference : 0)}
        </Typography>
        <Stack flexDirection="row" justifyContent="center">
          {!hideBackButton && !isLastStep && index > 0 && (
            <Button>
              <TertiaryButton
                onClick={e => {
                  backProps.onClick(e as React.MouseEvent<HTMLElement, MouseEvent>);
                }}
                text="Back"
                size="medium"
                color="grey"
                width="fill"
                dataAid="BigidButtonTourBack"
              />
            </Button>
          )}
          <Button>
            {!hideCloseButton && (
              <PrimaryButton
                text={buttonStepText()}
                size="medium"
                onClick={e => {
                  primaryProps.onClick(e as React.MouseEvent<HTMLElement, MouseEvent>);
                }}
                disabled={disabledIndexes?.includes(index)}
                color="grey"
                width="fill"
                dataAid="BigidButtonTourDoneNext"
              />
            )}
          </Button>
        </Stack>
      </ButtonsSection>
    </Main>
  );
};
