import { AvailableLanguagesType, DictionaryType } from './types';
import { api } from '../apiService';

const getAvailableLanguages = (PPforceApiUrl = ''): Promise<AvailableLanguagesType> =>
  api
    .get<{ locales: AvailableLanguagesType }>(
      `${PPforceApiUrl}/supported-locales`,
      PPforceApiUrl ? { baseURL: '' } : {},
    )
    .then(res => res.data.locales);

const getDictionary = (localeCode = 'en', PPforceApiUrl = ''): Promise<DictionaryType> =>
  api
    .get<{ keyValues: DictionaryType }>(
      `${PPforceApiUrl}/localizations/${localeCode}`,
      PPforceApiUrl ? { baseURL: '' } : {},
    )
    .then(res => res.data.keyValues);

export const languageService = {
  getAvailableLanguages,
  getDictionary,
};
