import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  BigidBreadcrumbs,
  BigidDialog,
  BigidHeading2,
  BigidSidePanel,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { useNavigate } from 'react-router-dom';
import {
  BigidAddIcon,
  BigidAdministrationIcon,
  BigidActionCenterIcon,
  BigidMessageIcon,
  BigidQueryLanguageIcon,
} from '@bigid-ui/icons';
import { Portal } from '@mui/base';
import { Root, classes } from './RequestCustomizationStyles';
import { WorkflowCustomizationContainer } from '../hooks/useWorkflowCustomization';
import { StageEmailCustomizationContainer } from '../hooks/useStageEmailCustomization';
import { NEW_WEBHOOK_ID, WEBHOOK_DEFAULT, WebhookCustomizationContainer } from '../hooks/useWebhookCustomization';
import { WorkflowCustmizationPipeline } from '../../../components/WorkflowCustmizationPipeline';
import {
  addAutoCloseResolution,
  addManualCloseResolution,
  addWebhook,
  deleteAutoCloseResolutions,
  deleteManualCloseResolutions,
  deleteWebhook,
  setDefaultManualCloseResolutions,
  updateStageEmail,
  updateWebhook,
  updateWorkflowStage,
} from '../../../services/settingsService';
import {
  ProcessingStageType,
  RequestWorkflowStageEmailType,
  StageEmailConfigurationType,
  StageConfigType,
  ManualRequestResolutionsType,
  ResolutionType,
  RequestWorkflowStageEmailCode,
  RequestType,
  RequestWorkflowStageType,
  WebhookType,
  ActionsWorkflowGetType,
} from '../../../types';
import WebhookCustomization from '../WebhookCustomization';
import { SnackbarUtils } from '../../../utils';
import StageEmailCustomization from '../StageEmailCustomization';
import { Loader } from '../../../components';
import ManualCloseResolutionTable from '../ManualCloseResolutionTable';
import AutoCloseResolutionTable from '../AutoCloseResolutionTable';
import RequestStageTable from '../RequestStageTable';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { generateGuidedTourId } from '@bigid-ui/utils';
import { WebhooksTable } from '../WebhooksTable/WebhooksTable';
import { RequestCustomizationExitDialog } from './RequestCustomizationExitDialog';
import ActionsWorkflowTable from '../ActionsWorkflowTable';
import Box from '@mui/material/Box';
import { VendorDeletionTable } from '../VendorDeletionTable';

const SLIDE_OUT_MARKER = 0;

const hideVerifyForAppeal = (stages: RequestWorkflowStageType[]) =>
  stages.filter(stage => stage.name !== ProcessingStageType.VERIFY);

export const RequestCustomization = () => {
  const navigate = useNavigate();
  const {
    stages,
    requestTypeId,
    requestWorkflowData,
    autoCloseResolutions,
    manualCloseResolutions,
    reloadWorkflowDefinitions,
    reloadManualCloseResolutions,
    reloadAutoCloseResolutions,
  } = WorkflowCustomizationContainer.useContainer();
  const { formikEmail, stageEmail, setStageEmail, emailTemplateId, setEmailTemplateId } =
    StageEmailCustomizationContainer.useContainer();
  const {
    webhooks,
    formikWebhook,
    webhookTemplateId,
    setWebhookTemplateId,
    setWebhookTemplate,
    webhookPlaceholders,
    reloadWebhooks,
  } = WebhookCustomizationContainer.useContainer();
  const [currentStageId, setCurrentStageId] = useState<number>();
  const [stageName, setStageName] = useState<ProcessingStageType>();
  const [stageEmails, setStateEmails] = useState<RequestWorkflowStageEmailType[]>();
  const [vendorDeletionEmail, setVendorDeletionEmail] = useState<RequestWorkflowStageEmailType>();
  const [stageActions, setStageActions] = useState<ActionsWorkflowGetType[]>();
  const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);
  const [isSaveWebhookDialogOpen, setSaveWebhookDialogOpen] = useState(false);
  const [deleteDialogData, setDeleteDialogData] = useState<{ type: StageConfigType; id: number }>();
  const [deleteWebhookIdx, setWebhookDeleteDialogData] = useState<number>();

  usePageTitle(`Workflow-${requestWorkflowData?.type}`, { enable: !!requestWorkflowData?.type });

  useEffect(() => {
    if (stages) {
      const currentFirstStage = stages[0];
      const stageData = currentStageId && stages.find(({ id }) => id === currentStageId);

      setStageActions(stageData ? stageData.actions : currentFirstStage.actions);
      setStageName(stageData ? stageData.name : currentFirstStage.name);

      const emails = stageData ? stageData.emails : currentFirstStage.emails;

      const sortedEmails = [...emails].sort((a, b) => {
        if (a.code === RequestWorkflowStageEmailCode.COMMON_REQUEST_RECEIVED) return 1;
        if (b.code === RequestWorkflowStageEmailCode.COMMON_REQUEST_RECEIVED) return -1;
        return 0;
      });

      setStateEmails(sortedEmails.filter(({ code }) => code !== RequestWorkflowStageEmailCode.VENDOR_DELETION_REQUEST));
      setVendorDeletionEmail(
        sortedEmails.find(({ code }) => code === RequestWorkflowStageEmailCode.VENDOR_DELETION_REQUEST),
      );
      reloadManualCloseResolutions(currentStageId || currentFirstStage.id);
      reloadWebhooksTable();
    }
  }, [currentStageId, stages]); //eslint-disable-line react-hooks/exhaustive-deps

  const onBack = useCallback(() => {
    navigate('/settings/workflow-customization');
  }, [navigate]);

  const onSettingsClick = useCallback(() => {
    navigate(`/settings/workflow-customization/workflow-settings/${requestWorkflowData?.id}`);
  }, [navigate, requestWorkflowData]);

  const toggleEnabled = requestWorkflowData?.type !== RequestType.APPEAL;
  const hideVerify = requestWorkflowData?.type === RequestType.APPEAL;

  const onToggle = useCallback(
    async (id: number, name: string, enabled: boolean) => {
      await updateWorkflowStage(id, { enabled });

      SnackbarUtils.toast(
        <Root>
          <div className={classes.toastHeader}>{`${name} Stage ${enabled ? 'enabled' : 'disabled'}.`}</div>
          <div className={classes.toastDescription}>
            {!enabled &&
              `${name} will no longer be part of this workflow and automated emails for this stage will not be sent.`}
          </div>
        </Root>,
      );
      reloadWorkflowDefinitions();
    },
    [reloadWorkflowDefinitions],
  );

  const onStageClick = useCallback((stageId: number) => {
    setCurrentStageId(stageId);
  }, []);

  const onSliderLeave = useCallback(() => {
    formikEmail.dirty ? setSaveDialogOpen(true) : setEmailTemplateId(SLIDE_OUT_MARKER);
    formikWebhook.dirty ? setSaveWebhookDialogOpen(true) : setWebhookTemplateId(SLIDE_OUT_MARKER);
  }, [formikEmail.dirty, formikWebhook.dirty, setEmailTemplateId, setWebhookTemplateId]);

  const onRowClick = useCallback(
    (configType: StageConfigType, row: StageEmailConfigurationType) => {
      setEmailTemplateId(row.emailTemplateId);
      setStageEmail({ configType, config: row });
    },
    [setEmailTemplateId, setStageEmail],
  );

  const onWebhookRowClick = (row: WebhookType) => {
    setWebhookTemplate(row);
    setWebhookTemplateId(row.id);
  };

  const onDeleteCloseResolution = useCallback(
    async (deleteDialogData: { type: StageConfigType; id: number }) => {
      const currentFirstStage = stages![0];
      const name =
        deleteDialogData.type === 'auto'
          ? autoCloseResolutions!.find(({ id }) => id === deleteDialogData.id)!.name
          : manualCloseResolutions!.find(({ id }) => id === deleteDialogData.id)!.name;

      if (deleteDialogData.type === 'auto') {
        await deleteAutoCloseResolutions(deleteDialogData.id);
        await reloadAutoCloseResolutions();
      } else {
        await deleteManualCloseResolutions(deleteDialogData.id);
        await reloadManualCloseResolutions(currentStageId || currentFirstStage.id);
      }

      SnackbarUtils.toast(
        <Root>
          <div className={classes.toastHeader}>{`${name} resolution was deleted`}</div>
        </Root>,
      );

      setDeleteDialogData(undefined);
    },
    [
      autoCloseResolutions,
      currentStageId,
      manualCloseResolutions,
      reloadAutoCloseResolutions,
      reloadManualCloseResolutions,
      stages,
    ],
  );

  const onDefaultManualCloseResolution = useCallback(
    async (indexMenu: number) => {
      const currentFirstStage = stages![0];
      const name = manualCloseResolutions!.find(({ id }) => id === indexMenu)!.name;

      await setDefaultManualCloseResolutions(indexMenu, name);
      SnackbarUtils.toast(
        <Root>
          <div className={classes.toastHeader}>{`${name} is default now`}</div>
        </Root>,
      );
      await reloadManualCloseResolutions(currentStageId || currentFirstStage.id);
    },
    [currentStageId, manualCloseResolutions, reloadManualCloseResolutions, stages],
  );

  const onSaveEmailCustomization = useCallback(async () => {
    await formikEmail.handleSubmit();

    await reloadWorkflowDefinitions();
    const emailName = stageEmail?.config.name;

    SnackbarUtils.toast(
      <Root>
        <div className={classes.toastHeader}>{`Changes saved`}</div>
        <div className={classes.toastDescription}>{`“${emailName}” was updated.`}</div>
      </Root>,
    );

    setEmailTemplateId(SLIDE_OUT_MARKER);
    setSaveDialogOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageEmail, reloadWorkflowDefinitions, setEmailTemplateId]);

  const reloadWebhooksTable = useCallback(async () => {
    const currentFirstStage = stages![0];
    await reloadWebhooks(currentStageId || currentFirstStage.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stages, currentStageId]);

  const onSaveWebhook = useCallback(async () => {
    const currentFirstStage = stages![0];
    formikWebhook.values.id === NEW_WEBHOOK_ID
      ? await addWebhook(currentStageId || currentFirstStage.id, formikWebhook.values)
      : await updateWebhook(formikWebhook.values);
    SnackbarUtils.toast(
      <Root className={classes.toastHeader}>
        API Call {formikWebhook.values.id === NEW_WEBHOOK_ID ? 'created' : 'updated'} successfully
      </Root>,
    );
    reloadWebhooksTable();
    setSaveWebhookDialogOpen(false);
    setWebhookTemplateId(SLIDE_OUT_MARKER);
    formikWebhook.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stages, formikWebhook.values, currentStageId]);

  const onDeleteWebhookCallback = useCallback(
    async (deleteWebhookIdx: number) => {
      await deleteWebhook(deleteWebhookIdx);
      setWebhookDeleteDialogData(undefined);
      reloadWebhooksTable();
    },
    [reloadWebhooksTable],
  );

  const onAddResolutionClick = useCallback(
    async (resolutionType: ResolutionType) => {
      const currentFirstStage = stages![0];

      if (resolutionType === 'auto') {
        const newResolution = await addAutoCloseResolution({
          requestId: +requestTypeId!,
          name: 'New Automated Closing Resolution',
          text: 'New Automated Closing Resolution',
          emailEnabled: false,
          translations: {},
        });
        onRowClick('auto', {
          ...newResolution,
          code: RequestWorkflowStageEmailCode.COMMON_REQUEST_IDENTITY_DECLINED,
        } as StageEmailConfigurationType);
        await reloadAutoCloseResolutions();
      } else {
        const newResolution = await addManualCloseResolution({
          type: resolutionType,
          name: 'New Closing Resolution',
          defaultForStage: false,
          emailEnabled: true,
          requestStageId: currentStageId || currentFirstStage.id,
          translations: {},
        });
        onRowClick('manual', {
          ...newResolution,
          code:
            resolutionType === ManualRequestResolutionsType.REQUEST_COMPLETION
              ? requestWorkflowData?.type === RequestType.APPEAL
                ? RequestWorkflowStageEmailCode.COMMON_NEW_REQUEST_V2
                : RequestWorkflowStageEmailCode.COMMON_REQUEST_CLOSED_V2
              : RequestWorkflowStageEmailCode.COMMON_NOTIFICATION_V2,
        } as StageEmailConfigurationType);
        await reloadManualCloseResolutions(currentStageId || currentFirstStage.id);
      }
    },
    [
      requestWorkflowData?.type,
      currentStageId,
      onRowClick,
      reloadAutoCloseResolutions,
      reloadManualCloseResolutions,
      stages,
      requestTypeId,
    ],
  );

  const onAddWebhookClick = useCallback(async () => {
    setWebhookTemplateId(NEW_WEBHOOK_ID);
    setWebhookTemplate(WEBHOOK_DEFAULT);
    formikWebhook.resetForm();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const updateConfirmFirstSegmentStageEmails = (rows: RequestWorkflowStageEmailType[]) => {
    rows.shift();
    rows.forEach(async row => {
      await updateStageEmail(row.id, { enabled: false });
    });
  };

  const onStageEmailActiveSwitch = async ({
    rowsData,
    row,
    id,
    checked,
    isConfirmSection,
  }: {
    rowsData: RequestWorkflowStageEmailType[];
    row: RequestWorkflowStageEmailType;
    id: number;
    checked: boolean;
    isConfirmSection?: boolean;
  }) => {
    const isConfirmMainWasDisabled = isConfirmSection && id === 0 && !checked;
    await updateStageEmail(row.id, { enabled: checked });
    isConfirmMainWasDisabled &&
      updateConfirmFirstSegmentStageEmails(
        rowsData.filter(r => r.code !== RequestWorkflowStageEmailCode.COMMON_REQUEST_RECEIVED),
      );

    await reloadWorkflowDefinitions();

    SnackbarUtils.toast(
      <Root>
        <div className={classes.toastHeader}>{`${row.name} ${!checked ? 'not' : ''} active`}</div>
        <div className={classes.toastDescription}>
          {row.code === RequestWorkflowStageEmailCode.CONFIRM_CONFIG_SECTION &&
            isConfirmMainWasDisabled &&
            `The System automatically deactivated the following: Reminder 1, Reminder 2 and Request rejected`}
        </div>
      </Root>,
    );
  };

  const renderAddNewButton = (resolutionType: ResolutionType) => (
    <div
      className={classes.addNew}
      onClick={() => onAddResolutionClick(resolutionType)}
      data-aid={`add-${resolutionType}_button`}
    >
      <span>
        <BigidAddIcon /> Add New
      </span>
    </div>
  );

  const renderAddApiTriggerButton = () => (
    <div className={classes.addNew} onClick={() => onAddWebhookClick()} data-aid="add-webhook_button">
      <span>
        <BigidAddIcon /> Add New API call
      </span>
    </div>
  );

  const manualTermination = useMemo(
    () =>
      manualCloseResolutions &&
      manualCloseResolutions.filter(({ type }) => type === ManualRequestResolutionsType.REQUEST_TERMINATION),
    [manualCloseResolutions],
  );

  const manualCompletion = useMemo(
    () =>
      manualCloseResolutions &&
      manualCloseResolutions.filter(({ type }) => type === ManualRequestResolutionsType.REQUEST_COMPLETION),
    [manualCloseResolutions],
  );

  if (!requestWorkflowData) return <Loader />;

  const isEmailsToShow =
    (stageEmails && !!stageEmails.length) ||
    (autoCloseResolutions && !!autoCloseResolutions.length) ||
    (manualCloseResolutions && !!manualCloseResolutions.length);
  const isStageEmailsToShow = stageEmails && !!stageEmails.length;
  const isStageActionsToShow = stageActions && !!stageActions.length;
  const isAutocloseResolutionsToShow = autoCloseResolutions && !!autoCloseResolutions.length;
  const isManualTerminationToShow = manualTermination && !!manualTermination.length;
  const isManualCompletionToShow = manualCompletion && !!manualCompletion.length;
  const isConfirmSection = stageName === ProcessingStageType.CONFIRM;
  const isVerifyStage = stageName === ProcessingStageType.VERIFY;

  return (
    <Root>
      <div className={classes.root}>
        <div>
          <div className={classes.stickyLayout}>
            <div className={classes.header}>
              <BigidBreadcrumbs
                breadcrumbs={[
                  {
                    label: 'Workflow Customization',
                    onClick: onBack,
                  },
                  {
                    label: requestWorkflowData?.workflowName,
                  },
                ]}
              />
              <SecondaryButton
                text="Workflow Settings"
                startIcon={<BigidAdministrationIcon />}
                onClick={onSettingsClick}
                size="medium"
              />
            </div>

            <div className={classes.wrapper}>
              {!!stages?.length && (
                <WorkflowCustmizationPipeline
                  stages={hideVerify ? hideVerifyForAppeal(stages) : stages}
                  onToggle={toggleEnabled ? onToggle : undefined}
                  onClick={onStageClick}
                />
              )}
            </div>
          </div>

          <div className={classes.stageLayout}>
            {isStageActionsToShow && (
              <>
                <Box mt={6} mb={2} ml={-2} display="flex" alignItems="center">
                  <BigidActionCenterIcon />
                  &nbsp;&nbsp;
                  <BigidHeading2>Automations</BigidHeading2>
                </Box>
                <div className={classes.tableDescription}>Configure automatic actions for this stage.</div>
                <div className={classes.tableWrapper}>
                  <ActionsWorkflowTable rows={stageActions} />
                </div>
              </>
            )}
            {isEmailsToShow && (
              <Box mt={7} mb={2} ml={-2} display="flex" alignItems="center">
                <BigidMessageIcon />
                &nbsp;&nbsp;
                <BigidHeading2>Emails</BigidHeading2>
              </Box>
            )}
            {vendorDeletionEmail && (
              <>
                <div className={classes.tableSubTitle}>Vendor communication</div>
                <div className={classes.tableDescription}>
                  Automate vendor task notifications through email configuration for efficient task management during
                  the Update stage
                </div>
                <div className={classes.tableWrapper} data-aid="vendor-communication-email">
                  <VendorDeletionTable
                    rows={[vendorDeletionEmail]}
                    onStageEmailActiveSwitch={onStageEmailActiveSwitch}
                    onRowClick={onRowClick}
                  />
                </div>
              </>
            )}
            {isStageEmailsToShow &&
              (isConfirmSection ? (
                <>
                  <div className={classes.tableSubTitle}>Process Request</div>
                  <div className={classes.tableDescription}>
                    Configure automatic emails sent during this stage of request processing.
                  </div>
                  <div className={classes.tableWrapper}>
                    <RequestStageTable
                      isConfirmSection
                      rowsToUpdate={stageEmails}
                      rows={stageEmails.filter(
                        ({ code }) => !code.includes(RequestWorkflowStageEmailCode.CONFIRM_SEPARATE_SECTION),
                      )}
                      onStageEmailActiveSwitch={onStageEmailActiveSwitch}
                      onRowClick={onRowClick}
                    />
                  </div>
                  <div data-tour-id={generateGuidedTourId('ClosedRequests', ['table'])}>
                    <div className={classes.tableSubTitle}>Close Request (Automatic)</div>
                    <div className={classes.tableDescription}>
                      Configure emails sent when the request is automatically closed in this stage.
                    </div>
                    <div className={classes.tableWrapper}>
                      <RequestStageTable
                        rows={stageEmails
                          .filter(({ code }) => code.includes(RequestWorkflowStageEmailCode.CONFIRM_SEPARATE_SECTION))
                          .map(stageEmail =>
                            !stageEmails.find(s => s.code === RequestWorkflowStageEmailCode.CONFIRM_CONFIG_SECTION)
                              ?.enabled
                              ? { ...stageEmail, disabled: true, enabled: false }
                              : stageEmail,
                          )}
                        onStageEmailActiveSwitch={onStageEmailActiveSwitch}
                        onRowClick={onRowClick}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.tableSubTitle}>Stage Completed</div>
                  <div className={classes.tableDescription}>
                    Configure automatic actions and emails sent during this stage of request processing.
                  </div>
                  <div className={classes.tableWrapper}>
                    <RequestStageTable
                      rows={stageEmails!}
                      onStageEmailActiveSwitch={onStageEmailActiveSwitch}
                      onRowClick={onRowClick}
                    />
                  </div>
                </>
              ))}
            {isAutocloseResolutionsToShow && isVerifyStage && (
              <>
                <div className={classes.tableSubTitle}>Close Request (Automatic)</div>
                <div className={classes.tableDescription}>
                  Configure emails sent when the request is automatically closed in this stage.
                </div>
                <div className={classes.tableWrapper}>
                  <AutoCloseResolutionTable
                    rows={autoCloseResolutions}
                    onRowClick={onRowClick}
                    setDeleteDialogData={setDeleteDialogData}
                  />
                </div>
                {renderAddNewButton('auto')}
              </>
            )}
            {isManualTerminationToShow && (
              <>
                <div className={classes.tableSubTitle}>Close Request (Manual - Request Termination)</div>
                <div className={classes.tableDescription}>
                  Configure reasons and emails for manually closing requests in this stage.
                </div>
                <div className={classes.tableWrapper}>
                  <ManualCloseResolutionTable
                    tableType={ManualRequestResolutionsType.REQUEST_TERMINATION}
                    rows={manualTermination}
                    onDefaultManualCloseResolution={onDefaultManualCloseResolution}
                    onRowClick={onRowClick}
                    setDeleteDialogData={setDeleteDialogData}
                  />
                </div>
                {renderAddNewButton(ManualRequestResolutionsType.REQUEST_TERMINATION)}
              </>
            )}
            {isManualCompletionToShow && (
              <>
                <div className={classes.tableSubTitle}>Close Request (Manual - Completion)</div>
                <div className={classes.tableDescription}>
                  Configure reasons and emails for manually closing requests in this stage.
                </div>
                <div className={classes.tableWrapper}>
                  <ManualCloseResolutionTable
                    tableType={ManualRequestResolutionsType.REQUEST_COMPLETION}
                    rows={manualCompletion!}
                    onDefaultManualCloseResolution={onDefaultManualCloseResolution}
                    onRowClick={onRowClick}
                    setDeleteDialogData={setDeleteDialogData}
                  />
                </div>
                {renderAddNewButton(ManualRequestResolutionsType.REQUEST_COMPLETION)}
              </>
            )}
            <>
              <Box mt={5} mb={2} ml={-2} display="flex" alignItems="center">
                <BigidQueryLanguageIcon />
                &nbsp;&nbsp;
                <BigidHeading2>API Triggers</BigidHeading2>
              </Box>

              <div className={classes.tableDescription}>Set this event to trigger an API call</div>
              <div className={classes.tableWrapper}>
                <WebhooksTable
                  rows={webhooks}
                  reloadTable={reloadWebhooksTable}
                  onRowClick={onWebhookRowClick}
                  setDeleteDialogData={setWebhookDeleteDialogData}
                />
              </div>
              {renderAddApiTriggerButton()}
            </>
          </div>
        </div>
      </div>
      {stageEmail && (
        <Portal>
          <BigidSidePanel
            isShowBackdrop
            open={!!emailTemplateId}
            content={
              <StageEmailCustomization stageEmail={stageEmail} onSaveEmailCustomization={onSaveEmailCustomization} />
            }
            maxWidth="medium"
            onClose={onSliderLeave}
            title={stageEmail.config.name || ''}
            dataAid="email-template__slider"
          />
        </Portal>
      )}

      {/** SLIDER FOR API TRIGGERS WEBHOOKS */}
      {!!webhookTemplateId && (
        <Portal>
          <BigidSidePanel
            isShowBackdrop
            open={!!webhookTemplateId}
            content={
              <WebhookCustomization
                formikWebhook={formikWebhook}
                webhookPlaceholders={webhookPlaceholders}
                onSaveWebhookCustomization={onSaveWebhook}
              />
            }
            maxWidth="medium"
            onClose={onSliderLeave}
            title="API Call"
          />
        </Portal>
      )}

      {/* A router only supports one blocker at a time
      on leave page useBlocker will block page with unsaved prompt,
      bug in lib, only one blocker can be on a page  */}
      {formikEmail.dirty && (
        <RequestCustomizationExitDialog
          showWarningOnLeave={isSaveDialogOpen}
          isDirty={formikEmail.dirty}
          buttonCancel={{
            component: TertiaryButton,
            text: 'Close without saving',
            onClick: () => {
              setSaveDialogOpen(false);
              setEmailTemplateId(SLIDE_OUT_MARKER);
              formikEmail.resetForm();
            },
            dataAid: 'email-customization-save-dialog__cancel',
          }}
          buttonApply={{
            component: PrimaryButton,
            text: 'Save',
            onClick: onSaveEmailCustomization,
            disabled: formikEmail.dirty && !formikEmail.isValid,
            dataAid: 'email-customization-save-dialog__apply',
          }}
          onClose={() => setSaveDialogOpen(false)}
        />
      )}

      {/* A router only supports one blocker at a time
      on leave page useBlocker will block page with unsaved prompt,
      bug in lib, only one blocker can be on a page  */}
      {formikWebhook.dirty && (
        <RequestCustomizationExitDialog
          showWarningOnLeave={isSaveWebhookDialogOpen}
          isDirty={formikWebhook.dirty}
          buttonCancel={{
            component: TertiaryButton,
            text: 'Close without saving',
            onClick: () => {
              setSaveWebhookDialogOpen(false);
              setWebhookTemplateId(SLIDE_OUT_MARKER);
              formikWebhook.resetForm();
            },
            dataAid: 'webhook-save-dialog__cancel',
          }}
          buttonApply={{
            component: PrimaryButton,
            text: 'Save',
            onClick: onSaveWebhook,
            disabled: formikWebhook.dirty && !formikWebhook.isValid,
            dataAid: 'webhook-save-dialog__apply',
          }}
          onClose={() => setSaveWebhookDialogOpen(false)}
        />
      )}

      {deleteWebhookIdx && (
        <BigidDialog
          title="Delete Webhook?"
          isOpen={!!deleteWebhookIdx}
          buttons={[
            {
              component: TertiaryButton,
              text: 'Cancel',
              onClick: () => {
                setWebhookDeleteDialogData(undefined);
              },
              dataAid: 'delete-webhook-dialog__cancel',
            },
            {
              component: PrimaryButton,
              text: 'Delete',
              onClick: () => onDeleteWebhookCallback(deleteWebhookIdx),
              disabled: formikWebhook.dirty && !formikWebhook.isValid,
              dataAid: 'delete-webhook-dialog__apply',
            },
          ]}
          onClose={() => setDeleteDialogData(undefined)}
        >
          {`Are you sure you want to delete ${webhooks!.find(({ id }) => id === deleteWebhookIdx)!.name}?`}
        </BigidDialog>
      )}

      {!!deleteDialogData && (
        <BigidDialog
          title="Delete Resolution?"
          isOpen={!!deleteDialogData}
          buttons={[
            {
              component: TertiaryButton,
              text: 'Cancel',
              onClick: () => {
                setDeleteDialogData(undefined);
              },
              dataAid: 'delete-resolution-dialog__cancel',
            },
            {
              component: PrimaryButton,
              text: 'Delete',
              onClick: () => onDeleteCloseResolution(deleteDialogData),
              disabled: formikWebhook.dirty && !formikWebhook.isValid,
              dataAid: 'delete-resolution-dialog__apply',
            },
          ]}
          onClose={() => setDeleteDialogData(undefined)}
        >
          {`Are you sure you want to delete ${
            deleteDialogData.type === 'auto'
              ? autoCloseResolutions!.find(({ id }) => id === deleteDialogData.id)!.name
              : manualCloseResolutions!.find(({ id }) => id === deleteDialogData.id)!.name
          } resolution?`}
        </BigidDialog>
      )}
    </Root>
  );
};
