import { styled } from '@mui/material/styles';
import { primaryBorder } from '../../../assets/styles';

const PREFIX = 'WebhookCustomization';

export const classes = {
  root: `${PREFIX}-root`,
  controls: `${PREFIX}-controls`,
  status: `${PREFIX}-status`,
  tabsContainer: `${PREFIX}-tabsContainer`,
  statusLabel: `${PREFIX}-statusLabel`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: '16px 20px',
  },
  [`& .${classes.controls}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 20px',
    borderBottom: primaryBorder,
    position: 'sticky',
    top: 0,
    backgroundColor: theme.vars.palette.bigid?.white,
    zIndex: 10,
  },
  [`& .${classes.status}`]: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  [`& .${classes.statusLabel}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
  },
  [`& .${classes.tabsContainer}`]: {
    marginBottom: 0,
    borderBottom: `1px solid ${theme.vars.palette.bigid?.gray300}`,
  },
}));
