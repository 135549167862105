import { BigidGridQueryComponents, BigidGridSorting } from '@bigid-ui/grid';
import { BigidMeFilterType } from '../../components/MeFilterToolbar';
import { AxiosError } from 'axios';
import qs from 'query-string';

import { api } from './apiService';
import {
  RequestResponseType,
  CustomerDetailsWithFilesType,
  CollectingDataDetailsResponseType,
  FindReviewableFieldsOptionsType,
  UpdateReviewableFieldType,
  RequestDataType,
  StagesResponseType,
  StageResponseType,
  ReviewableFieldType,
  GridFetchResponseType,
  GridOptOutDataUpdateType,
  UpdateConsentPreferencesGridRowType,
  ErrorResponseType,
  UserFilesType,
  GridActivityLogRowType,
  GridRowType,
  ReviewDataStateType,
  UpdateStageResponseType,
  UpdateStageReviewableFieldsType,
  RequestStageType,
  NoteType,
  RequestManagerStateType,
  SingleRequestStateType,
  RequestDeletionMethod,
  ReportTemplate,
  ConsentFiltersType,
  RequestFilter,
  ReportAttributeFilterType,
  DiplicatesResolutionMode,
  ApproveDataStateType,
  RequestCloseResolutionType,
  UploadedFileType,
  CollectingSystemType,
  PreferencesGridFilter,
  UpdateDataSourceType,
  NoteCreateType,
  NoteUpdateType,
  FileType,
  CommunicationDto,
} from '../../types';
import {
  b64DecodeUnicode,
  b64EncodeUnicode,
  downloadFile,
  extractSearchFromFilters,
  getCellSortMarker,
  removeSearchFromFilters,
  toStringQuery,
} from '../../utils';
import { noImageAvailable } from '../../assets/noImageAvailable-base64';
import { authService } from '../authentication';
import { EmailMode } from '../../pages/SingleRequest/ContactRequestorDialog';
import { AuthorizedAgentMessageMode } from '../../types/SettingsTypes';
import { BigidFieldFilter, BigidFilter } from '@bigid-ui/components';

const apiBaseUrl = process.env.REACT_APP_WORKFLOW_API_BASE_URL;

const CSV_RECORDS_LIMIT = 100000;

export const fetchSingleRequest = async (requestId: string): Promise<RequestDataType> =>
  await api
    .post<RequestResponseType>(
      `/requests/search`,
      {
        filters: [
          {
            name: 'id',
            values: [requestId],
          },
        ],
      },
      {
        params: {
          skip: 0,
          limit: 1,
        },
      },
    )
    .then(resolve => resolve.data.data[0]);

export const fetchCustomerDetails = async (requestId: string): Promise<CustomerDetailsWithFilesType> =>
  await api.get<CustomerDetailsWithFilesType>(`/requests/${requestId}/attributes`).then(resolve => resolve.data);

export const fetchCustomerCard = async (requestId: string, fileId: UserFilesType[]): Promise<string> => {
  // if user file not exist
  if (!fileId || !fileId[0]) {
    return '';
  }

  return await api
    .get<Uint8Array>(`/requests/${requestId}/files/${fileId[0].uploadId}`, {
      responseType: 'arraybuffer',
    })
    .then(response => {
      const image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
      return `data:${response.headers['content-type']?.toLowerCase()};base64,${image}`;
    })
    .catch(() => {
      return noImageAvailable;
    });
};

export const downloadCustomerCard = async (requestId: string, fileId: string, fileName: string): Promise<void> =>
  await api
    .get<File>(`/requests/${requestId}/files/${fileId}`, {
      responseType: 'blob',
    })
    .then(response => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });

export const postVerificationStatus = async (requestId: string, verified: boolean): Promise<void> =>
  await api
    .post<void>(`/requests/${requestId}/stages/verify/actions/verify`, {
      verified,
      reason: 'here is the reason',
    })
    .then(resolve => resolve.data);

export const postVerificationAgentStatus = async (requestId: string, verified: boolean): Promise<void> =>
  await api
    .post<void>(`/requests/${requestId}/stages/verify/actions/verify-agent`, {
      verified,
    })
    .then(resolve => resolve.data);

export const retryAutoVerification = async (requestId: string): Promise<void> =>
  await api
    .post<void>(`/requests/${requestId}/stages/verify/actions/retry-auto-verification`)
    .then(resolve => resolve.data);
export const postConfirmedStatus = async (requestId: string): Promise<Record<string, unknown>> =>
  await api
    .post<Record<string, unknown>>(`/requests/${requestId}/stages/confirm/actions/confirm`)
    .then(resolve => resolve.data);

export const retryConfirmationEmail = async (requestId: string): Promise<void> =>
  await api
    .post<void>(`/requests/${requestId}/stages/confirm/actions/retry-confirmation-email`)
    .then(resolve => resolve.data);

export const retryCorrectionsEmail = async (requestId: string): Promise<void> =>
  await api
    .post<void>(`/requests/${requestId}/stages/update/actions/retry-corrections-email`)
    .then(resolve => resolve.data);

export const fetchCollectingDataDetails = async (requestId: string): Promise<CollectingDataDetailsResponseType> =>
  await api.get(`/requests/${requestId}/stages/collect`).then(resolve => resolve.data);

export const getCollectStageDataSources = async ({
  requestId,
  auto,
  filter,
  sort,
}: {
  requestId: string;
  auto: boolean;
  filter?: BigidFilter;
  sort?: BigidGridSorting[];
}): Promise<{
  data: CollectingSystemType[];
  totalCount: number;
}> => {
  const filtersWithoutSearch = removeSearchFromFilters(filter);
  console.log(filtersWithoutSearch);
  const search = extractSearchFromFilters(filter);
  return await api
    .get(`/requests/${requestId}/stages/collect/data-sources`, {
      params: {
        sort: sort && getCellSortMarker(sort),
        auto,
        search,
        ...(filter?.reduce((acc, { field, value }) => ({ ...acc, [field]: value }), {}) || {}),
      },
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params);
        },
      },
    })
    .then(resolve => resolve.data);
};

export const fetchCollectingDataFilters = async (
  requestId: string,
  auto = true,
): Promise<{
  statuses: string[];
  names: string[];
}> =>
  await api
    .get(`/requests/${requestId}/stages/collect/grid-filters`, {
      params: {
        auto,
      },
    })
    .then(resolve => resolve.data);

export const uploadReport = async (
  requestId: string,
  formData: FormData,
  mode?: DiplicatesResolutionMode,
): Promise<void | ErrorResponseType | AxiosError> => {
  return mode
    ? await api
        .post<void>(`/requests/${requestId}/report-additions?duplicatesResolutionMode=${mode}`, formData)
        .then(resolve => resolve.data)
    : await api.post<void>(`/requests/${requestId}/report-additions`, formData).then(resolve => resolve.data);
};

export const uploadReportWithParams =
  (dsId: number[]) =>
  async (
    requestId: string,
    formData: FormData,
    mode?: DiplicatesResolutionMode,
  ): Promise<void | ErrorResponseType | AxiosError> => {
    return mode
      ? await api
          .post<void>(`/requests/${requestId}/report-additions?duplicatesResolutionMode=${mode}`, formData, {
            params: {
              dsId,
            },
            paramsSerializer: {
              serialize: params => {
                return qs.stringify(params);
              },
            },
          })
          .then(resolve => resolve.data)
      : await api
          .post<void>(`/requests/${requestId}/report-additions`, formData, {
            params: {
              dsId,
            },
            paramsSerializer: {
              serialize: params => {
                return qs.stringify(params);
              },
            },
          })
          .then(resolve => resolve.data);
  };

export const downloadReportTemplate = async (requestId: string): Promise<void> =>
  await api
    .get<File>(`/requests/${requestId}/report-additions/template`, {
      responseType: 'blob',
    })
    .then(response => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'upload-data.csv');
      document.body.appendChild(link);
      link.click();
    });

export const downloadReportTemplateWithParams = (dsId: number[]) => async (requestId: string) =>
  await api
    .get<File>(`/requests/${requestId}/report-additions/template`, {
      responseType: 'blob',
      params: {
        dsId,
      },
      paramsSerializer: {
        serialize: params => {
          return qs.stringify(params);
        },
      },
    })
    .then(response => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'upload-data.csv');
      document.body.appendChild(link);
      link.click();
    });

export const previewReport = async (requestId: string, isZippedReport?: boolean) => {
  const tenant = authService.getUser()?.tenant || '';

  const url = isZippedReport
    ? `${apiBaseUrl}/${tenant}/requests/${requestId}/report/file/bundle`
    : `${apiBaseUrl}/${tenant}/requests/${requestId}/report/file`;

  downloadFile(url, 'Portability report - Privacy request');
};

export const rescanCurrentDsar = async (requestId: string, dataSourceId: number): Promise<void> =>
  await api
    .post<void>(`/requests/${requestId}/stages/collect/data-sources/${dataSourceId}/actions/retry`)
    .then(resolve => resolve.data);

export const rescanCurrentDsarByIds = async (requestId: string, sdIds: number[]): Promise<void[]> =>
  Promise.all(sdIds.map(id => rescanCurrentDsar(requestId, id)));

export const restartCollectingData = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/collect/actions/retry-dsar`).then(resolve => resolve.data);

export const verifyScan = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/collect/actions/next`).then(resolve => resolve.data);

export const verifyReport = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/update/actions/next`).then(resolve => resolve.data);

export const reviewStageNext = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/review/actions/next`).then(resolve => resolve.data);

export const markReviewStageDone = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/review/actions/mark-done`).then(resolve => resolve.data);

export const stageBack = async (
  requestId: string,
  stage:
    | RequestStageType.REVIEW
    | RequestStageType.UPDATE
    | RequestStageType.COMPLETE
    | RequestStageType.COLLECT
    | RequestStageType.APPROVE,
): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/${stage}/actions/back`).then(resolve => resolve.data);

export const markCollectingStageDone = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/collect/actions/mark-done`).then(resolve => resolve.data);

export const markConsentsAsCompleted = async (
  requestId: string,
  userPreferenceIds: number[],
): Promise<{ preferences: UpdateConsentPreferencesGridRowType[] }> =>
  await api
    .post(`/requests/${requestId}/stages/update/actions/complete-user-preferences`, { userPreferenceIds })
    .then(({ data }) => data.preferences);

export const getPreferenceRequestFilters = async (requestId: string): Promise<ConsentFiltersType> =>
  await api.get(`/requests/${requestId}/user-preference-facets`).then(resolve => resolve.data.facets);

export const fetchActivityLog = async ({
  requestId,
  filter,
  search,
  skip,
  limit,
  sort,
}: {
  requestId: string;
  skip?: number;
  limit?: number;
  sort?: { field: Partial<GridRowType | GridActivityLogRowType> | string; order: 'asc' | 'desc' }[];
  filter?: BigidMeFilterType[];
  search?: string;
}) =>
  await api
    .get<GridFetchResponseType>(`/requests/${requestId}/activity-logs`, {
      params: {
        skip,
        limit,
        sort: sort && getCellSortMarker(sort),
        filter: toStringQuery(filter),
        search,
      },
    })
    .then(resolve => ({
      totalCount: resolve.data.totalCount,
      data: resolve.data.data,
    }));

export const getRequestNotes = async (requestId?: string) =>
  await api
    .get<{ notes: NoteType[]; currentStage: string; possibleStages: string[] }>(`/requests/${requestId}/notes`)
    .then(resolve => resolve.data);

export const addRequestNote = async (requestId: string, data: NoteCreateType): Promise<NoteType> =>
  await api.post<NoteType>(`/requests/${requestId}/notes`, data).then(resolve => resolve.data);

export const updateRequestNote = async (data: NoteUpdateType): Promise<NoteType> =>
  await api.patch<NoteType>(`/request-notes/${data.id}`, data).then(resolve => resolve.data);

export const deleteRequestNote = async (noteId: string | number): Promise<NoteType> =>
  await api.delete<NoteType>(`/request-notes/${noteId}`).then(resolve => resolve.data);

export const downloadRequestNoteFile = async (fileId: number | string, fileName: string): Promise<void> =>
  await api
    .get<File>(`/request-note-files/${fileId}/content`, {
      responseType: 'blob',
    })
    .then(response => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });

export const uploadFileNote = (file: File) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.post<{ uploadId: string }>(`/uploaded-files`, formData).then(({ data }) => data.uploadId);
};

export const sendMessage = async (
  requestId: string,
  {
    title,
    subject,
    content,
    files,
    emailMode,
  }: {
    title: string;
    subject: string;
    content: string;
    files: UploadedFileType[];
    emailMode?: EmailMode | string;
  },
): Promise<void> =>
  await api
    .post(`/requests/${requestId}/messages`, { title, subject, content: b64EncodeUnicode(content), files, emailMode })
    .then(resolve => resolve.data);

export const updateMessage = async (
  requestId: string,
  messageId: number,
  { seen }: { seen?: boolean },
): Promise<void> =>
  await api.patch(`/requests/${requestId}/messages/${messageId}`, { seen }).then(resolve => resolve.data);

export const downloadMessageFile = async (requestId: string, fileId: number, fileName: string) =>
  downloadFile(
    `${apiBaseUrl}/${authService.getUser()?.tenant}/requests/${requestId}/messages/files/${fileId}`,
    fileName,
  );

export const downloadVendorFile = async (fileId: number, fileName: string) =>
  downloadFile(`${apiBaseUrl}/${authService.getUser()?.tenant}/vendor-request-files/${fileId}`, fileName);

export const downloadCollaborationEmailFile = async (fileId: number, fileName: string) =>
  downloadFile(`${apiBaseUrl}/${authService.getUser()?.tenant}/email-collaboration-files/${fileId}`, fileName);

export const updateVendorMessage = async (messageId: number, { seen }: { seen?: boolean }): Promise<void> =>
  await api.patch(`/vendor-requests/${messageId}`, { seen }).then(resolve => resolve.data);

export const getCommunicationThreads = async (
  requestId: string,
  filters: BigidFieldFilter[],
): Promise<CommunicationDto> =>
  await api.post(`/requests/${requestId}/communications`, { filters }).then(resolve => resolve.data);

export const proceedDeletionReportToNextStage = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/update/actions/next`).then(resolve => resolve.data);

export const proceedEditingReportToNextStage = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/update/actions/next`).then(resolve => resolve.data);

// TODO: fix for BE not autoclosed requests
export const manuallyCloseReport = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/manually-close`).then(resolve => resolve.data);

export const closeRequest = ({
  requestId,
  resolutionId,
  resolutionName,
  authorizedAgentMessageMode,
  emailMessage,
  emailTemplate,
  attachmentIds,
  emailNotificationEnabled,
  trackingNumber,
}: {
  requestId: string;
  resolutionId: number;
  resolutionName: string;
  authorizedAgentMessageMode?: AuthorizedAgentMessageMode;
  emailMessage?: string;
  emailTemplate?: string;
  attachmentIds?: string[];
  emailNotificationEnabled?: boolean;
  trackingNumber?: string;
}) =>
  api
    .post(`/requests/${requestId}/actions/close`, {
      resolutionId,
      resolutionName,
      emailMessage: emailNotificationEnabled && emailMessage != null ? b64EncodeUnicode(emailMessage) : null,
      emailTemplate: emailNotificationEnabled ? emailTemplate : null,
      attachmentIds,
      authorizedAgentMessageMode,
      emailNotificationEnabled,
      ...(trackingNumber && { trackingNumber }),
    })
    .then(({ data }) => data);

export const getResolutionParams = (requestId: string, completeOnly?: boolean): Promise<RequestCloseResolutionType[]> =>
  api
    .get(`/requests/${requestId}/close-resolutions${completeOnly !== undefined ? `?completeOnly=${completeOnly}` : ''}`)
    .then(({ data }) => data?.resolutions);

export const getResolutionEmail = (requestId: string, resolutionId?: string) =>
  api
    .get<{
      enabled: boolean;
      emailTemplate?: string;
      emailMessage?: string;
      authorizedAgentMessageMode?: AuthorizedAgentMessageMode;
    }>(`/requests/${requestId}/close-resolutions/${resolutionId}/email`)
    .then(({ data: { enabled, emailTemplate, emailMessage, authorizedAgentMessageMode } }) => ({
      enabled,
      emailTemplate,
      authorizedAgentMessageMode,
      emailMessage: emailMessage ? b64DecodeUnicode(emailMessage) : '',
    }));

export const uploadResolutionFile = (file: File, requestId: string, resolutionId: string): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);

  return api
    .post(`/requests/${requestId}/close-resolutions/${resolutionId}/attachments`, formData)
    .then(({ data }) => data?.uploadId);
};

export const abortCollectingData = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/collect/actions/abort-dsar`).then(resolve => resolve.data);

export const getReviewableFieldsFilters = async (requestId: string, prevFilters: ReportAttributeFilterType[] = []) =>
  await api
    .post<ReportAttributeFilterType[]>(`/requests/${requestId}/report-attribute-filters`, {
      prevFilters,
    })
    .then(resolve => resolve.data);

export const getRequestFilters = async () =>
  await api.get<RequestFilter>(`/request-filter`).then(resolve => resolve.data);

export const getPreferencesGridFilters = async (gridName: string) =>
  await api
    .get<{ filters: PreferencesGridFilter[] }>(`/preferences/grids/${gridName}/filters`)
    .then(resolve => resolve.data.filters);

export const getPreferencesGridFiltersOptions = async (
  gridName: string,
  data: { name: string; source: string; search?: string }[],
) =>
  await api
    .post<{
      filters: {
        name: string;
        options: { name: string; value: string }[];
      }[];
    }>(`/preferences/grids/${gridName}/options`, {
      filters: data,
    })
    .then(resolve => resolve.data.filters);

export const getReviewStageReviewableFields = async ({
  requestId,
  skip,
  limit,
  filter,
  search,
}: FindReviewableFieldsOptionsType) =>
  await api
    .post<GridFetchResponseType>(`/requests/${requestId}/reviewable-fields/grid`, {
      pagination: {
        offset: skip,
        size: limit,
      },
      fetchOptions: {
        filters: (filter || []).map(f => ({
          name: f.field,
          values: f.value,
        })),
        search,
      },
    })
    .then(resolve => resolve.data);

export const getUpdateStageSystemsFilters = async (requestId: string) =>
  await api
    .get<{
      names: string[];
      statuses: string[];
    }>(`/requests/${requestId}/stages/update/grid-filters`)
    .then(resolve => resolve.data);

export const exportReviewableFieldsCsv = (
  requestId: string,
  filters?: { name: string; values: (number | string)[] }[],
  search?: string,
  skip?: number,
  limit: number = CSV_RECORDS_LIMIT,
  isUpdateRequest?: boolean,
) =>
  api
    .post(
      `/requests/${requestId}/reviewable-fields/csv`,
      {
        pagination: {
          offset: skip,
          size: limit,
        },
        fetchOptions: {
          filters: [
            ...(filters ?? []),
            { name: 'included', values: ['true'] },
            ...(isUpdateRequest ? [{ name: 'updated', values: ['true'] }] : []),
          ],
          search,
        },
      },
      { responseType: 'blob' },
    )
    .then(response => {
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `reviewable-fields.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

export const exportReviewableFieldsCsvForSingleDS = ({
  requestId,
  search,
  skip,
  limit = CSV_RECORDS_LIMIT,
  id,
  isUpdateRequest,
}: {
  requestId: string;
  search?: string;
  skip?: number;
  limit?: number;
  id: number;
  isUpdateRequest?: boolean;
}) =>
  exportReviewableFieldsCsv(
    requestId,
    id ? [{ name: 'dataSource.dataSourceId', values: [id] }] : undefined,
    search,
    skip,
    limit,
    isUpdateRequest,
  );

export const exportReviewableFieldsCsvForMultipleDS = ({
  requestId,
  search,
  skip,
  limit = CSV_RECORDS_LIMIT,
  ids,
  isUpdateRequest,
}: {
  requestId: string;
  search?: string;
  skip?: number;
  limit?: number;
  ids: number[];
  isUpdateRequest?: boolean;
}) =>
  exportReviewableFieldsCsv(
    requestId,
    ids ? [{ name: 'dataSource.dataSourceId', values: ids }] : undefined,
    search,
    skip,
    limit,
    isUpdateRequest,
  );

export const getConsentPreferences = async (
  requestId: string,
  { skip = 0, limit = 30, sort = [], filter, ids = [] }: BigidGridQueryComponents & { ids?: number[] },
): Promise<{ data: UpdateConsentPreferencesGridRowType[]; totalCount: number }> =>
  await api
    .get(`/requests/${requestId}/user-preferences`, {
      params: {
        skip,
        limit,
        sort: getCellSortMarker(sort),
        filter: toStringQuery(filter),
        ids: ids.join(','),
      },
    })
    .then(res => res.data);

export const retryPreferenceUpdate = async (requestId: string, userPreferenceId: number) =>
  await api
    .post(`/requests/${requestId}/stages/update/user-preferences/${userPreferenceId}/actions/retry-update`)
    .then(res => res.data);

export const getOptOutDataUpdate = async (requestId: string): Promise<GridOptOutDataUpdateType> =>
  await api.get<GridOptOutDataUpdateType>(`/requests/${requestId}/user-preferences-systems`).then(res => res.data);

export const setOptOutSystemCompleted = async (requestId: string, system: string): Promise<void> =>
  await api
    .post<void>(`/requests/${requestId}/user-preferences-systems/${system}/edit-completion`)
    .then(res => res.data);

export const updateReviewableFields = async (
  requestId: string,
  fields: UpdateReviewableFieldType[],
): Promise<ReviewableFieldType[] | undefined> =>
  await api
    .post<{ fields: ReviewableFieldType[] } | undefined>(`/requests/${requestId}/reviewable-fields`, {
      fields,
    })
    .then(res => res.data?.fields);

export const fetchReviewDataNamesOptions = async (): Promise<string[]> =>
  api.get<{ values: string[] }>(`/reviewable-field-names`).then(res => res.data.values);

export const getReviewDataStageState = async (requestId: string): Promise<ReviewDataStateType> =>
  api.get<ReviewDataStateType>(`/requests/${requestId}/stages/review`).then(res => res.data);

export const getApproveDataStageState = async (requestId: string): Promise<ApproveDataStateType> =>
  api.get<ApproveDataStateType>(`/requests/${requestId}/stages/approve`).then(res => res.data);

export const fetchReviewDataPurposeOptions = async (): Promise<string[]> =>
  api.get<{ values: string[] }>(`/reviewable-field-purposes`).then(res => res.data.values);

export const fetchReviewDataCategoryOptions = async (): Promise<string[]> =>
  api.get<{ values: string[] }>(`/reviewable-field-categories`).then(res => res.data.values);

export const updateReviewDataNamesOptions = async (value: string): Promise<void> =>
  api.post(`/reviewable-field-names`, { value });

export const updateReviewDataPurposeOptions = async (value: string): Promise<void> =>
  api.post(`/reviewable-field-purposes`, { value });

export const updateReviewDataCategoryOptions = async (value: string): Promise<void> =>
  api.post(`/reviewable-field-categories`, { value });

export const getStages = async (requestId: string): Promise<StageResponseType[]> =>
  await api.get<StagesResponseType>(`/requests/${requestId}/stages`).then(resolve => resolve.data.stages);

export const postRequestApprove = async (requestId: string, verified: boolean): Promise<Record<string, unknown>> =>
  await api
    .post<Record<string, unknown>>(`/requests/${requestId}/stages/approve/actions/approve`, {
      closed: !verified, // approve -> closed: false
    })
    .then(resolve => resolve.data);

export const getUpdateStage = async (requestId: string): Promise<UpdateStageResponseType> =>
  await api.get<UpdateStageResponseType>(`/requests/${requestId}/stages/update`).then(resolve => resolve.data);

export const getUpdateStageDataSources = async ({
  requestId,
  sort,
  filter,
}: {
  requestId: string;
  sort?: BigidGridSorting[];
  filter?: BigidFilter;
}) => {
  const filtersWithoutSearch = removeSearchFromFilters(filter);
  const search = extractSearchFromFilters(filter);

  return await api
    .get<{
      data: UpdateDataSourceType[];
      totalCount: number;
    }>(`/requests/${requestId}/stages/update/data-sources`, {
      params: {
        sort: sort && getCellSortMarker(sort),
        search,
        filter: toStringQuery(filtersWithoutSearch) || [],
      },
    })
    .then(resolve => resolve.data);
};

export const getStageData = async <T>(requestId: string, stage: RequestStageType) =>
  api.get<T>(`/requests/${requestId}/stages/${stage}`).then(resolve => resolve.data);

export const SYSTEM_FIELDS_PAGE_SIZE = 10;

const mapFieldForSorting = (origin: string) => {
  if (origin === 'name') {
    return 'originalName';
  }

  if (origin === 'friendlyName') {
    return 'name';
  }

  return origin;
};

export const getUpdateStageReviewableFields = async ({
  requestId,
  dataSourceId,
  page,
  size,
  sortField,
  sortOrder,
  search,
  isUpdateRequest = true,
}: {
  requestId: string;
  dataSourceId: number;
  page?: number;
  size?: number;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  search?: string;
  isUpdateRequest?: boolean;
}): Promise<UpdateStageReviewableFieldsType> =>
  await api
    .post<UpdateStageReviewableFieldsType>(`/requests/${requestId}/reviewable-fields/grid`, {
      pagination: {
        offset: page ? page * SYSTEM_FIELDS_PAGE_SIZE : undefined,
        size: size || SYSTEM_FIELDS_PAGE_SIZE,
        sort: sortField ? `${sortOrder === 'asc' ? '+' : ''}${mapFieldForSorting(sortField)}` : undefined,
      },
      fetchOptions: {
        filters: [
          { name: 'dataSource.dataSourceId', values: [dataSourceId] },
          { name: 'included', values: ['true'] },
          ...(isUpdateRequest ? [{ name: 'updated', values: ['true'] }] : []),
        ],
        search,
      },
    })
    .then(resolve => resolve.data);

export const postReminderByAdmin = (requestId: string, rowId: number, userIds: number[], message = '') =>
  api.post(`/requests/${requestId}/stages/update/data-sources/${rowId}/actions/remind-collaborators`, {
    message,
    userIds,
  });

export const addDataSourceNote = async (
  requestId: string,
  rowId: number,
  notes = '',
  files: Array<UploadedFileType | FileType> = [],
): Promise<NoteType> =>
  api.post(`/requests/${requestId}/stages/update/data-sources/${rowId}/notes`, {
    content: notes,
    files,
  });

export const deleteDataSourceNote = async (requestId: string, rowId: number): Promise<NoteType> =>
  api.delete(`/requests/${requestId}/stages/update/data-sources/${rowId}/notes`);

export const markDoneDS = async (requestId: string, rowId: number): Promise<void> =>
  api.post(`/requests/${requestId}/stages/update/data-sources/${rowId}/actions/mark-done`);

export const markDoneDSs = async (requestId: string, dataSourceIds: number[]): Promise<void[]> =>
  api.post(`/requests/${requestId}/stages/update/actions/mark-data-sources-done`, {
    dataSourceIds,
  });
export const markExcludedDS = async (requestId: string, rowId: number): Promise<void> =>
  api.post(`/requests/${requestId}/stages/update/data-sources/${rowId}/actions/mark-excluded`);

export const markExcludedDSs = async (requestId: string, dataSourceIds: number[]): Promise<void[]> =>
  api.post(`/requests/${requestId}/stages/update/actions/mark-data-sources-excluded`, {
    dataSourceIds,
  });

export const markIncludedDS = async (requestId: string, rowId: number): Promise<void> =>
  api.post(`/requests/${requestId}/stages/update/data-sources/${rowId}/actions/mark-included`);

export const markIncludedDSs = async (requestId: string, dataSourceIds: number[]): Promise<void[]> =>
  api.post(`/requests/${requestId}/stages/update/actions/mark-data-sources-included`, {
    dataSourceIds,
  });

export const markManualCollectDoneSingleDatasource = async (requestId: string, rowId: number): Promise<void> =>
  api.post(`/requests/${requestId}/stages/collect/data-sources/${rowId}/actions/mark-manual-collect-done`);

export const markManualCollectDone = async (requestId: string, sdIds: number[]): Promise<void[]> => {
  return Promise.all(sdIds.map(id => markManualCollectDoneSingleDatasource(requestId, id)));
};

export const markUpdateStageDone = async (requestId: string): Promise<void> =>
  await api.post<void>(`/requests/${requestId}/stages/update/actions/mark-done`).then(resolve => resolve.data);

export const markCorrectionUpdateStageDone = async (requestId: string): Promise<void> =>
  await api
    .post<void>(`/requests/${requestId}/stages/update/actions/mark-user-comment-done`)
    .then(resolve => resolve.data);

export const uploadCustomReport = (requestId: string, formData: FormData) =>
  api.post<void>(`/requests/${requestId}/custom-report`, formData).then(resolve => resolve.data);

export const deleteCustomReport = (requestId: string) => api.delete<void>(`/requests/${requestId}/custom-report`);

export const markCustomReportAsDone = (requestId: string) =>
  api.post<void>(`/requests/${requestId}/stages/collect/actions/custom-report-done`).then(resolve => resolve.data);

export const refreshRequestDataSources = (requestId: string) =>
  api.post<void>(`/requests/${requestId}/stages/collect/actions/refresh-data-sources`).then(resolve => resolve.data);

export const getRequestManagerState = async () =>
  api.get<RequestManagerStateType>('/request-manager').then(({ data }) => data);

export const getSingleRequestState = async (requestId?: string) =>
  api.get<SingleRequestStateType>(`/single-request/${requestId}`).then(({ data }) => data);

export const deleteRecords = (
  requestId: string,
  datasourceId: number,
  method: RequestDeletionMethod,
  useAsDefault?: boolean,
) =>
  api.post(`/requests/${requestId}/stages/update/data-sources/${datasourceId}/actions/delete`, {
    method,
    useAsDefault,
  });

export const deleteRecordsByIds = async (requestId: string, dataSourceIds: number[], method: RequestDeletionMethod) =>
  api.post(`/requests/${requestId}/stages/update/actions/delete`, {
    dataSourceIds,
    method,
  });

export const getReportTemplates = () => api.get<ReportTemplate[]>('/agent-report-templates').then(({ data }) => data);

export const setReportTemplate = async (requestId: string, reportTemplateId: number) =>
  api.put<void>(`/requests/${requestId}/agent-report-template`, { reportTemplateId });

export const activatePrivacyRequests = () => api.post('/privacy');

export const uploadFileForSendEmail = (
  file: File,
  requestId: string,
  dataSourseId: number,
  stage: 'update' | 'collect',
) => {
  const formData = new FormData();
  formData.append('file', file);

  return api
    .post(`/requests/${requestId}/stages/${stage}/data-sources/${dataSourseId}/attachments`, formData)
    .then(({ data }) => data?.uploadId);
};
