import { api } from '../request/apiService';
import { BusinessUserType } from '../../types';
import { ReleasedFeatureType } from '../../types/ReleasedFeaturesTypes';
import { authService } from '../authentication';

export const getBusinessUser = async (userId: number, config?: any): Promise<BusinessUserType> =>
  await api.get<BusinessUserType>(`/business-users/${userId}`, config).then(resolve => resolve.data);

export const getBusinessUsers = async (): Promise<BusinessUserType[]> =>
  await api.get<{ users: BusinessUserType[] }>('/business-users').then(resolve => resolve.data?.users);

export const loginWithTenant = (tenantId: string) => {
  const baseUrl = process.env.REACT_APP_WORKFLOW_API_BASE_URL;
  const fromTenantId = authService.getUser()?.tenant;
  const url = `${baseUrl}/${fromTenantId}/login-with-tenant?loginWithTenantId=${tenantId}`;
  window.location.assign(url);
};

export const getUserReleasedFeatures = async (): Promise<ReleasedFeatureType[]> =>
  await api.get<{ data: ReleasedFeatureType[] }>(`/users/released-features`).then(resolve => resolve.data.data);

export const updateUserViewedFeatures = async (featureName: string) =>
  await api.post(`/users/viewed-features/${featureName}`);
