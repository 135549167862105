import React, { FC, ReactNode } from 'react';
import { useMatch } from 'react-router-dom';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import { Tutorial } from '../Tutorial';
import { TutorialContainer } from '../../state/tutorialContainer';

import { useStyles } from './RootWrapperStyles';
import { NewFeatureOnboarding } from '../NewFeatureOnboarding/NewFeatureOnboarding';
import { NewFeatureContainer } from '../../state/newFeatureContainer';
import { useEmbeddedApp } from '../../hooks';

type RootWrapperPropType = {
  children?: ReactNode;
};

export const RootWrapper: FC<RootWrapperPropType> = ({ children }) => {
  const { isEmbeddedInIframe } = useEmbeddedApp();
  const classes = useStyles({ isEmbeddedInIframe });
  const isShowingOnboardingPage = !!useMatch('/onboarding');
  const { tutorialChecklist } = TutorialContainer.useContainer();
  const { newFeaturesList } = NewFeatureContainer.useContainer();

  return isEmbeddedInIframe ? (
    <div className={classes.root}>
      <div className={classes.wrapper}>{children}</div>
    </div>
  ) : (
    <div className={classes.root}>
      <>
        <Header logoClickable={!isShowingOnboardingPage} />
        <>
          <Sidebar disabled={isShowingOnboardingPage} />
          {!isShowingOnboardingPage && !tutorialChecklist.completionConfirmed && tutorialChecklist.tutorialEnabled && (
            <Tutorial />
          )}
          {newFeaturesList.length > 0 && <NewFeatureOnboarding />}
        </>
      </>
      <div className={classes.wrapper}>{children}</div>
    </div>
  );
};
