export interface CreateAccountValidationType {
  firstUser: boolean;
}

export interface ValidationCompanyNameType {
  companyValid: boolean;
  tenantId: string;
}

export enum ConsentsActionType {
  ACCEPT = 'ACCEPT',
  DECLINE = 'DECLINE',
}

export enum ConsentsExternalIdType {
  SEND_EMAILS = 'SEND_EMAILS',
  PRIVACY_NOTICE = 'PRIVACY_NOTICE',
  USERCENTRICS_TERMS_AND_CONDITIONS = 'USERCENTRICS_TERMS_AND_CONDITIONS',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
}

export interface CreateAccountType {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  captcha: string | null;
  consents: ConsentType[];
}

export interface ConsentType {
  externalId: ConsentsExternalIdType;
  version: string;
  action: ConsentsActionType;
}
