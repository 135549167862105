import { styled } from '@mui/material/styles';

const PREFIX = 'WebhooksTable';

export const classes = {
  tr: `${PREFIX}-tr`,
  dashCentering: `${PREFIX}-dashCentering`,
  emptyTable: `${PREFIX}-emptyTable`,
  activeTd: `${PREFIX}-activeTd`,
  triggerTd: `${PREFIX}-triggerTd`,
  default: `${PREFIX}-default`,
};

export const Root = styled('div')(({ theme }) => ({
  [`& .${classes.tr}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.emptyTable}`]: {
    display: 'flex',
    height: 56,
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.vars.palette.bigid?.white,
    color: theme.vars.palette.bigid?.gray500,
  },
  [`& .${classes.dashCentering}`]: {
    paddingLeft: 33,
  },
  [`& .${classes.activeTd}`]: {
    textAlign: 'end',
    width: 50,
  },
  [`& .${classes.activeTd} span`]: {
    display: 'inline-flex',
  },
  [`& .${classes.triggerTd}`]: {
    width: 420,
  },
  [`& .${classes.default}`]: {
    color: theme.vars.palette.bigid?.gray500,
  },
}));
