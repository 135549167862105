import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 50,
  },
  content: {
    border: `1px solid ${theme.vars.palette.bigid?.blue150}!important`,
    backgroundColor: `${theme.vars.palette.bigid?.blue100}!important`,
    padding: '3px 10px 3px 16px!important',
    minWidth: '420px!important',
    borderRadius: '8px!important',
    boxShadow: 'none!important',
    color: `${theme.vars.palette.bigid?.gray700}!important`,
    fontSize: '0.81rem!important',
    fontWeight: '400px!important',
  },
  icon: {
    width: '0.6em',
    height: '0.6em',
  },
  info: {
    border: `1px solid ${theme.vars.palette.bigid?.blue150}!important`,
    backgroundColor: `${theme.vars.palette.bigid?.blue100}!important`,
    '& > div > svg': {
      color: theme.vars.palette.bigid?.blue700,
    },
  },
  error: {
    backgroundColor: `${theme.vars.palette.bigid?.red100}!important`,
    border: `1px solid ${theme.vars.palette.bigid?.red150}!important`,
    '& > div > svg': {
      color: theme.vars.palette.bigid?.red500,
    },
  },
  success: {
    backgroundColor: `${theme.vars.palette.bigid?.green100}!important`,
    border: `1px solid ${theme.vars.palette.bigid?.green150}!important`,
    '& > div > svg': {
      color: theme.vars.palette.bigid?.green700,
    },
  },
  warning: {
    backgroundColor: `${theme.vars.palette.bigid?.orange100}!important`,
    border: `1px solid ${theme.vars.palette.bigid?.orange200}!important`,
    '& > div > svg': {
      color: theme.vars.palette.bigid?.yellow500,
    },
  },
}));
