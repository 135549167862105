import React, { FC } from 'react';
import { AppealGuidedTourStepTemplate } from './AppealGuidedTourStepTemplate';

interface AllowAppealStepProps {
  dataAid?: string;
}

export const AllowAppealStep: FC<AllowAppealStepProps> = ({ dataAid }) => {
  return (
    <AppealGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Add link to Appeal"
      firstSectionText='Toggle on "Allow Appeal" and customize the text'
    />
  );
};
