import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    maxWidth: 855,
    borderRadius: 12,
  },
  subtitle: {
    color: theme.vars.palette.bigid?.gray700,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '30%',
  },
  purpleTitle: {
    margin: '12px 0 12px',
    padding: '8px 0',
    fontSize: '0.875rem',
    fontWeight: 700,
    color: theme.vars.palette.bigid?.primary500,
  },
  description: {
    color: theme.vars.palette.bigid?.gray700,
    fontSize: '0.8125rem',
    margin: '8px 0 24px',
  },
  allowOutsideRequestor: {
    color: theme.vars.palette.bigid?.gray600,
    fontSize: '0.8125rem',
    margin: '10px 0',
  },
  allowOutsideRequestorLabel: {
    color: theme.vars.palette.bigid?.gray600,
    fontSize: '0.8125rem',
  },
  languagePicker: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  dropdownTitle: {
    display: 'flex',
    paddingRight: 15,
    alignItems: 'center',
  },
  dropdownTitleOptional: {
    color: theme.vars.palette.bigid?.gray500,
    paddingLeft: 3,
    paddingRight: 5,
  },
  accordionHeading: {
    marginTop: 16,
    marginBottom: 16,
    '&& .MuiIconButton-root': {
      marginLeft: '7px',
    },
  },
  accordionBody: {
    background: theme.vars.palette.bigid?.white,
    '&& .MuiIconButton-root': {
      marginLeft: '7px',
    },
  },
}));
