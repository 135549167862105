import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { UserTenantType } from '../types';

export interface Auth {
  isAuthenticated: boolean;
  fullName: string;
  id: number;
  tenants: UserTenantType[];
  currentTenant?: string;
  hasError?: boolean; // TODO
}

export const useAuth = (initial: Auth = { isAuthenticated: false, fullName: '', id: 0, tenants: [] }) => {
  const [auth, setAuth] = useState<Auth>(initial);
  return { auth, setAuth };
};

export const AuthContainer = createContainer(useAuth);
