import makeStyles from '@mui/styles/makeStyles';
import { BORDER_RADIUS, paperBoxShadow, primaryBorder } from '../../assets/styles';

import prev from '../../assets/images/pdfViewer/prev-page.png';
import next from '../../assets/images/pdfViewer/next-page.png';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: theme.vars.palette.bigid?.white,
    color: theme.vars.palette.bigid?.gray600,
  },
  previewRoot: {
    height: '221px',
    width: '354px',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    border: primaryBorder,
    borderRadius: '12px',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    paddingBottom: 55,
    '& div': {
      marginTop: 20,
      fontSize: '0.813rem',
    },
    '& p': {
      fontWeight: '700',
      fontSize: '0.813rem',
    },
  },
  preview: {
    position: 'absolute',
    bottom: '1px',
    left: '1px',
    right: '1px',
    padding: '16px 0',
    height: '55px',
    textAlign: 'center',
    borderTop: `1px solid ${theme.vars.palette.bigid?.gray400}`,
    borderRadius: '0 0 12px 12px',
    boxShadow: paperBoxShadow,
    backgroundColor: theme.vars.palette.bigid?.white,
    cursor: 'pointer',
    margin: '0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      padding: '5px',
      color: theme.vars.palette.bigid?.gray600,
      fontSize: '0.875rem',
    },
    '& div': {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  nextPageBtn: {
    margin: '0 15px',
    color: theme.vars.palette.bigid?.gray700,
    width: 20,
    height: 14,
    padding: '5px',
    border: 'none',
    backgroundImage: `url(${next})`,
    backgroundPosition: '6px 0',
    backgroundSize: 'contain',
    cursor: 'pointer',
    '& span': {
      display: 'none',
    },
  },
  previousPageBtn: {
    margin: '0 15px',
    color: theme.vars.palette.bigid?.gray700,
    width: 20,
    height: 14,
    padding: '5px',
    border: 'none',
    backgroundImage: `url(${prev})`,
    backgroundSize: 'contain',
    cursor: 'pointer',
    '& span': {
      display: 'none',
    },
  },
  navbarWrapper: {
    position: 'absolute',
    top: 0,
    textAlign: 'center',
    width: '100%',
    padding: '5px 0',
    borderRadius: BORDER_RADIUS,
    '&:hover': {
      background: theme.vars.palette.bigid?.primary50,
    },
  },
  padding: {
    padding: 10,
  },
}));
