import React, { FC } from 'react';
import { TooltipRenderProps } from '@bigid-ui/guided-tour';

import lottieJson from '../../../../assets/lotties/AppealTourLottieFileAnimation.json';
import { StartTooltip } from '../../../GuidedTour/tooltips/StartTooltip';

export const AppealStartTooltip: FC<TooltipRenderProps> = ({ ...props }: TooltipRenderProps) => (
  <StartTooltip
    {...props}
    title="Appeal a request"
    description="Enable requestors to appeal the results of a previous request."
    lottie={lottieJson}
  />
);
