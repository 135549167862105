import makeStyles from '@mui/styles/makeStyles';
import { BigidColorsV2 } from '@bigid-ui/components';
import { chartColors, paperBoxShadow } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  trendWrapper: {
    padding: '20px',
    borderRadius: 12,
    boxShadow: paperBoxShadow,
    backgroundColor: theme.vars.palette.bigid?.white,
    width: '50%',
    '&:nth-child(even)': {
      marginLeft: '20px',
    },
  },
  trendHeader: {
    height: 43,
    borderBottom: `1px solid ${BigidColorsV2.gray[400]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 11,
  },
  trendTitle: {
    color: chartColors.chipValueColor,
    fontWeight: 'bold',
    margin: 0,
  },
  horizontalBarRoot: {
    height: 202,
    padding: '16px 16px 0 16px',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
}));
