import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: ({ width, progress }: { width: number | string; progress: number | string }) => ({
    width,
    backgroundColor: theme.vars.palette.bigid?.gray200,
    borderRadius: 60,
    display: 'inline-flex',
    '&::before': {
      content: '""',
      width: progress,
      height: 4,
      margin: 0,
      borderRadius: 60,
      background: theme.vars.palette.bigid?.primary600,
    },
  }),
}));
