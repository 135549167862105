import React, { FC } from 'react';
import { BigidDialog } from '@bigid-ui/components';
import { usePreventNavigation } from '../../../hooks/usePreventNavigation';
import { usePreventLeavePage } from '../../../hooks/usePreventLeavePage';

export const RequestCustomizationExitDialog: FC<any> = ({
  showWarningOnLeave,
  isDirty,
  buttonCancel,
  buttonApply,
  onClose,
}) => {
  const { showWarning, retry, reset } = usePreventNavigation(isDirty);
  usePreventLeavePage(isDirty);

  return (
    <BigidDialog
      title="You have unsaved changes"
      isOpen={showWarningOnLeave || showWarning}
      buttons={[
        {
          ...buttonCancel,
          onClick: () => {
            retry && retry();
            buttonCancel.onClick();
          },
        },
        {
          ...buttonApply,
          onClick: () => {
            reset && reset();
            buttonApply.onClick();
          },
        },
      ]}
      onClose={() => {
        reset && reset();
        onClose();
      }}
    >
      Your changes will be lost. Are you sure you want to close?
    </BigidDialog>
  );
};
