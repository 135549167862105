import React, { FC } from 'react';
import { Root, classes } from './RequestStageTableStyles';
import { debounce } from 'lodash';

import { RequestWorkflowStageEmailType, StageConfigType, StageEmailConfigurationType } from '../../../types';
import { SimpleTable } from '../../../components/SimpleTable';
import { RequestStageRow } from './RequestStageRow';

const TABLE_EMAIL_HEADERS = ['Names', 'Triggers', 'Last Update', 'Enabled', ''];
const TABLE_EMAIL_HEADERS_CLASSES = ['', classes.triggerTd, '', classes.activeTd, classes.activeTd];

interface RequestStageTablePropsType {
  rows: RequestWorkflowStageEmailType[];
  rowsToUpdate?: RequestWorkflowStageEmailType[];
  isConfirmSection?: boolean;
  onRowClick: (configType: StageConfigType, row: StageEmailConfigurationType) => void;
  onStageEmailActiveSwitch: (args: {
    rowsData: RequestWorkflowStageEmailType[];
    row: RequestWorkflowStageEmailType;
    id: number;
    checked: boolean;
    isConfirmSection?: boolean;
  }) => void;
}

export const RequestStageTable: FC<RequestStageTablePropsType> = ({
  rows,
  rowsToUpdate,
  isConfirmSection,
  onRowClick,
  onStageEmailActiveSwitch,
}) => {
  return (
    <Root>
      <SimpleTable
        th={TABLE_EMAIL_HEADERS}
        thClasses={TABLE_EMAIL_HEADERS_CLASSES}
        bodyTestId="general-requests-table"
        trElement={
          <RequestStageRow
            rows={rows}
            rowsToUpdate={rowsToUpdate}
            isConfirmSection={isConfirmSection}
            onRowClick={onRowClick}
            onStageEmailActiveSwitch={debounce(onStageEmailActiveSwitch, 300)}
          />
        }
      />
    </Root>
  );
};
