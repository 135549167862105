import React, { FC, useState } from 'react';
import { BigidMenu, BigidSwitch } from '@bigid-ui/components';
import { useRefMap } from 'react-use-ref-map';
import { BigidMoreActionIcon } from '@bigid-ui/icons';
import { IconButton } from '@mui/material';
import { Root, classes } from './WebhooksTableStyles';

import { WebhookType } from '../../../types';
import { SimpleTable } from '../../../components/SimpleTable';
import { useFormatting } from '../../../hooks/useFormatting';
import { updateWebhook } from '../../../services/settingsService';
import { SnackbarUtils } from '../../../utils';
import { webhookReason } from '../WebhookCustomization/WebhookCustomization';

const TABLE_TRIGGERS_HEADERS = ['Action Names', 'Type', 'When Triggered', 'Last Update', 'Enabled', ''];
const TABLE_TRIGGERS_HEADERS_CLASSES = ['', '', '', '', classes.activeTd, classes.activeTd, ''];

interface WebhooksTablePropsType {
  rows?: WebhookType[];
  setDeleteDialogData: (id: number) => void;
  onRowClick: (row: WebhookType) => void;
  reloadTable: () => void;
}

export const WebhooksTable: FC<WebhooksTablePropsType> = ({ rows, reloadTable, setDeleteDialogData, onRowClick }) => {
  const [setRef, refs] = useRefMap();
  const [openIndexMenu, setOpenIndexMenu] = useState<number>(NaN);
  const { getFormattedDate } = useFormatting();

  const onDeleteClick = () => {
    setDeleteDialogData(openIndexMenu);
  };

  const onWebhookEnableSwitch = async (row: WebhookType, enabled: boolean) => {
    await updateWebhook({ ...row, enabled });
    SnackbarUtils.toast(<Root>{`${row.name} ${!enabled ? 'not' : ''} active`}</Root>);
    reloadTable();
  };

  if (!rows || rows.length === 0) {
    return (
      <Root>
        <SimpleTable th={TABLE_TRIGGERS_HEADERS}>
          <tr>
            <td className={classes.emptyTable}>No API calls added</td>
          </tr>
        </SimpleTable>
      </Root>
    );
  }
  return (
    <Root>
      <SimpleTable
        th={TABLE_TRIGGERS_HEADERS}
        thClasses={TABLE_TRIGGERS_HEADERS_CLASSES}
        bodyTestId={`webhook-${rows[0].name}`}
        tr={rows.map((row: WebhookType) => (
          <tr key={row.id} className={classes.tr} data-aid={`webhook-tr${row.id}`} onClick={() => onRowClick(row)}>
            <td>{row.name}</td>
            <td>{row.method}</td>
            <td>{webhookReason.find(({ id }) => row.trigger === id)?.displayValue}</td>
            <td>
              {row.modificationDate ? (
                getFormattedDate(row.modificationDate, {
                  hour: 'numeric',
                  minute: 'numeric',
                })
              ) : (
                <span className={classes.dashCentering}>-</span>
              )}
            </td>
            <td className={classes.activeTd} onClick={event => event.stopPropagation()}>
              <BigidSwitch
                checked={row.enabled}
                dataAid={`${row.id}-enabled__switch`}
                onChange={(event, checked) => onWebhookEnableSwitch(row, checked)}
              />
            </td>
            <td className={classes.activeTd} onClick={event => event.stopPropagation()}>
              <IconButton
                onClick={() => setOpenIndexMenu(row.id)}
                ref={setRef(row.id)}
                size="small"
                data-aid={`webhook-${row.id}-menu-action-${row.id}`}
              >
                <BigidMoreActionIcon />
              </IconButton>
            </td>
          </tr>
        ))}
      />
      <BigidMenu
        open={!!openIndexMenu}
        anchorEl={refs.current[openIndexMenu]}
        items={[
          {
            id: 'delete',
            label: 'Delete',

            dataAid: 'webhook-delete-action',
            onClick: onDeleteClick,
          },
        ]}
        onMenuClose={() => setOpenIndexMenu(NaN)}
      />
    </Root>
  );
};
