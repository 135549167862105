import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { api } from './request';
import {
  DataSourceScopeType,
  DatasourcesGridResponseType,
  DatasourcesGridType,
  DatasourcesProviderType,
  DatasourcesResponseType,
  GenericConnectorPlaceHolder,
  ScopeInfoType,
  SignInResponseType,
  SignInValidationRequestType,
  SignInValidationResponseType,
  SystemDataSourceType,
  SystemsSorting,
  SystemInfoType,
  CreateDatasourseType,
  SettingsProfileGetType,
  UseCaseGetType,
} from '../types';
import { getCellSortMarker } from '../utils';
import { BigidGridDataFetchResult } from '@bigid-ui/grid';

export const loadAvailableDataSources = async () =>
  await api
    .get<{ dataSources: DatasourcesGridType[] }>('/data-sources', {
      params: {
        skip: 0,
        limit: 10000,
      },
    })
    .then(response => response.data.dataSources);

// TODO: add pagination via { skip, limit } parameters to support infinite scroll UI feature
export const getSystemDataSourceTypes = async (
  search = '',
  sort = SystemsSorting.PRIORITY,
): Promise<{ datasources: SystemDataSourceType[] }> =>
  await api
    .get<DatasourcesResponseType>(`/data-source-types`, {
      params: {
        search,
        sort: `+${sort}`,
      },
    })
    .then(({ data: { dataSourceTypes = [] } }) => ({
      datasources: dataSourceTypes,
    }));

export const getPopularDataproviderTypes = async (
  search = '',
  sort = SystemsSorting.PRIORITY,
): Promise<SystemDataSourceType[]> =>
  await api
    .get<DatasourcesResponseType>(`/data-source-types/popular`, {
      params: {
        search,
        sort: `+${sort}`,
      },
    })
    .then(({ data: { dataSourceTypes = [] } }) => [...dataSourceTypes]);

export const getDataproviderTypes = async (
  search = '',
  sort = SystemsSorting.PRIORITY,
): Promise<SystemDataSourceType[]> =>
  await api
    .get<DatasourcesResponseType>(`/data-source-types/supported`, {
      params: {
        search,
        sort: `+${sort}`,
      },
    })
    .then(({ data: { dataSourceTypes = [] } }) => [...dataSourceTypes]);

export const getScopesOptions = async () =>
  await api
    .get<{ scopes: DataSourceScopeType[] }>('/data-source-scopes', { params: { all: 'true' } })
    .then(res => res.data.scopes);

export const loadScopes = async (search: string, { skip, limit }: BigidGridQueryComponents) =>
  await api
    .get<BigidGridDataFetchResult<DataSourceScopeType>>('/data-source-scopes', { params: { search, skip, limit } })
    .then(res => res.data);

export const loadScopesById = async (id: string) =>
  await api.get<ScopeInfoType>(`/data-source-scopes/${id}`).then(res => res.data);

export const createScope = async (name: string, dataSourceIds: Array<string | number>) =>
  await api.post('/data-source-scopes', { name, dataSourceIds }).then(res => res.data);

export const deleteScope = async (id: string) => await api.delete(`/data-source-scopes/${id}`).then(res => res.data);

export const saveScope = async (id: string, name: string) =>
  await api.patch(`/data-source-scopes/${id}`, { name }).then(res => res.data);

export const removeDataSourcesFromScope = async (id: string | number, dataSourceIds: Array<string | number>) =>
  await api.delete(`/data-source-scopes/${id}/data-sources?dataSourceIds=${dataSourceIds}`).then(res => res.data);

export const addDataSourcesToScope = async (id: string | number, dataSourceIds: Array<string | number>) =>
  await api.put(`/data-source-scopes/${id}/data-sources`, { dataSourceIds }).then(res => res.data);

export const getDataSources = async ({
  search,
  args: { skip, limit, sort = [] } = {},
  scopeId,
  excludeScopeId,
}: {
  search?: string;
  args?: BigidGridQueryComponents;
  scopeId?: string | number;
  excludeScopeId?: string | number;
}): Promise<DatasourcesGridType[]> =>
  await api
    .get<DatasourcesGridResponseType>(`/data-sources`, {
      params: {
        scopeId,
        excludeScopeId,
        skip,
        limit,
        sort: getCellSortMarker(sort),
        search,
      },
    })
    .then(resolve => resolve.data.dataSources);

export const getScopeDataSources = async ({
  search,
  args: { skip, limit, sort = [] } = {},
  scopeId,
  excludeScopeId,
}: {
  search?: string;
  args?: BigidGridQueryComponents;
  scopeId?: string | number;
  excludeScopeId?: string | number;
}): Promise<DatasourcesGridType[]> =>
  await api
    .get<DatasourcesGridResponseType>(`/data-source-scopes/data-sources`, {
      params: {
        scopeId,
        excludeScopeId,
        skip,
        limit,
        sort: getCellSortMarker(sort),
        search,
      },
    })
    .then(resolve => resolve.data.dataSources);

export const createDatasourceType = (name?: string) =>
  api
    .post<SystemDataSourceType>('/data-source-types', {
      name,
      displayName: name,
      supported: true,
      providerType: DatasourcesProviderType.CUSTOM,
    })
    .then(({ data }) => data);

export const deleteDatasourceType = (id: number) => api.delete<SystemDataSourceType>(`/data-source-types/${id}`);

export const createSystem = async (createDatasource: CreateDatasourseType): Promise<SystemInfoType> =>
  await api.post<SystemInfoType>(`/data-sources`, createDatasource).then(resolve => resolve.data);

export const editSystem = async ({
  name,
  scopeIds,
  profileIds,
  authId,
  externalId,
  id,
  thirdParty,
  supportedOperations,
  dataSourceAccessDtos,
}: CreateDatasourseType): Promise<SystemInfoType> =>
  await api
    .put<SystemInfoType>(`/data-sources/${id}`, {
      name,
      scopeIds,
      profileIds,
      authId,
      externalId,
      thirdParty,
      supportedOperations,
      dataSourceAccessDtos,
    })
    .then(resolve => resolve.data);

export const signinDatasource = async (dataSourceTypeId: number): Promise<SignInResponseType> =>
  await api
    .post<SignInResponseType>(`/data-sources/sign-in`, {
      dataSourceTypeId,
    })
    .then(resolve => resolve.data);

export const validateDatasourceSignIn = async (
  request: SignInValidationRequestType,
): Promise<SignInValidationResponseType> =>
  await api
    .post<SignInValidationResponseType>(`/data-sources/sign-in-validations`, request)
    .then(resolve => resolve.data);

export const getSystemById = async (id: number): Promise<SystemInfoType> =>
  await api.get<SystemInfoType>(`/data-sources/${id}`).then(resolve => resolve.data);

export const deleteDatasourceById = async (id: number): Promise<void> => await api.delete(`/data-sources/${id}`);

export const getGenericConnectorPlaceHolders = (): Promise<GenericConnectorPlaceHolder[]> =>
  api
    .get<GenericConnectorPlaceHolder[]>('/generic-connector/request-composer/place-holders')
    .then(response => response.data);

export const refreshSignin = async (id: number) => await api.put(`/data-sources/${id}/refresh-sign-in`);

export const getDataSourceOptions = async () =>
  await api
    .get<{
      useCases: UseCaseGetType[];
      departments: DataSourceScopeType[];
      profiles: SettingsProfileGetType[];
    }>('/data-sources/data-source-options')
    .then(res => res.data);
