import React from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidBody1,
  BigidCaption,
  BigidDialog,
  BigidHeading4,
  BigidScheduleEventPicker,
  BigidSidePanel,
  BigidSwitch,
  BigidTextField,
  BigidTooltip,
  PrimaryButton,
  ScheduledIdentifierOptions,
  TertiaryButton,
} from '@bigid-ui/components';
import { Box, Stack } from '@mui/system';
import { Portal } from '@mui/base';
import { get } from 'lodash';
import { useToggle } from '../../../hooks';
import { BigidEditIcon, BigidMessageIcon } from '@bigid-ui/icons';
import { LanguageType } from '@consumer/state/languageContainer';
import { primaryBorder } from '../../../assets/styles';
import { classes, ScheduleEventPickerStyled } from './DueDateNotificationsStyles';
import { DueDateNotificationsContainer } from '../hooks/useDueDateNotifications';
import { useSnackbar } from 'notistack';
import { BigidMeTooltipWithIcon } from '../../../components';
import { DEFAULT_CRON_START } from '../../../utils/dateTimeFormat';

const selectedLanguage = LanguageType.EN;

export const DueDateNotifications = () => {
  const { value: isOpenSettings, setFalse: closeSettings, setTrue: openSettings } = useToggle(false);
  const { value: isOpenSaveDialog, setFalse: closeSaveDialog, setTrue: openSaveDialog } = useToggle(false);

  const { settingsFormik, sendTestEmail, handleDueDateNotificationsEmailUpdate, emailTemplateFormik } =
    DueDateNotificationsContainer.useContainer();

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseEmailSideBar = () => {
    closeSettings();
    emailTemplateFormik.resetForm();
  };

  const handleSaveEmailChanges = async () => {
    await handleDueDateNotificationsEmailUpdate();
    closeSaveDialog();
    closeSettings();
    enqueueSnackbar('Changes saved');
  };

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between" mb={2}>
        <Stack flexDirection="row" alignItems="center">
          <BigidSwitch
            onChange={(_, checked) => settingsFormik.setFieldValue('enabled', checked)}
            checked={settingsFormik.values.enabled}
            dataAid="due-date-notification-enabled"
            size="small"
          />
          <BigidHeading4>Send Due Date Notification</BigidHeading4>
        </Stack>
        <TertiaryButton
          startIcon={<BigidEditIcon />}
          text="Edit email"
          dataAid="due-date-notification-edit-email-button"
          onClick={openSettings}
          size="medium"
        />
      </Stack>
      <BigidBody1 mb={2}>{"Send a summary of open request that are 'Overdue' or 'Almost Overdue'"}</BigidBody1>
      <BigidBody1 mb={1}>
        Repeat
        <Box
          ml={1}
          display="inline"
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <BigidMeTooltipWithIcon title="Times shown are in UTC" />
        </Box>
      </BigidBody1>
      <Stack flexDirection="row" alignItems="center" mb={2}>
        <ScheduleEventPickerStyled>
          <BigidScheduleEventPicker
            startTime={settingsFormik.values.cron || DEFAULT_CRON_START}
            onChange={value => value.isValid && settingsFormik.setFieldValue('cron', value.cronExpressionStart)}
            includedIdentifiers={[
              ScheduledIdentifierOptions.DAILY,
              ScheduledIdentifierOptions.MONTHLY,
              ScheduledIdentifierOptions.WEEKLY,
            ]}
            onError={m => console.log('ScheduleEvent error', m)}
            selectProps={{
              className: classes.select,
            }}
            key={settingsFormik.values.cron}
          />
        </ScheduleEventPickerStyled>
      </Stack>
      <Portal>
        <BigidSidePanel
          open={isOpenSettings}
          content={
            <>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
                padding="10px 24px 10px 0"
                borderBottom={primaryBorder}
              >
                <BigidTooltip title={`Click to send yourself a preview test email`} placement="bottom" width="210px">
                  <TertiaryButton
                    margin="0 16px"
                    startIcon={<BigidMessageIcon />}
                    text="Send Test Email"
                    dataAid="send-test-email_button"
                    onClick={sendTestEmail}
                    size="medium"
                  />
                </BigidTooltip>
                <PrimaryButton
                  text="Save Changed"
                  onClick={handleSaveEmailChanges}
                  size="medium"
                  disabled={!emailTemplateFormik.isValid}
                  dataAid="save-email-changes-button"
                />
              </Stack>
              <Box m={1}>
                <BigidAccordion
                  defaultExpanded
                  style={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                >
                  <BigidAccordionSummary size={AccordionSummarySizeEnum.large}>
                    <BigidCaption size="large">
                      Email message{' '}
                      <Box
                        display="inline"
                        onClick={event => {
                          event.stopPropagation();
                        }}
                      >
                        <BigidMeTooltipWithIcon title="You may insert {{number}} as a variable counting the number of records." />
                      </Box>
                    </BigidCaption>
                  </BigidAccordionSummary>
                  <BigidAccordionDetails sx={{ padding: '2px 0 !important' }}>
                    <Box mr={5} ml={6}>
                      <Box mb={2}>
                        <BigidTextField
                          required
                          errorMessage={get(emailTemplateFormik.errors, `translations[${selectedLanguage}].subject`)}
                          value={emailTemplateFormik.values.translations?.[selectedLanguage]?.subject}
                          label="Subject"
                          dataAid="due_date_notifications_subject_field"
                          onChange={value =>
                            emailTemplateFormik.setFieldValue(
                              `translations[${selectedLanguage}].subject`,
                              value.target.value,
                            )
                          }
                          size="medium"
                          type="text"
                        />
                      </Box>
                      <Box mb={2}>
                        <BigidTextField
                          required
                          errorMessage={get(emailTemplateFormik.errors, `translations[${selectedLanguage}].header`)}
                          value={emailTemplateFormik.values.translations?.[selectedLanguage]?.header}
                          label="Header"
                          dataAid="due_date_notifications_header_field"
                          onChange={value =>
                            emailTemplateFormik.setFieldValue(
                              `translations[${selectedLanguage}].header`,
                              value.target.value,
                            )
                          }
                          size="medium"
                          type="text"
                        />
                      </Box>
                      <Box mb={2}>
                        <BigidTextField
                          required
                          errorMessage={get(emailTemplateFormik.errors, `translations[${selectedLanguage}].buttonText`)}
                          value={emailTemplateFormik.values.translations?.[selectedLanguage]?.buttonText}
                          label="Button"
                          dataAid="due_date_notifications_buttonText_field"
                          onChange={value =>
                            emailTemplateFormik.setFieldValue(
                              `translations[${selectedLanguage}].buttonText`,
                              value.target.value,
                            )
                          }
                          size="medium"
                          type="text"
                        />
                      </Box>
                    </Box>
                  </BigidAccordionDetails>
                </BigidAccordion>
              </Box>
            </>
          }
          maxWidth="medium"
          onClose={emailTemplateFormik.dirty ? openSaveDialog : handleCloseEmailSideBar}
          title="Notification Email"
          dataAid="email-template__slider"
          isShowBackdrop
        />
      </Portal>
      <BigidDialog
        title="You have unsaved changes"
        isOpen={isOpenSaveDialog}
        buttons={[
          {
            component: TertiaryButton,
            text: 'Close without saving',
            onClick: () => {
              closeSaveDialog();
              handleCloseEmailSideBar();
            },
            dataAid: 'workflow-settings-save-dialog__cancel',
          },
          {
            component: PrimaryButton,
            text: 'Save',
            onClick: handleSaveEmailChanges,
            disabled: !emailTemplateFormik.isValid,
            dataAid: 'workflow-settings-save-dialog__apply',
          },
        ]}
        onClose={closeSaveDialog}
      >
        Your changes will be lost. Are you sure you want to close?
      </BigidDialog>
    </>
  );
};
