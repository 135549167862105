import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  tooltip: {
    width: 300,
    maxWidth: 300,
    pointerEvents: 'auto',
  },
  content: {
    padding: 12,
    textAlign: 'left',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    paddingBottom: 5,
    fontSize: '0.9375rem',
    fontWeight: 600,
    borderBottom: '1px solid #E9E9E9',
  },
  closeIcon: {
    width: 15,
    cursor: 'pointer',
  },
  closeIconWrapper: {
    marginLeft: 'auto',
    display: 'flex',
  },
});
