import BigidMeIconViewSrc from '../../assets/illustrations/consumer-icon-view.svg';
import BigidMeIconUpdateSrc from '../../assets/illustrations/consumer-icon-update.svg';
import BigidMeIconDeleteSrc from '../../assets/illustrations/consumer-icon-delete.svg';
import BigidMeIconPreferencesSrc from '../../assets/illustrations/consumer-icon-preferences.svg';
import BigidMeIconOptOutSrc from '../../assets/illustrations/consumer-icon-optout.svg';
import BigidMeIconAppealtSrc from '../../assets/illustrations/consumer-icon-appeal.svg';
import { RequestType, FeatureCode } from '../../types';

type UseCasesType = Record<
  RequestType,
  {
    title: string;
    description: string;
    descriptionShort: string;
    iconSrc: string;
    featureCode?: FeatureCode;
  }
>;

export const UseCases: UseCasesType = {
  [RequestType.VIEW]: {
    title: 'View Data',
    description: "Generate a report of the requester's personal data.",
    descriptionShort: `Generate a report of your user's personal data.`,
    iconSrc: BigidMeIconViewSrc,
  },
  [RequestType.UPDATE]: {
    title: 'Edit Data',
    description: 'Allow the requester to modify their personal data, such as name, email, or phone.',
    descriptionShort: `Allow the user to modify their personal data`,
    iconSrc: BigidMeIconUpdateSrc,
    featureCode: FeatureCode.FLOW_EDIT,
  },
  [RequestType.DELETE]: {
    title: 'Delete Data',
    description: "Delete the requester's personal data (except for some records required for auditing).",
    descriptionShort: `Delete user's personal data.`,
    iconSrc: BigidMeIconDeleteSrc,
  },
  [RequestType.PREFERENCES]: {
    title: 'Manage Preferences',
    description:
      'Allow requester to set preferences for using personal data, such as email notifications and sharing data.',
    descriptionShort: `Collect user's preferences for the use of their personal data.`,
    iconSrc: BigidMeIconPreferencesSrc,
    featureCode: FeatureCode.FLOW_PREFERENCES,
  },
  [RequestType.OPT_OUT]: {
    title: `Don't Sell/Share`,
    description: 'Allow requester to opt out of using personal data.',
    descriptionShort: `Manage user's opt-outs.`,
    iconSrc: BigidMeIconOptOutSrc,
    featureCode: FeatureCode.FLOW_OPT_OUT,
  },
  [RequestType.APPEAL]: {
    title: `Appeal`,
    description: `Appeal the results of a previous request.`,
    descriptionShort: `Appeal the results of a previous request.`,
    iconSrc: BigidMeIconAppealtSrc,
    featureCode: FeatureCode.FLOW_APPEAL,
  },
};
