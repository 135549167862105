import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { datadogRum } from '@datadog/browser-rum';
import { getEnv } from '@consumer/utils/commonUtils';
import { fetchMonitoringConfig } from '../services/monitoringService';
import { authService } from '../services/authentication';
import { AuthContainer } from './authContainer';
import { MonitoringConfigType } from '../types';

const startDataDogRum = ({
  userName,
  tenant,
  applicationId,
  clientToken,
  version,
}: {
  userName: string;
  tenant: string;
  applicationId: string;
  clientToken: string;
  version: string;
}) => {
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.com',
    service: 'privacy-portal-app',
    env: getEnv(),
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    // allowedTracingUrls: [url => url.startsWith('http')],
    defaultPrivacyLevel: 'mask',
  });
  datadogRum.setUserProperty('id', userName);
  datadogRum.setUserProperty('tenant', tenant);
  datadogRum.startSessionReplayRecording();
};
const useMonitoring = () => {
  const { auth } = AuthContainer.useContainer();
  const [config, setConfig] = useState<MonitoringConfigType>();

  useEffect(() => {
    const loadConfig = async () => {
      const monitoringConfig = await fetchMonitoringConfig();

      setConfig(monitoringConfig);
    };

    loadConfig();
  }, []);

  useEffect(() => {
    const initMonitoringService = () => {
      const tenant = authService.getUser()?.tenant || '';
      const userName = `privacyportal-user-${auth.id}`;
      if (window._DATADOG_SYNTHETICS_BROWSER === undefined) {
        if (config && config.enabled) {
          startDataDogRum({
            userName,
            tenant,
            applicationId: config.applicationIdPrivacyPortalApp,
            clientToken: config.clientTokenPrivacyPortalApp,
            version: config.ddVersion,
          });
        }
      }

      return () => {
        datadogRum.stopSessionReplayRecording();
      };
    };

    return initMonitoringService();
  }, [auth.id, config]);

  return {};
};

export const MonitoringContainer = createContainer(useMonitoring);
