import React from 'react';
import { Step } from '@bigid-ui/guided-tour';
import {
  BrandsEditStep,
  FromsTabStep,
  AgentTabStep,
  AgentFormStep,
  AllowAgentStep,
  RequestorTypeStep,
  RequestorTypeAccordionStep,
} from '.';
import { getGuidedTourIdSelector } from '@bigid-ui/utils';
import { AuthorizedAgentStartTooltip } from '../tooltips/AuthorizedAgentStartTooltip';
import { AuthorizedAgentStepTooltip } from '../tooltips/AuthorizedAgentStepTooltip';
import { RequestorTypeType } from '../../../types';

export const authorizedAgentTourSelectors = {
  START_TOUR: getGuidedTourIdSelector('Editor', ['start']),
  BRANDS_EDIT_BUTTON: getGuidedTourIdSelector('EditButton-0', ['button']),
  REQUESTOR_TYPE_ACCORDION: getGuidedTourIdSelector('RequestorTypeAccordion', ['accordion']),
  REQUESTOR_TYPE_ACCORDION_TOGGLE: '#requestor-type-toggle',
  REQUESTOR_TYPE_ACCORDION_AGENT: `#requestorTypeAccordion-${RequestorTypeType.AUTHORIZED_AGENT}`,
  AUTHORIZED_AGENT_TAB: '#vertical-tab-2',
  AUTHORIZED_AGENT_ACCORDION: '#authorizedAgentAccordion',
  FORM_TAB: '#vertical-tab-1',
  ALLOW_AGENT: getGuidedTourIdSelector('AllowAgent', ['agent']),
  GENERAL_TAB: '#vertical-tab-0',
};
export const authorizedAgentTourSteps = () => {
  const dataAid = 'AuthorizedAgentGuidedTour';
  const steps: Step[] = [
    {
      content: null,
      target: authorizedAgentTourSelectors.START_TOUR,
      disableBeacon: true,
      placement: 'center',
      tooltipComponent: AuthorizedAgentStartTooltip,
    },
    {
      content: <BrandsEditStep dataAid={dataAid} />,
      target: authorizedAgentTourSelectors.BRANDS_EDIT_BUTTON,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-end',
      tooltipComponent: AuthorizedAgentStepTooltip,
      spotlightPadding: 10,
    },
    {
      content: <RequestorTypeStep dataAid={dataAid} />,
      target: authorizedAgentTourSelectors.REQUESTOR_TYPE_ACCORDION,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-end',
      tooltipComponent: AuthorizedAgentStepTooltip,
    },
    {
      content: <RequestorTypeAccordionStep dataAid={dataAid} />,
      target: authorizedAgentTourSelectors.REQUESTOR_TYPE_ACCORDION_AGENT,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-start',
      tooltipComponent: AuthorizedAgentStepTooltip,
    },
    {
      content: <AgentTabStep dataAid={dataAid} />,
      target: authorizedAgentTourSelectors.AUTHORIZED_AGENT_TAB,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-end',
      tooltipComponent: AuthorizedAgentStepTooltip,
    },
    {
      content: <AgentFormStep dataAid={dataAid} />,
      target: authorizedAgentTourSelectors.AUTHORIZED_AGENT_ACCORDION,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-start',
      tooltipComponent: AuthorizedAgentStepTooltip,
    },
    {
      content: <FromsTabStep dataAid={dataAid} />,
      target: authorizedAgentTourSelectors.FORM_TAB,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-end',
      tooltipComponent: AuthorizedAgentStepTooltip,
    },
    {
      content: <AllowAgentStep dataAid={dataAid} />,
      target: authorizedAgentTourSelectors.ALLOW_AGENT,
      disableBeacon: true,
      spotlightClicks: true,
      placement: 'right-end',
      tooltipComponent: AuthorizedAgentStepTooltip,
      spotlightPadding: 5,
    },
  ];

  return steps;
};
