import { styled } from '@mui/material/styles';
import { BORDER_RADIUS } from '../../assets/styles';

const PREFIX = 'WorkflowCustmizationPipeline';

export const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  stage: `${PREFIX}-stage`,
  enabled: `${PREFIX}-enabled`,
  active: `${PREFIX}-active`,
  toggle: `${PREFIX}-toggle`,
};

export const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
    display: 'flex',
  },
  [`& .${classes.wrapper}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.stage}`]: {
    display: 'flex',
    margin: '0 20px',
    padding: '7px 5px 7px 7px',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 135,
    background: theme.vars.palette.bigid?.white,
    border: `1.5px solid transparent`,
    boxShadow: `0px 0px 8px rgba(0, 0, 0, 0.2)`,
    borderRadius: BORDER_RADIUS,

    [theme.breakpoints.down(1367)]: {
      margin: '0 10px',
      minWidth: 108,
    },
  },
  [`& .${classes.wrapper}:first-of-type .${classes.stage}`]: {
    margin: '0 20px 0 0',
    [theme.breakpoints.down(1367)]: {
      margin: '0 10px 0 0',
    },
  },
  [`& .${classes.wrapper}:last-child .${classes.stage}`]: {
    margin: '0 0 0 20px',
    [theme.breakpoints.down(1367)]: {
      margin: '0 0 0 10px',
    },
  },
  [`& .${classes.stage} div`]: {
    minWidth: 75,
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: 400,
    color: theme.vars.palette.bigid?.gray400,
    cursor: 'pointer',
    [theme.breakpoints.down(1367)]: {
      fontSize: '.8rem',
    },
  },
  [`& .${classes.enabled} div`]: {
    color: theme.vars.palette.bigid?.gray700,
  },
  [`& .${classes.active}`]: {
    border: `1.5px solid ${theme.vars.palette.bigid?.gray700}`,
  },
  [`& .${classes.active} div`]: {
    fontWeight: 700,
    color: theme.vars.palette.bigid?.gray700,
  },
  [`& .${classes.toggle}`]: {
    display: 'contents',
  },
}));
