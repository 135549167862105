import React, { FC, useEffect, useMemo, useState, ComponentType } from 'react';
import { NavLink } from 'react-router-dom';
import { noop } from 'lodash';
import { BigidHeader, HeaderActionItem } from '@bigid-ui/components';
import { AuthContainer } from '../../state/authContainer';
import { PricingPlanContainer } from '../../state/pricingPlanContainer';
import { MeLogo } from '../MeLogo';
import { authService, UserTenantType } from '../../services/authentication';
import { isSmallIdApp, DOCUMENTATION, SUPPORT } from '../../utils';
import { fetchProducts } from '../../services/products/productSubscriptionsService';
import { useStyles } from './HeaderStyles';
import {
  BigidAppLogoPrivacyPortalIcon,
  BigidAppLogoDefaultIcon,
  IconComponentProps,
  BigidHelpLargeIcon,
  BigidApplicationsIcon,
} from '@bigid-ui/icons';
import { DrawerItem } from '@bigid-ui/components/lib/BigidItemsDrawer/BigidDrawerItem';
import { TrackingEvents } from '../../types';
import { trackEvent } from '../../services/eventsTrackingService';
import { loginWithTenant } from '../../services/user/userService';

interface HeaderType {
  logoClickable?: boolean;
}

interface ProductsDataType {
  items: DrawerItem[];
  allProductsUrl: string;
  portalEnabled: boolean;
}

const openExternalLink = (url: string) => window.open(url, '_blank');

const iconsByName: Record<string, ComponentType<IconComponentProps>> = {
  BigidSubscriptionsPrivacyIcon: BigidAppLogoPrivacyPortalIcon,
  BigidSubscriptionsDiscoveryIcon: BigidAppLogoDefaultIcon,
  BigidSubscriptionsDevToolsIcon: BigidAppLogoDefaultIcon,
  BigidSubscriptionsCookiesIcon: BigidAppLogoDefaultIcon,
};

export const Header: FC<HeaderType> = ({ logoClickable = true }) => {
  const classes = useStyles({ logoClickable });
  const { auth } = AuthContainer.useContainer();
  const { isEnterprise } = PricingPlanContainer.useContainer();
  const [productsData, setProductsData] = useState<ProductsDataType>();

  useEffect(() => {
    fetchProducts().then(data => {
      setProductsData({
        items: data.products.map(({ code, icon, name, url }) => ({
          id: code,
          icon: iconsByName[icon] as ComponentType<IconComponentProps>,
          link: url,
          text: name,
          onItemClick: (code: string) => {
            trackEvent(`${TrackingEvents.HEADER_PRODUCT_ITEM_CLICK}_${code}`).finally(() =>
              window.location.assign(url),
            );
            return false;
          },
        })),
        allProductsUrl: data.allProductsUrl,
        portalEnabled: data.portalEnabled,
      });
    });
  }, []);

  const logout = async () => {
    await authService.logOut();
  };

  function HeaderLogo() {
    return (
      <NavLink to="/" className={classes.logoLink}>
        <MeLogo />
      </NavLink>
    );
  }

  const handleTenantMenuItemClick = async (tenant: UserTenantType) => {
    loginWithTenant(tenant.tenantId);
  };

  const accountInfoMenu = useMemo(() => {
    if (!auth) {
      return [];
    }

    let menu: Array<{ label?: string; divider?: boolean; onClick?: () => void }> = [
      { label: 'Logout', onClick: logout },
    ];
    if (auth.tenants.length > 1) {
      menu = [
        ...auth.tenants.map(tenant => {
          const currentTenantPrefix = tenant.tenantId === auth.currentTenant ? '*' : '';
          const defaultTenantSuffix = tenant.defaultTenant ? ' (default)' : '';
          return {
            label: `${currentTenantPrefix}${tenant.tenantId}${defaultTenantSuffix}`,
            onClick: () => handleTenantMenuItemClick(tenant),
          };
        }),
        { divider: true },
        ...menu,
      ];
    }

    return menu;
  }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

  const headerActions = useMemo(() => {
    const items: HeaderActionItem[] = [
      {
        icon: BigidHelpLargeIcon,
        onClick: noop,
        menu: isEnterprise
          ? [
              { label: 'Support', onClick: () => openExternalLink(SUPPORT) },
              { label: 'Documentation', onClick: () => openExternalLink(DOCUMENTATION) },
            ]
          : undefined,
        dataAid: 'HelpButton',
      },
    ];

    if (isSmallIdApp() && productsData?.portalEnabled) {
      items.push({
        icon: BigidApplicationsIcon,
        dataAid: 'ProductMenu',
        itemsDrawerConfiguration: {
          content: [
            {
              items: productsData.items,
            },
          ],
          onOpen: () => {
            trackEvent(TrackingEvents.HEADER_PRODUCT_MENU_CLICK);
          },
          onScroll: noop,
          displayBottomLink: true,
          linkProps: {
            href: productsData.allProductsUrl,
            text: 'See All Services',
            onClick: () => {
              trackEvent(`${TrackingEvents.HEADER_PRODUCT_ITEM_CLICK}_all`).finally(() =>
                window.location.assign(productsData.allProductsUrl),
              );
              return false;
            },
          },
        },
      });
    }

    return items;
  }, [isEnterprise, productsData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!productsData || !headerActions) <></>;

  return (
    <div className={classes.root}>
      <BigidHeader
        logo={HeaderLogo}
        accountInfo={{
          accountName: auth.fullName,
          menu: accountInfoMenu,
        }}
        actions={headerActions}
      />
    </div>
  );
};
