import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',

    '& thead, & tbody, tbody tr': {
      display: 'table',
      width: '100%',
      tableLayout: 'fixed',
      marginBottom: 3,
    },

    '& th': {
      padding: '10px 15px',
      fontSize: '0.75rem',
      fontWeight: 600,
      color: theme.vars.palette.bigid?.gray600,
      textAlign: 'start',
    },
  },
  body: {
    display: 'block',
    overflowY: 'scroll',

    '& tr': {
      boxShadow: '0 0px 10px 0 rgb(103 102 102 / 11%)',
      borderRadius: 5,

      '& td': {
        backgroundColor: theme.vars.palette.bigid?.white,
        verticalAlign: 'middle',

        '&:first-child': {
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
        },
        '&:last-child': {
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
        },
        margin: 0,
        padding: '10px 15px',
        wordBreak: 'break-all',
      },
    },
    '& tr:hover td': {
      backgroundColor: theme.vars.palette.bigid?.gray150,
    },
  },
}));
