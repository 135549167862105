import React, { FC, MouseEvent } from 'react';
import cx from 'classnames';
import { noop } from 'lodash';
import { BigidTooltip } from '@bigid-ui/components';

import { useStyles, SwitchToggleColorsType } from './SwitchToggleStyles';
import { useResponsive } from '../../../hooks/useResponsive';

export type SwitchTogglePropsType = {
  id: string | number;
  checked: boolean;
  onChange?: (id: string | number, checked: boolean) => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  className?: string;
  testId?: string;
  tooltip?: string;
  checkedColor?: string;
  uncheckedColor?: string;
  disabled?: boolean;
  textOn?: string;
  textOff?: string;
} & SwitchToggleColorsType;

export const SwitchToggle: FC<SwitchTogglePropsType> = ({
  id,
  checked,
  onChange,
  onClick,
  className,
  testId,
  disabled,
  tooltip,
  checkedColor,
  uncheckedColor,
  textOn,
  textOff,
}) => {
  const { isTablet } = useResponsive();
  const classes = useStyles({ isTablet, clickable: !!onChange, checkedColor, uncheckedColor });

  const onClickToggle = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onClick && onClick(event);
  };

  const onChangeToggle = (id: string | number, checked: boolean) => {
    onChange && onChange?.(id, checked);
  };

  const renderToggleTextState = (text: string) => {
    return <div className={classes.toggleStateText}>{text}</div>;
  };

  return (
    <BigidTooltip title={tooltip || ''}>
      <div className={cx(classes.root, className, { [classes.disabled]: disabled })} onClick={onClickToggle}>
        <>
          <input
            type="checkbox"
            name={id.toString()}
            className={classes.checkbox}
            id={`MeSwitchToggle-${id}`}
            tabIndex={0}
            checked={checked}
            onChange={({ target: { checked } }) => onChangeToggle(id, checked)}
            data-aid={testId}
            disabled={disabled}
          />
          <label className={classes.label} htmlFor={`MeSwitchToggle-${id}`} data-aid={`${testId}_label`} />
          {checked && textOn
            ? renderToggleTextState(textOn)
            : !checked && textOff
            ? renderToggleTextState(textOff)
            : noop()}
        </>
      </div>
    </BigidTooltip>
  );
};
