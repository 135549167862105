import { GridColumns, GridRequestsFilterType, UserGridPreferenceViewFilter } from '../types';
import { NextGridState } from '@bigid-ui/grid/lib/BigidGrid/hooks';
import { BigidGridColumn, BigidGridRow } from '@bigid-ui/grid';
import { BigidAdvancedToolbarFilterTypes } from '@bigid-ui/components';

export const generateNewGridColumnState = ({
  nextGridState,
  columns,
  defaultColumns,
}: {
  nextGridState?: NextGridState;
  columns: GridColumns[];
  defaultColumns?: BigidGridColumn<BigidGridRow>[];
}) => {
  const defaultColumnState = defaultColumns
    ? (defaultColumns || []).map((c, i) => ({
        label: c.name,
        name: c.name,
        ordinal: i,
        enabled: !c.isHiddenByDefault,
        sort: '',
      }))
    : [];

  let newColumnState = columns.length ? columns : defaultColumnState;
  if (nextGridState?.hiddenColumnNames) {
    newColumnState = newColumnState?.map(a => ({
      ...a,
      enabled: !nextGridState?.hiddenColumnNames!.includes(a.name),
    }));
  }
  if (nextGridState?.columnsOrder) {
    newColumnState = newColumnState
      ?.map(a => ({ ...a, ordinal: nextGridState?.columnsOrder?.indexOf(a.name) || 0 }))
      .sort(
        (o1: GridColumns, o2: GridColumns) =>
          nextGridState?.columnsOrder!.indexOf(o1.name) - nextGridState?.columnsOrder!.indexOf(o2.name),
      )
      .map((a, i) => ({ ...a, ordinal: i }));
  }

  if (nextGridState?.sort) {
    newColumnState = newColumnState?.map(column => {
      const sort = nextGridState?.sort?.find(s => s.field === column.name)?.order || column.sort;
      if (sort) {
        return {
          ...column,
          sort,
        };
      }

      return column;
    });
  }

  return newColumnState;
};

export const generateNewGridFiltersState = ({
  gridFilters,
  filters,
}: {
  gridFilters?: GridRequestsFilterType[];
  filters?: UserGridPreferenceViewFilter[];
}) => {
  const viewFiltersWithOptions = (filters || []).map(filter => {
    const gridFilter = gridFilters?.find(gf => gf.field === filter.name);

    return {
      ...filter,
      options: !!gridFilter ? [...(gridFilter.value as string)] : [],
      dateFrom: gridFilter?.dateFrom ? String(gridFilter?.dateFrom) : undefined,
      dateTo: gridFilter?.dateTo ? String(gridFilter?.dateTo) : undefined,
      currentRangeOption: gridFilter?.currentRangeOption,
      currentRangeMode: gridFilter?.currentRangeMode,
    };
  });

  const gridFiltersWithOptions = (gridFilters || [])
    .filter(filter => !filters?.map(gf => gf.name).includes(filter.field))
    .map(filter => ({
      id: 0,
      name: filter.field,
      options: filter.value as string[],
      operator: filter.operator,
      ordinal: 0,
      enabled: true,
      type: (filter.type === BigidAdvancedToolbarFilterTypes.DATE_RANGE ? 'Date' : 'String') as 'String' | 'Date',
      dateFrom: filter.dateFrom ? String(filter.dateFrom) : undefined,
      dateTo: filter.dateTo ? String(filter.dateTo) : undefined,
      currentRangeOption: filter.currentRangeOption,
      currentRangeMode: filter.currentRangeMode,
    }));

  return [...viewFiltersWithOptions, ...gridFiltersWithOptions];
};
