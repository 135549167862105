import { ReactNode, useCallback, useEffect, useReducer, useState, useMemo } from 'react';
import { find } from 'lodash';
import { createContainer } from 'unstated-next';
import { languageService } from '../services/language/languageService';
import { DictionaryType } from '../services/language/types';
import { isLanguageRtl } from '../utils';

export enum LanguageType {
  EN = 'en',
  PT = 'pt',
  FR = 'fr',
  DE = 'de',
  ES = 'es',
  FR_CA = 'fr-ca',
  IT = 'it',
  CS = 'cs',
  DA = 'da',
  FI = 'fi',
  JA = 'ja',
  KO = 'ko',
  NL = 'nl',
  PL = 'pl',
  SV = 'sv',
  TR = 'tr',
  ZH_CN = 'zh-cn',
  AR = 'ar',
  EL = 'el',
  ET = 'et',
  HE = 'he',
  HI = 'hi',
  MS = 'ms',
  NO = 'no',
  SK = 'sk',
  TH = 'th',
  VI = 'vi',
}

export type BigIdMeTranslateType = (key: string, placeholders?: { key: string; value: string }[]) => string;

export type BigIdMeTranslateWithNodeType = (
  key: string,
  placeholders: { key: string; value: string | ReactNode }[],
) => Array<ReactNode | string> | string;

type LanguageReducerStateType = {
  language: LanguageType;
  dictionary?: {
    [langauage: string]: DictionaryType;
  };
};

enum LanguageReducerActionTypes {
  SET_LANGUAGE,
  SET_DICTIONARY,
}

type LanguageReducerActionType = {
  type: LanguageReducerActionTypes;
  payload: {
    language: LanguageType;
    data?: DictionaryType;
  };
};

const languageReducer = (
  state: LanguageReducerStateType,
  action: LanguageReducerActionType,
): LanguageReducerStateType => {
  const { type, payload } = action;
  switch (type) {
    case LanguageReducerActionTypes.SET_LANGUAGE: {
      return {
        ...state,
        language: payload.language,
      };
    }
    case LanguageReducerActionTypes.SET_DICTIONARY: {
      return {
        ...state,
        dictionary: {
          ...state.dictionary,
          [payload.language]: payload.data || {},
        },
      };
    }
    default:
      return state;
  }
};

const initialState: LanguageReducerStateType = {
  language: LanguageType.EN,
  dictionary: undefined,
};

// PPforceApiUrl?: string; PPforceLocale?: LanguageType are for Privacy Portal editor previewDataPP
export const useLanguage = (initial?: { PPforceApiUrl?: string; PPforceLocale?: LanguageType; rootId?: string }) => {
  const [{ language, dictionary }, dispatch] = useReducer(languageReducer, initialState);
  const [availableLanguages, setAvailableLanguages] = useState<{ value: LanguageType; label: string }[]>();
  const [dictionaryLoading, setDictionaryLoading] = useState(false);
  const showError = () => (window.location.hash = '/error');

  useEffect(() => {
    fetchAvailableLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDictionary = useCallback(
    async (language: LanguageType) => {
      setDictionaryLoading(true);

      try {
        const response = await languageService.getDictionary(language, initial?.PPforceApiUrl);
        // uncomment to enable STORYBOOK translations
        // response = await languageService.getDictionary('en', 'http://test-bigid.me/api/consumer/bizstart');
        dispatch({
          type: LanguageReducerActionTypes.SET_DICTIONARY,
          payload: {
            language,
            data: response,
          },
        });

        setDictionaryLoading(false);
      } catch (e) {
        showError();
      }
    },
    [initial?.PPforceApiUrl],
  );

  const setLanguage = useCallback(
    (language: LanguageType) => dispatch({ type: LanguageReducerActionTypes.SET_LANGUAGE, payload: { language } }),
    [],
  );

  useEffect(() => {
    if (language && !dictionary?.[language]) {
      fetchDictionary(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDictionary, language]);

  useEffect(() => {
    if (initial?.PPforceLocale) {
      setLanguage(initial.PPforceLocale);
    }
  }, [initial?.PPforceLocale, setLanguage]);

  const fetchAvailableLanguages = async () => {
    setDictionaryLoading(true);

    try {
      const languages = await languageService.getAvailableLanguages(initial?.PPforceApiUrl);
      // uncomment to enable STORYBOOK translations
      // const languages = await languageService.getAvailableLanguages('http://test-bigid.me/api/consumer/bizstart');
      const defaultLang = find(languages, 'useAsDefault');
      const availableLanguagesOptions = languages?.map(({ code, name }) => ({ value: code, label: name }));

      dispatch({
        type: LanguageReducerActionTypes.SET_LANGUAGE,
        payload: {
          language: (initial?.PPforceLocale ||
            // localStorage
            localStorage.getItem('lang') ||
            // default language from list
            defaultLang?.code ||
            // browser preferances with exact naming like pt-BR, en-GB
            navigator.languages.find(language => find(languages, language)) ||
            // browser preferances with general language name like pt, en
            languages.find(language =>
              find(navigator.languages, navLang => {
                return navLang.includes(language.code);
              }),
            )?.code ||
            languages?.find(language => language?.code === LanguageType.EN)?.code) as LanguageType,
        },
        // for PRM edit portal
      });

      setAvailableLanguages(availableLanguagesOptions);
    } catch (e) {
      showError();
      return;
    }
  };

  const BigIdMeTranslate: BigIdMeTranslateType = useCallback(
    (key, placeholders = []) => {
      let translation = dictionary?.[language]?.[key];
      if (!translation) translation = dictionary?.[LanguageType.EN]?.[key];
      if (!translation) {
        console.debug(`Warning! Key '${key}' is broken!`);
        return '';
      }
      return !placeholders.length
        ? translation
        : placeholders.reduce(
            (acc, placeholder) => acc.replace(new RegExp(`{%${placeholder.key}%}`, 'gm'), placeholder.value),
            translation,
          );
    },
    [dictionary, language],
  );

  const BigIdMeTranslateWithNode: BigIdMeTranslateWithNodeType = useCallback(
    (key, placeholders = []) => {
      let translation = dictionary?.[language]?.[key];
      if (!translation) translation = dictionary?.[LanguageType.EN]?.[key];
      if (!translation) {
        console.debug(`Warning! Key '${key}' is broken!`);
        return '';
      }
      const result: Array<ReactNode | string> = [];
      placeholders.forEach((placeholder, index) => {
        const split = translation!.split(new RegExp(`{%${placeholder.key}%}`, 'gm'));
        result.push(split[0]);
        if (split[1] !== undefined) {
          result.push(placeholder.value);
          if (index + 1 === placeholders.length) {
            result.push(split[1]);
          } else {
            translation = split[1];
          }
        }
      });
      return result;
    },
    [dictionary, language],
  );

  const isRtl = useMemo(() => isLanguageRtl(language), [language]);

  useEffect(() => {
    const applyTextDirectionToHtml = () => {
      const root = (initial?.rootId && document.getElementById(initial?.rootId)) || document;
      root.dir = isRtl ? 'rtl' : 'auto';
    };

    applyTextDirectionToHtml();
  }, [isRtl, initial?.rootId]);

  return {
    language,
    isRtl,
    setLanguage,
    availableLanguages,
    BigIdMeTranslate,
    BigIdMeTranslateWithNode,
    dictionaryLoading,
    loadedLanguages: Object.keys(dictionary || {}),
  };
};

export const LanguageContainer = createContainer(useLanguage);
