import React, { ChangeEvent, FC, ReactElement, useState, useEffect, RefObject } from 'react';
import { FormControl, FormHelperText, Input } from '@mui/material';

import { useStyles } from './MeTextAreaStyles';
import { BigidMeTooltipWithIcon } from '../BigidMeTooltipWithIcon';

const DEFAULT_MAX_SYMBOLS = 500;
export interface MeTextAreaPropsType {
  value?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  error?: string | ReactElement;
  disabled?: boolean;
  tip?: string;
  inputRef?: RefObject<HTMLTextAreaElement>;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e?: ChangeEvent) => void;
  onValidationFail?: (errorMessage: string) => void;
  testId?: string;
  name?: string;
  withCounter?: boolean;
  maxSymbols?: number;
}

export const MeTextArea: FC<MeTextAreaPropsType> = ({
  required,
  value = '',
  label,
  placeholder,
  error,
  disabled,
  onChange,
  onBlur,
  onValidationFail,
  testId,
  name,
  inputRef,
  tip,
  withCounter = false,
  maxSymbols = DEFAULT_MAX_SYMBOLS,
}) => {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState(value);
  const [internalError, setInternalError] = useState('');

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = (e.target as HTMLInputElement).value;
    if (value.length >= maxSymbols + 1) {
      return;
    }
    setInternalValue(value);
    onChange(e);
  };

  const handleOnBlur = (e: ChangeEvent) => {
    if (!internalValue && required) {
      setInternalError('Field must not be empty');
    }
    onBlur && onBlur(e);
  };

  useEffect(() => setInternalValue(value), [value]);

  internalError && onValidationFail && onValidationFail(internalError);

  return (
    <FormControl fullWidth className={classes.root}>
      {label && <span className={classes.label}>{label}</span>}
      {tip && <BigidMeTooltipWithIcon title={tip} />}
      <Input
        multiline
        type="text"
        inputRef={inputRef || null}
        placeholder={placeholder}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        disableUnderline
        disabled={disabled}
        error={!!error || !!internalError}
        value={internalValue}
        classes={{
          root: classes.inputRoot,
          error: classes.inputError,
        }}
        inputProps={{ 'data-aid': testId }}
        name={name}
      />
      {withCounter && (
        <div className={classes.counter}>
          {internalValue.length}/{maxSymbols}
        </div>
      )}
      {(error || internalError) && (
        <FormHelperText error classes={{ root: `${classes.error}` }}>
          {error || internalError}
        </FormHelperText>
      )}
    </FormControl>
  );
};
