import React, { FC } from 'react';

import { OnboardingStepType } from '../../../types';
import ConsumerScreenshot from '../../../assets/images/Consumer-Screenshot.jpg';
import { useStyles } from './RequestsFormStepStyles';

export const RequestsFormStep: FC<OnboardingStepType> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.text}>
        <span className={classes.bold}>An out-of-the-box Privacy Center has been created for you.</span>
        <br />
        <br />
        You can customize this center with your branding and add it to your site to be compliant with privacy
        regulations.
      </div>
      <img src={ConsumerScreenshot} width={350} />
    </div>
  );
};
