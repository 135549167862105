import { RequestSubmitPortalType } from '../../types';
import { cloneDeep, cloneWith } from 'lodash';

export const findPortalPathInPortals = (portals: RequestSubmitPortalType[], portalId: number) => {
  const portalIndex = portals.findIndex(portal => portal.id === portalId);
  return { portalIndex, portal: portalIndex === -1 ? undefined : portals[portalIndex] };
};

export const findPortalInPortals = (portals: RequestSubmitPortalType[], portalId: number) =>
  findPortalPathInPortals(portals, portalId).portal;

export const clonePortalInPortals = (portals: RequestSubmitPortalType[], portalId: number) => {
  const portal = findPortalInPortals(portals, portalId);
  return cloneWith(portals, value => (portal?.id === portalId ? cloneDeep(value) : value));
};

export const addRequestSubmitPortalInPortals = (portals: RequestSubmitPortalType[], data: RequestSubmitPortalType) => [
  ...portals,
  data,
];

export const updateRequestSubmitPortalInPortals = (
  portals: RequestSubmitPortalType[],
  portalId: number,
  data: Partial<RequestSubmitPortalType>,
) => {
  const { portal } = findPortalPathInPortals(portals, portalId);
  if (portal) {
    const updatedPortals = clonePortalInPortals(portals, portal.id);
    const updatedPortal = findPortalInPortals(updatedPortals, portalId);
    if (updatedPortal) {
      Object.assign(updatedPortal, data);
    }
    return updatedPortals;
  }
  return portals;
};

export const deleteRequestSubmitPortalInPortals = (portals: RequestSubmitPortalType[], portalId: number) =>
  portals.filter(portal => portal.id !== portalId);

export const isPortalNameUniqueInPortals = (portals: RequestSubmitPortalType[], portalId: number, portalName: string) =>
  portals.every(portal => portal.id === portalId || portal.name !== portalName);

export const getDefaultRequestSubmitPortal = (portals: RequestSubmitPortalType[]) =>
  portals.sort((a, b) => a.id - b.id)[0];
