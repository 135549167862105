import React, { useEffect } from 'react';
import { ReactComponent as IconOnboardingBg } from '../../../assets/illustrations/onboarding-bg.svg';
import { ReactComponent as IconLogo } from '../../../assets/illustrations/logo-purple.svg';
import { ReactComponent as IconWelcome } from '../../../assets/illustrations/welcome.svg';
import { trackOnboardingEvent } from '../../../services/eventsTrackingService';
import { useStyles } from './WelcomePopupStyles';
import { TrackingEvents } from '../../../types/TrackingEventTypes';
import { BRAND_NAME } from '../../../utils';
import { PrimaryButton } from '@bigid-ui/components';

export interface WelcomePopupPropsType {
  onWelcomeContinue: () => void;
}

export const WelcomePopup = ({ onWelcomeContinue }: WelcomePopupPropsType) => {
  const classes = useStyles();

  useEffect(() => {
    trackOnboardingEvent(TrackingEvents.WELCOME_MESSAGE, true);
  }, []);

  return (
    <div className={classes.root}>
      <IconOnboardingBg />
      <div className={classes.purpleLogo}>
        <IconLogo />
      </div>
      <div className={classes.title}>Welcome to {BRAND_NAME}!</div>
      <div className={classes.text}>Comply with privacy regulations in 3 simple steps </div>
      <div className={classes.layout}>
        <IconWelcome />
        <div>
          <span>
            Select
            <br />
            regulations
          </span>
          <span>
            Create
            <br />
            Privacy Center
          </span>
          <span>
            Connect to
            <br />
            Your Data
          </span>
        </div>
      </div>
      <div className={classes.button}>
        <PrimaryButton
          text="Let’s Go"
          width="fill"
          onClick={onWelcomeContinue}
          size="medium"
          dataAid="onboarding-welcome-btn"
        />
      </div>
    </div>
  );
};
