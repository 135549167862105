import {
  AuthorizedAgentFieldNameType,
  AuthorizedAgentFieldType,
  AuthorizedAgentFormFieldType,
  AuthorizedAgentTextType,
  FieldTranslationsType,
} from '../types';
import { LanguageType } from '@consumer/state/languageContainer';
import { b64EncodeUnicode } from './encoding';

export const getInitialValuesForAuthorizedAgentField = (): AuthorizedAgentFieldType => ({
  id: 0,
  ordinal: 0,
  name: '' as AuthorizedAgentFieldNameType,
  label: '',
  canToggle: true,
  type: AuthorizedAgentFormFieldType.TEXT,
  enabled: true,
  showTooltip: false,
  predefined: false,
  canChangeRequired: true,
  showDescription: false,
  required: false,
  translations: {},
});

export const getBrandAuthorizedAgentWithEncodedTranslations = (branAuthorizedAgent: {
  fields: AuthorizedAgentFieldType[];
  texts: AuthorizedAgentTextType[];
  deletedFieldIds: number[];
  newFields: AuthorizedAgentFieldType[];
  translations: FieldTranslationsType;
}) => {
  return {
    ...branAuthorizedAgent,
    texts: branAuthorizedAgent.texts.map(t => {
      const newTranslations = Object.keys(t.translations).reduce((obj, cur) => {
        const value = t.translations[cur as LanguageType]?.value;
        return { ...obj, [cur]: { value: value ? b64EncodeUnicode(value) : undefined } };
      }, {});
      return {
        ...t,
        translations: newTranslations,
      };
    }),
  };
};
