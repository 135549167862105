import React from 'react';
import { TertiaryButton, BigidFilterType } from '@bigid-ui/components';
import { MeFilter } from '../MeFilter';
import { useStyles } from './MeFilterToolbarStyles';
import { isArray } from 'lodash';

export interface BigidMeFilterType extends BigidFilterType {
  showSearch?: boolean;
  showSelectAll?: boolean;
}

interface BigidFilterToolbarProps {
  filters: BigidMeFilterType[];
  onFiltersChange: (data: BigidMeFilterType[], changedFilter: BigidMeFilterType) => void;
  onClearAll: (data: BigidMeFilterType[]) => void;
  onSearchChange?: (searchText: string) => void;
  showClearAll?: boolean;
  showFilterCounter?: boolean;
}

export const MeFilterToolbar = ({
  filters,
  onClearAll,
  onFiltersChange,
  showClearAll: showClearAllFromProps,
  showFilterCounter,
}: BigidFilterToolbarProps) => {
  const classes = useStyles({});

  const handleFilterApply = (idx: number, changedFilter: BigidMeFilterType) => {
    const newFilters = filters.map((item, id) =>
      id === idx
        ? {
            ...changedFilter,
          }
        : item,
    );
    onFiltersChange(newFilters, changedFilter);
  };

  const handleClearAll = () => {
    onClearAll(filters);
  };

  const renderFilters = (filters: BigidMeFilterType[]) =>
    filters.map(({ title, field, operator, value, options, disabled = false, showSearch, showSelectAll }, idx) => (
      <div className={classes.filterWrapper} key={idx}>
        <MeFilter
          data-aid="filter"
          title={title}
          field={field}
          operator={operator}
          value={value}
          options={options}
          disabled={disabled}
          showSearch={showSearch}
          showSelectAll={showSelectAll}
          showCounter={showFilterCounter && options.length > 0}
          onApply={(...args) => handleFilterApply(idx, ...args)}
        />
      </div>
    ));

  const showClearAllFinal =
    filters.some(({ value }) => (!isArray(value) && !!value) || (isArray(value) && value.length > 0)) ||
    showClearAllFromProps;

  return (
    <div className={classes.root}>
      <span className={classes.subtitle}>Filters</span>
      {renderFilters(filters)}
      {showClearAllFinal && (
        <TertiaryButton
          text="Clear All"
          size="medium"
          dataAid="filters-clear-all"
          margin="0 7px"
          onClick={handleClearAll}
        />
      )}
    </div>
  );
};
