import React, { FC, useEffect, useState } from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidFormFieldLabelWrapper,
  BigidHeading6,
  BigidSwitch,
} from '@bigid-ui/components';
import { get } from 'lodash';
import { FormikErrors } from 'formik';
import { generateGuidedTourId } from '@bigid-ui/utils';

import { LanguageType } from '@consumer/state/languageContainer';
import { Root, classes } from './StageEmailCustomizationStyles';
import { StageConfigType, StageEmailConfigurationType, UpdateEmailTemplateType } from '../../../types';
import { StopPropagationContainer } from '../../../components/StopPropagationContainer';
import { RichTextEditor } from '../../../components';

interface StageEmailAppealCustomizationPropsType {
  emailEnabled: boolean;
  stageEmail: { configType: StageConfigType; config: StageEmailConfigurationType };
  emailTemplate: UpdateEmailTemplateType;
  emailTemplateErrors?: FormikErrors<UpdateEmailTemplateType>;
  selectedLanguage: LanguageType;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
}

export const StageEmailAppealCustomization: FC<StageEmailAppealCustomizationPropsType> = ({
  emailEnabled,
  stageEmail,
  emailTemplate,
  emailTemplateErrors,
  selectedLanguage,
  setFieldValue,
}) => {
  const [isOpen, setAccordionOpen] = useState(false);

  useEffect(() => {
    !emailEnabled && setAccordionOpen(false);
  }, [emailEnabled]);

  return (
    <Root data-tour-id={generateGuidedTourId('AllowAppeal', ['workflow'])}>
      <BigidAccordion
        className={classes.accordion}
        onChange={(event, expanded) => setAccordionOpen(expanded)}
        expanded={isOpen}
      >
        <BigidAccordionSummary
          size={AccordionSummarySizeEnum.large}
          sx={{ padding: '0 !important' }}
          dataAid="allow-appeal-accordion__header"
        >
          <BigidHeading6 sx={{ display: 'flex', alignItems: 'center', width: 150, justifyContent: 'space-between' }}>
            <div className={classes.purpleTitle}>Allow appeal</div>
            <StopPropagationContainer>
              <BigidSwitch
                checked={emailTemplate.appealEnabled}
                disabled={!emailEnabled && stageEmail.config.hasOwnProperty('emailEnabled')}
                dataAid="allow_appeal__switch"
                onChange={(event, checked) =>
                  setFieldValue(`${stageEmail.configType}.emailTemplate.appealEnabled`, checked)
                }
              />
            </StopPropagationContainer>
          </BigidHeading6>
        </BigidAccordionSummary>
        <BigidAccordionDetails sx={{ padding: '2px 0 !important' }}>
          <BigidFormFieldLabelWrapper
            // enableBracketsLink
            id="appeal_text"
            name="appealText"
            label="Text"
            isRequired
            errorIsShown
            error={get(emailTemplateErrors, `translations[${selectedLanguage}].appealText`)}
            tooltipText="You may insert the following variables in the text: {{brandName}}, {{consumerName}}, {{privacyPolicyLink}}, {{userDevice}}, {{userLocation}}, {{reportExpirationDate}}, {{reportExpirationDays}}, {{supportLink}}, {{appealLink}}"
          >
            <RichTextEditor
              dataAid="appeal-text-body_input"
              value={emailTemplate.translations[selectedLanguage]!.appealText}
              onBlur={html =>
                setFieldValue(
                  `${stageEmail.configType}.emailTemplate.translations[${selectedLanguage}].appealText`,
                  html,
                )
              }
            />
          </BigidFormFieldLabelWrapper>
        </BigidAccordionDetails>
      </BigidAccordion>
    </Root>
  );
};
