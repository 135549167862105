import { ReactNode } from 'react';
import { RegulationType, RequestStatusType, RequestType } from '.';

export enum TrendRequestsTypes {
  TOTAL = 1,
  TYPE,
  STATUS,
  REASON,
}

export enum StatisticPeriodValuesType {
  all = 'all',
  year = 365,
  month = 30,
  week = 7,
}

export interface TrendsRequestsOptionsType {
  period_days?: StatisticPeriodValuesType;
  regulation?: RegulationType;
}

export interface StatisticsType<T> {
  statistics: T;
}

export interface RequestsByStatusType {
  total: number;
  data: DataRequestsByStatusType[];
}

export interface DataRequestsByStatusType {
  type: RequestStatusType;
  value: number;
  color: string;
}

export interface RequestsByTypeType {
  type: RequestType;
  requests: {
    total: number;
    data: DataRequestsByStatusType[];
  };
}

export interface DataRequestsByTypeType {
  type: RequestType;
  value: number;
}

export interface MapChartItemDataType {
  title: string;
  latitude: number;
  longitude: number;
  requestsCount: number;
}

export interface TrendRequestDataItemType {
  date: Date | string;
  value?: number;
  auto?: number;
  manual?: number;
  view?: number;
  preferences?: number;
  delete?: number;
  update?: number;
  inProgress?: number;
  aboutOverdue?: number;
  overdue?: number;
}

export interface RegulationQuantityType {
  type: RegulationType;
  value: number;
}

export type RegulationQuantitiesType = RegulationQuantityType[];

export enum UserProfileType {
  'User' = 'User',
  'Customer' = 'Customer',
  'Consumer' = 'Consumer',
  'Current Employee' = 'Current Employee',
  'Former Employee' = 'Former Employee',
}

export type UserProfilesType = {
  type: UserProfileType;
  value: number;
  color?: string;
};

export type RequestsByUserProfileType = {
  total: number;
  profiles: UserProfilesType[];
};

export interface MapChartDataType {
  statistics: MapChartItemDataType[];
}

export interface ErrorResponseType {
  traceId: string;
  errorCode: number;
  message: ReactNode;
  messageWithTrace: ReactNode;
  status?: number;
}
