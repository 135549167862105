import React, { FC, memo } from 'react';
import { BigidDropdown, BigidDropdownOption } from '@bigid-ui/components';
import { Stack } from '@mui/system';

import { BigidMeTooltipWithIcon } from '../../../components';

const BigidDropdownMemo = memo(BigidDropdown);

type ExceptionResidencesPropsType<T> = {
  value: T;
  onChange: (options: T) => void;
  options: T;
};

export const ExceptionResidences: FC<ExceptionResidencesPropsType<BigidDropdownOption[]>> = ({
  value,
  options,
  onChange,
}) => {
  return (
    <Stack direction="column" py={2} pr={'40%'}>
      <Stack direction="row" pr={2} mb={1} alignItems="center">
        Exceptions: <span> (Optional)</span>
        <BigidMeTooltipWithIcon title="Allow residents from selected regions outside of supported regulation jurisdictions" />
      </Stack>
      <BigidDropdownMemo
        applyOnChange
        entityNamePlural="Residencies"
        entityNameSingular="Residency"
        isMulti
        isSearchable
        isValueDisplayedAsChips
        noOptionsMessage="No items available!"
        noSearchResultsMessage="No items found!"
        onSelect={onChange}
        options={options}
        placeholder="Select Residencies"
        size="large"
        value={value}
        dataAid="dropdown_country"
        displayLimit={500}
      />
    </Stack>
  );
};
