export const isValidURL = (str: string) => {
  const res = str.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );
  return res !== null;
};

export const getTenantFromLocation = () => {
  const tenantWithParameters = window.location.hash.split('/')[1];
  return tenantWithParameters.split('?')[0];
};

export const getEnv = () => {
  const hostParts = location.hostname.split('.');

  if (hostParts[0] === 'localhost' || hostParts[0] === 'test-bigid') return 'bigidme-dev';
  if (hostParts[1] === 'bigid') return 'bigidme-bigid-me-production';

  return 'bigidme-production';
};

// needed to be able to mock window.location.origin in cypress
(window as any).getOrigin = () => window.location.origin;

export const isSmallIdApp = () => (window as any).getOrigin().indexOf('bigid.me') != -1;
