import React, { FC } from 'react';
import { BigidHeading3, BigidBody1 } from '@bigid-ui/components';
import { Step, classes } from './WorkflowCustomizationOnboardingStepStyles';

export type WorkflowCustomizationOnboardingStepProps = {
  imageSrc: string;
  title: string;
  description: string;
};

export const WorkflowCustomizationOnboardingStep: FC<WorkflowCustomizationOnboardingStepProps> = ({
  imageSrc,
  title,
  description,
}) => (
  <Step>
    <div className={classes.image}>
      <img alt={title} src={`${process.env.PUBLIC_URL}/${imageSrc}`} />
    </div>
    <BigidHeading3 className={classes.title}>{title}</BigidHeading3>
    <BigidBody1 className={classes.description}>{description}</BigidBody1>
  </Step>
);
