export enum TrackingEvents {
  // sign up
  CREATE_ACCOUNT_COMPANY = 'SU1',
  EMAIL_SIGN_IN = 'SU6',
  FIRST_LOGIN = 'SU7',
  WELCOME_MESSAGE = 'SU8',
  WELCOME_GO_CLICK = 'SU8N',

  SIGNUP_PAGE1_VISIT = 'SU0F',
  SIGNUP_ERROR_EMAIL_INVALID_EMAIL_FORMAT = 'SU1IS',
  SIGNUP_ERROR_EMAIL_GENERIC = 'SU1GE',
  SIGNUP_ERROR_EMAIL_USED = 'SU1AU',
  SIGNUP_PAGE2_VISIT = 'SU0S',
  SIGNUP_ERROR_COMPANY_INVALID_SYMBOLS = 'SU1ISC',

  SELECT_REGION_BACK = 'FT10B', // selectRegionBack
  SELECT_REGION_SKIP = 'FT10S', // selectRegionSkip
  SELECT_REGION_NEXT = 'FT10N', // selectRegionNext
  SELECT_REGULATIONS_ALL = 'FT11A', // selectRegulationsAll
  SELECT_REGULATIONS_BACK = 'FT11B', // selectRegulationsBack
  SELECT_REGULATIONS_NEXT = 'FT11N', // selectRegulationsNext
  REQUEST_TYPE_NEXT = 'FT12N', // requestTypeNext
  REQUEST_TYPE_BACK = 'FT12B', // requestTypeBack
  FORM_NEXT = 'FT13N', // createFormNext
  FORM_BACK = 'FT13B', // createFormBack
  CONGRATULATIONS_PREVIEW = 'FT17P', // congratulationsPreview
  CONGRATULATIONS_DONE = 'FT17D', // congratulationsDone
  CONGRATULATIONS_CUSTOMIZE = 'FT17C', // congratulationsCustomize

  ONBOARDING_SYSTEM_CLICK = 'FT16C',
  ONBOARDING_SEE_ALL_SYSTEM = 'FT16A',
  ONBOARDING_SYSTEM_DISCONNECT = 'FT16D',
  ONBOARDING_CONNECTION_ERROR = 'FT16E',
  ONBOARDING_SYSTEM_NEXT = 'FT16N',
  ONBOARDING_SYSTEM_BACK = 'FT16B',
  ONBOARDING_SYSTEM_SKIP = 'FT16S',

  PORTAL_EDITOR = 'FT14',
  PUBLISH = 'FT15',

  // editor general (EG)
  PORTAL_EDITOR_TAB_GENERAL = 'EG1',
  PORTAL_EDITOR_GENERAL_USE_CASES = 'EG2',
  PORTAL_EDITOR_GENERAL_LOGO_UPLOAD = 'EG3',
  PORTAL_EDITOR_GENERAL_LOGO_DELETE = 'EG4',
  PORTAL_EDITOR_GENERAL_TOGGLE = 'EG5',

  // editor form (EF)
  PORTAL_EDITOR_TAB_FORM = 'EF1',
  PORTAL_EDITOR_FORM_TOGGLE = 'EF2',
  PORTAL_EDITOR_FORM_CHECKBOX = 'EF3',

  PRIVATE_PRICING_PLAN = 'PP1',
  PUBLIC_PRICING_PAGE_VISIT = 'PP1M',
  PUBLIC_PRICING_PAGE_START_BTN_CLICK = 'PP1C',

  // deletion records of request flow (DR)
  DELETE_RECORDS_AUTO_CLICK = 'DR3A',
  DELETE_RECORDS_MANUAL_CLICK = 'DR3M',
  DELETE_RECORDS_AUTO_CONFIRM = 'DR4A',
  DELETE_RECORDS_MANUAL_CONFIRM = 'DR4M',
  DELETE_RECORDS_POPUP_CANCEL = 'DR5',

  // consents mapping
  CONSENTS_VISIT_PAGE = 'CMTAB',
  CONSENTS_PAGE_ADD_MAPPING = 'CMAM',
  CONSENTS_WIZARD_ADD_MAPPING = 'CMWAM',
  CONSENTS_CONFIRM_SELECT_CONSENT_TYPE = 'CMSN',
  CONSENTS_CONFIRM_SELECT_DATA_SOURCES = 'CMS',
  CONSENTS_CONFIRM_MAP_CONSENTS = 'CMD',
  CONSENTS_ADD_SYSTEM = 'CMAS',
  CONSENTS_RESCAN = 'CMR',
  CONSENTS_EDIT = 'CMIE',
  CONSENTS_EDIT_ALL = 'CMIEA',
  CONSENTS_REMOVE = 'CMIR',
  CONSENTS_REMOVE_ALL = 'CMIRA',
  CONSENTS_PAGE_VIEW = 'CCPV',
  CONSENTS_USERCENTRICS_CLICK = 'CCPC',

  // payment
  CONSENTS_FIRST_PAYMENT = 'CCBPV',
  PORTAL_FIRST_PAYMENT = 'PPBPV',

  // SmallID Portal
  HEADER_PRODUCT_MENU_CLICK = 'SMM',
  HEADER_PRODUCT_ITEM_CLICK = 'SMP', //SMM_productname
}
