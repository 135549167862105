import React, { FC } from 'react';
import { AppealGuidedTourStepTemplate } from './AppealGuidedTourStepTemplate';

interface FromsTabStepProps {
  dataAid?: string;
}

export const FromsTabStep: FC<FromsTabStepProps> = ({ dataAid }) => {
  return (
    <AppealGuidedTourStepTemplate
      stepIndex={1}
      width={400}
      dataAid={dataAid}
      title="Set up Appeal form"
      firstSectionText="Go to Forms to find the Appeal intake request form."
    />
  );
};
