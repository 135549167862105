import makeStyles from '@mui/styles/makeStyles';
import { primaryBorder } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '&:hover': {
      '& $previewControls': {
        display: 'block',
      },
    },
  },
  valueWrapper: {
    alignItems: 'center',
    minHeight: 44,
    fontSize: '0.8125rem',
    position: 'relative',
  },
  valueWrapperFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filled: {
    backgroundColor: theme.vars.palette.bigid?.white,
    border: primaryBorder,
    borderRadius: 8,
    paddingLeft: 20,
  },
  label: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.7rem',
    display: 'flex',
    alignItems: 'center',
  },
  controls: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    marginLeft: 8,
  },
  previewControls: {
    display: 'none',
    position: 'absolute',
    right: 14,
  },
  disabled: {
    pointerEvents: 'none',

    '& $valuePreview': {
      opacity: 0.6,
    },
  },
  valuePreview: {
    padding: '6px 30px 6px 0',
  },

  valuePreviewWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  colorInput: {
    border: 'none',
    background: 'none',
    width: 30,
    marginRight: 8,
  },
  richTextEditorWrapper: {
    '& .jodit-wysiwyg': {
      cursor: 'text',
    },
    '& .jodit-toolbar__box': {
      cursor: 'default',
    },
  },
}));
