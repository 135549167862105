import React, { FC, useCallback, useMemo, useRef } from 'react';
import {
  AccordionSummarySizeEnum,
  BigidAccordion,
  BigidAccordionDetails,
  BigidAccordionSummary,
  BigidBody1,
  BigidCaption,
  BigidDialog,
  BigidDropdownOption,
  BigidHeading4,
  BigidMenu,
  BigidSidePanel,
  BigidSwitch,
  PrimaryButton,
  TertiaryButton,
} from '@bigid-ui/components';
import { Portal } from '@mui/base';
import { useStyles } from './AllowOutsideRequestorsStyles';
import { EmailTemplate } from '../../../components';
import { FormLanguageGeneralError } from '../../../components/FormLanguageGeneralError';
import { LanguagePicker } from '../../../components/LanguagePicker';
import { OnScreenMessage } from './OnScreenMessage';
import { ExceptionResidences } from './ExceptionResidences';
import { getCountryDropdownValueOptions } from '../utils/countriesUtils';
import { AllowOutsideRequestorsContainer } from '../hooks/useAllowOutsideRequestors';
import { WorkflowSettingsDataContainer } from '../hooks/useWorkflowSettingsData';
import EmailTemplateControlsBar from '../EmailTemplateControlsBar';
import { Box, Stack } from '@mui/system';
import { AvailableLanguagesContainer } from '../../../state/availableLanguagesContainer';
import { useToggle } from '../../../hooks';
import { BigidChevronDownIcon } from '@bigid-ui/icons';
import { primaryBorder } from '../../../assets/styles';
import { useSnackbar } from 'notistack';
import { RequestWorkflowStageEmailCode } from '../../../types';

export const AllowOutsideRequestors: FC = () => {
  const classes = useStyles();
  const { languages } = AvailableLanguagesContainer.useContainer();
  const buttonRef = useRef<HTMLDivElement>(null);
  const { value: openEditEmailMenu, toggle: toggleEditEmailMenu } = useToggle(false);
  const {
    value: isOpenOnscreenSettings,
    setFalse: closeOnscreenSettings,
    setTrue: openOnscreenSettings,
  } = useToggle(false);
  const {
    value: isOpenRejectionSettings,
    setFalse: closeRejectionSettings,
    setTrue: openRejectionSettings,
  } = useToggle(false);

  const { value: isOpenSaveDialog, setFalse: closeSaveDialog, setTrue: openSaveDialog } = useToggle(false);
  const { enqueueSnackbar } = useSnackbar();

  const { countriesOptions } = WorkflowSettingsDataContainer.useContainer();
  const {
    settingFormik,
    emailTemplateFormik,
    onScreenMessageFormik,
    allowOutsideRequestorsData,
    updateAllowOutsideRequestorsEmailTemplate,
    updateAllowOutsideRequestorsOnScreenMessage,
  } = AllowOutsideRequestorsContainer.useContainer();

  const setFieldValue = settingFormik.setFieldValue;

  const exceptionOptionHandler = useCallback(
    (options: BigidDropdownOption[]) => {
      setFieldValue(
        'exceptionResidencies',
        options.map(option => option.id),
      );
    },
    [setFieldValue],
  );

  const countryDropdownValueOptions = useMemo(() => {
    return getCountryDropdownValueOptions(settingFormik.values.exceptionResidencies, countriesOptions);
  }, [settingFormik.values.exceptionResidencies, countriesOptions]);

  const handleCloseRejectionSettings = () => {
    closeRejectionSettings();
    emailTemplateFormik.resetForm();
  };

  const handleCloseOnScreenMessageSettings = () => {
    closeOnscreenSettings();
    onScreenMessageFormik.resetForm();
  };

  const handleSaveRejectionSettings = async () => {
    await updateAllowOutsideRequestorsEmailTemplate();
    closeSaveDialog();
    closeRejectionSettings();
    enqueueSnackbar('Changes saved');
  };

  const handleSaveOnScreenMessageSettings = async () => {
    await updateAllowOutsideRequestorsOnScreenMessage();
    closeSaveDialog();
    closeOnscreenSettings();
    enqueueSnackbar('Changes saved');
  };

  if (!allowOutsideRequestorsData) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div>
        <Stack flexDirection="row" justifyContent="space-between" mb={2}>
          <Stack flexDirection="row" alignItems="center">
            <BigidSwitch
              onChange={(_, checked) => settingFormik.setFieldValue('allowOutsideRequestors', !checked)}
              checked={!settingFormik.values.allowOutsideRequestors}
              dataAid="allow-outside-requestor-enabled"
              size="small"
            />
            <BigidHeading4>Reject excluded regulations</BigidHeading4>
          </Stack>

          <Box>
            <div ref={buttonRef}>
              <TertiaryButton
                text="Edit message"
                dataAid="allow-outside-requestor-edit-email-button"
                onClick={toggleEditEmailMenu}
                size="medium"
                endIcon={<BigidChevronDownIcon />}
              />
            </div>
            <BigidMenu
              onMenuClose={toggleEditEmailMenu}
              anchorEl={buttonRef.current as Element}
              open={openEditEmailMenu}
              items={[
                {
                  label: 'Onscreen message',
                  onClick: openOnscreenSettings,
                  dataAid: 'openOnscreenSettingsMenu',
                },
                {
                  label: 'Rejection email',
                  onClick: openRejectionSettings,
                  dataAid: 'openRejectionSettingsMenu',
                },
              ]}
            />
          </Box>
        </Stack>
        <BigidBody1 mb={2}>
          Reject requests from requestors residing outside of selected regulations and residencies.
          <br /> Otherwise, accept automatically.
        </BigidBody1>
        <div>
          {!settingFormik.values.allowOutsideRequestors && (
            <>
              <ExceptionResidences
                value={countryDropdownValueOptions}
                options={countriesOptions}
                onChange={exceptionOptionHandler}
              />
            </>
          )}
          <div>
            <Portal>
              <BigidSidePanel
                isShowBackdrop
                maxWidth="medium"
                onClose={onScreenMessageFormik.dirty ? openSaveDialog : handleCloseOnScreenMessageSettings}
                title="Rejection onscreen level"
                dataAid="email-template__slider"
                open={isOpenOnscreenSettings}
                content={
                  <>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      padding="10px 16px"
                      borderBottom={primaryBorder}
                    >
                      <LanguagePicker
                        testId="onscreen_message_language_selector"
                        language={settingFormik.values.onScreenMessageLanguage}
                        onChange={value => settingFormik.setFieldValue('onScreenMessageLanguage', value)}
                      />
                      <PrimaryButton
                        text="Save Changes"
                        onClick={handleSaveOnScreenMessageSettings}
                        size="medium"
                        disabled={!onScreenMessageFormik.isValid}
                        dataAid="save-email-changes-button"
                      />
                    </Stack>
                    <Box>
                      <BigidAccordion
                        defaultExpanded
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                        }}
                      >
                        <BigidAccordionSummary size={AccordionSummarySizeEnum.large}>
                          <BigidCaption size="large">Onscreen Message</BigidCaption>
                        </BigidAccordionSummary>
                        <BigidAccordionDetails sx={{ padding: '2px 0 !important' }}>
                          <Box mr={5} ml={6}>
                            <FormLanguageGeneralError
                              selectedLanguage={settingFormik.values.onScreenMessageLanguage}
                              fieldName="title"
                              fieldLabel="Title"
                              errors={onScreenMessageFormik.errors?.onScreenMessage?.translations}
                              availableLanguages={languages}
                            />
                            <OnScreenMessage
                              selectedLanguage={settingFormik.values.onScreenMessageLanguage}
                              onScreenMessage={onScreenMessageFormik.values.onScreenMessage}
                              setFieldValue={onScreenMessageFormik.setFieldValue}
                              onScreenMessageErrors={onScreenMessageFormik.errors?.onScreenMessage}
                            />
                          </Box>
                        </BigidAccordionDetails>
                      </BigidAccordion>
                    </Box>
                  </>
                }
              />
              <BigidSidePanel
                isShowBackdrop
                maxWidth="medium"
                onClose={emailTemplateFormik.dirty ? openSaveDialog : handleCloseRejectionSettings}
                title="Reject outside requestors email"
                dataAid="email-template__slider"
                open={isOpenRejectionSettings}
                content={
                  <>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                      borderBottom={primaryBorder}
                      pr={2}
                    >
                      <EmailTemplateControlsBar
                        languagePickerTestId="email_message_language_selector"
                        selectedLanguage={settingFormik.values.emailMessageLanguage}
                        emailTemplateId={allowOutsideRequestorsData.emailTemplate!.id}
                        emailTemplate={emailTemplateFormik.values.emailTemplate}
                        emailCode={RequestWorkflowStageEmailCode.ALLOW_OUTSIDE_REQUESTORS}
                        onSelectedLanguageChange={value => settingFormik.setFieldValue('emailMessageLanguage', value)}
                      />
                      <PrimaryButton
                        text="Save Changes"
                        size="medium"
                        onClick={handleSaveRejectionSettings}
                        disabled={!emailTemplateFormik.isValid}
                        dataAid="save-email-changes-button"
                      />
                    </Stack>
                    <Box>
                      <BigidAccordion
                        defaultExpanded
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                        }}
                      >
                        <BigidAccordionSummary size={AccordionSummarySizeEnum.large}>
                          <BigidCaption size="large">
                            <div className={classes.subtitle} data-aid="email_message_accordion_summary">
                              Email Message
                              <div onClick={event => event.stopPropagation()}>
                                <BigidSwitch
                                  id="emailMessageAvailable"
                                  checked={settingFormik.values.emailMessageAvailable}
                                  onChange={settingFormik.handleChange}
                                  dataAid="toggle_email_message_available"
                                  size="medium"
                                />
                              </div>
                            </div>
                          </BigidCaption>
                        </BigidAccordionSummary>
                        <BigidAccordionDetails sx={{ padding: '2px 0 !important' }}>
                          <Box mr={5} ml={6}>
                            <FormLanguageGeneralError
                              fieldName="subject"
                              fieldLabel="Subject"
                              selectedLanguage={settingFormik.values.emailMessageLanguage}
                              errors={emailTemplateFormik.errors?.emailTemplate?.translations}
                              availableLanguages={languages}
                            />
                            <EmailTemplate
                              testIdPrefix="allow_outside_requestors"
                              selectedLanguage={settingFormik.values.emailMessageLanguage}
                              emailTemplate={emailTemplateFormik.values.emailTemplate}
                              emailTemplateErrors={emailTemplateFormik.errors?.emailTemplate}
                              setFieldValue={emailTemplateFormik.setFieldValue}
                            />
                          </Box>
                        </BigidAccordionDetails>
                      </BigidAccordion>
                    </Box>
                  </>
                }
              />
            </Portal>
          </div>
        </div>
      </div>
      <BigidDialog
        title="You have unsaved changes"
        isOpen={isOpenSaveDialog}
        buttons={[
          {
            component: TertiaryButton,
            text: 'Close without saving',
            onClick: () => {
              closeSaveDialog();
              isOpenRejectionSettings && handleCloseRejectionSettings();
              isOpenOnscreenSettings && handleCloseOnScreenMessageSettings();
            },
            dataAid: 'workflow-settings-save-dialog__cancel',
          },
          {
            component: PrimaryButton,
            text: 'Save',
            onClick: isOpenRejectionSettings ? handleSaveRejectionSettings : handleSaveOnScreenMessageSettings,
            disabled: !onScreenMessageFormik.isValid || !emailTemplateFormik.isValid,
            dataAid: 'workflow-settings-save-dialog__apply',
          },
        ]}
        onClose={closeSaveDialog}
      >
        Your changes will be lost. Are you sure you want to close?
      </BigidDialog>
    </div>
  );
};
