import { useFetch } from '../../../hooks';
import {
  fetchDueDateNotifications,
  postTestEmailDueDateNotifications,
  updateDueDateNotifications,
} from '../../../services/settingsService';
import { WorkflowCustomizationContainer } from './useWorkflowCustomization';
import { useFormik } from 'formik';
import { noop } from 'lodash';
import { createContainer } from 'unstated-next';
import { DueDateNotificationsRecipient } from '../../../types';
import { makeDueDateNotificationValidationSchema } from '../../../utils';
import { useCallback, useMemo } from 'react';
import { AvailableLanguagesContainer } from '../../../state/availableLanguagesContainer';
import { LanguageType } from '@consumer/state/languageContainer';
import { useSnackbar } from 'notistack';
import { DEFAULT_CRON_START } from '../../../utils/dateTimeFormat';

export const useDueDateNotifications = () => {
  const { requestWorkflowData } = WorkflowCustomizationContainer.useContainer();
  const { languagesDropdownOptions } = AvailableLanguagesContainer.useContainer();

  const dueDateNotificationsValidationSchema = useMemo(
    () => makeDueDateNotificationValidationSchema(languagesDropdownOptions),
    [languagesDropdownOptions],
  );

  const {
    data: dueDateNotificationsData,
    loadData: loadDueDateNotificationsData,
    setData: setDueDateNotificationsData,
  } = useFetch({
    fetchApi: () => fetchDueDateNotifications(requestWorkflowData!.id),
    permission: !!requestWorkflowData?.id,
    dependencyParams: [requestWorkflowData?.id],
    initValue: {
      enabled: false,
      recipientType: DueDateNotificationsRecipient.OWNER_AND_COLLABORATORS,
      cron: undefined,
      emailTemplate: {
        translations: {},
      },
      selectedRecipients: [],
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const settingsFormik = useFormik({
    initialValues: {
      ...dueDateNotificationsData,
      cron: dueDateNotificationsData.cron || DEFAULT_CRON_START,
    },
    onSubmit: noop,
    enableReinitialize: true,
  });

  const emailTemplateFormik = useFormik({
    initialValues: {
      ...dueDateNotificationsData.emailTemplate,
    },
    onSubmit: noop,
    enableReinitialize: true,
    validationSchema: dueDateNotificationsValidationSchema,
  });

  const sendTestEmail = useCallback(async () => {
    await postTestEmailDueDateNotifications(requestWorkflowData!.id, {
      locale: LanguageType.EN,
      subject: emailTemplateFormik.values.translations[LanguageType.EN]?.subject || '',
      header: emailTemplateFormik.values.translations[LanguageType.EN]?.header || '',
      buttonText: emailTemplateFormik.values.translations[LanguageType.EN]?.buttonText || '',
    });
    enqueueSnackbar('Test email was sent successfully');
  }, [enqueueSnackbar, emailTemplateFormik.values.translations, requestWorkflowData]);

  const handleDueDateNotificationsUpdate = useCallback(async () => {
    await updateDueDateNotifications(requestWorkflowData!.id, {
      enabled: settingsFormik.values.enabled,
      recipientType: settingsFormik.values.recipientType,
      selectedRecipients: settingsFormik.values.selectedRecipients,
      cron: settingsFormik.values.cron || DEFAULT_CRON_START,
    });
    await loadDueDateNotificationsData();
  }, [settingsFormik.values, loadDueDateNotificationsData, requestWorkflowData]);

  const handleDueDateNotificationsEmailUpdate = useCallback(async () => {
    const { data } = await updateDueDateNotifications(requestWorkflowData!.id, {
      emailTemplate: emailTemplateFormik.values,
    });

    setDueDateNotificationsData({
      ...settingsFormik.values,
      emailTemplate: data.emailTemplate,
    });

    emailTemplateFormik.resetForm();
  }, [emailTemplateFormik, requestWorkflowData, setDueDateNotificationsData, settingsFormik.values]);

  return {
    settingsFormik,
    emailTemplateFormik,
    sendTestEmail,
    handleDueDateNotificationsUpdate,
    handleDueDateNotificationsEmailUpdate,
  };
};

export const DueDateNotificationsContainer = createContainer(useDueDateNotifications);
