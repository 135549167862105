import { RequestRejectReasonType, TrendRequestStatusType, TrendRequestType } from '../../types';

export const simpleSeries = ['value'];
export const typeSeries = [
  TrendRequestType.VIEW,
  TrendRequestType.PREFERENCES,
  TrendRequestType.DELETE,
  TrendRequestType.UPDATE,
  TrendRequestType.OPT_OUT,
  TrendRequestType.APPEAL,
];
export const statusSeries = [
  TrendRequestStatusType.IN_PROGRESS,
  TrendRequestStatusType.ALMOST_OVERDUE,
  TrendRequestStatusType.OVERDUE,
];
export const rejectSeries = [RequestRejectReasonType.AUTO, RequestRejectReasonType.MANUAL];
export const barchartTitle = { main: 'Response Time (days)' };
