import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  system: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 800,
  },
  systemIcon: {
    width: 50,
    height: 40,
    marginRight: 8,
  },
  systemName: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.vars.palette.bigid?.gray700,
  },
}));
