import React, { FC } from 'react';
import { BigidCheckbox } from '@bigid-ui/components';
import { trackOnboardingEvent } from '../../../services/eventsTrackingService';
import { TrackingEvents } from '../../../types/TrackingEventTypes';
import { OnboardingStepType, OnboardingRegulation } from '../../../types';

import { useStyles } from './RegulationsStepStyles';
import { getRegulationIcon } from '../../../utils';

export const RegulationsStep: FC<OnboardingStepType> = ({ data: { regulations }, handleDataChange }) => {
  const classes = useStyles();
  const renderRegulation = (
    { id, shortName, label, enabled }: OnboardingRegulation,
    onChange: ({ id, enabled }: OnboardingRegulation) => void,
  ) => {
    return (
      <BigidCheckbox
        key={id}
        label={
          <div className={classes.regulationLabel} data-aid="regulation-label">
            {getRegulationIcon(shortName)}
            <span className={classes.bold}>{label.split('(')[0]}</span>
            <span className={classes.normal}>({label.split('(')[1]}</span>
          </div>
        }
        checked={enabled}
        onChange={() => onChange({ id, shortName, label, enabled })}
        dataAid="onboarding-regulations-checkbox"
      />
    );
  };

  const setRegulationData = (
    setArray: OnboardingRegulation[],
    { id, enabled }: Pick<OnboardingRegulation, 'id' | 'enabled'>,
  ) => setArray!.map(regulation => (id === regulation.id ? { ...regulation, enabled: !enabled } : regulation));

  const onRegulationChange = ({ id, enabled }: Pick<OnboardingRegulation, 'id' | 'enabled'>) => {
    handleDataChange('regulations', {
      all: setRegulationData(all!, { id, enabled }),
      popular: setRegulationData(popular!, { id, enabled }),
      preselected: setRegulationData(preselected!, { id, enabled }),
    });
  };

  if (!regulations) return <></>;

  const { all, popular, preselected } = regulations;
  const preselectedLinesHeight = Math.ceil(regulations!.preselected.length / 3) * 39;

  return (
    <div className={classes.root}>
      {preselected.length > 0 && (
        <>
          <div className={classes.text}>
            We selected the regulations that match your replies. You can add or remove as needed.
          </div>
          <div>
            <div className={classes.regulations} data-aid="onboarding-preselected-regulations">
              {preselected.length > 0 &&
                preselected!.map(({ id, shortName, label, enabled }) =>
                  renderRegulation({ id, shortName, label, enabled }, onRegulationChange),
                )}
            </div>
          </div>
        </>
      )}
      {preselected.length === 0 && (
        <>
          <div className={classes.text}>Select as many regulations as you need to cover your compliance needs.</div>
          <div className={classes.regulationsLabel}>Most Popular Regulations:</div>
          <div>
            <div className={classes.regulations}>
              {popular.length > 0 &&
                popular!.map(({ id, shortName, label, enabled }) =>
                  renderRegulation({ id, shortName, label, enabled }, onRegulationChange),
                )}
            </div>
          </div>
        </>
      )}
      <div className={classes.regulationsAllLabel}>All Regulations:</div>
      <div
        style={{ height: preselected.length === 0 ? 218 : 290 - preselectedLinesHeight }}
        className={classes.regulationsLayout}
      >
        <div className={classes.regulations} data-aid="onboarding-all-regulations">
          <BigidCheckbox
            label={<span className={classes.allLabel}>All</span>}
            checked={all!.filter(({ enabled }) => enabled).length === all!.length}
            onChange={(_event, checked) => {
              checked && trackOnboardingEvent(TrackingEvents.SELECT_REGULATIONS_ALL);
              handleDataChange('regulations', {
                all: all!.map(regulation => ({ ...regulation, enabled: checked })),
                popular: popular!.map(regulation => ({ ...regulation, enabled: checked })),
                preselected: preselected!.map(regulation => ({ ...regulation, enabled: checked })),
              });
            }}
            dataAid="onboarding-all-regulations-checkbox"
          />
          {all!.map(({ id, shortName, label, enabled }) =>
            renderRegulation({ id, shortName, label, enabled }, onRegulationChange),
          )}
        </div>
        <div className={classes.opacity}></div>
      </div>
    </div>
  );
};
