import makeStyles from '@mui/styles/makeStyles';
import { primaryBorder } from '../../../assets/styles';
import bgImage from '../../../assets/illustrations/pattern.svg';

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.vars.palette.bigid?.gray150,
  },
  stepsRoot: {
    width: '95%',
    maxWidth: 1060,
    minHeight: 560,
    borderRadius: 12,
    background: `${theme.vars.palette.bigid?.white} url('${bgImage}') no-repeat 0 50%/contain`,
    padding: '20px 52px 72px 166px',
    position: 'relative',

    ['@media (max-width: 1180px)']: {
      height: 680,
    },
  },
  heading: {
    width: '100%',
    height: 60,
    borderBottom: primaryBorder,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  title: {
    color: theme.vars.palette.bigid?.gray700,
    fontSize: '1.5rem',
    fontWeight: 600,
    letterSpacing: '0.22px',
  },
  controls: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 'calc(100% - 110px)',
    height: 72,
    padding: '0 52px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: primaryBorder,

    ['@media (max-width: 1180px)']: {
      width: 'calc(100% - 133px)',
    },
  },
  controlsRight: {
    display: 'flex',

    '& button:not(:last-child)': {
      marginRight: 12,
    },
  },
  underlined: {
    '& button': {
      textDecoration: 'underline',
    },
  },
}));
