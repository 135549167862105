import { LanguageType } from '@consumer/state/languageContainer';
import { BigidDropdownOption, BigidDropdownValue } from '@bigid-ui/components';

export const languageLabels: Record<string, string> = {
  en: 'English',
  pt: 'Português',
  cs: 'Čeština',
  ar: 'اَلْعَرَبِيَّةُ',
  da: 'Dansk',
  fr: 'Français',
  de: 'Deutsch',
  el: 'ελληνικά',
  'fr-ca': 'Français Canadien',
  he: 'עברית',
  hi: 'हिन्दी',
  it: 'Italiano',
  es: 'Español',
  et: 'Eesti keel',
  fi: 'Suomi',
  ja: '日本語',
  ko: '한국어',
  ms: 'Bahasa Melayu',
  nl: 'Nederlands',
  no: 'Norsk',
  pl: 'Polski',
  sk: 'Slovenčina',
  sv: 'Svenska',
  th: 'ไทย',
  tr: 'Türkçe',
  vi: 'Tiếng Việt',
  'zh-cn': '繁體中文',
};

export const mapLanguagesToDropdownOptions = (
  languages: { code: LanguageType }[] = [{ code: LanguageType.EN }],
): { value: LanguageType; label: string }[] =>
  languages.map(({ code }) => ({ value: code, label: languageLabels[code] }));

export const languagesToDropdownOptions = (
  languages: { code: string }[] = [{ code: LanguageType.EN }],
): BigidDropdownOption[] =>
  languages.map(({ code }) => ({ value: code, displayValue: languageLabels[code], id: code }));

export const languageToDropdownValue = (code?: LanguageType): BigidDropdownValue =>
  code ? [{ value: code, displayValue: languageLabels[code], id: code }] : [];
