import React, { FC, ReactNode, useEffect, useCallback, useState, ChangeEvent } from 'react';
import { StyledEngineProvider } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import cx from 'classnames';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { useStyles } from './MeAccordionStyles';
import { primaryBorder } from '../../assets/styles';
import { TransitionProps } from '@mui/material/transitions';
import { BigidColorsV2 } from '@bigid-ui/components';
import { useTheme } from '@mui/styles';
import { BigidChevronRightIcon } from '@bigid-ui/icons';

export interface MeAccordionPropsType {
  name: string;
  heading: ReactNode | string;
  body: ReactNode | string;
  headerClasses?: { root?: string; expanded?: string };
  bodyClassName?: string;
  isExpanded?: boolean;
  isSquared?: boolean;
  isDisabled?: boolean;
  arrowRightExpandIcon?: boolean;
  className?: string;
  onExpanding?: () => void;
  onClose?: () => void;
  // added to not break backward compatibility
  isExpandedControlled?: boolean;
  isHighlighted?: boolean;
  isPaymentStyles?: boolean;
  transitionProps?: TransitionProps;
  id?: string;
  isDetailsBGWhite?: boolean;
  moreButton?: ReactNode;
}

export const MeAccordion: FC<MeAccordionPropsType> = ({
  name,
  heading,
  body,
  headerClasses,
  bodyClassName,
  isExpanded,
  isSquared,
  isDisabled,
  onExpanding,
  onClose,
  className,
  isExpandedControlled,
  isHighlighted,
  isPaymentStyles,
  transitionProps,
  id,
  isDetailsBGWhite,
  moreButton,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(isExpandedControlled || isExpanded ? name : false);

  const handleChange = (panel: string) => (event: ChangeEvent<unknown>, isExpanded: boolean) => {
    onExpanding && expanded !== name && onExpanding();
    onClose && expanded === name && onClose();
    event.stopPropagation();
    setExpanded(isExpandedControlled || isExpanded ? panel : false);
  };

  const setExpandedChange = useCallback(
    (name: string) => {
      setExpanded(isExpandedControlled || isExpanded ? name : false);
    },
    [isExpanded, isExpandedControlled],
  );

  useEffect(() => {
    setExpandedChange(name);
  }, [name, setExpandedChange]);

  return (
    <StyledEngineProvider injectFirst>
      <Accordion
        id={id}
        expanded={expanded === name}
        onChange={handleChange(name)}
        disabled={isDisabled}
        classes={{ root: className }}
        TransitionProps={transitionProps}
        sx={[
          {
            '&': {
              margin: '0 !important',
              boxShadow: 'none',
              '&:before': {
                content: '""',
              },
            },
            '.MuiSvgIcon-root': {
              fill: BigidColorsV2.gray[600],
            },
            '.MuiAccordionSummary-root': {
              // a css fix for BME-8851
              '&:hover': {
                '& .previewControls': { display: 'block' },
              },
            },
            '.MuiAccordionSummary-content': {
              marginLeft: 1,
            },
            '.MuiAccordionSummary-content.Mui-expanded': {
              marginLeft: 1,
            },
          },
          !!isPaymentStyles
            ? {
                '.MuiAccordionDetails-root': {
                  backgroundColor: theme.vars.palette.bigid?.white,
                  padding: 0,
                  margin: '8px 0 0 -18px',
                },
              }
            : {
                '.MuiAccordionDetails-root': {
                  backgroundColor: isDetailsBGWhite
                    ? theme.vars.palette.bigid?.white
                    : theme.vars.palette.bigid?.gray150,
                  padding: 0,
                  ...(!isSquared
                    ? {
                        margin: 0,
                        borderRadius: '8px',
                      }
                    : {}),
                },
              },
          !!isPaymentStyles
            ? {
                '.MuiAccordionSummary-root': {
                  flexDirection: 'row',
                  justifyContent: 'start',
                  minHeight: 'auto !important',
                  padding: 0,
                  height: 'auto !important',
                },
                '.MuiAccordionSummary-content': {
                  flexGrow: 0,
                  margin: '0 !important',
                },
                '.MuiAccordionSummary-expandIconWrapper': {
                  padding: '0 8px',
                },
              }
            : !!isSquared
            ? {
                '.MuiAccordionSummary-root': {
                  flexDirection: 'row-reverse',
                  padding: '0 16px 0 0',
                  borderBottom: primaryBorder,
                  height: 'auto !important',
                  '&[class*="disabled"]': {
                    background: theme.vars.palette.bigid?.white,
                    opacity: 1,
                    '& p, & svg': {
                      color: BigidColorsV2.gray[400],
                      fill: BigidColorsV2.gray[400],
                    },
                  },
                  '&:not(:hover)': {
                    '& .AccordionSummaryMoreButton': {
                      display: 'none',
                    },
                  },
                },
                '.MuiAccordionSummary-expandIconWrapper': {
                  padding: '12px',
                  marginRight: '-12px',
                },
                '.MuiAccordionSummary-content': {
                  justifyContent: 'flex-end',
                },
              }
            : {
                '.MuiAccordionSummary-root': {
                  flexDirection: 'row-reverse',
                  padding: 0,
                  height: 'auto !important',
                  '&[class*="disabled"]': {
                    background: theme.vars.palette.bigid?.white,
                    opacity: 1,
                    '& p, & svg': {
                      color: BigidColorsV2.gray[400],
                      fill: BigidColorsV2.gray[400],
                    },
                  },
                },
                '.MuiAccordionSummary-content': {
                  alignItems: 'center',
                },
                '.MuiIconButton-root': {
                  marginLeft: '-12px',
                },
              },
        ]}
      >
        <AccordionSummary
          expandIcon={
            <BigidChevronRightIcon
              dataAid={`${name}-expand-more-icon`}
              className={cx({ [classes.rotate90]: expanded === name })}
              size="small"
            />
          }
          id={name}
          classes={{
            root: cx(headerClasses?.root, headerClasses?.expanded && { [headerClasses.expanded]: expanded }, {
              [classes.highlighted]: isHighlighted,
            }),
          }}
          data-aid={`accordion-summary-${name}`}
        >
          {typeof heading === 'string' ? <div className={classes.heading}>{heading}</div> : heading}
          {moreButton && <span className="AccordionSummaryMoreButton">{moreButton}</span>}
        </AccordionSummary>
        <AccordionDetails>
          <div className={cx(classes.body, bodyClassName)}>{body}</div>
        </AccordionDetails>
      </Accordion>
    </StyledEngineProvider>
  );
};
