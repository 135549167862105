import makeStyles from '@mui/styles/makeStyles';

import { paperBoxShadow } from '../../assets/styles';

export const useStyles = makeStyles(theme => ({
  gridWrapper: {
    display: 'inline-flex',
    height: 'calc(100vh - 215px)',
    width: '100%',
    overflow: 'auto',
    maxHeight: 800,
    background: theme.vars.palette.bigid?.white,
    borderRadius: 20,
    flexFlow: 'column nowrap',
    boxShadow: paperBoxShadow,
    position: 'relative',
  },
  gridControls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  systemBadge: {
    marginRight: 16,
    display: 'inline-flex',
  },
  systemBadgeWithError: {
    padding: '7px 12px',
    borderRadius: 8,
    backgroundColor: theme.vars.palette.bigid?.red100,
  },
  scanErrorBadge: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 12px 5px 8px',
    borderRadius: 8,
    backgroundColor: theme.vars.palette.bigid?.red100,
  },
  systemBadgeIcon: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
  systemBadgeLabel: {
    fontSize: '0.8125rem',
    fontWeight: 'normal',
  },
  limitedNotification: {
    position: 'absolute',
    top: 4,
    left: 240,
    width: 167,
    height: 26,
    borderRadius: 4,
    backgroundColor: '#CCD5F8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    fontWeight: 700,
    '& svg': {
      marginRight: 8,
    },
  },
}));
