import { FormVerification, RequestSubmitFieldType, RequestSubmitTextType } from './SettingsTypes';
import { RequestType } from './SingleRequestTypes';
import { RequestDefinitionLayoutType } from '@consumer/services';

export type RequestSubmitPortalType = {
  id: number;
  name: string;
  publishName: string;
  brandId: number;
  url: string;
  forms: RequestSubmitFormType[];
};

export type RequestSubmitPortalMergeFormType = {
  brandRequestsChange: {
    brandRequestIds: number[];
    sourceFormId?: number | string;
    targetFormId?: number | string;
  };
  name?: string;
};

export type GpcSettings = {
  hostingDomain: string;
  recognizedUserPopupTitle: string;
  recognizedUserPopupDescription: string;
  recognizedUserEnabled: boolean;

  unrecognizedUserPopupTitle: string;
  unrecognizedUserPopupDescription: string;
  unrecognizedUserEnabled: boolean;
};

export type RequestSubmitFormType = {
  formId: number;
  fields: RequestSubmitFieldType[];
  texts: RequestSubmitTextType[];
  verifications: FormVerification[];
  brandRequests: RequestSubmitFormBrandRequestType[];
  layout: RequestDefinitionLayoutType;
  authorizedAgentEnabled: boolean;
  autoVerificationWithAgentAllowed: boolean;
  gpcSettings?: GpcSettings;
};

export type RequestSubmitFormBrandRequestType = {
  id: number;
  name: RequestType;
};
