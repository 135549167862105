import { useEffect, useRef, useState } from 'react';
import { Client, IFrame, StompSubscription } from '@stomp/stompjs';

import { authService } from '../services/authentication';

type UseWebSocketPropsType<T> = {
  topic: string;
  isActive?: boolean;
  onMessage: (value: T) => void;
};

const host = window.location.host;
const brokerUrl = `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${host}/prm-websocket`;

export const useWebSocket = <T>({ topic, onMessage, isActive = true }: UseWebSocketPropsType<T>) => {
  const user = authService.getUser();
  const tenant = user && user.tenant;
  const onMessageRef = useRef<(value: T) => void>();

  // have only one copy of client
  const [client] = useState(
    new Client({
      brokerURL: brokerUrl,
      reconnectDelay: 30000,
    }),
  );

  useEffect(() => {
    onMessageRef.current = onMessage;
  }, [onMessage]);

  useEffect(() => {
    if (!tenant || !isActive) {
      return;
    }

    let subscription: StompSubscription;

    client.onConnect = (_: IFrame) => {
      subscription = client.subscribe(`/topic/${tenant}/${topic}`, message => {
        const callback = onMessageRef.current;
        if (callback) {
          callback(JSON.parse(message.body) as T);
        }
      });
    };

    const finish = () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      if (client.connected) {
        client.deactivate();
      }
    };

    client.onWebSocketError = evt => {
      console.log('WebSocket error', evt);
      finish();
    };

    client.onStompError = evt => {
      console.log('WebSocket client error', evt);
      finish();
    };

    if (!client.connected) {
      client.activate();
    }

    return () => {
      finish();
    };
  }, [topic, tenant, isActive, client]);
};
