import { experimental_extendTheme as extendTheme, CssVarsThemeOptions } from '@mui/material/styles';
import { commonTheme } from '@bigid-ui/components';
import '@fontsource/mulish';
import type {} from '@mui/material/themeCssVarsAugmentation';

export const AppTheme = extendTheme({
  ...commonTheme,
  colorSchemes: {
    ...commonTheme.colorSchemes,
    // this is needed for preview, without it orange theme doesn't work
    orange: extendTheme({
      colorSchemes: {
        ...commonTheme.colorSchemes,
      },
    }).colorSchemes.light,
  },
  typography: {
    ...commonTheme.typography,
    fontFamily: 'Mulish, Helvetica, Arial, sans-serif',
  },
} as CssVarsThemeOptions);
