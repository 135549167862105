import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  title: {
    marginBottom: 10,
    fontSize: '0.9375rem',
    fontWeight: 600,
  },
  content: {
    padding: 12,
  },
});
