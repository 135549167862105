import { styled } from '@mui/material/styles';

const PREFIX = 'SystemNameValueFields';

export const classes = {
  field: `${PREFIX}-field`,
};

export const Root = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'end',
  justifyContent: 'space-between',
  fontSize: '14px',
  [`& .${classes.field}`]: {
    width: '100%',
    '&:first-child': {
      marginRight: 20,
    },
  },
});
